import { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { withTranslation, TFunction } from "react-i18next";
import Image from "react-bootstrap/Image";
import { isMobile } from "react-device-detect";
import JobDetailsV2 from "src/components/common/MainContent/JobDescription/JobDetailsV2";
import JobsCarousel from "src/components/common/MainContent/JobDescription/JobsCarousel";
import { postJobApplicationFavoriteEmail } from "src/redux/userAction/applyFavEmail/action";
import { detectJobCardClick } from "src/redux/jobDetails/action";
import Banner from "src/components/common/Banner/HeroBanner";
import config from "src/config";
import { fetchRecommendedJobs } from "src/redux/jobs/SearchCrossCountry/action";
import { getAccessToken } from "axios-jwt";
import imageResolution from "src/services/ImageResolution";
import { jobDetailType, facilityCustomContentResult } from "src/components/common/MainContent/JobResults/common/index";
import { graphqlRequest as request } from "src/services/GraphQL/GraphQL";
import { GET_FACILITY_CUSTOM_CONTENT, GET_JOB_DETAILS } from "src/services/GraphQL/graph.query";
const facilityModalApplicable = config.FACILITY_MODAL === "true";
import { RecommendedJobsSourceTypes } from "src/types/receommendedJobsSource";
import getCandidateInformation, { OnboardingInfo } from "src/components/common/CandidateInformation/CandidateInformation";

interface RecommendedJobsData {
  sort: string;
  companyId: string | null;
  size: string | undefined;
  candidateId: string;
  candidateInformation: any;
}

interface DetectJobCardClick {
  isCardClicked: boolean;
  job: jobDetailType;
  isRecommended: boolean;
  campaignCode: string | null;
  sourceType: string | null;
  companyId: string | null;
  size: string | undefined;
  candidateInformation: any;
  candidateId: string;
}

interface ViewedJob {
  jobId: string | null;
  userId: number;
  action: string;
  request: {
    isActive: boolean;
  };
  recommended: string;
  recommendedJobs: string;
  isRecommendedJobsOnResult: boolean;
}

interface DescriptionMasterV2Props {
  detectJobCardClick: (data: DetectJobCardClick) => void;
  viewedJob: (data: ViewedJob) => void;
  t: TFunction<"translation", undefined>;
  atsBullhornCandidateId: string;
  bhId: string;
  fetchRecommendedJobs: (data: RecommendedJobsData, nav?: string) => void;
  recommended: string;
  recommendedJobs: string;
  isRecommendedJobsOnResult: boolean;
  isRecommended: boolean;
  similarJobsCarouselItems: string[];
  collaboratedJobsCarouselItems: string[];
  onboardingInfo: OnboardingInfo;
  jobsSource: RecommendedJobsSourceTypes;
}

function DescriptionMasterV2(props: DescriptionMasterV2Props) {
  const history = useHistory();
  const [query, setQuery] = useState(new URLSearchParams(history.location.search));
  const [jobId, setJobId] = useState(query.get("jobId") || "");
  const [companyId, setCompanyId] = useState(query.get("companyId") || "");
  const [isRecommended, setIsRecommended] = useState(query.get("isRecommended") === "true");
  const [campaignCode, setCampaignCode] = useState(query.get("campaignCode") || "");
  const [sourceType, setSourceType] = useState(query.get("sourceType") || "");
  const [jobDetails, setJobDetails] = useState<jobDetailType>();
  const [isApplied, setIsApplied] = useState<boolean>(false);
  const [imageResolutionDetails, setImageResolutionDetails] = useState("");
  const [facilityCustomContent, setFacilityCustomContent] = useState<facilityCustomContentResult[]>([])
  const [authToken, setAuthToken] = useState(getAccessToken());

  useEffect(() => {
    const candidateInformation = getCandidateInformation(props.onboardingInfo);
    const data = {
      sort: "relevant",
      companyId,
      size: config.JOB_COUNT,
      candidateId: props.atsBullhornCandidateId || props.bhId,
      candidateInformation,
    };
    props.fetchRecommendedJobs(data);
    setQuery(new URLSearchParams(history.location.search));
    const query = new URLSearchParams(history.location.search);
    setJobId(query.get("jobId") || "");
    setCompanyId(query.get("companyId") || "");
    setIsRecommended(query.get("isRecommended") === "true");
    setCampaignCode(query.get("campaignCode") || "");
    setSourceType(query.get("sourceType") || "");
    setIsApplied(query.get("isApplied") === "true");
  }, [history.location.search, props.atsBullhornCandidateId]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setAuthToken(getAccessToken());
    updateJob();
  }, [jobId]);

  // update Facility Custom Content Based on Job Details
  useEffect(() => {
    if (
      (jobDetails !== null || jobDetails !== undefined) &&
      facilityModalApplicable &&
      jobDetails?.isCustomContentEnabled
    ) {
      updateFacilityCustomContent();
    }
  }, [jobDetails]);

  useEffect(() => {
    const jobDetailsUpdated = jobDetails;
    if (jobDetailsUpdated && query.get("isApplied") !== "null") {
      jobDetailsUpdated.isApplied = isApplied;
      setJobDetails(jobDetailsUpdated);
    }
  }, [isApplied, jobDetails]);

  useEffect(() => {
    if (jobDetails && props.onboardingInfo?.onboardingInformation && (!props.onboardingInfo?.isCredentialsLoading) ) {
      const candidateInformation = getCandidateInformation(props.onboardingInfo);
      const obj = {
        isCardClicked: true,
        job: jobDetails,
        isRecommended,
        campaignCode,
        sourceType,
        companyId,
        size: config.JOB_COUNT,
        candidateInformation,
        candidateId: props.atsBullhornCandidateId || props.bhId,
      };
      props.detectJobCardClick(obj);
      const reqObj = {
        jobId,
        userId: 2,
        action: "view",
        request: {
          isActive: true,
        },
        recommended: props.recommended,
        recommendedJobs: props.recommendedJobs,
        isRecommendedJobsOnResult: props.isRecommendedJobsOnResult,
      };
      props.viewedJob(reqObj);
    }
  }, [jobDetails, props.onboardingInfo?.onboardingInformation, props.onboardingInfo?.isCredentialsLoading]);

  useEffect(() => {
    if (jobDetails && jobDetails.imageUri) {
      setImageResolutionDetails(
        imageResolution(jobDetails.imageUri, isMobile ? "Mobile" : "Desktop", "jobDescription")
      );
    }
  }, [jobDetails]);

  const updateJob = async () => {
    try {
      const query = { jobId: Number(jobId) };
      const {getJob} = await request(GET_JOB_DETAILS, query,  authToken);
      if(getJob?.userMessage){
        setJobDetails(getJob?.content)
      }
    } catch (e) {
      console.error("Failed to Fetch job details by jobId", e);
      setJobDetails(undefined)
    }
  };

  // fetch facility custom content API and set the content state
  const updateFacilityCustomContent = async () => {
    try {
      const { getFacilityCustomContent } = await request(GET_FACILITY_CUSTOM_CONTENT, {
        atsFacilityId: jobDetails?.facilityId?.toString(),
      });
      if(getFacilityCustomContent?.isSuccess){
        setFacilityCustomContent(getFacilityCustomContent.results)
      }
    } catch (e) {
      console.error("Failed to Fetch Facility Custom Content", e);
    }
  };

  const result = jobDetails;
  if (result) {
    result.id = jobId;
  }
  if (result) {
    return (
      <div className="jobDetailsWrap">
        <Container fluid className="p-0">
          {jobDetails?.imageUri ? (
            <Image
              src={config.JOB_CARD_IMAGE_URI_DOMAIN + imageResolutionDetails}
              className="jobDetailsBanner"
            />
          ) : isMobile ? (
            <Image
              src={
                (config.JOB_CARD_IMAGE_URI_DOMAIN || "") + (config.JOB_CARD_DEFAULT_IMAGE_SM || "")
              }
              className="jobDetailsBanner"
            />
          ) : (
            <Image
              src={
                (config.JOB_CARD_IMAGE_URI_DOMAIN || "") + (config.JOB_CARD_DEFAULT_IMAGE_LG || "")
              }
              className="jobDetailsBanner"
            />
          )}
        </Container>
        <Container className="mainJobdetailsV2">
          <JobDetailsV2
            jobDetails={result}
            companyId={companyId}
            sourceType={sourceType}
            isRecommended={isRecommended}
            jobId={0}
            facilityCustomContent={facilityCustomContent}
            jobsSource={props.jobsSource}
          />
        </Container>

        <div>
          {props.similarJobsCarouselItems.length > 0 ? (
            <div>
              <JobsCarousel
                card={props.similarJobsCarouselItems}
                title={props.t("jobs.jobsCard.similarJobsTitle")}
              />
            </div>
          ) : null}
          {props.collaboratedJobsCarouselItems.length > 0 ? (
            <div>
              <JobsCarousel
                card={props.collaboratedJobsCarouselItems}
                title={props.t("jobs.jobsCard.otherJobsTitle")}
              />
            </div>
          ) : null}
        </div>
      </div>
    );
  }
  return (
    <div>
      <Banner />
    </div>
  );
}

const mapStateToProps = (state) => ({
  similarJobsCarouselItems: state.jobDetails.similarJobsCarouselItems,
  collaboratedJobsCarouselItems: state.jobDetails.collaboratedJobsCarouselItems,
  recommendedJobs: state.jobs.recommendedJobs,
  atsBullhornCandidateId: state.candidate.atsBullhornCandidateId,
  bhId: state.candidate.bullhornIdFromBullhorn,
  onboardingInfo: state.onBoarding,
});

const mapDispatchToProps = (dispatch) => ({
  viewedJob: (reqObj) => dispatch(postJobApplicationFavoriteEmail(reqObj)),
  detectJobCardClick: (obj) => dispatch(detectJobCardClick(obj)),
  fetchRecommendedJobs: (obj, nav) => dispatch(fetchRecommendedJobs(obj, nav)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(DescriptionMasterV2));
