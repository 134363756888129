import { useEffect, useState, useRef, FC } from "react";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { makeStyles } from "@material-ui/core/styles";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { isMobile } from "react-device-detect";
import { useHistory } from "react-router-dom";
import dateFormat from "dateformat";
import filter from "lodash/filter";
import TertiaryButton from "src/components/common/Buttons/TertiaryButton";
import SecondaryButton from "src/components/common/Buttons/SecondaryButton";
import PrimaryButton from "src/components/common/Buttons/PrimaryButton";
import { UnregisterCallback, Location } from "history";
import OnBoardingSignature from "src/components/common/OnBoarding/SkillChecklist/OnBoardingSignature";
import OnBoardingSkillRadio from "src/components/common/OnBoarding/SkillChecklist/OnBoardingSkillRadio";
import CircularProgressWithLabel from "src/components/common/OnBoarding/SkillChecklist/OnBoardingCircularProgress";
import style from "src/components/common/OnBoarding/SkillChecklist/OnBoardingSkillRatings.module.scss";
import {
  BASIC_INFO_PAGE,
  EDU_SPEC_PAGE,
  WORK_EXP_PAGE,
  SKILL_CHECKLIST_OVERVIEW_PAGE,
  DASHBOARD,
  REFERENCES_PAGE,
  LATEST_TASKS,
} from "src/router/routes";
import {
  saveSkillChecklistSelected,
  saveOptionalSkillChecklist,
  saveOnboardingComplete,
  updateCompleteFlag,
  updateActiveFlag,
} from "src/redux/onBoarding/action";
import Analytics from "src/services/Analytics";
import { SKILLS_CHECKLIST } from "src/constants/onboardingEventName";
import MyProfileNavWrapper from "../MyProfileNavWrapper";

const perStepPercentage = 20;

const useStyles = makeStyles(() => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  root: {
    "& .MuiPaper-root": {
      boxShadow: "0px 0px 4px 0px #3f3f4426 !important",
      marginTop: "0.8rem",
      border: "1px solid #3f3f4426",
      opacity: "1",
      borderRadius: "5px",
    },
    "& .MuiAccordionSummary-root": {
      minHeight: "60px",
    },
    "& .MuiAccordion-root:before": {
      backgroundColor: "#fff4f7",
    },
    "& .MuiAccordionDetails-root": {
      borderTop: "1px solid #3f3f4426",
      padding: "0px 0px",
    },
  },
}));

interface LocationState {
  navigateToMyTask: boolean;
  isUpdateNowClicked: boolean;
  source: string;
  image: string;
}
interface SelectedItem {
  isBulkUpdate: boolean;
  skillChecklistGroupName: string;
  skillCheckListGroupSkillAsProp: string;
  shouldOpen: boolean;
}

interface SkillChecklist {
  id: string | null;
  name: string;
  complete: boolean;
  required: string;
}
interface SkillChecklistItem {
  atsId: string;
  name: string;
  value: string;
}

interface SkillChecklistGroupSkills extends Array<SkillChecklistItem> {
  [index: number]: SkillChecklistItem;
}
interface CompletedSkillsChecklistState extends Array<string> {
  [index: number]: string;
}
interface Onboarding {
  skillsChecklists: SkillChecklist[];
}
interface Skill {
  id: string;
  active: string;
  groupId: string;
  name: string;
  scale: string;
  subScaleName: string | null;
  value: string | null;
}
interface SkillGroup {
  active: string;
  checklistId: string;
  id: string;
  initial: string | null;
  name: string;
  scale: string;
  skills: Skill[];
}
interface UpdatedSkillsChecklist {
  name: string;
  value: string;
}
interface skillChecklistSelectedData {
  id: string;
  name: string;
  required: string;
  groups: SkillGroup[];
  signature: null | string;
  complete: null | boolean;
  completedDate: null | string;
  lastUpdated: string;
}

interface ApplicantData {
  candidateId: number;
  candidateSpecialties: string[];
  company: {
    id: string;
  };
  completedSkillsCheckList: CompletedSkillsChecklistState;
  email: string;
  firstName: string;
  onBoardingCompletedSteps: { [key: number]: boolean };
  saveAndUploadLoading: boolean;
  saveOptionalSkillChecklistAction: (reqObj: any, previousOnboardingBasicInfo?: any) => void;
  saveSkillChecklistResponse: { onboarding: Onboarding };
  saveSkillChecklistSelectedAction: (skillSelected: any) => void;
  skillChecklistSelected: skillChecklistSelectedData;
  skillChecklists: any[];
  skillsChecklistGroupSkills: SkillChecklistGroupSkills;
  updateActiveFlagAction: (data: any) => void;
  updateCompleteFlagAction: (completedSteps: any) => void;
  updatedSkillsChecklist: UpdatedSkillsChecklist;
  onboardingBasicInfo: any;
}
const OnBoardingSkillChecklistNew: FC<ApplicantData> = ({
  candidateId,
  candidateSpecialties,
  company,
  completedSkillsCheckList,
  email,
  firstName,
  onBoardingCompletedSteps,
  saveAndUploadLoading,
  saveOptionalSkillChecklistAction,
  saveSkillChecklistResponse,
  saveSkillChecklistSelectedAction,
  skillChecklistSelected,
  skillChecklists,
  skillsChecklistGroupSkills,
  updateActiveFlagAction,
  updateCompleteFlagAction,
  updatedSkillsChecklist,
  onboardingBasicInfo,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const classes = useStyles();
  const [expanded, setExpanded] = useState<string | boolean>(false);
  const [openProgress, setOpenProgress] = useState<boolean>(false);
  const [loaderLabel, setLoaderLabel] = useState<string | null>(null);
  const [signatureURL, setSignatureURL] = useState<string | null>(null);
  const [openSection, setOpenSection] = useState<boolean>(false);
  const [showErrMsg, setShowErrMsg] = useState<boolean>(false);
  const [selectedGroup, setSelectedGroup] = useState<string | null>(null);
  const [selectedGroupSkills, setSelectedGroupSkills] = useState<{ skills: any[] }>({ skills: [] });
  const signatureRef = useRef<HTMLDivElement>(null);
  const [skillChecklistSelectedState] =
    useState<skillChecklistSelectedData>(skillChecklistSelected);
  const [skillsChecklistGroupSkillsState] = useState<SkillChecklistGroupSkills>(
    skillsChecklistGroupSkills
  );
  const [completedList] = useState<CompletedSkillsChecklistState>(completedSkillsCheckList);
  const [pageOffsetY, setPageOffsetY] = useState<number>(window.pageYOffset);
  const [selectedGroupIndex, setSelectedGroupIndex] = useState<number>(0);
  const [reRenderComponentItem, setReRenderComponentItem] = useState<boolean>(true);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [formChangeDetected, setFormChangeDetected] = useState<boolean>(false);
  const [openRate, setOpenRate] = useState<boolean>(false);
  const [openRateSelectedItem, setOpenRateSelectedItem] = useState<SelectedItem | null>(null);
  const [bulkUpdateValue, setBulkUpdateValue] = useState<string | null>(null);
  const [bulkUpdateGroupName, setBulkUpdateGroupName] = useState<string | null>(null);
  const unblockHandle = useRef<UnregisterCallback | undefined | null>(null);
  const unblockHandleDestination = useRef<Location | undefined | null>(null);
  const [showSaveAndUpload, setShowSaveAndUpload] = useState<boolean>(
    skillChecklistSelected ? !!skillChecklistSelected.signature : false
  );
  const [skillChecklistsState] = useState<any[]>(() => {
    if (skillChecklists && skillChecklists.length > 0) {
      return skillChecklists;
    }
    if (saveSkillChecklistResponse?.onboarding?.skillsChecklists) {
      return saveSkillChecklistResponse.onboarding.skillsChecklists;
    }
    return [];
  });
  const isUpdateNowClicked = (history?.location?.state as LocationState)?.isUpdateNowClicked;
  const source = (history?.location?.state as LocationState)?.source;
  const image = (history?.location?.state as LocationState)?.image;

  const handleopenProgress = () => {
    setOpenProgress(true);
  };

  const handleCloseProgress = () => {
    setOpenProgress(false);
  };

  useEffect(() => {
    const completedSkillsGroupFromStore = completedList;
    if (skillChecklistSelectedState) {
      for (let i = 0; i < skillChecklistSelectedState.groups.length; i++) {
        let isPresent = true;
        for (let j = 0; j < skillChecklistSelectedState.groups[`${i}`].skills.length; j++) {
          if (
            skillsChecklistGroupSkillsState.some(
              (skillsChecklistGroupSkill) =>
                skillsChecklistGroupSkill.name ===
                skillChecklistSelectedState.groups[`${i}`].skills[`${j}`].name
            )
          ) {
            isPresent = isPresent && true;
          } else {
            isPresent = isPresent && false;
            break;
          }
        }
        if (isPresent) {
          if (
            !completedSkillsGroupFromStore.includes(skillChecklistSelectedState.groups[`${i}`].name)
          ) {
            completedSkillsGroupFromStore.push(skillChecklistSelectedState.groups[`${i}`].name);
          }
        }
      }
    }
  }, [skillsChecklistGroupSkillsState, skillChecklistSelectedState, completedList]);

  useEffect(() => {
    window.scrollTo(0, 0);
    const arr: string[] = []; // Array to hold the keys
    // Iterate over localStorage and insert the keys that meet the condition into arr

    for (let i = 0; i < localStorage.length; i++) {
      if (
        localStorage.key(i)?.substring(0, 6) === "groupn" ||
        localStorage.key(i)?.substring(0, 6) === "groupv"
      ) {
        const key = localStorage.key(i);
        if (key !== null) {
          arr.push(key);
        }
      }
    }
    // Iterate over arr and remove the items by key
    for (let j = 0; j < arr.length; j++) {
      localStorage.removeItem(arr[`${j}`]);
    }
  }, []);

  useEffect(() => {
    if (openModal) {
      unblockHandle.current = history.block((targetLocation) => {
        if (window.location.pathname !== targetLocation.pathname) {
          unblockHandleDestination.current = targetLocation;
          setOpen(true);
        }
        return false;
      });

      return function cleanup() {
        if (unblockHandle.current) {
          unblockHandle.current();
        }
      };
    }
    return () => {};
  }, [openModal]);

  const handleChange = (panel: string, index: number) => (_event, isExpanded) => {
    setSelectedGroupIndex(index);
    if (panel === "completeFlag") {
      setExpanded(isExpanded);
      return;
    }

    if ((!openSection && !showErrMsg) || selectedGroup) {
      setExpanded(isExpanded ? panel : false);
      setShowErrMsg(false);
      setOpenSection(true);
      setSelectedGroup(panel);
      setSelectedGroupSkills(
        skillChecklistSelectedState.groups.filter((group) => group.name === panel)[0]
      );

      if (pageOffsetY !== 0 && pageOffsetY < window.pageYOffset && isExpanded) {
        const offset =
          pageOffsetY +
          (document.getElementById(panel)?.offsetHeight || 0) * (index - selectedGroupIndex);
        window.scrollTo(window.pageXOffset, offset);
        setPageOffsetY(offset);
      } else {
        setPageOffsetY(window.pageYOffset);
      }
    } else {
      setShowErrMsg(true);
      const missedSkill = selectedGroupSkills.skills.find((skill) => !skill.value);
      if (missedSkill && missedSkill.id) {
        const element = document.getElementById(missedSkill.id);
        if (element) {
          element.scrollIntoView({ block: "center" });
        }
      }
    }
  };

  const handleOpen = () => {
    const navigateToMyTask = (history?.location?.state as LocationState)?.navigateToMyTask;
    if (navigateToMyTask) {
      history.push(LATEST_TASKS);
    } else if (formChangeDetected) {
      if (unblockHandleDestination.current) {
        unblockHandleDestination.current = null;
      }
      setOpen(true);
    } else {
      history.push(SKILL_CHECKLIST_OVERVIEW_PAGE, { source, isUpdateNowClicked, image });
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenRate = (item) => {
    setOpenRateSelectedItem(item);
    setOpenRate(isMobile ? item.shouldOpen : false);
  };

  const handleCloseRate = () => {
    setOpenRate(false);
  };

  const confirmBulkUpdate = (value, groupName) => {
    setOpenRate(false);
    setBulkUpdateValue(value);
    setBulkUpdateGroupName(groupName);
  };
  const deselectBulkUpdate = () => {
    setBulkUpdateValue(null);
  };

  const closeRadioPopup = () => {
    if (!openRateSelectedItem?.isBulkUpdate) setOpenRate(false);
  };
  const reRenderComponent = () => {
    setReRenderComponentItem(!reRenderComponentItem);
  };

  const handleButtonRendering = (value, signature) => {
    setShowSaveAndUpload(value);
    setSignatureURL(signature);
  };
  const handleSkillUpdated = (value) => {
    setFormChangeDetected(value);
    setOpenModal(value);
  };

  const checkSkillChecklistCompleteStatus = () => {
    const skillChecklistSelectedLocal = skillChecklistSelectedState;
    if (skillChecklistSelectedLocal.required === "0") {
      if (skillChecklistSelectedLocal.complete) {
        return true;
      }
      return false;
    }

    const filterOptionalSkillChecklists = filter(skillChecklistsState, (o) => {
      return o.name !== skillChecklistSelectedLocal.name && o.id !== null;
    });
    const isRemainingOptionalSkillsCompleted = filterOptionalSkillChecklists.every(
      (skillChecklist) => skillChecklist.complete
    );

    return skillChecklistSelectedLocal.complete && isRemainingOptionalSkillsCompleted;
  };

  const setSkillGroupCompleteFlag = async () => {
    setOpenModal(false);
    const now = new Date();
    handleopenProgress();
    const skillChecklistSelectedLocal = skillChecklistSelectedState;
    let skillChecklistCompleted = true;
    if (skillChecklistSelectedLocal) {
      for (let i = 0; i < skillChecklistSelectedLocal.groups.length; i++) {
        if (!completedList?.includes(skillChecklistSelectedLocal.groups[`${i}`].name)) {
          skillChecklistCompleted = skillChecklistCompleted && false;
        }
      }
      if (skillChecklistCompleted && signatureURL) {
        skillChecklistSelectedLocal.complete = true;
        skillChecklistSelectedLocal.completedDate = dateFormat(now, "yyyy-mm-dd HH:MM:ss");
      }
      skillChecklistSelectedLocal.lastUpdated = dateFormat(now, "yyyy-mm-dd HH:MM:ss");
      const saveChecklistReqObj = {
        event: SKILLS_CHECKLIST,
        candidateId,
        email,
        companyId: parseInt(company.id, 10),
        sqsEvent: true,
        profile: {
          meta: {
            lastVisitedPage: "4",
            completedSections: {
              basicInfo: !!onBoardingCompletedSteps[0],
              specialtiesAndEducation: !!onBoardingCompletedSteps[1],
              workExperience: !!onBoardingCompletedSteps[2],
              skillsChecklists: !!checkSkillChecklistCompleteStatus(),
              references: !!onBoardingCompletedSteps[3],
            },
          },
          skillsChecklist: skillChecklistSelectedLocal,
        },
      };
      await saveSkillChecklistSelectedAction(skillChecklistSelectedLocal);
      await saveOptionalSkillChecklistAction(saveChecklistReqObj, onboardingBasicInfo);
    }
  };

  const goBackToSkillsOverview = () => {
    setLoaderLabel(t("onBoarding.skillChecklist.savingLoaderText"));
    handleClose();
    setSkillGroupCompleteFlag();
    setOpenModal(false);
    setTimeout(() => {
      handleCloseProgress();
      const previousLocation = unblockHandleDestination.current;
      if (previousLocation) {
        history.push(previousLocation.pathname);
      } else {
        history.push(SKILL_CHECKLIST_OVERVIEW_PAGE, { source, isUpdateNowClicked, image });
      }
    }, 2000);
  };

  const segmentEvent = (checklistName) => {
    const segmentEventName = t("segment.taskCompleted");
    const segmentEventProps = {
      taskName: checklistName,
      taskType: t("myTasks.checklist"),
    };
    Analytics.segmentTrackEvent(segmentEventName, segmentEventProps);
  };
  const profileProgressInPercentage = () => {
    let completedPercentage = 0;
    let noOfStepsCompleted = 0;

    const completedStepKeys = Object.keys(onBoardingCompletedSteps);
    noOfStepsCompleted = completedStepKeys.length;

    completedPercentage = noOfStepsCompleted * perStepPercentage;
    return completedPercentage;
  };
  const handlePageToRedirect = () => {
    let pageToRedirect = DASHBOARD;
    if (!onBoardingCompletedSteps[0]) {
      pageToRedirect = BASIC_INFO_PAGE;
      updateActiveFlagAction(0);
    } else if (!onBoardingCompletedSteps[1]) {
      pageToRedirect = EDU_SPEC_PAGE;
      updateActiveFlagAction(1);
    } else if (!onBoardingCompletedSteps[2]) {
      pageToRedirect = WORK_EXP_PAGE;
      updateActiveFlagAction(2);
    } else if (!onBoardingCompletedSteps[3]) {
      pageToRedirect = REFERENCES_PAGE;
      updateActiveFlagAction(3);
    } else if (!onBoardingCompletedSteps[4]) {
      pageToRedirect = SKILL_CHECKLIST_OVERVIEW_PAGE;
      updateActiveFlag(4);
    }
    if (pageToRedirect === DASHBOARD) {
      const event = {
        category: t("googleAnalytics.category.onboarding"),
        action: t("googleAnalytics.action.completeApplication"),
        label: t("googleAnalytics.label.application"),
      };
      const dimensions = {
        dimension9:
          candidateSpecialties && candidateSpecialties.length > 0
            ? candidateSpecialties.toString()
            : null,
      };
      Analytics.sendPageview(history.location, dimensions);
      Analytics.sendEvent(event);
    }
    history.push(pageToRedirect);
  };

  const handleSaveButton = async () => {
    setOpenModal(false);
    setLoaderLabel(t("onBoarding.skillChecklist.updatingLoaderText"));
    const skillWithSignature = skillChecklistSelectedState;
    const navigateToMyTask = (history?.location?.state as LocationState)?.navigateToMyTask;
    if (signatureURL) {
      skillWithSignature.signature = signatureURL;
      skillWithSignature.complete = true;
      skillWithSignature.completedDate = dateFormat(new Date(), "yyyy-mm-dd HH:MM:ss");
    } else if (skillWithSignature.signature) {
      skillWithSignature.complete = true;
    } else {
      skillWithSignature.complete = false;
    }
    await setSkillGroupCompleteFlag();
    saveSkillChecklistSelectedAction(skillWithSignature);

    const completedChecklist = {
      id: skillChecklistSelectedState.id.toString(),
      name: skillChecklistSelectedState.name,
    };
    if (skillChecklistSelectedState.required === "0") {
      if (!saveAndUploadLoading) {
        handleCloseProgress();
        const onBoardingCompletedStepsFinal = onBoardingCompletedSteps;
        onBoardingCompletedStepsFinal[4] = true;
        updateCompleteFlagAction(onBoardingCompletedStepsFinal);
        updateActiveFlagAction(4);
        if (navigateToMyTask) {
          segmentEvent(completedChecklist.name);
          history.push({
            pathname: LATEST_TASKS,
            state: { completedChecklist },
          });
        } else {
          handlePageToRedirect();
        }
      }
    } else {
      // eslint-disable-next-line no-lonely-if
      if (checkSkillChecklistCompleteStatus()) {
        const onBoardingCompletedStepsFinal = onBoardingCompletedSteps;
        onBoardingCompletedStepsFinal[4] = true;
        updateCompleteFlagAction(onBoardingCompletedStepsFinal);
        updateActiveFlagAction(4);
        if (navigateToMyTask) {
          segmentEvent(completedChecklist.name);
          history.push({
            pathname: LATEST_TASKS,
            state: { completedChecklist },
          });
        } else {
          handlePageToRedirect();
        }
      } else if (navigateToMyTask) {
        segmentEvent(completedChecklist.name);
        history.push({
          pathname: LATEST_TASKS,
          state: { completedChecklist },
        });
      } else {
        history.push(SKILL_CHECKLIST_OVERVIEW_PAGE, { source, isUpdateNowClicked, image });
      }
    }

    const event = {
      category: t("googleAnalytics.category.onboarding"),
      action: t("googleAnalytics.action.submitSkillsChecklist"),
      label: t("googleAnalytics.label.skillsChecklist"),
    };
    const dimensions = {
      dimension9:
        candidateSpecialties && candidateSpecialties.length > 0 ? candidateSpecialties[0] : null,
    };
    Analytics.sendPageview(history.location, dimensions);
    Analytics.sendEvent(event);
    const profileProgress = profileProgressInPercentage();
    const segmentEventName = t("segment.profileUpdated");
    const lineItem: string[] = [];
    if (skillChecklistSelectedState && skillChecklistSelectedState.name) {
      lineItem.push(skillChecklistSelectedState.name);
    }
    const segmentEventProps = {
      section: t("myProfileSubNav.skillChecklist"),
      progress: profileProgress,
      origin: document.referrer,
      lineItem,
    };
    Analytics.segmentTrackEvent(segmentEventName, segmentEventProps);
  };

  function formatDropdownValue(skillChecklistGroupSkill) {
    if (skillChecklistGroupSkill.value) {
      if (updatedSkillsChecklist && updatedSkillsChecklist.name === skillChecklistGroupSkill.name) {
        return skillChecklistGroupSkill.value;
      }
      return skillChecklistGroupSkill.value;
    }
    return t("onBoarding.skillChecklist.addRating");
  }
  const renderGroupSkills = (skillChecklistsGroupName) => {
    const skillChecklistsGroups = skillChecklistSelectedState.groups;
    let skillChecklistGroupSkills: Skill[] = [];
    for (let i = 0; i < skillChecklistsGroups.length; i++) {
      if (skillChecklistsGroups[`${i}`].name === skillChecklistsGroupName) {
        skillChecklistGroupSkills = skillChecklistsGroups[`${i}`].skills;
      }
    }
    return skillChecklistGroupSkills.map((skillChecklistGroupSkill) => (
      <Row
        className={
          showErrMsg && !skillChecklistGroupSkill.value
            ? `${style.onBSkillCheckBtmBorder} ${style.onBSkillCheckError}`
            : style.onBSkillCheckBtmBorder
        }
        onClick={() =>
          handleOpenRate({
            skillCheckListGroupSkillAsProp: skillChecklistGroupSkill,
            skillChecklistGroupName: skillChecklistsGroupName,
            isBulkUpdate: false,
            shouldOpen: true,
          })
        }
        key={skillChecklistGroupSkill.id}
        id={skillChecklistGroupSkill.id}
      >
        <Col sm={12} md={12} lg={12} xs={12}>
          <div className={style.onBSubSkill}>
            {skillChecklistGroupSkill.name}
            {skillChecklistGroupSkill.value ? (
              ""
            ) : (
              <span className={style.onBSkillsGroupErr}>
                {!isMobile ? <>&nbsp; &nbsp;</> : ""}
                {showErrMsg ? t("onBoarding.skillChecklist.skillsIncompleteErrorMsg") : ""}
              </span>
            )}
          </div>
        </Col>
        <Col sm={12} md={12} lg={12} xs={12}>
          {isMobile ? (
            <>
              <span
                className={
                  formatDropdownValue(skillChecklistGroupSkill) ===
                  t("onBoarding.skillChecklist.addRating")
                    ? style.onBSkillAddRatingMobile
                    : style.onBSkillRatingMobColor
                }
              >
                {formatDropdownValue(skillChecklistGroupSkill)}
              </span>
              <ArrowDropDownIcon className={style.onBSkillRatingDropDownIcon} />
            </>
          ) : (
            <OnBoardingSkillRadio
              skillUpdated={handleSkillUpdated}
              skillChecklistGroupName={skillChecklistsGroupName}
              skillCheckListGroupSkillAsProp={skillChecklistGroupSkill}
              isBulkUpdate={false}
            />
          )}
        </Col>
      </Row>
    ));
  };

  const renderGroups = () => {
    const skillChecklistsGroups = skillChecklistSelectedState.groups;
    return skillChecklistsGroups.map((skillChecklistsGroup, index) => (
      <Accordion
        expanded={expanded === skillChecklistsGroup.name}
        onChange={handleChange(skillChecklistsGroup.name, index)}
        key={skillChecklistsGroup.id}
        id={skillChecklistsGroup.name}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          className={style.onBSkillCheckAccorIcon}
        >
          <Typography className={style.onBMainSkill}>{skillChecklistsGroup.name}</Typography>
          &nbsp; &nbsp;&nbsp;&nbsp;
          {completedList?.includes(skillChecklistsGroup.name) ||
          completedList?.includes(skillChecklistsGroup.name) ? (
            <div className={style.onBCompleteStatus}>{t("onBoarding.skillChecklist.complete")}</div>
          ) : (
            <div className={style.onBIncompleteStatus}>
              {t("onBoarding.skillChecklist.inComplete")}
            </div>
          )}
        </AccordionSummary>
        <AccordionDetails>
          <Container>
            <Row
              className={`${style.onBQuickSetFirstColumn} ${style.onBSkillCheckBtmBorder}`}
              onClick={
                isMobile
                  ? () =>
                      handleOpenRate({
                        skillChecklistGroupName: skillChecklistsGroup.name,
                        isBulkUpdate: true,
                        shouldOpen: true,
                      })
                  : () => reRenderComponent()
              }
            >
              <Col sm={12} md={12} lg={12} xs={12}>
                <div className={style.onBSubSkill}>
                  {t("onBoarding.skillChecklist.quickSetFeature")}
                </div>
              </Col>
              <Col sm={12} md={12} lg={12} xs={12}>
                {isMobile ? (
                  <>
                    <span
                      className={
                        bulkUpdateValue && bulkUpdateGroupName === skillChecklistsGroup.name
                          ? style.onBSkillRatingMobColor
                          : style.onBSkillAddRatingMobile
                      }
                    >
                      {bulkUpdateValue && bulkUpdateGroupName === skillChecklistsGroup.name
                        ? bulkUpdateValue
                        : t("onBoarding.skillChecklist.addRating")}
                    </span>
                    <ArrowDropDownIcon className={style.onBSkillRatingDropDownIcon} />
                  </>
                ) : (
                  <OnBoardingSkillRadio
                    isBulkUpdate
                    skillUpdated={handleSkillUpdated}
                    deselectBulkUpdate={deselectBulkUpdate}
                    skillChecklistGroupName={skillChecklistsGroup.name}
                    onClick={() => reRenderComponent()}
                  />
                )}
              </Col>
            </Row>
            <div>{renderGroupSkills(skillChecklistsGroup.name)}</div>
          </Container>
        </AccordionDetails>
      </Accordion>
    ));
  };

  return (
    <div key={`completedList-${completedList}`}>
      <Container fluid className="myProfileWrap">
        <Row>
          <MyProfileNavWrapper />
          <div className="myProfilePageWrap">
            <Container fluid className={style.onBSkillListWrap}>
              <Container>
                <Row>
                  <Col sm={12} xs={12} md={12} lg={12}>
                    <div className="pt-4">
                      <div>
                        <TertiaryButton onClick={handleOpen} size="medium" component="button">
                          <ArrowBackIcon className={style.onBBackIcon} />
                          &nbsp;{t("onBoarding.skillChecklist.goBack")}
                        </TertiaryButton>
                      </div>
                      <Modal
                        className={classes.modal}
                        open={open}
                        onClose={handleClose}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        data-testid="modal"
                        BackdropProps={{
                          timeout: 500,
                        }}
                      >
                        <Fade in={open}>
                          <div className={style.onBSkillsCheckPopUpSkillRate}>
                            <div className={`${style.onBSkillCheckPopUpTxt} ${style.textCenter}`}>
                              {t("onBoarding.hey")} {firstName}
                              {t("onBoarding.skillChecklist.backButtonText")}
                            </div>
                            <br />
                            <div
                              className={`${style.textCenter} d-flex justify-content-center flex-sm-row flex-column`}
                            >
                              <TertiaryButton
                                className={`${style.fullWidth} mr-sm-2 mr-0 mb-3 mb-sm-0`}
                                onClick={goBackToSkillsOverview}
                                component="button"
                              >
                                {t("onBoarding.skillChecklist.saveAndContinue")}
                              </TertiaryButton>
                              <PrimaryButton
                                className={`${style.fullWidth} ${style.provideSkillBorder}`}
                                onClick={handleClose}
                                component="button"
                              >
                                {t("onBoarding.skillChecklist.provideSkills")}
                              </PrimaryButton>
                            </div>
                          </div>
                        </Fade>
                      </Modal>
                      <Modal
                        className={classes.modal}
                        open={openRate}
                        onClose={handleCloseRate}
                        onClick={closeRadioPopup}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                          timeout: 500,
                        }}
                      >
                        <Fade in={openRate}>
                          {!isMobile ? (
                            <div className={style.onBSkillsCheckPopUpDesk}>
                              <div className={`${style.textCenter} ${style.onBSkillRatingDesk}`}>
                                {t("onBoarding.skillChecklist.quickSetPopupMsg")}
                                <b>Some Experience</b> {t("onBoarding.skillChecklist.inThisGroup")}
                              </div>
                              <br />
                              <div className={style.textCenter}>
                                {" "}
                                <SecondaryButton
                                  className={style.onBSkillYesBtn}
                                  component="button"
                                >
                                  {t("onBoarding.skillChecklist.yesBtnLbl")}
                                </SecondaryButton>
                                &nbsp;&nbsp;
                                <PrimaryButton className={style.onBNoBtn} component="button">
                                  {t("onBoarding.skillChecklist.noBtnLbl")}
                                </PrimaryButton>
                              </div>
                            </div>
                          ) : (
                            <div className={style.onBSkillsCheckPopUp}>
                              <OnBoardingSkillRadio
                                skillUpdated={handleSkillUpdated}
                                isBulkUpdate={openRateSelectedItem?.isBulkUpdate}
                                confirmBulkUpdate={confirmBulkUpdate}
                                deselectBulkUpdate={deselectBulkUpdate}
                                skillChecklistGroupName={
                                  openRateSelectedItem?.skillChecklistGroupName
                                }
                                skillCheckListGroupSkillAsProp={
                                  openRateSelectedItem?.skillCheckListGroupSkillAsProp
                                }
                              />
                            </div>
                          )}
                        </Fade>
                      </Modal>
                      <Modal
                        className={classes.modal}
                        open={openProgress}
                        onClose={handleCloseProgress}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                          timeout: 500,
                        }}
                      >
                        <Fade in={openProgress}>
                          <div>
                            <CircularProgressWithLabel loaderText={loaderLabel} />
                          </div>
                        </Fade>
                      </Modal>
                      <h3 className={style.onBSkillCheckTitle}>
                        {skillChecklistSelectedState
                          ? skillChecklistSelectedState.name
                          : "No Skill Checklist Found!"}
                      </h3>
                      <div className={style.onBWorkExpText}>
                        {t("onBoarding.skillChecklist.proficiencyExplanation")}
                        <br /> <br />
                        <div className={style.onBSkillRatingKeys}>
                          <div className={style.onBSkillRatingHead}>
                            {t("onBoarding.skillChecklist.ratingKeys")}
                          </div>
                          <div className={`${style.onBSkillRatingKeysList} mt-2`}>
                            <b>{t("onBoarding.skillChecklist.notApplicable")}</b>
                          </div>
                          <div className={style.onBSkillRatingKeysList}>
                            <b>{t("onBoarding.skillChecklist.noExperience")}</b>
                          </div>
                          <div className={style.onBSkillRatingKeysList}>
                            <b>{t("onBoarding.skillChecklist.someExperience")}</b>
                            {t("onBoarding.skillChecklist.someExperienceText")}
                          </div>
                          <div className={style.onBSkillRatingKeysList}>
                            <b>{t("onBoarding.skillChecklist.intermittentExperience")}</b>
                            {t("onBoarding.skillChecklist.intermittentExperienceText")}
                          </div>
                          <div className={style.onBSkillRatingKeysList}>
                            <b>{t("onBoarding.skillChecklist.experienced")}</b>
                            {t("onBoarding.skillChecklist.experiencedText")}
                          </div>
                          <div className={style.onBSkillRatingKeysList}>
                            <b>{t("onBoarding.skillChecklist.veryExperienced")}</b>
                            {t("onBoarding.skillChecklist.veryExperiencedText")}
                          </div>
                        </div>
                      </div>
                    </div>
                    <br />
                    <br />
                    <div className={`${style.onBSkillCheckAccordMain} ${classes.root}`}>
                      <div className={`${style.onBSkillRatingHead} mb-2`}>
                        {t("onBoarding.skillChecklist.skillsGroup")}
                      </div>
                      {skillChecklistSelectedState ? renderGroups() : "No Skill Checklist found"}
                    </div>
                    <Row>
                      <Col sm={12} md={12} lg={12} xs={12} className={style.textCenter}>
                        <br />
                        <OnBoardingSignature
                          innerRef={signatureRef}
                          skillUpdated={handleSkillUpdated}
                          showSaveBtn={handleButtonRendering}
                        />
                        {showSaveAndUpload ? (
                          <div className="d-flex justify-content-center">
                            <SecondaryButton
                              onClick={handleSaveButton}
                              className={style.saveandUpload}
                              component="button"
                            >
                              {t("onBoarding.skillChecklist.saveAndUpload")}
                            </SecondaryButton>
                          </div>
                        ) : null}
                      </Col>
                    </Row>
                    <div className={style.onBSaveandUpload}>
                      <TertiaryButton onClick={handleOpen} size="medium" component="button">
                        <ArrowBackIcon className={style.onBBackIcon} />
                        &nbsp;{t("onBoarding.skillChecklist.goBack")}
                      </TertiaryButton>
                    </div>
                  </Col>
                </Row>
              </Container>
            </Container>
          </div>
          {/* Parent Column Ends  */}
        </Row>
      </Container>
    </div>
  );
};

const mapStateToProps = (state) => ({
  skillChecklistSelected: state.onBoarding.skillChecklistSelected,
  skillsChecklistGroupSkills: state.onBoarding.skillsChecklistGroupSkills,
  completedSkillsCheckList: state.onBoarding.completedSkillsCheckList,
  firstName: state.auth.firstName,
  company: state.company.company,
  email: state.auth.email,
  isSkillCompleted: state.onBoarding.selectedSkillCompleted,
  applicantId: state.candidate.applicantId,
  userId: state.auth.userId,
  candidateSpecialties: state.auth.candidateSpecialties,
  candidateId: state.auth.candidateId,
  saveAndUploadLoading: state.onBoarding.loading,
  updatedSkillsChecklist: state.onBoarding.updatedSkillsChecklist,
  onBoardingCompletedSteps: state.onBoarding.completedSteps,
  skillChecklists: state.onBoarding.skillChecklists,
  saveSkillChecklistResponse: state.onBoarding.saveSkillChecklistResponse,
  onboardingBasicInfo: state.onBoarding.basicInfo,
});
const mapDispatchToProps = (dispatch) => ({
  saveSkillChecklistSelectedAction: (skillSelected) =>
    dispatch(saveSkillChecklistSelected(skillSelected)),
  saveOptionalSkillChecklistAction: (reqObj, onboardingBasicInfo) => dispatch(saveOptionalSkillChecklist(reqObj, onboardingBasicInfo)),
  updateCompleteFlagAction: (completedSteps) => dispatch(updateCompleteFlag(completedSteps)),
  updateActiveFlagAction: (data) => dispatch(updateActiveFlag(data)),
  saveOnboardingComplete: (reqObj, triggeringPoint, skillChecklistCompleteStatus) =>
    dispatch(saveOnboardingComplete(reqObj, triggeringPoint, skillChecklistCompleteStatus)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OnBoardingSkillChecklistNew);
