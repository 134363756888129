function IsJsonString(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

const getItem = (key, initial) => {
  try {
    const val = localStorage.getItem(key);
    return val && IsJsonString(val) ? JSON.parse(val) : initial;
  } catch (error) {
    return initial;
  }
};
export default { getItem };
