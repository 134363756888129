import i18n from "../translations/i18n";
import config from "../config";

const ThankYouReferenceConstraint = {
  firstName: {
    presence: { allowEmpty: false },
    format: {
      pattern: config.REFERENCE_SURVEY_NAME_REGEX,
      message: i18n.t("onBoarding.errorMessage.refName"),
    },
    length: {
      maximum: 50,
      message: i18n.t("onBoarding.errorMessage.refNameLength"),
    },
  },
  lastName: {
    presence: { allowEmpty: false },
    format: {
      pattern: config.REFERENCE_SURVEY_NAME_REGEX,
      message: i18n.t("onBoarding.errorMessage.refName"),
    },
    length: {
      maximum: 50,
      message: i18n.t("onBoarding.errorMessage.refNameLength"),
    },
  },
  emailAddress: {
    presence: { allowEmpty: false },
    format: {
      pattern: config.EMAIL_REGEX,
      message: i18n.t("onBoarding.errorMessage.email"),
    },
  },
  jobType: {
    presence: { allowEmpty: false },
  },
  profession: {
    presence: { allowEmpty: false },
  },
  specialty: {
    presence: { allowEmpty: false },
  },
  yrsOfExp: {
    presence: { allowEmpty: false },
  },
  date: {
    startDateSurveyCustom: true,
  },
  phoneNumber: {
    presence: { allowEmpty: false },
    format: {
      pattern: config.PHONE_REGEX,
      message: i18n.t("onBoarding.errorMessage.phone"),
    },
  },
  zip: {
    format: {
      pattern: config.ZIP_REGEX,
      message: i18n.t("onBoarding.errorMessage.zip"),
    },
  },
};
export default { ThankYouReferenceConstraint };
