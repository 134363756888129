import { useEffect, useMemo, useRef, useState } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { connect, useSelector } from "react-redux";
import moment from "moment";
import Modal from "@material-ui/core/Modal";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { useTranslation, withTranslation } from "react-i18next";
import { useHistory, withRouter } from "react-router-dom";
import AddIcon from "@material-ui/icons/Add";
import { SKILL_CHECKLIST_OVERVIEW_PAGE } from "src/router/routes";
import { Typography } from "@mui/material";
import { getAccessToken } from "axios-jwt";
import { updateActiveFlag, fetchCandidateCredentials } from "src/redux/onBoarding/action";
import { fetchAccessToken } from "src/redux/candidate/action";
import analyticsSegmentIdentify from "src/services/SegmentIdentify";
import PrimaryButton from "src/components/common/Buttons/PrimaryButton";
import SecondaryButton from "src/components/common/Buttons/SecondaryButton";
import { getCredentialsTypeQuery } from "src/employers/reference/GraphQL/graph.query";
import { graphqlRequest as request } from "src/services/GraphQL/GraphQL";
import CertificationsLicensesForm from "src/components/common/CertificationsLicenses/CertificationAndLicensesForm";
import CredentialItem, {
  CandidateCredentialData,
} from "src/components/common/OnBoarding/CredentialItem";
import COMPLETE_STATUS_LIST from "src/constants/certificationLicenses";
import "src/components/common/OnBoarding/OnBoardingStyles/CertificationLicenses.scss";
import GenericErrorMessagePopup from "src/components/common/OnBoarding/GenericErrorMessagePopup";
import TertiaryButton from "src/components/common/Buttons/TertiaryButton";
import SnackbarSuccess from "src/components/common/SnackbarSuccess/SnackbarSuccess";
import {
  SaveCandidateCredentials,
  UpdateCandidateCredentials,
} from "src/services/GraphQL/graph.query";
import { APIMethods } from "src/constants/constants";
import LoaderSpinner from "src/components/common/LoaderSpinner/LoaderSpinner";
import ExitPrompt from "src/components/common/ExitPrompt/ExitPrompt";
import Analytics from "src/services/Analytics";
import SnackbarReturnToSource from "src/components/common/SnackbarReturnToSource/SnackbarReturnToSource";
import uniqBy from "lodash/uniqBy";
import MyProfileNavWrapper from "./MyProfileNavWrapper";

type credentialsTypes = {
  id: number;
  label: string;
  defaultExpirationReminderDays: string;
  expirationDateOptional: boolean;
  hasLicenseNumber: string;
  isTwoSided: boolean;
  requireCompleteDate: string;
};

type queryOption = {
  credentialsCategory: Array<string>;
  size: string;
};

export interface CertificationsLicensesReduxState {
  company: {
    company: {
      code: number;
      id: string;
    };
  };
  auth: {
    email: string;
    userId: string;
    firstName: string;
    lastName: string;
    phone: number;
  };
  consultant: {
    recruiterDetails: string;
  };
  onBoarding: {
    specialtiesAndEducation: string;
    basicInfo: string;
    candidateCredentials: any;
  };
  candidate: {
    atsBullhornCandidateId: string;
  };
}

export interface CredentialTypeResponse {
  getCredentialsType: {
    isSuccess: boolean;
    code: number;
    developerMessage: string;
    userMessage: string;
    credentialsTypes: Array<credentialsTypes>;
    count: number;
  };
}

interface CertificationsLicensesPageProps {
  updateActiveFlag: Function;
  fetchCredentials: Function;
}

export interface SaveCredentialsResponse {
  saveCandidateCredentials: {
    status: string;
    statusCode: number;
    developerMessage: string;
    userMessage: string;
  };
}

export interface UpdateCredentialsResponse {
  updateCandidateCredentials: {
    status: string;
    statusCode: number;
    developerMessage: string;
    userMessage: string;
  };
}

export const CertificationsLicensesPage = (props: CertificationsLicensesPageProps) => {
  const history = useHistory();
  const [, setShowExitPrompt] = ExitPrompt(false);
  const { t } = useTranslation();
  const candidateCredentials = useSelector(
    (state: CertificationsLicensesReduxState) => state.onBoarding.candidateCredentials
  );

  const obj = {
    email: useSelector((state: CertificationsLicensesReduxState) => state.auth.email),
    company: useSelector((state: CertificationsLicensesReduxState) => state.company.company),
    userId: useSelector((state: CertificationsLicensesReduxState) => state.auth.userId),
    firstName: useSelector((state: CertificationsLicensesReduxState) => state.auth.firstName),
    lastName: useSelector((state: CertificationsLicensesReduxState) => state.auth.lastName),
    phone: useSelector((state: CertificationsLicensesReduxState) => state.auth.phone),
    recruiterDetails: useSelector(
      (state: CertificationsLicensesReduxState) => state.consultant.recruiterDetails
    ),
    onboardingSpecialtiesAndEducation: useSelector(
      (state: CertificationsLicensesReduxState) => state.onBoarding.specialtiesAndEducation
    ),
    onboardingBasicInfo: useSelector(
      (state: CertificationsLicensesReduxState) => state.onBoarding.basicInfo
    ),
    companyId: useSelector((state: CertificationsLicensesReduxState) => state.company.company.id),
    atsBullhornCandidateId: useSelector(
      (state: CertificationsLicensesReduxState) => state.candidate.atsBullhornCandidateId
    ),
  };

  const [authToken, setAuthToken] = useState(getAccessToken());
  const [isOpen, setIsOpen] = useState(false);
  const [credentialTypeList, setCredentialTypeList] = useState<credentialsTypes[]>([]);
  const [isError, setIsError] = useState(false);
  const [isConfirm, setIsConfirm] = useState(true);
  const [toast, setToast] = useState<null | string>(null);
  const [candidateCredential, setCandidateCredential] = useState<null | CandidateCredentialData>(
    null
  );
  const [displaySaveSpinner, setDisplaySaveSpinner] = useState(false);
  const [mode, setMode] = useState<string>(APIMethods.ADD);
  const { updateActiveFlag: updateActiveFlagProp, fetchCredentials } = props;
  updateActiveFlagProp(5);
  const [userMadeChanges, setUserMadeChanges] = useState(false);
  const loc = JSON.parse(JSON.stringify(history.location?.state || ""));
  const { source, isUpdateNowClicked, image } = loc;

  const handleEdit = (recId: number) => {
    setMode(APIMethods.EDIT);
    const candidateCredentialRecords = candidateCredentials.find((item) => item.id === recId);
    if (candidateCredentialRecords) setCandidateCredential(candidateCredentialRecords);
    setToast(null);
    setIsOpen(true);
  };

  const reFetchCredentials = () => {
    fetchCredentials(obj.atsBullhornCandidateId, obj.companyId, authToken);
  };

  const segmentCredentialEvent = (
    eventName: "Added" | "Deleted" | "Downloaded",
    eventProps: any
  ) => {
    const segmentEventName = t(`segment.credential${eventName}`);
    Analytics.segmentTrackEvent(segmentEventName, eventProps);
  };

  const credentialsToDisplay = uniqBy(candidateCredentials as CandidateCredentialData[], "name")?.map(
    ({
      id,
      name,
      status,
      dateExpiration,
      dateCompleted,
      certificationFileAttachments,
      licenseNumber,
    }) => {
      const isStatusComplete = COMPLETE_STATUS_LIST.includes(status?.toString()?.toLowerCase());
      const dateExpirationDate = new Date(dateExpiration);
      const dateCompletedDate = new Date(dateCompleted);

      const dateFormat = t("certificationAndLicenses.dateFormat");
      const statusMapped = isStatusComplete
        ? t("certificationAndLicenses.complete")
        : t("certificationAndLicenses.incomplete");
      const statusClassName = isStatusComplete ? "onBSpeCompleteStatus" : "onBSpeInCompleteStatus";

      const candidateCredentialDataRow = {
        id,
        name,
        dateExpiration: dateExpiration && moment(dateExpirationDate).format(dateFormat).toString(),
        dateCompleted: dateCompleted && moment(dateCompletedDate).format(dateFormat).toString(),
        status: status === "Expired" ? t("certificationAndLicenses.expired") : statusMapped,
        statusClassName,
        certificationFileAttachments,
        licenseNumber,
      };

      return (
        <CredentialItem
          candidateCredentialData={candidateCredentialDataRow}
          bhCandidateId={parseInt(obj.atsBullhornCandidateId, 10)}
          companyId={parseInt(obj.companyId, 10)}
          key={`${id}-${name}-${status}`}
          handleEdit={(recId: number) => handleEdit(recId)}
          reFetchCredentials={reFetchCredentials}
          segmentCredentialEvent={segmentCredentialEvent}
        />
      );
    }
  );

  const noRecordFoundMessage = (
    <div className="noRecordFoundMessage">{t("certificationAndLicenses.noRecordFoundMessage")}</div>
  );
  const columnHeaderClass = `${
    candidateCredentials?.length ? "columnHeads" : "certLicenNoRecords"
  }`;
  const tableNoRecords = `${candidateCredentials?.length ? "" : "certLicenNoRecords"}`;

  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    }, [value]);
    return ref.current;
  };
  const prevEmail = usePrevious(obj.email);
  const prevRecruiterDetails = usePrevious(obj.recruiterDetails);

  useEffect(() => setShowExitPrompt(false), []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setAuthToken(getAccessToken());
  }, [obj]);

  useEffect(() => {
    if (
      obj &&
      obj.userId &&
      (obj.email !== prevEmail || obj.recruiterDetails !== prevRecruiterDetails)
    ) {
      analyticsSegmentIdentify(obj);
    }
    updateActiveFlag(-1);
  }, [prevEmail, obj]);

  const handleOpen = () => {
    setToast(null);
    setIsOpen(true);
    setMode(APIMethods.ADD);
    setCandidateCredential(null);
  };

  const handleClose = (reason) => {
    if ((reason && reason === "backdropClick") || reason === "escapeKeyDown") return;
    if (userMadeChanges) setIsConfirm(!isConfirm);
    else {
      setIsOpen(false);
      setIsError(false);
      setIsConfirm(true);
    }
  };

  const handleConfirmation = () => {
    setIsConfirm(!isConfirm);
  };

  const getCredentialsType = async () => {
    const query: queryOption = {
      credentialsCategory: ["Certifications", "Licensure"],
      size: "500",
    };
    return new Promise((_, reject) => {
      request(getCredentialsTypeQuery, query, authToken)
        .then((res: CredentialTypeResponse) => {
          const items: credentialsTypes[] = res?.getCredentialsType?.credentialsTypes;
          if (items?.length > 0) {
            setCredentialTypeList(items);
            setIsError(false);
          }
        })
        .catch((error) => {
          reject(error);
          setIsError(true);
        });
    });
  };

  const handleBackBtnClick = async () => {
    updateActiveFlagProp(4);
    history.push(SKILL_CHECKLIST_OVERVIEW_PAGE);
  };

  useEffect(() => {
    try {
      if (credentialTypeList?.length === 0 && getAccessToken() && !isError) {
        getCredentialsType();
      }
    } catch (error) {
      setCredentialTypeList([]);
    }
  }, [obj, credentialTypeList, getAccessToken()]);

  const saveNewCredentials = async (
    certificationContent,
    certificationFiles,
    type,
    certificationId,
    certificationFileIds
  ) => {
    try {
      const token = authToken;
      const vars: any = {
        bhCandidateId: Number(obj.atsBullhornCandidateId),
        companyId: Number(obj.companyId),
        certificationContent,
        certificationFiles,
        candidateCertId: certificationId,
        certificationFileIds: certificationFileIds.filter((item) => item),
      };

      if (APIMethods.ADD === type) {
        delete vars.certificationId;
        delete vars.candidateCertId;
        setDisplaySaveSpinner(true);
        const {
          saveCandidateCredentials: { statusCode, userMessage },
        }: SaveCredentialsResponse = await request(SaveCandidateCredentials, vars, token);

        setIsOpen(false);
        setDisplaySaveSpinner(false);
        if (statusCode !== 200) {
          setIsError(true);
          console.error(userMessage);
        } else {
          segmentCredentialEvent("Added", { credentialName: certificationContent.name });
          fetchCredentials(obj.atsBullhornCandidateId, obj.companyId, token);
          setToast(
            `${certificationContent.name} ${t("certificationAndLicenses.successfullyUploaded")}`
          );
        }
      }

      if (APIMethods.EDIT === type) {
        setDisplaySaveSpinner(true);
        const {
          updateCandidateCredentials: { statusCode, userMessage },
        }: UpdateCredentialsResponse = await request(UpdateCandidateCredentials, vars, token);

        setDisplaySaveSpinner(false);
        if (statusCode !== 200) {
          setIsError(true);
          console.error(userMessage);
        } else {
          fetchCredentials(obj.atsBullhornCandidateId, obj.companyId, token);
          setToast(
            `${certificationContent.name} ${t("certificationAndLicenses.successfullyUploaded")}`
          );
        }
      }

      setMode(APIMethods.ADD);
    } catch (err) {
      setDisplaySaveSpinner(false);
      setIsError(true);
      console.error(err);
    } finally {
      setIsOpen(false);
      setUserMadeChanges(false);
      setShowExitPrompt(false);
    }
  };

  const userMadeChangesCallback = () => {
    setShowExitPrompt(true);
    setUserMadeChanges(true);
  };

  const handleDiscardChanges = () => {
    setUserMadeChanges(false);
    setIsOpen(false);
    setIsError(false);
    setIsConfirm(true);
    setCandidateCredential(null);
    setShowExitPrompt(false);
  };

  // This constant work towards filtering the already added credentials from the list
  const filterCredentialTypeList: credentialsTypes[] = useMemo(() => {
    if (candidateCredentials?.length > 0 && credentialTypeList?.length > 0 && candidateCredential === null) {
      const candidateCredentialsNameSet = new Set(
        candidateCredentials.map((item: CandidateCredentialData) => item?.name)
      );
      return credentialTypeList?.filter(
        (credentialItem) => !candidateCredentialsNameSet.has(credentialItem?.label)
      );
    }
    return credentialTypeList;
  }, [candidateCredentials, credentialTypeList, candidateCredential]);

  return (
    <Container fluid className="myProfileWrap">
      {isUpdateNowClicked && (
        <div className="returnToSourceWrapper">
          <SnackbarReturnToSource
            topRight
            message={t("QuickApplyCard.QuickApplyBackToJobDetail")}
            source={source}
            image={image}
          />
        </div>
      )}
      <Row>
        <MyProfileNavWrapper />
        <div className="myProfilePageWrap">
          <Container>
            <Row className="certLicenRow">
              <Col sm={12} xs={12} md={12} lg={12} className="onBCertificationAndLicensesBanner">
                <div className="onBCertificationAndLicensesBannerText">
                  <Typography className="bannerHeading">
                    {t("certificationAndLicenses.certificationsAndLicensesHeading")}
                  </Typography>
                  <Typography className="bannerSubHeading">
                    {t("certificationAndLicenses.certificationsAndLicensesSubHeading")}
                  </Typography>
                </div>
              </Col>
            </Row>
            <Row className="certLicenRow">
              <PrimaryButton
                component="button"
                size="medium"
                className="onBFullWidthMob addCredentialsButtonBorder"
                onClick={handleOpen}
              >
                <AddIcon fontSize="small" className="iconPaddingBottom" />
                <span className="addCredentialsButtonText">
                  {t("certificationAndLicenses.addCredentialsButtonText")}
                </span>
              </PrimaryButton>
            </Row>
            <Row className="onBoardingTable">
              <Col sm={12} md={12} lg={12} xs={12}>
                <div className={`tableWrapper ${tableNoRecords}`}>
                  <div className="table tableCollapse">
                    <div className="tableRow tableRowHead">
                      <div className={`tableCell certLicenStatusColumn ${columnHeaderClass}`}>
                        {t("certificationAndLicenses.status")}
                      </div>
                      <div className={`tableCell certLicenNameColumn ${columnHeaderClass}`}>
                        {t("certificationAndLicenses.name")}
                      </div>
                      <div className={`tableCell certLicenUploadDateColumn ${columnHeaderClass}`}>
                        {t("certificationAndLicenses.uploadDate")}
                      </div>
                      <div
                        className={`tableCell certLicenExpirationDateColumn ${columnHeaderClass}`}
                      >
                        {t("certificationAndLicenses.expirationDate")}
                      </div>
                      <div className="certLicenActionColumn">
                        <div className={`tableCell ${columnHeaderClass}`}>
                          {t("certificationAndLicenses.action")}
                        </div>
                      </div>
                    </div>
                    {credentialsToDisplay?.length ? credentialsToDisplay : noRecordFoundMessage}
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col sm={12} xs={12} md={12} lg={12}>
                <div className="onBNextBtn d-flex">
                  <TertiaryButton
                    component="button"
                    size="medium"
                    className="outlineBtnColor"
                    onClick={handleBackBtnClick}
                  >
                    {t("onBoarding.workExp.backBtnLbl")}
                  </TertiaryButton>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </Row>
      <LoaderSpinner isOpen={displaySaveSpinner} size={70} />
      <Modal
        className="cerAndLicModal"
        open={isOpen && !displaySaveSpinner}
        onClose={(_, reason) => handleClose(reason)}
      >
        <div className="certAndLic">
          <IconButton
            aria-label="Close"
            onClick={handleClose}
            style={{ position: "absolute", right: 8, top: 8, zIndex: 9 }}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
          <div hidden={!isConfirm}>
            <CertificationsLicensesForm
              credentialTypeList={filterCredentialTypeList}
              saveNewCredentials={saveNewCredentials}
              candidateCredential={candidateCredential}
              apiMethod={mode}
              userMadeChangesCallback={userMadeChangesCallback}
            />
          </div>
          <div className="confirmationBox" hidden={isConfirm}>
            <div className="confirmTitle">{t("certificationAndLicenses.unsavedChanges")}</div>
            <div className="confirmSubTitle">{t("certificationAndLicenses.pleaseExit")}</div>
            <SecondaryButton
              component="button"
              size="medium"
              className="stayOnPage"
              onClick={handleConfirmation}
            >
              <span>{t("certificationAndLicenses.stayOnPage")}</span>
            </SecondaryButton>
            <PrimaryButton
              component="button"
              size="medium"
              className="discard"
              onClick={handleDiscardChanges}
            >
              <span>{t("certificationAndLicenses.exitAndDiscard")}</span>
            </PrimaryButton>
          </div>
        </div>
      </Modal>
      {isError && (
        <GenericErrorMessagePopup
          informativeMessage={t("genericError.informativeMessage")}
          tryAgainMessage={t("genericError.tryAgainMessage")}
          callBack={handleClose}
        />
      )}
      {toast && (
        <span className="createPasswordSuccess certLic">
          <SnackbarSuccess message={toast} topRight />
        </span>
      )}
    </Container>
  );
};

const mapStateToProps = (state: any) => ({
  applicantId: state.candidate.applicantId,
  isCandidateListEmpty: state.auth.isCandidateListEmpty,
  company: state.company.company,
  useProfileIframe: state.auth.useProfileIframe,
  email: state.auth.email,
  userId: state.auth.userId,
  firstName: state.auth.firstName,
  lastName: state.auth.lastName,
  phone: state.auth.phone,
  recruiterDetails: state.consultant.recruiterDetails,
  onboardingSpecialtiesAndEducation: state.onBoarding.specialtiesAndEducation,
  onboardingBasicInfo: state.onBoarding.basicInfo,
  candidateCredentials: state.onBoarding.candidateCredentials,
});

const mapDispatchToProps = (dispatch) => ({
  fetchAccessToken: (payload: any) => dispatch(fetchAccessToken(payload)),
  updateActiveFlag: (activeFlag: number) => dispatch(updateActiveFlag(activeFlag)),
  fetchCredentials: (bhId: number, companyId: number, authToken: string) =>
    dispatch(fetchCandidateCredentials(bhId, companyId, authToken)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withRouter(CertificationsLicensesPage as any)));
