import React, { useEffect } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useLocation, useHistory } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import moment from "moment";
import CustomNavMob from "../../../Navigation/CustomNavMob";
import PayrollNavV2 from "../PayrollNavV2";
import PayrollNoticeV2 from "../Timesheets/PayrollNoticeV2";
import { PAYROLL_PAYSTUBS } from "../../../../../router/routes";

function earnings(paycheck, props) {
  return (
    <Row>
      <Col sm={12} md={12} lg={12} xs={12}>
        <TableContainer component={Paper} className="payDetails payStubDetails">
          <Table aria-label="customized table">
            <TableHead>
              <TableRow className="payheading">
                <TableCell width="60%">
                  {props.t("payroll.paystubDetail.earnings.earnings")}
                </TableCell>
                <TableCell width="30%">{props.t("payroll.paystubDetail.earnings.hours")}</TableCell>
                <TableCell width="10%">
                  {props.t("payroll.paystubDetail.earnings.amount")}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paycheck.paycheckPayCodes.map((payCode) => (
                <TableRow className="payDetails">
                  <TableCell component="th" scope="row">
                    {payCode.description}
                  </TableCell>
                  <TableCell>{hourFormatter.format(payCode.hours || 0)}</TableCell>
                  <TableCell>{currencyFormatter.format(payCode.amount || 0)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableHead>
              <TableRow className="payheading">
                <TableCell>
                  {props.t("payroll.paystubDetail.earnings.totalHoursAndGrossPay")}
                </TableCell>
                <TableCell>{hourFormatter.format(paycheck.totalHours || 0)}</TableCell>
                <TableCell>{currencyFormatter.format(paycheck.grossPay || 0)}</TableCell>
              </TableRow>
            </TableHead>
          </Table>
        </TableContainer>
      </Col>
    </Row>
  );
}
function deductions(paycheck, props) {
  return (
    <Row>
      <Col sm={12} md={12} lg={12} xs={12}>
        <TableContainer component={Paper} className="payDetails payStubDetails">
          <Table aria-label="customized table">
            <TableHead>
              <TableRow className="payheading">
                <TableCell width="90%">
                  {props.t("payroll.paystubDetail.deductions.deductions")}
                </TableCell>
                <TableCell width="10%">
                  {props.t("payroll.paystubDetail.deductions.amount")}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paycheck.paycheckDeductions.map((deduction) => (
                <TableRow className="payDetails">
                  <TableCell component="th" scope="row">
                    {" "}
                    {deduction.description}{" "}
                  </TableCell>
                  <TableCell>{currencyFormatter.format(deduction.amount || 0)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableHead>
              <TableRow className="payheading">
                <TableCell>{props.t("payroll.paystubDetail.deductions.netPay")}</TableCell>
                <TableCell>{currencyFormatter.format(paycheck.netAmount || 0)}</TableCell>
              </TableRow>
            </TableHead>
          </Table>
        </TableContainer>
      </Col>
    </Row>
  );
}

function taxes(taxData, TotalTax, props) {
  return (
    <Row>
      <Col sm={12} md={12} lg={12} xs={12}>
        <TableContainer component={Paper} className="payDetails payStubDetails">
          <Table aria-label="customized table">
            <TableHead>
              <TableRow className="payheading">
                <TableCell width="90%">
                  {props.t("payroll.paystubDetail.taxes.taxDetails")}
                </TableCell>
                <TableCell width="10%">{props.t("payroll.paystubDetail.taxes.amount")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {taxData.map((row) => (
                <TableRow key={row.taxDetails} className="payDetails">
                  <TableCell component="th" scope="row">
                    {" "}
                    {row.taxDetails}{" "}
                  </TableCell>
                  <TableCell>{currencyFormatter.format(row.taxAmt || 0)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableHead>
              <TableRow className="payheading">
                <TableCell>{props.t("payroll.paystubDetail.taxes.totalTaxes")}</TableCell>
                <TableCell>{currencyFormatter.format(TotalTax || 0)}</TableCell>
              </TableRow>
            </TableHead>
          </Table>
        </TableContainer>
      </Col>
    </Row>
  );
}

function deposits(paycheck, props) {
  return (
    <Row className="">
      <Col sm={12} md={12} lg={12} xs={12}>
        <TableContainer component={Paper} className="payDetails payStubDetails">
          <Table aria-label="customized table" id="payTable">
            <TableHead>
              <TableRow className="payheading">
                <TableCell width="90%">
                  {props.t("payroll.paystubDetail.deposits.checkDeposits")}
                </TableCell>
                <TableCell width="10%">
                  {props.t("payroll.paystubDetail.deposits.amount")}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody className="tableRowHover">
              {paycheck.paycheckDeposits.map((deposit) => (
                <TableRow className="payDetails">
                  <TableCell component="th" scope="row">
                    {" "}
                    {deposit.description}{" "}
                  </TableCell>
                  <TableCell>{currencyFormatter.format(deposit.amount || 0)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Col>
    </Row>
  );
}

function addTaxDetailsData(paycheck, props) {
  const taxDetailsRow = [];
  if (paycheck.fedTax)
    taxDetailsRow.push({
      taxDetails: props.t("payroll.paystubDetail.taxDetails.federal"),
      taxAmt: paycheck.fedTax,
    });
  if (paycheck.socsecTax)
    taxDetailsRow.push({
      taxDetails: props.t("payroll.paystubDetail.taxDetails.socialSecurity"),
      taxAmt: paycheck.socsecTax,
    });
  if (paycheck.medTax)
    taxDetailsRow.push({
      taxDetails: props.t("payroll.paystubDetail.taxDetails.medicare"),
      taxAmt: paycheck.medTax,
    });
  if (paycheck.state1Tax)
    taxDetailsRow.push({
      taxDetails: props.t("payroll.paystubDetail.taxDetails.state1"),
      taxAmt: paycheck.state1Tax,
    });
  if (paycheck.city1Tax)
    taxDetailsRow.push({
      taxDetails: props.t("payroll.paystubDetail.taxDetails.city1"),
      taxAmt: paycheck.city1Tax,
    });
  if (paycheck.state2Tax)
    taxDetailsRow.push({
      taxDetails: props.t("payroll.paystubDetail.taxDetails.state2"),
      taxAmt: paycheck.state2Tax,
    });
  if (paycheck.city2Tax)
    taxDetailsRow.push({
      taxDetails: props.t("payroll.paystubDetail.taxDetails.city2"),
      taxAmt: paycheck.city2Tax,
    });
  if (paycheck.suisdiAmount)
    taxDetailsRow.push({
      taxDetails: props.t("payroll.paystubDetail.taxDetails.suisdi"),
      taxAmt: paycheck.suisdiAmount,
    });
  return taxDetailsRow;
}

function getHeading(props) {
  const payStubHeading = props.t("payroll.paystubDetail.payStubHeading");
  return payStubHeading;
}

const currencyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 2,
});

const hourFormatter = new Intl.NumberFormat("en-US", {
  minimumFractionDigits: 2,
});

function PaystubDetail(props) {
  const location = useLocation();
  const history = useHistory();
  useEffect(() => {}, [location]);
  const { paycheck } = location.state;
  const taxData = addTaxDetailsData(paycheck, props);
  let earning;
  let deduction;
  let tax;
  let deposit;
  if (paycheck.paycheckPayCodes) earning = earnings(paycheck, props);
  if (paycheck.paycheckDeductions) deduction = deductions(paycheck, props);
  if (taxData.length > 0) tax = taxes(taxData, paycheck.totalTax, props);
  if (paycheck.paycheckDeposits) deposit = deposits(paycheck, props);
  const heading = getHeading(props);
  const handleBackBtnClick = () => {
    history.push(PAYROLL_PAYSTUBS);
  };

  return (
    <Container fluid>
      <Row>
        <div id="paymentDeductionPg" className="payRollNavMargin">
          <div className="payrollNavWrap">
            {isMobile ? <CustomNavMob type="payroll" /> : <PayrollNavV2 />}
          </div>
          <div className="mainPaystub">
            <Container id="payStubDetailMargin">
              <div className="payRowClick">
                <h6 onClick={handleBackBtnClick}>
                  <ArrowBackIosIcon className="paystubBackBtn" />
                  {props.t("payroll.payrollNavbar.backToPaystubs")}
                </h6>
              </div>
              <div dangerouslySetInnerHTML={{ __html: heading }} />
              <div>
                <p className="latestPay">
                  {`${paycheck.facilities}, ${paycheck.city} ${paycheck.state}`}
                  <span className="payDetailDate">
                    {`${
                      paycheck.payCheckStartDate
                        ? moment.utc(paycheck.payCheckStartDate).format("MMM DD, YYYY")
                        : ""
                    } - ${
                      paycheck.payCheckEndDate
                        ? moment.utc(paycheck.payCheckEndDate).format("MMM DD, YYYY")
                        : ""
                    }`}{" "}
                    {props.t("payrollV2.paystub")}
                  </span>
                </p>
              </div>
              {earning}
              <br />
              {deduction}
              <br />
              {tax}
              <br />
              {deposit}
              <PayrollNoticeV2 />
            </Container>
          </div>
        </div>
      </Row>
    </Container>
  );
}
export default withTranslation()(PaystubDetail);
