import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import RadioGroupInput from "../../RadioGroupInput";

const useStyles = makeStyles((theme) => ({
  saveSearchRadioBtnML: {
    marginLeft: "-10px",
  },
  emailNoteRadioGrp: {
    fontSize: "1rem ",
    color: "#626C7D",
    "@media(min-width: 1024px) and (max-width: 1280px)": {
      fontSize: "0.9rem",
    },
  },
  emailNoteRadioGrpDisable: {
    color: theme.palette.colorDisabled,
    fontSize: "1rem ",
    "@media(min-width: 1024px) and (max-width: 1280px)": {
      fontSize: "0.9rem",
    },
  },
}));

export default function EmailNotificationRadio(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <>
      <div className={classes.saveSearchRadioBtnML}>
        <RadioGroupInput
          row
          disabled={props.disabled}
          name="emailNotification"
          options={[
            {
              key: "instantly",
              label: t("savedSearches.emailNoteRadioInstantly"),
              value: "instantly",
            },
            {
              key: "daily",
              label: t("savedSearches.emailNoteRadioDaily"),
              value: "daily",
            },
          ]}
          value={props.value}
          onChange={props.onChange}
        />
      </div>
    </>
  );
}
