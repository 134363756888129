import React, { Component } from "react";
import { Navbar, Nav } from "react-bootstrap";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import {
  PAYROLL_TIMESHEET,
  PAYROLL_PAYSTUBS,
  PAYROLL_PAYMENT_DEDUCTION,
} from "../../../../router/routes";
import { handleSelectedPayrollMenu } from "../../../../redux/payroll/action";
import { isHamburgerSubMenuOpen, isHamburgerMenuOpen } from "../../../../redux/navigation/action";
import config from "../../../../config";
import PaymentsIcon from "@mui/icons-material/Payments";
import FeedIcon from "@mui/icons-material/Feed";

const enablePaystubs = config.ENABLE_PAYSTUBS === "true";
class PayrollNavV2 extends Component {
  handleSelect(eventKey) {
    this.props.handleSelectedPayrollMenu(eventKey);
    this.props.isHamburgerSubMenuOpen(false);
    this.props.setHamburgerMenuOpen(false);
    this.props.history.push(eventKey);
  }

  render() {
    const { location } = this.props;
    return (
      <>
        <div className="payrollNav">
          <div className="noPaddingMob">
            <div className="myProfilePgTitle">{this.props.t("footer.profile.payroll")}</div>
            <Navbar className="payrollNavbarV2">
              {!enablePaystubs && (
                <Nav
                  activeKey={
                    location.pathname.includes("paystubs") ? PAYROLL_PAYSTUBS : location.pathname
                  }
                  onSelect={this.handleSelect.bind(this)}
                >
                  <Nav.Item>
                    <Nav.Link eventKey={PAYROLL_TIMESHEET}>
                      <div className="">
                        {this.props.t("payroll.payrollNavbar.paystubsAndTimesheets")}
                      </div>
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              )}

              {enablePaystubs && (
                <>
                  <Nav
                    activeKey={
                      location.pathname.includes("paystubs") ? PAYROLL_PAYSTUBS : location.pathname
                    }
                    onSelect={this.handleSelect.bind(this)}
                  >
                    <Nav.Item>
                      <Nav.Link eventKey={PAYROLL_TIMESHEET}>
                        <div className="payrollTab">
                          <FeedIcon />
                          <p>{this.props.t("payroll.payrollNavbar.timesheet")}</p>
                        </div>
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>

                  <Nav
                    activeKey={
                      location.pathname.includes("paystubs") ? PAYROLL_PAYSTUBS : location.pathname
                    }
                    onSelect={this.handleSelect.bind(this)}
                  >
                    <Nav.Item>
                      <Nav.Link eventKey={PAYROLL_PAYSTUBS}>
                        <div className="payrollTab">
                          <PaymentsIcon />
                          <p>{this.props.t("payroll.payrollNavbar.paystubs")}</p>
                        </div>
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </>
              )}
            </Navbar>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  navigationKey: state.payroll.navigationKey,
  assignments: state.payroll.assignments,
});

const mapDispatchToProps = (dispatch) => ({
  handleSelectedPayrollMenu: (key) => dispatch(handleSelectedPayrollMenu(key)),
  isHamburgerSubMenuOpen: (data) => dispatch(isHamburgerSubMenuOpen(data)),
  setHamburgerMenuOpen: (data) => dispatch(isHamburgerMenuOpen(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withRouter(PayrollNavV2)));
