import { useEffect } from "react";
import { connect } from "react-redux";
import { verifyToken } from "../../../redux/auth/action";

interface PasswordResetJwtVerifyProps {
  verifyTokenReceived: (jwtToken: string, password: string | null, companyId: string) => void;
  computedMatch: {
    path: string;
    url: string;
    isExact: boolean;
    params: {
      jwt: string;
    };
  };
  company: {
    id: string;
    name: string;
    code: string;
    url: string;
  };
}

function PasswordResetJwtVerify({
  verifyTokenReceived,
  computedMatch,
  company,
}: PasswordResetJwtVerifyProps) {
  useEffect(() => {
    verifyTokenReceived(computedMatch.params.jwt, null, company?.id);
  });

  return <></>;
}

const mapStateToProps = (state) => ({
  company: state.company.company,
});
const matchDispatchToProps = (dispatch) => ({
  verifyTokenReceived: (jwtToken, password, companyId) =>
    dispatch(verifyToken(jwtToken, password, companyId)),
});

export default connect(mapStateToProps, matchDispatchToProps)(PasswordResetJwtVerify);
