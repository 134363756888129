import { useInbox } from "@trycourier/react-inbox";
import React from "react";
import MarkAsUnreadOutlinedIcon from "@mui/icons-material/MarkAsUnreadOutlined";
import DraftsOutlinedIcon from "@mui/icons-material/DraftsOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import styles from "./Message.module.scss";

interface MessageProps {
  message: any;
}
export const Message: React.FunctionComponent<MessageProps> = ({ message }) => {
  const { markMessageRead, markMessageUnread, markMessageArchived } = useInbox();

  const handleReadMessage = (message) => {
    try {
      markMessageRead(message.messageId, message.trackingIds?.readTrackingId);
    } catch (err) {
      console.error(err);
    }
  };

  const handleUnreadMessage = (message) => {
    try {
      markMessageUnread(message.messageId, message.trackingIds?.unreadTrackingId);
    } catch (err) {
      console.error(err);
    }
  };

  const handleArchiveMessage = (message) => {
    markMessageArchived(message.messageId, message.trackingIds.archiveTrackingId);
  };

  const getDateDiff = (dateString: string) => {
    const dateTimeCreated = new Date(dateString);
    const currentDateTime = new Date();
    let delta = Math.abs(currentDateTime.valueOf() - dateTimeCreated.valueOf()) / 1000;
    const days = Math.floor(delta / 86400);
    delta -= days * 86400;
    const hours = Math.floor(delta / 3600) % 24;
    delta -= hours * 3600;
    const minutes = Math.floor(delta / 60) % 60;
    delta -= minutes * 60;
    let datediff = "";
    if (days > 0) {
      if (days > 99) {
        datediff = "99d";
      } else {
        datediff = `${days.toString()}d`;
      }
    } else if (hours > 0) datediff = `${hours.toString()}h`;
    else if (minutes > 0) datediff = `${minutes.toString()}m`;
    else datediff = "Now";

    return datediff;
  };

  const dateDiff = getDateDiff(message.created);

  return (
    <div key={message.messageId + message.created + Math.random()} className={styles.Message}>
      <div className={styles.Indicator}>
        <div className={message.read ? styles.IndicatorRead : styles.IndicatorUnread} />
      </div>
      <div className={styles.MessageBody}>
        <div className={styles.MessageTitle}>{message.title}</div>
        <div className={styles.MessageBody}>{message.body}</div>
      </div>
      <div className={styles.Date}>
        <div>{dateDiff}</div>
      </div>
      <div className={styles.Actions}>
        {message.read ? (
          <>
            <div onClick={() => handleUnreadMessage(message)}>
              <MarkAsUnreadOutlinedIcon />
            </div>
            <div onClick={() => handleArchiveMessage(message)}>
              <DeleteOutlineOutlinedIcon />
            </div>
          </>
        ) : (
          <div onClick={() => handleReadMessage(message)}>
            <DraftsOutlinedIcon />
          </div>
        )}
      </div>
    </div>
  );
};
