import Analytics from "src/services/Analytics";

export const trackSegmentAnalyticsEvent = (
  translatedText: string,
  eventProps: {
    [value: string]: any;
  },
  t
) => {
  const segmentEventName = translatedText.includes(".") ? t(translatedText) : translatedText;
  const segmentEventProps = eventProps;
  Analytics.segmentTrackEvent(segmentEventName, segmentEventProps);
};
