import React from "react";
import MainLayout from "./MainLayout";
//import Auth from '../common/auth/Auth';
import AuthV2 from "../common/auth/AuthV2";

const AuthPage = (props) => (
  //return(<MainLayout component={Auth} otherProps={props} />)
  <MainLayout component={AuthV2} otherProps={props} />
);
export default AuthPage;
