import axios from "axios";
import { DIGITAL_INTERVIEW_URL_CCN, DIGITAL_INTERVIEW_URL_CCA } from "src/constants/external_links";
import config from "src/config";

export const SET_COMPANY_FROM_DOMAIN = "SET_COMPANY_FROM_DOMAIN";
export const setCompanyFromDomain = (payload) => ({ type: SET_COMPANY_FROM_DOMAIN, payload });

export const REQUEST_SET_COMPANY_DOMAIN_FOR_IFRAME = "REQUEST_SET_COMPANY_DOMAIN_FOR_IFRAME";
export const requestSetCompanyDomainForIframe = (payload) => ({
  type: REQUEST_SET_COMPANY_DOMAIN_FOR_IFRAME,
  payload,
});

export const SET_COMPANY_DOMAIN_FOR_IFRAME_SUCCESS = "SET_COMPANY_DOMAIN_FOR_IFRAME_SUCCESS";
export const setCompanyDomainForIframeSuccess = (payload) => ({
  type: SET_COMPANY_DOMAIN_FOR_IFRAME_SUCCESS,
  payload,
});

export const SET_COMPANY_DOMAIN_FOR_IFRAME_FAILURE = "SET_COMPANY_DOMAIN_FOR_IFRAME_FAILURE";
export const setCompanyDomainForIframeFailure = (payload) => ({
  type: SET_COMPANY_DOMAIN_FOR_IFRAME_FAILURE,
  payload,
});

export const referFriendNavigation = () => {
  const referFriendURL = config.REFER_FRIEND_URL;
  return referFriendURL;
};

export const digitalInterviewNavigation = (companyId) => {
  let digitalInterviewURL;
  switch (companyId) {
    case 1:
      digitalInterviewURL = DIGITAL_INTERVIEW_URL_CCN;
      break;
    case 2:
      digitalInterviewURL = DIGITAL_INTERVIEW_URL_CCA;
      break;
    default:
      digitalInterviewURL = DIGITAL_INTERVIEW_URL_CCN;
      break;
  }
  return digitalInterviewURL;
};

export const setCompanyDomainForIframe = (payload) => (dispatch) => {
  const apiUrl = config.EMPLOYEE_STREAM_APPLY_COMPLETE_API_URL;
  const data = {};
  data.applyComplete = `${payload.url}/redirect.html`;
  dispatch(requestSetCompanyDomainForIframe());
  const ax = axios.create({});

  ax.post(apiUrl, data, {
    headers: {
      "Content-Type": "application/json",
    },
    withCredentials: true,
    crossDomain: true,
  })
    .then((response) => {
      if (response.data.statusCode === 200) {
        dispatch(setCompanyDomainForIframeSuccess());
      } else {
        dispatch(setCompanyDomainForIframeFailure());
      }
    })
    .catch((error) => {
      console.error("Error:", error);
      dispatch(setCompanyDomainForIframeFailure());
    });
};
