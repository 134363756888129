import {
  FETCH_LOCATION_REQUEST,
  FETCH_LOCATION_SUCCESS,
  FETCH_LOCATION_FAILURE,
  DETECT_LOCATION_INPUT_CLICK,
  FETCH_SPECIALTIES_REQUEST,
  FETCH_SPECIALTIES_SUCCESS,
  FETCH_SPECIALTIES_FAILURE,
  FETCH_PRIMARY_SPECIALTIES_REQUEST,
  FETCH_PRIMARY_SPECIALTIES_SUCCESS,
  FETCH_PRIMARY_SPECIALTIES_FAILURE,
  FETCH_SELECTED_SPECIALTIES,
  FLUSH_SPECIALTIES,
} from "./action";

const initialState = {
  location: [],
  selectedLocation: "",
  error: "",
  loading: false,
  locationInputClicked: false,
  specialty: [],
  selectedSpecialties: [],
  SelectedSpecialtiesWithCode: [],
  selectedSpecialtyName: [],
};
const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_LOCATION_REQUEST:
      return { ...state, loading: true, location: [] };

    case FETCH_LOCATION_SUCCESS:
      return { ...state, loading: false, location: action.payload, error: "" };

    case FETCH_LOCATION_FAILURE:
      return { ...state, loading: false, error: action.payload, location: [] };

    case DETECT_LOCATION_INPUT_CLICK:
      return { ...state, locationInputClicked: action.payload };

    case FETCH_SPECIALTIES_REQUEST:
      return { ...state, loading: true };

    case FETCH_SPECIALTIES_SUCCESS:
      return { ...state, loading: false, specialty: action.payload, error: "" };

    case FETCH_SPECIALTIES_FAILURE:
      return { ...state, loading: false, error: action.payload, specialty: [] };

    case FLUSH_SPECIALTIES:
      return { ...state, loading: false, specialty: [] };

    case FETCH_PRIMARY_SPECIALTIES_REQUEST:
      return { ...state, loading: true };

    case FETCH_PRIMARY_SPECIALTIES_SUCCESS:
      return { ...state, loading: false, specialty: action.payload, error: "" };

    case FETCH_PRIMARY_SPECIALTIES_FAILURE:
      return { ...state, loading: false, error: action.payload, specialty: [] };

    case FETCH_SELECTED_SPECIALTIES:
      return {
        ...state,
        selectedSpecialtyName: action.payload,
        selectedSpecialties: action.specialtyCode,
        SelectedSpecialtiesWithCode: action.specialtiesWithCode,
      };
    default:
      return state;
  }
};

export default rootReducer;
