import { useTranslation, withTranslation } from "react-i18next";
import { payStubDetailsTypes } from "src/components/common/MainContent/Payroll/Paystubs/Tables";
import { formatCurrency } from "src/utils/formatCurrency";
import styles from "./DeductionsTable.module.scss";

interface DeductionsTableProps {
  payStubDetails: payStubDetailsTypes;
}

const DeductionsTable = ({ payStubDetails }: DeductionsTableProps) => {
  const { t } = useTranslation();

  return (
    <>
      <div className={styles.paystubsTable}>
        <h3 className={styles.tableHeading}>
          {t("payStubs.deductions")} &nbsp;&nbsp;
          {formatCurrency(payStubDetails?.totalDeductionAmount)}
        </h3>
        <table className="paystubsTable">
          <thead>
            <tr>
              <th>{t("payStubs.item")}</th>
              <th>{t("payStubs.type")}</th>
              <th>{t("payStubs.amount")}</th>
            </tr>
          </thead>
          <tbody>
            {payStubDetails?.deductions?.map((deduct) => (
              <tr key={deduct.description}>
                <td>{deduct.description}</td>
                <td>{deduct.deduction}</td>
                <td>{formatCurrency(deduct.amount)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};
export default withTranslation()(DeductionsTable);
