import { CHANGE_SETTING_THEME_REQUEST } from "./actions";

const initialState = {
  theme: "default",
};

const settingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_SETTING_THEME_REQUEST:
      return { ...state, theme: action.payload };
    default:
      return state;
  }
};

export default settingsReducer;
