import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { HOME } from "../../router/routes";
import { setCompleteStatus } from "../../redux/candidate/action";

function ApplyCompletePage(props) {
  props.setCompleteStatus();
  return <Link to={HOME} />;
}

const mapDispatchToProps = (dispatch) => ({
  setCompleteStatus: () => dispatch(setCompleteStatus()),
});

export default connect(null, mapDispatchToProps)(ApplyCompletePage);
