import { FC, ReactElement } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";
import CustomNavMob from "src/components/common/Navigation/CustomNavMob";
import MyProfileNav from "src/components/common/OnBoarding/MyProfileNav";
import { CERTIFICATIONS_LICENSES } from "src/router/routes";

const MyProfileNavWrapper: FC = (): ReactElement => {
  const { t } = useTranslation();
  const history = useHistory();
  const handleRedirection = () => {
    history.push(CERTIFICATIONS_LICENSES);
  };
  return (
    <div className="myProfileSubNav">
      {isMobile && <CustomNavMob type="myProfile" />}
      {!isMobile && (
        <>
          <div className="myProfilePgTitle">{t("footer.profile.profileDocs")}</div>
          <MyProfileNav />
          <div
            className={
              window.location.pathname.includes("certifications-and-licenses")
                ? "certLicencesBtnActive"
                : "certLicencesBtn"
            }
            role="button"
            tabIndex={0}
            onClick={handleRedirection}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                handleRedirection();
              }
            }}
          >
            <div className="certLicencesBtnIcon" />
            <span className="certLicencesBtnTxt">
              {t("myProfileSubNav.certificationsLicenses")}
            </span>
          </div>
        </>
      )}
    </div>
  );
};

export default MyProfileNavWrapper;
