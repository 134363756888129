import { useState, FC } from "react";
import { useTranslation } from "react-i18next";
import { IconButton } from "@mui/material";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import { isMobile, isTablet } from "react-device-detect";
import { useSelector, useDispatch } from "react-redux";
import config from "src/config";
import { MyJobsFilters, MyJobsFiltered } from "src/components/common/MainContent/JobResults/common";
import { setMyJobsFilter, setMyJobsPage } from "src/redux/jobs/MyJob/action";
import Analytics from "src/services/Analytics";

interface RootState {
  myJobs: {
    filteredJobs: MyJobsFiltered;
    filterSelected: MyJobsFilters;
  };
}

const enableQuickApply = config.ENABLE_QUICK_APPLY === "true";

const MyJobsFilterV2: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const myJobs = useSelector((state: RootState) => state.myJobs.filteredJobs);
  const filterSelected = useSelector((state: RootState) => state.myJobs.filterSelected);

  const initialStates = [
    { value: "favorite", label: t("jobs.jobFavorite.favorited") },
    { value: "apply", label: t("jobs.jobDescription.applied") },
    { value: "email", label: t("jobs.emailRecruiter.emailed") },
    { value: "assignments", label: t("jobs.myAssignments") },
  ];
  if (enableQuickApply) {
    initialStates.splice(3, 0, {
      value: "quickApply",
      label: t("jobs.applied"),
    });
  }
  const [checkboxesData] = useState(initialStates);
  const [nextOrBack, setNextOrBack] = useState("next");
  const mobileView = isMobile && !isTablet;

  const segmentJobSectionViewed = (sectionSelected: string) => {
    const segmentSourceName =
      isMobile || isTablet ? t("jobs.analytics.source.mobile") : t("jobs.analytics.source.web");
    const section = t(`jobs.analytics.${sectionSelected}`);
    const segmentEventName = "Job Status Section Viewed";

    const segmentEventProps = {
      section,
      segmentSourceName,
    };
    Analytics.segmentTrackEvent(segmentEventName, segmentEventProps);
  };

  const handleFilterClick = (filter: MyJobsFilters) => {
    segmentJobSectionViewed(filter);
    dispatch(setMyJobsFilter(filter));
    dispatch(setMyJobsPage(1));
  };

  const renderTab = (item, index) => {
    const isTabDisplayed =
      !mobileView || (nextOrBack === "next" && index < 3) || (nextOrBack === "back" && index > 2);
    return (
      isTabDisplayed && (
        <div
          key={item.value}
          className={filterSelected === item.value ? "myJobChipSelected" : "myJobChip"}
          onClick={() => handleFilterClick(item.value)}
          onKeyUp={() => handleFilterClick(item.value)}
          role="button"
          tabIndex={index}
        >
          {item.label} ({myJobs ? myJobs[item.value]?._embedded.results.length : 0})
        </div>
      )
    );
  };

  return (
    <div data-testid="filterChips" className="myJobsTxtAlign">
      {mobileView && nextOrBack === "back" && (
        <IconButton onClick={() => setNextOrBack("next")}>
          <NavigateBeforeIcon />
        </IconButton>
      )}

      {checkboxesData.map((item, index) => renderTab(item, index))}

      {mobileView && nextOrBack === "next" && (
        <IconButton onClick={() => setNextOrBack("back")}>
          <NavigateNextIcon />
        </IconButton>
      )}
    </div>
  );
};

export default MyJobsFilterV2;
