import MainLayout from "src/components/pages/MainLayout";
import CertificationsLicenses from "src/components/common/OnBoarding/CertificationsLicenses";
import CertificationsLicensesV2 from "src/components/common/OnBoarding/CertificationsLicensesV2";
import config from "src/config";

const isCertificationsLicenses = config.ENABLE_CERTIFICATIONS_LICENSES === "true";
const CertificationsLicensesPage = (props) => (
  <MainLayout
    component={isCertificationsLicenses ? CertificationsLicenses : CertificationsLicensesV2}
  />
);

export default CertificationsLicensesPage;
