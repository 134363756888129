import React from "react";
import { connect } from "react-redux";
import { Route } from "react-router-dom";
import { push } from "connected-react-router";
import { isLoggedIn } from "axios-jwt";
import { LOGIN, JOBS_DETAILS, JOBS_RESULTS } from "./routes";
import { initApp } from "../redux/auth/action";
import getCompanyFromDomain from "../services/Company";
import { setCompanyFromDomain } from "../redux/company/action";

const LoggedInRoute = ({ component: Component, ...otherProps }) => {
  let companyDetails;

  if (!otherProps.companyDetails) {
    companyDetails = getCompanyFromDomain();
  } else companyDetails = otherProps.companyDetails;

  otherProps.setCompanyFromDomain(companyDetails);
  const auth = isLoggedIn();

  if (auth === false) {
    if (otherProps.path === JOBS_DETAILS || otherProps.path === JOBS_RESULTS) {
      otherProps.push({
        pathname: LOGIN,
        state: { search: otherProps.location.search, path: otherProps.location.pathname },
      });
    } else {
      otherProps.push(LOGIN);
    }
  }
  if (!otherProps.username && !otherProps.isCandidateListEmpty && !otherProps.initCalled) {
    let path = null;
    if (otherProps.path === JOBS_DETAILS || otherProps.path === JOBS_RESULTS) {
      path = otherProps.location.pathname + otherProps.location.search;
    }
    otherProps.initApp(companyDetails.id, path);
  }

  return <Route render={(otherProps) => <Component {...otherProps} />} />;
};

const mapStateToProps = (state) => ({
  username: state.auth.username,
  email: state.auth.email,
  companyDetails: state.company.company,
  isCandidateListEmpty: state.auth.isCandidateListEmpty,
  applicantStatus: state.candidate.applicantStatus,
  initCalled: state.auth.initCalled,
});

const mapDispatchToProps = {
  initApp: (companyId) => initApp(companyId),
  setCompanyFromDomain: (company) => setCompanyFromDomain(company),
  push,
};

export default connect(mapStateToProps, mapDispatchToProps)(LoggedInRoute);
