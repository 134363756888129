import { jobDetailType } from "src/components/common/MainContent/JobResults/common/index";
import config from "src/config";

export interface EligibilityMeterRequirement {
  isMet: boolean;
}

const EligibilityPercentage = (job: jobDetailType) => {
  const enableEligibilityMeter = config.ENABLE_ELIGIBILITY_METER === "true";
  const { isQuickApplied } = job;
  const showEligibilityMeter = enableEligibilityMeter && isQuickApplied !== true;

  const eligibilityMeterRequirements: EligibilityMeterRequirement[] = [
    { isMet: job?.quickApplyEligibility?.qualifications?.ssnAndDob?.quickApply  === true },
    { isMet: job?.quickApplyEligibility?.qualifications?.workHistory?.quickApply === true },
    { isMet: job?.quickApplyEligibility?.qualifications?.reference?.quickApply === true },
    ...(job?.quickApplyEligibility?.qualifications?.credential?.credentials != null &&
        job?.quickApplyEligibility?.qualifications?.credential?.credentials?.length > 0 ? [{
      isMet: job?.quickApplyEligibility?.qualifications?.credential?.quickApply === true
    }] : []),
    { isMet: job?.quickApplyEligibility?.qualifications?.checkList?.quickApply === true }
  ];

  const eligibilityPercentage =
    eligibilityMeterRequirements.filter((requirement) => requirement.isMet).length /
      eligibilityMeterRequirements.length *100;

  return showEligibilityMeter && eligibilityPercentage > 20 ? eligibilityPercentage : null;
};

export default EligibilityPercentage;
