import Slider from "@mui/material/Slider";
import { styled } from "@mui/material/styles";
import {
  CategoryScale,
  Chart as ChartJS,
  Filler,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import { useState } from "react";
import { Line } from "react-chartjs-2";
import { isMobile } from "react-device-detect";
import { withTranslation, TFunction } from "react-i18next";
import styles from "./WeatherWidget.module.scss";
import WeatherWidgetLegendDesktop from "./WeatherWidgetLegendDesktop.svg";
import WeatherWidgetLegendMobile from "./WeatherWidgetLegendMobile.svg";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

const OverlaySlider = styled(Slider)({
  color: "transparent",
  height: 8,
  "& .MuiSlider-track": {
    border: "none",
  },
  "& .MuiSlider-valueLabel": {
    backgroundColor: styles?.colorPrimary,
    "&:before": {
      display: "none",
    },
  },
  "& .MuiSlider-thumb": {
    height: 20,
    width: 20,
    backgroundColor: styles?.colorPrimary,
    border: "4px solid #ffffff",
    "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
      boxShadow: "inherit",
    },
    "&:after": {
      content: "''",
      position: "absolute",
      height: isMobile ? 70 : 86,
      width: 1,
      backgroundColor: "white",
      marginTop: isMobile ? 44 : 53,
    },
  },
});

export const options = {
  responsive: true,
  stacked: false,
  maintainAspectRatio: false,
  padding: 0,
  elements: {
    point: {
      radius: 0,
      hitRadius: 0,
    },
  },
  plugins: {
    legend: {
      display: false,
      position: "top" as const,
      align: "end" as const,
    },
    tooltip: {
      enabled: false,
    },
    title: {
      display: false,
      text: "Chart.js Line Chart",
    },
  },
  scales: {
    x: {
      color: "rgb(255, 99, 132)",
      ticks: {
        color: "#ffffff",
        display: true,
        autoSkip: false,
      },
      grid: {
        drawOnChartArea: false,
        color: "#ffffff",
        display: true,
      },
    },

    y: {
      title: {
        display: true,
        text: "Temperature - °F",
        color: "#ffffff",
      },
      beginAtZero: true,
      min: 0,
      max: 120,
      type: "linear" as const,
      display: true,
      position: "left" as const,
      ticks: {
        stepSize: 60,
        color: "#ffffff",
      },
      grid: {
        drawOnChartArea: false,
        color: "#ffffff",
        display: true,
      },
    },

    y1: {
      title: {
        display: true,
        text: "Precipitation - inches",
        color: "#ffffff",
      },
      min: 0,
      max: 60,
      type: "linear" as const,
      display: true,
      position: "right" as const,
      ticks: {
        color: "#ffffff",
        stepSize: 10,
        // callback(value, index) {
        //   if (index % 2 === 0) return "";
        //   return value;
        // },
      },
      grid: {
        drawOnChartArea: false,
        color: "#ffffff",
        display: true,
        drawBorder: false,
      },
    },
  },
};

const labels = ["Jan", " ", " ", "Apr", " ", " ", "Jul", " ", " ", "Oct", " ", " "];

interface WeatherWidgetProps {
  t: TFunction<"translation", undefined>;
  weatherData: { highTemp: number[]; lowTemp: number[]; precipitation: number[] };
  trackDragEvent: () => void;
}
const WeatherWidget = ({ t, weatherData, trackDragEvent }: WeatherWidgetProps) => {
  const { highTemp, lowTemp, precipitation } = weatherData;
  const sliderMonthValue = (value) => {
    switch (value) {
      case 1:
        return "January";
      case 2:
        return "February";
      case 3:
        return "March";
      case 4:
        return "April";
      case 5:
        return "May";
      case 6:
        return "June";
      case 7:
        return "July";
      case 8:
        return "August";
      case 9:
        return "September";
      case 10:
        return "October";
      case 11:
        return "November";
      case 12:
        return "December";
      default:
        return "";
    }
  };
  const getCurrentMonth = (): number => {
    const date = new Date();
    const month = date.getMonth() + 1;
    return month;
  };
  const [sliderSelection, setSliderSelection] = useState<{
    month: string;
    monthIndex: number;
  }>({ month: sliderMonthValue(getCurrentMonth()), monthIndex: getCurrentMonth() });

  const handleChange = (_event: Event, newValue: number) => {
    const sliderValue = { month: sliderMonthValue(newValue), monthIndex: newValue };
    trackDragEvent();
    setSliderSelection(sliderValue);
  };

  const data = {
    labels,
    datasets: [
      {
        label: "High Temp",
        data: highTemp,
        borderColor: "#ffffffb7",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
        yAxisID: "y",
        lineTension: 0.5,
        borderDash: [5, 5],
      },
      {
        label: "Low Temp",
        data: lowTemp,
        borderColor: "#ffffffb7",
        backgroundColor: "rgba(99, 235, 53, 0.5)",
        lineTension: 0.5,
        yAxisID: "y",
        borderDash: [5, 5],
      },
      {
        label: "Precipitation",
        data: precipitation,
        borderColor: "#5379AB",
        backgroundColor: "#5379AB",
        fill: true,
        lineTension: 0.5,
        yAxisID: "y1",
      },
    ],
  };

  return (
    <div className={styles.Widget}>
      <div className={styles.WidgetTitle}>{t("jobs.moreInfo.location")}</div>
      <div className={styles.WidgetContainer}>
        <div className={styles.WidgetHeader}>
          <div className={styles.MonthlyData}>
            <div className={styles.Month}>{sliderSelection.month.toUpperCase()} AVERAGE</div>
            <div className={styles.TempPrecipContainer}>
              <div className={styles.TempPrecipLabel}>
                Temperature
                <div className={styles.HighLowTempContainer}>
                  <span className={styles.TempPrecipData}>
                    High{" "}
                    {Object.keys(weatherData).length > 0 &&
                      weatherData?.highTemp[sliderSelection.monthIndex - 1]}
                    º
                  </span>
                  <span className={styles.TempPrecipData}>
                    Low{" "}
                    {Object.keys(weatherData).length > 0 &&
                      weatherData?.lowTemp[sliderSelection.monthIndex - 1]}
                    º
                  </span>
                </div>
              </div>
              <div className={styles.TempPrecipLabel}>
                Precipitation
                <div>
                  <span className={styles.TempPrecipData}>
                    Inches:{" "}
                    {Object.keys(weatherData).length > 0 &&
                      weatherData?.precipitation[sliderSelection.monthIndex - 1]}
                    &quot;
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.ChartLegend}>
            {!isMobile ? (
              <img
                src={WeatherWidgetLegendDesktop}
                width="80px"
                height="36px"
                alt="Cross Country Healthcare Logo"
                role="presentation"
              />
            ) : (
              <img
                src={WeatherWidgetLegendMobile}
                width="100%"
                height="14px"
                alt="Cross Country Healthcare Logo"
                role="presentation"
              />
            )}
          </div>
        </div>
        <div className={styles.ChartContainer}>
          <div className={styles.OverlaySlider}>
            <OverlaySlider
              aria-label="Month Selector"
              defaultValue={getCurrentMonth()}
              valueLabelDisplay="off"
              valueLabelFormat={(value) => sliderMonthValue(value).slice(0, 3)}
              step={1}
              min={1}
              max={12}
              onChange={handleChange as any}
            />
          </div>
          <div className={styles.Chart}>
            <Line options={options} data={data} />
          </div>
        </div>
      </div>
    </div>
  );
}
 export default withTranslation()(WeatherWidget);