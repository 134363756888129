import { useEffect, useState } from "react";
import config from "src/config";

const useWeatherData = (zip) => {
  const [highTemp, setHighTemp] = useState({} as any);
  const [lowTemp, setLowTemp] = useState({} as any);
  const [precipitation, setPrecipitation] = useState({} as any);
  const [weatherData, setWeatherData] = useState({} as any);

  const baseURL = config.WEATHER_DATA_BASE_URL;
  const highTempEndpoint = "/high-temp/";
  const lowTempEndpoint = "/low-temp/";
  const precipitationEndpoint = "/rain/";
  
  const fetchWeatherData = async (endpoint, setter) => {
    try {
      const response = await fetch(baseURL + endpoint + zip);
      response.json().then((res) => {
        setter(convertDataToArray(res));
      });
    } catch (error) {
      console.error(error);
      return;
    }
  };

  const monthOrder = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  function convertDataToArray(data: any) {
    if (!data.Item) return [];
    const sortedData = monthOrder.map((month) => {
      return parseFloat(data.Item[month]?.S || 0);
    });
    return sortedData;
  }

  useEffect(() => {
    fetchWeatherData(highTempEndpoint, setHighTemp);
    fetchWeatherData(lowTempEndpoint, setLowTemp);
    fetchWeatherData(precipitationEndpoint, setPrecipitation);
  }, [zip]);

  useEffect(() => {
    setWeatherData({
      highTemp: highTemp,
      lowTemp: lowTemp,
      precipitation: precipitation,
    });
  }, [highTemp, lowTemp, precipitation]);

  return weatherData;
};

export default useWeatherData;
