import { useRef, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { snapEngage, hideSnapEngage } from "src/components/common/ThirdPartyScripts/thirdPartyData";
import style from "./SnapEngage.module.scss";
import SnapChatIcon from "./SnapChatIcon";

const SnapEngage = (): React.ReactElement => {
  const location = useLocation();
  const scriptLoaded = useRef<boolean>(false);
  const [showButton, setShowButton] = useState<boolean>(true);

  const attachSnapScript = (): void => {
    if (!scriptLoaded.current) snapEngage();
    scriptLoaded.current = true;
  };

  const isVisible = (): string => {
    const btnStyle = showButton ? style.snapVisible : style.snapHidden;
    return btnStyle;
  };

  const shouldHideChat = () => {
    if (hideSnapEngage()) {
      setShowButton(false);
      if (scriptLoaded.current) window.SnapEngage.hideButton();
    } else if (scriptLoaded.current && !showButton) {
      window.SnapEngage.showButton();
    } else {
      setShowButton(true);
    }
  };

  useEffect(() => {
    shouldHideChat();
  }, [location]);

  const handleClick = (): void => {
    attachSnapScript();
    setShowButton(false);
  };

  return (
    <div className={`${style.snapWrapper} ${isVisible()}`}>
      <button type="button" tabIndex={0} onClick={handleClick}>
        <SnapChatIcon />
        <span>Live Chat</span>
      </button>
    </div>
  );
};

export default SnapEngage;
