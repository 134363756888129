import { alpha, styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";

const GreenSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: "#D7282F",
    "&:hover": {
      backgroundColor: alpha("#D7282F", theme.palette.action.hoverOpacity),
    },
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: " #D7282F",
  },
}));
const useStyles = makeStyles((theme) => ({
  emailNotificationTxt: {
    color: "#626C7D",
    fontSize: "0.8rem !important",
    marginBottom: "30px",
    marginTop: "15px",
    "@media(min-width: 0px) and (max-width: 768px)": {
      fontSize: "1rem !important",
    },
  },
  emailNotiTxtFontSize: {
    fontSize: "1rem ",
    marginRight: "10px",
    color: "#626C7D",
    "@media(min-width: 1024px) and (max-width: 1280px)": {
      fontSize: "0.9rem",
    },
  },
}));
const label = { inputProps: { "aria-label": "Switch" } };

export default function NotificationSwitch(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <div>
      <label
        className={
          props.fontsizesavesearch === "true"
            ? classes.emailNotiTxtFontSize
            : classes.emailNotificationTxt
        }
      >
        {t("savedSearches.emailNotificationLabel")}
      </label>
      <GreenSwitch {...label} {...props} />
    </div>
  );
}
