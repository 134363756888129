import Image from "react-bootstrap/Image";
import Link from "@material-ui/core/Link";
import { withTranslation, useTranslation } from "react-i18next";
import { useState } from "react";
import "src/components/common/auth/SignInUser/ExistingUserVerification.scss";
import EmailVerificationIcon from "src/components/common/auth/SignInUser/EmailVerificationIcon.svg";
import { RESEND_EMAIL } from "src/services/GraphQL/graph.query";
import { useMutation } from "@apollo/client";
import CircularProgress from "@material-ui/core/CircularProgress";
import styles from "src/components/common/auth/SIgnUpUser/EmailVerificationModal.module.scss";

function SignInUserVerification(props) {
  const { t } = useTranslation();
  const [successfulMsg, setSuccessfulMsg] = useState(false);
  const [resendEmail, { data: resendEmailResponse, loading: resendEmailResponseLoading }] =
    useMutation(RESEND_EMAIL);
  const resendEmailToUser = async () => {
    try {
      await resendEmail({
        variables: { emailVerification: props.emailVerificationJson },
      });
      setSuccessfulMsg(false);
      setTimeout(() => {
        setSuccessfulMsg(true);
      }, 3000);
    } catch (error) {
      console.error("Error while sending verification mail:", error);
    }
  };

  return (
    <div>
      <p className={styles.emailVerificationHead}>{t("emailVerification.headText")}</p>
      <p className={styles.emailVerificationText}>{t("emailVerification.headSubText")}</p>
      <div className="d-flex justify-content-center">
        <Image src={EmailVerificationIcon} />
      </div>
      <div className="emailVerificationSubText">
        <div>
          <div className={styles.mailNotFoundMsg}>{t("verifyEmailExpired.mailNotFoundMsg")}</div>
          <div className={styles.spamMsg}>{t("verifyEmailExpired.spamCheckMsg")}</div>
          <div className={styles.emailVerificationSubText}>
            {!resendEmailResponse?.resendEmailVerification?.success || successfulMsg ? (
              <Link onClick={resendEmailToUser} className={styles.resendEmail}>
                <span className={styles.resendEmailText}>{t("emailVerification.resendEmail")}</span>
                {resendEmailResponseLoading ? (
                  <CircularProgress size={14} className={styles.loaderResendBtn} thickness={5.5} />
                ) : null}
              </Link>
            ) : (
              <p className="sentSuccessfully">{t("emailVerification.sentSuccessfully")}</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default withTranslation()(SignInUserVerification);
