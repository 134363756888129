import { Suspense } from "react";
import PropTypes from "prop-types";
import config from "../config";
import getCompanyFromDomain from "../services/Company";

import CcnTheme from "./CcnTheme";
import CcaTheme from "./CcaTheme";

const ThemeSelector = ({ children }) => {
  const companyDetails = getCompanyFromDomain();
  const ccnCompany = config.COMPANY_CCN;
  const ccaCompany = config.COMPANY_CCA;
  return (
    <>
      <Suspense fallback={<></>}>
        {companyDetails.id === ccnCompany.id && <CcnTheme />}
        {companyDetails.id === ccaCompany.id && <CcaTheme />}
        {children}
      </Suspense>
    </>
  );
};

ThemeSelector.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ThemeSelector;
