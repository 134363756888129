import { push } from "connected-react-router";
import jwt from "jwt-simple";
import { getRefreshToken, setAuthTokens, STORAGE_KEY ,getAccessToken} from "axios-jwt";
import { CognitoUser } from "amazon-cognito-identity-js";
import moment from "moment";
import axios from "axios";
import config from "src/config";
import { updateUserLastLoginDate } from "src/redux/user/action";
import getCompanyFromDomain from "src/services/Company";
import { graphqlRequest as request,graphqlRequestPii  } from "src/services/GraphQL/GraphQL";
import { getAllReferencesQuery, GET_ALL_WORK_EXPERIENCE, GET_PII_TOKENS, GET_PII_TOKEN_VALUE } from "src/services/GraphQL/graph.query";
import combinedReferences from "src/services/CombinedReferences";
import { LOGIN, HOME, LOGIN_JWT_URL, REDIRECT_JWT_URL } from "src/router/routes";
import API from "src/services/Api";
import { signIn } from "src/services/Auth";
import {
  updateCompleteFlag,
  updateActiveFlag,
  fetchOnboardingInfoSuccess,
  updateReferencesLoadingFlag,
  fetchCandidateCredentials,
} from "src/redux/onBoarding/action";
import {
  fetchAccessToken,
  fetchCandidateProfile,
  isInitApiLoading,
  setAtsBullhornCandidateId,
  fetchBHId,
} from "src/redux/candidate/action";
import { fetchAssignments } from "src/redux/payroll/action";
import { fetchSpecialties } from "src/redux/globalSearch/action";
import UserPool from "src/auth/UserPool";
import { fetchRecruiterDetails } from "src/redux/consultant/action";
import Analytics from "src/services/Analytics";
import i18n from "src/translations/i18n";
import analyticsSegmentIdentify from "src/services/SegmentIdentify";
import Dynatrace from "src/services/Dynatrace";
import { fetchMyJobs } from "src/redux/jobs/MyJob/action";

export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const logInSuccess = (payload) => ({ type: LOGIN_SUCCESS, payload });

export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const logInFailure = (payload) => ({ type: LOGIN_FAILURE, payload });

export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const logInRequest = (payload) => ({ type: LOGIN_REQUEST, payload });

export const DETECT_EMPTY_TOKEN = "DETECT_EMPTY_TOKEN";
export const detectEmptyToken = (payload) => ({ type: DETECT_EMPTY_TOKEN, payload });

const { clientId } = config.cognito;
const refreshTokenRevokeUrl = config.REFRESH_TOKEN_REVOKE_URL;
const referencesIsDeleted = config.REFERENCES_IS_DELETED === "true";
const enableWorkHistory = config.ENABLE_WORK_HISTORY === "true";
const workExpIsDeleted = config.WORK_EXPERIENCES_IS_DELETED === "true";
const enableAppPIIData = config.ENABLE_PII_DATA === "true"
const enableIsLegalQuestion = config.ENABLE_IS_LEGAL_QUESTION === "true";

const signOut = async (email) => {
  try {
    const username = email;
    const user = new CognitoUser({
      Username: username,
      Pool: UserPool,
    });
    user.signOut();
  } catch (error) {
    console.error("Signout error", error);
    window.location.reload();
  }
  try {
    if (!Dynatrace().disable()) {
      Dynatrace().closeSession();
    }
  } catch (err) {
    console.log(`Error while closing user session in dynatrace: ${err}`); // eslint-disable-line no-console
  }
  localStorage.clear();
  sessionStorage.clear();
};

const logoutResponse = async (email, dispatch) => {
  await signOut(email);
  dispatch(detectEmptyToken(false));
  dispatch(push(LOGIN));
  dispatch(updateCompleteFlag(new Set()));
  dispatch(updateActiveFlag(-1));
  localStorage.clear();
};

const fetchPIITokenValues = (email) => {
  const query = { email };
  const piiValues = {
    dob: null,
    ethnicity: null,
    ss_num: null,
    gender: null,
  };
  return new Promise((resolve, reject) => {
    request(GET_PII_TOKENS, query, getAccessToken())
      .then((res) => {
        const piiTokensResponse = res?.getPIITokens;
        const token = [
          piiTokensResponse.ss_num,
          piiTokensResponse.dob,
          piiTokensResponse.gender,
          piiTokensResponse.ethnicity,
        ].filter((value) => value !== null && value !== undefined);
        const variables = { tokens: token };
        if (token.length > 0) {
          request(GET_PII_TOKEN_VALUE, variables, getAccessToken())
            .then((resp) => {
              if (resp.getPIITokenValue && resp.getPIITokenValue.piiTokens && typeof resp.getPIITokenValue.piiTokens === "object") {
                resp.getPIITokenValue.piiTokens.forEach((tokens) => {
                  if (tokens.token === piiTokensResponse.ss_num) piiValues.ss_num = tokens.value;
                  if (tokens.token === piiTokensResponse.dob) piiValues.dob = tokens.value;
                  if (tokens.token === piiTokensResponse.gender) piiValues.gender = tokens.value;
                  if (tokens.token === piiTokensResponse.ethnicity)
                    piiValues.ethnicity = tokens.value;
                });
              }
              resolve(piiValues);
            })
            .catch((err) => {
              console.error("Error while fetching PII tokens:", err.message);
              reject(piiValues);
            });
        } else {
          resolve(piiValues);
        }
      })
      .catch((err) => {
        reject(piiValues);
        console.error("Error while fetching PII tokens:", err.message);
      });
  });
};

export const logout = (email) => (dispatch) => {
  logoutResponse(email, dispatch);
};

export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const forgotPassword = (payload) => ({ type: FORGOT_PASSWORD, payload });

export const saveTokensInLocalStorage = (idToken, refreshToken) => {
  setAuthTokens({ accessToken: idToken, refreshToken });
};

const saveUsernameInLocalStorage = (username) => {
  localStorage.setItem("username", username);
};

export const INACTIVE_USER = "INACTIVE_USER";
export const inactiveUser = (payload) => ({ type: INACTIVE_USER, payload });

export const IS_CANDIDATE_LIST_EMPTY = "IS_CANDIDATE_LIST_EMPTY";
export const isCandidateListEmpty = (payload) => ({ type: IS_CANDIDATE_LIST_EMPTY, payload });

export const initApp = (companyId, path) => {
  const enableReferences = config.ENABLE_REFERENCES === "true";

  let apiResponse;
  const company = getCompanyFromDomain();
  return (dispatch) => {
    let authToken = localStorage.getItem(STORAGE_KEY);
    authToken = JSON.parse(authToken);
    if (!authToken) return;
    let origin;
    dispatch(isInitApiLoading(true));
    dispatch(logInRequest(companyId));
    API.get("/app/init", {
      params: {
        companyId,
      },
    })
      .then((response) => {
        const currentDate = moment.utc().format("yyyy-MM-DD HH:mm:ss");
        const reqObj = {
          email: response.data.user.email,
          lastLoginDate: currentDate,
        };
        dispatch(updateUserLastLoginDate(reqObj));
        let onboardingSpecialtiesAndEducation;
        let onboardingBAsicInfo;
        if (
          response.data.onboardingScoutToWeboneLambdaResponse &&
          response.data.onboardingScoutToWeboneLambdaResponse.response
        ) {
          onboardingSpecialtiesAndEducation =
            response.data.onboardingScoutToWeboneLambdaResponse.response?.onboarding
              ?.specialtiesAndEducation;
          onboardingBAsicInfo =
            response.data.onboardingScoutToWeboneLambdaResponse.response?.onboarding?.basicInfo;
        }
        const identifyObj = {
          email: response?.data?.user?.email,
          company: response?.data?.candidates[0]?.companyId,
          userId: response?.data?.user?.id,
          firstName: response?.data?.user?.firstName,
          lastName: response?.data?.user?.lastName,
          phone: response?.data?.user?.phone,
          recruiterDetails: null,
          onboardingSpecialtiesAndEducation,
          onboardingBasicInfo: onboardingBAsicInfo,
        };
        analyticsSegmentIdentify(identifyObj);
        if (
          localStorage.getItem("historyPathName") &&
          localStorage.getItem("historyPathName").includes("/auth/relay")
        ) {
          origin = "WCM User Apply";
        } else if (
          localStorage.getItem("historyPathName") &&
          localStorage.getItem("historyPathName").includes("/password-recovery")
        ) {
          origin = "Forgot Password";
        } else {
          origin = "User Sign In";
        }
        if (
          response.data.user.isActive &&
          !response.data.user.isDeleted &&
          response?.data?.candidates?.length !== 0
        ) {
          if (response.data.candidates[0].companyId === 2 && company.url !== config.CCA_URL) {
            localStorage.clear();
            window.location.replace(
              `${config.CCA_URL}/xdl?ctoken=${authToken.accessToken}&rtoken=${authToken.refreshToken}`
            );
          }
        }
        return response;
      })
      .then(async (response) => {
        apiResponse = response;
        if (enableReferences) {
          const query = {};
          query.email = response.data.user.email;
          query.isDeleted = referencesIsDeleted;
          dispatch(updateReferencesLoadingFlag(true));
          const accessToken = getAccessToken();
          const referencesResponse = await request(getAllReferencesQuery, query, accessToken);
          let references = [];
          for (let i = 0; i < referencesResponse.getAllReferences.content.length; i++) {
            for (
              let j = 0;
              j <
              referencesResponse.getAllReferences.content[String(i)].candidateReference.references
                .length;
              j++
            ) {
              const ref =
                referencesResponse.getAllReferences.content[String(i)].candidateReference
                  .references[String(j)];
              ref.companyId = referencesResponse.getAllReferences.content[String(i)].companyId;
              references = references.concat(ref);
              referencesResponse.getAllReferences.content[String(i)].candidateReference.references[
                String(j)
              ] = ref;
            }
          }
          return referencesResponse;
        }
        return null;
      })
      .then(async (referencesResponse) => {
        // checking user is not active and is deleted
        dispatch(updateReferencesLoadingFlag(false));
        const response = apiResponse;
        if (!response.data.user.isActive && response.data.user.isDeleted) {
          dispatch(inactiveUser(response.data));
          localStorage.clear();
          dispatch(detectEmptyToken(true));
          localStorage.setItem("isTokenEmpty", true);
        } else {
          if (response.data.candidates === null || response.data.candidates.length === 0) {
            dispatch(isCandidateListEmpty(true));
          } else {
            let orgId = null;
            let atsBullhornCandidateId = null;
            for (let i = 0; i < response.data.candidates.length; i++) {
              if (response.data.candidates[String(i)].candidateExternalIds != null) {
                if (response.data.candidates[String(i)].candidateExternalIds.length > 0) {
                  if (orgId != null) {
                    break;
                  } else {
                    for (
                      let j = 0;
                      j < response.data.candidates[String(i)].candidateExternalIds.length;
                      j++
                    ) {
                      if (
                        response.data.candidates[String(i)].candidateExternalIds[String(j)]
                          .orgType === "ES"
                      ) {
                        orgId =
                          response.data.candidates[String(i)].candidateExternalIds[String(j)].orgId;
                        break;
                      }
                    }

                    for (
                      let k = 0;
                      k < response.data.candidates[String(i)].candidateExternalIds.length;
                      k++
                    ) {
                      if (
                        response.data.candidates[String(i)].candidateExternalIds[String(k)]
                          .orgType === "BH"
                      ) {
                        atsBullhornCandidateId =
                          response.data.candidates[String(i)].candidateExternalIds[String(k)].orgId;
                        break;
                      }
                    }
                  }
                }
                if (orgId != null) {
                  dispatch(fetchCandidateProfile(orgId));
                  dispatch(fetchAccessToken(orgId));
                }
               
                if (atsBullhornCandidateId != null) {
                  dispatch(setAtsBullhornCandidateId(atsBullhornCandidateId));
                } else if (authToken) {
                  atsBullhornCandidateId= dispatch(fetchBHId(response.data.user.email, authToken?.accessToken));
                }
              }
              dispatch(isInitApiLoading(false));
              dispatch(fetchRecruiterDetails(response.data.candidates[0].id));
              dispatch(fetchAssignments(response.data.candidates[0].id));
              if (response.data.candidates[0].specialties.length > 0) {
                dispatch(
                  fetchSpecialties(response.data.candidates[0].specialties[0], true, companyId)
                );
              }
            }
            if (atsBullhornCandidateId && companyId && authToken?.accessToken) {
              dispatch(
                fetchCandidateCredentials(atsBullhornCandidateId, companyId, authToken?.accessToken)
              );
              if (!window.location.pathname.includes("result")) {
              await dispatch(fetchMyJobs(atsBullhornCandidateId, companyId, authToken?.accessToken));
              }
            }
            const gaVariable = {
              dimension1: response.data.user.id,
              dimension2: orgId,
            };
            Analytics.setGlobalVariables(gaVariable);

            dispatch(logInSuccess(response.data));
            if (
              response.data.onboardingScoutToWeboneLambdaResponse &&
              response.data.onboardingScoutToWeboneLambdaResponse.response
            ) {
              const onBoardingDataObject = {
                response: response.data.onboardingScoutToWeboneLambdaResponse.response,
                triggeringPoint: "init",
              };
              if (enableWorkHistory) {
                const workHistoryResponse = await request(GET_ALL_WORK_EXPERIENCE, {
                  email: response.data.user.email,
                  isDeleted: workExpIsDeleted,
                });
                if (
                  workHistoryResponse?.getAllWorkExperience?.status === 200 &&
                  workHistoryResponse?.getAllWorkExperience?.content.length > 0
                ) {
                  onBoardingDataObject.response.onboarding.workExperience =
                    workHistoryResponse?.getAllWorkExperience?.content[0]?.candidateWorkExperience?.workExperience;
                  onBoardingDataObject.response.onboarding.meta.completedSections.workExperience =
                    workHistoryResponse?.getAllWorkExperience?.content[0]?.candidateWorkExperience?.meta?.completedSections?.workExperience;
                } else if (
                  workHistoryResponse?.getAllWorkExperience?.status === 200 &&
                  workHistoryResponse?.getAllWorkExperience?.content.length === 0
                ) {
                  onBoardingDataObject.response.onboarding.workExperience = [];
                  onBoardingDataObject.response.onboarding.meta.completedSections.workExperience = false;
                }

                // check for isLegal and Update Onboarding Meta for Completed Steps
                if (
                  enableIsLegalQuestion &&
                  response.data.onboardingScoutToWeboneLambdaResponse.response?.onboarding
                    ?.specialtiesAndEducation &&
                  response.data.onboardingScoutToWeboneLambdaResponse.response.onboarding
                    .specialtiesAndEducation?.isLegal === null
                ) {
                  onBoardingDataObject.response.onboarding.meta.completedSections.workExperience = false;
                }
              }
              if (referencesResponse) {
                // Need to explicitly blank data based on new code changes(Profile table)
                onBoardingDataObject.response.onboarding.meta.completedSections.references = false;
                onBoardingDataObject.response.onboarding.references = [];
                if (referencesResponse?.getAllReferences?.content.length > 0) {
                  const references = combinedReferences(referencesResponse);
                  let referencesMeta = false;
                  for (let i = 0; i < referencesResponse.getAllReferences.content.length; i++) {
                    if (
                      Number(referencesResponse.getAllReferences.content[String(i)].companyId) ===
                        Number(company.id) &&
                      references.length > 0
                    ) {
                      referencesMeta =
                        referencesResponse.getAllReferences.content[String(i)].candidateReference
                          ?.meta?.completedSections?.references;
                    }
                  }
                  onBoardingDataObject.response.onboarding.meta.completedSections.references =
                    referencesMeta;
                  onBoardingDataObject.response.onboarding.references = references;
                }
              }
             if (enableAppPIIData) {
               const piiTokenValues = await fetchPIITokenValues(response.data.user?.email);
               onBoardingDataObject.response.onboarding.basicInfo.socialSecurityNumber =
                 piiTokenValues?.ss_num || null;
               onBoardingDataObject.response.onboarding.basicInfo.dateOfBirth =
                 piiTokenValues?.dob || null;
               onBoardingDataObject.response.onboarding.basicInfo.gender =
                 piiTokenValues?.gender || null;
               onBoardingDataObject.response.onboarding.basicInfo.ethnicity =
                 piiTokenValues?.ethnicity || null;
             }
              dispatch(fetchOnboardingInfoSuccess(onBoardingDataObject));
            }
          }
          saveUsernameInLocalStorage(response.data.user.email);
          // dispatch(removeStyleClassFromBody);
          if (
            window.location.pathname === "/login" ||
            window.location.pathname.includes("/auth/relay") ||
            window.location.pathname === "/"
          ) {
            // This is for login, sign up and autologin from WCM page
            if (path) {
              dispatch(push(path));
            } else {
              dispatch(push(HOME));
            }
          } else if (window.location.pathname.includes("/login")) {
            dispatch(push(`${HOME}?verifyPopUp=true`));
          }

          let onboardingSpecialtiesAndEducation;
          if (
            response.data.onboardingScoutToWeboneLambdaResponse &&
            response.data.onboardingScoutToWeboneLambdaResponse.response
          ) {
            onboardingSpecialtiesAndEducation =
              response.data.onboardingScoutToWeboneLambdaResponse.response?.onboarding
                ?.specialtiesAndEducation;
          }
          const currentDate = moment.utc().format("yyyy-MM-DD HH:mm:ss");

          const segmentEventName = i18n.t("segment.userSignedIn");
          const segmentEventProps = {
            id: response?.data?.user?.id,
            userName: response?.data?.user?.username,
            firstName: response?.data?.user?.firstname,
            lastName: response?.data?.user?.lastname,
            email: response?.data?.user?.email,
            profession: onboardingSpecialtiesAndEducation?.specialty?.profession,
            specialty: onboardingSpecialtiesAndEducation?.specialty?.primarySpecialty,
            company: response?.data?.candidates[0]?.companyId,
            origin,
            lastLoginDate: currentDate,
          };
          Analytics.segmentTrackEvent(segmentEventName, segmentEventProps);
        }
      })
      .catch((error) => {
        dispatch(logInFailure(error));
        dispatch(updateReferencesLoadingFlag(false));
        if (error && error.message) {
          if (error.message.includes("400")) {
            dispatch(isCandidateListEmpty(true));
          }
        }
        throw new Error(`Error occurred in saveTokenInLocalStorage method: ${error.message}`);
      });
  };
};

export const saveTokenInLocalStorageAndFillState =
  (idToken, refreshToken, companyId, path) => (dispatch) => {
    saveTokensInLocalStorage(idToken, refreshToken);
    initApp(companyId, path)(dispatch);
  };

export const getConitoTokenByJwt = (jwtToken, companyId) => (dispatch) => {
  API.get(LOGIN_JWT_URL + jwtToken)
    .then((response) => {
      localStorage.setItem("historyPathName", "/auth/relay");
      if (response.data.token === null) {
        dispatch(push(LOGIN));
      } else {
        localStorage.setItem("isTokenEmpty", false);
        // dispatch(removeStyleClassFromBody);
        const parsedToken = JSON.parse(response.data.token);
        dispatch(
          saveTokenInLocalStorageAndFillState(
            parsedToken.idToken.jwtToken,
            parsedToken.refreshToken.token,
            companyId,
            null
          )
        );
      }
    })
    .catch((error) => {
      console.error("Error occurred in getConitoTokenByJwt, JWT sent must be invalid:", error);
      localStorage.clear();
      localStorage.setItem("historyPathName", "/auth/relay");
      dispatch(detectEmptyToken(true));
      dispatch(push(LOGIN));
      localStorage.setItem("isTokenEmpty", true);
    });
};

const decodeJwtToken = (token, key) => {
  try {
    return jwt.decode(token, key);
  } catch (error) {
    console.error("UUID is invalid, returning error:", error);
    return "error";
  }
};

export const getJwtTokenForAutoLogin = (uuid) => (dispatch) => {
  const jwtToken = decodeJwtToken(uuid, config.JWT_REDIRECT_SECRET_KEY);
  if (jwtToken !== "error") {
    API.get(REDIRECT_JWT_URL + jwtToken.transactionId)
      .then((response) => {
        dispatch(push(LOGIN_JWT_URL + response.data.token));
      })
      .catch((error) => {
        console.error("Error occurred in getJwtTokenForAutoLogin, UUID is invalid:", error);
        localStorage.clear();
        dispatch(push(LOGIN));
      });
  } else {
    localStorage.clear();
    dispatch(push(LOGIN));
  }
};

export const isUserPresentInSystem = (email, companyCode, companyId) => (dispatch) => {
  API.get(`/users/resetPassword/${email}/${companyCode}/${companyId}`)
    .then(() => {
      dispatch(
        forgotPassword({
          showResend: true,
          userDoesNotExist: false,
          passResetEmailSent: true,
          forgotPassLoading: false,
          resetPassAttempts: true,
        })
      );
    })
    .catch((error) => {
      let userDoesNotExist;
      let apiError;
      if (error.message.includes("404")) {
        userDoesNotExist = true;
        apiError = false;
      } else {
        userDoesNotExist = false;
        apiError = true;
      }
      dispatch(
        forgotPassword({
          userDoesNotExist,
          passResetEmailSent: false,
          forgotPassLoading: false,
          resetPassApiError: apiError || false,
        })
      );
      console.error("Error occurred in isUserPresentInSystem, error recevied:", error);
    });
};

const parseJwt = (token) => {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join("")
  );

  return JSON.parse(jsonPayload);
};

const signInUser = async (email, password, dispatch, companyId) => {
  const tokenObject = await signIn(email, password);
  dispatch(
    saveTokenInLocalStorageAndFillState(
      tokenObject.idToken.jwtToken,
      tokenObject.refreshToken.token,
      companyId,
      null
    )
  );
};

export const verifyToken = (jwtToken, password, companyId, passwordCreated) => (dispatch) => {
  const requestBody = {
    token: jwtToken,
    password,
  };
  API.post("users/resetPassword/verify", requestBody)
    .then(async (response) => {
      if (response.data.status === false) {
        dispatch(push(HOME));
      } else if (response.data.action.toLowerCase() === "verify")
        dispatch(push(`/password-recovery/${jwtToken}`));
      else {
        const emailRetrieved = parseJwt(jwtToken).email;
        if (emailRetrieved == null) dispatch(push(HOME));
        else {
          await signInUser(emailRetrieved, password, dispatch, companyId);
          if (passwordCreated) {
            Analytics.segmentTrackEvent("User Password Created", {});
            dispatch(push(`${HOME}?passwordCreated=true`));
          } else {
            dispatch(push(HOME));
          }
        }
      }
    })
    .catch((error) => {
      console.error("Error occurred in verifyToken, error recevied:", error);
      dispatch(push(HOME));
    });
};

// eslint-disable-next-line no-unused-vars
export const revokeRefreshToken = () => (dispatch) => {
  const details = {
    client_id: clientId,
    token: getRefreshToken(),
    grant_type: "refresh_token",
  };
  let formBody = [];

  Object.entries(details).forEach(([property, value]) => {
    const encodedKey = encodeURIComponent(property);
    const encodedValue = encodeURIComponent(value);
    formBody.push(`${encodedKey}=${encodedValue}`);
  });

  formBody = formBody.join("&");
  axios
    .post(refreshTokenRevokeUrl, formBody, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      withCredentials: true,
      crossDomain: true,
    })
    .catch((error) => {
      console.error("Error in revoking refresh token:", error);
    });
};

export const CHANGE_IS_FIRST_LOGIN_FLAG = "CHANGE_IS_FIRST_LOGIN_FLAG";
export const changeIsFirstLoginFlag = (payload) => ({ type: CHANGE_IS_FIRST_LOGIN_FLAG, payload });

export const LOGIN_STATUS = "LOGIN_STATUS";
export const loginStatus = (payload, origin) => ({ type: LOGIN_STATUS, payload, origin });

export const JOB_PREFERENCES_FLAG = "JOB_PREFERENCES_FLAG";
export const updateJobPreferencesFlag = (payload) => ({ type: JOB_PREFERENCES_FLAG, payload });

export const getAllWorkExperiences = (userEmail, onBoardingData) => (dispatch) => {
  const onBoardingDataObject = onBoardingData;
  if (enableWorkHistory) {
    request(GET_ALL_WORK_EXPERIENCE, {
      email: userEmail,
      isDeleted: workExpIsDeleted,
    })
      .then((workHistoryResponse) => {
        if (
          workHistoryResponse?.getAllWorkExperience?.status === 200 &&
          workHistoryResponse?.getAllWorkExperience?.content.length > 0
        ) {
          onBoardingDataObject.response.onboarding.workExperience =
            workHistoryResponse?.getAllWorkExperience?.content[0]?.candidateWorkExperience?.workExperience;
          onBoardingDataObject.response.onboarding.meta.completedSections.workExperience =
            workHistoryResponse?.getAllWorkExperience?.content[0]?.candidateWorkExperience?.meta?.completedSections?.workExperience;
        } else if (
          workHistoryResponse?.getAllWorkExperience?.status === 200 &&
          workHistoryResponse?.getAllWorkExperience?.content.length === 0
        ) {
          onBoardingDataObject.response.onboarding.workExperience = [];
          onBoardingDataObject.response.onboarding.meta.completedSections.workExperience = false;
        }
        // check for isLegal and Update Onboarding Meta for Completed Steps
        if (
          enableIsLegalQuestion &&
          onBoardingDataObject.response?.onboarding?.specialtiesAndEducation &&
          onBoardingDataObject.response.onboarding?.specialtiesAndEducation?.isLegal === null
        ) {
          onBoardingDataObject.response.onboarding.meta.completedSections.workExperience = false;
        }
        dispatch(fetchOnboardingInfoSuccess(onBoardingDataObject));
      })
      .catch((error) => {
        console.error("Error occurred while fetching all work experiences", error);
      });
  }
};
