import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useTranslation, withTranslation } from "react-i18next";
import WhatshotIcon from "@mui/icons-material/Whatshot";
import { fetchMspJobs } from "src/redux/jobs/SearchCrossCountry/action";
import config from "src/config";
import { RecommendedJobsSourceTypes } from "src/types/receommendedJobsSource";
import { JobCardProps } from "../../job-card/JobCard";
import styles from "./HotJobs.module.scss";
import JobSlider from "../../job-card/slider/JobSlider";
import JobCardList from "../../job-card/list/JobCardList";

const mspJobsSize = config.MSP_JOBS_COUNT;

interface GetMspJobs {
  isSuccess: boolean
  code: number
  developerMessage: string
  userMessage: string
  embedded: {
    results: any
    tags: Array<any>
    resultOfType: any
    aggregations: any
  }
  page: {
    size: number
    number: number
    totalPages: number
    totalElements: any
  }
}

interface QuickApplyEligibleProps {
  onboardingInfo: any;
  companyId: any;
  mspJobs: GetMspJobs | null;
  fetchMspJobs: any;
}

const QuickApplyEligible = ({
  onboardingInfo,
  companyId,
  mspJobs,
  fetchMspJobs: getMspJobs
}: QuickApplyEligibleProps) => {
  const { t } = useTranslation();

  const skeletonCardOptions = Array.from({ length: 4 }).map((_) => ({
    job: undefined,
    headerOptions: {},
    contentOptions: {},
    footerOptions: {}
  } as JobCardProps));
  const jobCardOptions = mspJobs?.embedded?.results?.map((job) => ({
    job,
    contentOptions: {
      chips: [{ type: "hot-job", size: "compact" }]
    },
    footerOptions: {},
    campaignCode: "Hot Jobs",
    sourceType: "Homepage",
    jobsSource: RecommendedJobsSourceTypes.NONE,
  } as JobCardProps)) ?? [];

  const isLoading = mspJobs == null;
  const [hasFeteched, setHasFetched] = useState<boolean>(false);

  const { specialtiesAndEducation: onboardingSpecialty } = onboardingInfo;
  const { profession, primarySpecialty } = onboardingSpecialty?.specialty ?? {};

  useEffect(() => {
    if (profession && primarySpecialty && companyId && hasFeteched === false) {
      setHasFetched(true);
      getMspJobs({
        profession,
        specialty: primarySpecialty,
        companyId,
        size: parseInt(mspJobsSize!, 10),
      });
    }
  }, [profession, primarySpecialty, companyId, mspJobsSize]);

  if (!isLoading && (mspJobs?.embedded?.results == null || mspJobs?.embedded?.results?.length === 0 || jobCardOptions.length === 0)) {
    return null;
  }

  return (
    <div className={styles.main}>
      <div className={styles.sub}>
        <div className={styles.heading}>
          <WhatshotIcon />
          {t("dashboard.hotJobs.hotJobsHeadingText")}
        </div>

        <div className={styles.jobSlider}>
          <JobSlider
            jobCardOptions={isLoading ? skeletonCardOptions : jobCardOptions}
            settings={{
              xs: {
                swipe: !isLoading,
              },
              md: {
                dots: !isLoading,
              }
            }}
          />
        </div>

        <div className={styles.jobCardList}>
          <JobCardList
            jobCardOptions={(isLoading ? skeletonCardOptions : jobCardOptions).slice(0, 4)}
          />
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  onboardingInfo: state.onBoarding,
  companyId: state.company.company.id,
  mspJobs: state.jobs.mspJobs,
});

const mapDispatchToProps = (dispatch) => ({
  fetchMspJobs: (query) => dispatch(fetchMspJobs(query)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(QuickApplyEligible));