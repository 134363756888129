import { Fade, Modal, IconButton } from "@material-ui/core";
import closeIcon from "src/imagesV2/FacilityDetailsModal/closeIcon.svg";
import { facilityCustomContentData } from "src/components/common/MainContent/JobResults/common";
// eslint-disable-next-line import/no-extraneous-dependencies
import DOMPurify from "dompurify";
import styles from "./FacilityDetailsModal.module.scss";

interface FacilityDetailsModalProps {
  isOpen: boolean;
  handleClose: () => void;
  facilityCustomContent: facilityCustomContentData["info"];
}

const FacilityDetailsModal = ({
  isOpen,
  handleClose,
  facilityCustomContent,
}: FacilityDetailsModalProps) => {
  // Sanitizing for XSS vulnerabilities for HTML, Iframe and SVG.
  const updatedFacilityCustomContent = DOMPurify.sanitize(facilityCustomContent, {
    WHOLE_DOCUMENT: true,
    ADD_TAGS: ["iframe", "a"],
    ADD_ATTR: ["title", "allow", "referrerpolicy", "allowfullscreen", "target"],
  });

  return (
    <Modal open={isOpen} onClose={handleClose} closeAfterTransition className={styles.modal}>
      <Fade in={isOpen}>
        <div className={styles.content}>
          {/* Head Content */}
          <div className={styles.headeContent}>
            <div className={styles.heading}>
              <span>Facility Details</span>
            </div>
            <div className={styles.closeIconContainer}>
              <IconButton aria-label="close" onClick={handleClose}>
                <img src={closeIcon} alt="close-icon" />
              </IconButton>
            </div>
          </div>
          {/* Custom Content */}
          <div className={styles.mainContent}>
           {/* eslint-disable-next-line react/no-danger */}
            <div dangerouslySetInnerHTML={{ __html: updatedFacilityCustomContent }} />
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

export default FacilityDetailsModal;
