import { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import filter from "lodash/filter";
import {
  BASIC_INFO_PAGE,
  EDU_SPEC_PAGE,
  WORK_EXP_PAGE,
  SKILL_CHECKLIST_OVERVIEW_PAGE,
  SKILL_CHECKLIST_DETAILS_PAGE,
  PAYROLL_TIMESHEET,
  CERTIFICATIONS_LICENSES,
  REFERENCES_PAGE,
  PAYROLL_PAYSTUBS,
} from "src/router/routes";
import { TFunction, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import BasicInfoIcon from "@material-ui/icons/AccountBoxRounded";
import SpecialtyEduIcon from "@material-ui/icons/SchoolRounded";
import WorkExpIcon from "@material-ui/icons/BusinessCenterRounded";
import SkillsIcon from "@material-ui/icons/EmojiEventsRounded";
import DoneIcon from "@material-ui/icons/Done";
import DownArrow from "@material-ui/icons/ExpandMore";
import ReferencesIcon from "@mui/icons-material/PersonAddRounded";
import CertificationIcon from "src/imagesV2/CertificationIcon.svg";
import CertificationWhiteIcon from "src/imagesV2/CertificationWhiteIcon.svg";
import config from "src/config";
import { v4 as uuid } from "uuid";

const enablePaystubs = config.ENABLE_PAYSTUBS === "true";

interface CustomNavMobProps {
  type: string;
  onBoardingCompletedSteps: { [key: number]: boolean };
  t: TFunction<"translation", undefined>;
}

const CustomNavMob = ({ type, onBoardingCompletedSteps, t }: CustomNavMobProps) => {
  const history = useHistory();
  const location = useLocation();

  const setInitialSelectedOption = () => {
    let selectedValue = "";
    const { pathname } = location;
    if (type === "myProfile") {
      selectedValue = BASIC_INFO_PAGE;
      if (pathname === BASIC_INFO_PAGE) {
        selectedValue = t("myProfileSubNav.basicInfo");
      } else if (pathname === EDU_SPEC_PAGE) {
        selectedValue = t("myProfileSubNav.specialties&Education");
      } else if (pathname === WORK_EXP_PAGE) {
        selectedValue = t("myProfileSubNav.workExperience");
      } else if (pathname === REFERENCES_PAGE) {
        selectedValue = t("myProfileSubNav.references");
      } else if (
        pathname === SKILL_CHECKLIST_OVERVIEW_PAGE ||
        pathname === SKILL_CHECKLIST_DETAILS_PAGE
      ) {
        selectedValue = t("myProfileSubNav.skillChecklist");
      } else if (pathname === CERTIFICATIONS_LICENSES) {
        selectedValue = t("myProfileSubNav.certificationsLicenses");
      } else {
        selectedValue = t("myProfileSubNav.basicInfo");
      }
      return selectedValue;
    }
    if (type === "payroll") {
      selectedValue = "";
      if (enablePaystubs) {
        if (pathname === PAYROLL_TIMESHEET) {
          selectedValue = t("payroll.payrollNavbar.timesheet");
        } else if (pathname === PAYROLL_PAYSTUBS) {
          selectedValue = t("payroll.payrollNavbar.paystubs");
        }
      } else if (pathname === PAYROLL_TIMESHEET) {
        selectedValue = t("payroll.payrollNavbar.paystubsAndTimesheets");
      }
      return selectedValue;
    }
    return selectedValue;
  };

  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(setInitialSelectedOption());
  const [completed, setCompleted] = useState(onBoardingCompletedSteps);

  useEffect(() => {
    setCompleted(onBoardingCompletedSteps);
  }, [onBoardingCompletedSteps]);

  const setInitialValues = (value) => {
    if (type === "myProfile") {
      const optionsList = [
        "Basic Information",
        "Specialties & Education",
        "Work Experience",
        "References",
        "Skills Checklist",
        "Certifications/Licenses",
      ];

      const filterOptions = filter(optionsList, (option) => {
        return option !== value;
      });
      return filterOptions;
    }
    if (type === "payroll") {
      const optionsList = ["Paystubs & Timesheets"];
      if (enablePaystubs) {
        optionsList.splice(0, 1);
        optionsList.push("Timesheets");
        optionsList.push("Pay Statements");
      }
      const filterOptions = filter(optionsList, (option) => {
        return option !== value;
      });
      return filterOptions;
    }
    return [];
  };

  const [options, setOptions] = useState(setInitialValues(selectedOption));

  const handleOptionClicked = (value) => () => {
    setSelectedOption(setInitialSelectedOption());
    setIsOpen(false);
    setOptions(setInitialValues(value));
    if (value && type === "myProfile") {
      switch (value) {
        case t("myProfileSubNav.basicInfo"):
          history.push(BASIC_INFO_PAGE);
          break;
        case t("myProfileSubNav.specialties&Education"):
          history.push(EDU_SPEC_PAGE);
          break;
        case t("myProfileSubNav.workExperience"):
          history.push(WORK_EXP_PAGE);
          break;
        case t("myProfileSubNav.references"):
          history.push(REFERENCES_PAGE);
          break;
        case t("myProfileSubNav.skillChecklist"):
          history.push(SKILL_CHECKLIST_OVERVIEW_PAGE);
          break;
        case t("myProfileSubNav.certificationsLicenses"):
          history.push(CERTIFICATIONS_LICENSES);
          break;
        default:
          history.push(BASIC_INFO_PAGE);
          break;
      }
    }
    if (value && type === "payroll") {
      switch (value) {
        case t("payroll.payrollNavbar.paystubsAndTimesheets"):
          history.push(PAYROLL_TIMESHEET);
          break;
        case t("payroll.payrollNavbar.timesheet"):
          history.push(PAYROLL_TIMESHEET);
          break;
        case t("payroll.payrollNavbar.paystubs"):
          history.push(PAYROLL_PAYSTUBS);
          break;
        default:
          history.push(PAYROLL_TIMESHEET);
          break;
      }
    }
  };

  const handleToggle = () => setIsOpen(!isOpen);

  const navData = (option) => {
    const item = {
      timing: "",
      className: "",
      icon: <div />,
    };

    if (type === "myProfile") {
      if (!completed[0] && option === t("myProfileSubNav.basicInfo")) {
        item.timing = " - 2 mins";
        item.className = "onBStepperActiveStep";
        item.icon = <BasicInfoIcon />;
      } else if (!completed[1] && option === t("myProfileSubNav.specialties&Education")) {
        item.timing = " - 3 mins";
        item.className = "onBStepperActiveStep";
        item.icon = <SpecialtyEduIcon />;
      } else if (!completed[2] && option === t("myProfileSubNav.workExperience")) {
        item.timing = " - 5 mins";
        item.className = "onBStepperActiveStep";
        item.icon = <WorkExpIcon />;
      } else if (!completed[3] && option === t("myProfileSubNav.references")) {
        item.timing = " - 2 mins";
        item.className = "onBStepperActiveStep";
        item.icon = <ReferencesIcon />;
      } else if (!completed[4] && option === t("myProfileSubNav.skillChecklist")) {
        item.timing = " - 15 mins";
        item.className = "onBStepperActiveStep";
        item.icon = <SkillsIcon />;
      } else {
        item.className = "onBStepperCompleted";
        item.icon = <DoneIcon />;
      }

      if (option === t("myProfileSubNav.certificationsLicenses")) {
        item.className = "payrollNavMobActive";
        if (selectedOption === t("myProfileSubNav.certificationsLicenses"))
          item.icon = (
            <img src={CertificationWhiteIcon} alt={t("myProfileSubNav.certificationsLicenses")} />
          );
        else
          item.icon = (
            <img src={CertificationIcon} alt={t("myProfileSubNav.certificationsLicenses")} />
          );
      }
    }
    return item;
  };

  return (
    <div className="customNavWrap">
      <div className="customNavDropDown">
        <div
          role="button"
          tabIndex={0}
          onKeyDown={handleToggle}
          onClick={handleToggle}
          className={
            type === "myProfile" ? navData(selectedOption).className : "payrollNavMobActive"
          }
        >
          {type === "myProfile" && (
            <span className="onBStepIcons">{navData(selectedOption).icon}</span>
          )}
          {selectedOption}
          &nbsp;
          {type === "myProfile" && (
            <span className="onBStepMins">{navData(selectedOption).timing}</span>
          )}
          <span>
            {" "}
            <DownArrow className="dropDownIcon" />{" "}
          </span>
        </div>
        {isOpen && (
          <div>
            <ul className="custDropdown-ul">
              {options.map((option) => (
                <li key={uuid()}>
                  <div
                    className="customNavList"
                    onKeyDown={handleOptionClicked(option)}
                    onClick={handleOptionClicked(option)}
                    role="button"
                    tabIndex={0}
                  >
                    {type === "myProfile" && (
                      <span className="onBStepIcons">{navData(option).icon}</span>
                    )}
                    {option}
                    &nbsp;
                    {type === "myProfile" && (
                      <span className="onBStepMins">{navData(option).timing}</span>
                    )}
                  </div>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  onBoardingCompletedSteps: state.onBoarding.completedSteps,
});

export default connect(mapStateToProps)(withTranslation()(CustomNavMob));
