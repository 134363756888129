import Image from "react-bootstrap/Image";
import XperienceLogo from "src/imagesV2/xperience_rebrand_logo.svg";
import XperienceLogoMobile from "src/imagesV2/xperience_rebrand_logo_mobile.svg";
import { useMediaQuery } from "@material-ui/core";

interface LogoProps {
  className?: string;
  mobile?: boolean;
}

const Logo = ({ className , mobile}: LogoProps) => {
const isLarge = useMediaQuery("(min-width: 1200px)");
return(
  <Image
  src={(!isLarge || mobile) ? XperienceLogoMobile : XperienceLogo}
    className={`mainLogo ${className ?? ""}`}
  />
)
};

Logo.defaultProps = {
  className: "",
  mobile: undefined,
};

export default Logo;
