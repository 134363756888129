import { Component } from "react";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import { connect } from "react-redux";
import { TFunction, withTranslation } from "react-i18next";
import { Navbar, Nav } from "react-bootstrap";
import Divider from "@material-ui/core/Divider";
import { RouteComponentProps, withRouter } from "react-router-dom";
import BasicInfoIcon from "@material-ui/icons/AccountBoxRounded";
import SpecialtyEduIcon from "@material-ui/icons/SchoolRounded";
import WorkExpIcon from "@material-ui/icons/BusinessCenterRounded";
import PersonAddRoundedIcon from "@mui/icons-material/PersonAddRounded";
import SkillsIcon from "@material-ui/icons/EmojiEventsRounded";
import PaymentsIcon from "@mui/icons-material/Payments";
import FeedIcon from "@mui/icons-material/Feed";
import { History } from "history";
import config from "../../../config";
import {
  PAYROLL_TIMESHEET,
  BASIC_INFO_PAGE,
  EDU_SPEC_PAGE,
  WORK_EXP_PAGE,
  SKILL_CHECKLIST_OVERVIEW_PAGE,
  CERTIFICATIONS_LICENSES,
  LEGACY_PAGE,
  REFERENCES_PAGE,
  PAYROLL_PAYSTUBS,
} from "../../../router/routes";
import { handleSelectedPayrollMenu } from "../../../redux/payroll/action";
import {
  isHamburgerMenuOpen,
  isHamburgerSubMenuOpen,
  handleSelectedLocalNavigation,
  handleSelectedGlobalNavigation,
  handleSelectedSubNavigation,
} from "../../../redux/navigation/action";

const enablePaystubs = config.ENABLE_PAYSTUBS === "true";

interface HamburgerSubMenuV2Props {
  menuType: string;
  isTheHamburgerSubMenuOpen: (data: boolean) => void;
  handleTheSelectedPayrollMenu: (key: string) => void;
  setHamburgerMenuOpen: (data: boolean) => void;
  handleOverlay: (data: boolean) => void;
  history: History;
  location: History["location"];
  useProfileIframe: boolean;
  t: TFunction<"translation", undefined>;
}

class HamburgerSubMenuV2 extends Component<HamburgerSubMenuV2Props & RouteComponentProps> {
  handleActiveKey() {
    const {
      useProfileIframe,
      location: { search, pathname },
    } = this.props;
    let key = "";
    if (useProfileIframe) {
      if (search.includes("basic-info")) {
        key = BASIC_INFO_PAGE;
      } else if (search.includes("education-specialties")) {
        key = EDU_SPEC_PAGE;
      } else if (search.includes("work-exp")) {
        key = WORK_EXP_PAGE;
      } else if (search.includes("references")) {
        key = REFERENCES_PAGE;
      } else if (search.includes("skill-checklist")) {
        key = SKILL_CHECKLIST_OVERVIEW_PAGE;
      } else if (search.includes("certifications-and-licenses")) {
        key = CERTIFICATIONS_LICENSES;
      }
    } else {
      key = pathname;
    }
    return key;
  }

  closeSubMenu = () => {
    const { isTheHamburgerSubMenuOpen } = this.props;
    isTheHamburgerSubMenuOpen(false);
  };

  redirectToPayroll = (key) => {
    const {
      handleTheSelectedPayrollMenu,
      handleOverlay,
      setHamburgerMenuOpen,
      isTheHamburgerSubMenuOpen,
      history: { push },
    } = this.props;
    handleTheSelectedPayrollMenu(key);
    handleOverlay(false);
    setHamburgerMenuOpen(false);
    isTheHamburgerSubMenuOpen(false);
    push(key);
  };

  redirectToPages = (key) => {
    const {
      handleOverlay,
      setHamburgerMenuOpen,
      isTheHamburgerSubMenuOpen,
      useProfileIframe,
      history: { push },
    } = this.props;
    handleOverlay(false);
    setHamburgerMenuOpen(false);
    isTheHamburgerSubMenuOpen(false);
    if (!key.includes("https")) {
      if (useProfileIframe) {
        let location = {};
        if (key === BASIC_INFO_PAGE) {
          location = {
            pathname: LEGACY_PAGE,
            search: "?page=basic-info",
          };
        } else if (key === EDU_SPEC_PAGE) {
          location = {
            pathname: LEGACY_PAGE,
            search: "?page=education-specialties",
          };
        } else if (key === WORK_EXP_PAGE) {
          location = {
            pathname: LEGACY_PAGE,
            search: "?page=work-exp",
          };
        } else if (key === REFERENCES_PAGE) {
          location = {
            pathname: LEGACY_PAGE,
            search: "?page=references",
          };
        } else if (key === SKILL_CHECKLIST_OVERVIEW_PAGE) {
          location = {
            pathname: LEGACY_PAGE,
            search: "?page=skill-checklist",
          };
        } else if (key === CERTIFICATIONS_LICENSES) {
          location = {
            pathname: LEGACY_PAGE,
            search: "?page=certifications-and-licenses",
          };
        } else {
          location = {
            pathname: LEGACY_PAGE,
            search: "?page=basic-info",
          };
        }
        push(location);
      } else {
        push(key);
      }
    }
  };

  pageTitle() {
    const { menuType } = this.props;
    let title = "";
    switch (menuType) {
      case "jobs":
        title = "Jobs";
        break;
      case "payroll":
        title = "Payroll";
        break;
      case "needHelp":
        title = "Benefits";
        break;
      case "myProfile":
        title = "My Profile";
        break;
      default:
        title = "";
        break;
    }
    return title;
  }

  renderSubMenu() {
    const {
      menuType,
      location: { pathname },
      t,
    } = this.props;
    switch (menuType) {
      case "payroll":
        return (
          <div className="hamMenuNavBar">
            <Navbar>
              <Nav data-testid="paystubs" activeKey={pathname} onSelect={this.redirectToPayroll}>
                {!enablePaystubs && (
                  <Nav.Item>
                    <Nav.Link eventKey={PAYROLL_TIMESHEET} className="hamMenu">
                      <span className="hamNavLabel">
                        {t("payroll.payrollNavbar.paystubsAndTimesheets")}
                      </span>
                    </Nav.Link>
                  </Nav.Item>
                )}

                {enablePaystubs && (
                  <>
                    <Nav.Item>
                      <Nav.Link eventKey={PAYROLL_TIMESHEET} className="hamMenu">
                        <span className="myprofileIcon">
                          <FeedIcon />
                        </span>
                        <span className="hamNavLabel">{t("payroll.payrollNavbar.timesheet")}</span>
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey={PAYROLL_PAYSTUBS} className="hamMenu">
                        <span className="myprofileIcon">
                          <PaymentsIcon />
                        </span>
                        <span className="hamNavLabel">{t("payroll.payrollNavbar.paystubs")}</span>
                      </Nav.Link>
                    </Nav.Item>
                  </>
                )}
              </Nav>
            </Navbar>
          </div>
        );
      case "myProfile":
        return (
          <div data-testid="myProfileSection" className="hamMenuNavBar">
            <Navbar>
              <Nav activeKey={this.handleActiveKey()} onSelect={this.redirectToPages}>
                <Nav.Item>
                  <Nav.Link eventKey={BASIC_INFO_PAGE} className="hamMenu">
                    <span className="myprofileIcon">
                      <BasicInfoIcon />
                    </span>
                    <span className="hamNavLabel">{t("myProfileSubNav.basicInfo")}</span>
                  </Nav.Link>
                </Nav.Item>

                <Nav.Item>
                  <Nav.Link eventKey={EDU_SPEC_PAGE} className="hamMenu">
                    <span className="myprofileIcon">
                      <SpecialtyEduIcon />
                    </span>
                    <span className="hamNavLabel">
                      {t("myProfileSubNav.specialties&Education")}
                    </span>
                  </Nav.Link>
                </Nav.Item>

                <Nav.Item>
                  <Nav.Link eventKey={WORK_EXP_PAGE} className="hamMenu">
                    <span className="myprofileIcon">
                      <WorkExpIcon />
                    </span>
                    <span className="hamNavLabel">{t("myProfileSubNav.workExperience")}</span>
                  </Nav.Link>
                </Nav.Item>

                <Nav.Item>
                  <Nav.Link eventKey={REFERENCES_PAGE} className="hamMenu">
                    <span className="myprofileIcon">
                      <PersonAddRoundedIcon />
                    </span>
                    <span className="hamNavLabel">{t("myProfileSubNav.references")}</span>
                  </Nav.Link>
                </Nav.Item>

                <Nav.Item>
                  <Nav.Link eventKey={SKILL_CHECKLIST_OVERVIEW_PAGE} className="hamMenu">
                    <span className="myprofileIcon">
                      <SkillsIcon />
                    </span>
                    <span className="hamNavLabel">{t("myProfileSubNav.skillChecklist")}</span>
                  </Nav.Link>
                </Nav.Item>

                <Nav.Item>
                  <Nav.Link eventKey={CERTIFICATIONS_LICENSES} className="hamMenu">
                    <span className="myprofileIcon">
                      <span className="certLicencesBtnHamIcon" />
                    </span>
                    <span className="hamNavLabel certLicencesBtnHamTxt">
                      {t("myProfileSubNav.certificationsLicenses")}
                    </span>
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </Navbar>
          </div>
        );
      default:
        return <div />;
    }
  }

  render() {
    const pageTitle = this.pageTitle();
    return (
      <div>
        <div>
          <div
            className="p-3"
            role="button"
            tabIndex={0}
            onKeyDown={() => this.closeSubMenu()}
            onClick={() => this.closeSubMenu()}
          >
            <ChevronLeftIcon className="hamSubMenuBack" />
            <span className="hamSubMenuTxt">{pageTitle}</span>
          </div>
          <Divider className="hamNavLine" />
        </div>
        {this.renderSubMenu()}
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  isSubMenuOpen: state.navigation.isHamburgerSubMenuOpen,
  menuType: state.navigation.menuType,
  subNavigationKey: state.navigation.subNavigationKey,
  globalNavigationKey: state.navigation.globalNavigationKey,
  localNavigationKey: state.navigation.localNavigationKey,
  company: state.company.company,
  useProfileIframe: state.auth.useProfileIframe,
});
const mapDispatchToProps = (dispatch) => ({
  isTheHamburgerSubMenuOpen: (data) => dispatch(isHamburgerSubMenuOpen(data)),
  handleSelectedSubNavigation: (obj) => dispatch(handleSelectedSubNavigation(obj)),
  handleSelectedLocalNavigation: (obj) => dispatch(handleSelectedLocalNavigation(obj)),
  handleSelectedGlobalNavigation: (obj) => dispatch(handleSelectedGlobalNavigation(obj)),
  handleTheSelectedPayrollMenu: (key) => dispatch(handleSelectedPayrollMenu(key)),
  setHamburgerMenuOpen: (data) => dispatch(isHamburgerMenuOpen(data)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withRouter(HamburgerSubMenuV2)));
