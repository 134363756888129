import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import { withTranslation } from "react-i18next";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import styles from "src/components/common/MainContent/Payroll/Paystubs/PaystubsSkeleton/PaystubsSkeleton.module.scss";

const PaystubsSkeleton = () => {
  return (
    <>
      <Stack>
        <Stack spacing={6}>
          <Skeleton
            data-testid="skeleton-element"
            variant="rectangular"
            className={styles.skeletonWrapper}
            width={230}
            height={30}
          />
        </Stack>
        <Row className="mt-4">
          <Col md={5} lg={5} xl={5} className={styles.payStatementsSkeleton}>
            <Skeleton
              variant="rectangular"
              data-testid="skeleton-element"
              className={styles.iconSkeleton}
            />
            <Skeleton
              variant="rectangular"
              data-testid="skeleton-element"
              className={styles.dropdownSkeleton}
            />
          </Col>
          <Col md={3} lg={3} xl={3}>
            <Skeleton
              variant="rectangular"
              data-testid="skeleton-element"
              className={styles.payPeriodSkeleton}
            />
          </Col>
          <Col md={2} lg={2} xl={2}>
            <Skeleton
              variant="rectangular"
              data-testid="skeleton-element"
              className={styles.payRateSkeleton}
            />
          </Col>
          <Col md={2} lg={2} xl={2}>
            <Skeleton
              variant="rectangular"
              data-testid="skeleton-element"
              className={styles.viewButtonSkeleton}
            />
          </Col>
        </Row>
        <Stack>
          <Skeleton
            variant="rectangular"
            data-testid="skeleton-element"
            className={styles.grossPaySkeleton}
          />
        </Stack>

        <Stack className="mt-4">
          <Skeleton
            variant="rectangular"
            data-testid="skeleton-element"
            width={230}
            height={30}
            className={styles.skeletonWrapper}
          />
        </Stack>
        <Stack className="mt-3">
          <Skeleton
            variant="rectangular"
            data-testid="skeleton-element"
            className={styles.tableSkeleton}
          />
        </Stack>
        <Stack className="mt-4">
          <Skeleton
            variant="rectangular"
            data-testid="skeleton-element"
            width={230}
            height={30}
            className={styles.skeletonWrapper}
          />
        </Stack>
        <Stack className="mt-3">
          <Skeleton
            variant="rectangular"
            data-testid="skeleton-element"
            className={styles.tableSkeleton}
          />
        </Stack>
        <Stack className="mt-4">
          <Skeleton
            variant="rectangular"
            data-testid="skeleton-element"
            width={230}
            height={30}
            className={styles.skeletonWrapper}
          />
        </Stack>
        <Stack className="mt-3">
          <Skeleton
            variant="rectangular"
            data-testid="skeleton-element"
            className={styles.tableSkeleton}
          />
        </Stack>
      </Stack>
    </>
  );
};

export default withTranslation()(PaystubsSkeleton);
