import React, { useState, useRef } from "react";
import Popup from "reactjs-popup";
import SignaturePad from "react-signature-canvas";
import Button from "@material-ui/core/Button";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import dateFormat from "dateformat";
import { makeStyles } from "@material-ui/core/styles";
import { saveSkillChecklistSelected } from "../../../../redux/onBoarding/action";
import { PrimaryButton, SecondaryButton, TertiaryButton } from "../../Buttons";

const useStyles = makeStyles((theme) => ({
  signatureBtn: {
    marginTop: "2rem",
    float: "right",
  },
}));

const OnBoardingSignature = (props) => {
  const classes = useStyles();
  const [imageURL, setImageURL] = useState(
    props.skillChecklistSelected ? props.skillChecklistSelected.signature : null
  ); // create a state that will contain our image url
  const sigCanvas = useRef({});
  const [disableSignSave, setDisableSignSave] = useState(true);
  const signOnBegin = () => {
    setDisableSignSave(false);
  };
  /* a function that uses the canvas ref to trim the canvas
    from white spaces via a method given by react-signature-canvas
    then saves it in our state */
  const save = () => {
    const url = sigCanvas.current.getTrimmedCanvas().toDataURL("image/png");
    setImageURL(url);
    setDisableSignSave(true);
    props.skillUpdated(true);
    const skillChecklist = props.skillChecklistSelected;
    skillChecklist.signature = url;
    skillChecklist.complete = true;
    skillChecklist.lastUpdated = dateFormat(new Date(), "yyyy-mm-dd HH:MM:ss");
    skillChecklist.completedDate = dateFormat(new Date(), "yyyy-mm-dd HH:MM:ss");
    props.saveSkillChecklistSelected(skillChecklist);
    props.showSaveBtn(true, url);
  };
  const renderPopUp = (buttonType) => {
    let button;
    if (buttonType === "update") {
      button = (
        <TertiaryButton>{props.t("onBoarding.skillChecklist.updateSignature")}</TertiaryButton>
      );
    } else if (buttonType === "addSign") {
      button = (
        <div className="d-flex justify-content-center mt-4">
          <PrimaryButton size="medium">
            {props.t("onBoarding.skillChecklist.signToComplete")}
          </PrimaryButton>
        </div>
      );
    }
    return (
      <Popup modal trigger={button} closeOnDocumentClick={false} className="OnBSignaturePopup">
        {(close) => (
          <>
            <div>{props.t("onBoarding.skillChecklist.signPopUpText")}</div>
            <br />
            <SignaturePad
              ref={sigCanvas}
              canvasProps={{
                className: "signatureCanvas",
              }}
              onBegin={() => signOnBegin()}
            />
            {/* Button to trigger save canvas image */}
            <div className="d-flex justify-content-center mt-2">
              <br />
              <SecondaryButton
                disabled={disableSignSave}
                onClick={(e) => {
                  save();
                  close();
                }}
              >
                {props.t("onBoarding.skillChecklist.saveBtn")}
              </SecondaryButton>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <PrimaryButton
                onClick={() => {
                  close();
                  setDisableSignSave(true);
                }}
              >
                {props.t("onBoarding.skillChecklist.cancelBtn")}
              </PrimaryButton>
            </div>
          </>
        )}
      </Popup>
    );
  };
  return (
    <div ref={props.innerRef} className="onBSignatureWrap mb-5">
      {/* if our we have a non-null image url we should
      show an image and pass our imageURL state to it */}
      {imageURL ? (
        <div className="onBSignatureRow">
          <span className="onBSinatureBox">
            <img src={imageURL} alt="my signature" />
          </span>
          <div className={classes.signatureBtn}>{renderPopUp("update")}</div>
        </div>
      ) : props.isSkillCompleted ? (
        renderPopUp("addSign")
      ) : null}
    </div>
  );
};

const mapStateToProps = (state) => ({
  isSkillCompleted: state.onBoarding.selectedSkillCompleted,
  skillChecklistSelected: state.onBoarding.skillChecklistSelected,
});

const mapDispatchToProps = (dispatch) => ({
  saveSkillChecklistSelected: (skillSelected) =>
    dispatch(saveSkillChecklistSelected(skillSelected)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(OnBoardingSignature));
