import React from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import Container from "react-bootstrap/Container";
import { withRouter } from "react-router-dom";
import config from "../../../config";
import IFrameLoader from "../IFrameLoader";
import MissingApplicantId from "../MainContent/Home/MissingApplicantIdError";
import { fetchAccessToken } from "../../../redux/candidate/action";
import API from "../../../services/Api";

const enableReferences = config.ENABLE_REFERENCES === "true";
class OnBoardingLegacy extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      accessToken: null,
    };
  }

  componentDidMount() {
    if (!this.props.isCandidateListEmpty && this.props.applicantId) {
      API.get(`${config.API_BASE_HOST}/ui/v1/users/es/token/${this.props.applicantId}`)
        .then((response) => {
          this.setState({ accessToken: response.data.response.accessToken });
        })
        .catch(this.setState({ accessToken: null }));
    }
  }

  iFrame() {
    const { applicantId } = this.props;
    const searchParam = this.props.history.location.search;
    let src = "";
    if (applicantId && this.state.accessToken) {
      src = `${config.ABLE_APPLY_BASIC_INFO}?access_token=${this.state.accessToken}&applicant_id=${this.props.applicantId}`;
      if (searchParam.includes("basic-info")) {
        src = `${config.ABLE_APPLY_BASIC_INFO}?access_token=${this.state.accessToken}&applicant_id=${this.props.applicantId}`;
      } else if (searchParam.includes("education-specialties")) {
        src = `${config.ABLE_APPLY_EDUCATION_HISTORY}?access_token=${this.state.accessToken}&applicant_id=${this.props.applicantId}`;
      } else if (searchParam.includes("work-exp")) {
        src = `${config.ABLE_APPLY_WORK_HISTORY}?access_token=${this.state.accessToken}&applicant_id=${this.props.applicantId}`;
      } else if (enableReferences && searchParam.includes("references")) {
        src = `${config.ABLE_APPLY_REFERENCES}?access_token=${this.state.accessToken}&applicant_id=${this.props.applicantId}`;
      } else if (searchParam.includes("skill-checklist")) {
        src = `${config.ABLE_APPLY_SKILL_CHECKLIST}?access_token=${this.state.accessToken}&applicant_id=${this.props.applicantId}`;
      } else if (searchParam.includes("certifications-and-licenses")) {
        src = `${config.ABLE_APPLY_CERTIFICATIONS_LICENSES}?access_token=${this.state.accessToken}&applicant_id=${this.props.applicantId}`;
      }
    }
    return (
      <>
        {applicantId && this.state.accessToken ? (
          <IFrameLoader src={src} />
        ) : applicantId === null ? (
          <MissingApplicantId />
        ) : (
          <IFrameLoader />
        )}
      </>
    );
  }

  render() {
    return (
      <Container fluid className="pl-0 pr-0">
        <div className="">{this.iFrame()}</div>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  applicantId: state.candidate.applicantId,
  isCandidateListEmpty: state.auth.isCandidateListEmpty,
  company: state.company.company,
});

const mapDispatchToProps = (dispatch) => ({
  fetchAccessToken: (payload) => dispatch(fetchAccessToken(payload)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withRouter(OnBoardingLegacy)));
