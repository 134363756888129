import { useTranslation, withTranslation } from "react-i18next";
import { payStubDetailsTypes } from "src/components/common/MainContent/Payroll/Paystubs/Tables";
import { formatCurrency } from "src/utils/formatCurrency";
import styles from "./ReimbursementsTable.module.scss"


interface ReimbursementsTableProps {
  payStubDetails: payStubDetailsTypes;
}

const ReimbursementsTable = ({ payStubDetails }: ReimbursementsTableProps) => {
  const { t } = useTranslation();
  const totalReimbursementAmount: number = parseFloat(payStubDetails?.totalReimbursementAmount);
  const showReimbursements: boolean = totalReimbursementAmount !== 0;

  return (
    <>
      {showReimbursements && (
        <div className={styles.paystubsTable}>
          <h3 className={styles.tableHeading}>
            {t("payStubs.reimbursements")} &nbsp;&nbsp;
            {formatCurrency(payStubDetails?.totalReimbursementAmount)}
          </h3>
          <table>
            <thead>
              <tr>
                <th>{t("payStubs.item")}</th>
                <th>{t("payStubs.type")}</th>
                <th>{t("payStubs.amount")}</th>
              </tr>
            </thead>
            <tbody>
              {payStubDetails?.reimbursements
                ?.filter((reimbursement) => parseFloat(reimbursement.amount) !== 0)
                .map((reimbursement) => (
                  <tr key={reimbursement.description}>
                    <td>{reimbursement.description}</td>
                    <td>{reimbursement.deduction}</td>
                    <td>{formatCurrency(reimbursement.amount)}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};
export default withTranslation()(ReimbursementsTable);
