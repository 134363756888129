import { push } from "connected-react-router";
import orderBy from "lodash/orderBy";
import config from "src/config"
import { MY_JOBS } from "../../../router/routes";
import API from "../../../services/Api";
import i18n from "../../../translations/i18n";
import Analytics from "../../../services/Analytics";
import { GET_MY_JOBS , GET_MY_JOBS_ELIGIBILITY_GAUGE} from "../../../services/GraphQL/graph.query";
import { graphqlRequest } from "../../../services/GraphQL/GraphQL";

const enableEligibilityMeter = config.ENABLE_ELIGIBILITY_METER === "true";
 
export const FETCH_MY_JOBS_REQUEST = "FETCH_MY_JOBS_REQUEST";
export const fetchMyJobsRequest = (payload) => ({
  type: FETCH_MY_JOBS_REQUEST,
  payload: payload ?? {},
});

export const FETCH_MY_JOBS_SUCCESS = "FETCH_MY_JOBS_SUCCESS";
export const fetchMyJobsSuccess = (payload) => ({ type: FETCH_MY_JOBS_SUCCESS, payload });

export const FETCH_MY_JOBS_FAILURE = "FETCH_MY_JOBS_FAILURE";
export const fetchMyJobsFailure = (payload) => ({ type: FETCH_MY_JOBS_FAILURE, payload });

const trackGaEvent = (dimensions) => {
  const event = {
    category: i18n.t("googleAnalytics.category.jobs"),
    action: i18n.t("googleAnalytics.action.filterJobResults"),
    label: i18n.t("googleAnalytics.label.filterJobs"),
  };
  Analytics.sendPageview(window.location.pathname, dimensions);
  Analytics.sendEvent(event);
};

export const UPDATE_MY_JOB_OBJECT = "UPDATE_MY_JOB_OBJECT";
export const updateMyJobsObject = (payload) => {
  let result = null;
  if (payload?.myJobs?.embedded?.results) {
    result = payload?.myJobs?.embedded?.results;
  } else if (payload?.myJobs?._embedded?.results) {
    result = payload?.myJobs?._embedded?.results;
  }
  result?.forEach((job, index) => {
    if (payload.jobId === job.id) {
      switch (payload.action) {
        case "favorite":
          result[String(index)].isFavorite = payload.request.isActive;
          break;

        case "apply":
          result[String(index)].isApplied = payload.request.isActive;
          break;

        case "email":
          result[String(index)].isEmailed = payload.request.isActive;
          break;

        default:
          break;
      }
    }
  });

  const payloadUpdated = { ...payload };
  if (payloadUpdated?.myJobs?.embedded?.results) {
    payloadUpdated.myJobs.embedded.results = result;
  } else if (payloadUpdated?.myJobs?._embedded?.results) {
    payloadUpdated.myJobs._embedded.results = result;
  }

  return { type: UPDATE_MY_JOB_OBJECT, payload: payloadUpdated };
};

const filterMyJobs = (payload) => {
  const appliedStatuses = [
    "Applied",
    "Applied - Submitted",
    "Application Closed",
    "Applied - New Offer",
  ];

  const filtered = {
    favorite: payload.filter(
      (job) => job.isFavorite && job.jobStatus === "" && !job.isQuickApplied
    ),
    email: payload.filter((job) => job.isEmailed && job.jobStatus === "" && !job.isQuickApplied),
    apply: payload.filter((job) => job.isApplied && job.jobStatus === "" && !job.isQuickApplied),
    quickApply: payload.filter(
      (job) =>
        appliedStatuses.includes(job.jobStatus) || (job.isQuickApplied && job.jobStatus === "")
           ).map((job) => ({ ...job, isJobAppliedOrAssigned: true })),
    assignments: payload.filter(
      (job) => job.jobStatus !== "" && !appliedStatuses.includes(job.jobStatus)
        ).map((job) => ({ ...job, isJobAppliedOrAssigned: true })),
  };

  const getPageObj = (category) => ({
    size: 12,
    number: 1,
    totalElements: category.length,
    totalPages: Math.ceil(category.length / 12),
  });
  const filteredObj = {};
  Object.keys(filtered).forEach((category) => {
    filteredObj[String(category)] = {
      _embedded: { results: filtered[String(category)] },
      page: getPageObj(filtered[String(category)]),
    };
  });

  return filteredObj;
};

export const fetchMyJobs = (bhId, companyId, authToken, candidateInformation, includeQuickApplyEligibility) => async (dispatch) => {
  const params = {
    actionFilters: "quickApply,apply,favorite,email",
    companyId: Number(companyId),
    bhCandidateId: Number(bhId),
    includeQuickApplyEligibility,
    candidateInformation
  };
  try {
    dispatch(fetchMyJobsRequest());
    let myJobsResponse;
    if (enableEligibilityMeter && includeQuickApplyEligibility && candidateInformation) {
      myJobsResponse = await graphqlRequest(GET_MY_JOBS_ELIGIBILITY_GAUGE, params, authToken);
    } else {
      myJobsResponse = await graphqlRequest(GET_MY_JOBS, params, authToken);
    } 
    const {myJobs} = myJobsResponse;
    if (myJobs?.code === 200) {
      dispatch({
        type: FETCH_MY_JOBS_SUCCESS,
        payload: {
          filteredJobs: filterMyJobs(myJobs.embedded.results),
          myJobs,
        },
      });
      const myJobsResults = filterMyJobs(myJobs?.embedded?.results);
      const latestJobSorting = orderBy(
        myJobsResults?.assignments?._embedded?.results,
        ["assignmentId"],
        ["desc"]
      );
      const latestJobStatus = latestJobSorting?.length > 0 ? latestJobSorting[0]?.jobStatus : null;
      Analytics.fetchMyJobsData(latestJobStatus);
    } else {
      console.error(myJobs?.developerMessage);
      dispatch(fetchMyJobsFailure(myJobs?.developerMessage));
    }
  } catch (error) {
    dispatch(fetchMyJobsFailure(error));
    console.error(error);
  }
};

export const SET_MY_JOBS_PAGE = "SET_MY_JOBS_PAGE";
export const setMyJobsPage = (payload) => ({ type: SET_MY_JOBS_PAGE, payload });

export const SET_MY_JOBS_FILTER = "SET_MY_JOBS_FILTER";
export const setMyJobsFilter = (payload) => ({ type: SET_MY_JOBS_FILTER, payload });
