import { withTranslation, TFunction } from "react-i18next";
import styles from "src/components/common/MainContent/JobDescription/Housing/Housing.module.scss";
import ProvidedHousingIcon from "src/imagesV2/ProvidedHousingIcon.svg";
import StipendIcon from "src/imagesV2/StipendIcon.svg";

interface IHousingDescription {
  t: TFunction<"translation", undefined>;
}

const HousingDescription = ({ t }: IHousingDescription) => {
  return (
    <>
      <div className={styles.housingHeading}>{t("jobs.housing.housingHeading")}</div>
      <div className={styles.Frame}>
        <div className={styles.Flex}>
          <div className={styles.FrameLeft}>
            <div className={styles.FrameHousingImg}>
              <div className={styles.ImgHousing}>
                <img src={StipendIcon} alt="StipendIcon" />
              </div>
              <div className={styles.ProvidedHousing}>
                {t("jobs.housing.housingStipendHeading")}
              </div>
            </div>
          </div>
          <p id="housingStipendBodyText" className={styles.providedHousingText}>
            {t("jobs.housing.housingStipendBody")}
          </p>
        </div>
        <div className={styles.Flex}>
          <div className={styles.FrameLeft}>
            <div className={styles.FrameHousingImg}>
              <div className={styles.ImgHousing}>
                <img src={ProvidedHousingIcon} alt="ProvidedHousingIcon" />
              </div>
              <div className={styles.ProvidedHousing}>
                {t("jobs.housing.providedHousingHeading")}
              </div>
            </div>
          </div>
          <p className={styles.providedHousingText}>{t("jobs.housing.providedHousingBody")}</p>
        </div>
        <div className={styles.housingSubHeading}>{t("jobs.housing.housingSubHeading")}</div>
      </div>
    </>
  );
};

export default withTranslation()(HousingDescription);
