import { Link } from "react-router-dom";
import queryString from "query-string";
import { connect } from "react-redux";
import { useEffect } from "react";
import { HOME } from "../../router/routes";
import getCompanyFromDomain from "../../services/Company";
import { saveTokenInLocalStorageAndFillState } from "../../redux/auth/action";

function AuthVerify(props) {
  useEffect(() => {
    const authToken = queryString.parse(props.location.search);
    const company = getCompanyFromDomain();
    props.pullUserInformation(authToken.ctoken || "", authToken.rtoken || "", company.id, HOME);
  }, []);
  return <></>;
}
const mapDispatchToProps = (dispatch) => ({
  pullUserInformation: (idToken, refreshToken, companyId, path) =>
    dispatch(saveTokenInLocalStorageAndFillState(idToken, refreshToken, companyId, path)),
});
const mapStateToProps = () => {};
export default connect(mapStateToProps, mapDispatchToProps)(AuthVerify);
