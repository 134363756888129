import config from "src/config";

const createShareURL = (companyId: number, jobId: string) => {
  const { SHARE_LINK_CCA, SHARE_LINK_CCN,JOB_SHARE_CAMPAIGN_CODE } = config;
 
  const baseUrl = companyId === 2 ? SHARE_LINK_CCA : SHARE_LINK_CCN;
  const url = `${baseUrl}${jobId}?campaignCode=${JOB_SHARE_CAMPAIGN_CODE}`;
  return url;
  
};

export default createShareURL;
