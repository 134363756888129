import { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Button from "@material-ui/core/Button";
import { useTranslation, withTranslation } from "react-i18next";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { isMobile } from "react-device-detect";
import { postJobApplicationFavoriteEmail } from "src/redux/userAction/applyFavEmail/action";
import { reqObjType } from "src/components/common/MainContent/JobResults/common/index";

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: isMobile ? "94vw" : "28%",
    backgroundColor: "#ffffff",
    boxShadow: theme.shadows[5],
    padding: "2rem 3rem",
    borderRadius: 10,
  },
}));

interface ImInterestedModalProps {
  shouldOpen: boolean | "";
  applyJob: (reqObj: any) => void;
  reqObject: reqObjType;
  onClose: () => void;
}

function ImInterestedModal({
  shouldOpen,
  applyJob,
  reqObject,
  onClose,
}: ImInterestedModalProps & RouteComponentProps) {
  const [open, setOpen] = useState(Boolean(shouldOpen));
  const classes = useStyles();
  const { t } = useTranslation();
  const handleClose = () => {
    setOpen(false);
    onClose?.();
  };
  const getModalStyle = () => {
    return {
      top: isMobile ? "30%" : "30%",
      left: isMobile ? "3%" : "34%",
      right: isMobile ? "4%" : "37%",
    };
  };

  useEffect(() => {
    setOpen(Boolean(shouldOpen));
  }, [shouldOpen]);

  const handleTryAgain = () => {
    applyJob(reqObject);
  };

  return (
    <div>
      <Modal open={open} closeAfterTransition onClose={handleClose}>
        <div style={getModalStyle()} className={classes.paper}>
          <div className="modalHead">{t("imInterested.oopsMsg")}</div>
          <p className="modalText">
            {t("imInterested.problemMsg")}
            <br />
            {t("imInterested.tryAgainMsg")}
          </p>
          <div className="textcenter">
            <Button variant="contained" className="tryAgainBtn" onClick={handleTryAgain}>
              {t("jobCardV2.tryAgainBtn")}
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

const mapDispatchToProps = (dispatch) => ({
  applyJob: (reqObj) => dispatch(postJobApplicationFavoriteEmail(reqObj)),
  onClose: () => {},
});

export default connect(null, mapDispatchToProps)(withTranslation()(withRouter(ImInterestedModal)));
