import i18n from "../translations/i18n";
import config from "../config";
import facilityType from "../constants/facilityType.json";
import positionType from "../constants/positionType.json";
import emrExpList from "../constants/onBoardingEmr.json";
import reasonForLeavingList from "../constants/onBoardingReasonForLeaving.json";

const enableIsLegalQuestion = config.ENABLE_IS_LEGAL_QUESTION === "true";

const workExpFormConstraints = {
  facilityName: {
    presence: { allowEmpty: false },
    format: {
      pattern: config.CITY_REGEX,
      message: i18n.t("onBoarding.errorMessage.facility"),
    },
    length: {
      maximum: 50,
      message: i18n.t("onBoarding.errorMessage.facilityLength"),
    },
  },
  city: {
    presence: { allowEmpty: false },
    format: {
      pattern: config.WORK_EXP_CITY_REGEX,
      message: i18n.t("onBoarding.errorMessage.city"),
    },
    length: {
      maximum: 30,
      message: i18n.t("onBoarding.errorMessage.cityLength"),
    },
  },
  profession: {
    presence: { allowEmpty: false },
    length: {
      maximum: 50,
      message: i18n.t("onBoarding.errorMessage.professionLength"),
    },
  },
  specialty: {
    presence: { allowEmpty: false },
    length: {
      maximum: 60,
      message: i18n.t("onBoarding.errorMessage.specialtyLength"),
    },
  },
  position: {
    singleSelectCustom: positionType.type,
  },
  supervisor: {
    // WEBONE - 6939 requirement -start
    // presence: { allowEmpty: false },
    // WEBONE - 6939 requirement end
    format: {
      pattern: config.DEGREE_REGEX,
      message: i18n.t("onBoarding.errorMessage.supervisorName"),
    },
    length: {
      maximum: 200,
      message: i18n.t("onBoarding.errorMessage.supervisorNameLength"),
    },
  },
  supervisorPhone: {
    // WEBONE - 6939 requirement -start
    // presence: { allowEmpty: false },
    // WEBONE - 6939 requirement -end
    format: {
      pattern: config.PHONE_REGEX_BASIC_INFO,
      message: i18n.t("onBoarding.errorMessage.phone"),
    },
  },
  // Start - WEBONE - 6972 requirements
  // email: {
  //   format: {
  //     pattern: config.EMAIL_REGEX,
  //     message: i18n.t("onBoarding.errorMessage.supervisorEmail"),
  //   },
  // },
  // End   - WEBONE - 6972 requirements
  shiftWorked: {
    presence: { allowEmpty: false },
    format: {
      pattern: config.CHAR_NUMBER,
      message: i18n.t("onBoarding.errorMessage.shiftWorked"),
    },
    length: {
      maximum: 30,
      message: i18n.t("onBoarding.errorMessage.shiftWorkedLength"),
    },
  },
  emrTechnology: {
    singleSelectCustom: emrExpList.emrExp,
  },
  floatingPlace: {
    format: {
      pattern: config.STREET_REGEX,
      message: i18n.t("onBoarding.errorMessage.floatingPlace"),
    },
    length: {
      maximum: 50,
      message: i18n.t("onBoarding.errorMessage.floatingPlaceLength"),
    },
  },
  isCurrentlyWorking: { presence: { allowEmpty: false } },
  startDate: {
    startDateCustom: true,
  },
  endDate: {
    endDateCustom: true,
  },
  reasonForLeaving: {
    reasonForLeavingCustom: true,
    singleSelectCustom: reasonForLeavingList.reasonForLeaving,
  },
  isTempJob: { presence: { allowEmpty: false } },
  staffingAgency: {
    staffingAgencyCustom: true,
  },
  weeklyHours: {
    format: {
      pattern: config.NUMBER_REGEX,
      message: i18n.t("onBoarding.errorMessage.weeklyHours"),
    },
    length: {
      maximum: 30,
      message: i18n.t("onBoarding.errorMessage.weeklyHoursLength"),
    },
  },
  facilityType: {
    singleSelectCustom: facilityType.types,
  },
  patientRatio: {
    length: {
      maximum: 255,
      message: i18n.t("onBoarding.errorMessage.patientRatioLength"),
    },
  },
};
const workExpPageConstraints = {
  malpractices: {
    presence: { allowEmpty: false },
  },
  ... (enableIsLegalQuestion && {
    isLegalQuestion: {
      presence: { allowEmpty: false },
    }
  }),
  workExperience: {
    workExperienceListCustom: true,
  },
};
export default { workExpFormConstraints, workExpPageConstraints };
