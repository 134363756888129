import React, { lazy } from "react";
import { Route, Switch } from "react-router-dom";
import LoggedInRoute from "./LoggedInRoute";
import LoggedOutRouter from "./LoggedOutRoute";
import AuthPage from "../components/pages/AuthPage";
import HomePage from "../components/pages/HomePage";
import NotFound from "../components/pages/NotFound";
import {
  APPLY,
  APPLY_COMPLETE,
  HOME,
  LOGIN,
  JOBS_RESULTS,
  JOBS_DETAILS,
  MY_JOBS,
  LOGIN_JWT,
  REGISTER,
  PASSWORD_RECOVERY,
  PASSWORD_RESET_JWT,
  ACCOUNT_SETTINGS,
  PAYROLL_TIMESHEET,
  PAYROLL_PAYSTUBS,
  PAYROLL_PAYMENT_DEDUCTION,
  PAYROLL_PAYSTUBS_DETAILS,
  LATEST_TASKS,
  BENEFITS,
  DASHBOARD,
  OPEN_ENROLLMENT,
  TAX_SAVING_PLUS,
  FORGOT_PASSWORD,
  PASSWORD_CREATE,
  BASIC_INFO_PAGE,
  EDU_SPEC_PAGE,
  WORK_EXP_PAGE,
  SKILL_AND_MORE_PAGE,
  SKILL_CHECKLIST_OVERVIEW_PAGE,
  SKILL_CHECKLIST_DETAILS_PAGE,
  SKILL_CHECKLIST_DETAILS_PROFILE_PAGE,
  REDIRECT_JWT,
  REDIRECT_JWT_URL,
  CERTIFICATIONS_LICENSES,
  MY_PROFILE,
  LOGOUT,
  LEGACY_PAGE,
  SAVED_SEARCHES_PAGE,
  AUTH,
  REFERENCES_PAGE,
  REFERENCES_SURVEY_FORM,
  REFERENCES_SURVEY_FORM_EXPIRE,
  EMPLOYERS_REFERENCE,
  REFERENCES_SURVEY_THANK_YOU,
  MY_TEAM_PAGE,
  REFERENCE_GENERIC_APPLY,
  AUTO_LOGIN,
  VERIFY_EMAIL,
} from "./routes";
import JobResultsPage from "../components/pages/JobResultsPage";
import JobDetailsPage from "../components/pages/JobDetailsPage";
import JobsPageV2 from "../components/pages/Jobs/JobsPageV2";
import ApplyPage from "../components/pages/ApplyPage";
import LatestTasksPage from "../components/pages/LatestTasksPage";
import ApplyCompletePage from "../components/pages/ApplyCompletePage";
import LoginJwt from "../components/common/auth/LoginJwt";
import RedirectJwt from "../components/common/auth/RedirectJwt";
import PasswordRecovery from "../components/common/auth/PasswordRecovery";
import PasswordResetJwtVerify from "../components/common/auth/PasswordResetJwtVerify";
import PayrollTimesheetPage from "../components/pages/Payroll/PayrollTimesheetPage";
import PayrollPaystubsPage from "../components/pages/Payroll/PayrollPaystubsPage";
import PayrollPaymentDeductionPage from "../components/pages/Payroll/PayrollPaymentDeductionPage";
import PayrollPaystubsDetailsPage from "../components/pages/Payroll/PayrollPaystubsDetailsPage";
import DashboardPageV2 from "../components/common/MainContent/Home/Dashboard";
import AccountSettingsPage from "../components/pages/AccountSettingsPage";
import BenefitsPage from "../components/pages/BenefitsPage";
import OpenEnrollmentPage from "../components/pages/OpenEnrollmentPage";
import TaxSavingPlusPage from "../components/pages/TaxSavingPlusPage";
import ProfileDocs from "../components/pages/Profiledocs";
import ForgotPassword from "../components/common/auth/ForgotPassword";
import BasicInfo from "../components/pages/BasicInfoPage";
import EduSpec from "../components/pages/Specialties&EduPage";
import WorkExp from "../components/pages/WorkExpPage";
import ReferencesPage from "../components/pages/ReferencesPage";
import SkillsAndMore from "../components/pages/SkillsAndMore";
import SkillChecklistOverview from "../components/pages/SkillChecklistOverview";
import SkillChecklistDetails from "../components/pages/SkillChecklistDetails";
import SkillChecklistDetailsProfile from "../components/pages/SkillChecklistDetailsProfile";
import CertificationsLicensesPage from "../components/pages/CertificationsLicensesPageV2";
import Logout from "../components/pages/Logout";
import LegacyPage from "../components/pages/LegacyPage";
import SavedSearchesPage from "../components/pages/SavedSearchesPage";
import AuthVerify from "../components/pages/AuthVerify";
import ReferenceSurvey from "../components/common/MainContent/ReferenceSurvey/ReferenceSurvey";
import ReferenceSurveyExpire from "../components/common/MainContent/ReferenceSurvey/ReferenceExpiredLink";
import ReferenceSurveyThankYou from "../components/common/MainContent/ReferenceSurvey/ReferenceSurveyThankYou";
import ReferenceForm from "../employers/reference/ReferenceForm";
import MyTeamPage from "../components/pages/MyTeamPage";
import CreatePasswordForm from "../components/common/auth/CreatePassword/CreatePasswordForm";
import Login from "src/components/common/auth/Login";
import SignUpEmailVerificationModal from "src/components/common/auth/SIgnUpUser/SignUpEmailVerificationModal";
import DashboardPageV3 from "../components/common/MainContent/v3/dashboard/Dashboard";
import config from "../config";

const enableV3 = config.ENABLE_DASHBOARD_V3 === "true";

const DashboardPage = enableV3 ? DashboardPageV3 : DashboardPageV2;

const VerifyEmailExpiredPage = lazy(() =>
  import("src/components/pages/VerifyEmailExpiredPage/VerifyEmailExpiredPage")
);

const MainRouter = (props) => (
  <Switch>
    <LoggedOutRouter exact path={LOGIN} component={AuthPage} />
    <LoggedOutRouter exact path={AUTO_LOGIN} component={Login} />
    <LoggedOutRouter exact path={REGISTER} component={AuthPage} />
    <LoggedOutRouter exact path={AUTH} component={AuthVerify} />
    <LoggedOutRouter exact path={LOGIN_JWT} component={LoginJwt} key={Math.random()} />
    <LoggedOutRouter exact path={REDIRECT_JWT} component={RedirectJwt} key={Math.random()} />
    <LoggedOutRouter exact path={REDIRECT_JWT_URL} component={RedirectJwt} />
    <LoggedOutRouter
      exact
      path={PASSWORD_RESET_JWT}
      component={PasswordResetJwtVerify}
      key={Math.random()}
    />
    <LoggedOutRouter exact path={PASSWORD_RECOVERY} component={PasswordRecovery} />
    <LoggedOutRouter exact path={FORGOT_PASSWORD} component={ForgotPassword} />
    <LoggedOutRouter exact path={PASSWORD_CREATE} component={CreatePasswordForm} />
    <LoggedOutRouter
      exact
      path={REFERENCE_GENERIC_APPLY}
      component={SignUpEmailVerificationModal}
    />
    <LoggedInRoute exact path={APPLY} component={ApplyPage} />
    <LoggedInRoute exact path={LATEST_TASKS} component={LatestTasksPage} />
    <LoggedInRoute exact path={APPLY_COMPLETE} component={ApplyCompletePage} />
    <LoggedInRoute exact path={HOME} component={HomePage} />
    <LoggedInRoute path={MY_JOBS} component={JobsPageV2} />
    <LoggedInRoute exact path={JOBS_RESULTS} component={JobResultsPage} />
    <LoggedInRoute exact path={JOBS_DETAILS} component={JobDetailsPage} />
    <LoggedInRoute exact path={PAYROLL_TIMESHEET} component={PayrollTimesheetPage} />
    <LoggedInRoute exact path={PAYROLL_PAYSTUBS} component={PayrollPaystubsPage} />
    <LoggedInRoute exact path={PAYROLL_PAYMENT_DEDUCTION} component={PayrollPaymentDeductionPage} />
    <LoggedInRoute exact path={PAYROLL_PAYSTUBS_DETAILS} component={PayrollPaystubsDetailsPage} />
    <LoggedInRoute exact path={ACCOUNT_SETTINGS} component={AccountSettingsPage} />
    <LoggedInRoute exact path={DASHBOARD} component={DashboardPage} />
    <LoggedInRoute exact path={BENEFITS} component={BenefitsPage} />
    <LoggedInRoute exact path={OPEN_ENROLLMENT} component={OpenEnrollmentPage} />
    <LoggedInRoute exact path={MY_PROFILE} component={ProfileDocs} />
    <LoggedInRoute exact path={TAX_SAVING_PLUS} component={TaxSavingPlusPage} />
    <LoggedInRoute exact path={BASIC_INFO_PAGE} component={BasicInfo} />
    <LoggedInRoute exact path={EDU_SPEC_PAGE} component={EduSpec} />
    <LoggedInRoute exact path={WORK_EXP_PAGE} component={WorkExp} />
    <LoggedInRoute exact path={REFERENCES_PAGE} component={ReferencesPage} />
    <LoggedInRoute exact path={SKILL_AND_MORE_PAGE} component={SkillsAndMore} />
    <LoggedInRoute exact path={SKILL_CHECKLIST_OVERVIEW_PAGE} component={SkillChecklistOverview} />
    <LoggedInRoute exact path={SKILL_CHECKLIST_DETAILS_PAGE} component={SkillChecklistDetails} />
    <LoggedInRoute
      exact
      path={SKILL_CHECKLIST_DETAILS_PROFILE_PAGE}
      component={SkillChecklistDetailsProfile}
    />
    <LoggedInRoute exact path={CERTIFICATIONS_LICENSES} component={CertificationsLicensesPage} />
    <LoggedInRoute exact path={LEGACY_PAGE} component={LegacyPage} />
    <LoggedInRoute exact path={LOGOUT} component={Logout} />
    <LoggedInRoute exact path={SAVED_SEARCHES_PAGE} component={SavedSearchesPage} />
    <LoggedInRoute exact path={MY_TEAM_PAGE} component={MyTeamPage} />
    <Route exact path={REFERENCES_SURVEY_FORM} component={ReferenceSurvey} />
    <Route exact path={REFERENCES_SURVEY_FORM_EXPIRE} component={ReferenceSurveyExpire} />
    <Route exact path={EMPLOYERS_REFERENCE} component={ReferenceForm} />
    <Route exact path={REFERENCES_SURVEY_THANK_YOU} component={ReferenceSurveyThankYou} />
    <Route exact path={VERIFY_EMAIL} component={VerifyEmailExpiredPage} />
    <Route component={NotFound} />
  </Switch>
);
export default MainRouter;
