import Box from "@material-ui/core/Box";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import clsx from "clsx";
import PropTypes from "prop-types";
import TextInputError from "../TextInput/TextInputError";
import TextInputLabel from "../TextInput/TextInputLabel";
import RadioControl from "./RadioControl";

const useStyles = makeStyles((theme) => ({
  radioBtnSize: {
    "& .MuiSvgIcon-root": {
      width: "1.2rem",
      height: "1.2rem",
    },
  },
  labelRoot: {
    marginLeft: 0,
    marginRight: 0,
  },

  label: {
    color: theme.palette.text.label,
    fontFamily: `${theme.fontFamilyPrimary} !important`,
    "@media(min-width: 1024px) and (max-width: 1280px)": {
      fontSize: "0.9rem",
    },
  },
  checkedLabel: {
    color: theme.palette.text.label,
    "@media(min-width: 1024px) and (max-width: 1280px)": {
      fontSize: "0.9rem",
    },
  },
}));

function RadioGroupInput({
  helperText,
  labelContentColumn,
  disabled,
  isBoolean,
  row,
  errorText,
  label,
  name,
  options,
  value,
  onChange,
}) {
  const classes = useStyles();
  errorText = errorText || helperText;

  const buildFakeChangeEvent = (newValue) => ({
    target: { name, value: newValue },
  });

  const handleChange = (event) => {
    let newValue = event.target.value;

    if (isBoolean) {
      newValue = newValue === "true";
    }

    onChange(buildFakeChangeEvent(newValue));
  };

  return (
    <div>
      {label && <TextInputLabel disabled={disabled} value={label} />}

      <RadioGroup
        name={name}
        row={row}
        value={value}
        onChange={handleChange}
        className={classes.radioBtnSize}
      >
        {options.map((option, index) => {
          const formControlLabel = (
            <FormControlLabel
              classes={{
                root: classes.labelRoot,
                label: clsx(classes.label, {
                  [classes.checkedLabel]: value === option.value,
                }),
              }}
              control={
                <RadioControl
                  inputProps={{
                    "data-testid": `radio-button-${option.value}`,
                  }}
                />
              }
              disabled={disabled || !!option.disabled}
              label={option.label}
              value={option.value}
            />
          );

          return (
            <Box
              alignItems={!labelContentColumn ? "center" : undefined}
              display={!labelContentColumn ? "flex" : undefined}
              key={option.key || option.value}
              mt={option.labelContent && index !== 0 && !labelContentColumn ? "14px" : undefined}
            >
              {option.tooltip ? (
                <Tooltip title={option.tooltip}>{formControlLabel}</Tooltip>
              ) : (
                formControlLabel
              )}

              {option.labelContent ? option.labelContent : null}
            </Box>
          );
        })}
      </RadioGroup>

      <TextInputError value={errorText} />
    </div>
  );
}

RadioGroupInput.propTypes = {
  disabled: PropTypes.bool,
  isBoolean: PropTypes.bool,
  labelContentColumn: PropTypes.bool,
  row: PropTypes.bool,
  errorText: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired,
};

export default RadioGroupInput;
