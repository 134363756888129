import { TFunction, withTranslation } from "react-i18next";
import {
  CUSTOMER_SERVICE_EMAIL,
  RECRUITER_DEFAULT_CARE_TEAM_PHONE,
  MAIN_OFFICE_ADDRESS,
} from "src/constants/contact_info";
import { PRIVACY_POLICY_URL } from "src/constants/external_links";
import styles from "./LowerFooter.module.scss";

interface LowerFooterProps {
  t: TFunction<"translation", undefined>;
}

const LowerFooter = ({ t }: LowerFooterProps) => {
  return (
    <div className="container">
      <div className={styles.LowerFooter}>
        <div>
          &copy; {new Date().getFullYear()} {t("footer.address.footerText")}
        </div>
        <span className={styles.FooterDivider}>&nbsp; - &nbsp;</span>
        <div>{MAIN_OFFICE_ADDRESS}</div>
        <span className={styles.FooterDivider}>&nbsp; &nbsp; | &nbsp; &nbsp;</span>
        <a className={styles.FooterLink} href={`mailto:${CUSTOMER_SERVICE_EMAIL}`}>
          {CUSTOMER_SERVICE_EMAIL}
        </a>
        <span className={styles.FooterDivider}>&nbsp; &nbsp; | &nbsp; &nbsp;</span>
        <a className={styles.FooterLink} href={`tel:${RECRUITER_DEFAULT_CARE_TEAM_PHONE}`}>
          {RECRUITER_DEFAULT_CARE_TEAM_PHONE}
        </a>
        <span className={styles.FooterDivider}>&nbsp; &nbsp; | &nbsp; &nbsp;</span>
        <a className={styles.FooterLink} href={PRIVACY_POLICY_URL} rel="noreferrer" target="_blank">
          {t("footer.privacyPolicy")}
        </a>
      </div>
    </div>
  );
};

export default withTranslation()(LowerFooter);
