import { push } from "connected-react-router";
import moment from "moment";
import API from "../../services/Api";
import {
  PAYROLL_TIMESHEET,
  PAYROLL_PAYSTUBS,
  PAYROLL_PAYMENT_DEDUCTION,
} from "../../router/routes";

export const PAYROLL_UPDATE_NAVIGATION_KEY = "PAYROLL_UPDATE_NAVIGATION_KEY";
export const payrollUpdateNavigationKey = (payload) => ({
  type: PAYROLL_UPDATE_NAVIGATION_KEY,
  payload,
});

export const FETCH_ASSIGNMENTS_REQUEST = "FETCH_ASSIGNMENTS_REQUEST";
export const fetchAssignmentsRequest = (payload) => ({ type: FETCH_ASSIGNMENTS_REQUEST, payload });

export const FETCH_ASSIGNMENTS_SUCCESS = "FETCH_ASSIGNMENTS_SUCCESS";
export const fetchAssignmentsSuccess = (payload) => ({ type: FETCH_ASSIGNMENTS_SUCCESS, payload });

export const FETCH_ASSIGNMENTS_FAILURE = "FETCH_ASSIGNMENTS_FAILURE";
export const fetchAssignmentsFailure = (payload) => ({ type: FETCH_ASSIGNMENTS_FAILURE, payload });

export const NO_ASSIGNMENTS_FOUND = "NO_ASSIGNMENTS_FOUND";
export const noAssignmentsFound = (payload) => ({ type: NO_ASSIGNMENTS_FOUND, payload });

export const payrollSubNavRedirection = (payload) => (dispatch) => {
  if (payload) {
    switch (payload.path) {
      case PAYROLL_TIMESHEET:
        dispatch(push(PAYROLL_TIMESHEET));
        break;
      case PAYROLL_PAYSTUBS:
        dispatch(push(PAYROLL_PAYSTUBS));
        break;
      case PAYROLL_PAYMENT_DEDUCTION:
        dispatch(push(PAYROLL_PAYMENT_DEDUCTION));
        break;
      default:
        break;
    }
    dispatch(payrollUpdateNavigationKey(payload.key));
  }
};

export const handleSelectedPayrollMenu = (payload) => (dispatch) => {
  if (payload) {
    const obj = {
      path: null,
      key: payload,
    };
    switch (payload) {
      case PAYROLL_TIMESHEET:
        obj.path = PAYROLL_TIMESHEET;
        break;
      case PAYROLL_PAYSTUBS:
        obj.path = PAYROLL_PAYSTUBS;
        break;
      case PAYROLL_PAYMENT_DEDUCTION:
        obj.path = PAYROLL_PAYMENT_DEDUCTION;
        break;
      default:
        obj.path = PAYROLL_TIMESHEET;
        break;
    }
    dispatch(payrollSubNavRedirection(obj));
  }
};

export const FETCH_TIMESHEETS = "FETCH_TIMESHEETS";
export const fetchTimesheetsPerPayPeriod = (payload) => {
  const timesheets = [];
  let totalCount = {
    regularHours: 0,
    chargeHours: 0,
    oncallHours: 0,
    holidayHours: 0,
    callbackHours: 0,
    date: "",
  };
  for (let i = 0; i < 7; i++) {
    const timesheetData = { ...totalCount };
    const convertedDate = moment.utc(payload.startDate).add(i, "days").format("MM-DD-YYYY");
    timesheetData.date = convertedDate;
    timesheets.push(timesheetData);
  }
  payload.timesheets.forEach((element) => {
    const timesheetDate = element.date.split("T")[0];
    const startDate = moment.utc(payload.startDate).format("YYYY-MM-DD");
    const endDate = moment.utc(payload.endDate).format("YYYY-MM-DD");
    if (startDate <= timesheetDate && endDate >= timesheetDate) {
      const timesheet = element;
      const date = moment.utc(element.date).format("YYYY-MM-DD");
      timesheets.forEach((el, key) => {
        const eledate = moment.utc(el.date).format("YYYY-MM-DD");
        if (eledate === date) {
          timesheets[String(key)] = timesheet;
        }
      });
    }
  });
  timesheets.forEach((element) => {
    totalCount = {
      regularHours: (element.regularHours ? element.regularHours : 0) + totalCount.regularHours,
      chargeHours: (element.inchargeHours ? element.inchargeHours : 0) + totalCount.chargeHours,
      oncallHours: (element.oncallHours ? element.oncallHours : 0) + totalCount.oncallHours,
      holidayHours: (element.holidayHours ? element.holidayHours : 0) + totalCount.holidayHours,
      callbackHours: (element.callbackHours ? element.callbackHours : 0) + totalCount.callbackHours,
    };
  });

  const obj = {
    timesheets,
    totalHours: totalCount,
  };
  return { type: FETCH_TIMESHEETS, obj };
};

export const FETCH_PAYCHECKS = "FETCH_PAYCHECKS";
export const fetchPaychecks = (payload) => {
  const paychecksAsPerPagination = [];
  for (let i = 0; i < payload.assignments.length; i++) {
    for (let j = 0; j < payload.assignments[String(i)].payChecks.length; j++) {
      const paycheck = payload.assignments[String(i)].payChecks[String(j)];
      paycheck.facilities = payload.assignments[String(i)].hospital.name;
      paycheck.city = payload.assignments[String(i)].hospital.city;
      paycheck.state = payload.assignments[String(i)].hospital.state;
      paychecksAsPerPagination.push(paycheck);
    }
  }
  const paginatedPaychecks = paychecksAsPerPagination
    ? paychecksAsPerPagination
        .sort((a, b) => new Date(b.payCheckStartDate) - new Date(a.payCheckEndDate))
        .slice(0, 9)
    : paychecksAsPerPagination;
  const totalPaychecks = paychecksAsPerPagination.length;
  return {
    type: FETCH_PAYCHECKS,
    paychecksAsPerPagination,
    totalPaychecks,
    paginatedPaychecks,
  };
};

export const fetchAssignments = (candidateId) => (dispatch) => {
  dispatch(fetchAssignmentsRequest());
  API.get(`candidates/${candidateId}/payroll`)
    .then((response) => {
      if (response.status === 200) {
        if (response.data.assignments.length === 0) {
          dispatch(noAssignmentsFound(true));
        } else {
          dispatch(fetchAssignmentsSuccess(response.data));
          dispatch(fetchPaychecks(response.data));
        }
      }
    })
    .catch((error) => {
      dispatch(fetchAssignmentsFailure(error.message));
    });
};

export const FETCH_PAYCHECKS_PAGINATION = "FETCH_PAYCHECKS_PAGINATION";
export const fetchPaychecksAsPerPagination = (payload) => {
  const { paychecks } = payload;
  const { pageNumber } = payload;
  const { pageSize } = payload;
  const paychecksAsPerPagination = paychecks.slice(
    (pageNumber - 1) * pageSize,
    pageNumber * pageSize
  );
  return {
    type: FETCH_PAYCHECKS_PAGINATION,
    paychecksAsPerPagination,
    pageNumber,
  };
};
