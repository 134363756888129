import { useTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import config from "src/config";
import MainLayout from "src/components/pages/MainLayout";
import SnackbarSuccess from "src/components/common/SnackbarSuccess/SnackbarSuccess";
import styles from "./Dashboard.module.scss";
import IncompleteProfile from "./incomplete-profile/IncompleteProfile";
import Search from "./search/Search";
import HotJobs from "./hot-jobs/HotJobs";
import QuickApplyEligible from "./quick-apply-eligible/QuickApplyEligible";
import RecommendedJobs from "./recommended-jobs/RecommendedJobs";
import Support from "./support/Support";

interface DashboardProps {
  verifyEmailPopUp: boolean;
  passwordSuccessPopUp: boolean;
  completedSteps: {
    [key: string]: boolean;
  };
  hasFetchedCompletedSteps: boolean;
  useProfileIframe: boolean;
}

const Dashboard = ({
  verifyEmailPopUp,
  passwordSuccessPopUp,
  completedSteps,
  hasFetchedCompletedSteps,
  useProfileIframe,
}: DashboardProps) => {
  const { t } = useTranslation();

  const requiredStepsAmount = useProfileIframe ? 4 : 5;
  const completedStepsAmount = completedSteps != null ? Object.keys(completedSteps).length : 0;
  const isProfileComplete = completedStepsAmount >= requiredStepsAmount;

  const showIncompleteProfile = hasFetchedCompletedSteps === true && isProfileComplete === false;
  const showSearch = true;
  const showHotJobs = config.ENABLE_HOT_JOBS === "true";
  const showQuickApplyEligible = config.Dashboard_ADVERTISEMENT === "true" && isProfileComplete === true;
  const showRecommendedJobs = true;
  const showSupport = true;

  return (
    <MainLayout>
      <div className={styles.main}>
        {verifyEmailPopUp && <SnackbarSuccess message={t("emailVerification.success")} />}
        {passwordSuccessPopUp && (
          <span className="createPasswordSuccess">
            <SnackbarSuccess message={t("createPassword.passwordUpdated")} />
          </span>
        )}

        {showIncompleteProfile && <IncompleteProfile />}
        {showSearch && <Search />}
        {showHotJobs && <HotJobs />}
        {showQuickApplyEligible && <QuickApplyEligible />}
        {showRecommendedJobs && <RecommendedJobs />}
        {showSupport && <Support />}
      </div>
    </MainLayout>
  )
}

const mapStateToProps = (state) => ({
  completedSteps: state.onBoarding.completedSteps,
  hasFetchedCompletedSteps: state.onBoarding.hasFetchedCompletedSteps,
  useProfileIframe: state.auth.useProfileIframe,
});

export default connect(mapStateToProps)(withTranslation()(Dashboard));