import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import Box from "@material-ui/core/Box";
import Container from "react-bootstrap/Container";

export default function CircularStatic(props) {
  return (
    <Container className="onBProgressBarMain">
      <div className="textCenter">
        <Box position="relative" display="inline-flex">
          <span className="onBProgressBarText">{props.loaderText}</span>
          <CircularProgress variant="indeterminate" />
        </Box>
      </div>
    </Container>
  );
}
