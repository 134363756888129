import CircularProgress from "@material-ui/core/CircularProgress";
import { Theme, withStyles } from "@material-ui/core/styles";
import {
  ComponentClass,
  createElement,
  forwardRef,
  FunctionComponent,
  ReactNode,
  MouseEventHandler,
} from "react";
import clsx from "clsx";

interface ButtonProps {
  component: string | FunctionComponent<any> | ComponentClass<any, any>;
  classes?: any;
  className?: string;
  size?: "small" | "medium" | "large";
  clickable?: boolean;
  disabled?: boolean;
  isLoading?: boolean;
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  children?: ReactNode;
  onClick?: MouseEventHandler<HTMLElement>;
}

const Button = forwardRef<any, ButtonProps>(
  (
    {
      component = "div",
      classes,
      className,
      size,
      clickable,
      disabled,
      endIcon,
      isLoading,
      startIcon,
      children,
      ...other
    },
    ref
  ) => {
    const isDisabled = disabled || isLoading;

    const classNames = clsx(className, classes.root, {
      [classes.disabled]: isDisabled,
      [classes.clickable]: clickable,
      [classes.medium]: size === "medium",
      [classes.small]: size === "small",
    });

    const elProps: {
      className: string;
      ref: any;
      role?: string;
      tabIndex?: string;
    } = {
      className: classNames,
      ref,
      ...other,
    };

    if (clickable) {
      elProps.role = "button";
      elProps.tabIndex = "0";
    }

    return createElement(component, elProps, [
      startIcon && (
        <span className={classes.startIcon} key="startIcon">
          {startIcon}
        </span>
      ),
      <span className={classes.children} key="children">
        {isLoading ? <CircularProgress color="inherit" size={16} /> : children}
      </span>,
      endIcon && (
        <span className={classes.endIcon} key="endIcon">
          {endIcon}
        </span>
      ),
    ]);
  }
);

Button.defaultProps = {
  size: "medium",
  className: "",
  classes: {},
  clickable: true,
  disabled: false,
  isLoading: false,
  startIcon: <></>,
  endIcon: <></>,
  children: <></>,
  onClick: () => {},
};

const BaseButton = withStyles((theme) => {
  const themeWithProps = theme as Theme & {
    palette: {
      bgColorDisabled: string;
      colorDarkGrey: string;
    };
    fontFamilyPrimary: string;
  };
  return {
    root: {
      padding: "6px 15px",
      alignItems: "center",
      justifyContent: "center",
      verticalAlign: "middle",
      fontSize: "0.875rem",
      lineHeight: "1.75",
      boxShadow: "none",
      width: "max-content",
      textTransform: "uppercase",
      letterSpacing: "0.03em",
      fontFamily: themeWithProps.fontFamilyPrimary,
      minWidth: "90px",
      borderRadius: "10px 10px 10px 0",
    },
    startIcon: {
      display: "inline",
      margin: "0px 10px 0px 0px",
      fontSize: "0.875rem",
    },
    endIcon: {
      display: "inline",
      margin: "0px 0px 0px 10px",
      fontSize: "1rem",
    },
    clickable: {
      cursor: "pointer",
    },
    disabled: {
      cursor: "default",
      pointerEvents: "none",
      backgroundColor: `${themeWithProps.palette.bgColorDisabled} !important`,
      color: `${themeWithProps.palette.colorDarkGrey} !important`,
    },
    medium: {
      fontSize: "0.8rem",
      padding: "6px 16px",
      borderRadius: "10px 10px 10px 0",
      "@media(min-width: 0px) and (max-width: 768px)": {
        fontSize: "0.7rem",
      },
    },
    small: {
      fontSize: "0.7rem",
      padding: "4px 10px",
      "@media(min-width: 0px) and (max-width: 768px)": {
        fontSize: "0.675rem",
      },
    },
  };
})(Button);

export default BaseButton;
