import Pagination from "@material-ui/lab/Pagination";
import { BrowserView, MobileView, isMobile } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import jobSearchQueryBuilder from "src/services/QueryBuilder/JobSearchQueryBuilder";
import { myJobs } from "src/components/common/MainContent/JobResults/common/index";
import { setMyJobsPage } from "src/redux/jobs/MyJob/action";

interface RootState {
  myJobs: {
    currentPage: number;
  };
}

const PaginationSize = ({ jobs, pageSize }: { jobs: myJobs; pageSize: number }) => {
  const dispatch = useDispatch();
  const myJobsPpage = useSelector((state: RootState) => state.myJobs.currentPage);
  const isJobsSearchPage = window.location.pathname.includes("result");

  const handlePageChange = (_, selectedPage) => {
    if (isJobsSearchPage) {
      jobSearchQueryBuilder.handleParamsChange({ offset: selectedPage });
    } else {
      dispatch(setMyJobsPage(selectedPage));
    }
    window.scrollTo(0, 0);
  };
  const { totalElements } = jobs.page;
  const totalPages = Math.trunc((totalElements - 1) / pageSize);

  const paginationComp = () => (
    <Pagination
      count={totalPages + 1}
      defaultPage={1}
      page={isJobsSearchPage ? jobs.page.number : myJobsPpage}
      onChange={handlePageChange}
      siblingCount={isMobile ? 0 : 1}
    />
  );

  return (
    <div className="pagination">
      <BrowserView>{paginationComp()}</BrowserView>
      <MobileView>{paginationComp()}</MobileView>
    </div>
  );
};
export default PaginationSize;
