import { withTranslation } from "react-i18next";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import queryString from "query-string";
import HelpSignIn from "./HelpSignIn";
import config from "../../../config";

interface ForgotPasswordProps {
  t: any;
  location: {
    search: string;
  };
}

const ForgotPassword = ({ t, location }: ForgotPasswordProps) => {
  const queryParamObj = queryString.parse(location.search);
  const sm = 12;

  return (
    <div className="fullCoverBackground">
      <Container className="forgotPassMain">
        <Row className="duplicateMsgMargin">
          {queryParamObj.site === config.COMPANY_CCA.code ? (
            <div className="duplicateLoginMsg">
              <span>{t("login.forgotPasswordForAllied1")}</span>
              <br />
              <span>{t("login.forgotPasswordForAllied2")}</span>
            </div>
          ) : null}
        </Row>
        <Row>
          <Col xs={sm} sm={sm} md={5} lg={5} className="pb-3 pt-3" />
          <Col xs={sm} sm={sm} md={6} lg={6} className="pb-3 pt-3">
            <Row className="loginFormV2">
              <Col xs={sm} sm={sm} md={5} lg={5} />
              <Col xs={sm} sm={sm} md={7} lg={7} className="pb-3 pt-3 rightsigninform">
                <HelpSignIn email={queryParamObj.email} />
              </Col>
              <Col sm={sm} xs={sm} />
            </Row>
          </Col>
          <Col sm={sm} md={1} lg={1} xs={sm} className="pb-3 pt-3" />
        </Row>
      </Container>
    </div>
  );
};

export default withTranslation()(ForgotPassword);
