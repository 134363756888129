import React from "react";
import { useTheme } from "@material-ui/core/styles";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import FormHelperText from "@material-ui/core/FormHelperText";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, value, theme) {
  return {
    fontWeight: value
      ? value.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium
      : theme.typography.fontWeightRegular,
  };
}

export default function MultipleSelect(props) {
  const theme = useTheme();
  return (
    <FormControl variant="outlined">
      <InputLabel shrink>{props.fieldLabel}</InputLabel>
      <Select
        label={props.fieldLabel}
        multiple={props.isMultiple}
        value={props.fieldValue.value}
        onChange={(e) => props.handleMultipleSelect(e, props.fieldName)}
        notched
        MenuProps={MenuProps}
      >
        {props.fieldOptions?.map((option) => (
          <MenuItem
            key={option}
            value={option}
            style={getStyles(option, props.fieldValue.value, theme)}
          >
            {option}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>{props.helperText}</FormHelperText>
    </FormControl>
  );
}
