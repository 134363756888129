export const CHANGE_SETTING_THEME_REQUEST = "CHANGE_SETTING_THEME_REQUEST";
export const changeSettingThemeRequest = (payload) => ({
  type: CHANGE_SETTING_THEME_REQUEST,
  payload,
});

export const CHANGE_SETTING_THEME_SUCCESS = "CHANGE_SETTING_THEME_SUCCESS";
export const changeSettingThemeSuccess = (payload) => ({
  type: CHANGE_SETTING_THEME_SUCCESS,
  payload,
});

export const CHANGE_SETTING_THEME_FAILURE = "CHANGE_SETTING_THEME_FAILURE";
export const changeSettingThemeFailure = (payload) => ({
  type: CHANGE_SETTING_THEME_FAILURE,
  payload,
});

export const changeSettingTheme = (themeName) => (dispatch) => {
  dispatch(changeSettingThemeRequest(themeName));
};
