import { Component } from "react";
import { withTranslation } from "react-i18next";
import Container from "react-bootstrap/Container";
import { CUSTOMER_SERVICE_PHONE } from "src/constants/contact_info";
import Link from "@material-ui/core/Link";
import config from "src/config";

interface MissingApplicantIdProps {
  t: (value: string) => string;
}

class MissingApplicantId extends Component<MissingApplicantIdProps> {
  render() {
    const { t } = this.props;
    return (
      <Container fluid>
        <Container className="noApplicantIDWrap">
          <div className="onBnoAppIdError">
            {t("onBoarding.noApplicantId.noApplicantIDHeadOnb")}
            <span className="onBnoAppIdErrorMob">
              {t("onBoarding.noApplicantId.noApplicantIDHeadOnb1")}
            </span>
          </div>
          <div className="onBNoAppID">
            {t("onBoarding.noApplicantId.noapplicantidText1")}
            <br />
            {t("onBoarding.noApplicantId.noapplicantidText2")}&nbsp;
            <b>
              <Link href={`tel:${CUSTOMER_SERVICE_PHONE}`} className="custServiceNo">
                {CUSTOMER_SERVICE_PHONE}
              </Link>{" "}
            </b>
            {t("onBoarding.noApplicantId.noapplicantidText3")}&nbsp;
            {`${config.ERROR_CODES.NO_APPLICANT_ID}.`}
          </div>
        </Container>
      </Container>
    );
  }
}

export default withTranslation()(MissingApplicantId);
