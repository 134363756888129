import { useState } from "react";
import { useTranslation } from "react-i18next";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core/styles";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import InputLabel from "@material-ui/core/InputLabel";
import { isMobile } from "react-device-detect";
import jobSearchQueryBuilder from "src/services/QueryBuilder/JobSearchQueryBuilder";
import config from "src/config";

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: "75%",
    margin: "2px 0px 0px -18px",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

interface JobsSortV2Props {
  disabled?: boolean;
  pageSort?: string;
}

type parsedLocation = {
  city: string;
  plainState: string;
  state: string;
  latitude: number;
  longitude: number;
  states: string,
  latitudes: string,
  longitudes: string
};

const JobsSortV2 = ({ disabled, pageSort }: JobsSortV2Props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  function getSelectedSortOrder() {
    let sortOrder = pageSort;
    if (pageSort === "datePosted:desc") {
      sortOrder = "recent";
    } else if (pageSort === "salaryMax:desc") {
      sortOrder = "salaryMax:desc";
    } else if (pageSort === "distance:asc") {
      sortOrder = "distance:asc";
    } else {
      sortOrder = "relevant";
    }

    const selectedSortOrder = sortOrder || "relevant";
    return selectedSortOrder;
  }

  const [criteria, setCriteria] = useState(getSelectedSortOrder());
  const { search } = useLocation();
  const queryStringValues = search ? queryString.parse(search) : {};
  const location = queryStringValues?.location;
  const parseLocationObject = (): parsedLocation | {} => {
    if (!location) return {};
    if (Array.isArray(location)) {
      return JSON.parse(location.join(""));
    }
    return JSON.parse(location);
  };
  const locationObject = parseLocationObject();

  const setSortCriteria = (event) => {
    setCriteria(event.target.value);
    if (window.location.pathname.includes("result"))
      jobSearchQueryBuilder.handleParamsChange({
        sort: event.target.value === "recent" ? "datePosted:desc" : event.target.value,
      });
  };
  const enableJobLocationSearch = config.ENABLE_SEARCH_JOB_LOCATION === "true";

  const doesCityExist = () => {
    let city = false
    city  = enableJobLocationSearch ? 
    (Object.keys(locationObject).length > 0 &&
    "latitudes" in locationObject &&
    "longitudes" in locationObject &&
    locationObject.latitudes !== null &&
    locationObject.longitudes !== null)
    : 
    (Object.keys(locationObject).length > 0 &&
    "city" in locationObject &&
    locationObject.city !== "")
    
    return city;
  };

  const isCityAndStateExist = () => {
    let cityStatePresent = false
    const  sessionStates=JSON.parse(sessionStorage.getItem("selectedItems") as string);
    const sessionCites=JSON.parse(sessionStorage.getItem("citySelelctedItems") as string);
    cityStatePresent = sessionStates && sessionCites && sessionStates.length > 0 && sessionCites.length > 0

    return cityStatePresent
  }
   


  return (
    <div>
      <div className="myJobsSort">
        <FormControl variant={!isMobile ? "outlined" : undefined} className={classes.formControl}>
          <InputLabel className="mjchkboxAlign">{t("jobResults.popularTag.sort")}</InputLabel>
          <Select
            className="sortSelectListMob"
            label="Sort"
            onChange={(event) => setSortCriteria(event)}
            value={criteria}
            disabled={disabled}
          >
            <MenuItem className="mostRelevantText" value="relevant">
              {t("jobResults.popularTag.mostRelevant")}
            </MenuItem>
            <MenuItem className="mostRecentText" value="recent">
              {t("jobResults.popularTag.mostRecent")}
            </MenuItem>
            <MenuItem className="mostRelevantText" value="salaryMax:desc">
              {t("jobResults.highestPay")}
            </MenuItem>
            {enableJobLocationSearch && isCityAndStateExist()
            ? null 
            : doesCityExist() && (
              <MenuItem className="mostRecentText" value="distance:asc">
                {t("jobResults.distance")}
              </MenuItem>
            )}
          </Select>
        </FormControl>
      </div>
    </div>
  );
};

JobsSortV2.defaultProps = {
  disabled: false,
  pageSort: "datePosted:desc",
};

export default JobsSortV2;
