import moment from "moment";
import Analytics from "./Analytics";

const analyticsSegmentIdentify = (props) => {
  const obj = {
    userId: props.userId,
    email: props.email,
    firstName: props.firstName,
    lastName: props.lastName,
    phone: props.phone,
    company: props.company,
    profession:
      props.onboardingSpecialtiesAndEducation && props.onboardingSpecialtiesAndEducation.specialty
        ? props.onboardingSpecialtiesAndEducation.specialty.profession
        : null,
    specialties:
      props.onboardingSpecialtiesAndEducation && props.onboardingSpecialtiesAndEducation.specialty
        ? props.onboardingSpecialtiesAndEducation.specialty.primarySpecialty
        : null,
    streetAddress:
      props.onboardingBasicInfo &&
      props.onboardingBasicInfo.permanentAddress &&
      props.onboardingBasicInfo.permanentAddress.streetAddress
        ? props.onboardingBasicInfo.permanentAddress.streetAddress
        : null,
    city:
      props.onboardingBasicInfo &&
      props.onboardingBasicInfo.permanentAddress &&
      props.onboardingBasicInfo.permanentAddress.city
        ? props.onboardingBasicInfo.permanentAddress.city
        : null,
    state:
      props.onboardingBasicInfo &&
      props.onboardingBasicInfo.permanentAddress &&
      props.onboardingBasicInfo.permanentAddress.state
        ? props.onboardingBasicInfo.permanentAddress.state
        : null,
    zip:
      props.onboardingBasicInfo &&
      props.onboardingBasicInfo.permanentAddress &&
      props.onboardingBasicInfo.permanentAddress.zip
        ? props.onboardingBasicInfo.permanentAddress.zip
        : null,
    recruiterFname:
      props.recruiterDetails && props.recruiterDetails.firstName
        ? props.recruiterDetails.firstName
        : null,
    recruiterLname:
      props.recruiterDetails && props.recruiterDetails.lastName
        ? props.recruiterDetails.lastName
        : null,
    recruiterEmail:
      props.recruiterDetails && props.recruiterDetails.emailId
        ? props.recruiterDetails.emailId
        : null,
    lastSeenDate: moment.utc().format("yyyy-MM-DD HH:mm:ss"),
  };
  Analytics.segmentIdentify(obj);
};

export default analyticsSegmentIdentify;
