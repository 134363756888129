import omit from "lodash/omit";
import get from "lodash/get";
import assign from "lodash/assign";
import history from "../../redux/history";

export const QueryBuilderParamsDefaults = {};

class QueryBuilder {
  paramsDefaults = undefined;

  myJobsDefaults = undefined;

  path = undefined;

  constructor(paramsDefaults, path, myJobsDefaults) {
    this.paramsDefaults = paramsDefaults;
    this.myJobsDefaults = myJobsDefaults;
    this.path = path;
  }

  query = () => new URLSearchParams(history.location.search);

  currentParams = () => {
    const searchParamsObject = Object.fromEntries(this.query().entries());
    return Object.assign(this.paramsDefaults, searchParamsObject);
  };

  setInitialParams = () => {
    this.paramsDefaults = {};
    return Object.fromEntries(this.query().entries());
  };

  queryBuilder = (params) => {
    const searchParams = omit(params, "specialties");
    return Object.keys(searchParams)
      .map((key) => `${key}=${encodeURIComponent(get(searchParams, key) || "")}`)
      .join("&");
  };

  handleParamsChange = (newParams) => {
    if (Object.values(newParams).toString() !== "") {
      const currentParams = this.currentParams();
      const updatedParams = assign(currentParams, newParams);
      // Remove shouldFilterByQuickApplyEligibility if it's not defined or empty
      if (updatedParams.shouldFilterByQuickApplyEligibility === "" || updatedParams.shouldFilterByQuickApplyEligibility === undefined) {
        delete updatedParams.shouldFilterByQuickApplyEligibility;
      }
      const queryString = this.queryBuilder(updatedParams);
     
      history.push({
        pathname: this.path,
        search: queryString,
      });
    } else {
      // remove query param which has blank value
      const raw = this.currentParams();
      const filtered = Object.keys(raw)
        .filter((key) => key !== Object.keys(newParams).toString())
        .reduce((obj, key) => ({ ...obj, key: raw[String(key)] }), {});

      const queryString = this.queryBuilder(assign(filtered));
      history.push({
        pathname: this.path,
        search: queryString,
      });
    }
  };

  freshSearch = (newParams) => {
    const queryString = this.queryBuilder(newParams);
    history.push({
      pathname: this.path,
      search: queryString,
    });
  };
}
export default QueryBuilder;
