export const RECOMMENDED_JOBS = "Recommended Jobs";
export const SIMILAR_JOBS = "Similar Jobs";
export const CROWD_SOURCING_JOBS = "People Also Viewed";
export const JOBS_SEARCH = "Job Search";
export const MY_JOBS = "My Jobs";
export const SIGN_UP = "Sign Up";
export const JOBS_DETAILS = " Job Description Page";
export const HOMEPAGE = "Homepage";
export const MATCH_RECOMMENDED_JOBS = "Match Recommended Jobs";
export const HOT_JOBS = "Hot Jobs";
export const JOB_VIEWS = "Job Views";
export const QUICK_APPLY_ELIGIBLE= "Quick Apply Eligible"