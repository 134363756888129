import { useState } from "react";
import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import Backdrop from "@material-ui/core/Backdrop";
import { useTranslation } from "react-i18next";
import { getCredentialsFile, UpdateCandidateCredentials } from "src/services/GraphQL/graph.query";
import { graphqlRequest as request } from "src/services/GraphQL/GraphQL";
import { STORAGE_KEY } from "axios-jwt";
import CircularProgress from "@material-ui/core/CircularProgress";
import GetAppOutlinedIcon from "@mui/icons-material/GetAppOutlined";
import PrimaryButton from "src/components/common/Buttons/PrimaryButton";
import SecondaryButton from "src/components/common/Buttons/SecondaryButton";
import GenericErrorMessagePopup from "src/components/common/OnBoarding/GenericErrorMessagePopup";
import styles from "src/components/common/OnBoarding/OnBoardingStyles/CredentialItem.module.scss";

export interface CandidateCredentialData {
  id: string;
  name: string;
  status: string;
  statusClassName?: string;
  dateExpiration: string;
  dateCompleted: string;
  certificationFileAttachments: any[];
  licenseNumber: string;
}

export interface ChecklistData {
  checklistId: string;
  atsChecklistId: string;
  isComplete: boolean;
  name?: string;
}

interface CredentialItemProps {
  candidateCredentialData: CandidateCredentialData;
  bhCandidateId: number;
  companyId: number;
  handleEdit: (credentialTypeId: number) => void;
  reFetchCredentials: () => void;
  segmentCredentialEvent: (eventName: "Added" | "Deleted" | "Downloaded", eventProps: any) => void;
}

export interface CredentialFileResponse {
  getCredentialsFile: {
    isSuccess: boolean;
    code: number;
    developerMessage: string;
    userMessage: string;
    file: {
      name: string;
      contentType: string;
      fileContent: string;
    };
  };
}

const CredentialItem = (props: CredentialItemProps) => {
  const {
    candidateCredentialData,
    bhCandidateId,
    companyId,
    handleEdit,
    reFetchCredentials,
    segmentCredentialEvent,
  } = props;
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteError, setDeleteError] = useState(false);
  const { t } = useTranslation();
  const [displayDeleteSpinner, setDisplayDeleteSpinner] = useState(false);

  const [downloadPending, setDownloadPending] = useState(false);
  const isDownloadLinkActive: boolean =
    candidateCredentialData?.certificationFileAttachments.length > 0;

  const replaceFileName = (fileName: string) => {
    return fileName?.toLowerCase().replace("_front", "").replace("_back", "");
  };

  const downloadFile = (fileData: CredentialFileResponse) => {
    const [fileName, contentType, fetchData] = [
      fileData?.getCredentialsFile?.file?.name,
      fileData?.getCredentialsFile?.file?.contentType,
      fileData?.getCredentialsFile?.file?.fileContent,
    ];
    const downloadUrl = `data:${contentType};base64,${fetchData}`;
    const a = document.createElement("a");
    a.href = downloadUrl;
    a.download = replaceFileName(fileName);
    a.click();
  };

  const getCredentialsFileData = async (id: number) => {
    const authToken: string | null = localStorage.getItem(STORAGE_KEY);
    const token = authToken ? JSON.parse(authToken) : "";
    const query = { credentialsFileId: id };
    return new Promise<void>((resolve, reject) => {
      request(getCredentialsFile, query, token?.accessToken)
        .then((res: CredentialFileResponse) => {
          downloadFile(res);
          segmentCredentialEvent("Downloaded", {
            credentialName: candidateCredentialData.name,
            credentialType: candidateCredentialData.licenseNumber
              ? t("certificationAndLicenses.licensure")
              : t("certificationAndLicenses.certifications"),
          });
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const handleDownload = async () => {
    setDownloadPending(true);
    candidateCredentialData?.certificationFileAttachments?.forEach((obj) => {
      getCredentialsFileData(obj?.id).then(() => {
        setDownloadPending(false);
      });
    });
  };

  const renderDownloadIcon = () => {
    return !isDownloadLinkActive ? (
      <GetAppOutlinedIcon />
    ) : (
      <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path d="M19.825 9.353H15.59V3H9.236v6.353H5l7.414 7.414 7.411-7.414zM5 18.883V21h14.825v-2.117H5z" />
      </svg>
    );
  };

  const handleDelete = async () => {
    try {
      setDisplayDeleteSpinner(true);
      const authToken: string | null = localStorage.getItem(STORAGE_KEY);
      const token = authToken ? JSON.parse(authToken) : "";

      const variables = {
        bhCandidateId,
        companyId,
        candidateCertId: candidateCredentialData.id,
        certificationContent: {
          isDeleted: true,
        },
      };

      const response = await request(UpdateCandidateCredentials, variables, token?.accessToken);
      if (response?.updateCandidateCredentials?.statusCode !== 200) {
        setDeleteError(true);
        setDisplayDeleteSpinner(false);
        setOpenDeleteModal(false);
      } else {
        segmentCredentialEvent("Deleted", {
          credentialName: candidateCredentialData.name,
          credentialType: candidateCredentialData.licenseNumber
            ? t("certificationAndLicenses.licensure")
            : t("certificationAndLicenses.certifications"),
        });
        reFetchCredentials();
      }
    } catch (error) {
      setDeleteError(true);
      setDisplayDeleteSpinner(false);
      setOpenDeleteModal(false);
    }
  };

  const genericDeleteErrorProps = {
    informativeMessage: t("certificationAndLicenses.deleteErrorInformativeMessage"),
    tryAgainMessage: t("certificationAndLicenses.deleteErrorTryAgainMessage"),
    callBack: () => setDeleteError(false),
  };

  const loadingSpinner = (
    <div className={styles.spinnerBox}>
      <CircularProgress size={70} className={styles.loaderSpinner} />
    </div>
  );

  const deleteConfirmationModal = (
    <div className={styles.confirmationBox}>
      <div className="textCenter onBSkillDeletePopupTxt">
        {`${t("certificationAndLicenses.recordDeleteTxt1")} `}
        <span className="onBSkillDeletePopupTxt onBSkillChecklistName">
          {candidateCredentialData.name}
        </span>{" "}
        {` ${t("certificationAndLicenses.recordDeleteTxt2") || ""} `}
      </div>
      <div className="d-flex justify-content-center deleteConfirmation">
        <SecondaryButton component="button" className={styles.deletebutton} onClick={handleDelete}>
          {t("onBoarding.skillChecklist.yesBtnLbl")}
        </SecondaryButton>
        &nbsp; &nbsp;
        <PrimaryButton
          component="button"
          className={styles.deletebutton}
          onClick={() => setOpenDeleteModal(false)}
        >
          {t("onBoarding.skillChecklist.noBtnLbl")}
        </PrimaryButton>
      </div>
    </div>
  );

  const deleteModal = () => {
    if (displayDeleteSpinner) return loadingSpinner;
    return deleteConfirmationModal;
  };

  return (
    <>
      <div className="tableRow">
        <div className="tableCell certLicenStatusColumn">
          <div className="tableHeads">{t("certificationAndLicenses.status")}</div>
          <div className="table-content certificationLicensesItem">
            <Chip
              label={candidateCredentialData.status}
              className={candidateCredentialData.statusClassName}
            />
          </div>
        </div>
        <div className="tableCell certLicenNameColumn">
          <div className="tableHeads">{t("certificationAndLicenses.name")}</div>
          <span className="table-content certificationLicensesItem">
            {candidateCredentialData.name}
          </span>
        </div>
        <div className="tableCell certLicenUploadDateColumn">
          <div className="tableHeads">{t("certificationAndLicenses.uploadDate")}</div>
          <span className="table-content certificationLicensesItem">
            {candidateCredentialData?.dateCompleted}
          </span>
        </div>
        <div className="tableCell certLicenExpirationDateColumn">
          <div className="tableHeads">{t("certificationAndLicenses.expirationDate")}</div>
          <span className="table-content certificationLicensesItem">
            {candidateCredentialData?.dateExpiration}
          </span>
        </div>
        <div className="tableCell onBTableIcons">
          <div className="tableHeads">{t("certificationAndLicenses.action")}</div>
          <div className="table-content">
            <Button
              className="certLicenActionButton"
              onClick={() => handleEdit(Number(candidateCredentialData.id))}
            >
              <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path d="m14.06 9 .94.94L5.92 19H5v-.92L14.06 9zm3.6-6c-.25 0-.51.1-.7.29l-1.83 1.83 3.75 3.75 1.83-1.83c.39-.39.39-1.04 0-1.41l-2.34-2.34c-.2-.2-.45-.29-.71-.29zm-3.6 3.19L3 17.25V21h3.75L17.81 9.94l-3.75-3.75z" />
              </svg>
            </Button>
            <Button
              className="certLicenActionButton"
              disabled={!isDownloadLinkActive}
              onClick={handleDownload}
            >
              {downloadPending ? (
                <CircularProgress size={17} className="ml-2 loaderResendBtn" />
              ) : (
                renderDownloadIcon()
              )}
            </Button>
            <Button className="certLicenActionButton" onClick={() => setOpenDeleteModal(true)}>
              <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path d="M6 19a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V7H6v12zM8 9h8v10H8V9zm7.5-5-1-1h-5l-1 1H5v2h14V4h-3.5z" />
              </svg>
            </Button>
          </div>
        </div>
      </div>
      {deleteError && (
        <GenericErrorMessagePopup
          callBack={genericDeleteErrorProps.callBack}
          informativeMessage={genericDeleteErrorProps.informativeMessage}
          tryAgainMessage={genericDeleteErrorProps.tryAgainMessage}
        />
      )}
      <Modal
        className="onBSkillDeleteModal"
        open={openDeleteModal}
        onClose={() => setOpenDeleteModal(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openDeleteModal}>{deleteModal()}</Fade>
      </Modal>
    </>
  );
};

export default CredentialItem;
