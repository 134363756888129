import { memo, useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import Button from "@material-ui/core/Button";
import { isMobile } from "react-device-detect";
import { LEGACY_PAGE } from "src/router/routes";
import MyProfileStatus from "src/components/common/OnBoarding/MyProfileStatus";
import MyProfileNav from "src/components/common/OnBoarding/MyProfileNav";
import config from "src/config";
import clsx from "clsx";
import { getAccessToken } from "axios-jwt";
import { graphqlRequest } from "src/services/GraphQL/GraphQL";
import { SEARCH_JOBS_WITH_VIEWS } from "src/services/GraphQL/graph.query";
import { useMediaQuery } from "@material-ui/core";
import JobViewsDashboardAdvertisement from "./JobDashboardAdvertisement/JobViewsDashboardAdvertisement";
import getCandidateInformation from "../../CandidateInformation/CandidateInformation";
import LocationSpecialities from "./LocationSpecialities";

const enableDashboardAd = config.Dashboard_ADVERTISEMENT === "true";
interface RootState {
  onBoarding: {
    completedSteps: any;
    specialtiesAndEducation: any;
    completedSections:any;
  };
  auth: {
    firstName: string;
    useProfileIframe: boolean;
  };
  company: {
    company: {
      id: string;
    };
  };
  candidate: {
    atsBullhornCandidateId: string;
    bullhornIdFromBullhorn: string;
    isInitApiLoading: boolean;
  };
}

interface DashboardBannerV2Props {
  bgImgClass: string;
  onboardingInfo?: any
  updateQuickApplyAdvJobs: (newJobs: any) => void;
  componentName: string
  toggleJobsViewsLoader: (shouldLoad: boolean) => void

}

const DashboardBannerV2 = ({ bgImgClass, onboardingInfo, updateQuickApplyAdvJobs, toggleJobsViewsLoader, componentName }: DashboardBannerV2Props) => {
  const { t } = useTranslation();
  const isCompact = useMediaQuery("(max-width: 900px)");
  const history = useHistory();
  const completedSteps = useSelector((state: RootState) => state.onBoarding.completedSteps);
  const username = useSelector((state: RootState) => state.auth.firstName);
  const useProfileIframe = useSelector((state: RootState) => state.auth.useProfileIframe);
  const [jobs, setJobs] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const companyId = useSelector((state: RootState) => state.company.company.id);
  const completeProfile = (!useProfileIframe && Object.keys(completedSteps).length < 4) || Object.keys(completedSteps).length < 5
  const { atsBullhornCandidateId } = useSelector(
    (state: RootState) => state.candidate
  );
  const bhId = useSelector((state: RootState) => state.candidate.bullhornIdFromBullhorn);
  const onboardingSpecialty = useSelector(
    (state: RootState) => state.onBoarding.specialtiesAndEducation
  );
  const completedSections = useSelector((state: RootState) => state.onBoarding.completedSections);
  const isProfileDataLoaded = completedSections &&   Object.keys(completedSections).filter(key => completedSections[`${[key]}`] === true);
  const isProfileIncomplete = isProfileDataLoaded && isProfileDataLoaded?.length < 5;
  const redirectToProfile = () => {
    const location = {
      pathname: LEGACY_PAGE,
      search: "?page=basic-info",
    };
    history.push(location);
  };

  const fetchSearchJobsWithViews = async () => {

    const candidate = getCandidateInformation(onboardingInfo)
    const authToken = getAccessToken();
    if (completedSections) {
      setLoading(true);
      toggleJobsViewsLoader(true)
    }
    const params = {
      professions: onboardingSpecialty?.specialty?.profession || null,
      specialties: onboardingSpecialty?.specialty?.primarySpecialty || null,
      companyId: Number(companyId),
      candidateId: Number(atsBullhornCandidateId || bhId),
      includeQuickApplyEligibility: Object.keys(completedSteps).length === 5,
      candidateInformation: Object.keys(completedSteps).length === 5 ? candidate : {},
      isProfileComplete: Object.keys(completedSteps).length === 5,
    };

    try {
      await graphqlRequest(SEARCH_JOBS_WITH_VIEWS, params, authToken)
        .then((response) => {
          if (response?.searchJobsWithViews?.code === 200) {
            const recommendedJobs = response?.searchJobsWithViews?.embedded?.results;
            updateQuickApplyAdvJobs(recommendedJobs);
            toggleJobsViewsLoader(false)
            setJobs(recommendedJobs);
          }
          setLoading(false);
          toggleJobsViewsLoader(false)
        })
        .catch((error) => {
          console.error("Error:", error);
          setLoading(false);
          toggleJobsViewsLoader(false)
        });
    } catch (error) {
      console.error("Error while fetching jobs views data:", error);
      setLoading(false);
      toggleJobsViewsLoader(false)
    }
  };

  useEffect(() => {
    if ((atsBullhornCandidateId || bhId) && companyId && onboardingSpecialty) fetchSearchJobsWithViews();
  }, [(atsBullhornCandidateId || bhId), companyId, onboardingSpecialty]);

  return (
    
    <div
      id="dashboard-banner"
      // fluid
      className={
        isMobile
          ? `${clsx("dashProfileMain", bgImgClass, ((enableDashboardAd && Object.keys(completedSteps).length < 5) || !enableDashboardAd) && "dashboardBannerV2AddRefer",
            enableDashboardAd && Object.keys(completedSteps).length === 5 && "dashboardBannerWithSearch"
          )}`
          : `${clsx(
            "dashboardBannerV2",
            (enableDashboardAd && completeProfile) && "dashboardBannerEnableV2",
            (enableDashboardAd && Object.keys(completedSteps).length === 5 && "dashboardBannerWithLocationSearch"),
            "dashProfileMain",
            bgImgClass
          )}`
      }
    >

      {useProfileIframe && (
        <Container>
          <Row>
            <Col sm={12} xs={12} md={12} lg={12}>
              <p className="myProfileTextMobComplete dashBMargTop">
                <span className="myProfileNote myProfBannerTextComp">
                  {" "}
                  <span className="dashBannerTxtHead">
                    {t("dashboard.banner.dashProfileBannerTxt1")}
                    <span>{username ?? ""}</span>
                    !&nbsp;
                    <span className="complSubHeadMob">
                      {t("dashboard.banner.dashProfileBannerTxt2")}
                    </span>
                  </span>
                </span>
                <span
                  className={`${enableDashboardAd ? "dashBannerSubHeadEnable" : "dashBannerSubHead"
                    } dashBannerSubHeadMob`}
                >
                  {t("dashboard.banner.dashProfileBannerTxt3")}
                </span>
                <br />
                <div className="text-centerMob">
                  <span className="">
                    <Button
                      className="letsGoBtnDashB"
                      id="profileButton"
                      onClick={redirectToProfile}
                    >
                      {t("dashboard.banner.buttonLabel")}
                    </Button>
                  </span>
                </div>
              </p>
            </Col>
          </Row>
        </Container>
      )}
      {(!useProfileIframe && Object.keys(completedSteps).length < 4) ||
        Object.keys(completedSteps).length < 5 ? (
        <>
          <Container>
            <Row>
              <Col
                sm={12}
                xs={12}
                md={1}
                lg={1}
                className={clsx("myProfileStatus", enableDashboardAd && "myProfileStatusEnable")}
              >
                <div
                  className={clsx(
                    "dashProfileStatus",
                    enableDashboardAd && "dashProfileStatusEnable"
                  )}
                >
                  {" "}
                  <MyProfileStatus />
                </div>
              </Col>
              <Col sm={10} xs={12} md={11} lg={11}>
                <div className="dashProfileNav">
                  <MyProfileNav />
                </div>
              </Col>
            </Row>
          </Container>
          {enableDashboardAd && isProfileIncomplete && (
            <div className="JobViewsDashboardAdvertisement">
              <JobViewsDashboardAdvertisement jobs={jobs} loading={loading} />
            </div>
          )}
        </>
      ) : (
        <>
          <Container fluid className="jobSearchContainerWithCompleteProfile ">
            <Row className="flexRow">
              <Col sm={12} xs={12} md={12} lg={12}>
                <p className="myProfileTextMobComplete dashboardLocSearchMarginTop" id="bannerContainer">
                  <p className={clsx(!isMobile && "dashBannerTxtHead", "mt-2", isMobile && "dashBannerCompleteProdTxtHead")}>
                    {t("dashboard.banner.dashProfileBannerTxt1")}
                    <span>{username ? ` ${username}` : ""}</span>
                    !&nbsp;

                    {t("dashboard.banner.myProfileNavCompleteTxt2")}{" "}

                  </p>
                  <span className={clsx(!isMobile && "dashBannerSubHead", !isMobile && "dashBannerSubHeadMob", isMobile && "dashboardBannerDesc")}>
                    {t("dashboard.banner.dashProfileBannerTxt4")} &quot;
                    {t("dashboard.banner.dashProfileBannerTxt5")}&quot;{" "}
                    {t("dashboard.banner.dashProfileBannerTxt6")}
                  </span>
                  <br />
                </p>
              </Col>
            </Row>

          </Container>

          {enableDashboardAd && (
            <Container className={clsx(!isCompact && "jobSearchContainerWithHotJobs w-75", isCompact && "w-100 mt-5")}>
              <Row>
                <Col xs={12}>
                <LocationSpecialities enableDashboardAd={enableDashboardAd && Object.keys(completedSteps).length === 5} translate={t} componentName={componentName} />
                </Col>
              </Row>
            </Container>
          )}
        </>
      )}
    </div>
   
   
  );
};

DashboardBannerV2.defaultProps = {
  onboardingInfo: {}
}

export default memo(DashboardBannerV2);
