import { useEffect } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import Container from "react-bootstrap/Container";
import ReactLoading from "react-loading";
import { Redirect } from "react-router-dom";
import { getJwtTokenForAutoLogin } from "src/redux/auth/action";
import Logo from "src/components/common/Header/Logo";

interface RedirectJwtProps {
  getJwt: (jwt: string) => void;
  t: (key: string) => string;
  computedMatch: {
    params: {
      token: string;
    };
  };
  location: {
    pathname: string;
  };
}

function RedirectJwt({ location, t, getJwt, computedMatch }: RedirectJwtProps) {
  useEffect(() => {
    if (!(location.pathname === "/auth/redirect/" || location.pathname === "/auth/redirect")) {
      getJwt(computedMatch.params.token);
    }
  }, []);
  if (location.pathname === "/auth/redirect/" || location.pathname === "/auth/redirect")
    return <Redirect to="/login" />;
  return (
    <Container fluid className="loaderPage">
      <Container>
        <div>
          <Logo />
        </div>
        <div className="loadingTxt">{t("login.loginSuccess")}</div>
        <ReactLoading type="bubbles" className="loaderColor" />
      </Container>
    </Container>
  );
}

const matchDispatchToProps = (dispatch) => ({
  getJwt: (token) => dispatch(getJwtTokenForAutoLogin(token)),
});

export default connect(null, matchDispatchToProps)(withTranslation()(RedirectJwt));
