import React, { Component } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import TextField from "@material-ui/core/TextField";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Chip from "@material-ui/core/Chip";
import Button from "@material-ui/core/Button";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import Grid from "@material-ui/core/Grid";
import DateFnsUtils from "@date-io/date-fns";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { Radio } from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import FormHelperText from "@material-ui/core/FormHelperText";
import { isMobile } from "react-device-detect";
import { withRouter } from "react-router-dom";
import MUIPlacesAutocomplete, { geocodeByPlaceID } from "mui-places-autocomplete";
import find from "lodash/find";
import { withStyles } from "@material-ui/core/styles";
import config from "src/config";
import {
  fetchAllStates,
  updateCompleteFlag,
  updateActiveFlag,
  saveUserProfile,
} from "src/redux/onBoarding/action";
import { fetchLocation } from "src/redux/globalSearch/action";
import usStates from "src/constants/onBoardingUSStates.json";
import canadianStates from "src/constants/onBoardingCanadianStates.json";
import Analytics from "src/services/Analytics";
import MultipleSelect from "src/components/common/OnBoarding/onBBasicInfoSelect";
import { BASIC_INFO } from "src/constants/onboardingEventName";
import statesLicenses from "src/constants/stateLicenses.json";
import gender from "src/constants/onboardingGender.json";
import ethnicity from "src/constants/onBoardingEthnicity.json";
import relationship from "src/constants/EmergencyContactRelationship.json";
import countries from "src/constants/Country.json";
import { EDU_SPEC_PAGE, DASHBOARD } from "src/router/routes";
import basicInfoConstraints from "src/services/BasicInfoConstraint";
import validationService from "src/services/Validations";
import onboardingFields from "src/constants/onboardingFieldNames.json";
import basicInfoLists from "src/constants/basicInfoLists.json";
import { OnBoardingConfirmModal } from "src/components/common/OnBoarding/OnBoardingConfirmModal";
import analyticsSegmentIdentify from "src/services/SegmentIdentify";
import SecondaryButton from "src/components/common/Buttons/SecondaryButton";
import MyProfileNavWrapper from "src/components/common/OnBoarding/MyProfileNavWrapper";
import SelfIdentification from "src/components/common/OnBoarding/SelfIdentification/SelfIdentification";
import BasicInfoSsnAndDOB from "./BasicInfoSsnAndDOB"
import { getAccessToken } from "axios-jwt";
import { GET_MY_JOBS_STATUS } from "src/services/GraphQL/graph.query";
import { graphqlRequest } from "src/services/GraphQL/GraphQL";

const enableReferences = config.ENABLE_REFERENCES === "true";
const ethnicityAndGender = config.ETHNICITY_AND_GENDER === "true";
const perStepPercentage = enableReferences ? 20 : 25;
const enableSsnAndDobFields = config.ENABLE_SSN_AND_DOB_FIELDS === "true";
const enableHideEthnicityAndGender = config.ENABLE_HIDE_GENDER_AND_ETHNICITY_SECTION === "true";
const enableEmailRecruiter = config.ENABLE_EMAIL_RECRUITER === "true";

const SelectMenuProps = {
  PaperProps: {
    style: {
      maxHeight: "40vh",
    },
  },
};

const styles = (theme) => ({
  nextBtnMarginleft: {
    marginLeft: "auto",
  },
});
class OnBoardingBasicInfo extends Component {
  constructor(props) {
    super(props);
    validationService.init();
    validationService.setCustomBasicInfoValidations();
    this.state = {
      basicInfoFields: onboardingFields.basicInfo,
      canBeSavedPartially: true,
      errorMessages: [],
      currentAddress: [
        "currAddrStreetAddress",
        "currAddrApt",
        "currAddrCity",
        "currAddrState",
        "currAddrZip",
        "currAddrCountry",
      ],
      permanentAddress: [
        "permAddrStreetAddress",
        "permAddrApt",
        "permAddrCity",
        "permAddrState",
        "permAddrZip",
        "permAddrCountry",
      ],
      gray: true,
      openState: false,
      shifts:
        props.onboardingBasicInfo && props.onboardingBasicInfo.shifts
          ? validationService.verifyDropdownValues(
              "multiple",
              "shifts",
              props.onboardingBasicInfo.shifts,
              basicInfoLists.shifts,
              "basicInfo"
            )
          : [],
      datePickerValue:
        props.onboardingBasicInfo && props.onboardingBasicInfo.dateAvailable
          ? props.onboardingBasicInfo.dateAvailable
          : null,
      onPageDateLoad: true,
      shiftsJson: basicInfoLists.shifts,
      interestsJson: basicInfoLists.interests,
      requiredFields: [
        "firstname",
        "lastname",
        "email",
        "permAddrStreetAddress",
        "permAddrZip",
        "permAddrCity",
        "permAddrState",
        "dateAvailable",
        "isCurrAddressDiff",
        "relation",
      ],
      onPageLoad: true,
      firstname: {
        value:
          props.onboardingBasicInfo && props.onboardingBasicInfo.firstname
            ? props.onboardingBasicInfo.firstname
            : "",
        errors: !(props.onboardingBasicInfo && props.onboardingBasicInfo.firstname),
        errorText:
          props.onboardingBasicInfo && props.onboardingBasicInfo.firstname
            ? null
            : props.t("onBoarding.errorMessage.required"),
      },
      lastname: {
        value:
          props.onboardingBasicInfo && props.onboardingBasicInfo.lastname
            ? props.onboardingBasicInfo.lastname
            : "",
        errors: !(props.onboardingBasicInfo && props.onboardingBasicInfo.lastname),
        errorText:
          props.onboardingBasicInfo && props.onboardingBasicInfo.lastname
            ? null
            : props.t("onBoarding.errorMessage.required"),
      },
      preferredName: {
        value:
          props.onboardingBasicInfo && props.onboardingBasicInfo.preferredName
            ? props.onboardingBasicInfo.preferredName
            : "",
        errors: false,
        errorText: null,
      },
      email: {
        value:
          props.onboardingBasicInfo && props.onboardingBasicInfo.email
            ? props.onboardingBasicInfo.email
            : "",
        errors: !(props.onboardingBasicInfo && props.onboardingBasicInfo.email),
        errorText:
          props.onboardingBasicInfo && props.onboardingBasicInfo.email
            ? null
            : props.t("onBoarding.errorMessage.required"),
      },
      stateLicensesHeld: {
        value:
          props.onboardingBasicInfo && props.onboardingBasicInfo.stateLicensesHeld
            ? validationService.verifyDropdownValues(
                "multiple",
                "stateLicensesHeld",
                props.onboardingBasicInfo.stateLicensesHeld,
                statesLicenses.states,
                "basicInfo"
              )
            : [],
        errors: false,
        errorText: null,
      },
      gender: {
        value:
          props.onboardingBasicInfo && props.onboardingBasicInfo.gender
            ? validationService.verifyDropdownValues(
                "single",
                "gender",
                props.onboardingBasicInfo.gender,
                gender.gender,
                "basicInfo"
              )
            : "",
        errors: false,
        errorText: null,
      },
      ethnicity: {
        value:
          props.onboardingBasicInfo && props.onboardingBasicInfo.ethnicity
            ? validationService.verifyDropdownValues(
                "single",
                "ethnicity",
                props.onboardingBasicInfo.ethnicity,
                ethnicity.ethnicity,
                "basicInfo"
              )
            : "",
        errors: false,
        errorText: null,
      },
      ...(enableSsnAndDobFields && {
                socialSecurityNumber: {
          value:
            props.onboardingBasicInfo && props.onboardingBasicInfo.socialSecurityNumber
              ? props.onboardingBasicInfo.socialSecurityNumber
              : null,
          errors: false,
          errorText: null,
        },
        isSSNAvailable: {
          value:
            (props.onboardingBasicInfo && props.onboardingBasicInfo?.isSSNAvailable) ||
            props.onboardingBasicInfo?.isSSNAvailable === false
              ? props.onboardingBasicInfo?.isSSNAvailable
              : null,
          errors: false,
          errorText: null,
        },
                dateOfBirth: {
          value:
            props.onboardingBasicInfo && props.onboardingBasicInfo.dateOfBirth
              ? props.onboardingBasicInfo.dateOfBirth
              : null,
          errors: false,
          errorText: null,
        },
      }),

      isCurrAddressDiff: {
        value:
          props.onboardingBasicInfo &&
          (props.onboardingBasicInfo.isCurrAddressDiff ||
            props.onboardingBasicInfo.isCurrAddressDiff === false)
            ? props.onboardingBasicInfo.isCurrAddressDiff.toString()
            : null,
        errors: !(
          props.onboardingBasicInfo &&
          (props.onboardingBasicInfo.isCurrAddressDiff ||
            props.onboardingBasicInfo.isCurrAddressDiff === false)
        ),
        errorText:
          props.onboardingBasicInfo &&
          (props.onboardingBasicInfo.isCurrAddressDiff ||
            props.onboardingBasicInfo.isCurrAddressDiff === false)
            ? null
            : props.t("onBoarding.errorMessage.required"),
      },
      currAddrStreetAddress: {
        value:
          props.onboardingBasicInfo &&
          props.onboardingBasicInfo.currentAddress &&
          props.onboardingBasicInfo.currentAddress.streetAddress
            ? props.onboardingBasicInfo.currentAddress.streetAddress
            : "",
        errors: !(
          props.onboardingBasicInfo &&
          props.onboardingBasicInfo.currentAddress &&
          props.onboardingBasicInfo.currentAddress.streetAddress
        ),
        errorText:
          props.onboardingBasicInfo &&
          props.onboardingBasicInfo.currentAddress &&
          props.onboardingBasicInfo.currentAddress.streetAddress
            ? ""
            : props.t("onBoarding.errorMessage.required"),
      },
      currAddrZip: {
        value:
          props.onboardingBasicInfo &&
          props.onboardingBasicInfo.currentAddress &&
          props.onboardingBasicInfo.currentAddress.zip
            ? props.onboardingBasicInfo.currentAddress.zip
            : "",
        errors: !(
          props.onboardingBasicInfo &&
          props.onboardingBasicInfo.currentAddress &&
          props.onboardingBasicInfo.currentAddress.zip
        ),
        errorText:
          props.onboardingBasicInfo &&
          props.onboardingBasicInfo.currentAddress &&
          props.onboardingBasicInfo.currentAddress.zip
            ? null
            : props.t("onBoarding.errorMessage.required"),
      },
      phone: {
        value:
          props.onboardingBasicInfo && props.onboardingBasicInfo.phone
            ? props.onboardingBasicInfo.phone
            : "",
        errors: !(props.onboardingBasicInfo && props.onboardingBasicInfo.phone),
        errorText:
          props.onboardingBasicInfo && props.onboardingBasicInfo.phone
            ? null
            : props.t("onBoarding.errorMessage.required"),
      },
      currAddrCity: {
        value:
          props.onboardingBasicInfo &&
          props.onboardingBasicInfo.currentAddress &&
          props.onboardingBasicInfo.currentAddress.city
            ? props.onboardingBasicInfo.currentAddress.city
            : "",
        errors: !(
          props.onboardingBasicInfo &&
          props.onboardingBasicInfo.currentAddress &&
          props.onboardingBasicInfo.currentAddress.city
        ),
        errorText:
          props.onboardingBasicInfo &&
          props.onboardingBasicInfo.currentAddress &&
          props.onboardingBasicInfo.currentAddress.city
            ? ""
            : props.t("onBoarding.errorMessage.required"),
      },
      currAddrApt: {
        value:
          props.onboardingBasicInfo &&
          props.onboardingBasicInfo.currentAddress &&
          props.onboardingBasicInfo.currentAddress.apt
            ? props.onboardingBasicInfo.currentAddress.apt
            : "",
        errors: false,
        errorText: null,
      },
      currAddrCountry: {
        value:
          props.onboardingBasicInfo &&
          props.onboardingBasicInfo.currentAddress &&
          props.onboardingBasicInfo.currentAddress.country
            ? props.onboardingBasicInfo.currentAddress.country
            : "US",
        errors: false,
        errorText: null,
      },
      permAddrStreetAddress: {
        value:
          props.onboardingBasicInfo &&
          props.onboardingBasicInfo.permanentAddress &&
          props.onboardingBasicInfo.permanentAddress.streetAddress
            ? props.onboardingBasicInfo.permanentAddress.streetAddress
            : "",
        errors: !(
          props.onboardingBasicInfo &&
          props.onboardingBasicInfo.permanentAddress &&
          props.onboardingBasicInfo.permanentAddress.streetAddress
        ),
        errorText:
          props.onboardingBasicInfo &&
          props.onboardingBasicInfo.permanentAddress &&
          props.onboardingBasicInfo.permanentAddress.streetAddress
            ? ""
            : props.t("onBoarding.errorMessage.required"),
      },
      permAddrCity: {
        value:
          props.onboardingBasicInfo &&
          props.onboardingBasicInfo.permanentAddress &&
          props.onboardingBasicInfo.permanentAddress.city
            ? props.onboardingBasicInfo.permanentAddress.city
            : "",
        errors: !(
          props.onboardingBasicInfo &&
          props.onboardingBasicInfo.permanentAddress &&
          props.onboardingBasicInfo.permanentAddress.city
        ),
        errorText:
          props.onboardingBasicInfo &&
          props.onboardingBasicInfo.permanentAddress &&
          props.onboardingBasicInfo.permanentAddress.city
            ? ""
            : props.t("onBoarding.errorMessage.required"),
      },
      permAddrApt: {
        value:
          props.onboardingBasicInfo &&
          props.onboardingBasicInfo.permanentAddress &&
          props.onboardingBasicInfo.permanentAddress.apt
            ? props.onboardingBasicInfo.permanentAddress.apt
            : "",
        errors: false,
        errorText: null,
      },
      permAddrState: {
        value:
          props.onboardingBasicInfo &&
          props.onboardingBasicInfo.permanentAddress &&
          props.onboardingBasicInfo.permanentAddress.state
            ? props.onboardingBasicInfo.permanentAddress.state
            : "",
        errors: !(
          props.onboardingBasicInfo &&
          props.onboardingBasicInfo.permanentAddress &&
          props.onboardingBasicInfo.permanentAddress.state
        ),
        errorText:
          props.onboardingBasicInfo &&
          props.onboardingBasicInfo.permanentAddress &&
          props.onboardingBasicInfo.permanentAddress.state
            ? null
            : props.t("onBoarding.errorMessage.required"),
      },
      permAddrZip: {
        value:
          props.onboardingBasicInfo &&
          props.onboardingBasicInfo.permanentAddress &&
          props.onboardingBasicInfo.permanentAddress.zip
            ? props.onboardingBasicInfo.permanentAddress.zip
            : "",
        errors: !(
          props.onboardingBasicInfo &&
          props.onboardingBasicInfo.permanentAddress &&
          props.onboardingBasicInfo.permanentAddress.zip
        ),
        errorText:
          props.onboardingBasicInfo &&
          props.onboardingBasicInfo.permanentAddress &&
          props.onboardingBasicInfo.permanentAddress.zip
            ? null
            : props.t("onBoarding.errorMessage.required"),
      },
      permAddrCountry: {
        value:
          props.onboardingBasicInfo &&
          props.onboardingBasicInfo.permanentAddress &&
          props.onboardingBasicInfo.permanentAddress.country
            ? props.onboardingBasicInfo.permanentAddress.country
            : "US",
        errors: false,
        errorText: null,
      },
      dateAvailable: {
        value:
          props.onboardingBasicInfo && props.onboardingBasicInfo.dateAvailable
            ? props.onboardingBasicInfo.dateAvailable
            : null,
        errors: !(props.onboardingBasicInfo && props.onboardingBasicInfo.dateAvailable),
        errorText:
          props.onboardingBasicInfo && props.onboardingBasicInfo.dateAvailable
            ? null
            : props.t("onBoarding.errorMessage.required"),
      },
      currAddrState: {
        value:
          props.onboardingBasicInfo &&
          props.onboardingBasicInfo.currentAddress &&
          props.onboardingBasicInfo.currentAddress.state
            ? props.onboardingBasicInfo.currentAddress.state
            : "",
        errors: !(
          props.onboardingBasicInfo &&
          props.onboardingBasicInfo.currentAddress &&
          props.onboardingBasicInfo.currentAddress.state
        ),
        errorText:
          props.onboardingBasicInfo &&
          props.onboardingBasicInfo.currentAddress &&
          props.onboardingBasicInfo.currentAddress.state
            ? null
            : props.t("onBoarding.errorMessage.required"),
      },
      emergencyContact: {
        value:
          props.onboardingBasicInfo && props.onboardingBasicInfo.emergencyContact
            ? props.onboardingBasicInfo.emergencyContact
            : "",
        errors: !(props.onboardingBasicInfo && props.onboardingBasicInfo.emergencyContact),
        errorText:
          props.onboardingBasicInfo && props.onboardingBasicInfo.emergencyContact
            ? null
            : props.t("onBoarding.errorMessage.required"),
      },
      emergencyPhone: {
        value:
          props.onboardingBasicInfo && props.onboardingBasicInfo.emergencyPhone
            ? props.onboardingBasicInfo.emergencyPhone
            : "",
        errors: !(props.onboardingBasicInfo && props.onboardingBasicInfo.emergencyPhone),
        errorText:
          props.onboardingBasicInfo && props.onboardingBasicInfo.emergencyPhone
            ? null
            : props.t("onBoarding.errorMessage.required"),
      },
      relation: {
        value:
          props.onboardingBasicInfo && props.onboardingBasicInfo.relation
            ? validationService.verifyDropdownValues(
                "single",
                "relation",
                props.onboardingBasicInfo.relation,
                relationship.relationship,
                "basicInfo"
              )
            : "",
        errors: !(props.onboardingBasicInfo && props.onboardingBasicInfo.relation),
        errorText:
          props.onboardingBasicInfo && props.onboardingBasicInfo.relation
            ? null
            : props.t("onBoarding.errorMessage.required"),
      },
      openModal: false,
      ...(enableHideEthnicityAndGender && { IsGenderAndEthnicityVisible: false})
    };
  }
  componentDidMount() {
    if(enableHideEthnicityAndGender){
    this.getUserAssignmentStatus();
    }
  }

  async getUserAssignmentStatus() {
    try {
      const myJobs = this.props.myJobs;
      let josStatus;
      const appliedStatuses = [
        "Upcoming Assignment",
        "Working",
        "Completed",
      ];
      if (myJobs) {
        if (myJobs.assignments?._embedded?.results) {
          josStatus = myJobs.assignments._embedded.results;
          const myJobsFilter = josStatus?.filter((job) => appliedStatuses.includes(job.jobStatus));
          this.setState({
            IsGenderAndEthnicityVisible: myJobsFilter?.length > 0 ? true : false,
          });
        }
      } else {
        const authToken = await getAccessToken();
        const params = {
          actionFilters: "quickApply,apply,favorite,email",
          companyId: Number(this.props.company?.id),
          bhCandidateId: Number(this.props.atsBullhornCandidateId || this.props.bullhornIdFromBullhorn),
        };
        const { myJobs: fetchedJobs } = await graphqlRequest(GET_MY_JOBS_STATUS, params, authToken);
        josStatus = fetchedJobs?.embedded?.results;
        const myJobsFilter = josStatus?.filter((job) => appliedStatuses.includes(job.jobStatus));
        this.setState({
          IsGenderAndEthnicityVisible: myJobsFilter?.length > 0 ? true : false,
        });
      }
    } catch (error) {
      console.error("Error occurred while fetching user assignment status:", error);
    }
  }



componentWillMount() {
    window.scrollTo(0, 0);
    if (this.props.userId && this.props.email) {
      analyticsSegmentIdentify(this.props);
    }
    const profileProgress = this.profileProgressInPercentage();
    const segmentEventName = this.props.t("segment.profileViewed");
    const segmentEventProps = {
      section: this.props.t("myProfileSubNav.basicInfo"),
      progress: profileProgress,
      origin: document.referrer,
    };
    Analytics.segmentTrackEvent(segmentEventName, segmentEventProps);
    this.props.getAllStates();
    validationService.setCustomBasicInfoValidations();
  }

  profileProgressInPercentage() {
    let completedPercentage = 0;
    let noOfStepsCompleted = 0;
    for (const prop in this.props.onBoardingCompletedSteps) {
      if (Object.prototype.hasOwnProperty.call(this.props.onBoardingCompletedSteps, prop)) {
        noOfStepsCompleted = noOfStepsCompleted + 1;
      }
    }

    completedPercentage = noOfStepsCompleted * perStepPercentage;

    return completedPercentage;
  }

  async componentDidUpdate(prevProps) {
    if (enableHideEthnicityAndGender && this.props.atsBullhornCandidateId !== prevProps.atsBullhornCandidateId ){
      this.getUserAssignmentStatus();
    }
    if (
      this.props.email !== prevProps.email ||
      this.props.recruiterDetails !== prevProps.recruiterDetails
    ) {
      analyticsSegmentIdentify(this.props);
    }
    if (
      this.props.onboardingBasicInfo !== prevProps.onboardingBasicInfo ||
      this.props.saveBasicInfoResponse !== prevProps.saveBasicInfoResponse
    ) {
      const { onboardingBasicInfo } = this.props;
            onboardingBasicInfo.stateLicensesHeld = validationService.verifyDropdownValues(
        "multiple",
        "stateLicensesHeld",
        this.props.onboardingBasicInfo.stateLicensesHeld,
        statesLicenses.states,
        "basicInfo"
      );
      onboardingBasicInfo.gender = validationService.verifyDropdownValues(
        "single",
        "gender",
        this.props.onboardingBasicInfo.gender,
        gender.gender,
        "basicInfo"
      );
      onboardingBasicInfo.ethnicity = validationService.verifyDropdownValues(
        "single",
        "ethnicity",
        this.props.onboardingBasicInfo.ethnicity,
        ethnicity.ethnicity,
        "basicInfo"
      );
      onboardingBasicInfo.relation = validationService.verifyDropdownValues(
        "single",
        "relation",
        this.props.onboardingBasicInfo.relation,
        relationship.relationship,
        "basicInfo"
      );
      
      const validators = validationService.validateSetOfFields(
        onboardingBasicInfo,
        basicInfoConstraints
      );
      await this.setState({
        shifts:
          onboardingBasicInfo && onboardingBasicInfo.shifts
            ? validationService.verifyDropdownValues(
                "multiple",
                "shifts",
                onboardingBasicInfo.shifts,
                basicInfoLists.shifts,
                "basicInfo"
              )
            : [],
        datePickerValue:
          onboardingBasicInfo && onboardingBasicInfo.dateAvailable
            ? onboardingBasicInfo.dateAvailable
            : null,
        firstname: {
          value:
            onboardingBasicInfo && onboardingBasicInfo.firstname
              ? onboardingBasicInfo.firstname
              : "",
          errors: !!(validators && validators.firstname),
          errorText: validators && validators.firstname ? validators.firstname[0] : null,
        },
        lastname: {
          value:
            onboardingBasicInfo && onboardingBasicInfo.lastname ? onboardingBasicInfo.lastname : "",
          errors: !!(validators && validators.lastname),
          errorText: validators && validators.lastname ? validators.lastname[0] : null,
        },
        preferredName: {
          value:
            this.props.onboardingBasicInfo && this.props.onboardingBasicInfo.preferredName
              ? this.props.onboardingBasicInfo.preferredName
              : "",
          errors: !!(validators && validators.preferredName),
          errorText: validators && validators.preferredName ? validators.preferredName[0] : null,
        },
        email: {
          value: onboardingBasicInfo && onboardingBasicInfo.email ? onboardingBasicInfo.email : "",
          errors: false,
          errorText: null,
        },
        stateLicensesHeld: {
          value:
            onboardingBasicInfo && onboardingBasicInfo.stateLicensesHeld
              ? onboardingBasicInfo.stateLicensesHeld
              : [],
          errors: !!(validators && validators.stateLicensesHeld),
          errorText:
            validators && validators.stateLicensesHeld ? validators.stateLicensesHeld[0] : null,
        },
        gender: {
          value:
            onboardingBasicInfo && onboardingBasicInfo.gender ? onboardingBasicInfo.gender : "",
          errors: !!(validators && validators.gender),
          errorText: validators && validators.gender ? validators.gender[0] : null,
        },
        ethnicity: {
          value:
            onboardingBasicInfo && onboardingBasicInfo.ethnicity
              ? onboardingBasicInfo.ethnicity
              : "",
          errors: !!(validators && validators.ethnicity),
          errorText: validators && validators.ethnicity ? validators.ethnicity[0] : null,
        },
        ...(enableSsnAndDobFields && {
          socialSecurityNumber: {
            value:
              onboardingBasicInfo && onboardingBasicInfo.socialSecurityNumber
                ? onboardingBasicInfo.socialSecurityNumber
                : null,
            errors: false,
            errorText: null,
          },
          isSSNAvailable: {
            value:
              onboardingBasicInfo &&
              (onboardingBasicInfo.isSSNAvailable || onboardingBasicInfo.isSSNAvailable === false)
                ? onboardingBasicInfo.isSSNAvailable
                : null,
            errors: false,
            errorText: null,
          },
                    dateOfBirth: {
            value:
              onboardingBasicInfo && onboardingBasicInfo.dateOfBirth
                ? onboardingBasicInfo.dateOfBirth
                : null,
            errors: false,
            errorText: null,
          },
        }),

        isCurrAddressDiff: {
          value:
            onboardingBasicInfo &&
            (onboardingBasicInfo.isCurrAddressDiff ||
              onboardingBasicInfo.isCurrAddressDiff === false)
              ? onboardingBasicInfo.isCurrAddressDiff.toString()
              : null,
          errors: !!(validators && validators.isCurrAddressDiff),
          errorText:
            validators && validators.isCurrAddressDiff ? validators.isCurrAddressDiff[0] : null,
        },
        currAddrStreetAddress: {
          value:
            onboardingBasicInfo &&
            onboardingBasicInfo.currentAddress &&
            onboardingBasicInfo.currentAddress.streetAddress
              ? onboardingBasicInfo.currentAddress.streetAddress
              : "",
          errors: !!(validators && validators["currentAddress.streetAddress"]),
          errorText:
            validators && validators["currentAddress.streetAddress"]
              ? validators["currentAddress.streetAddress"][0]
              : null,
        },
        currAddrZip: {
          value:
            onboardingBasicInfo &&
            onboardingBasicInfo.currentAddress &&
            onboardingBasicInfo.currentAddress.zip
              ? onboardingBasicInfo.currentAddress.zip
              : "",
          errors: !(
            onboardingBasicInfo &&
            onboardingBasicInfo.currentAddress &&
            onboardingBasicInfo.currentAddress.zip
          ),
          errorText:
            onboardingBasicInfo &&
            onboardingBasicInfo.currentAddress &&
            onboardingBasicInfo.currentAddress.zip
              ? null
              : this.props.t("onBoarding.errorMessage.required"),
        },
        phone: {
          value: onboardingBasicInfo && onboardingBasicInfo.phone ? onboardingBasicInfo.phone : "",
          errors: !!(validators && validators.phone),
          errorText: validators && validators.phone ? validators.phone[0] : null,
        },
        currAddrCity: {
          value:
            onboardingBasicInfo &&
            onboardingBasicInfo.currentAddress &&
            onboardingBasicInfo.currentAddress.city
              ? onboardingBasicInfo.currentAddress.city
              : "",
          errors: !!(validators && validators["currentAddress.city"]),
          errorText:
            validators && validators["currentAddress.city"]
              ? validators["currentAddress.city"][0]
              : null,
        },
        currAddrApt: {
          value:
            onboardingBasicInfo &&
            onboardingBasicInfo.currentAddress &&
            onboardingBasicInfo.currentAddress.apt
              ? onboardingBasicInfo.currentAddress.apt
              : "",
          errors: !!(validators && validators["currentAddress.apt"]),
          errorText:
            validators && validators["currentAddress.apt"]
              ? validators["currentAddress.apt"][0]
              : null,
        },
        currAddrState: {
          value:
            onboardingBasicInfo &&
            onboardingBasicInfo.currentAddress &&
            onboardingBasicInfo.currentAddress.state
              ? onboardingBasicInfo.currentAddress.state
              : "",
          errors: !!(validators && validators["currentAddress.state"]),
          errorText:
            validators && validators["currentAddress.state"]
              ? validators["currentAddress.state"][0]
              : null,
        },
        currAddrCountry: {
          value:
            onboardingBasicInfo &&
            onboardingBasicInfo.currentAddress &&
            onboardingBasicInfo.currentAddress.country
              ? onboardingBasicInfo.currentAddress.country
              : "US",
          errors: false,
          errorText: null,
        },
        permAddrStreetAddress: {
          value:
            onboardingBasicInfo &&
            onboardingBasicInfo.permanentAddress &&
            onboardingBasicInfo.permanentAddress.streetAddress
              ? onboardingBasicInfo.permanentAddress.streetAddress
              : "",
          errors: !!(validators && validators["permanentAddress.streetAddress"]),
          errorText:
            validators && validators["permanentAddress.streetAddress"]
              ? validators["permanentAddress.streetAddress"][0]
              : null,
        },
        permAddrCity: {
          value:
            onboardingBasicInfo &&
            onboardingBasicInfo.permanentAddress &&
            onboardingBasicInfo.permanentAddress.city
              ? onboardingBasicInfo.permanentAddress.city
              : "",
          errors: !!(validators && validators["permanentAddress.city"]),
          errorText:
            validators && validators["permanentAddress.city"]
              ? validators["permanentAddress.city"][0]
              : null,
        },
        permAddrApt: {
          value:
            onboardingBasicInfo &&
            onboardingBasicInfo.permanentAddress &&
            onboardingBasicInfo.permanentAddress.apt
              ? onboardingBasicInfo.permanentAddress.apt
              : "",
          errors: !!(validators && validators["permanentAddress.apt"]),
          errorText:
            validators && validators["permanentAddress.apt"]
              ? validators["permanentAddress.apt"][0]
              : null,
        },
        permAddrState: {
          value:
            onboardingBasicInfo &&
            onboardingBasicInfo.permanentAddress &&
            onboardingBasicInfo.permanentAddress.state
              ? onboardingBasicInfo.permanentAddress.state
              : "",
          errors: !!(validators && validators["permanentAddress.state"]),
          errorText:
            validators && validators["permanentAddress.state"]
              ? validators["permanentAddress.state"][0]
              : null,
        },
        permAddrZip: {
          value:
            onboardingBasicInfo &&
            onboardingBasicInfo.permanentAddress &&
            onboardingBasicInfo.permanentAddress.zip
              ? onboardingBasicInfo.permanentAddress.zip
              : "",
          errors: !(
            onboardingBasicInfo &&
            onboardingBasicInfo.permanentAddress &&
            onboardingBasicInfo.permanentAddress.zip
          ),

          errorText:
            onboardingBasicInfo &&
            onboardingBasicInfo.permanentAddress &&
            onboardingBasicInfo.permanentAddress.zip
              ? null
              : this.props.t("onBoarding.errorMessage.required"),
        },
        permAddrCountry: {
          value:
            onboardingBasicInfo &&
            onboardingBasicInfo.permanentAddress &&
            onboardingBasicInfo.permanentAddress.country
              ? onboardingBasicInfo.permanentAddress.country
              : "US",
          errors: false,
          errorText: null,
        },
        emergencyContact: {
          value:
            onboardingBasicInfo && onboardingBasicInfo.emergencyContact
              ? onboardingBasicInfo.emergencyContact
              : "",
          errors: !!(validators && validators.emergencyContact),
          errorText:
            validators && validators.emergencyContact ? validators.emergencyContact[0] : null,
        },
        emergencyPhone: {
          value:
            onboardingBasicInfo && onboardingBasicInfo.emergencyPhone
              ? onboardingBasicInfo.emergencyPhone
              : "",
          errors: !!(validators && validators.emergencyPhone),
          errorText: validators && validators.emergencyPhone ? validators.emergencyPhone[0] : null,
        },
        relation: {
          value:
            onboardingBasicInfo && onboardingBasicInfo.relation ? onboardingBasicInfo.relation : "",
          errors: !!(validators && validators.relation),
          errorText: validators && validators.relation ? validators.relation[0] : null,
        },
        dateAvailable: {
          value:
            onboardingBasicInfo && onboardingBasicInfo.dateAvailable
              ? onboardingBasicInfo.dateAvailable
              : null,
          errors: !!(validators && validators.dateAvailable),
          errorText: validators && validators.dateAvailable ? validators.dateAvailable[0] : null,
        },
      });
    }
  }

  /* To find value of address object based on its type */
  findByType = async (addrObj, type) => find(addrObj, (o) => o.types[0] === type);

  /* Triggered when value is selected from Permanent Address dropdown */
  handlePermAddrSelect = async (e) => {
    let validators;
    // Use Geocodes instead of Places object
    const geo = await geocodeByPlaceID(e.place_id);
    const addressObjects = geo[0].address_components;
    const streetAddress = await this.findByType(addressObjects, "street_number");
    const city = await this.findByType(addressObjects, "locality");
    const state = await this.findByType(addressObjects, "administrative_area_level_1");
    const zipCode = await this.findByType(addressObjects, "postal_code");
    const route = await this.findByType(addressObjects, "route");
    const country = await this.findByType(addressObjects, "country");
    const streetAddr = `${streetAddress ? streetAddress.long_name : ""} ${
      route ? route.long_name : ""
    }`.trim();
    if (streetAddr !== "") {
      validators = validationService.validateSingleField(
        streetAddr,
        basicInfoConstraints.permAddrStreetAddress
      );
      this.setState({
        permAddrStreetAddress: {
          value: streetAddr,
          errors: !!validators,
          errorText: validators ? validators[0] : null,
        },
      });
    }
    if (city) {
      validators = validationService.validateSingleField(
        city.long_name,
        basicInfoConstraints.permAddrCity
      );
      this.setState({
        permAddrCity: {
          value: city.long_name,
          errors: !!validators,
          errorText: validators ? validators[0] : null,
        },
      });
    }
    if (state) {
      validators = validationService.validateSingleField(
        state.short_name,
        basicInfoConstraints.permAddrState
      );
      this.setState({
        permAddrState: {
          value: state.short_name,
          errors: !!validators,
          errorText: validators ? validators[0] : null,
        },
      });
    }
    if (zipCode) {
      if (country && country.short_name === "US") {
        validators = validationService.validateSingleField(
          zipCode.long_name,
          basicInfoConstraints.permAddrZip
        );
      } else if (country && country.short_name === "CA") {
        validators = validationService.validateSingleField(
          zipCode.long_name,
          basicInfoConstraints.permAddrZipCanada
        );
      }

      this.setState({
        permAddrZip: {
          value: zipCode.long_name,
          errors: !!validators,
          errorText: validators ? validators[0] : null,
        },
      });
    }
    if (country) {
      validators = validationService.validateSingleField(
        country.short_name,
        basicInfoConstraints.permAddrCountry
      );
      this.setState({
        permAddrCountry: {
          value: country.short_name,
          errors: false,
          errorText: null,
        },
      });
    }
  };

  /* Triggered when value is selected from Permanent Address dropdown */
  handleCurrAddrSelect = async (e) => {
    let validators;
    // Use Geocodes instead of Places object
    const geo = await geocodeByPlaceID(e.place_id);
    const addressObjects = geo[0].address_components;
    const streetAddress = await this.findByType(addressObjects, "street_number");
    const city = await this.findByType(addressObjects, "locality");
    const state = await this.findByType(addressObjects, "administrative_area_level_1");
    const zipCode = await this.findByType(addressObjects, "postal_code");
    const route = await this.findByType(addressObjects, "route");
    const country = await this.findByType(addressObjects, "country");
    const streetAddr = `${streetAddress ? streetAddress.long_name : ""} ${
      route ? route.long_name : ""
    }`.trim();
    if (streetAddr !== "") {
      validators = validationService.validateSingleField(
        streetAddr,
        basicInfoConstraints.currAddrStreetAddress
      );
      this.setState({
        currAddrStreetAddress: {
          value: streetAddr,
          errors: !!validators,
          errorText: validators ? validators[0] : null,
        },
      });
    }
    if (city) {
      validators = validationService.validateSingleField(
        city.long_name,
        basicInfoConstraints.currAddrCity
      );
      this.setState({
        currAddrCity: {
          value: city.long_name,
          errors: !!validators,
          errorText: validators ? validators[0] : null,
        },
      });
    }
    if (state) {
      validators = validationService.validateSingleField(
        state.short_name,
        basicInfoConstraints.currAddrState
      );
      this.setState({
        currAddrState: {
          value: state.short_name,
          errors: !!validators,
          errorText: validators ? validators[0] : null,
        },
      });
    }
    if (zipCode) {
      if (country && country.short_name === "US") {
        validators = validationService.validateSingleField(
          zipCode.long_name,
          basicInfoConstraints.currAddrZip
        );
      } else if (country && country.short_name === "CA") {
        validators = validationService.validateSingleField(
          zipCode.long_name,
          basicInfoConstraints.currAddrZipCanada
        );
      }

      this.setState({
        currAddrZip: {
          value: zipCode.long_name,
          errors: !!validators,
          errorText: validators ? validators[0] : null,
        },
      });
    }
    if (country) {
      validators = validationService.validateSingleField(
        country.short_name,
        basicInfoConstraints.currAddrCountry
      );
      this.setState({
        currAddrCountry: {
          value: country.short_name,
          errors: false,
          errorText: null,
        },
      });
    }
  };

  /* Triggered when input value changes */
  handleChange = async (fieldType, e) => {
    this.setState({ openModal: true });
    const fieldValue = e.target.value && e.target.value.trim() !== "" ? e.target.value : "";
    if (fieldType.includes("currAddr") || fieldType.includes("permAddr")) {
      await this.setState((prevState) => {
        let validators;
        if (fieldType.includes("currAddr")) {
          const trimmedName = fieldType.replace("currAddr", "");
          const fieldName = trimmedName[0].toLowerCase() + trimmedName.substring(1);
          if (!(fieldName === "zip" && this.state.currAddrCountry.value === "CA")) {
            validators = validationService.validateSingleField(
              fieldValue,
              basicInfoConstraints[`currentAddress.${fieldName}`]
            );
          } else {
            validators = validationService.validateSingleField(
              fieldValue,
              basicInfoConstraints["currentAddress.zipCanada"]
            );
          }
        }
        if (fieldType.includes("permAddr")) {
          const trimmedName = fieldType.replace("permAddr", "");
          const fieldName = trimmedName[0].toLowerCase() + trimmedName.substring(1);
          if (!(fieldName === "zip" && this.state.permAddrCountry.value === "CA")) {
            validators = validationService.validateSingleField(
              fieldValue,
              basicInfoConstraints[`permanentAddress.${fieldName}`]
            );
          } else {
            validators = validationService.validateSingleField(
              fieldValue,
              basicInfoConstraints["permanentAddress.zipCanada"]
            );
          }
        }
        return {
          [fieldType]: {
            value: fieldValue,
            errors: !!validators,
            errorText: validators ? validators[0] : null,
          },
        };
      });
    } else {
      await this.setState((prevState) => {
        const validators = validationService.validateSingleField(
          fieldValue,
          basicInfoConstraints[fieldType]
        );
        return {
          [fieldType]: {
            value: fieldValue,
            errors: !!validators,
            errorText: validators ? validators[0] : null,
          },
        };
      });
    }
  };

  renderStateDropDown(type) {
    let renderingStates;
    if (type === "permAddrState") {
      renderingStates =
        this.state.permAddrCountry.value === "US"
          ? usStates.states
          : this.state.permAddrCountry.value === "CA"
          ? canadianStates.states
          : null;
    } else {
      renderingStates =
        this.state.currAddrCountry.value === "US"
          ? usStates.states
          : this.state.currAddrCountry.value === "CA"
          ? canadianStates.states
          : null;
    }
    return (
      <div className={this.state[type].errors ? "Mui-error" : ""}>
        <FormControl variant="outlined">
          <InputLabel shrink id="state">
            {this.props.t("basicInfoPage.stateLabel")}
          </InputLabel>
          <Select
            labelId="state-label"
            label={this.props.t("basicInfoPage.stateLabel")}
            id="state"
            notched
            value={this.state[type].value}
            onChange={this.onInputChange.bind(this, type)}
            MenuProps={SelectMenuProps}
            error={!!this.state[type].errors}
          >
            {renderingStates
              ? renderingStates
                  .sort((a, b) =>
                    a.name.toLowerCase() > b.name.toLowerCase()
                      ? 1
                      : b.name.toLowerCase() > a.name.toLowerCase()
                      ? -1
                      : 0
                  )
                  .map((stateCode) => (
                    <MenuItem key={stateCode.code} value={stateCode.code}>
                      {stateCode.name}
                    </MenuItem>
                  ))
              : null}
          </Select>
          {this.state[type].errors ? (
            <FormHelperText>{this.state[type].errorText}</FormHelperText>
          ) : null}
        </FormControl>
      </div>
    );
  }

  renderCountryDropDown(type) {
    return (
      <div>
        <FormControl variant="outlined">
          <InputLabel shrink id="country">
            {this.props.t("basicInfoPage.countryLabel")}
          </InputLabel>
          <Select
            label={this.props.t("basicInfoPage.countryLabel")}
            labelId="country-label"
            id="country"
            value={this.state[type].value}
            onChange={this.onInputChange.bind(this, type)}
            notched
            MenuProps={SelectMenuProps}
            error={!!this.state[type].errors}
          >
            {countries.countries
              ? countries.countries
                  .sort((a, b) =>
                    a.name.toLowerCase() > b.name.toLowerCase()
                      ? 1
                      : b.name.toLowerCase() > a.name.toLowerCase()
                      ? -1
                      : 0
                  )
                  .map((country) => (
                    <MenuItem key={country.code} value={country.code}>
                      {country.name}
                    </MenuItem>
                  ))
              : null}
          </Select>
          {this.state[type].errors ? (
            <FormHelperText>{this.state[type].errorText}</FormHelperText>
          ) : null}
        </FormControl>
      </div>
    );
  }

  onInputChange = async (type, event) => {
    const { value } = event.target;
    const validators = validationService.validateSingleField(value, basicInfoConstraints[type]);
    await this.setState({
      [type]: {
        value,
        errors: !!validators,
        errorText: validators ? validators[0] : null,
      },
      openModal: !validators,
    });
    if (type.includes("Country")) {
      await this.setState({
        [type.replace("Country", "State")]: {
          value: "",
          errors: true,
          errorText: this.props.t("onBoarding.errorMessage.required"),
        },
        [type.replace("Country", "Zip")]: {
          value: "",
          errors: true,
          errorText: this.props.t("onBoarding.errorMessage.required"),
        },
      });
    }
    if (enableSsnAndDobFields && type.includes("socialSecurityNumber")) {
      if (value.length === 11) {
        const ssnKey = type.replace("socialSecurityNumber", "isSSNAvailable");
        await this.setState({
          [ssnKey]: {
            value: true,
            errors: false,
            errorText: null,
          },
        });
      }
    }

    if (enableSsnAndDobFields && type.includes("isSSNAvailable")) {
      const ssnKey = type.replace("isSSNAvailable", "socialSecurityNumber");
      await this.setState({
        [type]: {
          value: Boolean(value) ? !value : null,
          errors: false,
          errorText: null,
        },
        [ssnKey]: {
          value: null,
          errors: false,
          errorText: null,
        },
      });
    }
  };

  radioBtnHandleChange(type, e) {
    if (type === "isCurrAddressDiff") {
      this.setState({
        isCurrAddressDiff: {
          value: e.target.value,
          errors: false,
          errorText: null,
        },
        openModal: true,
      });
    }
  }

  renderShifts(shifts, type) {
    return shifts.map((shift) => (
      <Chip
        key={shift}
        label={shift}
        size="normal"
        onClick={() => this.handleOnChipClick(shift, type)}
        className={this.state.shifts.includes(shift) ? "onBChipSelected" : "onBOtherChip"}
      />
    ));
  }

  handleOnChipClick(value, type) {
    this.setState({ openModal: true });
    if (type === "shifts") {
      if (this.state.shifts.includes(value)) this.removeSelectedValue(value, type);
      else this.addSelectedValue(value, type);
    }
  }

  addSelectedValue(item, type) {
    if (type === "shifts")
      this.setState((preState) => ({
        shifts: [...preState.shifts, item],
      }));
  }

  removeSelectedValue(item, type) {
    if (type === "shifts")
      this.setState((preState) => ({
        shifts: preState.shifts.filter((i) => i !== item),
      }));
  }

  handleOnAllChipClick(type) {
    this.setState({ openModal: true });
    if (type === "shifts") {
      if (this.state.shifts.length === 6) this.resetSelectedValues(type);
      else this.selectAllValues(type);
    }
  }

  resetSelectedValues(type) {
    if (type === "shifts") this.setState({ shifts: [] });
  }

  selectAllValues(type) {
    if (type === "shifts") this.setState({ shifts: this.state.shiftsJson });
  }

  handleDateChange = async (date, value) => {
    const validators = validationService.validateSingleField(
      value,
      basicInfoConstraints.dateAvailable
    );
    await this.setState({
      dateAvailable: {
        value,
        errors: !!validators,
        errorText: validators ? validators[0] : null,
      },
      openModal: true,
      onPageDateLoad: false,
      datePickerValue: value,
    });
  };

  handleMultipleSelect = async (event, type) => {
    this.setState({ openModal: true });
    if (type === "gender" && event.target.value === "Clear") {
      await this.setState({
        gender: { value: "", errors: false, errorText: null },
      });
    } else {
      const { value } = event.target;

      const validators = validationService.validateSingleField(value, basicInfoConstraints[type]);
      await this.setState({
        [type]: {
          value,
          errors: !!(validators && validators[type]),
          errorText: validators && validators[type] ? validators[type][0] : null,
        },
      });
    }
  };

  NextBtnClickHandler = async (pageRedirectedFrom, pageToRedirect, redirection) => {
    this.setState({ openModal: false });
    const basicInfoReqObj = {};
    const currentAddress = {};
    const permanentAddress = {};
    this.state.basicInfoFields.forEach((value) => {
      if (value === "shifts") {
        if (this.state[value] && this.state[value].length > 0) {
          basicInfoReqObj[value] = this.state[value];
        }
      } else if (value === "stateLicensesHeld") {
        if (this.state[value].value.length > 0) {
          basicInfoReqObj[value] = this.state[value].value;
        }
      } else if (value.includes("currentAddress")) {
        const stateFieldName = value.replace("currentAddress.", "");
        const trimmedAddr = value.replace("currentAddress", "currAddr");
        const charToUpperCase = trimmedAddr.charAt(9);
        const fieldName = trimmedAddr.replace(`.${charToUpperCase}`, charToUpperCase.toUpperCase());
        if (this.state[fieldName].value && this.state[fieldName].value.length > 0) {
          currentAddress[stateFieldName] = this.state[fieldName].value;
        }
      } else if (value.includes("permanentAddress")) {
        const stateFieldName = value.replace("permanentAddress.", "");
        const trimmedAddr = value.replace("permanentAddress", "permAddr");
        const charToUpperCase = trimmedAddr.charAt(9);
        const fieldName = trimmedAddr.replace(`.${charToUpperCase}`, charToUpperCase.toUpperCase());
        if (this.state[fieldName].value && this.state[fieldName].value.length > 0) {
          permanentAddress[stateFieldName] = this.state[fieldName].value;
        }
      } else if (
        (value.includes("socialSecurityNumber") || value.includes("isSSNAvailable")) &&
        enableSsnAndDobFields
      ) {
          basicInfoReqObj[value] = this.state["socialSecurityNumber"].value === "" ? null : this.state[value].value;
      } else if (this.state[value]?.value) {
        basicInfoReqObj[value] = this.state[value].value;
      }
    });
    if (currentAddress && this.state.isCurrAddressDiff.value === "true") {
      basicInfoReqObj.currentAddress = currentAddress;
    }
    if (permanentAddress) {
      basicInfoReqObj.permanentAddress = permanentAddress;
    }
    if (enableSsnAndDobFields) {
      if (
        (this.props?.onboardingBasicInfo?.dateOfBirth !== null ||
          this.props?.onboardingBasicInfo?.dateOfBirth !== "") &&
        this.state.dateOfBirth === null
      ) {
        basicInfoReqObj.dateOfBirth = "";
      } else {
        basicInfoReqObj.dateOfBirth = this.state.dateOfBirth.value === "" ? null : this.state.dateOfBirth.value;
      }
    }

    const validators = validationService.validateSetOfFields(basicInfoReqObj, basicInfoConstraints);
    delete validators["permanentAddress.zipCanada"];
    delete validators["currentAddress.zipCanada"];
    if (basicInfoReqObj.currentAddress && basicInfoReqObj.currentAddress.country === "CA") {
      if (basicInfoReqObj.currentAddress.zip && basicInfoReqObj.currentAddress.zip !== "") {
        delete validators["currentAddress.zip"];
      }
      if (basicInfoReqObj.currentAddress.zip) {
        const validatorZipCanada = validationService.validateSingleField(
          basicInfoReqObj.currentAddress.zip,
          basicInfoConstraints["currentAddress.zipCanada"]
        );
        if (validatorZipCanada) {
          validators["currentAddress.zip"] = validatorZipCanada;
        }
      }
    }
    if (basicInfoReqObj.permanentAddress && basicInfoReqObj.permanentAddress.country === "CA") {
      if (basicInfoReqObj.permanentAddress.zip && basicInfoReqObj.permanentAddress.zip !== "") {
        delete validators["permanentAddress.zip"];
      }
      if (basicInfoReqObj.permanentAddress.zip) {
        const validatorZipCanada = validationService.validateSingleField(
          basicInfoReqObj.permanentAddress.zip,
          basicInfoConstraints["permanentAddress.zipCanada"]
        );
        if (validatorZipCanada) {
          validators["permanentAddress.zip"] = validatorZipCanada;
        }
      }
    }

    // need to remove this if dob and ssn becomes a permanent field on basic info
    if (!enableSsnAndDobFields) {
      delete validators["dateOfBirth"];
      delete validators["socialSecurityNumber"];
      delete validators["isSSNAvailable"];
      delete basicInfoReqObj.dateOfBirth;
      delete basicInfoReqObj.socialSecurityNumber;
      delete basicInfoReqObj.isSSNAvailable;
    }
    let fieldsWithError = [];
    fieldsWithError = validators ? Object.keys(validators) : [];
    const canBeSavedPartially = fieldsWithError.every((field) =>
      validators[field].includes("* Required")
    );
    if (canBeSavedPartially) {
      const reqObj = {
        event: BASIC_INFO,
        candidateId: this.props.candidateId,
        email: this.state.email.value,
        companyId: parseInt(this.props.company.id),
        sqsEvent: true,
        profile: {
          meta: {
            lastVisitedPage: "0",
            completedSections: {
              basicInfo: !(validators && Object.keys(validators).length !== 0),
              specialtiesAndEducation: !!this.props.onBoardingCompletedSteps[1],
              workExperience: !!this.props.onBoardingCompletedSteps[2],
              references: !!this.props.onBoardingCompletedSteps[3],
              skillsChecklists: !!this.props.onBoardingCompletedSteps[4],
            },
          },
          basicInfo: basicInfoReqObj,
        },
      };
      if (
        this.props.onboardingBasicInfo.permanentAddress !== basicInfoReqObj.permanentAddress ||
        this.props.onboardingBasicInfo.firstname !== basicInfoReqObj.firstname ||
        this.props.onboardingBasicInfo.lastname !== basicInfoReqObj.lastname
      ) {
        this.triggerSegmentIdentify(basicInfoReqObj);
      }
      const references = this.props.onBoardingReferences;
      this.props.saveTheUserProfile(reqObj, references, this.props.onboardingWorkExperience).then(
        (res) => {
          const onBoardingCompletedStepsFinal = this.props.onBoardingCompletedSteps;
          let isBasicInfoComplete = false;
          if (!validators) {
            onBoardingCompletedStepsFinal[0] = true;
            isBasicInfoComplete = true;
          }
          this.props.updateCompleteFlag(onBoardingCompletedStepsFinal);
          this.props.updateActiveFlag(1);
          const dimensions = {
            dimension9:
              this.props.candidateSpecialties && this.props.candidateSpecialties.length > 0
                ? this.props.candidateSpecialties.toString()
                : null,
          };
          const event = {
            category: this.props.t("googleAnalytics.category.onboarding"),
            action: this.props.t("googleAnalytics.action.submitBasicInfo"),
            label: this.props.t("googleAnalytics.label.basicInfo"),
          };
          Analytics.sendPageview(this.props.history.location, dimensions);
          Analytics.sendEvent(event);
          const profileProgress = this.profileProgressInPercentage();
          const segmentEventName = this.props.t("segment.profileUpdated");
          const segmentEventProps = {
            section: this.props.t("myProfileSubNav.basicInfo"),
            progress: profileProgress,
            origin: document.referrer,
          };
          Analytics.segmentTrackEvent(segmentEventName, segmentEventProps);
          if (redirection === "Next") {
            this.props.history.push(EDU_SPEC_PAGE);
          } else if (redirection === "Done") {
            if (isBasicInfoComplete) {
              //Complete Application event triggered
              const event = {
                category: this.props.t("googleAnalytics.category.onboarding"),
                action: this.props.t("googleAnalytics.action.completeApplication"),
                label: this.props.t("googleAnalytics.label.application"),
              };
              const dimensions = {
                dimension9:
                  this.props.candidateSpecialties && this.props.candidateSpecialties.length > 0
                    ? this.props.candidateSpecialties.toString()
                    : null,
              };
              Analytics.sendPageview(this.props.history.location, dimensions);
              Analytics.sendEvent(event);
            }
            this.props.history.push(DASHBOARD);
          }
        },
        (err) => {
          console.error(err);
        }
      );
    } else {
      const errorMessages = [];
      fieldsWithError.forEach((field) => {
        if (!validators[field].includes("* Required")) {
          validators[field].forEach((value) => {
            if (value.includes("* ")) {
              value = value.replace("* ", "");
            }
            errorMessages.push(value);
          });
        }
      });
      await this.setState({
        canBeSavedPartially,
        errorMessages,
      });
      if (!this.state.canBeSavedPartially) {
        window.scrollTo(0, 0);
      }
    }
  };

  triggerSegmentIdentify(basicInfoReqObj) {
    const obj = {
      userId: this.props.userId,
      email: this.props.email,
      firstName: this.props.firstName,
      lastName: this.props.lastName,
      phone: this.props.phone,
      company: this.props.companyDetails,
      profession: this.props.profession,
      specialties: this.props.specialties,
      streetAddress:
        basicInfoReqObj &&
        basicInfoReqObj.permanentAddress &&
        basicInfoReqObj.permanentAddress.streetAddress
          ? basicInfoReqObj.permanentAddress.streetAddress
          : null,
      city:
        basicInfoReqObj && basicInfoReqObj.permanentAddress && basicInfoReqObj.permanentAddress.city
          ? basicInfoReqObj.permanentAddress.city
          : null,
      state:
        basicInfoReqObj &&
        basicInfoReqObj.permanentAddress &&
        basicInfoReqObj.permanentAddress.state
          ? basicInfoReqObj.permanentAddress.state
          : null,
      zip:
        basicInfoReqObj && basicInfoReqObj.permanentAddress && basicInfoReqObj.permanentAddress.zip
          ? basicInfoReqObj.permanentAddress.zip
          : null,
      recruiterFname:
        this.props.recruiterDetails && this.props.recruiterDetails.firstName
          ? this.props.recruiterDetails.firstName
          : null,
      recruiterLname:
        this.props.recruiterDetails && this.props.recruiterDetails.lastName
          ? this.props.recruiterDetails.lastName
          : null,
      recruiterEmail:
        this.props.recruiterDetails && this.props.recruiterDetails.emailId
          ? this.props.recruiterDetails.emailId
          : null,
    };
    Analytics.segmentIdentify(obj);
  }

  renderEmailBanner() {
    if (enableEmailRecruiter && this.props.recruiterDetails?.emailId) {
      return (
        <>
          <Col
            sm={12}
            xs={12}
            md={12}
            lg={12}
            className="onBBasicInfoBanner onBBasicInfoChangeBanner"
          />
          <Col className="onBoardingBasicInfoChangeWrapper">
            <div className="changeBasicInfo">
              <span>{this.props.t("onBoarding.basicInfo.changeBasicInfo")} </span>
              <a
                className="emailRecruiter"
                onClick={() =>
                  (window.location = `mailto:${
                    this.props.recruiterDetails?.emailId
                  }?subject=${encodeURIComponent(
                    this.props.t("onBoarding.basicInfo.changeBasicInfoEmailSubjectLine")
                  )}`)
                }
              >
                Email Recruiter
              </a>
            </div>
          </Col>
        </>
      );
    } else {
      return <Col sm={12} xs={12} md={12} lg={12} className="onBBasicInfoBanner" />;
    }
  }

  isPhoneNumberInputDisabled() {
    return this.props.onboardingBasicInfo ? !!this.props.onboardingBasicInfo.phone : false;
  }

  render() {
    const allStepsCompleted =
      this.props.onBoardingCompletedSteps[0] &&
      this.props.onBoardingCompletedSteps[1] &&
      this.props.onBoardingCompletedSteps[2] &&
      this.props.onBoardingCompletedSteps[3] &&
      this.props.onBoardingCompletedSteps[4];

    const onlyStepLeftToComplete =
      this.props.onBoardingCompletedSteps[1] &&
      this.props.onBoardingCompletedSteps[2] &&
      this.props.onBoardingCompletedSteps[3] &&
      this.props.onBoardingCompletedSteps[4];
    return (
      <div>
        {this.state.openModal ? <OnBoardingConfirmModal enabled={this.state.openModal} /> : <></>}
        {/*  My Profile Navigation */}
        <Container fluid className="myProfileWrap">
          <Row>
            <MyProfileNavWrapper />
            <div className="myProfilePageWrap">
              {/*  Basic Information Page starts */}
              <Container>
                {!this.state.canBeSavedPartially ? (
                  <>
                    <Container>
                      <Row>
                        <Col sm={12} xs={12} md={12} lg={12} className="onBServErrWrap">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 24"
                            fill="#fff"
                            width="20px"
                            height="20px"
                          >
                            <path d="M0 0h24v24H0V0z" fill="none" />
                            <path d="M11 7h2v2h-2zm0 4h2v6h-2zm1-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z" />
                          </svg>
                          <span className="onBServErrTxt">
                            {this.props.t("onBoarding.basicInfo.validationErrorText")}
                          </span>
                          <div className="onBServErrSubTxt">
                            {this.props.t("onBoarding.basicInfo.validationErrorText1")}
                          </div>
                          <ul className="onBServErrList">
                            {this.state.errorMessages.map((message, index) => (
                              <li key={index}>{message}</li>
                            ))}
                          </ul>
                        </Col>
                      </Row>
                    </Container>
                  </>
                ) : null}

                <Row>
                  {this.renderEmailBanner()}
                </Row>
                <div className="onBMainTxtField">
                  <Row>
                    <Col sm={12} xs={12} md={12} lg={12}>
                      <Row className={isMobile ? "mb-3" : "mb-4"}>
                        <Col
                          sm={12}
                          xs={12}
                          md={4}
                          lg={4}
                          className={
                            this.state.firstname.errors
                              ? "onBTextFieldReq onBtextfiled txtFldMBMob"
                              : "onBtextfiled txtFldMBMob"
                          }
                        >
                          <div className="onBReadOnlyLabel">
                            {this.props.t("onBoarding.basicInfo.firstnameLabel")}
                          </div>
                          <div className="onBReadOnlyValue">
                            {this.state.firstname.value}
                          </div>
                        </Col>
                        <Col
                          sm={12}
                          xs={12}
                          md={4}
                          lg={4}
                          className={
                            this.state.lastname.errors
                              ? "onBTextFieldReq onBtextfiled txtFldMBMob"
                              : "onBtextfiled txtFldMBMob"
                          }
                        >
                          <div className="onBReadOnlyLabel">
                            {this.props.t("onBoarding.basicInfo.lastnameLabel")}
                          </div>
                          <div className="onBReadOnlyValue">
                            {this.state.lastname.value}
                          </div>
                        </Col>
                        <Col
                          sm={12}
                          xs={12}
                          md={4}
                          lg={4}
                          className={
                            this.state.preferredName.errors
                              ? "onBTextFieldReq onBtextfiled"
                              : "onBtextfiled "
                          }
                        >
                          <TextField
                            label={this.props.t("onBoarding.basicInfo.prefernameLabel")}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            variant="outlined"
                            type="Preferred Name"
                            value={this.state.preferredName.value}
                            onChange={this.handleChange.bind(this, "preferredName")}
                            error={
                              this.state.preferredName.value === ""
                                ? false
                                : !!this.state.preferredName.errors
                            }
                            helperText={
                              this.state.preferredName.errors
                                ? this.state.preferredName.errorText
                                : ""
                            }
                          />
                        </Col>
                      </Row>
                      <Row className={isMobile ? "mb-3" : "mb-4"}>
                        <Col
                          sm={12}
                          xs={12}
                          md={4}
                          lg={4}
                          className={
                            this.state.email.errors
                              ? "onBTextFieldReq onBtextfiled txtFldMBMob"
                              : "onBtextfiled txtFldMBMob"
                          }
                        >
                          <div className="onBReadOnlyLabel">
                            {this.props.t("onBoarding.basicInfo.emailLabel")}
                          </div>
                          <div className="onBReadOnlyValue">
                            {this.state.email.value}
                          </div>
                        </Col>
                        <Col
                          sm={12}
                          xs={12}
                          md={4}
                          lg={4}
                          className={
                            this.state.phone.errors
                              ? "onBTextFieldReq onBtextfiled txtFldMBMob"
                              : "onBtextfiled txtFldMBMob"
                          }
                        >
                          {this.isPhoneNumberInputDisabled() ? (
                            <>
                              <div className="onBReadOnlyLabel">
                                {this.props.t("onBoarding.basicInfo.phoneLabel")}
                              </div>
                              <div className="onBReadOnlyValue">
                                {this.state.phone.value}
                              </div>
                            </>
                          ) : (
                            <TextField
                              label={this.props.t("onBoarding.basicInfo.phoneLabel")}
                              InputLabelProps={{
                                shrink: true,
                              }}
                              variant="outlined"
                              type="Phone Number"
                              value={this.state.phone.value}
                              disabled={this.isPhoneNumberInputDisabled()}
                              onChange={this.handleChange.bind(this, "phone")}
                              error={
                                this.state.phone.value === "" ? false : !!this.state.phone.errors
                              }
                              helperText={this.state.phone.errors ? this.state.phone.errorText : ""}
                              autoComplete="new-phone"
                            />
                          )}
                        </Col>
                        {/* State licenses held */}
                        <Col
                          sm={12}
                          xs={12}
                          md={4}
                          lg={4}
                          className={
                            this.state.stateLicensesHeld.errors
                              ? "onBTextFieldReq onBtextfiled"
                              : "onBtextfiled "
                          }
                        >
                          <div>
                            <FormControl variant="outlined">
                              <MultipleSelect
                                fieldValue={this.state.stateLicensesHeld}
                                fieldLabel={this.props.t("onBoarding.basicInfo.sLHeldLabel")}
                                fieldName="stateLicensesHeld"
                                fieldOptions={statesLicenses.states}
                                handleMultipleSelect={this.handleMultipleSelect}
                                isMultiple
                                error={
                                  this.state.stateLicensesHeld.value === ""
                                    ? false
                                    : !!this.state.stateLicensesHeld.errors
                                }
                                helperText={
                                  this.state.stateLicensesHeld.errors
                                    ? this.state.stateLicensesHeld.errorText
                                    : null
                                }
                              />
                            </FormControl>
                          </div>
                        </Col>
                      </Row>

                      {/* PUT FLAG HERE */}
                      <Row>
                        {!ethnicityAndGender && (
                          <Col
                            sm={12}
                            xs={12}
                            md={4}
                            lg={4}
                            className={
                              this.state.gender.errors
                                ? "onBTextFieldReq onBtextfiled"
                                : "onBtextfiled "
                            }
                          >
                            <div>
                              <MultipleSelect
                                fieldValue={this.state.gender}
                                fieldLabel={this.props.t("onBoarding.basicInfo.yourGenderLabel")}
                                fieldName="gender"
                                fieldOptions={gender.gender}
                                handleMultipleSelect={this.handleMultipleSelect}
                                isMultiple={false}
                                error={
                                  this.state.gender.value === ""
                                    ? false
                                    : !!this.state.gender.errors
                                }
                                helperText={
                                  this.state.gender.errors ? this.state.gender.errorText : null
                                }
                              />
                            </div>
                          </Col>
                        )}
                        {enableSsnAndDobFields && (
                          <BasicInfoSsnAndDOB
                            isSSNAvailable={this.props?.onboardingBasicInfo?.isSSNAvailable}
                            socialSecurityNumber={
                              this?.props.onboardingBasicInfo?.socialSecurityNumber
                            }
                            dateOfBirth={this.props?.onboardingBasicInfo?.dateOfBirth}
                            dobValue={this.state?.dateOfBirth}
                            ssnValue={this.state?.socialSecurityNumber}
                            noSsn={this.state?.isSSNAvailable}
                            onInputChange={this.onInputChange}
                            handleChange={this.handleChange}
                          />
                        )}
                      </Row>
                      <Row>
                        <Col sm={12} xs={12} md={12} lg={12}>
                          <div className="onBSubHeading">
                            {this.props.t("onBoarding.basicInfo.subHeading")}
                          </div>
                        </Col>
                      </Row>

                      <Row className={isMobile ? "mb-3" : "mb-4"}>
                        <Col
                          sm={12}
                          xs={12}
                          md={4}
                          lg={4}
                          className={
                            this.state.permAddrStreetAddress.errors
                              ? "onBTextFieldReq onBtextfiled txtFldMBMob"
                              : "onBtextfiled txtFldMBMob"
                          }
                        >
                          <div className="googlePlaces">
                            <MUIPlacesAutocomplete
                              onSuggestionSelected={this.handlePermAddrSelect}
                              renderTarget={() => <div />}
                              textFieldProps={{
                                label: this.props.t("onBoarding.basicInfo.streetLabel"),
                                InputLabelProps: {
                                  shrink: true,
                                },
                                variant: "outlined",
                                type: "Street Address",
                                onChange: this.handleChange.bind(this, "permAddrStreetAddress"),
                                error: !!this.state.permAddrStreetAddress.errors,
                                helperText: this.state.permAddrStreetAddress.errors
                                  ? this.state.permAddrStreetAddress.errorText
                                  : "",
                                value: this.state.permAddrStreetAddress.value,
                                autoComplete: "new-streetAddress",
                              }}
                            />
                          </div>
                        </Col>
                        <Col
                          sm={12}
                          xs={12}
                          md={4}
                          lg={4}
                          className={
                            this.state.permAddrApt.errors
                              ? "onBTextFieldReq onBtextfiled txtFldMBMob"
                              : "onBtextfiled txtFldMBMob"
                          }
                        >
                          <TextField
                            label={this.props.t("onBoarding.basicInfo.aptLabel")}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            variant="outlined"
                            type="Apt"
                            onChange={this.handleChange.bind(this, "permAddrApt")}
                            helperText={
                              this.state.permAddrApt.errors ? this.state.permAddrApt.errorText : ""
                            }
                            error={
                              this.state.permAddrApt.value === ""
                                ? false
                                : !!this.state.permAddrApt.errors
                            }
                            value={this.state.permAddrApt.value}
                            autoComplete="new-apt"
                          />
                        </Col>
                        <Col
                          sm={12}
                          xs={12}
                          md={4}
                          lg={4}
                          className={
                            this.state.permAddrCity.errors
                              ? "onBTextFieldReq onBtextfiled "
                              : "onBtextfiled "
                          }
                        >
                          <TextField
                            label={this.props.t("onBoarding.basicInfo.cityLabel")}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            variant="outlined"
                            type="City"
                            onChange={this.handleChange.bind(this, "permAddrCity")}
                            helperText={
                              this.state.permAddrCity.errors
                                ? this.state.permAddrCity.errorText
                                : ""
                            }
                            error={!!this.state.permAddrCity.errors}
                            value={this.state.permAddrCity.value}
                            autoComplete="new-city"
                          />
                        </Col>
                      </Row>

                      <Row>
                        <Col
                          sm={12}
                          xs={12}
                          md={4}
                          lg={4}
                          className={
                            this.state.permAddrState.errors
                              ? "onBTextFieldReq onBtextfiled txtFldMBMob"
                              : "onBtextfiled txtFldMBMob"
                          }
                        >
                          <div>{this.renderStateDropDown("permAddrState")}</div>
                        </Col>
                        <Col
                          sm={12}
                          xs={12}
                          md={4}
                          lg={4}
                          className={
                            this.state.permAddrZip.errors
                              ? "onBTextFieldReq onBtextfiled txtFldMBMob"
                              : "onBtextfiled txtFldMBMob"
                          }
                        >
                          <TextField
                            label={this.props.t("basicInfoPage.zipCodeLabel")}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            type="Zip Code"
                            value={this.state.permAddrZip.value}
                            variant="outlined"
                            onChange={this.handleChange.bind(this, "permAddrZip")}
                            error={!!this.state.permAddrZip.errors}
                            helperText={
                              this.state.permAddrZip.errors ? this.state.permAddrZip.errorText : ""
                            }
                            autoComplete="new-zip"
                          />
                        </Col>
                        {/* Contry dropdown for Cancadian Adddress */}
                        <Col sm={12} xs={12} md={4} lg={4} className="onBtextfiled">
                          <div>{this.renderCountryDropDown("permAddrCountry")}</div>
                        </Col>
                      </Row>
                      <div className="onBPermanentAddMain">
                        <Row>
                          <Col sm={12} xs={12} md={12} lg={12}>
                            <div className="onBTextFieldReq">
                              <label className="onBPermanentAdd">
                                {this.props.t("onBoarding.basicInfo.currentAddDiffPermanentAdd")}
                              </label>

                              <RadioGroup
                                row
                                style={{ display: "inline-block" }}
                                value={this.value}
                                onChange={this.radioBtnHandleChange.bind(this, "isCurrAddressDiff")}
                              >
                                <FormControlLabel
                                  value="false"
                                  className="onBAddRadioBtn"
                                  control={
                                    <Radio
                                      name="checkedcurraddrdiffno"
                                      checked={this.state.isCurrAddressDiff.value === "false"}
                                    />
                                  }
                                  label={this.props.t("onBoarding.basicInfo.travellingOption1")}
                                />
                                <FormControlLabel
                                  value="true"
                                  className="onBAddRadioBtn"
                                  control={
                                    <Radio
                                      name="checkedcurraddrdiffyes"
                                      checked={this.state.isCurrAddressDiff.value === "true"}
                                    />
                                  }
                                  label={this.props.t("onBoarding.basicInfo.travellingOption2")}
                                />
                                <FormHelperText className="onBWorkExpErrorChkBox d-inline">
                                  {this.state.isCurrAddressDiff.errors
                                    ? this.state.isCurrAddressDiff.errorText
                                    : null}
                                </FormHelperText>
                              </RadioGroup>
                            </div>
                          </Col>
                        </Row>
                        <>
                          {this.state.isCurrAddressDiff.value === "true" ? (
                            <>
                              <Row className={isMobile ? "mb-3" : "mb-4"}>
                                <Col
                                  sm={12}
                                  xs={12}
                                  md={4}
                                  lg={4}
                                  className={
                                    this.state.currAddrStreetAddress.errors
                                      ? "onBTextFieldReq onBtextfiled txtFldMBMob"
                                      : "onBtextfiled txtFldMBMob"
                                  }
                                >
                                  <div className="googlePlaces">
                                    <MUIPlacesAutocomplete
                                      onSuggestionSelected={this.handleCurrAddrSelect}
                                      renderTarget={() => <div />}
                                      label={this.props.textFieldProps}
                                      InputLabel={{
                                        shrink: true,
                                      }}
                                      textFieldProps={{
                                        label: this.props.t(
                                          "onBoarding.basicInfo.currentstreetLabel"
                                        ),
                                        InputLabelProps: {
                                          shrink: true,
                                        },
                                        type: "Street Address",
                                        onChange: this.handleChange.bind(
                                          this,
                                          "currAddrStreetAddress"
                                        ),
                                        variant: "outlined",
                                        helperText: this.state.currAddrStreetAddress.errors
                                          ? this.state.currAddrStreetAddress.errorText
                                          : "",
                                        error: !!this.state.currAddrStreetAddress.errors,
                                        value: this.state.currAddrStreetAddress.value,
                                        autoComplete: "new-streetAddress",
                                      }}
                                    />
                                  </div>
                                </Col>
                                <Col
                                  sm={12}
                                  xs={12}
                                  md={4}
                                  lg={4}
                                  className={
                                    this.state.currAddrApt.errors
                                      ? "onBTextFieldReq onBtextfiled txtFldMBMob"
                                      : "onBtextfiled txtFldMBMob"
                                  }
                                >
                                  <TextField
                                    label={this.props.t("onBoarding.basicInfo.aptLabel")}
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    type="Apt"
                                    variant="outlined"
                                    onChange={this.handleChange.bind(this, "currAddrApt")}
                                    error={!!this.state.currAddrApt.errors}
                                    helperText={
                                      this.state.currAddrApt.errors
                                        ? this.state.currAddrApt.errorText
                                        : ""
                                    }
                                    value={this.state.currAddrApt.value}
                                    autoComplete="new-apt"
                                  />
                                </Col>
                                <Col
                                  sm={12}
                                  xs={12}
                                  md={4}
                                  lg={4}
                                  className={
                                    this.state.currAddrCity.errors
                                      ? "onBTextFieldReq onBtextfiled"
                                      : "onBtextfiled "
                                  }
                                >
                                  <TextField
                                    label={this.props.t("onBoarding.basicInfo.cityLabel")}
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    variant="outlined"
                                    type="City"
                                    onChange={this.handleChange.bind(this, "currAddrCity")}
                                    error={!!this.state.currAddrCity.errors}
                                    helperText={
                                      this.state.currAddrCity.errors
                                        ? this.state.currAddrCity.errorText
                                        : ""
                                    }
                                    value={this.state.currAddrCity.value}
                                    autoComplete="new-city"
                                  />
                                </Col>
                              </Row>
                              <Row>
                                <Col
                                  sm={12}
                                  xs={12}
                                  md={4}
                                  lg={4}
                                  className={
                                    this.state.currAddrState.errors
                                      ? "onBTextFieldReq onBtextfiled txtFldMBMob"
                                      : "onBtextfiled txtFldMBMob"
                                  }
                                >
                                  <div>{this.renderStateDropDown("currAddrState")}</div>
                                </Col>
                                <Col
                                  sm={12}
                                  xs={12}
                                  md={4}
                                  lg={4}
                                  className={
                                    this.state.currAddrZip.errors
                                      ? "onBTextFieldReq onBtextfiled txtFldMBMob"
                                      : "onBtextfiled txtFldMBMob"
                                  }
                                >
                                  <TextField
                                    label={this.props.t("basicInfoPage.zipCodeLabel")}
                                    InputLabelProps={{
                                      shrink: true,
                                    }}
                                    type="Zip Code"
                                    variant="outlined"
                                    value={this.state.currAddrZip.value}
                                    onChange={this.handleChange.bind(this, "currAddrZip")}
                                    error={!!this.state.currAddrZip.errors}
                                    helperText={
                                      this.state.currAddrZip.errors
                                        ? this.state.currAddrZip.errorText
                                        : ""
                                    }
                                    autoComplete="new-zip"
                                  />
                                </Col>
                                {/* Contry dropdown for Cancadian Adddress */}
                                <Col sm={12} xs={12} md={4} lg={4} className="onBtextfiled">
                                  <div>{this.renderCountryDropDown("currAddrCountry")}</div>
                                </Col>
                              </Row>
                              <br />
                            </>
                          ) : null}
                        </>
                      </div>
                      <Row>
                        <Col sm={12} xs={12} md={12} lg={12}>
                          <div className="onBSubHeading">
                            {this.props.t("onBoarding.basicInfo.emergencyContact")}
                          </div>
                        </Col>
                      </Row>

                      <Row className={isMobile ? "mb-3" : "mb-4"}>
                        <Col
                          sm={12}
                          xs={12}
                          md={4}
                          lg={4}
                          className={
                            this.state.emergencyContact.errors
                              ? "onBTextFieldReq onBtextfiled txtFldMBMob"
                              : "onBtextfiled txtFldMBMob"
                          }
                        >
                          <TextField
                            label={this.props.t("onBoarding.basicInfo.emergencyContactName")}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            type="Emergency Contact Name"
                            variant="outlined"
                            value={this.state.emergencyContact.value}
                            onChange={this.handleChange.bind(this, "emergencyContact")}
                            error={!!this.state.emergencyContact.errors}
                            helperText={
                              this.state.emergencyContact.errors
                                ? this.state.emergencyContact.errorText
                                : ""
                            }
                            autoComplete="new-emergencyContact"
                          />
                        </Col>
                        <Col
                          sm={12}
                          xs={12}
                          md={4}
                          lg={4}
                          className={
                            this.state.emergencyPhone.errors
                              ? "onBTextFieldReq onBtextfiled txtFldMBMob"
                              : "onBtextfiled txtFldMBMob"
                          }
                        >
                          <TextField
                            label={this.props.t("onBoarding.basicInfo.phoneLabel")}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            variant="outlined"
                            type="Phone Number"
                            value={this.state.emergencyPhone.value}
                            onChange={this.handleChange.bind(this, "emergencyPhone")}
                            error={!!this.state.emergencyPhone.errors}
                            helperText={
                              this.state.emergencyPhone.errors
                                ? this.state.emergencyPhone.errorText
                                : ""
                            }
                            autoComplete="new-emergencyPhone"
                          />
                        </Col>
                        {/* Relationship */}
                        <Col
                          sm={12}
                          xs={12}
                          md={4}
                          lg={4}
                          className={
                            this.state.relation.errors
                              ? "onBTextFieldReq onBtextfiled"
                              : "onBtextfiled "
                          }
                        >
                          <div className={this.state.relation.errors ? "Mui-error" : ""}>
                            <MultipleSelect
                              fieldValue={this.state.relation}
                              fieldLabel={this.props.t("onBoarding.basicInfo.relationship")}
                              fieldName="relation"
                              fieldOptions={relationship.relationship}
                              handleMultipleSelect={this.handleMultipleSelect}
                              isMultiple={false}
                              error={!!this.state.relation.errors}
                              helperText={
                                this.state.relation.errors ? this.state.relation.errorText : null
                              }
                            />
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
              </Container>
              {/* Basic Information Page Ends */}

              {/* About Travel Page */}
              <Container className="onBMainTxtField">
                <Row>
                  <Col sm={12} xs={12} md={12} lg={12} className="onBAboutTravBanner" />
                </Row>
                <Row className="pb-3">
                  <Col sm={12} xs={12} md={12} lg={12}>
                    <Row>
                      <Col sm={12} xs={12} md={4} lg={4} className="onBtextfiled onBFNSelected">
                        <div
                          className={
                            this.state.dateAvailable.errors
                              ? "onBTextFieldReq onBAbtTravDrop"
                              : "onBAbtTravDrop"
                          }
                        >
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <Grid container justifyContent="space-around">
                              <KeyboardDatePicker
                                inputVariant="outlined"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                className="datePick"
                                minDate={new Date()}
                                allowKeyboardControl={false}
                                disablePast="false"
                                autoOk="false"
                                variant="inline"
                                format="MM/dd/yyyy"
                                margin="normal"
                                id="date-picker-BasicInfo"
                                views={["year", "month", "date"]}
                                label={this.props.t("onBoarding.basicInfo.dateAvailableLabel")}
                                value={
                                  isMobile && this.state.onPageDateLoad
                                    ? this.state.datePickerValue
                                    : this.state.datePickerValue
                                }
                                onChange={this.handleDateChange.bind(this)}
                                minDateMessage={this.props.t("onBoarding.basicInfo.minDateMessage")}
                                invalidDateMessage={this.props.t(
                                  "onBoarding.errorMessage.validDateError"
                                )}
                                {...(this.state.dateAvailable.errors
                                  ? {
                                      helperText: this.state.dateAvailable.errorText,
                                      error: !!this.state.dateAvailable.errors,
                                    }
                                  : {})}
                              />
                            </Grid>
                          </MuiPickersUtilsProvider>
                        </div>
                      </Col>
                      <Col sm={12} xs={12} md={1} lg={1} className="onBtextfiled onBFNSelected" />
                      <Col sm={12} xs={12} md={7} lg={7} className="onBtextfiled onBFNSelected">
                        <div className="onBShiftTypeTxt">
                          {this.props.t("onBoarding.basicInfo.shiftLabel")} (
                          {this.props.t("onBoarding.basicInfo.shiftText")})
                        </div>
                        <div className="pt-2 pb-2">
                          {this.renderShifts(this.state.shiftsJson, "shifts")}
                        </div>
                      </Col>
                    </Row>
                    <Row />
                    <Row />
                    {/* Shift Type row Ends */}
                  </Col>
                </Row>
              </Container>
              {/* Shift Type row Ends */}
              {/* Ethnicity and Gender section starts */}
              {ethnicityAndGender &&  (enableHideEthnicityAndGender ? this.state.IsGenderAndEthnicityVisible : ethnicityAndGender) && (
                <>
                  <SelfIdentification />
                  <Container className="onBMainTxtField">
                    <Row>
                      <Col
                        sm={12}
                        xs={12}
                        md={4}
                        lg={4}
                        className={
                          this.state.gender.errors
                            ? "onBTextFieldReq onBtextfiled"
                            : "onBtextfiled "
                        }
                      >
                        <div>
                          <MultipleSelect
                            fieldValue={this.state.gender}
                            fieldLabel={this.props.t("onBoarding.basicInfo.yourGenderLabel")}
                            fieldName="gender"
                            fieldOptions={gender.gender}
                            handleMultipleSelect={this.handleMultipleSelect}
                            isMultiple={false}
                            error={
                              this.state.gender.value === "" ? false : !!this.state.gender.errors
                            }
                            helperText={
                              this.state.gender.errors ? this.state.gender.errorText : null
                            }
                          />
                        </div>
                      </Col>
                      <Col
                        sm={12}
                        xs={12}
                        md={4}
                        lg={4}
                        className={
                          this.state.ethnicity.errors
                            ? "onBTextFieldReq onBtextfiled"
                            : "onBtextfiled "
                        }
                      >
                        <div>
                          <MultipleSelect
                            fieldValue={this.state.ethnicity}
                            fieldLabel={this.props.t("onBoarding.basicInfo.yourEthnicityLabel")}
                            fieldName="ethnicity"
                            fieldOptions={ethnicity.ethnicity}
                            handleMultipleSelect={this.handleMultipleSelect}
                            isMultiple={false}
                            error={
                              this.state.ethnicity.value === ""
                                ? false
                                : !!this.state.ethnicity.errors
                            }
                            helperText={
                              this.state.ethnicity.errors ? this.state.ethnicity.errorText : null
                            }
                          />
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </>
              )}
              <Container className="onBMainTxtField">
                <Row className="pb-3">
                  <Col sm={12} xs={12} md={12} lg={12}>
                    <hr className="mt-5" />
                    <Row>
                      <Col sm={12} xs={12} md={12} lg={12}>
                        <div className="onBNextBtn d-flex">
                          {allStepsCompleted ? (
                            <SecondaryButton
                              className={this.props.classes.nextBtnMarginleft}
                              id="basic_info_button"
                              size="medium"
                              onClick={this.NextBtnClickHandler.bind(this, 0, 1, "Next")}
                            >
                              {this.props.t("onBoarding.basicInfo.btnLabel")}
                            </SecondaryButton>
                          ) : onlyStepLeftToComplete ? (
                            <SecondaryButton
                              size="medium"
                              className={this.props.classes.nextBtnMarginleft}
                              onClick={this.NextBtnClickHandler.bind(this, 0, 1, "Done")}
                            >
                              {this.props.t("onBoarding.skillChecklist.completeAppBtnLbl")}
                            </SecondaryButton>
                          ) : (
                            <SecondaryButton
                              className={this.props.classes.nextBtnMarginleft}
                              id="basic_info_button"
                              size="medium"
                              onClick={this.NextBtnClickHandler.bind(this, 0, 1, "Next")}
                            >
                              {this.props.t("onBoarding.basicInfo.btnLabel")}
                            </SecondaryButton>
                          )}
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Container>
            </div>
            {/* Parent Column Ends  */}
          </Row>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  companyDetails: state.company.company,
  destinations: state.onBoarding.selectedDestinations,
  locationAutosuggest: state.globalSearch.location,
  company: state.company.company,
  onboardingBasicInfo: state.onBoarding.basicInfo,
  onboardingError: state.onBoarding.error,
  onBoardingCompletedSteps: state.onBoarding.completedSteps,
  userId: state.auth.userId,
  applicantId: state.candidate.applicantId,
  candidateSpecialties: state.auth.candidateSpecialties,
  candidateId: state.auth.candidateId,
  saveBasicInfoResponse: state.onBoarding.saveBasicInfoResponse,
  email: state.auth.email,
  firstName: state.auth.firstName,
  lastName: state.auth.lastName,
  phone: state.auth.phone,
  recruiterDetails: state.consultant.recruiterDetails,
  onboardingSpecialtiesAndEducation: state.onBoarding.specialtiesAndEducation,
  onBoardingReferences: state.onBoarding.references,
  onboardingWorkExperience: state.onBoarding.workExperience,
  atsBullhornCandidateId: state.candidate.atsBullhornCandidateId,
  bullhornIdFromBullhorn: state.candidate.bullhornIdFromBullhorn,
  myJobs: state.myJobs.filteredJobs,
});

const mapDispatchToProps = (dispatch) => ({
  getLocation: (term) => dispatch(fetchLocation(term)),
  getAllStates: (term) => dispatch(fetchAllStates()),
  updateCompleteFlag: (completedSteps) => dispatch(updateCompleteFlag(completedSteps)),
  updateActiveFlag: (activeSteps) => dispatch(updateActiveFlag(activeSteps)),
  saveTheUserProfile: (reqObject, references, workExperience) =>
    dispatch(saveUserProfile(reqObject, references, workExperience)),
});

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withRouter(OnBoardingBasicInfo)))
);
