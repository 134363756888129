import CircularProgress from "@material-ui/core/CircularProgress";
import { Theme, withStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { createElement, forwardRef } from "react";
import { ChipProps } from "src/components/common/Chips/index";

const Chip = forwardRef((props: ChipProps, ref) => {
  const {
    component,
    classes,
    className,
    clickable,
    disabled,
    endIcon,
    isLoading,
    startIcon,
    children,
    ...other
  } = props;

  const isDisabled = disabled || isLoading;

  const classNames = clsx(className, classes.root, {
    [classes.disabled]: isDisabled,
    [classes.clickable]: clickable,
  });

  const injectedProps = {
    className: classNames,
    ref,
    ...other,
  };

  if (clickable) {
    injectedProps.role = "button";
    injectedProps.tabIndex = 0;
  }

  return createElement(component, injectedProps, [
    startIcon && (
      <span className={classes.startIcon} key="startIcon">
        {startIcon}
      </span>
    ),
    <span className={classes.children} key="children">
      {isLoading ? <CircularProgress color="inherit" size={16} /> : children}
    </span>,
    endIcon && (
      <span className={classes.endIcon} key="endIcon">
        {endIcon}
      </span>
    ),
  ]);
});

Chip.defaultProps = {
  component: "div",
  clickable: false,
  disabled: false,
  isLoading: false,
};

const BaseChip = withStyles((theme) => {
  const themeWithProps = theme as Theme & {
    palette: { fontFamilyPrimary: string; mainBorder: string };
  };

  return {
    root: {
      height: 32,
      color: "#626C7D",
      display: "inline-flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: 20,
      fontFamily: themeWithProps.palette.fontFamilyPrimary,
      fontWeight: 700,
      lineHeight: "18px",
      backgroundColor: "#fff",
      border: `${themeWithProps.palette.mainBorder}!important`,
      fontSize: "14px",
      padding: "0px 5px",
      marginTop: "7px",
      marginRight: "10px",
    },
    children: {
      margin: "0 7px",
    },
    startIcon: {
      display: "inherit",
      marginLeft: 8,
      marginRight: -4,
    },
    endIcon: {
      display: "inherit",
      marginLeft: -4,
      marginRight: 8,
    },
    clickable: {
      cursor: "pointer",
    },
    disabled: {
      cursor: "default",
      pointerEvents: "none",
      opacity: 0.5,
    },
  };
})(Chip);

export { Chip };
export default BaseChip;
