import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useTranslation } from "react-i18next";
import styles from "src/components/common/OnBoarding/SelfIdentification/SelfIdentification.module.scss";
import OnBOnHoverIcon from "src/images/onboarding/HoverIcon.svg";
import { useState } from "react";
import EthnicityAndGenderLearnMoreOverlay from "src/components/common/OnBoarding/SelfIdentification/EthnicityAndGenderLearnMoreOverlay";
import { isMobile } from "react-device-detect";

function SelfIdentification() {
  const [openOverlay, setOpenOverlay] = useState(false);
  const { t } = useTranslation();
  return (
    <>
      <Container className="onBMainTxtField">
        <Row>
          <Col sm={12} xs={12} md={12} lg={12} className="onBSelfIdentificationBanner" />
        </Row>
        <div className={isMobile ? "" : styles.selfIdentificationContainer}>
          <Row>
            <Col sm={12} xs={12} md={12} lg={12}>
              <Row>
                <div className={styles.notMandatory}>
                  {t("onBoarding.basicInfo.selfIdentificationInfo")}
                </div>
              </Row>
              <Row>
                <div className={styles.selfReporting}>
                  <div
                    role="presentation"
                    className={styles.onHoverIcon}
                    onClick={() => setOpenOverlay(true)}
                  >
                    <img src={OnBOnHoverIcon} alt="OnBOnHoverIcon" />
                  </div>
                  <div
                    role="presentation"
                    className={styles.learnMoreText}
                    onClick={() => setOpenOverlay(true)}
                  >
                    {t("onBoarding.basicInfo.learnMoreText")}
                  </div>
                </div>
              </Row>
            </Col>
          </Row>
        </div>
      </Container>
      <EthnicityAndGenderLearnMoreOverlay
        openPopup={openOverlay}
        setOpenPopup={setOpenOverlay}
        t={t}
      />
    </>
  );
}

export default SelfIdentification;
