import { LOCATION_CHANGE } from "connected-react-router";
import {
  DASHBOARD,
  MY_PROFILE,
  BENEFITS,
  PAYROLL_TIMESHEET,
  BASIC_INFO_PAGE,
  EDU_SPEC_PAGE,
  WORK_EXP_PAGE,
  SKILL_AND_MORE_PAGE,
  SKILL_CHECKLIST_OVERVIEW_PAGE,
  SKILL_CHECKLIST_DETAILS_PAGE,
  CERTIFICATIONS_LICENSES,
} from "../../router/routes";
import {
  UPDATE_GLOBAL_NAVIGATION_KEY,
  UPDATE_LOCAL_NAVIGATION_KEY,
  UPDATE_SUB_NAVIGATION_KEY,
  IS_HAMBURGER_SUBMENU_OPEN,
  IS_HAMBURGER_MENU_OPEN,
  ON_BOARDING_NAVIGATION_CLICKED,
} from "./action";

const initialState = {
  localNavigationKey: localStorage.getItem("localNavigationKey")
    ? localStorage.getItem("localNavigationKey")
    : DASHBOARD,
  globalNavigationKey: localStorage.getItem("globalNavigationKey")
    ? localStorage.getItem("globalNavigationKey")
    : DASHBOARD,
  subNavigationKey: localStorage.getItem("subNavigationKey")
    ? localStorage.getItem("subNavigationKey")
    : "",
  isHamburgerSubMenuOpen: false,
  menuType: "",
  isHamburgerMenuOpen: false,
  onBoardingNavigation: -1,
  isNavigateToOnboarding: false,
};

const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOCATION_CHANGE:
      if (action.payload.location.pathname.includes("/auth/relay"))
        localStorage.setItem("historyPathName", action.payload.location.pathname);
      switch (action.payload.location.pathname) {
        case BASIC_INFO_PAGE:
          return { ...state, onBoardingNavigation: 0, isNavigateToOnboarding: true };
        case EDU_SPEC_PAGE:
          return { ...state, onBoardingNavigation: 1, isNavigateToOnboarding: true };
        case WORK_EXP_PAGE:
          return { ...state, onBoardingNavigation: 2, isNavigateToOnboarding: true };
        case SKILL_CHECKLIST_OVERVIEW_PAGE:
          return { ...state, onBoardingNavigation: 3, isNavigateToOnboarding: true };
        case SKILL_CHECKLIST_DETAILS_PAGE:
          return { ...state, onBoardingNavigation: 4, isNavigateToOnboarding: true };
        case SKILL_AND_MORE_PAGE:
          return { ...state, onBoardingNavigation: 5, isNavigateToOnboarding: true };
        case "/my-profile/skills-checklist":
          return {
            ...state,
            localNavigationKey: MY_PROFILE,
            globalNavigationKey: DASHBOARD,
            subNavigationKey: "SkillsChecklist",
            isNavigateToOnboarding: false,
          };
        case "/my-profile/more-about-me":
          return {
            ...state,
            localNavigationKey: MY_PROFILE,
            globalNavigationKey: DASHBOARD,
            subNavigationKey: "MoreAboutMe",
            isNavigateToOnboarding: false,
          };
        default:
          return { ...state, onBoardingNavigation: -1, isNavigateToOnboarding: false };
      }

    case UPDATE_LOCAL_NAVIGATION_KEY:
      if (action.payload === MY_PROFILE) {
        localStorage.setItem("localNavigationKey", action.payload);
        return {
          ...state,
          localNavigationKey: action.payload,
        };
      }
      if (action.payload === CERTIFICATIONS_LICENSES) {
        localStorage.setItem("localNavigationKey", action.payload);
        return { ...state, localNavigationKey: action.payload };
      }
      if (action.payload === BENEFITS) {
        localStorage.setItem("localNavigationKey", action.payload);
        localStorage.setItem("subNavigationKey", BENEFITS);
        return { ...state, localNavigationKey: action.payload, subNavigationKey: BENEFITS };
      }
      if (action.payload === PAYROLL_TIMESHEET) {
        localStorage.setItem("localNavigationKey", action.payload);
        localStorage.setItem("subNavigationKey", PAYROLL_TIMESHEET);
        return {
          ...state,
          localNavigationKey: action.payload,
          subNavigationKey: PAYROLL_TIMESHEET,
        };
      }
      localStorage.setItem("localNavigationKey", action.payload);
      return { ...state, localNavigationKey: action.payload };

    case UPDATE_GLOBAL_NAVIGATION_KEY:
      localStorage.setItem("globalNavigationKey", action.payload);
      return { ...state, globalNavigationKey: action.payload };
    case UPDATE_SUB_NAVIGATION_KEY:
      localStorage.setItem("subNavigationKey", action.payload);
      return { ...state, subNavigationKey: action.payload };
    case IS_HAMBURGER_SUBMENU_OPEN:
      return { ...state, isHamburgerSubMenuOpen: action.payload, menuType: action.payload.type };
    case IS_HAMBURGER_MENU_OPEN:
      return { ...state, isHamburgerMenuOpen: action.payload };
    case ON_BOARDING_NAVIGATION_CLICKED:
      return { ...state, onBoardingNavigation: action.payload };
    default:
      return state;
  }
};

export default rootReducer;
