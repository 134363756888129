import { useState, useEffect } from "react";
import { Image, Row, Col, Container } from "react-bootstrap";
import { withTranslation, useTranslation } from "react-i18next";
import { InputAdornment, IconButton, Button, TextField, CircularProgress } from "@material-ui/core";
import { passwordStrength } from "check-password-strength";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { connect } from "react-redux";
import PasswordRequirements from "src/components/common/auth/PasswordRequirements/PasswordRequirements";
import { withStyles } from "@material-ui/core/styles";
import { verifyToken, updateJobPreferencesFlag } from "src/redux/auth/action";
import CreatePasswordIcon from "src/components/common/auth/CreatePassword/CreatePasswordIcon.svg";
import "src/components/common/auth/CreatePassword/CreatePassword.scss";
import passwordValidationCheck from "src/components/common/auth/PasswordRequirements/PasswordValidationCheck";
import Logo from "../../Header/Logo";

interface PasswordChangeProps {
  company: {
    id: string;
  };
  verifyTokenAndUpdatePassword: (jwt: string, password: string, companyId: string) => void;
  computedMatch: {
    params: {
      jwt: string;
    };
  };
}

const styles = () => ({});
function CreatePasswordForm({
  company,
  verifyTokenAndUpdatePassword,
  computedMatch,
}: PasswordChangeProps) {
  const { t } = useTranslation();
  const initialState = {
    password: "",
    confirmPassword: "",
    jwtReceived: "",
    errorMessage: "",
    showConfirmPassword: false,
    showPassword: false,
    showHelper: false,
    valid: false,
    message: "",
  };
  const [
    {
      password,
      confirmPassword,
      showConfirmPassword,
      showPassword,
      showHelper,
      jwtReceived,
      errorMessage,
      valid,
      message,
    },
    setValues,
  ] = useState(initialState);

  const [testedResult, setTestedResult] = useState(passwordStrength(password));
  const [loader, setLoader] = useState(false);
  useEffect(() => {
    setValues((prevState) => ({ ...prevState, jwtReceived: computedMatch.params.jwt }));
  }, [jwtReceived]);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClickShowConfirmPassword = () => {
    setValues((prevState) => ({
      ...prevState,
      showConfirmPassword: !showConfirmPassword,
    }));
  };

  const handleClickShowPassword = () => {
    setValues((prevState) => ({ ...prevState, showPassword: !showPassword }));
  };

  const onFocusHandler = () => {
    setValues((prevState) => ({ ...prevState, showHelper: true }));
  };

  const handlePasswordChange = (prop) => (event) => {
    let result;
    const { value } = event.target;
    const inputValue = value.replace(/ /g, "");
    setValues((prevState) => ({ ...prevState, [prop]: inputValue }));
    const testResult = passwordStrength(inputValue);

    if (prop === "password") {
      setTestedResult(testResult);
      setValues((prevState) => ({ ...prevState, testedResult: testResult }));
      // eslint-disable-next-line
      if (inputValue === confirmPassword) {
        result = setValues((prevState) => ({ ...prevState, errorMessage: "", valid: true }));
      }
      if (inputValue !== "")
        result = setValues((prevState) => ({ ...prevState, message: "", valid: true }));
    }

    if (prop === "confirmPassword") {
      if (inputValue !== "") {
        result = setValues((prevState) => ({ ...prevState, errorMessage: "", valid: true }));
      }
      // eslint-disable-next-line
      if (password === inputValue) {
        result = setValues((prevState) => ({ ...prevState, errorMessage: "", valid: true }));
      } else {
        result = setValues((prevState) => ({
          ...prevState,
          errorMessage: t("createPassword.passwordMatchError"),
          valid: false,
        }));
      }
    }
    return result;
  };

  const saveChangePassword = () => {
    let result;
    let error = false;
    const validationCheck = passwordValidationCheck(testedResult, password);
    // eslint-disable-next-line
    if (confirmPassword === password) {
      result = setValues((prevState) => ({ ...prevState, errorMessage: "", valid: true }));
    } else {
      result = setValues((prevState) => ({
        ...prevState,
        errorMessage: t("createPassword.passwordMatchError"),
        valid: false,
      }));
      error = true;
    }
    if (confirmPassword === "") {
      result = setValues((prevState) => ({
        ...prevState,
        errorMessage: t("createPassword.enterpassword"),
        valid: false,
      }));
      error = true;
    }

    if (testedResult && testedResult.id < 1) {
      result = setValues((prevState) => ({
        ...prevState,
        valid: false,
        message: t("accountSettingsForm.passwordStrengthError"),
      }));
      error = true;
    }

    if (validationCheck) {
      result = setValues((prevState) => ({ ...prevState, valid: true, message: "" }));
    } else {
      result = setValues((prevState) => ({
        ...prevState,
        valid: false,
        message: t("accountSettingsForm.passwordCriteriaMessage"),
      }));
      error = true;
    }
    // eslint-disable-next-line
    if (password === "") {
      result = setValues((prevState) => ({
        ...prevState,
        message: t("createPassword.enterpassword"),
        valid: false,
      }));
      error = true;
    }

    if (!error && valid) {
      setLoader(true);
      verifyTokenAndUpdatePassword(jwtReceived, password, company.id);
      updateJobPreferencesFlag(true);
    }
    return result;
  };

  return (
    <div className="fullCoverBackground">
      <Container fluid>
        <Row>
          <div className="createPasswordMargin" />
        </Row>
        <Row>
          <Col xs={12} sm={12} md={4} lg={4} className="pb-3 pt-3" />
          <Col xs={12} sm={12} md={4} lg={4} className="pb-3 pt-3">
            <div className="createPasswordTextWrap">
              <Logo className="createPasswordLogo" />
              <div>
                <Image className="createPasswordIcon" src={CreatePasswordIcon} />
              </div>
              <div>
                <p className="createPasswordNote">{t("createPassword.verifyMsg")}</p>
                <p className="createPasswordHead">{t("createPassword.createPassword")}</p>
              </div>
              <div className="mt-3">
                <div className="formValidationWrap">
                  <TextField
                    className={message ? "errorMsgSignUp passwordInput" : "passwordInput"}
                    id="Password"
                    label="Password"
                    type={showPassword ? "text" : "password"}
                    autoComplete="password"
                    onFocus={onFocusHandler}
                    onChange={handlePasswordChange("password")}
                    variant="outlined"
                    value={password}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            className="passwordIcon"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {showPassword ? (
                              <Visibility className="passwordIcon" />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  {/* Password Requirements start */}
                  <div className="erroMsgsignIn textLeft">{message}</div>
                  {showHelper && (
                    <PasswordRequirements password={password} changePassword={false} />
                  )}
                  {/* Password Requirements end */}
                  <span>
                    <TextField
                      className={errorMessage ? "errorMsgSignUp passwordInput" : "passwordInput"}
                      id="Confirm Password"
                      type={showConfirmPassword ? "text" : "password"}
                      label="Confirm Password"
                      variant="outlined"
                      value={confirmPassword}
                      onChange={handlePasswordChange("confirmPassword")}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle visibility"
                              className="passwordIcon"
                              onClick={handleClickShowConfirmPassword}
                              onMouseDown={handleMouseDownPassword}
                            >
                              {showConfirmPassword ? (
                                <Visibility className="passwordIcon" />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <div className="erroMsgsignIn textLeft">{errorMessage}</div>
                  </span>
                </div>
              </div>
              <div className="mt-4 saveButtonWrap">
                <Button className="saveButton" disabled={loader} onClick={saveChangePassword}>
                  {t("createPassword.saveAndLogin")}
                  {loader ? <CircularProgress size={17} className="ml-2 loaderResendBtn" /> : null}
                </Button>
              </div>
            </div>
          </Col>
          <Col xs={12} sm={12} md={4} lg={4} className="pb-3 pt-3" />
        </Row>
      </Container>
    </div>
  );
}
const mapStateToProps = (state) => ({
  company: state.company.company,
});
const mapDispatchToProps = (dispatch) => ({
  verifyTokenAndUpdatePassword: (token, password, companyId) =>
    dispatch(verifyToken(token, password, companyId, true)),
  updateJobPreferencesFlag: (value) => dispatch(updateJobPreferencesFlag(value)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(withTranslation()(CreatePasswordForm)));
