import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "@material-ui/core/Button";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { makeStyles } from "@material-ui/core/styles";
import { InputAdornment, IconButton } from "@material-ui/core";
import CloseIcon from "@mui/icons-material/Close";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { passwordStrength } from "check-password-strength";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { useTranslation } from "react-i18next";
import { signIn } from "src/services/Auth";
import API from "src/services/Api";
import config from "src/config";
import PasswordRequirements from "src/components/common/auth/PasswordRequirements/PasswordRequirements";
import passwordValidationCheck from "src/components/common/auth/PasswordRequirements/PasswordValidationCheck";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles(() => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modalContent: {
    backgroundColor: "#FFF",
    width: "auto",
    height: "auto",
    textAlign: "center",
    borderRadius: "10px",
  },
}));

function ChangePasswordPopup(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const username = useSelector((state: any) => state.auth.email);
  const [open, setOpen] = React.useState(props.openModal);
  const [showStrengthMeter, setShowStrengthMeter] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
  const [disableButton, setDisableButton] = React.useState(true);
  const [showHelper, setShowHelper] = React.useState(false);
  const [loader, setLoader]=React.useState(false);
  const enableEmailVerification = config.ENABLE_EMAIL_VERIFICATION === "true";
  const [password, setPassword] = React.useState({
    value: "",
    errors: false,
    errorText: "",
  });
  const [newPassword, setNewPassword] = React.useState({
    value: "",
    errors: false,
    errorText: "",
    strength: 0,
  });
  const [confirmNewPassword, setConfirmNewPassword] = React.useState({
    value: "",
    errors: false,
    errorText: "",
  });
  const [testedResult, setTestedResult] = React.useState(passwordStrength(newPassword.value));
  const requiredFields = ["password", "newPassword", "confirmNewPassword"];

  useEffect(() => setOpen(true), []);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };
  const handleClose = () => {
    setOpen(false);
    props.handlePopup(false);
  };

  const onFocusHandler = () => {
    setShowHelper(true);
  };

  const handleChange = (fieldType, event) => {
    const fieldValue = enableEmailVerification
      ? event.target.value.replace(/ /g, "")
      : event.target.value;
    const validators = validateFields(fieldType, fieldValue);
    if (fieldType === "newPassword") {
      const testResult = passwordStrength(fieldValue);
      setTestedResult(testResult);
      setNewPassword({
        value: fieldValue,
        errors: validators.errors,
        errorText: validators.errorText,
        strength: testResult.id,
      });
      setDisableButton(validators.disableButton);
      if (enableEmailVerification && fieldValue === confirmNewPassword.value) {
        setConfirmNewPassword((prevState) => ({ ...prevState, errors: false, errorText: "" }));
      }
    } else if (fieldType === "confirmNewPassword") {
      setConfirmNewPassword({
        value: fieldValue,
        errors: validators.errors,
        errorText: validators.errorText,
      });
      setDisableButton(validators.disableButton);
    } else if (fieldType === "password") {
      setPassword({
        value: fieldValue,
        errors: validators.errors,
        errorText: validators.errorText,
      });
      setDisableButton(validators.disableButton);
    }
  };

  const validateFields = (type, value) => {
    const validators = {
      disableButton: false,
      errors: false,
      type,
      errorText: "",
    };
    requiredFields.forEach((element) => {
      if (type === element) {
        if (!value.trim()) {
          validators.disableButton = true;
          validators.errors = true;
        }
      } else if (!window[element].value) {
        validators.disableButton = true;
      }
    });
    if (type === "newPassword" || type === "confirmNewPassword") {
      if (value === "") {
        validators.errorText = "";
        validators.disableButton = true;
        validators.errors = true;
      } else if (!enableEmailVerification && !value.match(config.PASSWORD_REGEX)) {
        validators.errorText = t("accountSettingsForm.passwordCriteriaMessage");
        validators.disableButton = true;
        validators.errors = true;
      }
    } else if (
      !enableEmailVerification &&
      !newPassword.value.match(config.PASSWORD_REGEX as string) &&
      type === "newPassword"
    ) {
      validators.disableButton = true;
      validators.errorText = t("accountSettingsForm.passwordCriteriaMessage");
    }

    if (enableEmailVerification && type === "confirmNewPassword") {
      if (newPassword.value !== value) {
        validators.errorText = t("accountSettingsForm.cnfNewPassErrorText");
        validators.disableButton = true;
        validators.errors = true;
      }
    }
    return validators;
  };

  const checkCurrentPassword = async () => {
    let tokenObject;
    setLoader(true);
    try {
      tokenObject = await signIn(username.trim().toLowerCase(), password.value);
      if (tokenObject && tokenObject.idToken.jwtToken) {
        const reqObj = {
          token: tokenObject.idToken.jwtToken,
          password: newPassword.value,
        };
        updatePassword(reqObj);
      }
    } catch (error) {
      setLoader(false);
      setPassword({
        value: password.value,
        errors: true,
        errorText: t("accountSettingsForm.passwordErrorText"),
      });
    }
  };
  const saveChanges = async () => {
    const testResult = passwordStrength(newPassword.value);
    setTestedResult(testResult);
    const validationCheck = passwordValidationCheck(testedResult, newPassword.value);

    if (password.value !== "" && newPassword.value !== "" && confirmNewPassword.value !== "") {
      let errors = checkCnfPassword();
      if (newPassword.value === password.value) {
        setNewPassword({
          value: newPassword.value,
          errors: true,
          errorText: t("accountSettingsForm.passwordMatchError"),
          strength: testedResult.id,
        });
        errors = true;
      }
      if (
        (!enableEmailVerification && newPassword.value.startsWith(" ")) ||
        newPassword.value.endsWith(" ")
      ) {
        setNewPassword({
          value: newPassword.value,
          errors: true,
          errorText: t("accountSettingsForm.newPasswordErrorText"),
          strength: testedResult.id,
        });
        errors = true;
      }

      if (testResult && 1 > testResult.id) {
        setNewPassword({
          value: newPassword.value,
          errors: true,
          errorText: t("accountSettingsForm.passwordStrengthError"),
          strength: testResult.id,
        });
        errors = true;
      }

      if (enableEmailVerification && !validationCheck) {
        setNewPassword({
          value: newPassword.value,
          errors: true,
          errorText: t("accountSettingsForm.passwordCriteriaMessage"),
          strength: testResult.id,
        });
        errors = true;
      }

      if (!errors) {
        checkCurrentPassword();
      }
    }
  };

  /* For validating confirm password field after sign up button click */
  const checkCnfPassword = () => {
    if (newPassword.value !== confirmNewPassword.value) {
      setConfirmNewPassword({
        value: confirmNewPassword.value,
        errors: true,
        errorText: t("accountSettingsForm.cnfNewPassErrorText"),
      });
      return true;
    }
    return false;
  };

  const createPasswordLabel = (result) => {
    switch (result.id) {
      case 0:
        return "Too Weak";
      case 1:
        return "Weak";
      case 2:
        return "Medium";
      case 3:
        return "Strong";
      default:
        return "Too Weak";
    }
  };

  const updatePassword = (reqObj) => {
    API.post("/users/updatePassword", JSON.stringify(reqObj), {
      headers: {
        "content-type": "application/json",
      },
    })
      .then((response) => {
        if (response.data.code === "200" && response.data.isPasswordUpdateSuccessful) {
          setLoader(false);
          setOpen(false);
          props.handlePopup(false);
          props.handlePasswordSuccess(true);
          setTimeout(() => {
            props.handlePasswordSuccess(false);
          }, 5000);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        setLoader(false);
      });
  };
  return (
    <Modal
      className={classes.modal}
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      disableBackdropClick
    >
      <Fade in={open}>
        <div className={classes.modalContent}>
          <div className="changePswWrap">
            <Grid container direction="row" justifyContent="flex-end">
              <IconButton aria-label="close" onClick={() => handleClose()}>
                <CloseIcon className="changPswCloseBtn" />
              </IconButton>
            </Grid>
            <div
              className={
                enableEmailVerification
                  ? "accSettingSubHeadPadding accSettingSubHeadV2"
                  : "accSettingSubHeadV2"
              }
            >
              {t("headerV2.changePassword")}
            </div>
            <Row>
              <Col sm={12} md={12} lg={12} xs={12} className={password.errors ? "Mui-error" : ""}>
                <TextField
                  className="changePswInput"
                  label="Current Password"
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  type="password"
                  inputProps={{
                    autoComplete: "new-password",
                    form: {
                      autoComplete: "off",
                    },
                  }}
                  id="password"
                  onChange={(event) => handleChange("password", event)}
                  helperText={password.errors ? password.errorText : ""}
                />
              </Col>
            </Row>

            <Row className="pb-1 pt-3 accSettingNewPwdSection">
              <Col
                sm={12}
                md={12}
                lg={12}
                xs={12}
                className={newPassword.errors ? "Mui-error" : ""}
              >
                <TextField
                  className="changePswInput"
                  label="New Password"
                  type={showPassword ? "text" : "password"}
                  variant="outlined"
                  value={newPassword.value}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    autoComplete: "new-password",
                    form: {
                      autoComplete: "off",
                    },
                  }}
                  id="newPassword"
                  onFocus={
                    enableEmailVerification ? onFocusHandler : () => setShowStrengthMeter(true)
                  }
                  onChange={(event) => handleChange("newPassword", event)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                        >
                          {showPassword ? (
                            <Visibility className="passwordIcon" />
                          ) : (
                            <VisibilityOff className="passwordIcon" />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  helperText={newPassword.errors ? newPassword.errorText : ""}
                />
                {!enableEmailVerification && showStrengthMeter ? (
                  <div className=" mt-3">
                    <span>
                      <div
                        className="changePswStrengthV2"
                        dangerouslySetInnerHTML={{
                          __html: t("accountSettingsForm.emailPatternText"),
                        }}
                      />
                    </span>
                  </div>
                ) : (
                  ""
                )}
              </Col>
              {/* Password Requirements start */}
              {enableEmailVerification && showHelper && (
                <PasswordRequirements password={newPassword.value} changePassword={true} />
              )}
              {/* Password Requirements end */}

              {!enableEmailVerification && showStrengthMeter ? (
                <>
                  <Col sm={12} md={12} lg={12} xs={12} className="accSettingPassStrMeter">
                    <div className="password-strength-meter ">
                      <div className="password-strength-meter-label">
                        {newPassword && (
                          <>
                            <strong>{createPasswordLabel(testedResult)}</strong>
                          </>
                        )}
                      </div>
                      <progress
                        className={`password-strength-meter-progress strength-${createPasswordLabel(
                          testedResult
                        )}`}
                        value={testedResult.id}
                        max="3"
                      />
                    </div>
                    {/* Password Strength Meter End */}
                  </Col>
                </>
              ) : (
                ""
              )}
            </Row>
            <Row className="pb-3 pt-3">
              <Col
                sm={12}
                md={12}
                lg={12}
                xs={12}
                className={confirmNewPassword.errors ? "Mui-error" : ""}
              >
                <TextField
                  className="changePswInput"
                  label="Confirm New Password"
                  type={showConfirmPassword ? "text" : "password"}
                  variant="outlined"
                  value={confirmNewPassword.value}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    autoComplete: "new-password",
                    form: {
                      autoComplete: "off",
                    },
                  }}
                  id="confirmNewPassword"
                  onChange={(event) => handleChange("confirmNewPassword", event)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowConfirmPassword}
                        >
                          {showConfirmPassword ? (
                            <Visibility className="passwordIcon" />
                          ) : (
                            <VisibilityOff className="passwordIcon" />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  helperText={confirmNewPassword.errors ? confirmNewPassword.errorText : ""}
                />
              </Col>
              <Col sm={12} md={12} lg={12} xs={12} className="accSettingConfPwd" />
            </Row>
            <div
              className={
                enableEmailVerification ? "saveButtonV2Padding saveButtonV2" : "saveButtonV2"
              }
            >
              <Button
                variant="contained"
                className="applyButton saveChangebtnbottom"
                onClick={() => saveChanges()}
                disabled={disableButton || loader}
              >
                {t("accountSettingsForm.saveChanges")}
                {loader ? (
              <CircularProgress size={17} className="ml-2 loaderResendBtn" />
            ) : null}
              </Button>
            </div>
          </div>
        </div>
      </Fade>
    </Modal>
  );
}

export default ChangePasswordPopup;
