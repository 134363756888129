import Dynatrace from "src/services/Dynatrace";
import SnapEngage from "src/components/common/SnapEngage/SnapEngage";
import scriptInjector from "src/components/common/ThirdPartyScripts/ScriptInjector";
import {
  dynatraceData,
  recruiticsData,
  oneTrustScript
} from "src/components/common/ThirdPartyScripts/thirdPartyData";

const ThirdPartyScripts = (): JSX.Element => {
  scriptInjector(recruiticsData);
  if ( window === window.parent ) {
    oneTrustScript();
  }
  try {
    if (!Dynatrace().disable()) scriptInjector(dynatraceData);
  } catch (err) {
    console.log(`Error while script Injecting for Dynatrace: ${err}`); // eslint-disable-line no-console
  }

  return <SnapEngage />;
};

export default ThirdPartyScripts;
