import { useEffect } from "react";
import { Theme, makeStyles } from "@material-ui/core/styles";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { withTranslation, useTranslation } from "react-i18next";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import PhoneOutlinedIcon from "@mui/icons-material/PhoneOutlined";
import { isMobile } from "react-device-detect";
import MyTeamBanner from "src/imagesV2/myTeamBanner.jpg";
import MyTeamBannerMobile from "src/imagesV2/myTeamBanner-Mobile.png";
import MyTeamPayroll from "src/imagesV2/myTeamPayroll.png";
import MyTeamPayrollMobile from "src/imagesV2/myTeamPayroll-Mobile.png";
import MyTeamRecruiter from "src/imagesV2/myTeamRecruiter.png";
import MyTeamRecruiterMobile from "src/imagesV2/myTeamRecruiter-Mobile.png";
import MyTeamCustomerSup from "src/imagesV2/myTeamCustomerSup.png";
import MyTeamCustomerSupMobile from "src/imagesV2/myTeamCustomerSup-Mobile.png";

import {
  CUSTOMER_SERVICE_EMAIL,
  PAYROLL_SUPPORT_PHONE,
  CUSTOMER_SERVICE_PHONE,
  PAYROLL_SUPPORT_EMAIL,
} from "src/constants/contact_info";

import { connect } from "react-redux";
import Link from "@material-ui/core/Link";
import RecruiterInformation from "src/components/common/MainContent/Home/RecruiterInformation";
import { DashboardProps } from "src/components/common/MainContent/Home/index";

const useStyles = makeStyles((theme) => {
  const themeWithProps = theme as Theme & {
    palette: { colorPrimary: string; colorSecondary: string };
  };
  return {
    myTeamPageWrapper: {
      "background": "#F0F1F4",
      "& .onBSubHeading": {
        "fontSize": "1.5rem",
      },
    },
    myTeamBannerImage: {
      backgroundImage: `url(${MyTeamBanner})`,
      height: "280px",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      position: "relative",
    },
    myTeamBannerImageMob: {
      backgroundImage: `url(${MyTeamBannerMobile})`,
      height: "275px",
      backgroundSize: "100% !important",
    },
    myTeamCardInnerWrapper: {
      padding: "44px 0 90px",
      "@media (max-width: 768px)": {
        padding: "44px 0 40px",
      },
    },
    myTeamBannerText: {
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      margin: "0",
      color: "#fff",
      fontSize: "3rem",
    },
    myTeamHead: {
      fontSize: "1rem",
      color: "#434851",
      fontWeight: "bold",
      paddingBottom: "20px",
      marginBottom: "20px",
      borderBottom: "1px solid #DADBDF",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      width: "90%",
    },
    myTeamCard: {
      boxShadow: "0px 3px 20px #0000001A",
      border: "1px solid #DADBDF",
      borderRadius: "20px 20px 20px 0px",
      display: "flex",
      height: "100%",
      background: "#E0E8FF",
      "@media (max-width: 768px)": {
        height: "auto",
        flexDirection: "column",
        marginBottom: "30px",
      },
    },
    myTeamCardContent: {
      padding: "18px 18px 0 18px",
      width: "100%",
      background: "#fff",
      borderRadius: "0 20px 20px 0",
      "@media (max-width: 768px)": {
        borderRadius: "0 0 20px 0",
        padding: "18px",
      },
    },
    myTeamCardHead: {
      fontSize: "1rem",
      color: themeWithProps.palette.colorPrimary,
      marginBottom: "15px",
      fontWeight: 600,
    },
    myTeamCardImage: {
      borderRadius: "20px 0 0 0",
      height: "auto",
      minWidth: "120px",
      objectFit: "contain",
      alignSelf: "end",
    },
    myTeamSubHead: {
      color: "#000",
      fontSize: "0.75rem",
      marginBottom: "10px",
    },
    myTeamInfoLink: {
      display: "flex",
      "& a": {
        color: themeWithProps.palette.colorPrimary,
        fontWeight: "bold",
        marginBottom: "10px",
        fontSize: "0.875rem",
        wordBreak: "break-all",
        lineHeight: "inherit",
        display: "inline-block",
        "&:hover": {
          color: `${themeWithProps.palette.colorSecondary}!important`,
          textDecoration: "none",
          lineHeight: "inherit",
          "@media (max-width: 768px)": {
            color: `${themeWithProps.palette.colorPrimary}!important`,
          },
        },
      },
      "& svg": {
        fontSize: "1.2rem",
        marginRight: "12px",
      },
    },
    customerSupport: {
      "& $myTeamInfoLink": {
        flexDirection: "column",
      },
    },
    customerSupportCall: {
      paddingBottom: "20px",
      marginBottom: "20px",
      borderBottom: "1px solid #DADBDF",
    },
  };
});

interface MyTeamProps {
  recruiterDetails: DashboardProps["recruiterDetails"];
}

function MyTeam({ recruiterDetails }: MyTeamProps) {
  const classes = useStyles();
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={classes.myTeamPageWrapper}>
      <div className={isMobile ? classes.myTeamBannerImageMob : classes.myTeamBannerImage}>
        <h2 className={classes.myTeamBannerText}>{t("myTeam.myTeamBannerText")}</h2>
      </div>
      <Container>
        <Row className={classes.myTeamCardInnerWrapper}>
          <Col sm={12} md={4} lg={4} xs={12}>
            <div className={classes.myTeamCardHead}>{t("myTeam.payrollHead")}</div>
            <div className={classes.myTeamCard}>
              <div className={classes.myTeamCardImage}>
                {isMobile ? (
                  <img
                    src={MyTeamPayrollMobile}
                    alt="Payroll Card"
                    className={classes.myTeamCardImage}
                  />
                ) : (
                  <img src={MyTeamPayroll} alt="Payroll Card" className={classes.myTeamCardImage} />
                )}
              </div>
              <div className={classes.myTeamCardContent}>
                <h6 className={classes.myTeamSubHead}>{t("myTeam.payrollDepartmentLabel")}</h6>
                <div className={classes.myTeamHead}>{t("myTeam.payrollCustomerService")}</div>
                <div className={classes.myTeamSubHead}>{t("myTeam.myTeamContactLabel")}</div>
                <div className={classes.myTeamInfoLink}>
                  <MailOutlineIcon />
                  <Link href={`mailto:${PAYROLL_SUPPORT_EMAIL}`}>{PAYROLL_SUPPORT_EMAIL}</Link>
                </div>
                <div className={classes.myTeamInfoLink}>
                  <PhoneOutlinedIcon />
                  <Link href={`tel:${PAYROLL_SUPPORT_PHONE}`}>{PAYROLL_SUPPORT_PHONE}</Link>
                </div>
              </div>
            </div>
          </Col>
          <Col sm={12} md={4} lg={4} xs={12}>
            <div className={classes.myTeamCardHead}>{t("myTeam.recruiterHead")}</div>
            <div className={classes.myTeamCard}>
              <div className={classes.myTeamCardImage}>
                {isMobile ? (
                  <img
                    src={MyTeamRecruiterMobile}
                    alt="My Recruiter Card"
                    className={classes.myTeamCardImage}
                  />
                ) : (
                  <img
                    src={MyTeamRecruiter}
                    alt="My Recruiter Card"
                    className={classes.myTeamCardImage}
                  />
                )}
              </div>
              <div className={classes.myTeamCardContent}>
                <h6 className={classes.myTeamSubHead}>{t("myTeam.myTeamNameLabel")}</h6>
                <div className={classes.myTeamHead}>
                  <RecruiterInformation recruiterDetails={recruiterDetails} displayRecruiterName />
                </div>
                <div className={classes.myTeamSubHead}>{t("myTeam.myTeamContactLabel")}</div>
                <div className={classes.myTeamInfoLink}>
                  <MailOutlineIcon />
                  <RecruiterInformation recruiterDetails={recruiterDetails} displayRecruiterEmail />
                </div>
                <div className={classes.myTeamInfoLink}>
                  <PhoneOutlinedIcon />
                  <RecruiterInformation recruiterDetails={recruiterDetails} displayRecruiterPhone />
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <div className="onBSubHeading pt-0">{t("myTeam.myTeamSubHead")}</div>
        <Row className={classes.myTeamCardInnerWrapper}>
          <Col sm={12} md={4} lg={4} xs={12}>
            <div className={classes.myTeamCardHead}>{t("myTeam.customerSupportHead")}</div>
            <div className={`${classes.myTeamCard} ${classes.customerSupport}`}>
              <div className={classes.myTeamCardImage}>
                {isMobile ? (
                  <img
                    src={MyTeamCustomerSupMobile}
                    alt="Customer Support Card"
                    className={classes.myTeamCardImage}
                  />
                ) : (
                  <img
                    src={MyTeamCustomerSup}
                    alt="Customer Support Card"
                    className={classes.myTeamCardImage}
                  />
                )}
              </div>
              <div className={classes.myTeamCardContent}>
                <div className={`${classes.myTeamInfoLink} ${classes.customerSupportCall}`}>
                  <div className={classes.myTeamSubHead}>{t("myTeam.myTeamCallLabel")}</div>
                  <div>
                    <PhoneOutlinedIcon />
                    <Link href={`tel:${CUSTOMER_SERVICE_PHONE}`}>{CUSTOMER_SERVICE_PHONE}</Link>
                  </div>
                </div>
                <div className={classes.myTeamInfoLink}>
                  <div className={classes.myTeamSubHead}>{t("myTeam.myTeamContactLabel")}</div>
                  <div>
                    <MailOutlineIcon />
                    <Link href={`mailto:${CUSTOMER_SERVICE_EMAIL}`}>{CUSTOMER_SERVICE_EMAIL}</Link>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

const mapStateToProps = (state) => ({
  recruiterDetails: state.consultant.recruiterDetails,
});

export default connect(mapStateToProps)(withTranslation()(MyTeam));
