import axios from "axios";
import { applyAuthTokenInterceptor } from "axios-jwt";
import config from "../config";
import { refreshSession } from "./Auth";

const apiClient = axios.create({
  baseURL: `${config.API_BASE_HOST}/ui/v1`,
  headers: {
    common: {
      Accept: "application/json",
    },
  },
  withCredentials: true,
});

const requestRefresh = (refresh) =>
  new Promise((resolve, reject) => {
    refreshSession(refresh, localStorage.getItem("username"))
      .then((result) => {
        resolve(result.idToken.jwtToken);
      })
      .catch((error) => {
        throw new Error(`Error received while refresh token request: ${error.message}`);
      });
  });

applyAuthTokenInterceptor(apiClient, { requestRefresh });

export default apiClient;
