import React from "react";
import Image from "react-bootstrap/Image";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { SuccessButton } from "../Buttons";
import WorkExpAutomation from "../../../imagesV2/WorkExpAutomation-Icon.svg";
import { WORK_EXP_PAGE } from "../../../router/routes";

function VerifyWorkExperience({ props }) {
  const { t: translate } = useTranslation();
  const history = useHistory();
  const navigateToWorkExp = () => {
    history.push(WORK_EXP_PAGE, { isVerifyNowClicked: true });
  };
  return (
    <div className="workExpAutomation">
      <Image className="" src={WorkExpAutomation} />
      <span className="pl-3 pr-3">
        <b>{translate(`dashboard.banner.VerifyWorkExperienceTxt`)} </b>
        {translate(`dashboard.banner.VerifyWorkExperienceSubTxt`)}
      </span>
      <SuccessButton onClick={() => navigateToWorkExp()} size="small">
        {translate(`dashboard.banner.VerifyWorkExperienceButtonTxt`)}
      </SuccessButton>
    </div>
  );
}

export default VerifyWorkExperience;
