import { useEffect, useState } from "react";
import { useTranslation, withTranslation } from "react-i18next";
import { connect, useSelector } from "react-redux";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { Accordion, AccordionSummary, AccordionDetails, Chip } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { isMobile } from "react-device-detect";
import { getAccessToken } from "axios-jwt";
import moment from "moment";
import PrimaryButton from "src/components/common/Buttons/PrimaryButton";
import GenericErrorMessagePopup from "src/components/common/OnBoarding/GenericErrorMessagePopup";
import MissingApplicantId from "src/components/common/MainContent/Home/MissingApplicantIdError";
import {
  GET_MY_TASKS_STATUS,
  GET_COMPLETED_FORM_FILE,
  UpdateCandidateCredentials,
  getCredentialsFile as GET_CREDENTIALS_FILE,
  GET_CHECKLIST,
  GET_COMPLETED_SKILL_CHECKLIST_FILE,
} from "src/services/GraphQL/graph.query";
import IFrameLoader from "src/components/common/IFrameLoader";
import { graphqlRequest as request } from "src/services/GraphQL/GraphQL";
import "./MyTasks.scss";
import { getCredentialsTypeQuery } from "src/employers/reference/GraphQL/graph.query";
import { APIMethods } from "src/constants/constants";
import {
  fetchCandidateCredentials,
  isNavigateToSkillChecklistDetails,
  saveSkillChecklistGroupCompleteStatus,
  saveSkillChecklistGroupSkills,
  saveSkillChecklistSelected,
  updateSelectedSkillCompleteFlag,
} from "src/redux/onBoarding/action";
import { withRouter, useHistory } from "react-router-dom";
import { SKILL_CHECKLIST_DETAILS_PROFILE_PAGE } from "src/router/routes";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Modal from "@material-ui/core/Modal";
import Analytics from "src/services/Analytics";
import CertificationsLicensesForm from "../../CertificationsLicenses/CertificationAndLicensesForm";
import {
  CertificationsLicensesReduxState,
  CredentialTypeResponse,
  UpdateCredentialsResponse,
} from "../../OnBoarding/CertificationsLicenses";
import { CandidateCredentialData, ChecklistData } from "../../OnBoarding/CredentialItem";
import SnackbarSuccess from "../../SnackbarSuccess/SnackbarSuccess";
import SecondaryButton from "../../Buttons/SecondaryButton";

interface RootState {
  candidate: {
    applicantId: string;
    atsBullhornCandidateId: string;
    accessToken: string;
  };
  company: {
    company: {
      id: string;
      code: string;
    };
  };
  auth: {
    email: string;
    loginLoading: boolean;
  };
}

interface GetTasksResponse {
  getMyTaskStatusByApplicantId: {
    status: string;
    content: {
      meta: {
        placement: {
          facilityName: string | null;
          startDate: number | null;
        };
      };
      forms: RSection<Form>;
      checklists: RSection<Checklist>;
      uploads: RSection<Upload>;
    };
    errorMessage: string | null;
  };
}

interface RSection<T> {
  status: string;
  errorMessage: string | null;
  tasks: RTasks<T> | null;
}
interface RTasks<T> {
  completeCount: number;
  incompleteCount: number;
  complete: T[];
  incomplete: T[];
}

type Item = { name: string; isComplete: boolean };
type Credential = {
  dateExpiration: string;
  dateCompleted: string;
  licenseNumber: string;
  certificationFileAttachments: any[];
};

interface Skill {
  id: number;
  groupId: number;
  name: string;
  scale: number;
  subScaleName: any | null;
  subScale: number;
  active: string;
  value: any | null;
}

interface Group {
  id: number;
  checklistId: string;
  name: string;
  initial: any | null;
  scale: number;
  active: string;
  skills: Skill[];
}

interface SkillsChecklist {
  id: number;
  customerId: number;
  name: string;
  description: string;
  categoryId: number;
  legend: string;
  subscaleLegend: any | null;
  scaleStart: number;
  scaleEnd: number;
  required: string;
  useSubSkills: number;
  active: string;
  lastUpdated: string;
  expiration: string;
  created: string;
  draft: any | null;
  completedDate: any | null;
  complete: any | null;
  fileBytes: string;
  fileName: string;
  fileAltId: any | null;
  signature: string;
  groups: Group[];
}

type Checklist = { checklistId: string; atsChecklistId: string } & Item;
type Upload = { id: number; status: string } & Item & Credential;
type Form = { formId: string; workflowId: string; link: string } & Item;

interface TaskList {
  count: number;
  checklists: Checklist[] | null;
  forms: Form[] | null;
  uploads: Upload[] | null;
}

interface Placement {
  facilityName: string;
  startDate: string;
  isActive: boolean;
}
interface Tasks {
  complete: TaskList;
  incomplete: TaskList;
}

interface FormFileResponse {
  getCompletedFormFile: {
    developerMessage: string;
    userMessage: string;
    status: string;
    statusCode: number;
    file: FormFile;
  };
}

interface SkillCheckListResponse {
  getCompletedSkillchecklistFile: {
    developerMessage: string;
    userMessage: string;
    status: string;
    statusCode: number;
    file: FormFile;
  };
}

interface FormFile {
  name: string;
  contentType: string;
  fileContent: string;
}

interface MyTasksProps {
  fetchCredentials: Function;
  saveSkillChecklistSelectedMyTask: Function;
  saveSkillChecklistGroupCompleteStatusMyTask: Function;
  saveSkillChecklistGroupSkillsMyTask: Function;
  updateSelectedSkillCompleteFlagMyTask: Function;
  isNavigateToSkillChecklistDetailsMyTask: Function;
}

type queryOption = {
  credentialsCategory: Array<string>;
  size: string;
};

type credentialsTypes = {
  id: number;
  label: string;
  defaultExpirationReminderDays: string;
  expirationDateOptional: boolean;
  hasLicenseNumber: string;
  isTwoSided: boolean;
  requireCompleteDate: string;
};

interface CredentialFileResponse {
  getCredentialsFile: {
    developerMessage: string;
    userMessage: string;
    isSuccess: boolean;
    codeode: number;
    file: FormFile;
  };
}

type ProcessingChecklist = { id: string; name: string };
type LocationState = undefined | { completedChecklist: ProcessingChecklist };

type Section = "checklists" | "forms" | "uploads";
type ToggleState = "incomplete" | "complete";

export const MyTasks = (props: MyTasksProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const applicantId = useSelector((state: RootState) => state.candidate.applicantId);
  const bhId = useSelector((state: RootState) => state.candidate.atsBullhornCandidateId);
  const companyId = useSelector((state: RootState) => state.company.company.id);
  const userEmail = useSelector((state: RootState) => state.auth.email);

  const [isOpen, setIsOpen] = useState(false);
  const [credentialTypeList, setCredentialTypeList] = useState<credentialsTypes[]>([]);
  const loading = useSelector((state: RootState) => state.auth.loginLoading);
  const [authToken, setAuthToken] = useState(getAccessToken());
  const [toggleState, setToggleState] = useState<ToggleState>("incomplete");
  const [tasks, setTasks] = useState<Tasks>();
  const [placement, setPlacement] = useState<Placement | null>(null);
  const [error, setError] = useState<boolean>(false);
  const [toast, setToast] = useState<null | string>(null);
  const [iFrameForm, setIframeForm] = useState<Form | null>(null);
  const [candidateCredential, setCandidateCredential] = useState<null | CandidateCredentialData>(
    null
  );
  const [processingChecklists, setProcessingChecklists] = useState<ProcessingChecklist[]>([]);
  const [isConfirm, setIsConfirm] = useState(true);
  const [currentTaskIsDownloading, setCurrentTaskIsDownloading] = useState<
    Form | Checklist | Upload | null
  >(null);

  const {
    fetchCredentials,
    saveSkillChecklistSelectedMyTask,
    saveSkillChecklistGroupCompleteStatusMyTask,
    saveSkillChecklistGroupSkillsMyTask,
    updateSelectedSkillCompleteFlagMyTask,
    isNavigateToSkillChecklistDetailsMyTask,
  } = props;

  const isEmpty = !tasks || (tasks.complete.count === 0 && tasks.incomplete.count === 0);

  const obj = {
    email: useSelector((state: CertificationsLicensesReduxState) => state.auth.email),
    company: useSelector((state: CertificationsLicensesReduxState) => state.company.company),
    userId: useSelector((state: CertificationsLicensesReduxState) => state.auth.userId),
    firstName: useSelector((state: CertificationsLicensesReduxState) => state.auth.firstName),
    lastName: useSelector((state: CertificationsLicensesReduxState) => state.auth.lastName),
    phone: useSelector((state: CertificationsLicensesReduxState) => state.auth.phone),
    recruiterDetails: useSelector(
      (state: CertificationsLicensesReduxState) => state.consultant.recruiterDetails
    ),
    onboardingSpecialtiesAndEducation: useSelector(
      (state: CertificationsLicensesReduxState) => state.onBoarding.specialtiesAndEducation
    ),
    onboardingBasicInfo: useSelector(
      (state: CertificationsLicensesReduxState) => state.onBoarding.basicInfo
    ),
    companyId: useSelector((state: CertificationsLicensesReduxState) => state.company.company.id),
    atsBullhornCandidateId: useSelector(
      (state: CertificationsLicensesReduxState) => state.candidate.atsBullhornCandidateId
    ),
  };

  useEffect(() => {
    setAuthToken(getAccessToken());
  }, [obj]);

  const getCredentialsType = async () => {
    const query: queryOption = {
      credentialsCategory: ["Certifications", "Licensure"],
      size: "500",
    };
    return new Promise((_, reject) => {
      request(getCredentialsTypeQuery, query, authToken)
        .then((res: CredentialTypeResponse) => {
          const items: credentialsTypes[] = res?.getCredentialsType?.credentialsTypes;
          if (items?.length > 0) {
            setCredentialTypeList(items);
            setError(false);
          }
        })
        .catch((err) => {
          reject(err);
          setError(true);
          console.error(err);
        });
    });
  };

  useEffect(() => {
    try {
      if (credentialTypeList?.length === 0 && getAccessToken() && !error) {
        getCredentialsType();
      }
    } catch (err) {
      setCredentialTypeList([]);
    }
  }, [obj, credentialTypeList, getAccessToken()]);

  useEffect(() => {
    const getTasks = async () => {
      const vars = {
        applicantId,
        bhCandidateId: parseInt(bhId, 10),
        companyId: parseInt(companyId, 10),
      };
      try {
        const { getMyTaskStatusByApplicantId }: GetTasksResponse = await request(
          GET_MY_TASKS_STATUS,
          vars,
          authToken
        );

        if (getMyTaskStatusByApplicantId.status === "200") {
          const {
            content: { meta, forms, checklists, uploads },
          } = getMyTaskStatusByApplicantId;

          const complete = {
            count: [forms, checklists, uploads].reduce(
              (acc, taskObj) => acc + (taskObj.tasks?.completeCount ?? 0),
              0
            ),
            forms: forms.tasks?.complete ?? null,
            checklists: checklists.tasks?.complete ?? null,
            uploads: uploads.tasks?.complete ?? null,
          };

          const incomplete = {
            count: [forms, checklists, uploads].reduce(
              (acc, taskObj) => acc + (taskObj.tasks?.incompleteCount ?? 0),
              0
            ),
            forms: forms.tasks?.incomplete ?? null,
            checklists: checklists.tasks?.incomplete ?? null,
            uploads: uploads.tasks?.incomplete ?? null,
          };

          setTasks({ complete, incomplete });

          const { facilityName, startDate } = meta.placement;
          if (facilityName && startDate) {
            setPlacement({
              facilityName,
              isActive: startDate <= new Date().getTime(),
              startDate: moment(startDate).format("DD/MM/YYYY"),
            });
          }
        } else {
          setError(true);
          console.error(getMyTaskStatusByApplicantId.errorMessage);
        }
      } catch (err) {
        setError(true);
        console.error(err);
      }
    };

    if (applicantId && bhId && authToken && !error) {
      getTasks();
    }
  }, [applicantId, bhId, authToken, error, isOpen]);

  useEffect(() => {
    const storedChecklists = localStorage.getItem("processingChecklists");
    const checklistsToStore = storedChecklists ? JSON.parse(storedChecklists) : [];

    return () => {
      localStorage.setItem("processingChecklists", JSON.stringify(checklistsToStore));
    };
  }, []);

  useEffect(() => {
    const storedChecklists = localStorage.getItem("processingChecklists");
    const checklistsToStore = storedChecklists ? JSON.parse(storedChecklists) : [];
    setProcessingChecklists(checklistsToStore);
  }, []);

  useEffect(() => {
    if (tasks) {
      const locationState = history.location.state as LocationState;
      const justCompleted = locationState && locationState.completedChecklist;
      const checklistIncomplete =
        justCompleted &&
        tasks?.incomplete.checklists?.find((el) => el.checklistId === justCompleted.id.toString());

      const updated = processingChecklists.filter(
        (el) => !tasks.complete.checklists?.some((task) => task.atsChecklistId === el.id)
      );

      if (justCompleted && checklistIncomplete) {
        updated.push({
          id: checklistIncomplete.atsChecklistId,
          name: justCompleted.name,
        });

        window.scrollTo(0, 0);
        setToast(`${justCompleted.name} ${t("myTasks.successfullySaved")}`);
        setToggleState("complete");

        history.replace({ ...history.location, state: undefined });
      }
      setProcessingChecklists(updated);
      localStorage.setItem("processingChecklists", JSON.stringify(updated));
    }
  }, [tasks]);

  const handleConfirmation = () => {
    setIsConfirm(!isConfirm);
  };

  const handleClose = () => {
    setIsOpen(false);
    setError(false);
    setIsConfirm(true);
  };

  const segmentViewed = (section: string, name: string) => {
    let taskType: string;
    if (section === "checklists") {
      taskType = t("myTasks.checklist");
    } else if (section === "uploads") {
      taskType = t("myTasks.credential");
    } else {
      taskType = t("myTasks.form");
    }
    const segmentEventName = t("segment.taskViewed");
    const segmentEventProps = {
      taskName: name,
      taskType,
    };
    Analytics.segmentTrackEvent(segmentEventName, segmentEventProps);
  };

  const redirectToChecklistDetails = (skillChecklistSelected: SkillsChecklist) => {
    const completedGroups: string[] = [];
    const skillChecklistGroupSkills: string[] = [];
    isNavigateToSkillChecklistDetailsMyTask(true);
    if (skillChecklistSelected.groups) {
      let isSkillChecklistCompleted = true;

      skillChecklistSelected.groups.forEach((group) => {
        let isPresent: boolean = true;
        group.skills.forEach((skill): boolean => {
          if (skill.value !== null) {
            isPresent = isPresent && true;
            isSkillChecklistCompleted = isSkillChecklistCompleted && true;
          } else {
            isPresent = isPresent && false;
            isSkillChecklistCompleted = isSkillChecklistCompleted && false;
            return false;
          }
          return true;
        });
        if (isPresent && !completedGroups.includes(group.name)) {
          completedGroups.push(group.name);
        }
      });

      saveSkillChecklistGroupCompleteStatusMyTask(completedGroups);
      saveSkillChecklistSelectedMyTask(skillChecklistSelected);
      saveSkillChecklistGroupSkillsMyTask(skillChecklistGroupSkills);
      const isSkillComplete = skillChecklistSelected.complete
        ? skillChecklistSelected.complete
        : !!isSkillChecklistCompleted;
      updateSelectedSkillCompleteFlagMyTask(isSkillComplete);
    }
    history.push(SKILL_CHECKLIST_DETAILS_PROFILE_PAGE, { navigateToMyTask: true });
  };

  const handleButtonClick = (section: Section, recId: number | string) => {
    if (section === "forms") {
      const form = tasks!.incomplete.forms?.find((el) => el.formId === recId);
      if (form) {
        setIframeForm(form);
        segmentViewed(section, form.name);
      }
    }
    if (section === "uploads") {
      const candidateCredentialRecord = tasks?.incomplete?.uploads?.find(
        (item) => item.id === recId
      ) as unknown as CandidateCredentialData;
      if (candidateCredentialRecord) setCandidateCredential(candidateCredentialRecord);
      setIsOpen(true);
      segmentViewed(section, candidateCredentialRecord.name);
    }
    if (section === "checklists") {
      const candidateChecklistRecord = tasks?.incomplete?.checklists?.find(
        (item) => item.atsChecklistId === recId
      ) as unknown as ChecklistData;
      if (candidateChecklistRecord) {
        const queryVariables = {
          "email": userEmail,
          "companyId": companyId,
          "applicantId": applicantId,
          "atsChecklistId": recId,
        };

        request(GET_CHECKLIST, queryVariables, authToken)
          .then((candidateChecklistResponse) => {
            if (candidateChecklistResponse?.skillsChecklistDetailsFromAble?.statusCode === 200) {
              redirectToChecklistDetails(
                candidateChecklistResponse.skillsChecklistDetailsFromAble.content.skillsChecklist
              );
              segmentViewed(
                section,
                candidateChecklistResponse.skillsChecklistDetailsFromAble.content.skillsChecklist
                  .name
              );
            } else {
              setError(true);
              throw new Error(
                candidateChecklistResponse?.skillsChecklistDetailsFromAble?.errorMessage
              );
            }
          })
          .catch((err) => {
            setError(true);
            console.error(err);
          });
      }
    }
  };

  const downloadFile = (formFile: FormFile) => {
    const [fileName, contentType, fetchData] = [
      formFile?.name,
      formFile?.contentType,
      formFile?.fileContent,
    ];
    const downloadUrl = `data:${contentType};base64,${fetchData}`;
    const a = document.createElement("a");
    a.href = downloadUrl;
    a.download = fileName;
    a.click();
  };

  const downloadCompleteEFormRequest = async (formId: string, workflowId: string) => {
    try {
      const variables = {
        applicantId,
        bhCandidateId: bhId,
        companyId,
        email: userEmail,
        workflowId,
        signRequestId: formId,
      };
      const { getCompletedFormFile }: FormFileResponse = await request(
        GET_COMPLETED_FORM_FILE,
        variables,
        authToken
      );
      if (getCompletedFormFile.statusCode === 200) {
        downloadFile(getCompletedFormFile?.file);
      } else {
        setError(true);
        console.error(getCompletedFormFile?.developerMessage);
      }
    } catch (err) {
      setError(true);
      console.error(err);
    } finally {
      setCurrentTaskIsDownloading(null);
    }
  };

  const downloadCompleteCertificationRequest = async (credentialsFileId: number) => {
    try {
      const variables = {
        credentialsFileId,
      };

      const { getCredentialsFile }: CredentialFileResponse = await request(
        GET_CREDENTIALS_FILE,
        variables,
        authToken
      );

      if (getCredentialsFile.isSuccess) {
        downloadFile(getCredentialsFile?.file);
      } else {
        setError(true);
        console.error(getCredentialsFile?.developerMessage);
      }
    } catch (err) {
      setError(true);
      console.error(err);
    } finally {
      setCurrentTaskIsDownloading(null);
    }
  };

  const downloadCompleteCheckListRequest = async (applicantChecklistId: string) => {
    try {
      const variables = {
        applicantId,
        companyId,
        applicantChecklistId,
        email: userEmail,
      };

      const { getCompletedSkillchecklistFile }: SkillCheckListResponse = await request(
        GET_COMPLETED_SKILL_CHECKLIST_FILE,
        variables,
        authToken
      );
      if (getCompletedSkillchecklistFile.statusCode === 200) {
        downloadFile(getCompletedSkillchecklistFile?.file);
      } else {
        setError(true);
        console.error(getCompletedSkillchecklistFile?.developerMessage);
      }
    } catch (err) {
      setError(true);
      console.error(err);
    } finally {
      setCurrentTaskIsDownloading(null);
    }
  };

  const handleDownloadClick = async (task: Form | Checklist | Upload, taskType: Section) => {
    setCurrentTaskIsDownloading(task);
    if (taskType === "forms") {
      const formId = (task as Form)?.formId;
      const workflowId = (task as Form)?.workflowId;
      downloadCompleteEFormRequest(formId, workflowId);
    } else if (taskType === "uploads") {
      const certificationsAttachements = (task as Upload)?.certificationFileAttachments;
      const certificationsPromises = certificationsAttachements?.map((attachement: any) =>
        downloadCompleteCertificationRequest(attachement?.id)
      );
      await Promise.allSettled(certificationsPromises);
    } else if (taskType === "checklists") {
      const atsChecklistId = (task as Checklist)?.atsChecklistId;
      downloadCompleteCheckListRequest(atsChecklistId);
    }
  };

  const renderToggle = (state: ToggleState) => {
    const options = t("myTasks.statuses", { returnObjects: true });
    return (
      <div className="myTasksToggle">
        {Object.keys(options).map((option) => (
          <div
            className={`myTasksToggleOption ${
              state === option ? "myTasksToggleOption-active" : ""
            }`}
            onClick={() => setToggleState(option as ToggleState)}
            onKeyDown={() => setToggleState(option as ToggleState)}
            key={option}
            tabIndex={0}
            role="button"
          >
            {options[String(option)]} ({tasks![String(option)].count})
          </div>
        ))}
      </div>
    );
  };

  const shouldButtonBeDisabled = (task: Form | Checklist | Upload, taskType: Section) => {
    if (taskType === "uploads") return (task as Upload)?.certificationFileAttachments?.length === 0;
    return false;
  };

  const renderButtons = (
    isCompleted: boolean,
    section: Section,
    task: Form | Checklist | Upload
  ) => {
    const nameToId = {
      checklists: "atsChecklistId",
      forms: "formId",
      uploads: "id",
    };
    const taskId = nameToId[String(section)];
    const isDownloadingFiles = currentTaskIsDownloading && currentTaskIsDownloading === task;
    const completedButtonDisabled =
      shouldButtonBeDisabled(task, section) || Boolean(isDownloadingFiles);
    const endIcon = isDownloadingFiles ? (
      <CircularProgress size="1rem" className="loader" />
    ) : (
      <FileDownloadIcon />
    );
    const downloadBtn = (
      <PrimaryButton
        size="medium"
        disabled={completedButtonDisabled}
        onClick={() => handleDownloadClick(task, section)}
        endIcon={endIcon}
        component="div"
        className="myTasksBtn myTasksDownload"
      >
        {!isMobile && t("myTasks.downloadBtn")}
      </PrimaryButton>
    );
    const startBtnText = t(`myTasks.${section}Btn`);
    const startBtn = (
      <PrimaryButton
        size="medium"
        onClick={() => handleButtonClick(section, task[String(taskId)])}
        className="myTasksBtn"
        component="div"
      >
        {isMobile ? startBtnText.split(" ")[0] : startBtnText}
      </PrimaryButton>
    );
    return isCompleted ? downloadBtn : startBtn;
  };

  const renderTaskSection = (
    section: Section,
    sectionTasks: Checklist[] | Upload[] | Form[] | undefined
  ) => {
    const nameToId = {
      checklists: "atsChecklistId",
      forms: "formId",
      uploads: "id",
    };
    const taskId = nameToId[String(section)];
    const isExpanded = !sectionTasks || sectionTasks?.length > 0;
    return (
      <Accordion defaultExpanded={isExpanded} key={`${section}-${toggleState}`}>
        <AccordionSummary expandIcon={<ArrowDropDownIcon fontSize="large" />}>
          {t(`myTasks.${section}Title`)} {sectionTasks && `(${sectionTasks?.length})`}
        </AccordionSummary>
        <AccordionDetails>
          {sectionTasks ? (
            [...sectionTasks]?.map((task) => {
              let chipStatus = t(`myTasks.statuses.${task.isComplete ? "complete" : "incomplete"}`);
              if (
                section === "checklists" &&
                toggleState === "incomplete" &&
                processingChecklists.find((el) => el.name === task.name)
              ) {
                chipStatus = t("myTasks.processing");
              }

              return (
                <div className="myTasksTable-task" key={task[String(taskId)]}>
                  <div className="myTasksTable-status">
                    <Chip label={chipStatus} className={`myTasks${chipStatus}Chip`} />
                  </div>
                  <div className="myTasksTable-name">{task.name}</div>
                  <div className="myTasksTable-action">
                    {renderButtons(task.isComplete, section, task)}
                  </div>
                </div>
              );
            })
          ) : (
            <div className="myTasksTable-error">{t("myTasks.sectionError")}</div>
          )}
        </AccordionDetails>
      </Accordion>
    );
  };

  const renderTab = () => {
    if (isEmpty) {
      return (
        <div className="myTasks-empty">
          <span>{t("myTasks.emptyText")}</span>
        </div>
      );
    }

    const sections: Section[] = ["checklists", "uploads", "forms"];
    const taskList = tasks?.[String(toggleState)];
    return sections.map((section) => {
      const sectionContent = taskList?.[String(section)];
      return renderTaskSection(section, sectionContent);
    });
  };

  if (!loading && (!applicantId || !bhId)) {
    return <MissingApplicantId />;
  }

  const updateCredential = async (
    certificationContent,
    certificationFiles,
    _,
    candidateCertId,
    certificationFileIds
  ) => {
    try {
      const token = authToken;
      const vars = {
        bhCandidateId: Number(obj.atsBullhornCandidateId),
        companyId: Number(obj.companyId),
        certificationContent,
        certificationFiles,
        candidateCertId,
        certificationFileIds: certificationFileIds.filter((item) => item),
      };
      const {
        updateCandidateCredentials: { statusCode, userMessage },
      }: UpdateCredentialsResponse = await request(UpdateCandidateCredentials, vars, token);

      if (statusCode !== 200) {
        setError(true);
        console.error(userMessage);
      } else {
        const segmentEventName = t("segment.taskCompleted");
        const segmentEventProps = {
          taskName: vars.certificationContent.name,
          taskType: t("myTasks.credential"),
        };
        Analytics.segmentTrackEvent(segmentEventName, segmentEventProps);

        window.scrollTo(0, 0);
        setError(false);
        fetchCredentials(obj.atsBullhornCandidateId, obj.companyId, token);
        setToggleState("complete");
        setToast(
          `${certificationContent.name} ${t("certificationAndLicenses.successfullyUploaded")}`
        );
      }

      setIsOpen(false);
    } catch (err) {
      setError(true);
      console.error(err);
    }
  };

  if (iFrameForm) {
    return <IFrameLoader src={iFrameForm.link} />;
  }

  return (
    <>
      <div className="myTasksBanner">
        <div className="myTasksBanner-sum">
          <span>{!isEmpty ? tasks?.incomplete.count : 0} </span>
          {t(`myTasks.${!isEmpty && tasks?.incomplete.count === 1 ? "task" : "tasks"}`)}
        </div>
        {placement && (
          <div className="myTasksBanner-placement">
            <h4>{t(`myTasks.placement.${placement.isActive ? "active" : "upcoming"}`)}</h4>
            <p className="myTasksBanner-hospital">{placement.facilityName}</p>
            <p className="myTasksBanner-date">{placement.startDate}</p>
          </div>
        )}
      </div>
      <div className="myTasksWrapper">
        <div className="myTasksContent">
          <h4>{t("myTasks.title")}</h4>
          {!isEmpty && renderToggle(toggleState)}
          <div className={`myTasksTable${isEmpty ? "-empty" : ""}`}>
            <div className="myTasksTableHead">
              <p className="myTasksTable-status">{t("myTasks.statusLabel")}</p>
              <p className="myTasksTable-name">{t("myTasks.nameLabel")}</p>
              <p className="myTasksTable-action">{t("myTasks.actionLabel")}</p>
            </div>
            {renderTab()}
          </div>
        </div>
      </div>
      <Modal className="cerAndLicModal" open={isOpen} onClose={handleConfirmation}>
        <div className="certAndLic">
          <IconButton
            aria-label="Close"
            onClick={handleClose}
            style={{ position: "absolute", right: 8, top: 8, zIndex: 9 }}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
          <div hidden={!isConfirm}>
            <CertificationsLicensesForm
              credentialTypeList={credentialTypeList}
              saveNewCredentials={updateCredential}
              candidateCredential={candidateCredential}
              apiMethod={APIMethods.EDIT}
            />
          </div>
          <div className="confirmationBox" hidden={isConfirm}>
            <div className="confirmTitle">{t("certificationAndLicenses.unsavedChanges")}</div>
            <div className="confirmSubTitle">{t("certificationAndLicenses.pleaseExit")}</div>
            <SecondaryButton
              component="button"
              size="medium"
              className="stayOnPage"
              onClick={handleConfirmation}
            >
              <span>{t("certificationAndLicenses.stayOnPage")}</span>
            </SecondaryButton>
            <PrimaryButton
              component="button"
              size="medium"
              className="discard"
              onClick={handleClose}
            >
              <span>{t("certificationAndLicenses.exitAndDiscard")}</span>
            </PrimaryButton>
          </div>
        </div>
      </Modal>
      {toast && <SnackbarSuccess message={toast} topRight />}
      {error && (
        <GenericErrorMessagePopup
          informativeMessage={t("genericError.informativeMessage")}
          tryAgainMessage={t("genericError.tryAgainMessage")}
          callBack={() => {
            setError(false);
          }}
        />
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  fetchCredentials: (bhId: number, companyId: number, authToken: string) =>
    dispatch(fetchCandidateCredentials(bhId, companyId, authToken)),
  saveSkillChecklistSelectedMyTask: (skillChecklistSelected) =>
    dispatch(saveSkillChecklistSelected(skillChecklistSelected)),
  saveSkillChecklistGroupCompleteStatusMyTask: (completedGroups) =>
    dispatch(saveSkillChecklistGroupCompleteStatus(completedGroups)),
  saveSkillChecklistGroupSkillsMyTask: (skillsChecklistGroupSkillsFromStore) =>
    dispatch(saveSkillChecklistGroupSkills(skillsChecklistGroupSkillsFromStore)),
  updateSelectedSkillCompleteFlagMyTask: (completeFlag) =>
    dispatch(updateSelectedSkillCompleteFlag(completeFlag)),
  isNavigateToSkillChecklistDetailsMyTask: (value) =>
    dispatch(isNavigateToSkillChecklistDetails(value)),
});

export default connect(null, mapDispatchToProps)(withTranslation()(withRouter(MyTasks as any)));
