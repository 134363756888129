import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { withTranslation, TFunction } from "react-i18next";
import JobDescription from "src/components/common/MainContent/JobDescription/jobDescription";
import Housing from "src/components/common/MainContent/JobDescription/Housing/Housing";
import MoreInfo from "src/components/common/MainContent/JobDescription/MoreInfo/MoreInfo";
import StipendIcon from "src/imagesV2/StipendIcon.svg";
import styles from "src/components/common/MainContent/JobDescription/MainJobDetail.module.scss";
import JobsIcon from "src/imagesV2/JobsIcon.svg";
import PayBreakdownIcon from "src/imagesV2/payBreakdownIcon.svg";
import MoreInfoIcon from "src/imagesV2/MoreInfoIcon.svg";
import config from "src/config";
import PayBreakdown from "src/components/common/MainContent/JobDescription/PayBreakdown/PayBreakdown";
import { isMobile } from "react-device-detect";
import { makeStyles } from "@material-ui/core/styles";
import useWeatherData from "src/hooks/useWeatherData";
import Analytics from "src/services/Analytics";

interface TabPanelProps {
  t: TFunction<"translation", undefined>;
  children?: Element;
  value?: number;
  index?: number;
  jobDetails?: {
    description?: string;
    atsJobId: string;
    salaryMax: number;
    salaryExpenseReimbursementWeekly: number;
    hoursPerWeek: number;
    zip: string;
    shift: string;
    state: string;
    regHours: string;
    otRate: number;
    otHours: string;
  };
}

const useStyles = makeStyles(() => ({
  flexContainer: {
    "justify-content": "flex-start",
    gap: "30px",
  },
  "@media only screen and (min-width: 0px) and (max-width: 1200px)": {
    flexContainer: {
      gap: "0px",
    },
  },
}));

const TabPanel: Function = (props: TabPanelProps): JSX.Element => {
  const { children, value, index } = props;
  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
};

const imageIcons = () => {
  if (isMobile) {
    return {
      jobDescriptionIcon: <></>,
      housingIcon: <></>,
      payBreakdownIcon: <></>,
      moreInfoIcon: <></>,
    };
  } else {
    return {
      jobDescriptionIcon: <img className={styles.Icon} src={JobsIcon} alt="icon stipend" />,
      housingIcon: <img className={styles.Icon} src={StipendIcon} alt="icon stipend" />,
      payBreakdownIcon: (
        <img className={styles.Icon} src={PayBreakdownIcon} alt="icon pay brakdown tab" />
      ),
      moreInfoIcon: <img className={styles.Icon} src={MoreInfoIcon} alt="icon more info tab" />,
    };
  }
};

// CHECK FOR ROTATING JOBS
const isJobOverTime = (shift: string) => {
  return shift.includes("ROTATING");
};

const MainJobDetail = (props: TabPanelProps) => {
  const classes = useStyles();
  const { jobDetails, t } = props;
  const [value, setValue] = React.useState(0);
  const { jobDescriptionIcon, housingIcon, payBreakdownIcon, moreInfoIcon } = imageIcons();

  const enablePayBreakdown = config.ENABLE_PAY_BREAKDOWN === "true" || false;
  const enableMoreInfo = config.ENABLE_MORE_INFO_JOB_DETAILS === "true";
  const handleChange = (_event, newValue) => {
    setValue(newValue);

    if (enablePayBreakdown && jobDetails && !isJobOverTime(jobDetails.shift) && newValue === 2) {
      jobDetailsSectionTrackEvent("payBreakDown");
    }

    // if(enableMoreInfo)
    // {
    //   if((enablePayBreakdown && jobDetails &&
    //     !isJobOverTime(jobDetails.hoursPerWeek, jobDetails.shift, jobDetails.state)) && newValue === 3)
    //   {
    //     jobDetailsSectionTrackEvent("moreInfo")
    //   }
    //   else if(!enablePayBreakdown && newValue === 2) {
    //     jobDetailsSectionTrackEvent("moreInfo")
    //   }
    // }
  };

  const jobDetailsSectionTrackEvent = (section) => {
    let eventName = "";
    let eventProps = {};
    switch (section) {
      case "payBreakDown":
        eventName = t("segment.jobDetailsSectionViewed");
        eventProps = {
          section: t("jobs.payBreakdown.webTabTitle"),
          segment_source_name: `${process.env.NODE_ENV}-web-gateway`,
        };
        break;
      case "moreInfo":
        eventName = t("segment.jobDetailsSectionViewed");
        eventProps = {
          section: t("segment.moreInfoWeatherSection"),
          segment_source_name: `${process.env.NODE_ENV}-web-gateway`,
        };
        break;
    }
    Analytics.segmentTrackEvent(eventName, eventProps);
  };

  const payBreakDownTabTitle = isMobile
    ? t("jobs.payBreakdown.mobileTabTitle")
    : t("jobs.payBreakdown.webTabTitle");

  const trackDragEvent = () => {
    const eventName = t("segment.weatherTrackEvent");
    Analytics.segmentTrackEvent(eventName, null);
  };

  const weatherData = useWeatherData(jobDetails?.zip);

  return (
    <div>
      <AppBar position="static">
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
          classes={{ flexContainer: classes.flexContainer }}
        >
          <Tab icon={jobDescriptionIcon} label={t("jobs.jobDescription.jobDescText")} />

          <Tab icon={housingIcon} label={t("jobs.housing.housing")} />
          {enablePayBreakdown && jobDetails && !isJobOverTime(jobDetails.shift) && (
            <Tab icon={payBreakdownIcon} label={payBreakDownTabTitle} />
          )}
          {enableMoreInfo &&
            weatherData?.highTemp?.length > 0 &&
            weatherData?.lowTemp?.length > 0 &&
            weatherData?.precipitation?.length > 0 && (
              <Tab icon={moreInfoIcon} label={t("jobs.moreInfo.moreInfo")} />
            )}
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <JobDescription jobDescription={jobDetails} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Housing />
      </TabPanel>
      {enablePayBreakdown && (
        <TabPanel
          value={value}
          index={enablePayBreakdown && jobDetails && !isJobOverTime(jobDetails.shift) && 2}
        >
          <PayBreakdown jobPayDetails={jobDetails} />
        </TabPanel>
      )}
      {enableMoreInfo && (
        <TabPanel
          value={value}
          index={enablePayBreakdown && jobDetails && !isJobOverTime(jobDetails.shift) ? 3 : 2}
        >
          <MoreInfo weatherData={weatherData} trackDragEvent={trackDragEvent} />
        </TabPanel>
      )}
    </div>
  );
};

export default withTranslation()(MainJobDetail);
