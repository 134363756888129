/* eslint-disable */
const googleKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
const segmentKey = process.env.REACT_APP_SEGMENT_WRITE_KEY;
const dynatraceKey = process.env.REACT_APP_DYNATRACE_API_KEY;
const cookieConsentKey = process.env.REACT_APP_COOKIE_CONSENT_KEY;

export const googleMapsData = {
  domain: "https://maps.googleapis.com",
  url: `https://maps.googleapis.com/maps/api/js?key=${googleKey}&libraries=places`,
  id: "google-maps-script",
};

export const recruiticsData = {
  domain: "//jsv3.recruitics.com",
  url: "//jsv3.recruitics.com/partner/a56c4f4e-62a7-11e9-a8d1-93c29ba7a1ba.js",
  id: "recruitics-script",
};

export const dynatraceData = {
  domain: "https://js-cdn.dynatrace.com",
  url: `https://js-cdn.dynatrace.com/jstag/145e12d594f/bf15505ekx/${dynatraceKey}_complete.js`,
  id: "dynatrace-script",
};

export const hideSnapEngage = () => {
  let isHidden;
  const url = window.location.pathname;
  const pagestoHide = ["reference-survey", "employers", "jobs"];

  pagestoHide.map((page) => {
    if (url.includes(page)) {
      isHidden = true;
      return;
    }
  });

  return isHidden;
};

export const snapEngage = () => {
  (function () {
    const snapEngageScriptElem = document.createElement("script");
    snapEngageScriptElem.type = "text/javascript";
    snapEngageScriptElem.defer = true;
    snapEngageScriptElem.id = "snapengage-script";
    snapEngageScriptElem.src =
      "https://storage.googleapis.com/code.snapengage.com/js/708e06df-a31c-47e4-be0e-316fadaeb7f2.js";
    let done = false;
    snapEngageScriptElem.onload = snapEngageScriptElem.onreadystatechange = function () {
      if (
        !done &&
        (!this.readyState || this.readyState === "loaded" || this.readyState === "complete")
      ) {
        done = true;
        SnapEngage.startChatGo();
        if (hideSnapEngage()) {
          SnapEngage.hideButton();
        }
      }
    };
    const domFirstScriptElem = document.getElementsByTagName("script")[0];
    domFirstScriptElem.parentNode.insertBefore(snapEngageScriptElem, domFirstScriptElem);
  })();
};

export const segmentScript = () => {
  let segmentWriteKey = segmentKey;
  !(function () {
    const analytics = (window.analytics = window.analytics || []);
    if (!analytics.initialize)
      if (analytics.invoked)
        window.console && console.error && console.error("Segment snippet included twice.");
      else {
        analytics.invoked = !0;
        analytics.methods = [
          "trackSubmit",
          "trackClick",
          "trackLink",
          "trackForm",
          "pageview",
          "identify",
          "reset",
          "group",
          "track",
          "ready",
          "alias",
          "debug",
          "page",
          "once",
          "off",
          "on",
          "addSourceMiddleware",
          "addIntegrationMiddleware",
          "setAnonymousId",
          "addDestinationMiddleware",
        ];
        analytics.factory = function (event) {
          return function () {
            const segmentAnalyticsArray = Array.prototype.slice.call(arguments);
            segmentAnalyticsArray.unshift(event);
            analytics.push(segmentAnalyticsArray);
            return analytics;
          };
        };
        for (let e = 0; e < analytics.methods.length; e++) {
          const key = analytics.methods[e];
          analytics[key] = analytics.factory(key);
        }
        analytics.load = function (key, e) {
          const segmentScriptElem = document.createElement("script");
          segmentScriptElem.type = "text/javascript";
          segmentScriptElem.async = true;
          segmentScriptElem.id = "segment-script";
          segmentScriptElem.src =
            "https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js";
          const domFirstScriptElem = document.getElementsByTagName("script")[0];
          domFirstScriptElem.parentNode.insertBefore(segmentScriptElem, domFirstScriptElem);
          analytics._loadOptions = e;
        };
        analytics._writeKey = segmentWriteKey;
        analytics.SNIPPET_VERSION = "4.13.2";
        analytics.load(segmentWriteKey);
        analytics.page();
      }
  })();
};

export const oneTrustScript = () => {
  const addOneTrustJS = () => {
    const oneTrustScriptElem = document.createElement("script");
    oneTrustScriptElem.type = "text/javascript";
    oneTrustScriptElem.setAttribute("data-domain-script", cookieConsentKey);
    oneTrustScriptElem.id = "onetrust-script";
    oneTrustScriptElem.src = "https://cdn.cookielaw.org/scripttemplates/otSDKStub.js";
    const domFirstScriptElem = document.getElementsByTagName("script")[0];
    if (document.getElementById("onetrust-script")) {
      return;
    }
    domFirstScriptElem.parentNode.insertBefore(oneTrustScriptElem, domFirstScriptElem);
  };

  const addOptanonFnc = () => {
    const optanonScript = document.createElement("script");
    optanonScript.type = "text/javascript";
    optanonScript.defer = true;
    optanonScript.id = "onetrust-optanon";
    optanonScript.innerHTML = `function OptanonWrapper() {}`;
    const domFirstScriptElem = document.getElementById("onetrust-script");
    if (document.getElementById("onetrust-optanon")) {
      return;
    }
    domFirstScriptElem.parentNode.insertBefore(optanonScript, domFirstScriptElem.nextSibling);
  };

  const addScriptTag = new Promise((resolve, reject) => {
    addOneTrustJS();
    resolve();
  });

  addScriptTag.then(() => {
    addOptanonFnc();
  });
};