import {
  GET_CANDIDATE_LOCATION,
  FETCH_CANDIDATE_PROFILE_REQUEST,
  FETCH_CANDIDATE_PROFILE_SUCCESS,
  FETCH_CANDIDATE_PROFILE_FAILURE,
  FETCH_ACCESS_TOKEN_REQUEST,
  FETCH_ACCESS_TOKEN_SUCCESS,
  FETCH_ACCESS_TOKEN_FAILURE,
  CURRENT_ASSIGNMENT_DETAILS,
  SET_COMPLETE_STATUS_FROM_APPLY_COMPLETE_PAGE,
  SET_APPLICANT_ID,
  SET_ATS_BULLHORN_CANDIDATE_ID,
  SET_BULLHORN_ID_FROM_BULLHORN,
  IS_INIT_API_LOADING,
} from "./action";

const initialState = {
  loading: false,
  isProfileCompleted: null,
  applicantStatus: null,
  accessToken: null,
  applicantId: null,
  atsBullhornCandidateId: null,
  isInitApiLoading: false,
  accessTokenError: null,
  candidateProfileError: null,
  bullhornIdFromBullhorn: null,
  currentAssignment: {
    startDate: "February 14, 2020",
    endDate: "March 15, 2020",
    profession: "RN",
    specialty: "INTENSIVE CARE UNIT",
    weeklyPay: "$1,480 - $1,700",
    shift: "Day",
    weeklyHours: 80,
    daysLeft: 10,
    facilityName: "Nicklaus Children’s Hospital",
    city: "Miami",
    state: "Florida",
  },
};
const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CANDIDATE_LOCATION:
      return { ...state, currentPosition: action.payload };
    case FETCH_CANDIDATE_PROFILE_REQUEST:
      return {
        ...state,
        loading: true,
        applicantStatus: null,
        isProfileCompleted: null,
        candidateProfileError: null,
        applicantId: action.payload,
      };
    case FETCH_CANDIDATE_PROFILE_SUCCESS:
      return {
        ...state,
        loading: false,
        isProfileCompleted: action.isComplete,
        applicantStatus: action.profileStatus,
        candidateProfileError: null,
      };
    case FETCH_CANDIDATE_PROFILE_FAILURE:
      return {
        ...state,
        loading: false,
        applicantStatus: null,
        candidateProfileError: action.payload,
        isProfileCompleted: null,
      };
    case FETCH_ACCESS_TOKEN_REQUEST:
      return {
        ...state,
        loading: true,
        accessTokenError: null,
        accessToken: null,
        applicantId: action.payload,
      };
    case FETCH_ACCESS_TOKEN_SUCCESS:
      return { ...state, loading: false, accessToken: action.accessToken, accessTokenError: null };
    case FETCH_ACCESS_TOKEN_FAILURE:
      return { ...state, loading: false, accessTokenError: action.payload, accessToken: null };
    case CURRENT_ASSIGNMENT_DETAILS:
      return { ...state, currentAssignment: initialState.currentAssignment };
    case SET_COMPLETE_STATUS_FROM_APPLY_COMPLETE_PAGE:
      return { ...state, applicantStatus: "COMPLETE" };
    case SET_APPLICANT_ID:
      return { ...state, applicantId: action.payload };
    case SET_ATS_BULLHORN_CANDIDATE_ID:
      return { ...state, atsBullhornCandidateId: action.payload };
    case SET_BULLHORN_ID_FROM_BULLHORN:
      return { ...state, bullhornIdFromBullhorn: action.payload };
    case IS_INIT_API_LOADING:
      return { ...state, isInitApiLoading: action.payload };
    default:
      return state;
  }
};

export default rootReducer;
