export default function combinedReferences(referencesResponse) {
  let references = [];
  for (let i = 0; i < referencesResponse.getAllReferences.content.length; i++) {
    for (
      let j = 0;
      j <
      referencesResponse.getAllReferences.content[String(i)].candidateReference.references.length;
      j++
    ) {
      const ref =
        referencesResponse.getAllReferences.content[String(i)].candidateReference.references[
          String(j)
        ];
      ref.companyId = referencesResponse.getAllReferences.content[String(i)].companyId;
      references = references.concat(ref);
    }
  }
  return references;
}
