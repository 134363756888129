import {
  PASSWORD_UPDATE_REQ_OBJECT,
  PASSWORD_UPDATE_SUCCESS,
  PASSWORD_UPDATE_FAILURE,
} from "./action";

const initialState = {
  loading: false,
  passwordUpdateResponse: {},
  error: "",
  showSuccessMsg: false,
};
const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case PASSWORD_UPDATE_REQ_OBJECT:
      return { ...state, loading: true };

    case PASSWORD_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        passwordUpdateResponse: action.payload,
        error: "",
        showSuccessMsg: true,
      };

    case PASSWORD_UPDATE_FAILURE:
      return {
        ...state,
        loading: false,
        passwordUpdateResponse: action.payload,
        error: action.payload,
        showSuccessMsg: false,
      };

    default:
      return state;
  }
};

export default rootReducer;
