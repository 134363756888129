import React from "react";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Button from "@material-ui/core/Button";
import Avatar from "@material-ui/core/Avatar";
import TextField from "@material-ui/core/TextField";
import { passwordStrength } from "check-password-strength";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { InputAdornment, IconButton } from "@material-ui/core";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import CheckCircleOutlineOutlinedIcon from "@material-ui/icons/CheckCircleOutlineOutlined";
import DefaultUser from "../../../images/Default-user.png";
import FemaleUser from "../../../images/Female.png";
import MaleUser from "../../../images/Male.png";
import { signIn } from "../../../services/Auth";
import config from "../../../config";
import { passwordUpdate } from "../../../redux/accountSettings/action";

interface IState {
  showStrengthMeter: boolean;
  showPassword: boolean;
  showConfirmPassword: boolean;
  passwordStrength: number;
  showSuccessMsg: boolean;
  disableButton: boolean;
  requiredFields: string[];
  openPopover: boolean;
  password: {
    value: string;
    errors: boolean;
    errorText: null;
  };
  newPassword: {
    value: string;
    errors: boolean;
    errorText: null;
    strength: number;
  };
  confirmNewPassword: {
    value: string;
    errors: boolean;
    errorText: null;
  };
}

interface IProps {
  t: any;
  username: any;
  phone: any;
  passwordUpdate: (reqObj: any) => void;
  companyCode: {
    code: number;
  };
}

class AccountSettings extends React.Component<IProps, IState> {
  t = this.props.t;

  constructor(props: IProps) {
    super(props);
    this.state = {
      showStrengthMeter: false,
      showPassword: false,
      showConfirmPassword: false,
      passwordStrength: 0,
      showSuccessMsg: false,
      disableButton: true,
      openPopover: true,
      requiredFields: ["password", "newPassword", "confirmNewPassword"],
      password: {
        value: "",
        errors: false,
        errorText: null,
      },
      newPassword: {
        value: "",
        errors: false,
        errorText: null,
        strength: 0,
      },
      confirmNewPassword: {
        value: "",
        errors: false,
        errorText: null,
      },
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.props !== nextProps) {
      if (nextProps.showSuccessMsg) {
        this.resetFormFields();
        this.setState({ showSuccessMsg: true });
        setTimeout(() => {
          this.setState({ showSuccessMsg: false });
        }, 5000);
      }
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handleClickShowPassword() {
    this.setState({ showPassword: !this.state.showPassword });
  }

  handleClickShowConfirmPassword() {
    this.setState({ showConfirmPassword: !this.state.showConfirmPassword });
  }

  createPasswordLabel = (result) => {
    switch (result.id) {
      case 0:
        return "Too Weak";
      case 1:
        return "Weak";
      case 2:
        return "Medium";
      case 3:
        return "Strong";
      default:
        return "Too Weak";
    }
  };

  closePopover() {
    this.setState({ openPopover: false });
  }

  resetFormFields() {
    const password = document.getElementById("password") as HTMLInputElement;
    const newPassword = document.getElementById("newPassword") as HTMLInputElement;
    const confirmNewPassword = document.getElementById("confirmNewPassword") as HTMLInputElement;
    password.value = "";
    newPassword.value = "";
    confirmNewPassword.value = "";
    this.setState({ showStrengthMeter: false });
    this.setState({ disableButton: true });
  }

  /* Triggered when input value changes */
  handleChange(fieldType, e) {
    const fieldValue = e.target.value;
    this.setState((prevState) => {
      const validators = this.validateFields(fieldType, fieldValue);
      if (fieldType === "newPassword" || fieldType === "confirmNewPassword") {
        return {
          ...prevState,
          [fieldType]: {
            value: fieldValue,
            errors: true,
            errorText: validators.errorText,
          },
          disableButton: validators.disableButton,
        };
      }
      return {
        ...prevState,
        [fieldType]: {
          value: fieldValue,
          errors: validators.errors,
        },
        disableButton: validators.disableButton,
      };
    });
  }

  validateFields(type, value) {
    const validators = {
      disableButton: false,
      errors: false,
      type,
      errorText: null,
    };
    this.state.requiredFields.forEach((element) => {
      if (type === element) {
        if (!value.trim()) {
          validators.disableButton = true;
          validators.errors = true;
        }
      } else if (!this.state[element].value) {
        validators.disableButton = true;
      }
    });
    if (type === "newPassword" || type === "confirmNewPassword") {
      if (!value.match(config.PASSWORD_REGEX)) {
        validators.errorText = this.props.t("accountSettingsForm.passwordCriteriaMessage");
        validators.disableButton = true;
        validators.errors = true;
      }
    } else if (
      !this.state.newPassword.value.match(config.PASSWORD_REGEX as string) &&
      type === "newPassword"
    ) {
      validators.disableButton = true;
      validators.errorText = this.props.t("accountSettingsForm.passwordCriteriaMessage");
    }
    return validators;
  }

  checkCurrentPassword = async () => {
    let tokenObject;
    try {
      tokenObject = await signIn(
        this.props.username.trim().toLowerCase(),
        this.state.password.value
      );
      if (tokenObject) {
        if (tokenObject.idToken.jwtToken) {
          const reqObj = {
            token: tokenObject.idToken.jwtToken,
            password: this.state.newPassword.value,
          };
          this.props.passwordUpdate(reqObj);
        }
      }
    } catch (prevState) {
      this.setState((prevState) => ({
        password: {
          value: prevState.password.value,
          errors: true,
          errorText: this.props.t("accountSettingsForm.passwordErrorText"),
        },
      }));
    }
  };

  /* Triggered when sign up is clicked */
  saveChanges = async () => {
    if (
      this.state.password.value !== "" &&
      this.state.newPassword.value !== "" &&
      this.state.confirmNewPassword.value !== ""
    ) {
      let errors = this.checkCnfPassword();
      if (this.state.newPassword.value === this.state.password.value) {
        this.setState((prevState) => ({
          ...prevState,
          newPassword: {
            value: prevState.newPassword.value,
            errors: true,
            errorText: this.props.t("accountSettingsForm.passwordMatchError"),
            strength: 0,
          },
        }));
        errors = true;
      }
      if (
        this.state.newPassword.value.startsWith(" ") ||
        this.state.newPassword.value.endsWith(" ")
      ) {
        this.setState((prevState) => ({
          ...prevState,
          newPassword: {
            value: prevState.newPassword.value,
            errors: true,
            errorText: this.props.t("accountSettingsForm.newPasswordErrorText"),
            strength: 0,
          },
        }));
        errors = true;
      }
      const testedResult = passwordStrength(this.state.newPassword.value);
      if (testedResult && 1 > testedResult.id) {
        this.setState((prevState) => ({
          newPassword: {
            value: prevState.newPassword.value,
            errors: true,
            errorText: this.props.t("accountSettingsForm.passwordStrengthError"),
            strength: testedResult.id,
          },
        }));
        errors = true;
      }
      if (!errors) {
        this.checkCurrentPassword();
      }
    }
  };

  /* For validating confirm password field after sign up button click */
  checkCnfPassword() {
    if (this.state.newPassword.value !== this.state.confirmNewPassword.value) {
      this.setState((prevState) => ({
        confirmNewPassword: {
          value: prevState.confirmNewPassword.value,
          errors: true,
          errorText: this.props.t("accountSettingsForm.cnfNewPassErrorText"),
        },
      }));
      return true;
    }
    return false;
  }

  formatPhone() {
    let phoneNo = "";
    if (this.props.phone) {
      let { phone } = this.props;
      phone = phone.trim();
      if (phone.length === 10)
        phoneNo = `+${phone.substr(0, 1)}-${phone.substr(1, 2)}-${phone.substring(
          3,
          6
        )}-${phone.substring(6, phone.length)}`;
    }
    return phoneNo;
  }

  /* popover Open Close End */
  render() {
    let testedResult;
    const { newPassword } = this.state;
    if (newPassword) testedResult = passwordStrength(this.state.newPassword.value);
    return (
      <Container fluid className="accSettingcontainer">
        <Row className="accSettingBanner" />
        {/* Code For change Avatar Photo Popover   */}
        {this.state.openPopover ? (
          <div className="ChangeAvatarpoper">
            <Row>
              <Col sm={6} md={6} lg={6} xs={6}>
                <div
                  className="userPhoto1"
                  onClick={() =>
                    this.setState((prevState) => ({
                      ...prevState,
                      openPopover: false,
                    }))
                  }
                >
                  <Avatar alt="Edf Sharp" src={FemaleUser} className="medium" />
                </div>
              </Col>
              <Col sm={6} md={6} lg={6} xs={6}>
                <div
                  className="userPhoto1"
                  onClick={() =>
                    this.setState((prevState) => ({
                      ...prevState,
                      openPopover: false,
                    }))
                  }
                >
                  <Avatar alt="Ssdfd Sharp" src={MaleUser} className="medium" />
                </div>
              </Col>
            </Row>
          </div>
        ) : (
          ""
        )}
        {/* End Code For change Avatar Photo Popover */}
        <div>
          <Row>
            <Col sm={12} md={8} lg={8} xs={12} className="accSettingContent">
              <div className="clearfix" />
              <Row className="accSettingActionBtns">
                <Col sm={12} md={6} lg={6} xs={12}>
                  <div className="userPhoto pb-0">
                    <Avatar alt="Default" src={DefaultUser} className="medium" />
                  </div>
                </Col>
              </Row>

              <Row className="pb-1 pt-1">
                <Col sm={12} md={12} lg={12} xs={12} className="accSettingemailrow">
                  <h4 className="accSettingSubHead">{this.props.t("accountSettingsForm.email")}</h4>
                  <div className="mb-3 mt-3">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: this.props.t(
                          `accountSettingsForm.emailDisclaimer-${this.props.companyCode.code}`
                        ),
                      }}
                    />
                  </div>
                </Col>

                <Col sm={12} md={6} lg={6} xs={12}>
                  <TextField
                    label="Current Email Address"
                    type="text"
                    autoComplete="current-email"
                    disabled
                    value={this.props.username}
                  />
                </Col>
              </Row>
              {/* ------------------Email Section End-------------------------------- */}
              <Row className="pb-1 pt-1">
                <Col sm={12} md={12} lg={12} xs={12}>
                  <h4 className="accSettingSubHead">{this.props.t("accountSettingsForm.phone")}</h4>
                </Col>

                <Col sm={12} md={6} lg={6} xs={12}>
                  <TextField
                    label="Current Phone Number"
                    type="text"
                    value={this.formatPhone()}
                    inputProps={{ pattern: "[0-9]*" }}
                    autoComplete="Current Phone Number"
                    disabled
                  />
                </Col>
              </Row>
              {/* ------------------Phone Section End-------------------------------- */}

              <Row className="pb-1 pt-3">
                <Col sm={12} md={12} lg={12} xs={12}>
                  <h4 className="accSettingSubHead">
                    {this.props.t("accountSettingsForm.password")}
                  </h4>
                </Col>

                <Col sm={12} md={6} lg={6} xs={12}>
                  <TextField
                    label="Current Password"
                    type="password"
                    autoComplete="Current Password"
                    id="password"
                    onChange={this.handleChange.bind(this, "password")}
                    helperText={this.state.password.errors ? this.state.password.errorText : ""}
                  />
                </Col>
                <Col sm={12} md={6} lg={6} xs={12} />
              </Row>

              <Row className="pb-1 pt-3 accSettingNewPwdSection">
                <Col sm={12} md={6} lg={6} xs={12}>
                  <TextField
                    label="New Password"
                    type={this.state.showPassword ? "text" : "password"}
                    autoComplete="New password"
                    id="newPassword"
                    onChange={this.handleChange.bind(this, "newPassword")}
                    onFocus={() => this.setState({ showStrengthMeter: true })}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={this.handleClickShowPassword.bind(this)}
                          >
                            {this.state.showPassword ? (
                              <Visibility className="passwordIcon" />
                            ) : (
                              <VisibilityOff className="passwordIcon" />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    helperText={
                      this.state.newPassword.errors ? this.state.newPassword.errorText : ""
                    }
                  />

                  {this.state.showStrengthMeter ? (
                    <div className=" mt-3">
                      <span className="">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: this.props.t("accountSettingsForm.emailPatternText"),
                          }}
                        />
                      </span>
                    </div>
                  ) : (
                    ""
                  )}
                </Col>
                {/* Password Strength Meter start */}
                {this.state.showStrengthMeter ? (
                  <>
                    <Col sm={12} md={6} lg={6} xs={12} className="accSettingPassStrMeter">
                      <div className="password-strength-meter ">
                        <div className="password-strength-meter-label">
                          {newPassword && (
                            <>
                              <strong>{this.createPasswordLabel(testedResult)}</strong>
                            </>
                          )}
                        </div>
                        <progress
                          className={`password-strength-meter-progress strength-${this.createPasswordLabel(
                            testedResult
                          )}`}
                          value={testedResult.id}
                          max="3"
                        />
                      </div>
                      {/* Password Strength Meter End */}
                    </Col>
                  </>
                ) : (
                  ""
                )}
              </Row>

              <Row className="pb-3 pt-3">
                <Col sm={12} md={6} lg={6} xs={12}>
                  <TextField
                    label="Confirm New Password"
                    type={this.state.showConfirmPassword ? "text" : "password"}
                    autoComplete="Confirm New Password"
                    id="confirmNewPassword"
                    onChange={this.handleChange.bind(this, "confirmNewPassword")}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={this.handleClickShowConfirmPassword.bind(this)}
                          >
                            {this.state.showConfirmPassword ? (
                              <Visibility className="passwordIcon" />
                            ) : (
                              <VisibilityOff className="passwordIcon" />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    helperText={
                      this.state.confirmNewPassword.errors
                        ? this.state.confirmNewPassword.errorText
                        : ""
                    }
                  />
                </Col>
                <Col sm={12} md={12} lg={12} xs={12} className="accSettingConfPwd" />
              </Row>

              {/* ------------------Password Section End-------------------------------- */}
              <Row className="pt-3 pb-4">
                <Col sm={12} md={6} lg={6} xs={12}>
                  <div
                    className={this.state.showSuccessMsg ? "successMsgFadeIn" : "successMsgFadeOut"}
                  >
                    <CheckCircleOutlineOutlinedIcon className="successIcon" />
                    {this.props.t("accountSettingsForm.passwordChangeSuccessful")}
                  </div>
                </Col>
                <Col sm={12} md={6} lg={6} xs={12} className="savechangedivbottom pull-right">
                  <Button
                    variant="contained"
                    className="applyButton saveChangebtnbottom"
                    onClick={this.saveChanges.bind(this)}
                    disabled={this.state.disableButton}
                  >
                    {this.props.t("accountSettingsForm.saveChanges")}
                  </Button>
                </Col>
              </Row>
              {/* ------------------Save changes button  End-------------------------------- */}
            </Col>
            <Col sm={12} md={2} lg={2} xs={12} />
          </Row>
        </div>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  username: state.auth.email,
  phone: state.auth.phone,
  passwordUpdateResponse: state.accountSettings.passwordUpdateResponse,
  showSuccessMsg: state.accountSettings.showSuccessMsg,
  companyCode: state.company.company,
});

const mapDispatchToProps = (dispatch) => ({
  passwordUpdate: (reqObj) => dispatch(passwordUpdate(reqObj)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(AccountSettings));
