import Container from "@mui/material/Container";
import { useEffect, useState } from "react";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import jsonwebtoken from "jsonwebtoken";
import { getReference } from "src/services/GraphQL/graph.query";
import { graphqlRequest as request } from "src/services/GraphQL/GraphQL";
import config from "src/config";
import validationsService from "src/services/Validations";
import ReferenceSurveyForm from "src/components/common/MainContent/ReferenceSurvey/ReferenceSurveyForm";
import ReferenceHeader from "src/components/common/MainContent/ReferenceSurvey/ReferenceHeader";
import ReferenceExpiredLink from "src/components/common/MainContent/ReferenceSurvey/ReferenceExpiredLink";
import { getAccessToken } from "axios-jwt";

const ReferenceSurvey = () => {
  const { search } = useLocation();
  const queryStringValues = search ? queryString.parse(search) : {};
  const { id, email, companyid } = queryStringValues;
  const [reference, setReference] = useState(null);
  const [referenceExpired, setReferenceExpired] = useState(null);
  const referencesIsDeleted = config.REFERENCES_IS_DELETED === "true";
  const accessToken = getAccessToken();
  useEffect(() => {
    validationsService.init();
    validationsService.setCustomReferencesSurveyValidations();
  }, []);
  useEffect(() => {
    const { id, email, companyid, token } = queryStringValues;
    const expiryToken = token;
    if (id && email && companyid) {
      const query = {};
      query.email = email;
      query.companyId = parseInt(companyid);
      query.getReferenceId = id;
      query.isDeleted = referencesIsDeleted;
      request(getReference, query, accessToken).then(
        (res) => {
          const references = res.getReference.content
            ? res.getReference.content.candidateReference
            : null;
          if (references) {
            const reference = references.references[0];
            if (reference) {
              const { token } = reference;
              const decoded = jsonwebtoken.verify(token, config.JWT_REDIRECT_SECRET_KEY);
              const thresholdDateForExpiry = new Date();
              thresholdDateForExpiry.setDate(thresholdDateForExpiry.getDate() - 3);
              if (
                reference.status.toLowerCase() === "expired" ||
                reference.status.toLowerCase() === "complete" ||
                reference.status.toLowerCase() === "declined" ||
                ((reference.status.toLowerCase() === "pending" ||
                  reference.status.toLowerCase() === "inprogress") &&
                  decoded.dateNotified &&
                  new Date(decoded.dateNotified).getTime() < thresholdDateForExpiry.getTime()) ||
                expiryToken !== reference.token
              ) {
                setReferenceExpired(true);
              } else {
                setReferenceExpired(false);
              }
              setReference(references);
            } else {
              setReferenceExpired(true);
            }
          } else {
            setReferenceExpired(true);
          }
        },
        (err) => {
          console.error(err);
        }
      );
    }
  }, []);
  return (
    <>
      <ReferenceHeader />
      {referenceExpired === null ? null : referenceExpired === true ? (
        <ReferenceExpiredLink />
      ) : (
        <Container>
          <ReferenceSurveyForm
            referenceInfo={reference}
            userEmail={email}
            userCompany={companyid}
            referenceId={id}
          />
        </Container>
      )}
    </>
  );
};

export default ReferenceSurvey;
