import { makeStyles, Theme } from "@material-ui/core/styles";
import clsx from "clsx";
import PropTypes from "prop-types";
import { DefaultChip } from "../Chips";

const useStyles = makeStyles((theme) => {
  const themeWithProps = theme as Theme & { palette: { colorPrimary: string } };
  return {
    chip: {
      marginRight: themeWithProps.spacing(1),
      marginBottom: themeWithProps.spacing(1),
      border: "1px solid",
    },
    noMarginBottom: {
      marginBottom: 0,
    },
    active: {
      background: themeWithProps.palette.colorPrimary,
      color: "#ffffff",
    },
  };
});

type items = {
  label: string;
  value: string;
  startIcon: React.ReactElement;
  activeStartIcon: React.ReactElement;
  isActive: boolean;
};

export interface HorizontalChipsProps {
  noMarginBottom: boolean;
  items: items[];
  onClick: (jobDetailFilter: any) => void;
  props?: any;
}

function HorizontalChips({
  noMarginBottom,
  items,
  onClick,
  props,
}: HorizontalChipsProps) {
  const classes = useStyles();
  const manipulatedItems = items.map((item) => {
    const { value, label, startIcon, activeStartIcon, isActive } = item;
    const chipClassNames = clsx(classes.chip, {
      [classes.noMarginBottom]: noMarginBottom,
      [classes.active]: isActive,
    });

    return (
      <DefaultChip
        clickable
        startIcon={isActive ? activeStartIcon : startIcon}
        className={chipClassNames}
        key={value}
        onClick={() => onClick(item)}
        {...props}
      >
        {label}
      </DefaultChip>
    );
  });

  return <>{manipulatedItems}</>
}

HorizontalChips.propTypes = {
  noMarginBottom: PropTypes.bool,
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  onClick: PropTypes.func,
};

HorizontalChips.defaultProps = {
  noMarginBottom: false,
};

export default HorizontalChips;