import { KeyboardEvent, MouseEvent } from "react";
import { TFunction, withTranslation } from "react-i18next";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { triggerAnalytics } from "src/components/common/Footer/utils/TriggerAnalytics";
import EarnBigIcon from "src/imagesV2/earnbigicon.svg";
import ReferFriendBackground from "src/imagesV2/referafriendbackground.png";
import { referFriendNavigation } from "src/redux/company/action";
import styles from "./ReferFriend.module.scss";

const ReferFriend = ({ t }: { t: TFunction<"translation", undefined> }) => {
  const redirectToReferFriend = (
    label: string,
    event: KeyboardEvent<HTMLDivElement> | MouseEvent<HTMLDivElement, globalThis.MouseEvent>
  ) => {
    triggerAnalytics(label, event);
    window.open(referFriendNavigation());
  };

  return (
    <div className={styles.ReferFriend}>
      <div className={styles.ReferFriendText}>{t("footer.referFriendHeading")}</div>
      <div
        className={styles.ImageContainer}
        role="button"
        tabIndex={0}
        onKeyPress={(event) => redirectToReferFriend(t("segment.referNow"), event)}
        onClick={(event) => redirectToReferFriend(t("segment.referNow"), event)}
      >
        <img
          className={styles.ReferFriendBackground}
          alt="Refer Friend Background"
          src={ReferFriendBackground}
        />
        <div className={styles.EarnBig}>
          <img className={styles.EarnBigIcon} alt="Earn Big Icon" src={EarnBigIcon} />
          <div>
            <div className={styles.EarnBigText}>{t("footer.referFriendText")}</div>
            <button type="button" className={styles.EarnBigButton}>
              {t("footer.referFriendBtnLbl")}&nbsp;
              <ArrowForwardIcon fontSize="small" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(ReferFriend);
