import styles from "./ProgressBar.module.scss";

interface IncompleteProfileProps {
  progress?: number;
}

const ProgressBar = ({
  progress
}: IncompleteProfileProps) => {
  const circumference = 2 * Math.PI * 70;
  const offset = circumference - ((progress ?? 0) / 100) * circumference;

  return (
    <div className={styles.container}>
      <svg className={styles.circle} viewBox="0 0 150 150">
        <circle className={styles.background} cx="75" cy="75" r="70" />
        <circle
          className={styles.bar}
          cx="75"
          cy="75"
          r="70"
          strokeDasharray={circumference}
          strokeDashoffset={offset}
        />
      </svg>
      <div className={styles.text}>
        {progress != null && (
          <>
            {progress === 0 ? "00" : progress}%
          </>
        )}

        {progress == null && "--"}
      </div>
    </div>
  );
}

ProgressBar.defaultProps = {
  progress: undefined
}

export default ProgressBar;
