import { useTranslation, withTranslation } from "react-i18next";
import { payStubDetailsTypes } from "src/components/common/MainContent/Payroll/Paystubs/Tables";
import styles from "./SickTable.module.scss";

interface SickTableProps {
  payStubDetails: payStubDetailsTypes;
}

const SickTable = ({ payStubDetails }: SickTableProps) => {
  const { t } = useTranslation();

  return (
    <>
      <div className={styles.paystubsTable}>
        <h3 className={styles.tableHeading}>{t("payStubs.sickTime")}</h3>
        <table>
          <thead>
            <tr>
              <th>{t("payStubs.description")}</th>
              <th>{t("payStubs.balance")}</th>
              <th>{t("payStubs.ytd")}</th>
            </tr>
          </thead>
          <tbody>
            {payStubDetails?.pto?.map((pto) => (
              <tr key={pto.description}>
                <td>
                  {pto.description ? pto.description.match(/\((.*)\)/)?.pop() : pto.description}
                </td>
                <td>
                  {pto.avail} {t("payStubs.hours")}
                </td>
                <td>{pto.ytdUsed}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};
export default withTranslation()(SickTable);
