import { KeyboardEvent, MouseEvent } from "react";
import { TFunction, withTranslation } from "react-i18next";
import Image from "react-bootstrap/Image";
import { triggerAnalytics } from "src/components/common/Footer/utils/TriggerAnalytics";
import {
  FOLLOW_US_FACEBOOK_URL_CCA,
  FOLLOW_US_FACEBOOK_URL_CCN,
  FOLLOW_US_INSTAGRAM_URL_CCA,
  FOLLOW_US_INSTAGRAM_URL_CCN,
  FOLLOW_US_LINKEDIN_URL_CCA,
  FOLLOW_US_LINKEDIN_URL_CCN,
  FOLLOW_US_TIKTOK_URL_CCA,
  FOLLOW_US_TIKTOK_URL_CCN,
  FOLLOW_US_YOUTUBE_URL_CCA,
  FOLLOW_US_YOUTUBE_URL_CCN,
} from "src/constants/external_links";
import styles from "./SocialLinks.module.scss";

interface SocialLinksProps {
  t: TFunction<"translation", undefined>;
  company: {
    id: string;
  };
}

const SocialLinks = ({ t, company }: SocialLinksProps) => {
  const socialMediaIcons = [
    {
      name: "instagram",
      src: "/img/instagramIcon.svg",
    },
    {
      name: "facebook",
      src: "/img/facebookIcon.svg",
    },
    {
      name: "linkedin",
      src: "/img/linkedInIcon.svg",
    },
    {
      name: "tiktok",
      src: "/img/tikTokIcon.svg",
    },
    {
      name: "youtube",
      src: "/img/youtubeIcon.svg",
    },
  ];

  const triggerAnalyticsAndNavigation = (
    event: KeyboardEvent<HTMLButtonElement> | MouseEvent<HTMLButtonElement, globalThis.MouseEvent>,
    companyId: number,
    label: string,
    name: string
  ) => {
    triggerAnalytics(label, event);

    const getURL = {
      instagram: {
        url: {
          1: FOLLOW_US_INSTAGRAM_URL_CCN,
          2: FOLLOW_US_INSTAGRAM_URL_CCA,
        },
      },
      facebook: {
        url: {
          1: FOLLOW_US_FACEBOOK_URL_CCN,
          2: FOLLOW_US_FACEBOOK_URL_CCA,
        },
      },
      linkedin: {
        url: {
          1: FOLLOW_US_LINKEDIN_URL_CCN,
          2: FOLLOW_US_LINKEDIN_URL_CCA,
        },
      },
      tiktok: {
        url: {
          1: FOLLOW_US_TIKTOK_URL_CCN,
          2: FOLLOW_US_TIKTOK_URL_CCA,
        },
      },
      youtube: {
        url: {
          1: FOLLOW_US_YOUTUBE_URL_CCN,
          2: FOLLOW_US_YOUTUBE_URL_CCA,
        },
      },
    };

    window.open(getURL[`${name}`]!.url[`${companyId}`], "_blank");
  };

  return (
    <div className={styles.SocialLinks}>
    <div className={styles.FollowUsText}>{t("footer.followUs.followUsText")}</div>
    <div className="footerSocialIcon">
      {socialMediaIcons.map((icon) => (
        <div className="footerIcon" key={icon.name}>
          <button
            type="button"
            style={{ background: "transparent", border: "none" }}
            tabIndex={0}
            onKeyPress={(event) =>
              triggerAnalyticsAndNavigation(
                event,
                parseInt(company.id, 10),
                t(`segment.${icon.name}`),
                icon.name
              )
            }
            onClick={(event) =>
              triggerAnalyticsAndNavigation(
                event,
                parseInt(company.id, 10),
                t(`segment.${icon.name}`),
                icon.name
              )
            }
          >
            <Image src={icon.src} alt={icon.name} />
          </button>
        </div>
      ))}
    </div>
  </div>
  );
};

export default withTranslation()(SocialLinks);
