import Snackbar, { SnackbarOrigin } from "@mui/material/Snackbar";
import { useState } from "react";
import "./SnackbarSuccess.scss";
import VerifiedIcon from "@mui/icons-material/Verified";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

interface SnackBarProps {
  message: string;
  topRight?: boolean;
}

function SnackbarSuccess({ message, topRight }: SnackBarProps) {
  const anchorOrigin: SnackbarOrigin = {
    vertical: "top",
    horizontal: topRight ? "right" : "center",
  };
  const [isOpen, setIsOpen] = useState(true);

  const toasterSuccessIcon = (
    <div className="itemIcon completeIcon">
      {topRight ? <CheckCircleIcon /> : <VerifiedIcon className="passwordSuccessIcon" />}
    </div>
  );

  const { vertical, horizontal } = anchorOrigin;

  return (
    <div className="toasterWrapper">
      <Snackbar
        anchorOrigin={anchorOrigin}
        open={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
        autoHideDuration={5000}
        message={message}
        key={vertical + horizontal}
        action={toasterSuccessIcon}
      />
    </div>
  );
}

export default SnackbarSuccess;

SnackbarSuccess.defaultProps = {
  topRight: false,
};
