import React, { useEffect, useState } from "react";
import { Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Paper from "@mui/material/Paper";
import Chip from "@material-ui/core/Chip";
import Button from "@material-ui/core/Button";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { APIMethods, ReferencesStates } from "../../../constants/constants";

const useStyles = makeStyles((theme) => ({
  onBTableIcons: {
    padding: "8px 5px !important",
    "@media(min-width: 1024px) and (max-width: 1280px)": {
      padding: "8px 0px !important",
    },
  },
  onBEditIcon: {
    lineHeight: "20px",
    color: "#373c73",
    fill: "#373c73",
    cursor: "pointer",
    minWidth: "24px !important",
    padding: "0px 5px 0px 5px !important",
    "&:hover": {
      fill: theme.palette.colorSecondary,
      color: theme.palette.colorSecondary,
      backgroundColor: "transparent !important",
    },
    "@media(min-width: 0px) and (max-width: 768px)": {
      padding: "0px 20px 0px 0px !important",
      marginTop: "-8px",
      marginBottom: "-5px",
    },
    "@media(min-width: 1024px) and (max-width: 1280px)": {
      padding: "0px 5px 0px 5px !important",
    },
  },
}));
function OnBoardingReferencesTable({ mode, references, handelEdit, handelDelete, isEdit }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [isDisabled, setIsDisabled] = useState(false);
  useEffect(() => {
    mode !== APIMethods.ADD ? setIsDisabled(false) : setIsDisabled(true);
  }, [mode]);

  const verifyStatus = (type, reference) => {
    if (
      type === "onEdit" &&
      reference.status?.toLowerCase() !== ReferencesStates.COMPLETE.toLowerCase() &&
      reference?.status?.toLowerCase() !== ReferencesStates.INPROGRESS.toLowerCase() &&
      reference?.status?.toLowerCase() !== ReferencesStates.DECLINED.toLowerCase() &&
      reference?.status?.toLowerCase() !== ReferencesStates.EXPIRED.toLowerCase()
    ) {
      return true;
    } else if (
      type === "onDelete" &&
      reference?.status?.toLowerCase() !== ReferencesStates.COMPLETE.toLowerCase() &&
      reference?.status?.toLowerCase() !== ReferencesStates.INPROGRESS.toLowerCase() &&
      reference?.status?.toLowerCase() !== ReferencesStates.DECLINED.toLowerCase()
    ) {
      return true;
    } else return false;
  };

  return (
    <Row className="mt-2 mb-3">
      <Col sm={12} md={12} lg={12} xs={12}>
        {isMobile ? (
          <Table id="OnBEmptySkill" className="onBSkillsTable responsiveTable mt-4">
            <Thead>
              <Tr className="onBTableHeadSkill">
                <Th style={{ width: "10%" }}>{t("addReferences.statusLabel")}</Th>
                <Th style={{ width: "40%" }}>{t("addReferences.nameLabelTable")}</Th>
                <Th style={{ width: "20%" }}>{t("addReferences.titleLabelTable")}</Th>
                <Th style={{ width: "20%" }}>{t("addReferences.expLabel")}</Th>
                <Th style={{ width: "20%" }}>{t("addReferences.emailLabelTable")}</Th>
                <Th style={{ width: "20%" }}>{t("addReferences.phoneLabel")}</Th>
                <Th style={{ width: "20%" }}>{t("addReferences.actionLabel")}</Th>
              </Tr>
            </Thead>
            <Tbody className="tableRowHover">
              {references.map((reference, index) => (
                <Tr className="onBTableCellSkill">
                  <Td style={{ width: "40%" }}>
                    <Chip
                      label={reference.status}
                      className={
                        reference.status === ReferencesStates.PENDING
                          ? "onBPendingStatus"
                          : reference?.status?.toLowerCase() ===
                            ReferencesStates.COMPLETE.toLowerCase()
                          ? "onBSpeCompleteStatus"
                          : reference?.status?.toLowerCase() ===
                            ReferencesStates.INPROGRESS.toLowerCase()
                          ? "onBInProgressStatus"
                          : reference?.status?.toLowerCase() ===
                            ReferencesStates.DECLINED.toLowerCase()
                          ? "onBDeclinedStatus"
                          : reference?.status?.toLowerCase() ===
                            ReferencesStates.EXPIRED.toLowerCase()
                          ? "onBExpiredStatus"
                          : ""
                      }
                    />
                  </Td>
                  <Td style={{ width: "40%" }}>
                    {reference.firstName && reference.lastName
                      ? `${reference.firstName} ${reference.lastName}`
                      : reference.firstName
                      ? reference.firstName
                      : reference.lastName
                      ? reference.lastName
                      : "-"}
                  </Td>
                  <Td style={{ width: "40%" }}>{reference.title ? reference.title : "-"}</Td>
                  <Td style={{ width: "40%" }}>
                    {reference.facilityName
                      ? `${reference.facilityName} - ${reference.startDate} - ${
                          reference.endDate ? reference.endDate : t("addReferences.presentLabel")
                        }`
                      : "-"}
                  </Td>
                  <Td style={{ width: "40%" }}>
                    <span className="normalText"> {reference.email ? reference.email : "-"} </span>
                  </Td>
                  <Td style={{ width: "40%" }}>{reference.phone ? reference.phone : "-"}</Td>
                  <Td style={{ width: "40%" }} className="mt-1">
                    {verifyStatus("onEdit", reference) && (
                      <Button
                        className={classes.onBEditIcon}
                        onClick={() => handelEdit(reference, index)}
                        disabled={
                          isEdit ||
                          isDisabled ||
                          reference?.status?.toLowerCase() !==
                            ReferencesStates.PENDING.toLowerCase()
                        }
                      >
                        <div>
                          <svg
                            className="onBEditIconSVG"
                            xmlns="http://www.w3.org/2000/svg"
                            height="24"
                            viewBox="0 0 24 24"
                            width="24"
                          >
                            <path d="M0 0h24v24H0V0z" fill="none" />
                            <path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM5.92 19H5v-.92l9.06-9.06.92.92L5.92 19zM20.71 5.63l-2.34-2.34c-.2-.2-.45-.29-.71-.29s-.51.1-.7.29l-1.83 1.83 3.75 3.75 1.83-1.83c.39-.39.39-1.02 0-1.41z" />
                          </svg>
                        </div>
                      </Button>
                    )}
                    {verifyStatus("onDelete", reference) && (
                      <Button
                        className="onBDeleteIcon"
                        onClick={() => handelDelete(index, reference)}
                        disabled={
                          isEdit ||
                          isDisabled ||
                          reference?.status?.toLowerCase() ===
                            ReferencesStates.INPROGRESS.toLowerCase() ||
                          reference?.status?.toLowerCase() ===
                            ReferencesStates.COMPLETE.toLowerCase()
                        }
                      >
                        <div>
                          <svg
                            className="onBDeleteIconSVG"
                            xmlns="http://www.w3.org/2000/svg"
                            height="24"
                            viewBox="0 0 24 24"
                            width="24"
                          >
                            <path d="M0 0h24v24H0V0z" fill="none" />
                            <path d="M16 9v10H8V9h8m-1.5-6h-5l-1 1H5v2h14V4h-3.5l-1-1zM18 7H6v12c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7z" />
                          </svg>
                        </div>
                      </Button>
                    )}
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        ) : (
          <TableContainer component={Paper} className="onBTableContain mt-4">
            <Table aria-label="customized table" className="payDeskTable" id="OnBEducation">
              <TableHead>
                <TableRow className="onBTableHead">
                  <TableCell>{t("addReferences.statusLabel")}</TableCell>
                  <TableCell>{t("addReferences.nameLabelTable")}</TableCell>
                  <TableCell>{t("addReferences.titleLabelTable")}</TableCell>
                  <TableCell>{t("addReferences.expLabel")}</TableCell>
                  <TableCell>{t("addReferences.emailLabelTable")}</TableCell>
                  <TableCell>{t("addReferences.phoneLabel")}</TableCell>
                  <TableCell>{t("addReferences.actionLabel")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody className="tableRowHover">
                {references.map((reference, index) => (
                  <TableRow>
                    <TableCell>
                      <Chip
                        label={reference.status}
                        className={
                          reference?.status?.toLowerCase() ===
                          ReferencesStates.PENDING.toLowerCase()
                            ? "onBPendingStatus"
                            : reference?.status?.toLowerCase() ===
                              ReferencesStates.COMPLETE.toLowerCase()
                            ? "onBSpeCompleteStatus"
                            : reference?.status?.toLowerCase() ===
                              ReferencesStates.INPROGRESS.toLowerCase()
                            ? "onBInProgressStatus"
                            : reference?.status?.toLowerCase() ===
                              ReferencesStates.DECLINED.toLowerCase()
                            ? "onBDeclinedStatus"
                            : reference?.status?.toLowerCase() ===
                              ReferencesStates.EXPIRED.toLowerCase()
                            ? "onBExpiredStatus"
                            : ""
                        }
                      />
                    </TableCell>
                    <TableCell>
                      {reference.firstName && reference.lastName
                        ? `${reference.firstName} ${reference.lastName}`
                        : reference.firstName
                        ? reference.firstName
                        : reference.lastName
                        ? reference.lastName
                        : "-"}
                    </TableCell>
                    <TableCell>{reference.title ? reference.title : "-"}</TableCell>
                    <TableCell>
                      {reference.facilityName
                        ? reference.startDate
                          ? `${reference.facilityName} - ${reference.startDate} - ${
                              reference.endDate
                                ? reference.endDate
                                : t("addReferences.presentLabel")
                            }`
                          : reference.facilityName
                        : "-"}
                    </TableCell>
                    <TableCell>
                      <span className="normalText">
                        {" "}
                        {reference.email ? reference.email : "-"}{" "}
                      </span>
                    </TableCell>
                    <TableCell>{reference.phone ? reference.phone : "-"}</TableCell>
                    <TableCell className={classes.onBTableIcons}>
                      {verifyStatus("onEdit", reference) && (
                        <Button
                          className={classes.onBEditIcon}
                          onClick={() => handelEdit(reference, index)}
                          disabled={
                            isEdit ||
                            isDisabled ||
                            reference?.status?.toLowerCase() !==
                              ReferencesStates.PENDING.toLowerCase()
                          }
                        >
                          <div>
                            <svg
                              className="onBEditIconSVG"
                              xmlns="http://www.w3.org/2000/svg"
                              height="24"
                              viewBox="0 0 24 24"
                              width="24"
                            >
                              <path d="M0 0h24v24H0V0z" fill="none" />
                              <path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM5.92 19H5v-.92l9.06-9.06.92.92L5.92 19zM20.71 5.63l-2.34-2.34c-.2-.2-.45-.29-.71-.29s-.51.1-.7.29l-1.83 1.83 3.75 3.75 1.83-1.83c.39-.39.39-1.02 0-1.41z" />
                            </svg>
                          </div>
                        </Button>
                      )}
                      {verifyStatus("onDelete", reference) && (
                        <Button
                          className="onBDeleteIcon"
                          onClick={() => handelDelete(index, reference)}
                          disabled={
                            isEdit ||
                            isDisabled ||
                            reference?.status?.toLowerCase() ===
                              ReferencesStates.INPROGRESS.toLowerCase() ||
                            reference?.status?.toLowerCase() ===
                              ReferencesStates.COMPLETE.toLowerCase()
                          }
                        >
                          <div>
                            <svg
                              className="onBDeleteIconSVG"
                              xmlns="http://www.w3.org/2000/svg"
                              height="24"
                              viewBox="0 0 24 24"
                              width="24"
                            >
                              <path d="M0 0h24v24H0V0z" fill="none" />
                              <path d="M16 9v10H8V9h8m-1.5-6h-5l-1 1H5v2h14V4h-3.5l-1-1zM18 7H6v12c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7z" />
                            </svg>
                          </div>
                        </Button>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Col>
    </Row>
  );
}

export default OnBoardingReferencesTable;
