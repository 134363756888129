/* eslint-disable import/no-cycle */
import { push } from "connected-react-router";
import { getAccessToken} from "axios-jwt";
import { GET_MSP_JOBS} from "src/services/GraphQL/graph.query";
import { graphqlRequest as request } from "src/services/GraphQL/GraphQL";
import Analytics from "src/services/Analytics";
import { MY_JOBS, JOBS_RESULTS } from "../../../router/routes";
import API from "../../../services/Api";
import JobCardContainer from "../../../components/common/MainContent/JobResults/JobCardContainer";
import { fetchMyJobs } from "../MyJob/action";
import {
  updateLocalNavigationKey,
  updateSubNavigationKey,
  updateGlobalNavigationKey,
} from "../../navigation/action";
import { RECOMMENDED_JOBS } from "../../../constants/campaigns_constants";
import getCompanyFromDomain from "../../../services/Company";
import config from "../../../config";

const enableEligibilityMeter = config.ENABLE_ELIGIBILITY_METER === "true";

export const FETCH_RECOMMENDED_JOBS_REQUEST = "FETCH_RECOMMENDED_JOBS_REQUEST";
export const fetchRecommendedJobsRequest = (payload) => ({
  type: FETCH_RECOMMENDED_JOBS_REQUEST,
  payload,
});

export const FETCH_RECOMMENDED_JOBS_SUCCESS = "FETCH_RECOMMENDED_JOBS_SUCCESS";
export const fetchRecommendedJobsSuccess = (payload) => ({
  type: FETCH_RECOMMENDED_JOBS_SUCCESS,
  payload,
});

export const FETCH_RECOMMENDED_JOBS_FAILURE = "FETCH_RECOMMENDED_JOBS_FAILURE";
export const fetchRecommendedJobsFailure = (payload) => ({
  type: FETCH_RECOMMENDED_JOBS_FAILURE,
  payload,
});

export const UPDATE_RECOMMENDED_JOB_OBJECT = "UPDATE_RECOMMENDED_JOB_OBJECT";
export const updateRecommendedJobsObject = (payload) => ({
  type: UPDATE_RECOMMENDED_JOB_OBJECT,
  payload,
});

export const UPDATE_NAVIGATION_KEY = "UPDATE_NAVIGATION_KEY";
export const updateNavigationKey = (payload) => ({ type: UPDATE_NAVIGATION_KEY, payload });

const enableExcludeJobs = config.ENABLE_EXCLUDE_JOBS === "true";

export const UPDATE_RECOMMENDED_JOBS_CAROUSEL = "UPDATE_RECOMMENDED_JOBS_CAROUSEL";
export const updateRecommendedJobsCarousel = (payload) => ({
  type: UPDATE_RECOMMENDED_JOBS_CAROUSEL,
  payload,
});

export const recommendedJobsCarousel = (items) => (dispatch) => {
  const cardArray = [];
  items.forEach((job) => {
    cardArray.push(<JobCardContainer job={job} recommended campaignCode={RECOMMENDED_JOBS} />);
  });
  dispatch(updateRecommendedJobsCarousel(cardArray));
};

export const fetchRecommendedJobs = (data, nav) => async (dispatch) => {
  const recommendedUrl = enableExcludeJobs ? "recommended" : "users?actionFilters=recommend";

  dispatch(fetchRecommendedJobsRequest());
  try {
    let response;
    if (enableEligibilityMeter) {
      response = await API.post(
        `jobs/search/${recommendedUrl}`,
        {
          candidateInformation: data.candidateInformation,
        },
        {
          params: {
            sort: data.sort,
            companyId: data.companyId,
            size: data.size,
            candidateId: data.candidateId,
            includeQuickApplyEligibility: true,
          },
        }
      );
    } else {
      response = await API.get(`jobs/search/${recommendedUrl}`, {
        params: {
          sort: data.sort,
          companyId: data.companyId,
          size: data.size,
          candidateId: data.candidateId,
        },
      });
    }
    if (response.status === 200) {
      dispatch(fetchRecommendedJobsSuccess(response.data));
      const jobSourceType = response?.data?._embedded?.results?.length > 0 ? response?.data?._embedded?.jobsSource : null;
      Analytics.fetchRecommendedJobsSourceType(jobSourceType);
      dispatch(recommendedJobsCarousel(response.data._embedded.results));
    } else {
      dispatch(fetchRecommendedJobsFailure(response));
    }

    if (nav === JOBS_RESULTS) {
      dispatch(push(nav));
    }
  } catch (error) {
    dispatch(fetchRecommendedJobsFailure(error));
  }
};

// eslint-disable-next-line no-unused-vars
export const updateCarouselRecommendedJobObject = (payload) => (dispatch) => {
  if (payload.recommendedJobs._embedded) {
    const result = payload.recommendedJobs._embedded.results;
    result.forEach((job, index) => {
      if (payload.jobId === job.id) {
        switch (payload.action) {
          case "favorite":
            result[String(index)].isFavorite = payload.request.isActive;
            break;

          case "apply":
            result[String(index)].isApplied = payload.request.isActive;
            break;

          case "email":
            result[String(index)].isEmailed = payload.request.isActive;
            break;

          default:
            break;
        }
      }
    });
  }
  updateRecommendedJobsObject(payload.recommendedJobs);
};

export const subNavRedirection = (payload) => {
  const reqObj = {
    filters: payload.filters,
    page: 1,
    companyId: getCompanyFromDomain().id,
  };
  return (dispatch) => {
    if (payload && payload.path === MY_JOBS) {
      dispatch(fetchMyJobs(reqObj));
    }
    dispatch(updateNavigationKey(payload.key));
    dispatch(updateSubNavigationKey(payload.key));
  };
};

export const handleSelectedJobMenu = (payload) => (dispatch) => {
  if (payload) {
    const obj = {
      path: null,
      key: payload.key,
    };
    obj.path = MY_JOBS;
    obj.filters = payload.filters;
    dispatch(subNavRedirection(obj));
  }
};

export const FETCH_MSP_JOBS_REQUEST = "FETCH_RECOMMENDED_JOBS_REQUEST";
export const fetchMspJobsRequest = (payload) => ({
  type: FETCH_MSP_JOBS_REQUEST,
  payload,
});

export const FETCH_MSP_JOBS_SUCCESS = "FETCH_MSP_JOBS_SUCCESS";
export const fetchMspJobsSuccess = (payload) => ({
  type: FETCH_MSP_JOBS_SUCCESS,
  payload,
});

export const FETCH_MSP_JOBS_FAILURE = "FETCH_MSP_JOBS_FAILURE";
export const fetchMspJobsFailure = (payload) => ({
  type: FETCH_MSP_JOBS_FAILURE,
  payload,
});

export const fetchMspJobs = (query) => async (dispatch) => {
  dispatch(fetchMspJobsRequest());
  try {
    const mspJobsResponse = await request(GET_MSP_JOBS, query, getAccessToken());
    if (mspJobsResponse?.getMSPJobs?.code === 200) {
      dispatch(fetchMspJobsSuccess(mspJobsResponse.getMSPJobs));
    } else {
      dispatch(fetchMspJobsFailure(response));
    }
  } catch (error) {
    dispatch(fetchMspJobsFailure(error));
  }
};

export const UPDATE_MSP_JOB_OBJECT = "UPDATE_MSP_JOB_OBJECT";
export const updateMspJobsObject = (payload) => {
  if (payload.mspJobs.embedded.results) {
    const result = payload.mspJobs.embedded.results;
    result.forEach((job, index) => {
      if (payload.jobId === job.id) {
        switch (payload.action) {
          case "favorite":
            result[String(index)].isFavorite = payload.request.isActive;
            break;

          case "apply":
            result[String(index)].isApplied = payload.request.isActive;
            break;

          case "email":
            result[String(index)].isEmailed = payload.request.isActive;
            break;

          default:
            break;
        }
      }
    });
  }
  return { type: UPDATE_MSP_JOB_OBJECT, payload: payload.mspJobs };
};