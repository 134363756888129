import { withStyles } from "@material-ui/core/styles";
import BaseButton from "./BaseButton";

const SecondaryButton = withStyles((theme) => ({
  root: {
    display: "flex",
    backgroundColor: theme.palette.colorSecondary,
    color: "#ffffff",
    "&:hover": {
      boxShadow: "0px 4px 2px -2px #00000070",
      backgroundColor: theme.palette.colorSecondaryHover,
    },
  },
}))(BaseButton);

export default SecondaryButton;
