import { TFunction, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import Link from "@material-ui/core/Link";
import { CUSTOMER_SERVICE_EMAIL, CUSTOMER_SERVICE_PHONE } from "src/constants/contact_info";
import Logo from "src/components/common/Header/Logo";

interface SomethingWentWrongProps {
  t: TFunction<"translation", undefined>;
  errorCode: string;
  email: string;
}

const SomethingWentWrong = ({ t, errorCode, email }: SomethingWentWrongProps) => {
  return (
    <div className="container-fluid">
      <div className="apiErrorImgDiv">
        <Logo />
      </div>
      <div className="apiErrorMainTxt">
        {t("applicantIDNotFound.noApplicantIDHead")}
        <span className="apiErrorMainTxtMob"> {t("applicantIDNotFound.noApplicantIDHead1")}</span>
      </div>
      <div className="apiErrorSubTxt">
        {t("applicantIDNotFound.noApplicantIDText1")} <br />
        <br />
        {t("applicantIDNotFound.noApplicantIDText2")}
        <span className="apiErroremailLink">
          &nbsp;
          <a
            href={`mailto:${CUSTOMER_SERVICE_EMAIL}?subject=${
              t("applicantIDNotFound.noApplicantIdEmailSubjectLine1") +
              errorCode +
              t("applicantIDNotFound.noApplicantIdEmailSubjectLine2")
            }${localStorage.getItem("username") || email}&`}
          >
            {CUSTOMER_SERVICE_EMAIL}
          </a>
        </span>
        &nbsp;
        {t("applicantIDNotFound.noApplicantIDText3") +
          errorCode +
          t("applicantIDNotFound.noApplicantIDText4")}
        &nbsp;{t("applicantIDNotFound.noApplicantIDTextCall")}
        <Link href={`tel:${CUSTOMER_SERVICE_PHONE}`} className="custServiceNo">
          {CUSTOMER_SERVICE_PHONE}
        </Link>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  email: state.auth.email,
});

export default connect(mapStateToProps)(withTranslation()(SomethingWentWrong));
