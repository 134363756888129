import { Theme, withStyles } from "@material-ui/core/styles";
import BaseButton from "./BaseButton";

const TertiaryButton = withStyles((theme) => {
  const themeWithProps = theme as Theme & { palette: { colorPrimary: string } };
  return {
    root: {
      display: "flex",
      backgroundColor: "#ffffff",
      color: themeWithProps.palette.colorPrimary,
      border: `1px solid${themeWithProps.palette.colorPrimary}`,
      "&:hover": {
        boxShadow: "none",
        backgroundColor: "#F2F2F7",
      },
    },
  };
})(BaseButton);

export default TertiaryButton;
