import React from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@mui/material/TextField";
import Button from "@material-ui/core/Button";
import Container from "react-bootstrap/Container";
import Link from "@material-ui/core/Link";
import CircularProgress from "@material-ui/core/CircularProgress";
import Axios from "axios";
import jsonwebtoken from "jsonwebtoken";
import ReactLoading from "react-loading";
import CryptoJS from "crypto-js";
import { History } from "history";
import {
  saveTokenInLocalStorageAndFillState,
  updateJobPreferencesFlag,
} from "src/redux/auth/action";
import HelpSigningIn from "./HelpSignIn";
import { signIn } from "src/services/Auth";
import Analytics from "src/services/Analytics";
import config from "src/config";
import getCompanyFromDomain from "src/services/Company";
import { setCompanyFromDomain } from "src/redux/company/action";
import isGeneric from "src/services/utility";
import SignInUserVerification from "./SignInUser/SignInUserVerification";
import { apolloClient } from "src/services/GraphQL/GraphQL";
import { SEND_EMAIL_VERIFICATION } from "src/services/GraphQL/graph.query";
import Dynatrace from "src/services/Dynatrace";
import SnackbarSuccess from "src/components/common/SnackbarSuccess/SnackbarSuccess";
import { LOGIN } from "src/router/routes";
import Logo from "src/components/common/Header/Logo";

const styles = () => ({});
const query = new URLSearchParams(window.location.search);
const enableEmailVerification = config.ENABLE_EMAIL_VERIFICATION === "true";
interface loginProps {
  t: (words: string) => string;
  company: {
    id: string;
  };
  redirectURL: string | null;
  pullUserInformation: (
    idToken: string,
    refreshToken: string,
    companyId: string,
    path: string
  ) => void;
  otherProps: {
    history: History;
  };
  userId: string;
  needHelpClicked: boolean;
  needHelpSignIn: () => void;
  loading: boolean;
  isTokenEmpty: string | boolean;
  updateJobPreferencesFlag: (flag: boolean) => void;
  setCompanyFromDomain: (company: any) => void;
  newUser: boolean;
}

interface loginState {
  email: string;
  password: string;
  emailRequiredError: boolean;
  passwordRequiredError: boolean;
  credentialsRequiredError: boolean;
  errorPresent: boolean;
  retryCount: number;
  waitForApiResp: boolean;
  isDisabled: boolean;
  companyDetails: string;
  isEmailVerified: boolean;
  [x: number]: any;
  verifyEmailPopUp: boolean;
  emailVerificationJson: {
    companyId: string;
    email: string;
    firstName: string;
    candidateStatus: string;
    password: string;
  };
  jwtPresent: boolean;
}

class Login extends React.Component<loginProps, loginState> {
  state = {
    email: query.get("email") || "",
    password: "",
    emailRequiredError: false,
    passwordRequiredError: false,
    credentialsRequiredError: false,
    errorPresent: false,
    retryCount: 0,
    waitForApiResp: false,
    isDisabled: false,
    companyDetails: "",
    isEmailVerified: true,
    verifyEmailPopUp: false,
    emailVerificationJson: {
      companyId: "",
      email: "",
      firstName: "",
      candidateStatus: "unverified",
      password: "",
    },
    jwtPresent: false,
  };

  constructor(props) {
    super(props);
    if (
      localStorage.getItem("historyPathName") &&
      localStorage.getItem("historyPathName")?.includes("/auth/relay")
    ) {
      this.triggerRecruitics();
    }
  }

  componentDidUpdate(nextProps) {
    if (this.props !== nextProps) {
      if (nextProps.isDisabled === true) {
        this.setState({
          waitForApiResp: false,
          passwordRequiredError: false,
          errorPresent: false,
          isDisabled: true,
        });
      }
    }
  }

  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    let jwtToken = window.location.pathname.split("/")[2]?.split("?")[0];
    const popUpFlag = urlParams.get("verifyPopUp");
    if (popUpFlag === "true") {
      this.setState({
        verifyEmailPopUp: true,
      });
    }
    try {
      if (jwtToken) {
        const decodedTokenObject = jsonwebtoken.verify(jwtToken, config.JWT_REDIRECT_SECRET_KEY);
        if (decodedTokenObject?.password && decodedTokenObject?.email) {
          this.setState(
            {
              password: decodedTokenObject.password,
              email: decodedTokenObject.email,
              jwtPresent: true,
            },
            this.signInClickHandler
          );
        } else {
          window.open(LOGIN, "_self");
        }
      }
    } catch (e) {
      window.open(LOGIN, "_self");
    }
  }

  //Trigger recruitics for WCM Existing user job apply
  triggerRecruitics() {
    if (sessionStorage.getItem("isRecruiticsCalled") === null) {
      sessionStorage.setItem("isRecruiticsCalled", true as any);
      setTimeout(() => {
        window.rx.trigger({
          triggerName: "apply",
          applyFlowId: window.rx.getViewerId(),
        });
      }, 10000);
    }
  }

  async getCompanyDetails(token) {
    let companyDetails;
    if (isGeneric()) {
      // To find company id based on user email from onBoarding
      const configuration = {
        headers: { Authorization: `Bearer ${token}` },
      };
      try {
        const res = await Axios(
          `${config.API_BASE_HOST}/ui/v1/profile/searchCompanyIdByEmail?email=${this.state.email}`,
          configuration
        );
        const content = res.data?.content;
        const status = res.data?.status;

        if (content.companyId) {
          companyDetails =
            content.companyId === 1 && status === "ok"
              ? config.COMPANY_CCN
              : content.companyId === 2
              ? config.COMPANY_CCA
              : config.COMPANY_CCN;

          await this.setState({ companyDetails });
        }
      } catch (e) {
        companyDetails = config.COMPANY_CCN;
        this.setState({ companyDetails });
      }
    } else {
      companyDetails = getCompanyFromDomain();
      this.setState({ companyDetails });
    }
  }

  signInClickHandler = async () => {
    try {
      let errorPresent = false;
      if (this.state.email.trim() === "") {
        this.setState({
          emailRequiredError: true,
          errorPresent: false,
        });
        errorPresent = true;
      }

      if (this.state.password.trim() === "") {
        this.setState({
          passwordRequiredError: true,
          errorPresent: false,
        });
        errorPresent = true;
      }

      if (errorPresent) return;
      this.setState({
        waitForApiResp: true,
      });
      const tokenObject = await signIn(this.state.email.trim().toLowerCase(), this.state.password);

      this.identityUserInDynatrace(tokenObject);

      await this.getCompanyDetails(tokenObject?.idToken?.jwtToken);
      await this.props.setCompanyFromDomain(this.state.companyDetails);

      if (!enableEmailVerification || tokenObject?.idToken?.payload?.email_verified) {
        this.setState({
          isEmailVerified: true,
        });

        this.props.pullUserInformation(
          tokenObject.idToken.jwtToken,
          tokenObject.refreshToken.token,
          this.props.company.id,
          this.props.redirectURL!
        );
        this.props.updateJobPreferencesFlag(true);
        if (tokenObject) {
          if (this.props.userId) {
            const event = {
              category: this.props.t("googleAnalytics.category.login"),
              action: this.props.t("googleAnalytics.action.userLoggedIn"),
              label: this.props.t("googleAnalytics.label.login"),
            };
            Analytics.sendPageview(this.props.otherProps.history.location, null, true);
            Analytics.sendEvent(event);
          }
          this.setState({
            waitForApiResp: false,
          });
        }
      } else {
        this.setState({
          isEmailVerified: false,
          waitForApiResp: false,
        });

        const emailVerificationJson = {
          companyId: this.props.company.id,
          email: this.state.email.trim().toLowerCase(),
          firstName: tokenObject?.idToken?.payload?.["custom:firstName"],
          candidateStatus: "unverified",
          password: CryptoJS.AES.encrypt(
            this.state?.password?.trim(),
            config.JWT_REDIRECT_SECRET_KEY
          ).toString(),
        };

        this.setState({
          emailVerificationJson: emailVerificationJson,
        });

        apolloClient
          .mutate({
            mutation: SEND_EMAIL_VERIFICATION,
            variables: { emailVerification: emailVerificationJson },
          })
          .catch((err) => console.error(err));
      }
    } catch (err) {
      this.setState((prevState) => ({
        errorPresent: true,
        passwordRequiredError: false,
        emailRequiredError: false,
        retryCount: prevState.retryCount + 1,
        waitForApiResp: false,
        isDisabled: false,
        jwtPresent: false,
      }));
    }
  };

  handleOnChange = (event) => this.setState({ [event.target.name]: event.target.value });

  identityUserInDynatrace = (tokenObject) => {
    try {
      if (tokenObject && !Dynatrace().disable()) {
        Dynatrace().identify(this.state.email.trim());
      }
    } catch (err) {
      console.log(`Error while identifying user in dynatrace: ${err}`);
    }
  }

  handleOnPaste = (event) => {
    const pasteValue = event.clipboardData.getData("text/plain");
    if (pasteValue.includes(" ")) event.preventDefault();
  };

  handleKeyPress = (event) => {
    if (event.key === " ") event.preventDefault();
  };

  render() {
    const highlightHelpSignIn = (
      <Link className="needHelpHighlight" onClick={this.props.needHelpSignIn}>
        {" "}
        {this.props.t("login.helpSignInText")}{" "}
      </Link>
    );

    const grayHelpSignIn = (
      <div className="needHelpTxt" onClick={this.props.needHelpSignIn} role="button">
        {" "}
        {this.props.t("login.helpSignInText")}{" "}
      </div>
    );
    const showDuplicateUserMsg =
      this.props.isTokenEmpty && !this.props.needHelpClicked && !this.props.newUser;

    const login = (
      <>
        {this.state.verifyEmailPopUp && (
          <span className="createPasswordSuccess">
            <SnackbarSuccess message={this.props.t("emailVerification.success")} />
          </span>
        )}
        <div className="genericLogo" />
        {showDuplicateUserMsg && (
          <div className="duplicateLoginMsgV2">{this.props.t("login.duplicateUserMessageV2")}</div>
        )}
        <TextField
          className={
            this.state.emailRequiredError || this.state.errorPresent
              ? "errorMsgSignUp"
              : "signintextfiled"
          }
          name="email"
          label={this.props.t("login.emailLabel")}
          type="Email Address"
          autoComplete="current-email"
          onChange={(event) => this.handleOnChange(event)}
          variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
          value={this.state.email}
        />
        {this.state.emailRequiredError && (
          <div className="erroMsgsignIn">{this.props.t("login.emailError")}</div>
        )}
        <TextField
          className={
            this.state.passwordRequiredError || this.state.errorPresent
              ? "errorMsgSignUp"
              : "signintextfiled"
          }
          id="textsignIn"
          label={this.props.t("login.passwordLabel")}
          name="password"
          type="password"
          autoComplete="current-password"
          onChange={(event) => {
            this.handleOnChange(event);
          }}
          onPaste={(event) => {
            this.handleOnPaste(event);
          }}
          onKeyPress={this.handleKeyPress}
          variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
        />
        <br />
        {this.state.passwordRequiredError && (
          <div className="erroMsgsignIn">{this.props.t("login.passwordError")}</div>
        )}

        {this.state.errorPresent && (
          <div className="erroMsgsignIn">{this.props.t("login.errorMsg")}</div>
        )}

        {this.state.isDisabled === true && (
          <div className="erroMsgsignIn">{this.props.t("login.accountDisabled")}</div>
        )}

        <br />
        <div className="signInBtnAlign">
          <Button
            className="signInButton"
            disabled={this.state.waitForApiResp || this.props.loading}
            onClick={this.signInClickHandler}
          >
            {this.props.t("login.signInButton")}
            {(this.props.loading || this.state.waitForApiResp) && (
              <CircularProgress size={17} className="ml-2 loaderResendBtn" />
            )}
          </Button>
        </div>
        <div className="signInBtnAlign">
          <Link href={config.SIGN_UP_URL} className="signUpLabel">
            {" "}
            {this.props.t("login.signUpLink")}{" "}
          </Link>
          <br />
        </div>
        <div className="needHelpTxt">
          {this.state.retryCount >= 3 ? highlightHelpSignIn : grayHelpSignIn}
        </div>
      </>
    );

    const needHelp = (
      <HelpSigningIn
        needHelpClicked={this.props.needHelpClicked}
        otherProps={this.props.otherProps}
      />
    );
    if (!this.state.jwtPresent) {
      return (
        <div>
          <Container>
            {!enableEmailVerification || this.state.isEmailVerified === true ? (
              this.props.needHelpClicked ? (
                needHelp
              ) : (
                login
              )
            ) : (
              <SignInUserVerification emailVerificationJson={this.state.emailVerificationJson} />
            )}
          </Container>
        </div>
      );
    } else {
      return (
        <Container fluid className="loaderPage">
          <Container>
            <div>
              <Logo />
            </div>
            <div className="loadingTxt">{this.props.t("login.loginSuccess")}</div>
            <ReactLoading type="bubbles" className="loaderColor" />
          </Container>
        </Container>
      );
    }
  }
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  isDisabled: state.auth.isDisabled,
  loginFailureData: state.auth.loginFailureData,
  company: state.company.company,
  userId: state.auth.userId,
  email: state.auth.email,
  loading: state.auth.loginLoading,
});

const mapDispatchToProps = (dispatch) => ({
  pullUserInformation: (idToken, refreshToken, companyId, path) =>
    dispatch(saveTokenInLocalStorageAndFillState(idToken, refreshToken, companyId, path)),
  updateJobPreferencesFlag: (value) => dispatch(updateJobPreferencesFlag(value)),
  setCompanyFromDomain: (company) => dispatch(setCompanyFromDomain(company)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(withTranslation()(Login)));
