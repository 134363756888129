import CloseIcon from "@material-ui/icons/Close";
import { connect } from "react-redux";
import { TFunction, withTranslation } from "react-i18next";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { updateJobPreferencesFlag } from "src/redux/auth/action";
import { BASIC_INFO_PAGE } from "src/router/routes";
import { History } from "history";
import { Dispatch } from "redux";

interface NotificationBoxProps {
  updateTheJobPreferencesFlag: (value: boolean) => void;
  t: TFunction<"translation", undefined>;
  history: History;
}

type NotificationBoxPropsAndWithRouterProps = NotificationBoxProps & RouteComponentProps;

function NotificationBox({
  updateTheJobPreferencesFlag,
  t: translate,
  history,
}: NotificationBoxPropsAndWithRouterProps) {
  const handleClose = () => {
    updateTheJobPreferencesFlag(false);
  };

  const handleNavigation = () => {
    updateTheJobPreferencesFlag(false);
    history.push(BASIC_INFO_PAGE);
  };

  return (
    <div className="filterBoxWrap">
      <div className="filterPreferance">
        <CloseIcon fontSize="small" className="filterCloseIcon" onClick={handleClose} />{" "}
        <div>
          <div className="filterSubTxt">
            {" "}
            {translate("filter.preferenceText1")} {translate("filter.preferenceText2")}{" "}
            {translate("filter.preferenceText3")}
            <br />
            <a className="filterUpdatePref" onClick={handleNavigation}>
              {translate("filter.updatePreferenceText")}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateTheJobPreferencesFlag: (value: boolean) => dispatch(updateJobPreferencesFlag(value)),
});

export default connect(null, mapDispatchToProps)(withTranslation()(withRouter(NotificationBox)));
