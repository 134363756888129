import { useInbox } from "@trycourier/react-inbox";
import { useEffect, FunctionComponent } from "react";
import config from "src/config";
import { Message } from "./Message/Message";
import styles from "./CustomInbox.module.scss";
import NoNotification from "./NoNotification/NoNotification";

const CustomInbox: FunctionComponent = () => {
  const loadCourier = config.LOAD_COURIER === "true";

  const { fetchMessages, getUnreadMessageCount, messages, isLoading, unreadMessageCount } =
    useInbox();
  const loading = messages?.length === 0 && isLoading;
  const getNotification = () => {
    try {
      fetchMessages({ params: { limit: 50 } });
      getUnreadMessageCount();
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (loadCourier) {
      getNotification();
    }
  }, []);

  return (
    <div className={styles.NotificationBox}>
      {loadCourier && (
        <>
          {unreadMessageCount > 0 && `Unread: ${unreadMessageCount}`}

          <div className={styles.MessageList}>
            {!loading &&
              messages.length > 0 &&
              messages?.map((message) => <Message message={message} />)}
          </div>
        </>
      )}

      {((!loading && messages?.length === 0) || !loadCourier) && <NoNotification />}
    </div>
  );
};

export default CustomInbox;
