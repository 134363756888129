import { withTranslation, useTranslation } from "react-i18next";
import paustubsMoneyIcon from "src/imagesV2/paystubsMoneyIcon.svg";
import styles from "src/components/common/MainContent/Payroll/Paystubs/PaystubsNoAssignments/PaystubsNoAssignments.module.scss"

const PaystubNoAssignments = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className={styles.paystubNoAssignmentWrapper}>
        <div>
          <img src={paustubsMoneyIcon} alt="payCheckMoneyIcon" title="payCheckMoneyIcon" />
        </div>
        <p>{t("payStubs.paystubData")}</p>
      </div>
    </>
  );
};

export default withTranslation()(PaystubNoAssignments);
