import { useTranslation, withTranslation } from "react-i18next";
import { BrowserView, MobileView } from "react-device-detect";
import Chip from "@material-ui/core/Chip";
import { Image } from "react-bootstrap";
import HotJobChipIcon from "src/imagesV2/Vector.svg";

const HotJobsIcon = () => {
    const { t } = useTranslation();

    const getHotJobsChip = () => {
        return <Chip size="medium" icon={<Image src={HotJobChipIcon}/>} label={t("dashboard.hotJobs.hotJobsChip")} className="quickOfferLabel" />;
    }

    const hotJobsChip = getHotJobsChip();
    return (
        <div className="hotJobChip">
            <BrowserView>
                {hotJobsChip}
            </BrowserView>
            <MobileView>
                {hotJobsChip}
            </MobileView>
        </div>
    )
   
}

export default withTranslation()(HotJobsIcon);
  