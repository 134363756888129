import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Container from "react-bootstrap/Container";
import ReactLoading from "react-loading";
import { logout } from "src/redux/auth/action";
import analyticsSegmentIdentify from "src/services/SegmentIdentify";
import Logo from "src/components/common/Header/Logo";

interface RootState {
  auth: {
    email: string;
    userId: string;
    firstName: string;
    lastName: string;
    phone: string;
  };
  company: {
    company: {
      code: string;
    };
  };
  consultant: {
    error: null;
    loading: boolean;
    recruiterDetails: {
      candidateId: number;
      companyId: number;
      consultantId: string;
      dateCreated: string;
      dateLastUpdated: string;
      emailId: string;
      firstName: string;
      id: number;
      isActive: boolean;
      isDeleted: boolean;
      lastName: string;
      phone: string;
      revenueCenter: string;
    };
  };
  onBoarding: {
    specialtiesAndEducation: {
      educationList: any[];
      isLegal: null;
      isMalpracticeClaim: null;
      isUSCitizen: null;
      specialty: string;
      primarySpecialty: string;
      profession: string;
      secondarySpecialty: null;
      yearsOfExperience: number;
    };
    basicInfo: {
      firstname: string;
      lastname: string;
      preferredName: null;
      email: string;
      phone: string;
      stateLicensesHeld: null;
      gender: null;
      permanentAddress: {
        streetAddress: null;
        apt: null;
        city: null;
        state: null;
        zip: null;
        country: null;
      };
      isCurrAddressDiff: boolean;
      currentAddress: {
        streetAddress: null;
        apt: null;
        city: null;
        state: null;
        zip: null;
        country: null;
      };
      emergencyContact: null;
      emergencyPhone: null;
      relation: null;
      isHcp: null;
      shifts: any[];
      interests: any[];
      dateAvailable: string;
      referredBy: null;
      requestedRecruiter: null;
      travelDestinations: [];
      preferredWorkEnv: null;
      professionalJourney: null;
    };
  };
}

function Logout() {
  const dispatch = useDispatch();
  const email = useSelector((state: RootState) => state.auth.email);
  const obj = {
    email: useSelector((state: RootState) => state.auth.email),
    company: useSelector((state: RootState) => state.company.company),
    userId: useSelector((state: RootState) => state.auth.userId),
    firstName: useSelector((state: RootState) => state.auth.firstName),
    lastName: useSelector((state: RootState) => state.auth.lastName),
    phone: useSelector((state: RootState) => state.auth.phone),
    recruiterDetails: useSelector((state: RootState) => state.consultant.recruiterDetails),
    onboardingSpecialtiesAndEducation: useSelector(
      (state: RootState) => state.onBoarding.specialtiesAndEducation
    ),
    onboardingBasicInfo: useSelector((state: RootState) => state.onBoarding.basicInfo),
  };

  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    }, [value]);
    return ref.current;
  };
  const prevEmail = usePrevious(obj.email);
  const prevRecruiterDetails = usePrevious(obj.recruiterDetails);

  useEffect(() => {
    if (
      obj &&
      obj.userId &&
      (obj.email !== prevEmail || obj.recruiterDetails !== prevRecruiterDetails)
    ) {
      analyticsSegmentIdentify(obj);
    }
  }, [obj, prevEmail, prevRecruiterDetails]);

  dispatch(logout(email));

  return (
    <Container fluid className="loaderPage">
      <Container>
        <div>
          <Logo />
        </div>
        <ReactLoading type="bubbles" className="loaderColor" />
      </Container>
    </Container>
  );
}

export default Logout;
