import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { Fragment, useEffect, useState } from "react";
import { useTranslation, withTranslation } from "react-i18next";
import { isMobile } from "react-device-detect";
import { payStubDetailsTypes } from "src/components/common/MainContent/Payroll/Paystubs/Tables";
import { formatCurrency } from "src/utils/formatCurrency";
import styles from "./EarningsTable.module.scss";

dayjs.extend(utc);

interface EarningsTableProps {
  payStubDetails: payStubDetailsTypes;
}

const EarningsTable = ({ payStubDetails }: EarningsTableProps) => {
  const { t } = useTranslation();
  const [earningsData, setEarningsData] = useState<any>({});

  const groupStubByCompany = (stub: any) => {
    if (!stub?.earnings || stub?.earnings?.length === 0) {
      return {};
    }
    const groupedStub = stub?.earnings.reduce((acc, curr) => {
      const key = curr.client;
      if (!acc[`${key}`]) {
        acc[`${key}`] = [];
      }
      acc[`${key}`].push(curr);
      return acc;
    }, {});
    return groupedStub;
  };

  const MobileTableData = () => {
    return Object.entries(earningsData).map((value: any) => (
      <Fragment key={value[0]}>
        <table>
          <thead>
            <tr>
              <th colSpan={5}>{value[0]}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <strong>{t("payStubs.date")}</strong>
              </td>
              <td>
                <strong>{t("payStubs.type")}</strong>
              </td>
              <td>
                <strong>{t("payStubs.hrs")}</strong>
              </td>
              <td>
                <strong>{t("payStubs.rate")}</strong>
              </td>
              <td>
                <strong>{t("payStubs.amount")}</strong>
              </td>
            </tr>
            {value[1].map((stub) => (
              <tr key={stub.date + stub.type}>
                <td>{dayjs(stub.date).utc().format("MM/DD/YY")}</td>
                <td>{stub.type}</td>
                <td>{stub.hours}</td>
                <td>{formatCurrency(stub.rate)}</td>
                <td>{formatCurrency(stub.amount)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </Fragment>
    ));
  };

  const earningsTableData = () => {
    return Object.entries(earningsData).map((value: any) => (
      <Fragment key={value[0]}>
        <tr>
          <td className={styles.tableClientName} colSpan={8}>
            {value[0]}
          </td>
        </tr>
        {value[1].map((stub) => (
          <tr key={stub.type + stub.date}>
            <td aria-label="" colSpan={3}>
              {" "}
            </td>
            <td>{dayjs(stub.date).utc().format("MM/DD/YY")}</td>
            <td>{stub.type}</td>
            <td>{stub.hours}</td>
            <td>{formatCurrency(stub.rate)}</td>
            <td className={styles.amountCol}>{formatCurrency(stub.amount)}</td>
          </tr>
        ))}
      </Fragment>
    ));
  };

  const DesktopTable = () => {
    return (
      <table>
        <thead>
          <tr>
            <th aria-label="" colSpan={3}>
              {" "}
            </th>
            <th>{t("payStubs.date")}</th>
            <th>{t("payStubs.type")}</th>
            <th>{t("payStubs.hrs")}</th>
            <th>{t("payStubs.rate")}</th>
            <th className={styles.amountCol}>{t("payStubs.amount")}</th>
          </tr>
        </thead>
        <tbody>{earningsTableData()}</tbody>
      </table>
    );
  };

  const renderTable = () => {
    if (!payStubDetails || !payStubDetails?.earnings || payStubDetails?.earnings?.length === 0) {
      return <></>;
    }
    return isMobile ? MobileTableData() : DesktopTable();
  };

  useEffect(() => {
    if (payStubDetails?.earnings) {
      setEarningsData(groupStubByCompany(payStubDetails));
    }
  }, [payStubDetails]);

  return (
    <>
      <div className={styles.paystubsEarningTable}>
        <h3 className={styles.tableHeading}>
          {t("payStubs.earnings")} &nbsp;&nbsp; {formatCurrency(payStubDetails?.totalPay)}
        </h3>
        {renderTable()}
      </div>
    </>
  );
};
export default withTranslation()(EarningsTable);
