import { Component, ReactElement } from "react";
import { connect } from "react-redux";
import Card from "@material-ui/core/Card";
import Container from "react-bootstrap/Container";
import EmailRecruiterV2 from "src/components/common/MainContent/JobResults/common/EmailRecruiterV2";
import JobCardSingleV2 from "src/components/common/MainContent/JobResults/common/JobCardSingleV2";
import {
  jobDetailType,
  MyJobsFilters,
} from "src/components/common/MainContent/JobResults/common/index";
import eligibilityPercentageCalc from "src/components/common/EligibilityMeter/EligibilityPercentage";
import { RecommendedJobsSourceTypes } from "src/types/receommendedJobsSource";

interface JobCardContainerV2Props {
  jobs: jobDetailType[];
  isRecommendedJobsOnResult: boolean;
  viewMyJobs: boolean;
  similarOrViewed: string;
  recommended: boolean;
  campaignCode: string;
  sourceType: string;
  myJobsFilter: MyJobsFilters;
  jobsSource: RecommendedJobsSourceTypes;
}

interface JobCardContainerV2State {
  openEmail: boolean;
  emailJobId: string;
  emailJobIdArray: string[];
  eligibilityPercentage: number |null;
}

class JobCardContainerV2 extends Component<JobCardContainerV2Props, JobCardContainerV2State> {
  constructor(props: JobCardContainerV2Props) {
    super(props);
    this.state = {
      openEmail: false,
      emailJobId: "",
      emailJobIdArray: [],
      eligibilityPercentage: null,
    };
  }

  handleEmailClick = (jobId: string): void => {
    this.setState((prevState) => ({
      openEmail: true,
      emailJobId: jobId,
      emailJobIdArray: prevState.emailJobIdArray.includes(jobId)
        ? [...prevState.emailJobIdArray]
        : [...prevState.emailJobIdArray, jobId],
    }));
  };

  handleEmailClose = (): void => {
    this.setState({ openEmail: false });
  };

  setEligibilityPercentage = (job: jobDetailType) => {
    const eligibilityPercent = eligibilityPercentageCalc(job);
    this.setState(() => ({
      eligibilityPercentage: eligibilityPercent,
    }));
  };

  renderJobResultsCards(jobs: JobCardContainerV2Props["jobs"]): JSX.Element {
    const { openEmail, emailJobId, eligibilityPercentage } = this.state;
    const {
      isRecommendedJobsOnResult,
      viewMyJobs,
      similarOrViewed,
      recommended,
      campaignCode,
      sourceType,
      myJobsFilter,
      jobsSource
    } = this.props;
    return (
      <Container>
        <div className="jobCardsFlexWrap">
          {jobs.map((job) => (
            <Card
              data-testid="jobCard"
              className={`jobCardMain ${myJobsFilter === "assignments" ? "jobCardDisabled" : ""}`}
              key={job.id}
            >
              {openEmail && emailJobId === job.id ? (
                <div className="emailCard emailCardV2">
                  <EmailRecruiterV2
                    job={job}
                    handleClose={this.handleEmailClose}
                    isRecommendedJobsOnResult={isRecommendedJobsOnResult}
                    viewMyJobs={viewMyJobs}
                    similarOrViewed={similarOrViewed}
                    recommended={recommended}
                    campaignCode={campaignCode}
                    sourceType={sourceType}
                    eligibilityPercent={eligibilityPercentage}
                    jobsSource={jobsSource}
                  />
                </div>
              ) : (
                <JobCardSingleV2
                  job={job}
                  handleEmailCLick={() => {
                    this.handleEmailClick(job.id);
                    this.setEligibilityPercentage(job);
                  }}
                  similarOrViewed={similarOrViewed}
                  isRecommendedJobsOnResult={isRecommendedJobsOnResult}
                  viewMyJobs={viewMyJobs}
                  recommended={recommended}
                  campaignCode={campaignCode}
                  sourceType={sourceType}
                  jobsSource={jobsSource}
                />
              )}
            </Card>
          ))}
        </div>
      </Container>
    );
  }

  render(): ReactElement {
    const { jobs } = this.props;
    return jobs ? this.renderJobResultsCards(jobs) : <div> No Jobs Found</div>;
  }
}

const mapStateToProps = (state) => ({
  myJobsFilter: state.myJobs.filterSelected,
});
export default connect(mapStateToProps)(JobCardContainerV2);
