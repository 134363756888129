import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { ReactNode } from "react";

interface StyledDialogDefaultsProps {
  open: boolean;
  showClose: boolean;
  handleClose: () => void;
  children: ReactNode;
}

const StyledDialogDefault = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(0),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "& .MuiPaper-root": {
    minHeight: "30vh",
    maxHeight: "355px",
    width: "100vw",
    margin: "0 auto",
    marginBottom: 0,
    borderRadius: " 40px 40px 40px 0px",
    "@media (max-width: 768px)": {
      borderRadius: "20px 20px 20px 0px",
    },
  },
}));

export default function StyledDialogDefaults(props: StyledDialogDefaultsProps) {
  const { open, handleClose, showClose, children } = props;
  return (
    <div>
      <StyledDialogDefault disableScrollLock={false} onClose={handleClose} open={open}>
        {showClose && (
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              zIndex: 9,
            }}
          >
            <CloseIcon />
          </IconButton>
        )}
        <DialogContent dividers>
          <div>{children}</div>
        </DialogContent>
      </StyledDialogDefault>
    </div>
  );
}
