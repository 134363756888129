import React from "react";
import MainLayout from "./MainLayout";
import OnBoardingWelcome from "../common/OnBoarding/OnBoardingWelcome";

class ApplyPage extends React.PureComponent {
  iFrame() {
    return (
      <>
        <OnBoardingWelcome />
      </>
    );
  }

  render() {
    const me = this;
    return <MainLayout component={me.iFrame.bind(me)} />;
  }
}

export default ApplyPage;
