import React from "react";
import { connect } from "react-redux";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import Link from "@material-ui/core/Link";
import IFrameLoader from "../IFrameLoader";
import config from "../../../config";
import MissingApplicantId from "../MainContent/Home/MissingApplicantIdError";
import { updateActiveFlag } from "../../../redux/onBoarding/action";
import { fetchAccessToken } from "../../../redux/candidate/action";
import analyticsSegmentIdentify from "../../../services/SegmentIdentify";
import API from "../../../services/Api";
import MyProfileNavWrapper from "./MyProfileNavWrapper";

class CertificationsLicensesPageV2 extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      accessToken: null,
    };
  }

  componentDidMount() {
    if (this.props.userId && this.props.email) {
      analyticsSegmentIdentify(this.props);
    }
    if (!this.props.isCandidateListEmpty && this.props.applicantId) {
      API.get(`${config.API_BASE_HOST}/ui/v1/users/es/token/${this.props.applicantId}`)
        .then((response) => {
          this.setState({ accessToken: response.data.response.accessToken });
        })
        .catch(this.setState({ accessToken: null }));
    }
    this.props.updateActiveFlag(-1);
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.email !== prevProps.email ||
      this.props.recruiterDetails !== prevProps.recruiterDetails
    ) {
      analyticsSegmentIdentify(this.props);
    }
  }

  iFrame() {
    const { applicantId } = this.props;
    let src = "";
    if (applicantId && this.state.accessToken) {
      src = `${config.EMPLOYEE_STREAM_PROFILE_CERTIFICATES_LICENSES}?access_token=${this.state.accessToken}&applicant_id=${this.props.applicantId}`;
    }
    return (
      <>
        {applicantId && this.state.accessToken ? (
          <IFrameLoader src={src} />
        ) : applicantId === null ? (
          <MissingApplicantId />
        ) : (
          <IFrameLoader />
        )}
      </>
    );
  }

  render() {
    if (this.props.useProfileIframe) {
      return (
        <Container fluid className="pl-0 pr-0">
          <div className="">{this.iFrame()}</div>
        </Container>
      );
    } else {
      return (
        <>
          <Container fluid className="myProfileWrap">
            <Row>
              <MyProfileNavWrapper />
              <div className="myProfilePageWrap iframeBGV2">{this.iFrame()}</div>
            </Row>
          </Container>
        </>
      );
    }
  }
}
const mapStateToProps = (state) => ({
  applicantId: state.candidate.applicantId,
  isCandidateListEmpty: state.auth.isCandidateListEmpty,
  company: state.company.company,
  useProfileIframe: state.auth.useProfileIframe,
  email: state.auth.email,
  userId: state.auth.userId,
  firstName: state.auth.firstName,
  lastName: state.auth.lastName,
  phone: state.auth.phone,
  recruiterDetails: state.consultant.recruiterDetails,
  onboardingSpecialtiesAndEducation: state.onBoarding.specialtiesAndEducation,
  onboardingBasicInfo: state.onBoarding.basicInfo,
});

const mapDispatchToProps = (dispatch) => ({
  fetchAccessToken: (payload) => dispatch(fetchAccessToken(payload)),
  updateActiveFlag: (activeFlag) => dispatch(updateActiveFlag(activeFlag)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withRouter(CertificationsLicensesPageV2)));
