import { useTranslation, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { jobDetailType } from "src/components/common/MainContent/JobResults/common";
import config from "src/config";
import { useEffect, useState } from "react";
import getCandidateInformation from "src/components/common/CandidateInformation/CandidateInformation";
import { fetchRecommendedJobs } from "src/redux/jobs/SearchCrossCountry/action";
import { RecommendedJobsSourceTypes } from "src/types/receommendedJobsSource";
import Analytics from "src/services/Analytics";
import styles from "./RecommendedJobs.module.scss";
import JobCardList from "../../job-card/list/JobCardList";
import { JobCardProps } from "../../job-card/JobCard";

interface RecommendedJobsProps {
  atsBullhornCandidateId: any;
  bhId: any;
  companyId: any;
  recommendedJobs: {
    _embedded: {
      results: jobDetailType[];
    };
  };
  onboardingInfo: any;
  fetchRecommendedJobs: any;
}

const RecommendedJobs = ({
  atsBullhornCandidateId,
  bhId,
  companyId,
  recommendedJobs,
  onboardingInfo,
  fetchRecommendedJobs: getRecommendedJobs,
}: RecommendedJobsProps) => {
  const { t } = useTranslation();

  const results = recommendedJobs?._embedded?.results;

  const skeletonCardOptions = Array.from({ length: 8 }).map((_) => ({
    job: undefined,
    headerOptions: {},
    contentOptions: {},
    footerOptions: {},
  } as JobCardProps));
  const jobCardOptions = results?.map((job) => ({
    job,
    headerOptions: {
      chips: [
        ...(job.msp === "Y" ? [{ type: "exclusive" }] : [])
      ]
    },
    contentOptions: {
      secondaryInfo: "quick-apply"
    },
    footerOptions: {},
    campaignCode: "Recommended Jobs",
    sourceType: "Homepage",
    jobsSource: RecommendedJobsSourceTypes.MATCH_LEGACY,
  } as JobCardProps)) ?? [];

  const isLoading = recommendedJobs == null;
  const [hasFeteched, setHasFetched] = useState<boolean>(false);
  const [hasTrackedAnalytics, setHasTrackedAnalytics] = useState<boolean>(false);

  const candidateInformation = getCandidateInformation(onboardingInfo);

  const headingText = t("dashboard.recommendedJobs.recommendedJobsHeadingText");

  useEffect(() => {
    if ((atsBullhornCandidateId || bhId) && companyId && candidateInformation && hasFeteched === false) {
      setHasFetched(true);
      getRecommendedJobs({
        sort: "relevant",
        companyId,
        size: config.JOB_COUNT,
        candidateId: (atsBullhornCandidateId || bhId) ?? null,
        candidateInformation
      });
    }
  }, [(atsBullhornCandidateId || bhId), companyId, candidateInformation]);

  useEffect(() => {
    if(!hasTrackedAnalytics && results?.length > 0) {
      setHasTrackedAnalytics(true);
      Analytics.segmentTrackEvent(t("segment.recommendedJobsLoaded"), null);
    }
  }, [hasTrackedAnalytics, results]);

  return (
    <div className={styles.main}>
      <div className={styles.sub}>
        <div className={styles.heading}>
          {headingText}
        </div>
        <JobCardList
          jobCardOptions={isLoading ? skeletonCardOptions : jobCardOptions}
        />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  recommendedJobs: state.jobs.recommendedJobs,
  bhId: state.candidate.bullhornIdFromBullhorn,
  atsBullhornCandidateId: state.candidate.atsBullhornCandidateId,
  companyId: state.company.company.id,
  onboardingInfo: state.onBoarding
});

const mapDispatchToProps = (dispatch) => ({
  fetchRecommendedJobs: (obj, nav) => dispatch(fetchRecommendedJobs(obj, nav)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(RecommendedJobs));

