import Snackbar, { SnackbarOrigin } from "@mui/material/Snackbar";
import { useState } from "react";
import "./SnackbarReturnToSource.scss";
import Image from "react-bootstrap/Image";
import JobsIcon from "src/imagesV2/JobsIcon.svg";
import BackIcon from "src/imagesV2/BackIcon.svg";
import { useHistory } from "react-router-dom";
import SnackbarContent from "@mui/material/SnackbarContent";

interface SnackBarProps {
  message: string;
  topRight?: boolean;
  source: string;
  image: string
}

function SnackbarReturnToSource({ message, topRight, source, image }: SnackBarProps) {
  const anchorOrigin: SnackbarOrigin = {
    vertical: "top",
    horizontal: topRight ? "right" : "center",
  };
  const [isOpen] = useState(true);
  const history = useHistory(); 
  
  const toasterContent = (
    <div className="contentWrapper">
      <div className="contentWrapperIcon">
        <Image src={BackIcon} />
        <Image src={JobsIcon} className = "jobDetailIcon"/>
      </div>
      <div className="contentWrapperText">
        {message}
      </div>
      <div className="contentWrapperImage">
        <Image src={image}/>
      </div>
    </div>
  );

  const { vertical, horizontal } = anchorOrigin;
  
  const handleClick = () => {
    history.push(source)  
  }

  return (
    <div aria-hidden className="snackbarWrapper" onClick={handleClick}>
      <Snackbar
        anchorOrigin={anchorOrigin}
        open={isOpen}
        key={vertical + horizontal}
      >
        <SnackbarContent
          message={
          <span >
            {toasterContent}
          </span>
          }
        /> 
      </Snackbar>
      
    </div>
  );
}

export default SnackbarReturnToSource;

SnackbarReturnToSource.defaultProps = {
  topRight: false,
};
