import config from "src/config";

function validateAndCreateRegExp(pattern) {
  try {
    // eslint-disable-next-line security/detect-non-literal-regexp
    return new RegExp(pattern);
  } catch (e) {
    console.error(`Invalid regex pattern: ${pattern}`, e);
    return null;
  }
}

export default function getCompanyFromDomain() {
  const ccnCompany = config.COMPANY_CCN;
  const ccaCompany = config.COMPANY_CCA;

  const ccnMatcher = validateAndCreateRegExp(config.CCN_URL_REGEX);
  const ccnScoutProdUrl = config.CCN_PROD_URL;
  const ccaMatcher = validateAndCreateRegExp(config.CCA_URL_REGEX);
  const ccaScoutProdUrl = config.CCA_PROD_URL;

  let company = ccnCompany;

  if (
    !ccnMatcher ||
    !ccnScoutProdUrl ||
    !ccaMatcher ||
    !ccaScoutProdUrl
  ) {
    console.error("One or more invalid regex patterns found in config");
  } else {
    const baseURI = document.location.origin;

    if (ccnMatcher.test(baseURI) || ccnScoutProdUrl === baseURI) {
      company = ccnCompany;
    }

    if (ccaMatcher.test(baseURI) || ccaScoutProdUrl === baseURI) {
      company = ccaCompany;
    }
  }

  return company;
}