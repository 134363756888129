import React, { Component } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Button from "@material-ui/core/Button";
import { connect } from "react-redux";
import Link from "@material-ui/core/Link";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { isMobile } from "react-device-detect";
import config from "src/config";
import { LOGIN_HELP_EMAIL, CUSTOMER_SERVICE_PHONE } from "src/constants/contact_info";
import { BASIC_INFO_PAGE, SEARCH } from "src/router/routes";
import { updateActiveFlag } from "src/redux/onBoarding/action";
import { logout, changeIsFirstLoginFlag } from "src/redux/auth/action";
import Analytics from "src/services/Analytics";
import {
  fetchRecommendedJobs,
  updateNavigationKey,
} from "src/redux/jobs/SearchCrossCountry/action";
import Logo from "src/components/common/Header/Logo";
import getCandidateInformation from "src/components/common/CandidateInformation/CandidateInformation";

class OnBoardingWelcome extends Component {
  constructor(props) {
    super(props);
    localStorage.removeItem("completedSteps");
    const { referrer } = document;

    if (
      localStorage.getItem("historyPathName") &&
      localStorage.getItem("historyPathName").includes("/auth/relay")
    )
      this.triggerRecruitics();
  }

  handleClickNavigateBasicInfo() {
    const event = {
      category: this.props.t("googleAnalytics.category.onboarding"),
      action: this.props.t("googleAnalytics.action.startApplication"),
      label: this.props.t("googleAnalytics.label.application"),
    };
    this.trackEvent(event, this.props);
    this.props.changeIsFirstLoginFlag();
    this.props.history.push(BASIC_INFO_PAGE);
  }

  handleClickNavigateJobSearch() {
    this.props.changeIsFirstLoginFlag();
    const { onboardingInfo } = this.props;
    const candidateInformation = getCandidateInformation(onboardingInfo);
    const data = {
      sort: "relevant",
      companyId: this.props.companyCode.id,
      size: config.JOB_COUNT,
      candidateId: this.props.atsBullhornCandidateId || this.props.bhId,
      candidateInformation,
    };
    this.props.fetchRecommendedJobs(data);
  }

  trackEvent(event, props) {
    Analytics.sendPageview(props.history.location, null, true);
    Analytics.sendEvent(event);
  }

  componentWillMount() {
    window.scrollTo(0, 0);
  }

  //Trigger recruitics for WCM new user job apply
  triggerRecruitics() {
    if (sessionStorage.getItem("isRecruiticsCalled") === null) {
      sessionStorage.setItem("isRecruiticsCalled", true);
      setTimeout(() => {
        window.rx.trigger({
          triggerName: "apply",
          applyFlowId: window.rx.getViewerId(),
        });
      }, 10000);
    }
  }

  render() {
    return (
      <div>
        {this.props.isFirstLogin ? (
          <Container fluid className="onBfixHeader">
            <Container>
              <Row className="pt-3 pb-3">
                <Col sm={1} xs={0} md={1} lg={1} />
                <Col sm={2} xs={0} md={2} lg={2}>
                  <div>
                    <Logo />
                  </div>
                </Col>
                <Col sm={9} xs={0} md={9} lg={9} />
              </Row>
            </Container>
          </Container>
        ) : (
          <></>
        )}
        <Container fluid className="onBBlueBackgLetsGo">
          <Container className="onBMainTxtField">
            <Row>
              <Col sm={12} xs={12} md={1} lg={1} />
              <Col sm={12} xs={12} md={10} lg={10}>
                <div className="onBMainLetsGo">
                  <Row>
                    <Col sm={12} xs={12} md={7} lg={7} className="onbWelcometxt">
                      <div className="onBLetsGoTitle textLeft">
                        {this.props.t("onBoarding.onBWelcomeTxt")}&nbsp;
                        {this.props.firstName}!
                      </div>
                      <div className="onBLetsGoDesc">
                        {this.props.t("onBoarding.onBWelcomeTxt1")}
                      </div>
                      <div className="onBLetsGoBtn onBcompleteSkillsBtn">
                        <Button onClick={this.handleClickNavigateBasicInfo.bind(this)}>
                          {this.props.t("onBoarding.getStartedBtn")}
                        </Button>
                      </div>
                      <div className="onBSkillNoThanksBtn">
                        <Button
                          className="onBNoThanksBtn"
                          onClick={this.handleClickNavigateJobSearch.bind(this)}
                        >
                          {this.props.t("onBoarding.OnBGoToSearchJobs")}
                        </Button>
                      </div>
                    </Col>
                    <Col sm={12} xs={12} md={5} lg={5}>
                      <div className="onBLetsgoImg" />
                    </Col>
                    {isMobile ? (
                      <Container>
                        <Row className="getStartFooter">
                          <Col sm={10} xs={9} md={10} lg={10}>
                            {this.props.t("footer.needHelp.needHelpEmail")}
                            <span className="iframePhNo">
                              &nbsp;
                              <Link href={`tel:${CUSTOMER_SERVICE_PHONE}`}>
                                {this.props.t("footer.needHelp.iFrameFooterLogout2")}
                              </Link>
                              &nbsp;
                              {this.props.t("footer.needHelp.iFrameFooterLogout1")}
                              &nbsp;
                            </span>
                            <span className="iframePhNo">
                              <Link
                                href={`mailto:${LOGIN_HELP_EMAIL} ?subject=${this.props.t(
                                  "footer.needHelp.iFrameFooterEmailsubject"
                                )}`}
                              >
                                {this.props.t("footer.needHelp.iFrameFooterLogout3")}
                              </Link>
                              {this.props.t("footer.needHelp.iFrameFooterLogout5")}
                            </span>{" "}
                          </Col>
                        </Row>
                      </Container>
                    ) : (
                      <Container>
                        <Row className="getStartFooter">
                          <Col sm={10} xs={9} md={10} lg={10}>
                            {this.props.t("footer.needHelp.needHelpEmail")}
                            &nbsp;
                            <span className="iframePhNo">
                              {this.props.t("footer.needHelp.iFrameFooterLogout2")}
                              &nbsp;
                              <span className="iframePhNo ">
                                <Link href={`tel:${CUSTOMER_SERVICE_PHONE}`}>
                                  {CUSTOMER_SERVICE_PHONE}
                                </Link>
                                &nbsp;
                                {this.props.t("footer.needHelp.iFrameFooterLogout1")}{" "}
                              </span>
                              {this.props.t("footer.needHelp.iFrameFooterLogout4")}{" "}
                              <span className="onBGetStartEmail">
                                <Link
                                  href={`mailto:${LOGIN_HELP_EMAIL}?subject=${this.props.t(
                                    "footer.needHelp.iFrameFooterEmailsubject"
                                  )}`}
                                >
                                  {LOGIN_HELP_EMAIL}
                                </Link>
                              </span>
                              {this.props.t("footer.needHelp.iFrameFooterLogout5")}
                            </span>
                          </Col>
                        </Row>
                      </Container>
                    )}
                  </Row>
                </div>
              </Col>
              <Col sm={12} xs={12} md={1} lg={1} />
            </Row>
          </Container>
        </Container>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  onBoardingNavigation: state.navigation.onBoardingNavigation,
  firstName: state.auth.firstName,
  companyDetails: state.company.company,
  onboardingInformation: state.onBoarding.onboardingInformation,
  email: state.auth.email,
  userId: state.auth.userId,
  applicantId: state.candidate.applicantId,
  isFirstLogin: state.auth.isFirstLogin,
  companyCode: state.company.company,
  atsBullhornCandidateId: state.candidate.atsBullhornCandidateId,
  onboardingInfo: state.onBoarding,
  bhId: state.candidate.bullhornIdFromBullhorn,
});

const mapDispatchToProps = (dispatch) => ({
  updateActiveFlag: (activeSteps) => dispatch(updateActiveFlag(activeSteps)),
  logout: (email) => dispatch(logout(email)),
  changeIsFirstLoginFlag: () => dispatch(changeIsFirstLoginFlag()),
  fetchRecommendedJobs: (obj, nav) => dispatch(fetchRecommendedJobs(obj, nav)),
  updateNavigationKey: (obj) => dispatch(updateNavigationKey(obj)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withRouter(OnBoardingWelcome)));
