/* eslint-disable react/jsx-props-no-spreading */
import { ReactNode } from "react";
import clsx from "clsx";
import WhatshotIcon from "@mui/icons-material/Whatshot";
import styles from "./JobCardChip.module.scss";

export interface JobCardChipProps {
  type: "exclusive" | "hot-job";
  size?: "normal" | "compact";
  className?: string;
}

const JobCardChip = ({
  type,
  size,
  className
}: JobCardChipProps) => {
  let label: ReactNode;
  let typeClassName: string;

  switch (type) {
    case "exclusive":
      label = "Exclusive";
      typeClassName = styles.exclusive;
      break;
    case "hot-job":
      label = (<div className={styles.hotJobLabel}>
        <WhatshotIcon />
        <span>Hot Job</span>
      </div>);
      typeClassName = styles.hotJob;
      break;
    default:
      label = "";
      typeClassName = "";
      break;
  }

  return (
    <div className={clsx(
      styles.main,
      size === "compact" && styles.compact,
      typeClassName,
      className
    )}>
      {label}
    </div>
  );
}

JobCardChip.defaultProps = {
  size: "normal",
  className: ""
};

export default JobCardChip;
