import React, { Component } from "react";
import Row from "react-bootstrap/Row";
import Link from "@material-ui/core/Link";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import { withTranslation } from "react-i18next";
import {
  DIRECT_DEPOSIT_FORM_DOCUMENT,
  ONBOARDING_PAYMENT_DOCUMENT,
  REIMBURSEMENT_REQUEST_DOCUMENT,
  SAN_FRANCISCO_SICK_PAY_NOTICE_DOCUMENT,
  SAN_FRANCISCO_MINIMUM_WAGE_DOCUMENT,
  PORTLAND_SICK_PAY_NOTICE_DOCUMENT,
  NEW_JERSEY_SICK_PAY_NOTICE_DOCUMENT,
  CALIFORNIA_PAID_SICK_NOTICE_DOCUMENT,
  EARNED_SICK_TIME_NOTICE_DOCUMENT,
  EZSTUBS_URL,
  CERIDIAN_URL
} from "../../../../../constants/external_links";
import PaycheckIcon from "src/imagesV2/paycheck-icon.png";
import payCheckMoneyIcon from "src/imagesV2/pay-check-money-icon.png";
import { PrimaryButton } from "src/components/common/Buttons/index";
import config from "src/config.js";
import Analytics from "src/services/Analytics";

const enableW2Ceridian = config.ENABLE_W2_CERIDIAN === "true";

const theme1 = createTheme({
  typography: {
    fontFamily: ["Nunito Sans", "Helvetica", "Arial", "sans-serif"].join(","),
  },
  overrides: {
    MuiOutlinedInput: {
      root: {
        "& $notchedOutline": {
          borderColor: "#e1e1e1",
        },
        "&:hover $notchedOutline": {
          borderColor: "#e1e1e1",
        },
        "&$focused $notchedOutline": {
          borderColor: "#e1e1e1",
        },
      },
      focused: {},
      notchedOutline: {},
    },
  },
});

class PayrollNotice extends Component {
  state = {
    selectedNotice: 0,
    notices: [
      ONBOARDING_PAYMENT_DOCUMENT,
      REIMBURSEMENT_REQUEST_DOCUMENT,
      SAN_FRANCISCO_SICK_PAY_NOTICE_DOCUMENT,
      SAN_FRANCISCO_MINIMUM_WAGE_DOCUMENT,
      PORTLAND_SICK_PAY_NOTICE_DOCUMENT,
      NEW_JERSEY_SICK_PAY_NOTICE_DOCUMENT,
      CALIFORNIA_PAID_SICK_NOTICE_DOCUMENT,
      EARNED_SICK_TIME_NOTICE_DOCUMENT,
    ],
  };

  handleChange(event) {
    this.setState({
      selectedNotice: event.target.value,
    });
  }

  trackClickEvent() {
    const eventName = this.props.t("payrollCeridian.ceridianTrackEvent");
    Analytics.segmentTrackEvent(eventName, null);
  }


  render() {
    const downloadNotice = () => {
      window.open(this.state.notices[this.state.selectedNotice], "_blank");
    };
    return (
      <>
        <div className="payrollNoticeWrap">
          <div className="directDeposit">
            <span> {this.props.t("payrollNotice.payrollnotices")}</span>
            <h5> {this.props.t("payrollNotice.payrollwagepay")}</h5>
            <FormControl className="dateDropDown">
              <MuiThemeProvider theme={theme1}>
                <Select
                  value={this.state.selectedNotice}
                  variant="outlined"
                  className="selectDate"
                  onChange={this.handleChange.bind(this)}
                >
                  <MenuItem value={0}>{this.props.t("payrollNotice.payrollwagepayopt1")}</MenuItem>
                  <MenuItem value={1}>{this.props.t("payrollNotice.payrollwagepayopt2")}</MenuItem>
                  <MenuItem value={2}>{this.props.t("payrollNotice.payrollwagepayopt3")}</MenuItem>
                  <MenuItem value={3}>{this.props.t("payrollNotice.payrollwagepayopt4")}</MenuItem>
                  <MenuItem value={4}>{this.props.t("payrollNotice.payrollwagepayopt5")}</MenuItem>
                  <MenuItem value={5}>{this.props.t("payrollNotice.payrollwagepayopt6")}</MenuItem>
                  <MenuItem value={6}>{this.props.t("payrollNotice.payrollwagepayopt7")}</MenuItem>
                  <MenuItem value={7}>{this.props.t("payrollNotice.payrollwagepayopt8")}</MenuItem>
                </Select>
              </MuiThemeProvider>
            </FormControl>
            <Link onClick={downloadNotice} className="viewPaystub">
              {this.props.t("payrollNotice.paydownloadnotice")}
            </Link>
          </div>
          <div className="directDeposit">
            <span>{this.props.t("payrollNoticeV2.payrollPayStubs")}</span>
            <h5>{this.props.t("payrollNoticeV2.payrollEZStubs")}</h5>
            <p>{this.props.t("payrollNoticeV2.payrollEZStubsText")} </p>
            <Link href={EZSTUBS_URL} target="_blank" className="viewPaystub">
              {this.props.t("payrollNoticeV2.payrollEZStubLabel")}
            </Link>
          </div>
          {enableW2Ceridian && (
            <div className="directDeposit">
              <span>{this.props.t("payrollCeridian.payrollW2")}</span>
              <h5>{this.props.t("payrollCeridian.payrollCeridian")}</h5>
              <p>{this.props.t("payrollCeridian.payrollCeridianText")} </p>
              <Link
                href={CERIDIAN_URL}
                target="_blank"
                className="viewPaystub"
                onClick={this.trackClickEvent.bind(this)}
              >
                {this.props.t("payrollCeridian.payrollCeridianLabel")}
              </Link>
            </div>
          )}
        </div>
      </>
    );
  }
}

export default withTranslation()(PayrollNotice);
