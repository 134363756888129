import Image from "react-bootstrap/Image";
import { withTranslation, useTranslation } from "react-i18next";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@material-ui/core/Button";
import { useMutation } from "@apollo/client";
import { useState } from "react";
import { RESEND_EMAIL } from "src/services/GraphQL/graph.query";
import Logo from "src/components/common/Header/Logo";
import styles from "./EmailVerificationModal.module.scss";
import EmailVerificationIcon from "./EmailVerificationIcon.svg";

interface IProps {
  location: {
    pathname: string;
    state: {
      candidateSetObject: any;
    };
  };
}

const SignUpEmailVerificationModal = ({ location }: IProps) => {
  const { t } = useTranslation();
  const [successfulMsg, setSuccessfulMsg] = useState(false);
  const [resendEmail, { data: resendEmailResponse, loading: resendEmailResponseLoading }] =
    useMutation(RESEND_EMAIL);
  const handleResendEmail = async () => {
    try {
      const candidateSetObject = location?.state?.candidateSetObject;
      if (candidateSetObject) {
        const resendEmailObject = {
          firstName: candidateSetObject?.firstName,
          email: candidateSetObject?.email,
          companyId: candidateSetObject?.company === "cca" ? "2" : "1",
          specialtyDesc: candidateSetObject?.specialtyDesc
            ? candidateSetObject?.specialtyDesc
            : undefined,
          professionDesc: candidateSetObject?.professionDesc
            ? candidateSetObject?.professionDesc
            : undefined,
        };
        await resendEmail({
          variables: { emailVerification: resendEmailObject },
        });
        setSuccessfulMsg(false);
        setTimeout(() => {
          setSuccessfulMsg(true);
        }, 3000);
      }
    } catch (error) {
      console.error("Error while re-sending verification mail:", error);
    }
  };

  return (
    <div className="fullCoverBackground">
      <Container fluid>
        <Row>
          <div className={styles.signupModalMargin} />
        </Row>
        <Row>
          <Col xs={12} sm={12} md={5} lg={5} className="pb-3 pt-3" />
          <Col xs={12} sm={12} md={6} lg={6} className="pb-3 pt-3">
            <Row className={styles.loginFormV2}>
              <Col xs={12} sm={12} md={5} lg={5} />
              <Col xs={12} sm={12} md={7} lg={7} className="pb-3 pt-3">
                <div className={styles.signupModalTextWrap}>
                  <Logo className="signupLogo" />
                  <div>
                    <p className={styles.emailVerificationHead}>
                      {t("emailVerification.headTextGeneric")}
                    </p>
                    <p className={styles.emailVerificationText}>
                      {t("emailVerification.bodyTextGeneric")}
                    </p>
                    <div className={styles.emailIcon}>
                      <Image
                        src={EmailVerificationIcon}
                        width="140px"
                        height="46px"
                        alt="Cross Country Healthcare Logo"
                        tabIndex={0}
                        role="presentation"
                      />
                    </div>
                    <div className={styles.emailVerificationSubText}>
                      <div>
                        <div className={styles.mailNotFoundMsg}>
                          {t("verifyEmailExpired.mailNotFoundMsg")}
                        </div>
                        <div className={styles.spamMsg}>{t("verifyEmailExpired.spamCheckMsg")}</div>
                        <div className={styles.emailVerificationSubText}>
                          {!resendEmailResponse?.resendEmailVerification?.success ||
                          successfulMsg ? (
                            <Button onClick={handleResendEmail} className={styles.resendEmail}>
                              <span className={styles.resendEmailText}>
                                {t("emailVerification.resendEmail")}
                              </span>
                              {resendEmailResponseLoading ? (
                                <CircularProgress
                                  size={14}
                                  className={styles.loaderResendBtn}
                                  thickness={5.5}
                                />
                              ) : null}
                            </Button>
                          ) : (
                            <p className="sentSuccessfully">
                              {t("emailVerification.sentSuccessfully")}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col xs={12} sm={12} />
            </Row>
          </Col>
          <Col xs={12} sm={12} md={1} lg={1} className="pb-3 pt-3" />
        </Row>
      </Container>
    </div>
  );
};

export default withTranslation()(SignUpEmailVerificationModal);
