export const ReferencesStates = {
  PENDING: "Pending",
  INPROGRESS: "In Progress",
  COMPLETE: "Complete",
  DECLINED: "Declined",
  EXPIRED: "Expired",
};
export const ReferencesStatesValues = Object.values(ReferencesStates);
Object.freeze(ReferencesStates);

export const APIMethods = {
  ADD: "add",
  EDIT: "edit",
  DELETE: "delete",
};
export const APIMethodsValues = Object.values(APIMethods);
Object.freeze(APIMethods);
