import { Component } from "react";
import React from "react";
import TextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Paper } from "@material-ui/core";
import Chip from "@mui/material/Chip";
import { isMobile } from "react-device-detect";
import { withTranslation } from "react-i18next";
import PinDropIcon from "@material-ui/icons/PinDrop";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { fetchLocation, detectLocationInputClick } from "src/redux/globalSearch/action";
import { locationAutosuggest, LocationBoxProps, LocationBoxState } from ".";
import FilterList from "./FilterList";
import SelectedList from "./SelectedList";
import config from "src/config.js";
import { Autocomplete } from "@mui/material";
import RoomOutlinedIcon from "@material-ui/icons/RoomOutlined";
import queryString from "query-string";
import clsx from "clsx";

let placeHolderNewText = "";

interface cityStateProps {
  city: string | null;
  state: string | null;
  stateCode: string | null;
  plainState: string | null;
  plainStateCode: string | null;
  latitude: number | null;
  longitude: number | null;
  cityStateCombination: string | null;
}

const styles = () => ({
  root: {
    padding: "2px 0px",
    display: "flex",
    alignItems: "center",
    overflow: "auto",
  },
  input: {
    marginLeft: "0px",
    flex: 3,
    fontSize: "1em",
  },
  iconButton: {
    padding: 10,
  },
  card: {
    backgroundColor: "#f5f5f5",
    display: "block",
    height: "200px",
    width: "203px",
  },
});

const enableJobLocationSearch = config.ENABLE_SEARCH_JOB_LOCATION === "true";

class LocationBoxV2 extends Component<
  LocationBoxProps & RouteComponentProps,
  LocationBoxState,
  cityStateProps
> {
  constructor(props: LocationBoxProps & RouteComponentProps) {
    super(props);
    const { defaultLocation } = this.props;
    this.setSelectedLocations();
    const sessionStateCities = JSON.parse(sessionStorage.getItem("showSelectedItems") as string);
    const sessionStates = JSON.parse(sessionStorage.getItem("selectedItems") as string);
    const sessionCites = JSON.parse(sessionStorage.getItem("citySelelctedItems") as string);

    const sessionStateCitiesMapped =
      sessionStateCities &&
      sessionStateCities.length > 0 &&
      sessionStateCities.map((item) => this.fetchIndividualLocation(item));

    const getSessionStateCities = sessionStateCities ? sessionStateCitiesMapped : [];

    this.state = {
      openPopup: false,
      openOverlay: false,
      isZipSearch: false,
      isKeyboardOpen: false,
      scrollY: 0,
      location: null,
      value: enableJobLocationSearch ? "" : (this.getDefaultLocation(defaultLocation) as string),
      showSelectedItems: enableJobLocationSearch ? getSessionStateCities : [],
      selectedItems: enableJobLocationSearch ? sessionStates || [] : [],
      citySelelctedItems: enableJobLocationSearch ? sessionCites || [] : [],
      overlayRef: React.createRef(),
      textInputRef: React.createRef(),
      compactStateCheckValue: this.getDefaultLocation(defaultLocation)[0] as boolean,
      isCompactStateDeleted: this.getDefaultLocation(defaultLocation)[1] as boolean,
    };
  }

  fetchCompactStates = async () => {
    const compactStateURL = config.COMPACT_STATES_URL;

    try {
      const response = await fetch(compactStateURL as string);
      response.json().then((res) => {
        sessionStorage.setItem("compactStates", JSON.stringify(res.Items));
      });
    } catch (error) {
      console.error(error);
    }
  };

  componentDidMount() {
    const { scrollY } = this.state;
    const { company } = this.props;
    const isUserType = company.code === "ccn" ? true : false;
    if (enableJobLocationSearch) {
      this.updatingTheStateOnRefresh();
      this.fetchCompactStates();
      if(isUserType){
        this.checkForCompactStates();
      }
    }
    window.addEventListener("scroll", this.handleScroll.bind(this, scrollY));
    document.addEventListener("mousedown", this.handleClickOutside);
    window.addEventListener("resize", this.handleKeyboardOpen);
    window.addEventListener("focusin", this.handleKeyboardOpen);
    window.addEventListener("focusout", this.handleKeyboardClose);
    window.addEventListener("beforeunload", this.handleBeforeUnload);
  }

  setSelectedLocations() {
    const searchParam = this.props.history.location.search;
    const queryStringValues = searchParam ? queryString.parse(searchParam) : {};
    const location = queryStringValues?.location;
    const locationObject = location ? JSON.parse(location as string) : {};
    const cityArr = locationObject?.city?.split(",");
    const cityStatesArr = locationObject?.cityStates?.split(",");
    const cityStateCodesArr = locationObject?.cityStateCodes?.split(",");
    const statesArr = locationObject?.states?.split(",");
    const plainStateArr = locationObject?.plainState?.split(",");
    const latitudesArr = locationObject?.latitudes?.split(",");
    const longitudesArr = locationObject?.longitudes?.split(",");
    // added below fields to get data of existing saves searches
    const stateArr = locationObject?.state?.split(",");
    const latitudeArr = locationObject?.latitude?.toString()?.split(",");
    const longitudeArr = locationObject?.longitude?.toString()?.split(",");

    let citySelected: cityStateProps[] = [];
    let stateSelected: cityStateProps[] = [];
    let cityStateSelected: cityStateProps[] = [];
    if (cityArr && cityArr.length > 0) {
      cityArr?.forEach((city, index) => {
        let obj: cityStateProps = {
          city: null,
          state: null,
          stateCode: null,
          plainState: null,
          plainStateCode: null,
          latitude: null,
          longitude: null,
          cityStateCombination: null,
        };

        obj = {
          city: city,
          state: cityStatesArr?.length > 0 ? cityStatesArr[index] : null,
          stateCode:
            cityStateCodesArr?.length > 0
              ? cityStateCodesArr[index]
              : stateArr?.length > 0
              ? stateArr[index]
              : null,
          latitude:
            latitudesArr?.length > 0
              ? latitudesArr[index]
              : latitudeArr?.length > 0
              ? latitudeArr[index]
              : null,
          longitude:
            longitudesArr?.length > 0
              ? longitudesArr[index]
              : longitudeArr?.length > 0
              ? longitudeArr[index]
              : null,
          cityStateCombination:
            city && cityStatesArr?.length > 0
              ? (city + ", " + cityStatesArr[index])?.toLowerCase()
              : city && stateArr?.length > 0
              ? (city + ", " + stateArr[index])?.toLowerCase()
              : null,
          plainState: null,
          plainStateCode: null,
        };

        citySelected.push(obj);
        cityStateSelected.push(obj);
      });
    }

    if (statesArr && statesArr.length > 0) {
      statesArr?.forEach((state, index) => {
        let obj: cityStateProps = {
          city: null,
          state: null,
          stateCode: null,
          plainState: null,
          plainStateCode: null,
          latitude: null,
          longitude: null,
          cityStateCombination: null,
        };

        obj = {
          cityStateCombination:
            state && plainStateArr?.length > 0
              ? (state + ", " + plainStateArr[index])?.toLowerCase()
              : null,
          plainState: plainStateArr?.length > 0 ? plainStateArr[index] : null,
          plainStateCode: state,
          city: null,
          state: null,
          stateCode: null,
          latitude: null,
          longitude: null,
        };
        stateSelected.push(obj);
        cityStateSelected.push(obj);
      });
    }

    if ((!cityArr || cityArr?.length === 0) && stateArr && stateArr.length > 0) {
      stateArr?.forEach((state, index) => {
        let obj: cityStateProps = {
          city: null,
          state: null,
          stateCode: null,
          plainState: null,
          plainStateCode: null,
          latitude: null,
          longitude: null,
          cityStateCombination: null,
        };

        obj = {
          cityStateCombination:
            state && plainStateArr?.length > 0
              ? (state + ", " + plainStateArr[index])?.toLowerCase()
              : null,
          plainState: plainStateArr?.length > 0 ? plainStateArr[index] : null,
          plainStateCode: state,
          city: null,
          state: null,
          stateCode: null,
          latitude: null,
          longitude: null,
        };
        stateSelected.push(obj);
        cityStateSelected.push(obj);
      });
    }
    let sortedLocationAlphabetically: cityStateProps[] = [];
    sortedLocationAlphabetically = cityStateSelected
    if(!cityStateSelected.some((i) => i.cityStateCombination === null)) {
      sortedLocationAlphabetically = cityStateSelected.sort((a, b) => 
      a.cityStateCombination!.localeCompare(b.cityStateCombination!)
      )
    }
    sessionStorage.setItem("showSelectedItems", JSON.stringify(sortedLocationAlphabetically));
    sessionStorage.setItem("selectedItems", JSON.stringify(stateSelected));
    sessionStorage.setItem("citySelelctedItems", JSON.stringify(citySelected));
  }

  checkForCompactStates = () => {
    const compactStates = sessionStorage.getItem("compactStates") as any;
          this.setState((prevState) => {
        const selected = [...prevState.selectedItems];
        JSON.parse(compactStates)?.forEach((state) => {
          const indexValue = selected.findIndex((item) => item.plainStateCode === state.code.S);
          if (indexValue > -1) {
            selected.splice(indexValue, 1);
            const cityState = (state.code.S + ", " + state.name.S)?.toLowerCase();
            selected.push({
              plainStateCode: state.code.S,
              plainState: state.name.S,
              cityStateCombination: cityState,
              isCompactState: true,
            });
          }
        });
        return {
          selectedItems: selected
            .sort((a, b) => {
              return this.sortByState(a, b, "state");
            })
        };
      });
      };

  async updatingTheStateOnRefresh() {
    const sessionStateCities = JSON.parse(sessionStorage.getItem("showSelectedItems") as string);
    const sessionStates = JSON.parse(sessionStorage.getItem("selectedItems") as string);
    const sessionCites = JSON.parse(sessionStorage.getItem("citySelelctedItems") as string);

    const sessionStateCitiesMapped =
      sessionStateCities &&
      sessionStateCities.length > 0 &&
      sessionStateCities.map((item) => this.fetchIndividualLocation(item));

    const getSessionStateCities = sessionStateCities ? sessionStateCitiesMapped : [];

    this.setState({
      showSelectedItems: enableJobLocationSearch ? getSessionStateCities : [],
      selectedItems: enableJobLocationSearch ? sessionStates || [] : [],
      citySelelctedItems: enableJobLocationSearch ? sessionCites || [] : [],
    });
  }

  async componentWillReceiveProps(nextProps) {
    if (this.props.defaultLocation !== nextProps.defaultLocation) {
      this.setState({
        ...this.state,
        value: enableJobLocationSearch
          ? ""
          : (this.getDefaultLocation(nextProps.defaultLocation) as string),
        compactStateCheckValue: this.getDefaultLocation(nextProps.defaultLocation)[0] as boolean,
        isCompactStateDeleted: this.getDefaultLocation(nextProps.defaultLocation)[1] as boolean,
      });
      if (enableJobLocationSearch) {
        const { company } = this.props;
        const isUserType = company.code === "ccn" ? true : false;
        this.fetchCompactStates();
        this.setSelectedLocations();
        await this.updatingTheStateOnRefresh();
        if(isUserType) {
          this.checkForCompactStates();
        }
        const {selectedItems, citySelelctedItems} = this.state;
        this.normalizeSearchObject(
          [...selectedItems, ...citySelelctedItems],
          selectedItems,
          citySelelctedItems
        );
      }
    }
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
    window.removeEventListener("resize", this.handleKeyboardOpen);
    window.removeEventListener("focusin", this.handleKeyboardOpen);
    window.removeEventListener("focusout", this.handleKeyboardClose);
    this.updatingTheStateOnRefresh();
    window.removeEventListener("beforeunload", this.handleBeforeUnload);
    if (this.props.history?.location?.pathname !== "/jobs/results") {
      this.handleBeforeUnload();
    }
  }

  handleClickOutside = (event) => {
    const { overlayRef, selectedItems, citySelelctedItems, textInputRef, scrollY } = this.state;
    const { t, componentName } = this.props;
    isMobile &&
      componentName === t("globalSearch.location.componentMyJobs") &&
      window.scrollTo(0, scrollY === 800 ? 0 : scrollY);
    if (
      enableJobLocationSearch &&
      overlayRef.current &&
      !overlayRef.current.contains(event.target) &&
      (!textInputRef?.current || !textInputRef?.current?.contains(event.target))
    ) {
      // Click occurred outside the overlay, close it
      this.normalizeSearchObject(
        [...selectedItems, ...citySelelctedItems],
        selectedItems,
        citySelelctedItems
      );

      this.setState({ openOverlay: false, value: "" });
      isMobile && window.scrollTo(0, scrollY === 800 ? 0 : scrollY);
    }
  };

  normalizeSearchObject = (showSelectedItems, selectedItems, citySelelctedItems) => {
    const { setLocation } = this.props;
    const { compactStateCheckValue, isCompactStateDeleted } = this.state;
    const cities: string[] = [];
    const states: string[] = [];
    const plainStates: string[] = [];
    const latitudes: number[] = [];
    const longitudes: number[] = [];
    const cityStateCodes: string[] = [];
    const cityStates: string[] = [];

    showSelectedItems.forEach((value) => {
      const { plainState, plainStateCode, latitude, longitude, city, state, stateCode } = value;
      plainState === null && cities.push(city);
      plainState != null && states.push(plainStateCode);
      plainStates.push(plainState);
      plainState === null && latitudes.push(latitude);
      plainState === null && longitudes.push(longitude);
      plainState === null && state && cityStates.push(state);
      plainState === null && stateCode && cityStateCodes.push(stateCode);
    });
    const cityValue = cities.length > 0 ? cities.join(",") : null;
    const stateValue = states.length > 0 ? states.join(",") : null;
    const plainStateValue = plainStates.length > 0 ? plainStates?.filter(item => item !== null).join(",") : null;
    const latitudeValue = latitudes.length > 0 ? latitudes.join(",") : null;
    const longitudesValue = longitudes.length > 0 ? longitudes.join(",") : null;
    const cityStatesValue = cityStates?.length > 0 ? cityStates.join(",") : null;
    const cityStateCodesValue = cityStateCodes?.length > 0 ? cityStateCodes.join(",") : null;

    const obj = {
      city: cityValue?.endsWith(",") ? cityValue?.slice(0, -1) : cityValue,
      states: stateValue?.endsWith(",") ? stateValue?.slice(0, -1) : stateValue,
      plainState: plainStateValue?.endsWith(",") ? plainStateValue?.slice(0, -1) : plainStateValue,
      latitudes: latitudeValue?.endsWith(",") ? latitudeValue?.slice(0, -1) : latitudeValue,
      longitudes: longitudesValue?.endsWith(",") ? longitudesValue?.slice(0, -1) : longitudesValue,
      cityStateCodes: cityStateCodesValue?.endsWith(",")
        ? cityStateCodesValue?.slice(0, -1)
        : cityStateCodesValue,
      cityStates: cityStatesValue?.endsWith(",") ? cityStatesValue?.slice(0, -1) : cityStatesValue,
      compactStateCheckValue,
      isCompactStateDeleted,
    };
    sessionStorage.setItem("showSelectedItems", JSON.stringify(showSelectedItems));
    sessionStorage.setItem("selectedItems", JSON.stringify(selectedItems));
    sessionStorage.setItem("citySelelctedItems", JSON.stringify(citySelelctedItems));
    setLocation(obj);
  };

  handleKeyboardOpen = () => {
    this.setState({ isKeyboardOpen: true });
  };

  handleKeyboardClose = () => {
    this.setState({ isKeyboardOpen: false });
  };

  handleBeforeUnload = () => {
    // Deleting the value from sessionStorage
    if (this.props?.history?.location?.pathname !== "/jobs/results") {
      sessionStorage.removeItem("showSelectedItems");
      sessionStorage.removeItem("selectedItems");
      sessionStorage.removeItem("citySelelctedItems");
    }
  };
  calculateOffsetForMobile = () => {
    const { componentName, t ,enableDashboardAd} = this.props;
    const headerPageHeight = document.getElementById("fixHeader")?.clientHeight ?? 0;
    const bannerHeight = document.getElementById("bannerContainer")?.clientHeight ?? 0;
    const dashboardBannerPageHeight =
      document.getElementById("dashboard-banner")?.clientHeight ?? 0;
    const jobHeadingHeight = document.getElementById("job-search-heading")?.clientHeight ?? 0;

    const jobBanerHeight = document.getElementById("job-banner")?.clientHeight ?? 0;
    const locSpecHeight = document.getElementById("locSpec")?.clientHeight ?? 0;
    var dashboardPageOffset
    if(enableDashboardAd){
      dashboardPageOffset =Number(bannerHeight) + Number(headerPageHeight) -10
    }
    else{
      dashboardPageOffset =
      Number(dashboardBannerPageHeight) + Number(jobHeadingHeight) + 46 + 20;
    }
    
    const offsetByJobHeading = Number(jobHeadingHeight) === 72 ? 36 : 0;
    const myJobsPageOffset = Number(jobBanerHeight) - Number(locSpecHeight) + offsetByJobHeading;
    return componentName === t("globalSearch.location.componentDashboard")
      ? dashboardPageOffset
      : myJobsPageOffset + 20;
  };

  handleTextInputChange = (event) => {
    const { isKeyboardOpen } = this.state;

    const offset = this.calculateOffsetForMobile();

    if (isMobile && isKeyboardOpen) {
      window.scrollTo(0, offset);
      this.setState({ openOverlay: true });
    }
    if (enableJobLocationSearch) {
      const { getLocation } = this.props;
      getLocation(event.target.value);
    }

    this.setState({
      value: event.target.value,
    });
  };

  handleScroll(scrollY) {
    const scrollTop = window.scrollY;
    if (scrollY !== window.pageYOffset) {
      if (!isMobile) {
        const { detectTheLocationInputClick } = this.props;
        this.setState({ openPopup: false });
        this.setState((prevState) => ({ openOverlay: prevState.openOverlay }));
        detectTheLocationInputClick(false);
      } else {
        this.setState((prevState) => ({ openPopup: prevState.openPopup }));
        this.setState((prevState) => ({ openOverlay: prevState.openOverlay }));
        this.setState({ scrollY: scrollTop });
      }
    }
  }

  onFocus = (event) => {
    const { t, detectTheLocationInputClick, componentName } = this.props;
    const { showSelectedItems, openOverlay, isKeyboardOpen } = this.state;
    event.target.placeholder =
      showSelectedItems.length > 0 && !openOverlay
        ? ""
        : isMobile
        ? t("globalSearch.location.onFocusPlaceholderMobile")
        : t("globalSearch.location.onFocusPlaceholderText");
    detectTheLocationInputClick(true);
    const offset = this.calculateOffsetForMobile();
    if (isMobile && isKeyboardOpen) {
      window.scrollTo(0, offset);
    }
    this.setState({ openOverlay: true });
    if (isMobile && componentName === t("globalSearch.location.componentMyJobs")) {
      window.scrollTo(0, 0);
    }
  };

  getDefaultLocation = (location) => {
    if (location !== "{}") {
      const locationJSON = JSON.parse(location);
      if (locationJSON.city) {
        return enableJobLocationSearch
          ? [locationJSON.compactStateCheckValue, locationJSON.isCompactStateDeleted]
          : `${locationJSON.city}, ${locationJSON.state}`;
      }
      return enableJobLocationSearch
        ? [locationJSON.compactStateCheckValue, locationJSON.isCompactStateDeleted]
        : locationJSON.plainState
        ? `${locationJSON.plainState}`
        : "";
    }
    return "";
  };

  setValue = (value) => {
    this.setState({ value });

    if (enableJobLocationSearch) {
      const { getLocation } = this.props;

      getLocation(value);
    }
  };

  onChange = (_, value) => {
    let obj = {
      city: null,
      state: null,
      plainState: null,
      latitude: null,
      longitude: null,
    };
    let optionText = "";
    const { detectTheLocationInputClick, setLocation } = this.props;
    if (value) {
      const { plainState, plainStateCode, stateCode, latitude, longitude, city } = value;
      optionText = this.getOptionText(value);
      this.setState({ location: optionText });
      obj = {
        city: plainState !== null ? null : city,
        state: plainState !== null ? plainStateCode : stateCode,
        plainState,
        latitude,
        longitude,
      };
    }
    this.setState({ openPopup: false, value: optionText });
    detectTheLocationInputClick(true);
    setLocation(obj);
  };

  onInputChange = (_, value) => {
    const { locationAutosuggest: autoSuggestLocation, getLocation, setLocation } = this.props;
    const { location } = this.state;

    this.setState({
      location: typeof value === "string" ? value.replace(/(?=[#@%$^*()!])./g, "") : value,
    });
    if (value.length > 0) {
      this.setState({
        openPopup: true,
        isZipSearch: !Number.isNaN(value),
      });
      getLocation(value);
    } else this.setState({ openPopup: false });
    if (location && autoSuggestLocation.length > 0) {
      const { plainState, city, plainStateCode, stateCode, latitude, longitude } =
        autoSuggestLocation[0];
      const obj: locationAutosuggest = {
        city: plainState !== null ? null : city,
        state: plainState !== null ? plainStateCode : stateCode,
        plainState,
        latitude,
        longitude,
      };
      setLocation(obj);
    }
  };

  getOptionText(option) {
    if (option) {
      const { plainState, city, stateCode } = option;
      return plainState ?? `${city}${stateCode ? `, ${stateCode}` : ""}`;
    }
    const { locationAutosuggest: autoSuggestLocation } = this.props;
    if (autoSuggestLocation.length > 0) {
      const {
        plainState,
        city: autoSuggestedCity,
        stateCode,
        plainStateCode,
      } = autoSuggestLocation[0];
      const city = plainState !== null ? "" : autoSuggestedCity;
      let state = plainState !== null ? plainStateCode : stateCode;
      if (city) {
        state = plainState!;
        return state;
      }
      return `${city}, ${state}`;
    }
    return "";
  }

  renderOption = (option) => {
    const optionText = this.getOptionText(option);
    return (
      <div>
        <PinDropIcon fontSize="small" className="pinDropIcon" />
        <>{optionText}</>
      </div>
    );
  };

  renderTextField = (params) => {
    const { t, firstName } = this.props;
    const { value } = this.state;
    const placeholder = isMobile
      ? t("header.searchJobsTextMobile")
      : `${t("globalSearch.location.defaultPlaceholderText")} ${firstName ?? ""}?`;
    placeHolderNewText = placeholder;
    return (
      <TextField
        fullWidth
        InputProps={{
          disableUnderline: true,
          ...params.InputProps,
          inputProps: {
            ...params.inputProps,
            placeholder,
            maxLength: 50,
            value,
            onKeyPress: (event) => {
              return event.key === "Enter" && event.preventDefault();
            },
          },
        }}
        onChange={(event) => this.handleTextInputChange(event)}
        variant="standard"
        onClick={this.onFocus}
      />
    );
  };

  getOptionValue = () => {
    const { value } = this.state;
    const optionText = this.getOptionText(value);
    return optionText;
  };

  sortByState = (a, b, locType) => {
    const nameA = a[`${locType === "state" ? "plainState" : "city"}`].toUpperCase();
    const nameB = b[`${locType === "state" ? "plainState" : "city"}`].toUpperCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  };

  handleSelectItem = (item) => {
    const compactStates = sessionStorage.getItem("compactStates") as any;
    const { company } = this.props;
    const isUserType = company.code === "ccn" ? true : false;
    this.setState((prevState) => {
      const selected = [...prevState.selectedItems];
      const citySelected = [...prevState.citySelelctedItems];

      const compactStateIndex = JSON.parse(compactStates)?.findIndex((state) => {
        return state.code.S === item.plainStateCode;
      });

      if (compactStateIndex !== -1 && isUserType) {
        item = { ...item, isCompactState: true };
      }

      const index = item.plainState
        ? selected.findIndex(
            (loc: any) => loc.cityStateCombination === item.cityStateCombination || loc.plainStateCode === item.plainStateCode
          )
        : citySelected.findIndex((loc: any) => loc.cityStateCombination === item.cityStateCombination);

      if (index === -1) {
        item.plainState ? selected.push(item) : citySelected.push(item);
      }

      return {
        selectedItems: selected.sort((a, b) => {
          return this.sortByState(a, b, "state");
        }),
        citySelelctedItems: citySelected.sort((a, b) => {
          return this.sortByState(a, b, "city");
        }),
        showSelectedItems: [...selected, ...citySelected].map((item) =>
          this.fetchIndividualLocation(item)
        ),
      };
    });
  };

  fetchIndividualLocation = (item) => {
    return this.getOptionText(item);
  };

  handleDelete = (item, deleteType) => {
    const { compactStateCheckValue } = this.state;
    if (item.isCompactState && compactStateCheckValue) {
      this.setState({ isCompactStateDeleted: true });
    }

    this.setState((prevState) => {
      const selected = [...prevState.selectedItems];
      const citySelected = [...prevState.citySelelctedItems];

      let index = -1;

      if (!item.isCompactState) {
        index =
          deleteType === "state"
            ? selected.findIndex((loc: any) => loc.cityStateCombination === item.cityStateCombination)
            : citySelected.findIndex((loc: any) => loc.cityStateCombination === item.cityStateCombination);
      }

      if (item.isCompactState) {
        index = selected.findIndex((loc: any) => loc.plainState === item.plainState);
      }

      if (index !== -1) {
        deleteType === "state" ? selected.splice(index, 1) : citySelected.splice(index, 1);
      }

      return {
        selectedItems: selected,
        citySelelctedItems: citySelected,
        showSelectedItems: [...selected, ...citySelected].map((item) =>
          this.fetchIndividualLocation(item)
        ),
      };
    });
  };

  handleAllDelete = () => {
    const { componentName, t } = this.props;
    this.setState({ isCompactStateDeleted: false, compactStateCheckValue: false });
    this.setState({
      selectedItems: [],
      citySelelctedItems: [],
      showSelectedItems: [],
      compactStateCheckValue: false,
    });
    if (isMobile && componentName === t("globalSearch.location.componentMyJobs")) {
      window.scrollTo(0, 0);
    }
  };

  handleShowDelete = (item) => {
    const { showSelectedItems } = this.state;
    this.setState({ showSelectedItems: showSelectedItems.filter((loc) => loc !== item) });
  };

  isSelected = (item) => {
    const { selectedItems } = this.state;
    return selectedItems.includes(item.id);
  };

  handleClearFields = () => {
    this.setState({ showSelectedItems: [], selectedItems: [], citySelelctedItems: [] });
  };

  handleChipGridClick = () => {
    const { textInputRef } = this.state;
    this.setState((prevState) => ({
      openOverlay: !prevState.openOverlay,
    }));
    if (textInputRef.current) {
      textInputRef?.current?.focus();
    }
    const offset = this.calculateOffsetForMobile();
    isMobile && window.scrollTo(0, offset);
  };

  handleCompactStates = (checkValue) => {
    const compactStates = sessionStorage.getItem("compactStates") as any;
            this.setState((prevState) => {
          const selected = [...prevState.selectedItems];
          const citySelected = [...prevState.citySelelctedItems];

          if (checkValue) {
            JSON.parse(compactStates)?.forEach((state) => {
              const indexValue = selected.findIndex((item) => item.plainStateCode === state.code.S);
              if (indexValue > -1) {
                selected.splice(indexValue, 1);
              }
              const cityState = (state.code.S + ", " + state.name.S)?.toLowerCase();
              selected.push({
                plainStateCode: state.code.S,
                plainState: state.name.S,
                cityStateCombination: cityState,
                isCompactState: true,
              });
            });
          } else {
            this.setState({ isCompactStateDeleted: false });
            JSON.parse(compactStates)?.forEach((state) => {
              const index = selected.findIndex((item) => item.plainStateCode === state.code.S);
              if (index !== -1) {
                selected.splice(index, 1);
              }
            });
          }
          return {
            selectedItems: selected.sort((a, b) => {
              return this.sortByState(a, b, "state");
            }),
            compactStateCheckValue: checkValue,
            showSelectedItems: [...selected, ...citySelected].map((item) =>
              this.fetchIndividualLocation(item)
            ),
          };
      });
      };

  render() {
    const { locationAutosuggest: autoSuggestLocation, loading, classes, t: translate } = this.props;
    const { t, company } = this.props;
    const {
      value,
      openOverlay,
      selectedItems,
      overlayRef,
      showSelectedItems,
      citySelelctedItems,
      openPopup,
      location,
      textInputRef,
      compactStateCheckValue,
      isCompactStateDeleted,
    } = this.state;

    const { firstName,enableDashboardAd } = this.props;
    let placeholder = isMobile
      ? t("header.searchJobsTextMobile")
      : `${t("globalSearch.location.defaultPlaceholderText")} ${firstName ?? ""}?`;
    placeholder = showSelectedItems.length > 0 && !openOverlay ? "" : placeholder;
    placeHolderNewText = placeholder;

    const maxItemToShow = isMobile ? 1 : 2;

    let sortedLocationAlphabetically = [...selectedItems, ...citySelelctedItems]
    if(!sortedLocationAlphabetically.some((item) => item.cityStateCombination === null)) {
      sortedLocationAlphabetically = [...selectedItems, ...citySelelctedItems]
        .sort((a, b) => a.cityStateCombination!.localeCompare(b.cityStateCombination!))
        .slice(0, maxItemToShow)
    }
    const itemsToShow = showSelectedItems.length > 0 ? sortedLocationAlphabetically : [];
    const remainingItemsCount = showSelectedItems.length - maxItemToShow;
    const pixelArr = [isMobile ? 8 : 3, 6, 7];
    const pixelValue: any =
      showSelectedItems.length <= maxItemToShow
        ? pixelArr[showSelectedItems.length - 1]
        : isMobile
        ? 8
        : 7;

    const overlayClassGridOne = `${isMobile ? "" : "jobSearchSelectedListOverlay"}`;
    const overlayClassGridTwo = `${isMobile ? "jobSearchSelectedListOverlay" : ""}`;
    const isUserType = company.code === "ccn" ? true : false;

    return (
      <div className={clsx("JobSearchFormContainer",isMobile && enableDashboardAd && "JobSearchFormContainerBanner")}>
        <Paper component="form" className="classes.root loactionPaperMob" id="jobSearchMainV2">
          {enableJobLocationSearch && (
            <Grid className="jobSearchGridContainer" spacing={0}>
              {!openOverlay && showSelectedItems.length > 0 && (
                <Grid
                  item
                  xs={pixelValue}
                  sm={pixelValue}
                  md={pixelValue}
                  lg={pixelValue}
                  style={{ cursor: "auto" }}
                  onClick={this.handleChipGridClick}
                >
                  {!openOverlay &&
                    showSelectedItems.length > 0 &&
                    itemsToShow.length > 0 &&
                    itemsToShow.map((item, index) => (
                      <Chip
                        key={index}
                        label={
                          item.isCompactState
                            ? `${this.fetchIndividualLocation(item)} (CS)`
                            : this.fetchIndividualLocation(item)
                        }
                        sx={
                          item.isCompactState
                            ? {
                                m: "7px",
                                bgcolor: "#6780A6",
                                "& .MuiChip-label": {
                                  color: "#ffffff !important",
                                },
                                "& .MuiChip-deleteIcon": {
                                  color: "#ffffff !important",
                                },
                                height: "25px",
                                marginLeft: "-2px",
                                marginRight: "9px",
                                fontSize: "12px",
                                fontFamily: "Nunito Sans",
                              }
                            : {
                                m: "7px",
                                bgcolor: "#E8ECF3",
                                "& .MuiChip-deleteIcon": {
                                  color: "#30333A !important",
                                },
                                height: "25px",
                                marginLeft: "-2px",
                                marginRight: "9px",
                                fontSize: "12px",
                                fontFamily: "Nunito Sans",
                              }
                        }
                      />
                    ))}
                  {!openOverlay && remainingItemsCount > 0 && (
                    <Chip
                      label={`+${remainingItemsCount}`}
                      className="jobSearchNumberChipLabel jobSearchChipNumberBackgroundColour chipNUmber"
                      sx={{
                        cursor: "auto",
                        borderRadius: "6px",
                        height: "25px",
                        "& .MuiChip-label": {
                          color: "#ffffff !important",
                          fontSize: "14px",
                          fontFamily: "Nunito Sans",
                        },
                      }}
                    />
                  )}
                </Grid>
              )}

              <Grid
                item
                xs={itemsToShow.length !== 0 && !openOverlay ? 2 : 12}
                sm={itemsToShow.length !== 0 && !openOverlay ? 2 : 12}
                md={itemsToShow.length !== 0 && !openOverlay ? 2 : 12}
                lg={itemsToShow.length !== 0 && !openOverlay ? 5 : 12}
              >
                <TextField
                  ref={textInputRef}
                  fullWidth
                  InputProps={{
                    disableUnderline: true,
                    inputProps: {
                      placeholder,
                      maxLength: 50,
                      value,
                      onKeyPress: (event) => {
                        return event.key === "Enter" && event.preventDefault();
                      },
                    },
                  }}
                  onChange={(event) => this.handleTextInputChange(event)}
                  onBlur={(event) => {
                    const onBlurTarget = event.target;
                    (onBlurTarget as HTMLInputElement).placeholder = placeHolderNewText;
                  }}
                  variant="standard"
                  onClick={(event) => {
                    this.onFocus(event);
                    // Your existing code for handling TextField click
                    event.stopPropagation();
                  }}
                />
              </Grid>
            </Grid>
          )}
          {enableJobLocationSearch && openOverlay && (
            <div ref={overlayRef} className={clsx("jobSearchOverlay",enableDashboardAd && "bannerOverlay")} data-condition={isUserType}>
              <Grid container spacing={0} className="jobSerachOverlayGridStyle">
                <Grid
                  item
                  lg={5}
                  xs={12}
                  sm={6}
                  className={isMobile ? overlayClassGridOne : overlayClassGridTwo}
                >
                  <FilterList
                    filterData={autoSuggestLocation}
                    selectedItems={selectedItems}
                    citySelectedItems={citySelelctedItems}
                    onItemClick={this.handleSelectItem}
                    inputValue={value}
                    onCompactStateClick={this.handleCompactStates}
                    isCompactStateCheckValue={compactStateCheckValue}
                    compactStateDeletedValue={isCompactStateDeleted}
                    isUserType={isUserType}
                  />
                </Grid>
                <Grid
                  item
                  lg={7}
                  xs={12}
                  sm={6}
                  className={isMobile ? overlayClassGridTwo : overlayClassGridOne}
                >
                  <SelectedList
                    selectedItems={selectedItems}
                    citySelectedItems={citySelelctedItems}
                    inputValue={value}
                    fetchSingleLocation={this.fetchIndividualLocation}
                    deleteLocation={this.handleDelete}
                    deleteAllLocation={this.handleAllDelete}
                    isUserType={isUserType}
                  />
                </Grid>
              </Grid>
            </div>
          )}

          {!enableJobLocationSearch && (
            <span className="jobLocationMapIcon">
              <RoomOutlinedIcon />
            </span>
          )}
          {!enableJobLocationSearch && (
            <Autocomplete
              ref={textInputRef} // Set the ref for the TextField
              id="country-select"
              inputValue={typeof location === "string" ? location : ""}
              className={classes.input}
              autoHighlight
              options={autoSuggestLocation}
              getOptionLabel={(option) => this.getOptionText(option)}
              renderInput={this.renderTextField}
              onInputChange={this.onInputChange}
              filterOptions={(options) => options}
              onBlur={(event) => {
                const onBlurTarget = event.target;
                (onBlurTarget as HTMLInputElement).placeholder = placeHolderNewText;
              }}
              onChange={this.onChange}
              open={openPopup}
              loading={loading}
              noOptionsText={translate("globalSearch.location.noOptionsText")}
            />
          )}
        </Paper>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  locationAutosuggest: state.globalSearch.location,
  loading: state.globalSearch.loading,
  firstName: state.auth.firstName,
  value: enableJobLocationSearch ? state.value : "",
  company: state.company.company,
});

const mapDispatchToProps = (dispatch) => ({
  getLocation: (term) => dispatch(fetchLocation(term)),
  detectTheLocationInputClick: (value) => dispatch(detectLocationInputClick(value)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(withTranslation()(withRouter(LocationBoxV2))));
