/* eslint-disable import/no-cycle */
import { push } from "connected-react-router";
import API from "../../services/Api";
import { JOBS_RESULTS } from "../../router/routes";
import { fetchRecommendedJobs } from "../jobs/SearchCrossCountry/action";
import i18n from "../../translations/i18n";
import Analytics from "../../services/Analytics";
import config from "../../config";

export const FETCH_JOBS_REQUEST = "FETCH_JOBS_REQUEST";
export const fetchJobsRequest = (payload) => ({ type: FETCH_JOBS_REQUEST, payload });

export const FETCH_JOBS_SUCCESS = "FETCH_JOBS_SUCCESS";
export const fetchJobsSuccess = (payload) => ({ type: FETCH_JOBS_SUCCESS, payload });

export const FETCH_JOBS_FAILURE = "FETCH_JOBS_FAILURE";
export const fetchJobsFailure = (payload) => ({ type: FETCH_JOBS_FAILURE, payload });

const trackGaEvent = (dimensions, location) => {
  let event;
  if (location === "popularTag") {
    event = {
      category: i18n.t("googleAnalytics.category.jobs"),
      action: i18n.t("googleAnalytics.action.filterJobResults"),
      label: i18n.t("googleAnalytics.label.filterJobs"),
    };
  } else if (location === "jobSearch") {
    event = {
      category: i18n.t("googleAnalytics.category.jobs"),
      action: i18n.t("googleAnalytics.action.executeJobSearch"),
      label: i18n.t("googleAnalytics.label.jobSearch"),
    };
  }
  Analytics.sendPageview(window.location.pathname, dimensions);
  Analytics.sendEvent(event);
};

export const fetchJobs = (data, location) => {
  let dimensions;
  if (location === "popularTag") {
    dimensions = {
      dimension5: null,
    };
  } else if (location === "jobSearch") {
    let where = null;
    where = data.city && data.state ? `${data.city}, ${data.state}` : data.city ?? data.state;

    dimensions = {
      dimension9: data.specialty,
      dimension6: where,
      dimension5: null,
    };
  }
  return (dispatch) => {
    dispatch(fetchJobsRequest());
    API.get("jobs/search", {
      params: {
        specialty: data.specialty,
        companyId: data.companyId,
        city: data.city,
        state: data.state,
        page: data.page,
        size: data.size,
        sort: data.sort,
        tags: data.tags,
        latitude: data.latitude,
        longitude: data.longitude,
        geoLocationRadius: config.GEO_LOCATION_RADIUS,
      },
    })
      .then((response) => {
        const jobs = {
          response: response.data,
          request: data,
        };
        if (response.data._embedded.results.length === 0) {
          dispatch(fetchJobsFailure("Failed"));
          dispatch(fetchRecommendedJobs(data, null));
        } else {
          dispatch(fetchJobsSuccess(jobs));
          if (location) dimensions.dimension5 = response.data.page.totalElements;
        }
        if (location) trackGaEvent(dimensions, location);
        dispatch(push(JOBS_RESULTS));
      })
      .catch((error) => {
        dispatch(fetchJobsFailure(error.message));
        if (location) trackGaEvent(dimensions, location);
      });
  };
};
export const FETCH_JOB_SEARCH_REQ_OBJECT = "FETCH_JOB_SEARCH_REQ_OBJECT";
export const fetchJobSearchReqObject = (payload) => ({
  type: FETCH_JOB_SEARCH_REQ_OBJECT,
  payload,
});

export const FETCH_SELECTED_TAG = "FETCH_SELECTED_TAG";
export const fetchSelectedTags = (payload) => ({ type: FETCH_SELECTED_TAG, payload });

export const UPDATE_JOB_OBJECT = "UPDATE_JOB_OBJECT";
export const UPDATE_RECOMMENDED_JOB_OBJECT = "UPDATE_RECOMMENDED_JOB_OBJECT";
export const updateJobObject = (payload) => {
  let jobsObj;
  if (payload.isRecommendedJobsOnResult)
    jobsObj = payload.recommendedJobs._embedded && payload.recommendedJobs._embedded.results;
  else jobsObj = payload.jobs._embedded && payload.jobs._embedded.results;

  if (jobsObj) {
    jobsObj.forEach((job, index) => {
      if (payload.jobId === job.id) {
        switch (payload.action) {
          case "favorite":
            jobsObj[String(index)].isFavorite = payload.request.isActive;
            break;

          case "apply":
            jobsObj[String(index)].isApplied = payload.request.isActive;
            break;

          case "email":
            jobsObj[String(index)].isEmailed = payload.request.isActive;
            break;

          default:
            break;
        }
      }
    });
  }

  if (payload.isRecommendedJobsOnResult) {
    return { type: UPDATE_RECOMMENDED_JOB_OBJECT, payload };
  }
  return { type: UPDATE_JOB_OBJECT, payload };
};

export const SHOW_MAP = "SHOW_MAP";
export const showMap = (mapController) => ({ type: SHOW_MAP, mapController });

export const doShowMap = (mapControllerObj) => (dispatch) => {
  const mapController = {
    viewMap: mapControllerObj.showMap,
    renderGoogleMaps: mapControllerObj.renderGoogleMaps,
  };
  if (mapController) {
    dispatch(showMap(mapController));
  }
};

export const IS_FILTERS_TAB_OPEN_ON_MOB = "IS_FILTERS_TAB_OPEN_ON_MOB";
export const isFiltersTabOpenOnMob = (payload) => ({ type: IS_FILTERS_TAB_OPEN_ON_MOB, payload });

export const UPDATE_AGGREGATIONS = "UPDATE_AGGREGATIONS";
export const updateAggregations = (payload) => ({ type: UPDATE_AGGREGATIONS, payload });
