import config from "../config";

const isGeneric = () => {
  const baseURI = document.location.origin;
  return baseURI === config.GENERIC_URL;
};

export default isGeneric;

export const fileToBase64 = (file) => {
  const reader = new FileReader();
  return new Promise((resolve, reject) => {
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      if (reader.result) {
        const converted = reader.result.replace("data:", "").replace(/^.+,/, "");
        resolve(converted);
      } else {
        reject(new Error("conversion to base64 failed"));
      }
    };
  });
};

export const base64ToFile = (fetchData, filename, contentType) => {
  const mime = contentType;
  const bstr = atob(fetchData);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  // eslint-disable-next-line no-plusplus
  while (n--) {
    // eslint-disable-next-line security/detect-object-injection
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
}
