import { useTranslation } from "react-i18next";
import PrimaryButton from "src/components/common/Buttons/PrimaryButton";
import SecondaryButton from "src/components/common/Buttons/SecondaryButton";
import styles from "src/components/common/QuickApply/Acknowledgement/AcknowledgementOverlay.module.scss";

interface IAcknowledgementCloseModal {
  isConfirm: boolean;
  setIsConfirm: Function;
  setUserMadeChanges: Function;
  setOpen: Function;
  handleClose: Function;
  handleAgreeAndAccept: Function;
}
const AcknowledgementCloseModal = ({
  isConfirm,
  setIsConfirm,
  setUserMadeChanges,
  setOpen,
  handleClose,
  handleAgreeAndAccept,
}: IAcknowledgementCloseModal) => {
  const { t } = useTranslation();

  const handleConfirmation = () => {
    setIsConfirm(!isConfirm);
  };

  const handleDiscardChanges = () => {
    setUserMadeChanges(false);
    setOpen(false);
    setIsConfirm(true);
    handleClose(false);
    handleAgreeAndAccept(false);
  };

  return (
    <div className={styles.confirmationBox} hidden={isConfirm}>
      <div className={styles.confirmTitle}>{t("acknowledgements.unsavedChanges")}</div>
      <div className={styles.confirmSubTitle}>{t("acknowledgements.pleaseExit")}</div>
      <SecondaryButton
        component="button"
        size="medium"
        className={styles.stayOnPage}
        onClick={handleConfirmation}
      >
        <span>{t("acknowledgements.stayOnPage")}</span>
      </SecondaryButton>
      <PrimaryButton
        component="button"
        size="medium"
        className={styles.discard}
        onClick={handleDiscardChanges}
      >
        <span>{t("acknowledgements.exitAndDiscard")}</span>
      </PrimaryButton>
    </div>
  );
};
export default AcknowledgementCloseModal;
