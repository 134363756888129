import {
  FETCH_MY_JOBS_REQUEST,
  FETCH_MY_JOBS_SUCCESS,
  FETCH_MY_JOBS_FAILURE,
  UPDATE_MY_JOB_OBJECT,
  FILTER_MY_JOBS,
  SET_MY_JOBS_PAGE,
  SET_MY_JOBS_FILTER,
} from "./action";

const initialState = {
  myJobs: null,
  filteredJobs: null,
  error: "",
  loading: false,
  currentPage: 1,
  filterSelected: "favorite",
};
const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_MY_JOBS_REQUEST:
      return { ...state, loading: true };

    case FETCH_MY_JOBS_SUCCESS:
      return { ...state, loading: false, ...action.payload, error: null };

    case FETCH_MY_JOBS_FAILURE:
      return { ...state, loading: false, error: action.payload };

    case UPDATE_MY_JOB_OBJECT: {
      const myJobsResult = { ...action.payload.myJobs };
      return { ...state, myJobs: myJobsResult };
    }
    case SET_MY_JOBS_PAGE:
      return { ...state, currentPage: action.payload };
    case SET_MY_JOBS_FILTER:
      return { ...state, filterSelected: action.payload };
    default:
      return state;
  }
};

export default rootReducer;
