// @ts-nocheck
import { Inbox } from "@trycourier/react-inbox";
import { CourierProvider } from "@trycourier/react-provider";
import { useSelector } from "react-redux";
import DefaultBell from "src/components/common/Header/Notification/CustomInbox/Icons/DefaultBell.svg";
import CustomInbox from "./CustomInbox/CustomInbox";
import Footer from "./CustomInbox/Footer/Footer";
import styles from "./Notification.module.scss";

const CLIENT_KEY = "OGU4M2Q5ODktZThjZi00MDhlLTlhODUtNzg4N2E3ZmU0Y2VhL3Rlc3Q=";

const theme = {
  root: {
    width: "434px",
    background: "#f5f5f5",
    marginTop: "10px",
  },
  container: {
    background: "#f5f5f5",
    padding: "0px 5px",
  },
  messageList: {
    container: {
      background: "white",
      height: "auto",
      maxHeight: "300px",
      minHeight: "90px",
      borderTop: "null",
    },
  },
  message: {
    container: {
      marginTop: "6px",
      marginBottom: "6px",
    },
  },
  unreadIndicator: {
    background: "transparent",
    color: "transparent",
    "-webkit-animation": "none",
    "animation": "none",
  },
};

const NotificationIcon = () => {
  return <img className={styles.BellIcon} src={DefaultBell} alt="NotificationBell" />;
};

export default function Notification() {
  const userId = useSelector((state) => state.auth.userId);

  return (
    <CourierProvider userId={userId} clientKey={CLIENT_KEY}>
      <Inbox
        theme={theme}
        renderContainer={CustomInbox}
        tabs={false}
        renderIcon={NotificationIcon}
        renderFooter={Footer}
      />
    </CourierProvider>
  );
}
