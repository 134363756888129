import { Component } from "react";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { passwordStrength } from "check-password-strength";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { InputAdornment, IconButton } from "@material-ui/core";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import config from "src/config";
import { verifyToken, updateJobPreferencesFlag } from "src/redux/auth/action";
import PasswordUpdate from "src/components/common/auth/PasswordUpdate";
import PasswordRequirements from "src/components/common/auth/PasswordRequirements/PasswordRequirements";
import passwordValidationCheck from "src/components/common/auth/PasswordRequirements/PasswordValidationCheck";

const styles = () => ({});
const enableEmailVerification = config.ENABLE_EMAIL_VERIFICATION === "true";

interface PasswordRecoveryProps {
  t: (key: string) => string;
  company: {
    id: string;
  };
  verifyTokenAndUpdatePassword: (jwt: string, password: string, companyId: string) => void;
  updateJobPreferencesFlag: (flag: boolean) => void;
  computedMatch: {
    params: {
      jwt: string;
    };
  };
}

interface PasswordRecoveryState {
  showPassword: boolean;
  showConfirmPassword: boolean;
  password: string;
  confirmPassword: string;
  errorMessage: string;
  message: string;
  error: boolean;
  jwtReceived: string;
  showHelper: boolean;
  passwordUpdateComplete: boolean;
}
class PasswordRecovery extends Component<PasswordRecoveryProps, PasswordRecoveryState> {
  constructor(props) {
    super(props);
    this.state = {
      showPassword: false,
      showConfirmPassword: false,
      password: "",
      confirmPassword: "",
      errorMessage: "",
      message: "",
      error: false,
      jwtReceived: "",
      showHelper: false,
      passwordUpdateComplete: false,
    };
  }

  componentDidMount() {
    this.setState({
      jwtReceived: this.props.computedMatch.params.jwt,
    });
  }

  handleClickShowPassword() {
    this.setState({ showPassword: !this.state.showPassword });
  }

  handleClickShowConfirmPassword() {
    this.setState({ showConfirmPassword: !this.state.showConfirmPassword });
  }
  createPasswordLabel = (result) => {
    switch (result.id) {
      case 0:
        return "Too Weak";
      case 1:
        return "Weak";
      case 2:
        return "Medium";
      case 3:
        return "Strong";
      default:
        return "Too Weak";
    }
  };

  adminChangePasswordHandler = () => {
    let error = false;
    const testedResult = passwordStrength(this.state.password);
    const validationCheck = passwordValidationCheck(testedResult, this.state.password);

    if (!enableEmailVerification) {
      if (
        this.state.password === "" ||
        this.state.confirmPassword === "" ||
        !this.state.password.match(config.PASSWORD_REGEX as string | RegExp)
      ) {
        this.setState({
          errorMessage: this.props.t("forgotPassword.validPassword"),
          error: true,
        });
        error = true;
        return;
      }

      if (this.state.password !== this.state.confirmPassword) {
        this.setState({
          errorMessage: this.props.t("forgotPassword.passwordNotMatch"),
          error: true,
        });
        error = true;
      }
    } else {
      if (this.state.password === this.state.confirmPassword) {
        this.setState((prevState) => ({
          ...prevState,
          errorMessage: "",
        }));
      } else {
        this.setState((prevState) => ({
          ...prevState,
          errorMessage: this.props.t("createPassword.passwordMatchError"),
        }));
        error = true;
      }
      if (this.state.confirmPassword === "") {
        this.setState((prevState) => ({
          ...prevState,
          errorMessage: this.props.t("createPassword.enterpassword"),
        }));
        error = true;
      }
      if (testedResult && testedResult.id < 1) {
        this.setState((prevState) => ({
          ...prevState,
          message: this.props.t("accountSettingsForm.passwordStrengthError"),
        }));
        error = true;
      }
      if (validationCheck) {
        this.setState((prevState) => ({ ...prevState, message: "" }));
      } else {
        this.setState((prevState) => ({
          ...prevState,
          message: this.props.t("accountSettingsForm.passwordCriteriaMessage"),
        }));
        error = true;
      }
      if (this.state.password === "") {
        this.setState((prevState) => ({
          ...prevState,
          message: this.props.t("createPassword.enterpassword"),
        }));
        error = true;
      }
    }
    if (!error) {
      this.setState({
        passwordUpdateComplete: true,
      });
      this.props.verifyTokenAndUpdatePassword(
        this.state.jwtReceived,
        this.state.password,
        this.props.company.id
      );
      this.props.updateJobPreferencesFlag(true);
      localStorage.setItem("historyPathName", "/password-recovery");
    }
  };

  onFocusHandler = () => {
    this.setState({
      showHelper: true,
    });
  };

  onChangeHandlerToHideErrorMessage(fieldType, event) {
    if (!enableEmailVerification) {
      const fieldValue = event.target.value;
      if (fieldType === "password") {
        this.setState({ password: fieldValue }, () => {
          this.fieldNullCheck();
        });
      } else if (fieldType === "confirmPassword") {
        this.setState({ confirmPassword: fieldValue }, () => {
          this.fieldNullCheck();
        });
      }
    } else {
      const { value } = event.target;
      const inputValue = value.replace(/ /g, "");
      this.setState((prevState) => ({ ...prevState, [fieldType]: inputValue }));

      if (fieldType === "password") {
        if (inputValue === this.state.confirmPassword) {
          this.setState((prevState) => ({ ...prevState, errorMessage: "" }));
        }
        if (inputValue !== "") this.setState((prevState) => ({ ...prevState, message: "" }));
      }

      if (fieldType === "confirmPassword") {
        if (inputValue !== "") {
          this.setState((prevState) => ({ ...prevState, errorMessage: "" }));
        }
        if (this.state.password === inputValue) {
          this.setState((prevState) => ({ ...prevState, errorMessage: "" }));
        } else {
          this.setState((prevState) => ({
            ...prevState,
            errorMessage: this.props.t("createPassword.passwordMatchError"),
          }));
        }
      }
    }
  }

  fieldNullCheck() {
    if (this.state.password === "" && this.state.confirmPassword === "") {
      this.setState({
        errorMessage: "",
        error: true,
      });
    }
  }

  render() {
    const { password } = this.state;
    const testedResult = passwordStrength(password);

    const passStrength = (
      <div className="password-strength-meter ">
        <div className="password-strength-meter-label">
          {password && (
            <>
              <strong>{this.createPasswordLabel(testedResult)}</strong>
            </>
          )}
        </div>
        <progress
          className={`password-strength-meter-progress strength-${this.createPasswordLabel(
            testedResult
          )}`}
          value={testedResult.id}
          max="3"
        />
        <br />
        <div className="passInstruction mt-1">
          {this.props.t("forgotPassword.passwordGuidelines")}
          <br />
          {this.props.t("forgotPassword.passwordExampleTitle")}
          <b>{this.props.t("forgotPassword.passwordExampleText")}</b>
        </div>
      </div>
    );
    const rightSideForm = (
      <div>
        <Container>
          <Row>
            <Col sm={12} md={12} lg={12} xs={12}>
              <div
                className={
                  enableEmailVerification
                    ? "logoPassRecovMargin signinLogoPassRecov genericLogo"
                    : "signinLogoPassRecov genericLogo"
                }
              />
              <p className="pswResetText">{this.props.t("forgotPassword.enterPassword")}</p>
              <span className={enableEmailVerification && this.state.message ? "Mui-error" : ""}>
                <TextField
                  className="signintextfiled"
                  id="textsignIn"
                  label={this.props.t("changePassword.createNewPassword")}
                  onFocus={this.onFocusHandler}
                  type={this.state.showPassword ? "text" : "password"}
                  autoComplete="password"
                  value={password}
                  onChange={this.onChangeHandlerToHideErrorMessage.bind(this, "password")}
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          className="passwordIcon"
                          onClick={this.handleClickShowPassword.bind(this)}
                        >
                          {this.state.showPassword ? (
                            <Visibility className="passwordIcon" />
                          ) : (
                            <VisibilityOff className="passwordIcon" />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </span>
              <span
                className={!enableEmailVerification && this.state.error ? "Mui-error" : ""}
              ></span>
              <span className="erroMsgsignIn textLeft">{this.state.message}</span>
              {/* Password Requirements start */}
              {enableEmailVerification && this.state.showHelper && (
                <>
                  <PasswordRequirements password={password} changePassword={false} />
                </>
              )}
              {/* Password Requirements end */}

              <span
                className={
                  enableEmailVerification
                    ? this.state.errorMessage
                      ? "Mui-error"
                      : ""
                    : this.state.error
                    ? "Mui-error"
                    : ""
                }
              >
                <TextField
                  className="signintextfiled"
                  id="textsignIn"
                  label={this.props.t("changePassword.confirmNewPassword")}
                  onFocus={this.onFocusHandler}
                  type={this.state.showConfirmPassword ? "text" : "password"}
                  autoComplete="current-password"
                  value={this.state.confirmPassword}
                  variant="outlined"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          className="passwordIcon"
                          onClick={this.handleClickShowConfirmPassword.bind(this)}
                        >
                          {this.state.showConfirmPassword ? (
                            <Visibility className="passwordIcon" />
                          ) : (
                            <VisibilityOff className="passwordIcon" />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  onChange={this.onChangeHandlerToHideErrorMessage.bind(this, "confirmPassword")}
                />
              </span>
              <div className="erroMsgsignIn textLeft">{this.state.errorMessage}</div>
              {/* Password Strength Meter start */}
              {!enableEmailVerification && this.state.showHelper && passStrength}
              {/* Password Strength Meter End */}
              <div className={enableEmailVerification  ? "saveBtn mb-0 mt-0" : "saveBtn mb-2 mt-3"}>
                <Button className="signInButton mt-4" onClick={this.adminChangePasswordHandler}>
                  SAVE
                </Button>
              </div>
              <br />
            </Col>
          </Row>
        </Container>
      </div>
    );

    const internalContainerWithLogo = (
      <>
        <Col sm={12} md={5} lg={5} xs={12} />
        <Col sm={12} md={7} lg={7} xs={12} className="pb-3 pt-3 rightsigninform">
          {rightSideForm}
        </Col>
        <Col sm={12} xs={12} />
      </>
    );

    const renderCode = (
      <div className="fullCoverBackground">
        <div>
          <Container
            className={
              enableEmailVerification ? "passwordRecovery passwordRecoveryTop" : "passwordRecovery"
            }
          >
            <Row>
              <Col sm={12} md={5} lg={5} xs={12} className="pb-3 pt-3" />
              <Col sm={12} md={6} lg={6} xs={12} className="pb-3 pt-3">
                <Row className="loginFormV2 loginFormMarginMobile">
                  {this.state.passwordUpdateComplete ? (
                    <PasswordUpdate />
                  ) : (
                    internalContainerWithLogo
                  )}
                </Row>
              </Col>
              <Col sm={12} md={1} lg={1} xs={12} className="pb-3 pt-3" />
            </Row>
          </Container>
        </div>
      </div>
    );

    return renderCode;
  }
}
const mapStateToProps = (state) => ({
  company: state.company.company,
  email: state.auth.email,
  userId: state.auth.userId,
  firstName: state.auth.firstName,
  lastName: state.auth.lastName,
  phone: state.auth.phone,
});
const mapDispatchToProps = (dispatch) => ({
  verifyTokenAndUpdatePassword: (token, password, companyId) =>
    dispatch(verifyToken(token, password, companyId)),
  updateJobPreferencesFlag: (value) => dispatch(updateJobPreferencesFlag(value)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(withTranslation()(PasswordRecovery)));
