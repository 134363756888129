// @ts-nocheck
import { Component, KeyboardEvent, MouseEvent } from "react";
import Container from "react-bootstrap/Container";
import { connect } from "react-redux";
import { Row, Col } from "react-bootstrap";
import Link from "@material-ui/core/Link";
import Image from "react-bootstrap/Image";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { TFunction, withTranslation } from "react-i18next";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { isMobile } from "react-device-detect";
import Button from "@material-ui/core/Button";
import {
  MY_JOBS,
  PAYROLL_TIMESHEET,
  LATEST_TASKS,
  BENEFITS,
  DASHBOARD,
  MY_PROFILE,
  CERTIFICATIONS_LICENSES,
  BASIC_INFO_PAGE,
  EDU_SPEC_PAGE,
  WORK_EXP_PAGE,
  SKILL_CHECKLIST_OVERVIEW_PAGE,
  LEGACY_PAGE,
  REFERENCES_PAGE,
  MY_TEAM_PAGE,
  PAYROLL_PAYSTUBS,
} from "src/router/routes";
import { fetchLatLngFromAddress } from "src/redux/dashboard/action";
import {
  CUSTOMER_SERVICE_EMAIL,
  MAIN_OFFICE_ADDRESS,
  CUSTOMER_SERVICE_PHONE,
} from "src/constants/contact_info";
import {
  FOLLOW_US_FACEBOOK_URL_CCA,
  FOLLOW_US_FACEBOOK_URL_CCN,
  FOLLOW_US_INSTAGRAM_URL_CCA,
  FOLLOW_US_INSTAGRAM_URL_CCN,
  FOLLOW_US_TIKTOK_URL_CCA,
  FOLLOW_US_TIKTOK_URL_CCN,
  FOLLOW_US_LINKEDIN_URL_CCN,
  FOLLOW_US_LINKEDIN_URL_CCA,
  FOLLOW_US_YOUTUBE_URL_CCN,
  FOLLOW_US_YOUTUBE_URL_CCA,
} from "src/constants/external_links";
import { referFriendNavigation } from "src/redux/company/action";
import { updateActiveFlag } from "src/redux/onBoarding/action";
import EarnBigIcon from "src/imagesV2/earnbigicon.svg";
import config from "src/config";
import Analytics from "src/services/Analytics";
import { History } from "history";
import DoormatLinks from "./DoormatLinks/DoormatLinks";
import ReferFriend from "./ReferFriend/ReferFriend";
import SocialLinks from "./SocialLinks/SocialLinks";
import LowerFooter from "./LowerFooter/LowerFooter";
import styles from "./FooterV2.module.scss";

const enableMyTeam = config.ENABLE_MY_TEAM === "true";
const enablePaystubs = config.ENABLE_PAYSTUBS === "true";

interface FooterV2Props {
  history: History;
  t: TFunction<"translation", undefined>;
  fetchTheLatLangFromAddress: (address: string, type: string) => void;
  updateTheActiveFlag: (activeFlag: number) => void;
  onBoardingCompletedSteps: { [key: number]: boolean };
  useProfileIframe: boolean;
  company: {
    id: string;
  };
  adjustFooter: boolean;
}

class FooterV2 extends Component<FooterV2Props & RouteComponentProps> {
  componentDidMount() {
    // this.getMainOfficeLatLng();
  }

  handlePageRedirection = (
    route: string
  ): {
    pathname: string;
    search?: string | undefined;
  } => {
    const {
      history: { location },
      updateTheActiveFlag,
    } = this.props;
    let newLocation = { pathname: MY_PROFILE };
    updateTheActiveFlag(0);
    if (route === BASIC_INFO_PAGE) {
      newLocation = this.buildURLLocation(location, LEGACY_PAGE, "?page=basic-info", 0, route);
    } else if (route === EDU_SPEC_PAGE) {
      newLocation = this.buildURLLocation(
        location,
        LEGACY_PAGE,
        "?page=education-specialties",
        1,
        route
      );
    } else if (route === WORK_EXP_PAGE) {
      newLocation = this.buildURLLocation(location, LEGACY_PAGE, "?page=work-exp", 2, route);
    } else if (route === REFERENCES_PAGE) {
      newLocation = this.buildURLLocation(location, REFERENCES_PAGE, "?page=references", 3, route);
    } else if (route === SKILL_CHECKLIST_OVERVIEW_PAGE) {
      newLocation = this.buildURLLocation(location, LEGACY_PAGE, "?page=skill-checklist", 4, route);
    } else if (route === CERTIFICATIONS_LICENSES) {
      newLocation = this.buildURLLocation(
        location,
        LEGACY_PAGE,
        "?page=certifications-and-licenses",
        5,
        route
      );
    }
    return newLocation;
  };

  getMainOfficeLatLng() {
    const { fetchTheLatLangFromAddress } = this.props;
    fetchTheLatLangFromAddress(MAIN_OFFICE_ADDRESS, "mainOffcAddr");
  }

  redirectToLatestUncompletedStep = (): string => {
    const { onBoardingCompletedSteps, updateTheActiveFlag } = this.props;
    const stepPages = [
      BASIC_INFO_PAGE,
      EDU_SPEC_PAGE,
      WORK_EXP_PAGE,
      REFERENCES_PAGE,
      SKILL_CHECKLIST_OVERVIEW_PAGE,
    ];
    const latestUncompletedStep = stepPages.findIndex(
      (_, index) => !onBoardingCompletedSteps[`${index}`]
    );
    const latestUncompletedStepFound = latestUncompletedStep !== -1;
    const pageToRedirect = latestUncompletedStepFound
      ? stepPages[`${latestUncompletedStep}`]
      : BASIC_INFO_PAGE;
    updateTheActiveFlag(latestUncompletedStepFound ? latestUncompletedStep : 0);
    return pageToRedirect;
  };

  buildURLLocation = (
    URLlocation: { pathname: string; search?: string },
    url: string,
    searchURL: string,
    flagNum: number,
    route: string
  ): { pathname: string; search?: string | undefined } => {
    const { useProfileIframe, updateTheActiveFlag } = this.props;
    let newUrlLocation: { pathname: string; search?: string } = URLlocation;
    if (useProfileIframe) {
      newUrlLocation = {
        pathname: url,
        search: searchURL,
      };
    } else {
      updateTheActiveFlag(flagNum);
      newUrlLocation = { pathname: route };
    }
    return newUrlLocation;
  };

  BuildFooterItems = ({ route, translatedString, className }, index) => {
    return (
      <div
        className={className}
        role="button"
        tabIndex={0}
        onKeyPress={(event) => this.redirectToProfilePages(route, translatedString, event)}
        onClick={(event) => this.redirectToProfilePages(route, translatedString, event)}
        key={index}
      >
        {translatedString}
      </div>
    );
  };

  triggerAnalytics = (label: string, event) => {
    if (event && event.type === "click") {
      const segmentEventName = "Clicked Footer";
      const segmentEventProps = {
        segment_source_name: `${process.env.NODE_ENV}-web-gateway`,
        text: label,
      };
      Analytics.segmentTrackEvent(segmentEventName, segmentEventProps);
    }
  };

  triggerAnalyticsAndNavigation = (
    event: KeyboardEvent<HTMLButtonElement> | MouseEvent<HTMLButtonElement, globalThis.MouseEvent>,
    companyId: number,
    label: string,
    name: string
  ) => {
    this.triggerAnalytics(label, event);

    const getURL = {
      instagram: {
        url: {
          1: FOLLOW_US_INSTAGRAM_URL_CCN,
          2: FOLLOW_US_INSTAGRAM_URL_CCA,
        },
      },
      facebook: {
        url: {
          1: FOLLOW_US_FACEBOOK_URL_CCN,
          2: FOLLOW_US_FACEBOOK_URL_CCA,
        },
      },
      linkedin: {
        url: {
          1: FOLLOW_US_LINKEDIN_URL_CCN,
          2: FOLLOW_US_LINKEDIN_URL_CCA,
        },
      },
      tiktok: {
        url: {
          1: FOLLOW_US_TIKTOK_URL_CCN,
          2: FOLLOW_US_TIKTOK_URL_CCA,
        },
      },
      youtube: {
        url: {
          1: FOLLOW_US_YOUTUBE_URL_CCN,
          2: FOLLOW_US_YOUTUBE_URL_CCA,
        },
      },
    };

    window.open(getURL[`${name}`]!.url[`${companyId}`], "_blank");
  };

  redirectToReferFriend = (
    label: string,
    event: KeyboardEvent<HTMLDivElement> | MouseEvent<HTMLDivElement, globalThis.MouseEvent>
  ) => {
    this.triggerAnalytics(label, event);
    window.open(referFriendNavigation());
  };

  redirectToProfilePages(
    route: string,
    label: string,
    event:
      | KeyboardEvent<HTMLDivElement>
      | MouseEvent<HTMLButtonElement, globalThis.MouseEvent>
      | MouseEvent<HTMLDivElement, globalThis.MouseEvent>
  ) {
    this.triggerAnalytics(label, event);
    const { history, useProfileIframe } = this.props;

    if (route === MY_PROFILE) {
      const location = {
        pathname: this.redirectToLatestUncompletedStep(),
        search: useProfileIframe ? "?page=basic-info" : "",
      };
      history.push(location);
    } else {
      const myProfileRoutes = [
        MY_PROFILE,
        BASIC_INFO_PAGE,
        LEGACY_PAGE,
        EDU_SPEC_PAGE,
        WORK_EXP_PAGE,
        REFERENCES_PAGE,
        SKILL_CHECKLIST_OVERVIEW_PAGE,
        CERTIFICATIONS_LICENSES,
      ];
      if (!myProfileRoutes.includes(route)) return history.push(route);
      const location = this.handlePageRedirection(route).pathname;
      history.push(location);
    }
    return null;
  }

  render() {
    const { t, company, adjustFooter } = this.props;
    const mainFooterLinks = [
      {
        className: `${styles.DoormatLabel}`,
        translatedString: t("footer.profile.dashboard"),
        route: DASHBOARD,
      },
      {
        className: `${styles.DoormatLabel}`,
        translatedString: t("header.jobsLink"),
        route: MY_JOBS,
      },
      {
        className: `${styles.DoormatLabel}`,
        translatedString: t("headerV2.myTasks"),
        route: LATEST_TASKS,
      },
      {
        className: `${styles.DoormatLabel}`,
        translatedString: t("headerV2.myTeamTitle"),
        route: MY_TEAM_PAGE,
      },
      {
        className: `${styles.DoormatLabel}`,
        translatedString: t("headerV2.benefits"),
        route: BENEFITS,
      },
    ];

    const myProfileSubNav = [
      {
        className: `${styles.DoormatLabel}`,
        translatedString: t("footer.profile.profileDocs"),
        route: MY_PROFILE,
      },
      {
        className: `${styles.DoormatSubLink}`,
        translatedString: t("myProfileSubNav.basicInfo"),
        route: BASIC_INFO_PAGE,
      },
      {
        className: `${styles.DoormatSubLink}`,
        translatedString: t("myProfileSubNav.specialties&Education"),
        route: EDU_SPEC_PAGE,
      },
      {
        className: `${styles.DoormatSubLink}`,
        translatedString: t("myProfileSubNav.workExperience"),
        route: WORK_EXP_PAGE,
      },
      {
        className: `${styles.DoormatSubLink}`,
        translatedString: t("myProfileSubNav.references"),
        route: REFERENCES_PAGE,
      },
      {
        className: `${styles.DoormatSubLink}`,
        translatedString: t("myProfileSubNav.skillChecklist"),
        route: SKILL_CHECKLIST_OVERVIEW_PAGE,
      },
      {
        className: `${styles.DoormatSubLink}`,
        translatedString: t("myProfileSubNav.certificationsLicenses"),
        route: CERTIFICATIONS_LICENSES,
      },
    ];

    const payrollSubNav = [
      {
        className: `${styles.DoormatLabel}`,
        translatedString: t("footer.profile.payroll"),
        route: PAYROLL_TIMESHEET,
      },
      {
        className: `${styles.DoormatSubLink}`,
        translatedString: t("footer.payrollNavbar.paystubsAndTimesheets"),
        route: PAYROLL_TIMESHEET,
      },
    ];

    const payrollSubNavV2 = [
      {
        className: `${styles.DoormatLabel}`,
        translatedString: t("footer.profile.payroll"),
        route: PAYROLL_TIMESHEET,
      },
      {
        className: `${styles.DoormatSubLink}`,
        translatedString: t("footer.payrollNavbar.timesheet"),
        route: PAYROLL_TIMESHEET,
      },
      {
        className: `${styles.DoormatSubLink}`,
        translatedString: t("footer.payrollNavbar.paystubs"),
        route: PAYROLL_PAYSTUBS,
      },
    ];

    const socialMediaIcons = [
      {
        name: "instagram",
        src: "/img/instagramIcon.svg",
      },
      {
        name: "facebook",
        src: "/img/facebookIcon.svg",
      },
      {
        name: "linkedin",
        src: "/img/linkedinIcon.svg",
      },
      {
        name: "tiktok",
        src: "/img/tikTokIcon.svg",
      },
      {
        name: "youtube",
        src: "/img/youtubeIcon.svg",
      },
    ];

    return (
      <>
        <div className="container">
          <div className={styles.Footer}>
            <DoormatLinks
              mainFooterLinks={mainFooterLinks}
              myProfileSubNav={myProfileSubNav}
              BuildFooterItems={this.BuildFooterItems}
              payrollSubNav={payrollSubNav}
              enableMyTeam={enableMyTeam}
              enablePaystubs={enablePaystubs}
              payrollSubNavV2={payrollSubNavV2}
            />
            <ReferFriend />
            <SocialLinks company={company} />
          </div>

          <div className={styles.FooterDivider}>
            <hr />
          </div>
        </div>
        <LowerFooter />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  candidateId: state.auth.candidateId,
  applicantStatus: state.candidate.applicantStatus,
  company: state.company.company,
  email: state.auth.email,
  portalComplete: state.onBoarding.portalComplete,
  activeSteps: state.onBoarding.activeSteps,
  triggeringPoint: state.onBoarding.triggeringPoint,
  onboardingInformation: state.onBoarding.onboardingInformation,
  isNavigateToOnboarding: state.navigation.isNavigateToOnboarding,
  isFirstLogin: state.auth.isFirstLogin,
  onBoardingCompletedSteps: state.onBoarding.completedSteps,
  useProfileIframe: state.auth.useProfileIframe,
});

const mapDispatchToProps = (dispatch) => ({
  fetchTheLatLangFromAddress: (address: string, type: string) =>
    dispatch(fetchLatLngFromAddress(address, type)),
  updateTheActiveFlag: (activeFlag: number) => dispatch(updateActiveFlag(activeFlag)),
});

export { FooterV2 };
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withRouter(FooterV2)));
