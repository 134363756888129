import React, { Component } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { DASHBOARD, SKILL_CHECKLIST_OVERVIEW_PAGE } from "../../../router/routes";
import { setCompleteStatus } from "../../../redux/candidate/action";
import { CUSTOMER_SERVICE_PHONE } from "../../../constants/contact_info";
import config from "../../../config";
import {
  sendEmailToSupportTeam,
  saveOnboardingComplete,
  updateCompleteFlag,
  updateActiveFlag,
} from "../../../redux/onBoarding/action";
import Analytics from "../../../services/Analytics";

const enableReferences = config.ENABLE_REFERENCES === "true";
class OnBoardingComplete extends Component {
  componentDidMount() {
    const event = {
      category: this.props.t("googleAnalytics.category.onboarding"),
      action: this.props.t("googleAnalytics.action.completeApplication"),
      label: this.props.t("googleAnalytics.label.application"),
    };
    this.trackEvent(event);
    if (!this.props.applicantId) {
      this.sendEmail();
    }
  }

  trackEvent(event) {
    const dimensions = {
      dimension9:
        this.props.candidateSpecialties && this.props.candidateSpecialties.length > 0
          ? this.props.candidateSpecialties.toString()
          : null,
    };
    Analytics.sendPageview(this.props.history.location, dimensions);
    Analytics.sendEvent(event);
  }

  handleNavigation(type, buttonName) {
    if (type === "skillsChecklist") {
      const event = {
        category: this.props.t("googleAnalytics.category.onboarding"),
        action: this.props.t("googleAnalytics.action.startSkillsChecklist"),
        label: this.props.t("googleAnalytics.label.skillsChecklist"),
      };
      this.trackEvent(event);
      this.props.setCompleteStatus();
      this.props.history.push(SKILL_CHECKLIST_OVERVIEW_PAGE);
    }
    if (type === "certificationsAndLicenses") {
      if (buttonName === "noThanks" || buttonName === "letsGo") {
        const reqObj = {
          email: this.props.email,
          companyId: this.props.companyDetails.id,
          candidateId: this.props.candidateId,
          sqsEvent: true,
        };
        const onBoardingCompletedStepsFinal = this.props.onBoardingCompletedSteps;
        onBoardingCompletedStepsFinal[enableReferences ? 4 : 3] = true;
        this.props.updateCompleteFlag(onBoardingCompletedStepsFinal);
        this.props.saveOnboardingComplete(reqObj, "init", this.props.isSkillChecklistComplete);
      }
      this.props.setCompleteStatus();
    }
    if (type === "skill-checklist-overview") {
      this.props.history.push(SKILL_CHECKLIST_OVERVIEW_PAGE);
    }
  }

  navigateToDashboard() {
    this.props.setCompleteStatus();
    const onBoardingCompletedStepsFinal = this.props.onBoardingCompletedSteps;
    onBoardingCompletedStepsFinal[enableReferences ? 4 : 3] = true;
    this.props.updateCompleteFlag(onBoardingCompletedStepsFinal);
    this.props.history.push(DASHBOARD);
  }

  sendEmail() {
    const emailParam = {
      email: this.props.email,
      firstName: this.props.firstName,
      lastName: this.props.lastName,
    };
    this.props.sendEmailToSupportTeam(emailParam);
  }

  render() {
    let title1;
    let title2;

    title1 = this.props.isSkillChecklistComplete
      ? this.props.t("onBoarding.onBAlmostDoneTitleTxt1")
      : this.props.t("onBoarding.awesomeJob");

    title2 = this.props.isSkillChecklistComplete
      ? this.props.t("onBoarding.onBAlmostDoneTitleTxt2")
      : this.props.t("onBoarding.almostDone");

    return (
      <div>
        <Container fluid className="onBBlueBgAlmostDone">
          <Container className="onBMainTxtField">
            <Row>
              <Col sm={12} xs={12} md={1} lg={1} />
              <Col sm={12} xs={12} md={10} lg={10}>
                <div className="onBAlmostDoneWrap">
                  <Row>
                    <Col sm={12} xs={12} md={6} lg={6} className="onbWelcometxt">
                      <div className="onBLetsGoTitle textLeft">
                        {this.props.firstName},&nbsp;{title1}
                        <br />
                        {title2 ? <span>{title2}</span> : null}
                      </div>
                      {/* replacing applicantId check with true to skip the error page */}
                      {true ? (
                        <>
                          <div className="onBAlmostDoneText textLeft">
                            {this.props.isSkillChecklistComplete
                              ? this.props.t("onBoarding.onBAlmostDoneBodyTxt1")
                              : this.props.t("onBoarding.incompleteText")}
                          </div>
                          {this.props.isSkillChecklistComplete ? (
                            <div>
                              <ul className="onBAlmostDoneList">
                                <li>{this.props.t("onBoarding.onBAlmostDoneBodyTxt4")}</li>
                              </ul>
                            </div>
                          ) : (
                            <></>
                          )}
                        </>
                      ) : (
                        <div className="onBLetsGoDesc">
                          {this.props.t("onBoarding.onBAlmostRegErrorBodyTxt1")}
                          &nbsp;
                          <span className="onBPhoneDivMob">
                            <Link className="onBPhoneNo" href={`tel:${CUSTOMER_SERVICE_PHONE}`}>
                              {CUSTOMER_SERVICE_PHONE}
                            </Link>
                            &nbsp;
                            {this.props.t("onBoarding.onBAlmostRegErrorBodyTxt2")}
                            &nbsp;{config.ERROR_CODES.NO_APPLICANT_ID}.
                          </span>
                        </div>
                      )}
                      {/* replacing applicantId check with true to skip the error page */}
                      {true ? (
                        <>
                          <div className="onBLetsGoBtn onBcompleteSkillsBtn">
                            {this.props.isSkillChecklistComplete ? (
                              <Button
                                onClick={this.handleNavigation.bind(
                                  this,
                                  "certificationsAndLicenses",
                                  "letsGo"
                                )}
                              >
                                {this.props.t("onBoarding.onBLetsGoBtn")}
                              </Button>
                            ) : (
                              <Button
                                style={{ marginTop: "0px" }}
                                onClick={this.handleNavigation.bind(
                                  this,
                                  "skill-checklist-overview",
                                  null
                                )}
                              >
                                {this.props.t("onBoarding.onBCompleteSkillsBtn")}
                              </Button>
                            )}
                          </div>
                          {!this.props.isSkillChecklistComplete ? (
                            <div className="onBSkillNoThanksBtn">
                              <Button
                                className="onBNoThanksBtn"
                                onClick={this.handleNavigation.bind(
                                  this,
                                  "certificationsAndLicenses",
                                  "noThanks"
                                )}
                              >
                                {this.props.t("onBoarding.OnBCompleteNoThanksBtn")}
                              </Button>
                            </div>
                          ) : (
                            <></>
                          )}
                        </>
                      ) : (
                        <div className="textCenter onBSkillAlmostBtn">
                          <Link
                            className="onBCallCareTeamBtn"
                            href={`tel:${CUSTOMER_SERVICE_PHONE}`}
                          >
                            {this.props.t("onBoarding.OnBCompleteCallBtn")}
                          </Link>
                          <Button
                            className="onBNoThanksBtn onBNoThanksMob"
                            onClick={this.navigateToDashboard.bind(this)}
                          >
                            {this.props.t("onBoarding.OnBCompleteNoThanksBtn")}
                          </Button>
                        </div>
                      )}
                    </Col>
                    <Col sm={12} xs={12} md={6} lg={6}>
                      <div className="onBAlmostDoneImg" />
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col sm={12} xs={12} md={1} lg={1} />
            </Row>
          </Container>
        </Container>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  onBoardingNavigation: state.navigation.onBoardingNavigation,
  firstName: state.auth.firstName,
  applicantId: state.candidate.applicantId,
  companyDetails: state.company.company,
  candidateSpecialties: state.auth.candidateSpecialties,
  userId: state.auth.userId,
  email: state.auth.email,
  lastName: state.auth.lastName,
  emailLambdaResponse: state.onBoarding.emailLambdaResponse,
  isSkillChecklistComplete: state.onBoarding.isSkillChecklistComplete,
  candidateId: state.auth.candidateId,
  onBoardingCompletedSteps: state.onBoarding.completedSteps,
});

const mapDispatchToProps = (dispatch) => ({
  setCompleteStatus: () => dispatch(setCompleteStatus()),
  sendEmailToSupportTeam: (obj) => dispatch(sendEmailToSupportTeam(obj)),
  saveOnboardingComplete: (reqObj, triggeringPoint, skillChecklistCompleteStatus) =>
    dispatch(saveOnboardingComplete(reqObj, triggeringPoint, skillChecklistCompleteStatus)),
  updateCompleteFlag: (completedSteps) => dispatch(updateCompleteFlag(completedSteps)),
  updateActiveFlag: (activeSteps) => dispatch(updateActiveFlag(activeSteps)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withRouter(OnBoardingComplete)));
