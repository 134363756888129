import SmartBanner from "react-smartbanner";
import "./NativeInstallBanner.scss";

const NativeInstallBanner = () => {

  const forceUserAgent = () => {

    const agent = window.navigator.userAgent;
    if (agent.includes("iPhone")) {
      return "ios";
    }
    if (agent.includes("Android")) {
      return "android";
    }
    return "";
  };

  return (
    <SmartBanner
      title="Xperience"
      author="Cross Country"
      daysHidden={1}
      daysReminder={1}
      position="bottom"
      force={forceUserAgent()}
      appMeta={{
        ios: "apple-itunes-app-2",
        android: "google-play-app",
      }}
    />
  );
};

export default NativeInstallBanner;
