import { useTranslation } from "react-i18next";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";

function OnBoardingBackBtnConfirmBox({ handleClose, handleYesClicked, handleNoClicked }) {
  const { t } = useTranslation();
  return (
    <div className="onBBackBtnPopUpPosition" id="backButtonPopUp">
      <IconButton aria-label="Close" className="onBPopUpCloseBtn" onClick={handleClose}>
        <CloseIcon fontSize="small"/>
      </IconButton>
      <div className="onBBackBtnPopUpTxt textCenter">
        {t("onBoarding.edu&spec.yourChangesPopupTxt")}
        <span className="onBBackBtnPopUpNote">{t("onBoarding.edu&spec.yourChangesPopupTxt1")}</span>
      </div>
      <div className="textCenter">
        <Button className="onBPopupPrimaryBtn" onClick={handleYesClicked}>
          {t("onBoarding.skillChecklist.yesBtnLbl")}
        </Button>
        &nbsp;&nbsp;
        <Button className="onBPopupBtn" onClick={handleNoClicked}>
          {t("onBoarding.skillChecklist.noBtnLbl")}
        </Button>
      </div>
    </div>
  );
}

export default OnBoardingBackBtnConfirmBox;