import React from "react";
import MainLayout from "./MainLayout";
import MyTasks from "src/components/common/MainContent/MyTasks/MyTasks";
import MyTasksV2 from "src/components/common/MainContent/MyTasks/MyTasksV2";
import config from "src/config";

const isMyTasksFlagEnabled = config.ENABLE_MY_TASKS === "true";

const LatestTasksPage = () => <MainLayout component={isMyTasksFlagEnabled ? MyTasks : MyTasksV2} />;

export default LatestTasksPage;
