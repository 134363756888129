import {
  CognitoUser,
  AuthenticationDetails,
  CognitoRefreshToken,
} from "amazon-cognito-identity-js";
import UserPool from "../auth/UserPool";

export const signIn = (userName, password) =>
  new Promise((resolve, reject) => {
    const username = userName.trim().toLowerCase();

    const user = new CognitoUser({
      Username: username,
      Pool: UserPool,
    });
    const authDetails = new AuthenticationDetails({
      ValidationData: { Name: "source", Value: "webone" },
      Username: username,
      Password: password,
    });
    user.setAuthenticationFlowType("USER_PASSWORD_AUTH");
    user.authenticateUser(authDetails, {
      onSuccess: (data) => {
        resolve(data);
      },

      onFailure: (err) => {
        console.error("onFailure:", err);
        reject(err);
      },

      newPasswordRequired: (data) => {
        console.error("newPasswordRequired:", data);
        reject(data);
      },
    });
  });

function logOutUser(user) {
  localStorage.clear();
  sessionStorage.clear();
  user.signOut();

  window.location.reload();
}

export const refreshSession = async (refreshToken, username) => {
  const user = new CognitoUser({
    Username: username,
    Pool: UserPool,
  });

  const refreshTokenObject = new CognitoRefreshToken({
    RefreshToken: refreshToken,
  });

  return new Promise((resolve, reject) => {
    user.refreshSession(refreshTokenObject, (err, data) => {
      if (err) {
        console.error("Error received while refreshing cognito token:", err);
        logOutUser(user);
        reject(err);
      } else {
        if (!data.refreshToken.token) {
          logOutUser(user);
          reject(err);
        }
        resolve(data);
      }
    });
  });
};
