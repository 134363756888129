import { EmailRecruiterV2Props, jobDetailType, reqObjType } from "src/components/common/MainContent/JobResults/common";
import { connect } from "react-redux";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import CloseIcon from "@mui/icons-material/Close";
import { TextField, IconButton, Button } from "@material-ui/core";
import { useEffect, useState } from "react";
import { postEmail } from "src/redux/userAction/emailRecruiter/action";
import { fetchRecruiterDetails } from "src/redux/consultant/action";
import { renderShiftDetails } from "../content/JobCardContent";
import styles from "./JobCardEmail.module.scss";

export interface JobCardEmailProps extends EmailRecruiterV2Props {
  job: jobDetailType;
  onDismiss: () => void;
  modifyJob?: (partialJob: Partial<jobDetailType>) => void;
}

const JobCardEmail = ({
  job,
  email,
  recruiterDetails,
  candidateId,
  allJobs,
  similarJobsCarouselItems,
  collaboratedJobsCarouselItems,
  similarOrViewed,
  jobDetails,
  recommended,
  recommendedJobs,
  isRecommendedJobsOnResult,
  viewMyJobs,
  myJobs,
  sendEmail: doSendEmail,
  fetchRecruiterDetails: getRecruiterDetails,
  onDismiss,
  modifyJob,
}: JobCardEmailProps) => {
  const [message, setMessage] = useState<string>("");

  const onMessageChange = (e) => {
    setMessage(e.target.value.trim());
  };

  const sendEmail = () => {
    modifyJob?.({ ...job, isEmailed: true });

    const recruiterEmail = recruiterDetails ? recruiterDetails.emailId : "";
    const reqObj = {
      jobId: job.id,
      action: "email",
      userId: 2,
      emailReqObj: {
        messageToSend: "",
        candidateId,
        toAddress: recruiterEmail,
        fromAddress: email,
      },
      request: {
        isActive: true,
        jobId: job.id,
      },
      jobs: allJobs,
      similarJobs: similarJobsCarouselItems,
      collaboratedJobs: collaboratedJobsCarouselItems,
      similarOrViewed,
      jobDetails,
      recommended,
      recommendedJobs,
      isRecommendedJobsOnResult,
      viewMyJobs,
      myJobs,
    };

    const newMessage = message.replaceAll("\n", "\\n").replaceAll("\"", "\\\"");
    reqObj.emailReqObj.messageToSend = newMessage;

    doSendEmail(reqObj);
    // this.trackSegmentEvent();
    onDismiss();
  };

  const [hasFetchedRecruiterDetails, setHasFetchedRecruiterDetails] = useState<boolean>(false);

  useEffect(() => {
    if (!recruiterDetails && candidateId && hasFetchedRecruiterDetails === false) {
      setHasFetchedRecruiterDetails(true);
      getRecruiterDetails(candidateId);
    }
  }, [recruiterDetails, candidateId]);

  return (
    <div className={styles.main}>
      <div className={styles.content}>
        <div className={styles.topBar}>
          <div className={styles.cityState}>
            <LocationOnIcon />
            {job.city}, {job.state}
          </div>
          <div className={styles.close}>
            <IconButton onClick={onDismiss}><CloseIcon /></IconButton>
          </div>
        </div>

        <div className={styles.facilityName}>
          {job.facilityName}
        </div>

        <div className={styles.message}>
          <TextField
            variant="outlined"
            value={message}
            placeholder="Write a message for recruiter..."
            onChange={onMessageChange}
            multiline
            minRows={7}
            maxRows={7}
            inputProps={{ maxLength: 500 }}
          />
        </div>

        <div className={styles.extraInfo}>
          <div className={styles.shift}>
            {renderShiftDetails(job)}
          </div>

          <div className={styles.hoursPerWeek}>
            <AccessTimeIcon />
            {job.hoursPerWeek}Hrs/Wk
          </div>

          <div className={styles.duration}>
            <CalendarTodayIcon />
            {job.duration} Wk
          </div>
        </div>
      </div>

      <div className={styles.send}>
        <Button disabled={message.trim().length === 0} onClick={sendEmail}>
          Send
        </Button>
      </div>
    </div>
  );
}

JobCardEmail.defaultProps = {
  modifyJob: undefined,
}

const mapStateToProps = (state) => ({
  allJobs: state.jobResults.jobs,
  similarJobsCarouselItems: state.jobDetails.similarJobsCarouselItems,
  collaboratedJobsCarouselItems: state.jobDetails.collaboratedJobsCarouselItems,
  recommendedJobsCarouselItems: state.jobs.recommendedJobsCarouselItems,
  jobDetails: state.jobDetails.jobDetails,
  myJobs: state.myJobs.myJobs,
  recommendedJobs: state.jobs.recommendedJobs,
  candidateId: state.auth.candidateId,
  recruiterDetails: state.consultant.recruiterDetails,
  email: state.auth.email,
});

const mapDispatchToProps = (dispatch) => ({
  sendEmail: (reqObj: reqObjType) => dispatch(postEmail(reqObj)),
  fetchRecruiterDetails: (candidateId: number) => dispatch(fetchRecruiterDetails(candidateId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(JobCardEmail);