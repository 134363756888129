import {
  POST_EMAIL_SUCCESS,
  POST_EMAIL_REQUEST,
  POST_EMAIL_FAILURE,
  SET_EMAIL_TO_DEFAULT,
} from "./action";

const initialState = {
  emailSuccessResponse: [],
  error: null,
  loading: false,
  emailSuccess: false,
};
const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case POST_EMAIL_REQUEST:
      return { ...state, loading: true, actionJobId: action.payload.jobId };
    case POST_EMAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        emailSuccessResponse: action.payload.data,
        emailSuccess: true,
        error: "",
      };
    case POST_EMAIL_FAILURE:
      return { ...state, loading: false, emailSuccess: false, error: action.payload };
    case SET_EMAIL_TO_DEFAULT:
      return { ...state, emailSuccess: false, error: null, emailSuccessResponse: { status: null } };
    default:
      return state;
  }
};

export default rootReducer;
