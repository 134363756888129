const referencesSurveyConstraint = {
  professionalStandards: {
    presence: { allowEmpty: false },
  },
  attendanceStandards: {
    presence: { allowEmpty: false },
  },
  clinicalStandards: {
    presence: { allowEmpty: false },
  },
};
const referencesSurveyFormConstraints = {
  references: {
    surveyListCustom: true,
  },
};

const config = {
  EMAIL_REGEX:
    "^(?:[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+(?:[.A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+)*)@(?:(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\\.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?)$",
  NAME_REGEX: "^[^~`!@#$%^&*()_+=,\"{}|:;\"\\\\/<>?\\[\\]]+$",
  NAME_REGEX_BASIC_INFO: "^[^~`!@#$%^&*,\"()_+={}|:;\"\\\\\\\\/<>?\\\\[\\\\]]+$",
  PHONE_REGEX: "^[0-9]{10}$",
  PHONE_REGEX_BASIC_INFO: "^\\d{10}$",
  NUMBER_REGEX: "^\\d+$",
  EMAIL_REGEX_BASIC_INFO:
    "^$|^(?!.*--)(?!.*\\.\\.)\\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,3})+$",
};

const referencesFormConstraints = {
  firstName: {
    presence: { allowEmpty: false },
    format: {
      pattern: config.NAME_REGEX,
      message: "* Please enter a first name that doesn't contain special characters.",
    },
    length: {
      maximum: 50,
      message: "* First name should not exceed the max length of 50 characters!",
    },
  },
  lastName: {
    presence: { allowEmpty: false },
    format: {
      pattern: config.NAME_REGEX,
      message: "* Please enter a last name that doesn't contain special characters.",
    },
    length: {
      maximum: 50,
      message: "* Last name should not exceed the max length of 50 characters!",
    },
  },
  title: {
    presence: { allowEmpty: false },
  },
  facilityNameDropDown: {
    presence: { allowEmpty: false },
  },
  email: {
    format: {
      pattern: `^$|${config.EMAIL_REGEX}`,
      message: "* Please insert a valid email address.",
    },
  },
  phone: {
    format: {
      pattern: `^$|${config.PHONE_REGEX_BASIC_INFO}`,
      message: "* Phone number should not contain special characters. 10 digits only.",
    },
  },
};
const referencesPageConstraints = {
  references: {
    referencesListCustom: true,
  },
};
export default {
  referencesFormConstraints,
  referencesPageConstraints,
  referencesSurveyConstraint,
  referencesSurveyFormConstraints,
};
