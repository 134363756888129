import FormControl from "@mui/material/FormControl";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getAccessToken, getRefreshToken } from "axios-jwt";
import { saveTokenInLocalStorageAndFillState } from "src/redux/auth/action";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import PaycheckIcon from "src/imagesV2/paycheck-icon.png";
import payCheckMoneyIcon from "src/imagesV2/pay-check-money-icon.png";
import Button from "@mui/material/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { isMobile } from "react-device-detect";
import CustomNavMob from "src/components/common/Navigation/CustomNavMob";
import PayrollNavV2 from "src/components/common/MainContent/Payroll/PayrollNavV2";
import Container from "react-bootstrap/Container";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { withTranslation, useTranslation } from "react-i18next";
import { EZSTUBS_URL } from "src/constants/external_links";
import { getAllPaystubs, getPaystub } from "src/services/GraphQL/graph.query";
import { formatCurrency } from "src/utils/formatCurrency";
import { graphqlRequest as request } from "src/services/GraphQL/GraphQL";
import Analytics from "src/services/Analytics";
import PaystubsSkeleton from "src/components/common/MainContent/Payroll/Paystubs/PaystubsSkeleton/PaystubsSkeleton";
import PaystubsNoAssignments from "./PaystubsNoAssignments/PaystubsNoAssignments";
import EarningsTable from "./Tables/EarningsTable/EarningsTable";
import ReimbursementsTable from "./Tables/ReimbursementsTable/ReimbursementsTable";
import TaxTable from "./Tables/TaxTable/TaxTable";
import SickTable from "./Tables/SickTable/SickTable";
import DeductionsTable from "./Tables/DeductionsTable/DeductionsTable";
import "src/components/common/MainContent/Payroll/Paystubs/Paystubs.scss";

dayjs.extend(utc);

export interface RootState {
  candidate: {
    atsBullhornCandidateId: string;
    isInitApiLoading: boolean;
  };
  company: {
    company: {
      id: any;
    };
  };
  auth: {
    email: string;
    userId: string;
    firstName: string;
    lastName: string;
    phone: number;
  };
}

const PayStubsMainV2 = () => {
  const { t } = useTranslation();
  const [payStubs, setPayStubs] = useState<any[] | []>([]);
  const [payStubDetails, setPayStubDetails] = useState<any>({});
  const [selectedPayStub, setSelectedPayStub] = useState<any>({});
  const [isPaystubsFetched, setIsPaystubFetched] = useState<any>(false);
  const [isPaystubsLoading, setIsPaystubsLoading] = useState<any>(true);
  const [apiResponseStatus, setApiResponseStatus] = useState<any>(200);
  const company = useSelector((state: RootState) => state.company.company);
  const trackingDetails = {
    email: useSelector((state: RootState) => state.auth.email),
    userId: useSelector((state: RootState) => state.auth.userId),
    firstName: useSelector((state: RootState) => state.auth.firstName),
    lastName: useSelector((state: RootState) => state.auth.lastName),
    url: EZSTUBS_URL,
    linkTitle: t("payStubs.downloadPayStub"),
  };
  const dispatch = useDispatch();
  const [authToken, setAuthToken] = useState<any>(getAccessToken());
  const { atsBullhornCandidateId, isInitApiLoading } = useSelector(
    (state: RootState) => state.candidate
  );

  const fetchPaystubDetails = async (id, candidateId) => {
    if (id && candidateId) {
      const query: any = {};
      query.candidateId = candidateId;
      query.paystubId = id.toString();
      const paystubResponse = await request(getPaystub, query, authToken);
      setPayStubDetails(paystubResponse?.getPaystub?.content);
      setApiResponseStatus(paystubResponse?.getPaystub?.status);
    }
  };

  const fetchPaystubs = async (candidateId) => {
    if (candidateId) {
      const query: any = {};
      query.candidateId = candidateId;
      const paystubResponse = await request(getAllPaystubs, query, authToken);
      const paystubList = paystubResponse?.getAllPaystubs?.content || [];
      setApiResponseStatus(paystubResponse?.getAllPaystubs?.status);
      if (paystubResponse?.getAllPaystubs?.status === 200) {
        setIsPaystubFetched(true);
        setIsPaystubsLoading(false);
        setPayStubs(paystubList);
        if (paystubList?.length > 0) {
          setSelectedPayStub(paystubList[0]);
          await fetchPaystubDetails(paystubList[0]?.id, candidateId);
        }
      }
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (atsBullhornCandidateId) {
      fetchPaystubs(atsBullhornCandidateId);
    } else if (!isInitApiLoading && atsBullhornCandidateId === null) {
      setIsPaystubsLoading(false);
    }
    if (apiResponseStatus === 401) {
      dispatch(
        saveTokenInLocalStorageAndFillState(getAccessToken(), getRefreshToken(), company?.id, null)
      );
      setAuthToken(getAccessToken());
    }
  }, [atsBullhornCandidateId, isInitApiLoading]);

  useEffect(() => {
    if (selectedPayStub && atsBullhornCandidateId) {
      fetchPaystubDetails(selectedPayStub.id, atsBullhornCandidateId);
    }
  }, [selectedPayStub]);

  const handleSelect = (event) => {
    setSelectedPayStub(event?.target?.value);
  };

  const convert = (payPeriod) => {
    if (payPeriod) {
      const payPeriodDate = payPeriod
        .split(" - ")
        .map((date) => dayjs(date).utc().format("MMM D, YYYY"))
        .join(" - ");
      return payPeriodDate;
    }
    return payPeriod;
  };

  const trackClickEvent = () => {
    const eventName: string = t("payStubs.downloadPayStub");
    if (trackingDetails) Analytics.segmentTrackEvent(eventName, trackingDetails);
  };

  return (
    <>
      <Container fluid>
        <Row>
          <div id="paymentDeductionPg" className="payRollNavMargin">
            <div className={!isMobile ? "payrollNavWrap" : "myProfileSubNav"}>
              {isMobile ? <CustomNavMob type="payroll" /> : <PayrollNavV2 />}
            </div>
            <div className="mainPaystub">
              <Container>
                {isPaystubsFetched && (
                  <>
                    {payStubs?.length > 0 ? (
                      <div className="paystubsPageWrapper">
                        <h2 className="pageHeading">{t("payStubs.payStatement")}</h2>
                        <div className="paystubsPageHeader">
                          <div className="paystubsPageHeaderInner">
                            <Row className="paystubsPageHeaderRow">
                              <Col md={4} xl={5} className="payCheckDateDropdownWrapper">
                                <div>
                                  <img
                                    src={PaycheckIcon}
                                    className="datePaycheckIcon"
                                    alt="Pay Check Icon"
                                    title="Pay Check Icon"
                                  />
                                </div>

                                <FormControl variant="outlined" className="payCheckDateDropdown">
                                  <InputLabel id="state" shrink>
                                    {t("payStubs.payCheckDate")}
                                  </InputLabel>
                                  <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={selectedPayStub}
                                    label="paycheck-date"
                                    onChange={handleSelect}
                                    MenuProps={{
                                      PaperProps: {
                                        style: {
                                          maxHeight: "300px",
                                        },
                                      },
                                    }}
                                    renderValue={(value) => (
                                      <b className="payCheckDate">
                                        {dayjs(value.checkDate).utc().format("MMM D, YYYY")}
                                      </b>
                                    )}
                                  >
                                    {[...payStubs]?.map((payStub) => (
                                      <MenuItem key={payStub} value={payStub}>
                                        <div className="menuItemInner">
                                          <div className="iconDateWrapper">
                                            <span className="icon">
                                              <img
                                                src={payCheckMoneyIcon}
                                                alt="payCheckMoneyIcon"
                                                title="payCheckMoneyIcon"
                                              />
                                            </span>
                                            <p className="date">
                                              {dayjs(payStub.checkDate).utc().format("MMM D, YYYY")}
                                            </p>
                                          </div>
                                          <div className="dollar">
                                            {formatCurrency(payStub.totalNetPay)}
                                          </div>
                                        </div>
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </Col>
                              <Col md={3} xl={3} className="payPeriod">
                                <span>{t("payStubs.payPeriod")}</span>
                                <p className="payPeriodDate">{convert(payStubDetails.payPeriod)}</p>
                              </Col>
                              <Col md={2} xl={2} className="patRate">
                                <h4>{formatCurrency(payStubDetails.totalNetPay)}</h4>
                                <span>
                                  {t("payStubs.takeHome")} <b>{payStubDetails.totalHours}</b>{" "}
                                  {t("payStubs.hrs")}
                                </span>
                              </Col>
                              <Col md={3} xl={2} className="viewPaystubButtonWrapper">
                                <a href={EZSTUBS_URL} target="_blank" rel="noreferrer">
                                  <Button
                                    size="medium"
                                    variant="contained"
                                    className="viewPaystubButton"
                                    onClick={trackClickEvent}
                                  >
                                    {t("payStubs.downloadPayStub")}
                                  </Button>
                                </a>
                              </Col>
                            </Row>
                          </div>
                          <div className="grossPay">
                            <h4>{t("payStubs.grossPay")}</h4>
                            <h4>{formatCurrency(payStubDetails.taxableGross)}</h4>
                          </div>
                        </div>

                        <EarningsTable payStubDetails={payStubDetails} />

                        {payStubDetails?.reimbursements?.length > 0 && (
                          <ReimbursementsTable payStubDetails={payStubDetails} />
                        )}

                        <TaxTable payStubDetails={payStubDetails} />

                        {payStubDetails?.deductions?.length > 0 && (
                          <DeductionsTable payStubDetails={payStubDetails} />
                        )}
                        {payStubDetails?.pto?.length > 0 && (
                          <SickTable payStubDetails={payStubDetails} />
                        )}
                      </div>
                    ) : (
                      <PaystubsNoAssignments />
                    )}
                  </>
                )}
                {(isInitApiLoading || isPaystubsLoading) && <PaystubsSkeleton />}
                {!isInitApiLoading && atsBullhornCandidateId === null && <PaystubsNoAssignments />}
              </Container>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};
export default withTranslation()(PayStubsMainV2);
