import { push } from "connected-react-router";

export const AUTHENTICATION_SUCCESS = "AUTHENTICATION_SUCCESS";
export const userAuthenticationSuccess = (payload) => ({ type: AUTHENTICATION_SUCCESS, payload });

// eslint-disable-next-line no-unused-vars
export const userAuthentication = (reqObj) => {
  const apiResponse = true;
  return (dispatch) => {
    if (apiResponse) {
      dispatch(userAuthenticationSuccess(apiResponse));
      dispatch(push("/home"));
    }
  };
};
