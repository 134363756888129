import { withTranslation } from "react-i18next";
import Container from "react-bootstrap/Container";
import { connect } from "react-redux";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {
  RECOMMENDED_JOBS,
  HOMEPAGE,
} from "src/constants/campaigns_constants";
import SkeletonUi from "src/components/common/MainContent/Home/Skeleton";
import JobCardContainerV2 from "src/components/common/MainContent/JobResults/JobCardContainerV2";
import { EmailRecruiterV2Props } from "src/components/common/MainContent/JobResults/common";
import times from "lodash/times";
import { v4 as uuid } from "uuid";
import { useEffect } from "react";

interface RecommendedJobsV2Props {
  recommendedJobs: EmailRecruiterV2Props["recommendedJobs"];
  viewMyJobs: boolean;
  recommended: boolean;
  isRecommendedJobsOnResult: boolean;
}

const RecommendedJobsV2 = ({
  recommendedJobs,
  viewMyJobs,
  recommended,
  isRecommendedJobsOnResult,
}: RecommendedJobsV2Props) => {
  const skeletonContainers = (key) => (
    <Col key={key} data-testid="skeleton" sm={12} md={3} lg={3} xs={12}>
      <SkeletonUi />
    </Col>
  );

  useEffect(() => {}, [recommendedJobs?._embedded?.results]);

  return (
    <>
      {recommendedJobs && recommendedJobs._embedded.results.length !== 0 ? (
        <Container data-testid="JobCardContainer" fluid className="JobCardContainer">
          <JobCardContainerV2
            jobs={recommendedJobs._embedded.results}
            isRecommendedJobsOnResult={isRecommendedJobsOnResult}
            viewMyJobs={viewMyJobs}
            recommended={recommended}
            campaignCode={RECOMMENDED_JOBS}
            sourceType={HOMEPAGE}
            similarOrViewed=""
            jobsSource={recommendedJobs._embedded.jobsSource}
          />
        </Container>
      ) : (
        <Container className="skeletonMain">
          <Row>{times(4, () => skeletonContainers(uuid()))}</Row>
        </Container>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  recommendedJobs: state.jobs.recommendedJobs,
});

export default connect(mapStateToProps)(withTranslation()(RecommendedJobsV2));
