import { useEffect } from "react";

const useFooterMargin = () => {
  useEffect(() => {
    const quickApplyCardUpper = document.getElementsByClassName(
      "quickApplyCardFooter"
    )[0] as HTMLElement;
    const quickApplyCardLower = document.getElementsByClassName(
      "fixedAjobDescApplyBtnFixed"
    )[0] as HTMLElement;
    const footerElement = document.getElementsByTagName("footer")[0];

    if (footerElement === undefined) {
      return;
    }

    if (window.innerWidth < 768 && quickApplyCardLower && quickApplyCardUpper) {
      footerElement.style.marginBottom = "150px";
    } else {
      footerElement.style.marginBottom = "0px";
    }

    return () => {
      footerElement.style.marginBottom = "0px";
    };
  }, []);
};

export default useFooterMargin;
