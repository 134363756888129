import React from "react";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Button from "@material-ui/core/Button";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { makeStyles } from "@material-ui/core/styles";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { isMobile } from "react-device-detect";
import { useHistory } from "react-router-dom";
import dateFormat from "dateformat";
import CircularProgressWithLabel from "../OnBoarding/SkillChecklist/OnBoardingCircularProgress";
import { SKILL_CHECKLIST_OVERVIEW_PAGE } from "../../../router/routes";
import {
  saveSkillChecklistSelected,
  saveOnboardingComplete,
  isNavigateToSkillChecklistDetails,
  saveSkillChecklistInAble,
} from "../../../redux/onBoarding/action";
import OnBoardingSkillRadio from "../OnBoarding/SkillChecklist/OnBoardingSkillRadio";
import OnBoardingSignature from "../OnBoarding/SkillChecklist/OnBoardingSignature";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

function SkillRatingsProfile(props) {
  const history = useHistory();
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const [showSaveAndUpload, setShowSaveAndUpload] = React.useState(
    props.skillChecklistSelected ? !!props.skillChecklistSelected.signature : false
  );
  const [openProgress, setOpenProgress] = React.useState(false);
  const [loaderLabel, setLoaderLabel] = React.useState(null);
  const [signatureURL, setSignatureURL] = React.useState(null);
  const [openSection, setOpenSection] = React.useState(false);
  const [showErrMsg, setShowErrMsg] = React.useState(false);
  const [selectedGroup, setSelectedGroup] = React.useState(null);
  const [selectedGroupSkills, setSelectedGroupSkills] = React.useState(null);
  const signatureRef = React.useRef(null);
  const [skillChecklistSelected] = React.useState(props.skillChecklistSelected);
  const [skillsChecklistGroupSkills] = React.useState(props.skillsChecklistGroupSkills);
  const [formChangeDetected, setFormChangeDetected] = React.useState(false);
  const [completedList] = React.useState(props.completedSkillsCheckList);
  const [pageOffsetY, setPageOffsetY] = React.useState(window.pageYOffset);
  const [selectedGroupIndex, setSelectedGroupIndex] = React.useState(null);
  const [reRenderComponentItem, setReRenderComponentItem] = React.useState(true);
  const [isGoBackBtnClicked, setGoBackBtnClicked] = React.useState(false);
  const handleopenProgress = () => {
    setOpenProgress(true);
  };
  const handleCloseProgress = () => {
    setOpenProgress(false);
  };
  React.useEffect(() => {
    const completedSkillsGroupFromStore = completedList;
    if (skillChecklistSelected) {
      for (let i = 0; i < skillChecklistSelected.groups.length; i++) {
        let isPresent = true;
        for (let j = 0; j < skillChecklistSelected.groups[i].skills.length; j++) {
          if (
            skillsChecklistGroupSkills.some(
              (skillsChecklistGroupSkill) =>
                skillsChecklistGroupSkill.name === skillChecklistSelected.groups[i].skills[j].name
            )
          ) {
            isPresent = isPresent && true;
          } else {
            isPresent = isPresent && false;
            break;
          }
        }
        if (isPresent) {
          if (!completedSkillsGroupFromStore.includes(skillChecklistSelected.groups[i].name)) {
            completedSkillsGroupFromStore.push(skillChecklistSelected.groups[i].name);
          }
        }
      }
    }
  }, [skillsChecklistGroupSkills, skillChecklistSelected, completedList]);

  React.useEffect(() => {
    window.scrollTo(0, 0);
    const arr = []; // Array to hold the keys
    // Iterate over localStorage and insert the keys that meet the condition into arr
    for (let i = 0; i < localStorage.length; i++) {
      if (
        localStorage.key(i).substring(0, 6) === "groupn" ||
        localStorage.key(i).substring(0, 6) === "groupv"
      ) {
        arr.push(localStorage.key(i));
      }
    }
    // Iterate over arr and remove the items by key
    for (let j = 0; j < arr.length; j++) {
      localStorage.removeItem(arr[j]);
    }
  }, []);

  const handleChange = (panel, index) => (event, isExpanded) => {
    setSelectedGroupIndex(index);
    if (panel === "completeFlag") {
      setExpanded(isExpanded);
    } else if ((!openSection && !showErrMsg) || completedList.includes(selectedGroup)) {
      setExpanded(isExpanded ? panel : false);
      setShowErrMsg(false);
      setOpenSection(true);
      setSelectedGroup(panel);
      setSelectedGroupSkills(
        skillChecklistSelected.groups.filter((group) => group.name === panel)[0]
      );
      if (pageOffsetY !== 0 && pageOffsetY < window.pageYOffset && isExpanded) {
        const offset =
          pageOffsetY + document.getElementById(panel).offsetHeight * (index - selectedGroupIndex);
        window.scrollTo(window.pageXOffset, offset);
        setPageOffsetY(offset);
      } else {
        setPageOffsetY(window.pageYOffset);
      }
    } else {
      setShowErrMsg(true);
      const missedSkill = selectedGroupSkills.skills.find((skill) => !skill.value);
      if (missedSkill && missedSkill.id) {
        document.getElementById(missedSkill.id).scrollIntoView({ block: "center" });
      }
    }
  };

  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    formChangeDetected ? setOpen(true) : redirectAboutSkillChecklist();
  };

  const redirectAboutSkillChecklist = () => {
    props.isNavigateToSkillChecklistDetails(false);
    history.push(SKILL_CHECKLIST_OVERVIEW_PAGE);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [openCancelBtn, setOpenCancelBtn] = React.useState(false);
  const handleCloseCancelBtn = () => {
    setOpenCancelBtn(false);
  };

  const [openRate, setOpenRate] = React.useState(false);

  const [openRateSelectedItem, setOpenRateSelectedItem] = React.useState("");
  const handleOpenRate = (item) => {
    setOpenRateSelectedItem(item);
    setOpenRate(isMobile ? item.shouldOpen : false);
  };

  const handleCloseRate = () => {
    setOpenRate(false);
  };

  const [bulkUpdateValue, setBulkUpdateValue] = React.useState(null);
  const [bulkUpdateGroupName, setBulkUpdateGroupName] = React.useState(null);
  const confirmBulkUpdate = (value, groupName) => {
    setOpenRate(false);
    setBulkUpdateValue(value);
    setBulkUpdateGroupName(groupName);
  };
  const deselectBulkUpdate = () => {
    setBulkUpdateValue(null);
  };

  const closeRadioPopup = () => {
    if (!openRateSelectedItem.isBulkUpdate) setOpenRate(false);
  };
  const reRenderComponent = () => {
    setReRenderComponentItem(!reRenderComponentItem);
  };
  const handleButtonRendering = (value, signature) => {
    setShowSaveAndUpload(value);
    setSignatureURL(signature);
  };
  const setSkillGroupCompleteFlag = () => {
    const now = new Date();
    handleopenProgress();
    const skillChecklistSelectedLocal = skillChecklistSelected;
    let skillChecklistCompleted = true;
    if (skillChecklistSelectedLocal) {
      for (let i = 0; i < skillChecklistSelectedLocal.groups.length; i++) {
        if (!completedList.includes(skillChecklistSelectedLocal.groups[i].name)) {
          skillChecklistCompleted = skillChecklistCompleted && false;
        }
      }
      if (skillChecklistCompleted && signatureURL) {
        skillChecklistSelectedLocal.complete = true;
        skillChecklistSelectedLocal.completedDate = dateFormat(now, "yyyy-mm-dd HH:MM:ss");
      }
      skillChecklistSelectedLocal.lastUpdated = dateFormat(now, "yyyy-mm-dd HH:MM:ss");
      const saveChecklistReqObj = {
        email: props.email,
        companyId: parseInt(props.company.id),
        skillsChecklist: skillChecklistSelectedLocal,
      };
      props.saveSkillChecklistSelected(skillChecklistSelectedLocal);
    }
  };
  const goBackToSkillsOverview = () => {
    handleClose();
    setGoBackBtnClicked(true);
    handleSaveButton();
  };

  const handleSaveButton = () => {
    isGoBackBtnClicked
      ? setLoaderLabel(props.t("onBoarding.skillChecklist.savingLoaderText"))
      : setLoaderLabel(props.t("onBoarding.skillChecklist.updatingLoaderText"));
    const skillWithSignature = skillChecklistSelected;
    skillWithSignature.signature = signatureURL;
    if (signatureURL) {
      skillWithSignature.complete = true;
      skillWithSignature.completedDate = dateFormat(new Date(), "yyyy-mm-dd HH:MM:ss");
    } else {
      skillWithSignature.complete = false;
    }
    props.saveSkillChecklistSelected(skillWithSignature);
    setSkillGroupCompleteFlag();
    props.saveSkillChecklistSelected(skillWithSignature);
    const reqObj = {
      applicantId: props.applicantId,
      candidateId: props.candidateId,
      weboneSkillsChecklist: skillChecklistSelected,
    };
    props.saveSkillChecklistInAble(reqObj).then(
      (response) => {
        handleCloseProgress();
        redirectAboutSkillChecklist();
      },
      (error) => {
        handleCloseProgress();
        redirectAboutSkillChecklist();
      }
    );
  };
  const handleSkillUpdated = (value) => {
    setFormChangeDetected(value);
  };
  function formatDropdownValue(skillChecklistGroupSkill) {
    if (skillChecklistGroupSkill.value) {
      if (
        props.updatedSkillsChecklist &&
        props.updatedSkillsChecklist.name === skillChecklistGroupSkill.name
      ) {
        return skillChecklistGroupSkill.value;
      }
      return skillChecklistGroupSkill.value;
    }
    return props.t("onBoarding.skillChecklist.addRating");
  }
  const renderGroupSkills = (skillChecklistsGroupName) => {
    const skillChecklistsGroups = skillChecklistSelected.groups;
    let skillChecklistGroupSkills = [];
    for (let i = 0; i < skillChecklistsGroups.length; i++) {
      if (skillChecklistsGroups[i].name === skillChecklistsGroupName) {
        skillChecklistGroupSkills = skillChecklistsGroups[i].skills;
      }
    }
    return skillChecklistGroupSkills.map((skillChecklistGroupSkill) => (
      <Row
        className={
          showErrMsg && !skillChecklistGroupSkill.value
            ? "onBSkillCheckBtmBorder onBSkillCheckError"
            : "onBSkillCheckBtmBorder"
        }
        onClick={() =>
          handleOpenRate({
            skillCheckListGroupSkillAsProp: skillChecklistGroupSkill,
            skillChecklistGroupName: skillChecklistsGroupName,
            isBulkUpdate: false,
            shouldOpen: true,
          })
        }
        key={skillChecklistGroupSkill.id}
        id={skillChecklistGroupSkill.id}
      >
        <Col sm={12} md={12} lg={12} xs={12}>
          <div className="onBSubSkill">
            {skillChecklistGroupSkill.name}
            {skillChecklistGroupSkill.value ? (
              ""
            ) : (
              <span className="onBSkillsGroupErr">
                {!isMobile ? <>&nbsp; &nbsp;</> : ""}
                {showErrMsg ? props.t("onBoarding.skillChecklist.skillsIncompleteErrorMsg") : ""}
              </span>
            )}
          </div>
        </Col>
        <Col sm={12} md={12} lg={12} xs={12}>
          {isMobile ? (
            <>
              <span
                className={
                  formatDropdownValue(skillChecklistGroupSkill) ===
                  props.t("onBoarding.skillChecklist.addRating")
                    ? "onBSkillAddRatingMobile"
                    : "onBSkillRatingMobColor"
                }
              >
                {formatDropdownValue(skillChecklistGroupSkill)}
              </span>
              <ArrowDropDownIcon className="onBSkillRatingDropDownIcon" />
            </>
          ) : (
            <OnBoardingSkillRadio
              skillUpdated={handleSkillUpdated}
              skillChecklistGroupName={skillChecklistsGroupName}
              skillCheckListGroupSkillAsProp={skillChecklistGroupSkill}
              isBulkUpdate={false}
            />
          )}
        </Col>
      </Row>
    ));
  };

  const renderGroups = () => {
    const skillChecklistsGroups = skillChecklistSelected.groups;
    return skillChecklistsGroups.map((skillChecklistsGroup, index) => (
      <Accordion
        expanded={expanded === skillChecklistsGroup.name}
        onChange={handleChange(skillChecklistsGroup.name, index)}
        key={skillChecklistsGroup.id}
        id={skillChecklistsGroup.name}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          className="onBSkillCheckAccorIcon"
        >
          <Typography className="onBMainSkill">{skillChecklistsGroup.name}</Typography>
          &nbsp; &nbsp;&nbsp;&nbsp;
          {completedList.includes(skillChecklistsGroup.name) ||
          completedList.includes(skillChecklistsGroup.name) ? (
            <div className="onBCompleteStatus">{props.t("onBoarding.skillChecklist.complete")}</div>
          ) : (
            <div className="onBIncompleteStatus">
              {props.t("onBoarding.skillChecklist.inComplete")}
            </div>
          )}
        </AccordionSummary>
        <AccordionDetails>
          <Container>
            <Row
              className="onBQuickSetFirstColumn onBSkillCheckBtmBorder"
              onClick={
                isMobile
                  ? () =>
                      handleOpenRate({
                        skillChecklistGroupName: skillChecklistsGroup.name,
                        isBulkUpdate: true,
                        shouldOpen: true,
                      })
                  : () => reRenderComponent()
              }
            >
              <Col sm={12} md={12} lg={12} xs={12}>
                <div className="onBSubSkill">
                  {props.t("onBoarding.skillChecklist.quickSetFeature")}
                </div>
              </Col>
              <Col sm={12} md={12} lg={12} xs={12}>
                {isMobile ? (
                  <>
                    <span
                      className={
                        bulkUpdateValue && bulkUpdateGroupName === skillChecklistsGroup.name
                          ? "onBSkillRatingMobColor"
                          : "onBSkillAddRatingMobile"
                      }
                    >
                      {bulkUpdateValue && bulkUpdateGroupName === skillChecklistsGroup.name
                        ? bulkUpdateValue
                        : props.t("onBoarding.skillChecklist.addRating")}
                    </span>
                    <ArrowDropDownIcon className="onBSkillRatingDropDownIcon" />
                  </>
                ) : (
                  <OnBoardingSkillRadio
                    isBulkUpdate
                    skillUpdated={handleSkillUpdated}
                    deselectBulkUpdate={deselectBulkUpdate}
                    skillChecklistGroupName={skillChecklistsGroup.name}
                    onClick={() => reRenderComponent()}
                  />
                )}
              </Col>
            </Row>
            <div>{renderGroupSkills(skillChecklistsGroup.name)}</div>
          </Container>
        </AccordionDetails>
      </Accordion>
    ));
  };
  return (
    <div key={completedList}>
      <Container fluid className="profileSkillWrap">
        <Container>
          <Row>
            <Col sm={12} xs={12} md={1} lg={1} />
            <Col sm={12} xs={12} md={10} lg={10}>
              <div className="pt-4">
                <div>
                  <Button className="onBSecondaryBtn" onClick={handleOpen}>
                    <ArrowBackIcon fontSize="small" />
                    &nbsp;{props.t("onBoarding.skillChecklist.goBack")}
                  </Button>
                </div>
                <Modal
                  className={classes.modal}
                  open={open}
                  onClose={handleClose}
                  closeAfterTransition
                  BackdropComponent={Backdrop}
                  BackdropProps={{
                    timeout: 500,
                  }}
                >
                  <Fade in={open}>
                    <div className="onBSkillsCheckPopUpSkillRate">
                      <div className="onBSkillCheckPopUpTxt textCenter">
                        {props.t("onBoarding.hey")} {props.firstName}
                        {props.t("onBoarding.skillChecklist.backButtonText")}
                      </div>
                      <br />
                      <div className="textCenter">
                        <Button
                          className="onBSpecSaveandContinueBtn"
                          onClick={goBackToSkillsOverview}
                        >
                          {props.t("onBoarding.skillChecklist.saveAndContinue")}
                        </Button>
                        &nbsp;&nbsp;
                        <Button className="onBSpecProvideSkillBtn" onClick={handleClose}>
                          {props.t("onBoarding.skillChecklist.provideSkills")}
                        </Button>
                      </div>
                    </div>
                  </Fade>
                </Modal>
                {/* Cancel Button Modal Code start */}
                <Modal
                  className={classes.modal}
                  open={openCancelBtn}
                  onClose={handleCloseCancelBtn}
                  closeAfterTransition
                  BackdropComponent={Backdrop}
                  BackdropProps={{
                    timeout: 500,
                  }}
                >
                  <Fade in={openCancelBtn}>
                    <div className="onBSkillsCheckPopUpDesk">
                      <div className="textCenter onBSkillRatingDesk">
                        {props.t("onBoarding.skillChecklist.skillCheckListCancelBtnMsg")}
                      </div>
                      <br />
                      <div className="textCenter">
                        {" "}
                        <Button className="onBSkillYesBtn">
                          {props.t("onBoarding.skillChecklist.yesBtnLbl")}
                        </Button>
                        &nbsp;&nbsp;
                        <Button className="onBNoBtn">
                          {props.t("onBoarding.skillChecklist.noBtnLbl")}
                        </Button>
                      </div>
                    </div>
                  </Fade>
                </Modal>
                {/* Cancel Button Modal Code End */}
                <Modal
                  className={classes.modal}
                  open={openRate}
                  onClose={handleCloseRate}
                  onClick={closeRadioPopup}
                  closeAfterTransition
                  BackdropComponent={Backdrop}
                  BackdropProps={{
                    timeout: 500,
                  }}
                >
                  <Fade in={openRate}>
                    {!isMobile ? (
                      <div className="onBSkillsCheckPopUpDesk">
                        <div className="textCenter onBSkillRatingDesk">
                          {props.t("onBoarding.skillChecklist.quickSetPopupMsg")}
                          <b>Some Experience</b> {props.t("onBoarding.skillChecklist.inThisGroup")}
                        </div>
                        <br />
                        <div className="textCenter">
                          {" "}
                          <Button className="onBSkillYesBtn">
                            {props.t("onBoarding.skillChecklist.yesBtnLbl")}
                          </Button>
                          &nbsp;&nbsp;
                          <Button className="onBNoBtn">
                            {props.t("onBoarding.skillChecklist.noBtnLbl")}
                          </Button>
                        </div>
                      </div>
                    ) : (
                      <div className="onBSkillsCheckPopUp">
                        <OnBoardingSkillRadio
                          isBulkUpdate={openRateSelectedItem.isBulkUpdate}
                          skillUpdated={handleSkillUpdated}
                          confirmBulkUpdate={confirmBulkUpdate}
                          deselectBulkUpdate={deselectBulkUpdate}
                          skillChecklistGroupName={openRateSelectedItem.skillChecklistGroupName}
                          skillCheckListGroupSkillAsProp={
                            openRateSelectedItem.skillCheckListGroupSkillAsProp
                          }
                        />
                      </div>
                    )}
                  </Fade>
                </Modal>
                <Modal
                  className={classes.modal}
                  open={openProgress}
                  onClose={handleCloseProgress}
                  closeAfterTransition
                  BackdropComponent={Backdrop}
                  BackdropProps={{
                    timeout: 500,
                  }}
                >
                  <Fade in={openProgress}>
                    <div>
                      <CircularProgressWithLabel loaderText={loaderLabel} />
                    </div>
                  </Fade>
                </Modal>
                <h3 className="onBSkillCheckTitle">
                  {skillChecklistSelected
                    ? skillChecklistSelected.name
                    : "No Skill Checklist Found!"}
                </h3>
                <div className="onBWorkExpText">
                  {props.t("onBoarding.skillChecklist.proficiencyExplanation")}
                  <br /> <br />
                  <div className="onBSkillRatingKeys">
                    <div className="onBSkillRatingHead">
                      {props.t("onBoarding.skillChecklist.ratingKeys")}
                    </div>
                    <div className="onBSkillRatingKeysList mt-2">
                      <b>{props.t("onBoarding.skillChecklist.notApplicable")}</b>
                    </div>
                    <div className="onBSkillRatingKeysList">
                      <b>{props.t("onBoarding.skillChecklist.noExperience")}</b>
                    </div>
                    <div className="onBSkillRatingKeysList">
                      <b>{props.t("onBoarding.skillChecklist.someExperience")}</b>
                      {props.t("onBoarding.skillChecklist.someExperienceText")}
                    </div>
                    <div className="onBSkillRatingKeysList">
                      <b>{props.t("onBoarding.skillChecklist.intermittentExperience")}</b>
                      {props.t("onBoarding.skillChecklist.intermittentExperienceText")}
                    </div>
                    <div className="onBSkillRatingKeysList">
                      <b>{props.t("onBoarding.skillChecklist.experienced")}</b>
                      {props.t("onBoarding.skillChecklist.experiencedText")}
                    </div>
                    <div className="onBSkillRatingKeysList">
                      <b>{props.t("onBoarding.skillChecklist.veryExperienced")}</b>
                      {props.t("onBoarding.skillChecklist.veryExperiencedText")}
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <br />
              <div className="onBSkillCheckAccordMain">
                <div className="onBSkillRatingHead mb-2">
                  {props.t("onBoarding.skillChecklist.skillsGroup")}
                </div>
                {skillChecklistSelected ? renderGroups() : "No Skill Checklist found"}
              </div>
              <Row>
                <Col sm={12} md={12} lg={12} xs={12} className="textCenter">
                  <OnBoardingSignature
                    ref={signatureRef}
                    showSaveBtn={handleButtonRendering}
                    skillUpdated={handleSkillUpdated}
                  />
                  {showSaveAndUpload ? (
                    <Button
                      skillUpdated={handleSkillUpdated}
                      className="onBPrimaryBtn"
                      onClick={handleSaveButton}
                    >
                      {props.t("onBoarding.skillChecklist.saveAndUpload")}
                    </Button>
                  ) : null}
                  &nbsp;&nbsp;&nbsp;&nbsp;
                </Col>
              </Row>
              <div className="onBSaveandUpload">
                <Button
                  className="onBSecondaryBtn onBGoBackBtn onBGoBackProfileBtn"
                  onClick={handleOpen}
                >
                  <ArrowBackIcon fontSize="small" /> &nbsp;
                  {props.t("onBoarding.skillChecklist.goBack")}
                </Button>
              </div>
            </Col>
            <Col sm={12} xs={12} md={1} lg={1} />
          </Row>
        </Container>
      </Container>
    </div>
  );
}

const mapStateToProps = (state) => ({
  skillChecklistSelected: state.onBoarding.skillChecklistSelected,
  skillsChecklistGroupSkills: state.onBoarding.skillsChecklistGroupSkills,
  completedSkillsCheckList: state.onBoarding.completedSkillsCheckList,
  firstName: state.auth.firstName,
  company: state.company.company,
  email: state.auth.email,
  isSkillCompleted: state.onBoarding.selectedSkillCompleted,
  applicantId: state.candidate.applicantId,
  userId: state.auth.userId,
  candidateSpecialties: state.auth.candidateSpecialties,
  candidateId: state.auth.candidateId,
  saveAndUploadLoading: state.onBoarding.loading,
  updatedSkillsChecklist: state.onBoarding.updatedSkillsChecklist,
});
const mapDispatchToProps = (dispatch) => ({
  saveSkillChecklistSelected: (skillSelected) =>
    dispatch(saveSkillChecklistSelected(skillSelected)),
  saveOnboardingComplete: (reqObj, triggeringPoint, skillChecklistCompleteStatus) =>
    dispatch(saveOnboardingComplete(reqObj, triggeringPoint, skillChecklistCompleteStatus)),
  isNavigateToSkillChecklistDetails: (value) => dispatch(isNavigateToSkillChecklistDetails(value)),
  saveSkillChecklistInAble: (obj) => dispatch(saveSkillChecklistInAble(obj)),
});
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(SkillRatingsProfile));
