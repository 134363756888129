import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { withTranslation } from "react-i18next";
import { Container } from "react-bootstrap";
import { CardContent, Card } from "@material-ui/core";
import { isMobile } from "react-device-detect";
import clsx from "clsx";
import PropTypes from "prop-types"
import style from "./JobViewsDashboardAdvertisement.module.scss";

interface JobViewsSkeletonProps {
  shouldWrap?: boolean;
  width?: number;
  height?: number;
  isEligibilityCard?:boolean
}

const JobViewsSkeleton: React.FC<JobViewsSkeletonProps> = ({ shouldWrap, width, height,isEligibilityCard }) => {
  return (
    <Container className={clsx(isEligibilityCard && isMobile && "p-0" )}>
      <Stack>
        <Grid container wrap={shouldWrap?"wrap":"nowrap"}>
          {Array.from(new Array(isMobile ? 1 : 4)).map(() => (
            <Box sx={{ width: width || 350,
               marginRight: isMobile ? 0 : 4, marginBottom:isEligibilityCard?3:0,marginTop:isEligibilityCard?0:3}}>
              <Card style={{height:height || 100}}>
                <CardContent>
                  <span className={style.jobsSkeletonHeader}>
                    <span className={style.jobsCitySkeleton}>
                      <Skeleton
                        data-testid="skeleton-element"
                        className={style.jobViewSkeleton}
                        animation="wave"
                        variant="rounded"
                        height={9}
                      />
                    </span>
                    <Skeleton
                      data-testid="skeleton-element"
                      className={style.jobViewChipSkeleton}
                      animation="wave"
                      variant="rounded"
                    />
                  </span>
                  <Skeleton
                    data-testid="skeleton-element"
                    className={style.jobViewSkeletonSecond}
                    animation="wave"
                    variant="rounded"
                    height={9}
                  />
                  <Skeleton
                    data-testid="skeleton-element"
                    className={style.jobViewSkeletonThird}
                    animation="wave"
                    variant="rounded"
                    height={9}
                  />
                  <Skeleton
                    data-testid="skeleton-element"
                    className={style.jobViewSkeletonForth}
                    animation="wave"
                    variant="rounded"
                    height={9}
                  />
                  {
                    isEligibilityCard && (
                      <Skeleton
                    data-testid="skeleton-element"
                    className={style.jobViewSkeletonFifth}
                    animation="wave"
                    variant="rounded"
                    height={9}
                  />
                    )
                  }
                   
                </CardContent>
              </Card>
            </Box>
          ))}
        </Grid>
      </Stack>
    </Container>
  );
};
JobViewsSkeleton.propTypes = {
  shouldWrap: PropTypes.bool,
  width: PropTypes.number,
  height: PropTypes.number,
  isEligibilityCard:PropTypes.bool
};
JobViewsSkeleton.defaultProps = {
  shouldWrap: false,
  width: 350,
  height: 100,
  isEligibilityCard:false
};

export default withTranslation()(JobViewsSkeleton);
