import {
  FETCH_PROFESSIONS_REQUEST,
  FETCH_PROFESSIONS_SUCCESS,
  FETCH_PROFESSIONS_FAILURE,
} from "./action";

const initialState = {
  error: "",
  loading: false,
  profession: [],
};
const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PROFESSIONS_REQUEST:
      return { ...state, loading: true };

    case FETCH_PROFESSIONS_SUCCESS:
      return { ...state, loading: false, profession: action.payload, error: "" };

    case FETCH_PROFESSIONS_FAILURE:
      return { ...state, loading: false, error: action.payload, profession: [] };
    default:
      return state;
  }
};

export default rootReducer;
