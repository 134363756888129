import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  EmailShareButton,
  EmailIcon,
} from "react-share";
import { useTranslation } from "react-i18next";
import style from "./ShareJob.module.scss";
import createShareURL from "./ShareJob.helpers";

interface iShareJob {
  jobId: string;
  company: {
    id: number;
  };
}

const ShareJob = ({ jobId, company }: iShareJob): JSX.Element => {
  const { t } = useTranslation();
  const title = t("shareJob.jobInfo.dataDescriptionText");
  const shareUrl = createShareURL(company.id, jobId);
  const iconSize = 45;

  return (
    <div className={style.shareWrap}>
      <FacebookShareButton title={title} url={shareUrl} data-testid="facebook">
        <FacebookIcon size={iconSize} round />
      </FacebookShareButton>
      <TwitterShareButton title={title} url={shareUrl} data-testid="twitter">
        <TwitterIcon size={iconSize} round />
      </TwitterShareButton>
      <LinkedinShareButton title={title} url={shareUrl} data-testid="linkedin">
        <LinkedinIcon size={iconSize} round />
      </LinkedinShareButton>
      <EmailShareButton subject={title} url={shareUrl} data-testid="email">
        <EmailIcon size={iconSize} round />
      </EmailShareButton>
    </div>
  );
};

export default ShareJob;
