import { useState } from "react";
import { Box } from "@mui/material";
import StyledDialogDefault from "src/components/common/Dialog/StyledDialogDefault";
import PrimaryButton from "src/components/common/Buttons/PrimaryButton";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  errorTitle: {
    fontSize: "32px",
    fontWeight: "bold",
    lineHeight: 1.29,
    padding: "70px 0px 23px 0px",
    color: "#d7282f",
    "@media (max-width: 768px)": {
      fontSize: "16px",
      padding: "40px 0px 15px 0px",
    },
  },
  errorSubtitle: {
    color: "#707070",
    paddingBottom: "64px",
    "@media (max-width: 768px)": {
      paddingBottom: "34px",
    },
  },
  addCredentialsButton: {
    marginBottom: "64px",
    border: "none",
    "@media (max-width: 768px)": {
      marginBottom: "34px",
    },
  },
  errorMessage: {
    color: "#707070",
  },
}));

export interface GenericErrorMessagePopupProps {
  informativeMessage: string;
  tryAgainMessage: string;
  callBack: Function;
}

const GenericErrorMessagePopup = ({
  callBack,
  tryAgainMessage,
  informativeMessage,
}: GenericErrorMessagePopupProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(true);

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleCallBack = () => {
    setIsOpen(false);
    callBack();
  };

  return (
    <StyledDialogDefault open={isOpen} showClose={false} handleClose={handleClose}>
      <Box display="grid" alignItems="center" justifyContent="center" justifyItems="center">
        <span className={classes.errorTitle}>{t("genericError.title")}</span>
        {informativeMessage && <span className={classes.errorMessage}>{informativeMessage}</span>}
        {tryAgainMessage && <span className={classes.errorSubtitle}>{tryAgainMessage}</span>}
        <PrimaryButton
          component="button"
          size="medium"
          className={classes.addCredentialsButton}
          onClick={handleCallBack}
        >
          <span>{t("genericError.tryAgainButton")}</span>
        </PrimaryButton>
      </Box>
    </StyledDialogDefault>
  );
};

export default GenericErrorMessagePopup;
