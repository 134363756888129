import JobCardsMainContentV2 from "src/components/common/MainContent/JobResults/JobCardsMainContentV2";
import { EmailRecruiterV2Props } from "src/components/common/MainContent/JobResults/common/index";
import { isMobile } from "react-device-detect";
import { RecommendedJobsSourceTypes } from "src/types/receommendedJobsSource";
import JobCardHeaderV2 from "../../JobResults/JobCardHeaderV2";

interface HotJobsCardMasterProps {
  job: EmailRecruiterV2Props["job"];
  campaignCode: EmailRecruiterV2Props["campaignCode"];
  sourceType: EmailRecruiterV2Props["sourceType"];
  isHotJobsCard: boolean;
  jobCount: number
 }

const HotJobsCardMaster = ({
  job,
  campaignCode,
  sourceType,
  isHotJobsCard,
  jobCount
}: HotJobsCardMasterProps) => {
  return (
    <div className={(isMobile && jobCount === 1 ) ? "jobCardMainWrapV2 hotJobCardSingleWrap" : "jobCardMainWrapV2 hotJobCardWrap"}>
      <JobCardHeaderV2
        job={job}
        handleEmailCLick={() => {}}
        similarOrViewed="" 
        recommended={false}
        isRecommendedJobsOnResult={false}
        viewMyJobs={false}
        campaignCode={campaignCode}
        sourceType={sourceType}
        isHotJobsCard={isHotJobsCard}
        jobsSource={RecommendedJobsSourceTypes.NONE}
        isQuickApplyAdvCard={false}
      />
      <JobCardsMainContentV2
        isJobDetailsPage
        job={job}
        similarOrViewed=""
        recommended=""
        isRecommendedJobsOnResult={false}
        viewMyJobs=""
        campaignCode={campaignCode}
        sourceType={sourceType}
        isHotJobsCard={isHotJobsCard}
        jobsSource={RecommendedJobsSourceTypes.NONE}
      />
    </div>
  );
};

export default HotJobsCardMaster;
