import React, { Component } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { MuiThemeProvider, createTheme } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { isMobile } from "react-device-detect";
import moment from "moment";
import { fetchTimesheetsPerPayPeriod } from "../../../../../redux/payroll/action";
import Analytics from "../../../../../services/Analytics";
import CustomNavMob from "../../../Navigation/CustomNavMob";
import PayrollNavV2 from "../PayrollNavV2";
import PayrollNoticeV2 from "./PayrollNoticeV2";
import analyticsSegmentIdentify from "../../../../../services/SegmentIdentify";

const dropdownTheme = createTheme({
  typography: {
    fontFamily: ["Nunito Sans", "Helvetica", "Arial", "sans-serif"].join(","),
  },
  overrides: {
    MuiOutlinedInput: {
      root: {
        "& $notchedOutline": {
          borderColor: "#e1e1e1",
        },
        "&:hover $notchedOutline": {
          borderColor: "#e1e1e1",
        },
        "&$focused $notchedOutline": {
          borderColor: "#e1e1e1",
        },
      },
      focused: {},
      notchedOutline: {},
    },
  },
});

class TimeSheet extends Component {
  state = {
    value: 0,
    selectedPayPeriod: null,
    noTimesheets: true,
    timesheetCount: 0,
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    if (this.props.userId && this.props.email) {
      analyticsSegmentIdentify(this.props);
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.email !== prevProps.email ||
      this.props.recruiterDetails !== prevProps.recruiterDetails
    ) {
      analyticsSegmentIdentify(this.props);
    }
  }

  selectPayPeriod(payPeriods, e) {
    this.setState({
      value: e.target.value,
      selectedPayPeriod: payPeriods[e.target.value - 1],
      noTimesheets: this.props.assignment.timeSheets.length === 0,
    });
    const startDate = moment
      .utc(payPeriods[e.target.value - 1].payPeriodStartDate)
      .format("MM-DD-YYYY");
    const endDate = moment
      .utc(payPeriods[e.target.value - 1].payPeriodEndDate)
      .format("MM-DD-YYYY");
    const obj = {
      startDate,
      endDate,
      timesheets: this.props.assignment.timeSheets,
    };
    this.props.fetchTimesheetsPerPayPeriod(obj);
  }

  formatPayPeriod(startDate, endDate) {
    return `${moment.utc(startDate).format("MM/DD/YYYY")} - ${moment
      .utc(endDate)
      .format("MM/DD/YYYY")}`;
  }

  formatTableDates(date) {
    const formattedDate = moment.utc(date).format("ddd DD, YYYY");
    return formattedDate;
  }

  renderTable() {
    const { t, timeSheets, totalHours } = this.props;
    return (
      <div>
        <div className="mt-2 mb-3">
          <b>
            {t("payroll.timeSheets.week")} {this.state.value}
          </b>
        </div>
        <Row className="paystubtable">
          <Col sm={12} md={12} lg={12} xs={12}>
            <TableContainer component={Paper} className="payDetails timesheetTblV2">
              <Table aria-label="customized table" className="payDeskTable" id="payTable">
                <TableHead>
                  <TableRow className="payheading">
                    <TableCell>{t("payroll.timeSheets.date")}</TableCell>
                    <TableCell>{t("payroll.timeSheets.hours")}</TableCell>
                    <TableCell>{t("payroll.timeSheets.charge")}</TableCell>
                    <TableCell>{t("payroll.timeSheets.onCall")}</TableCell>
                    <TableCell>{t("payroll.timeSheets.holiday")}</TableCell>
                    <TableCell>{t("payroll.timeSheets.callBack")}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody className="tableRowHover">
                  {timeSheets.map((row) => (
                    <TableRow className="payDetails">
                      <TableCell component="th" scope="row">
                        {this.formatTableDates(row.date)}
                      </TableCell>
                      <TableCell>
                        {row.regularHours ? row.regularHours.toFixed(2) : "0.00"}
                      </TableCell>
                      <TableCell>
                        {row.inchargeHours ? row.inchargeHours.toFixed(2) : "0.00"}
                      </TableCell>
                      <TableCell>{row.oncallHours ? row.oncallHours.toFixed(2) : "0.00"}</TableCell>
                      <TableCell>
                        {row.holidayHours ? row.holidayHours.toFixed(2) : "0.00"}
                      </TableCell>
                      <TableCell>
                        {row.callbackHours ? row.callbackHours.toFixed(2) : "0.00"}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
                <TableHead>
                  <TableRow className="payheading">
                    <TableCell>{t("payroll.timeSheets.total")}</TableCell>
                    <TableCell>{totalHours.regularHours.toFixed(2)}</TableCell>
                    <TableCell>{totalHours.chargeHours.toFixed(2)}</TableCell>
                    <TableCell>{totalHours.oncallHours.toFixed(2)}</TableCell>
                    <TableCell>{totalHours.holidayHours.toFixed(2)}</TableCell>
                    <TableCell>{totalHours.callbackHours.toFixed(2)}</TableCell>
                  </TableRow>
                </TableHead>
              </Table>
            </TableContainer>
          </Col>
        </Row>
      </div>
    );
  }

  renderTimesheets(payPeriods, heading) {
    const { t, history, companyCode, assignment } = this.props;
    const { value, selectedPayPeriod } = this.state;
    const event = {
      category: t("googleAnalytics.category.payroll"),
      action: t("googleAnalytics.action.openTimesheetList"),
      label: t("googleAnalytics.label.timesheetList"),
    };
    if (payPeriods) {
      payPeriods.sort((a, b) => new Date(b.payPeriodStartDate) - new Date(a.payPeriodStartDate));
    }
    Analytics.sendPageview(history.location, null, true);
    Analytics.sendEvent(event);
    const segmentEventName = t("segment.timesheetViewed");
    Analytics.segmentTrackEvent(segmentEventName, null);
    return (
      <div className="mainPaystub">
        <Container>
          <div dangerouslySetInnerHTML={{ __html: heading }} />
          <div className="hospTimesheet">
            <Row>
              <Col sm={12} md={4} lg={4} xs={12} className="hospTimeValue">
                <div className="hospTextMob">
                  {t(`payroll.timeSheets.hospitalLabel-${companyCode.code}`)}
                </div>
                <div className="hospTimeValue hospTextMob">
                  <b>
                    {assignment
                      ? `${assignment.hospital.name}, ${assignment.hospital.city} ${assignment.hospital.state}`
                      : ""}
                  </b>
                </div>
              </Col>
              <Col sm={6} md={1} lg={1} xs={6} className="hospTimeValue">
                <div className="timeLabel">{t("payroll.timeSheets.assignStartDate")}</div>
                <div className="timeValue">
                  {assignment
                    ? assignment.assignmentStartDate
                      ? moment.utc(assignment.assignmentStartDate).format("MM/DD/YYYY")
                      : ""
                    : ""}
                </div>
              </Col>
              <Col sm={6} md={1} lg={1} xs={6} className="hospTimeValue">
                <div className="timeLabel">{t("payroll.timeSheets.assignEndDate")}</div>
                <div className="timeValue">
                  {assignment
                    ? assignment.assignmentEndDate
                      ? moment.utc(assignment.assignmentEndDate).format("MM/DD/YYYY")
                      : ""
                    : ""}
                </div>
              </Col>
              <Col sm={6} md={2} lg={2} xs={6} className="hospTimeValue">
                <div className="timeLabel">{t("payroll.timeSheets.expHrsWithoutAto")}</div>
                <div className="timeValue">
                  {assignment
                    ? assignment.hoursExpectedWithoutATO
                      ? assignment.hoursExpectedWithoutATO.toFixed(2)
                      : 0.0
                    : 0.0}
                </div>
              </Col>
              <Col sm={6} md={2} lg={2} xs={6} className="hospTimeValue">
                <div className="timeLabel">{t("payroll.timeSheets.expHrsWithAto")}</div>
                <div className="timeValue">
                  {assignment
                    ? assignment.hoursExpectedWithATO
                      ? assignment.hoursExpectedWithATO.toFixed(2)
                      : 0.0
                    : 0.0}
                </div>
              </Col>
              <Col sm={12} md={2} lg={2} xs={12} className="hospTimeValue">
                <div className="timeLabel">{t("payroll.timeSheets.totalHrs")}</div>
                <div className="timeValue">
                  {assignment
                    ? assignment.hoursWorked
                      ? assignment.hoursWorked.toFixed(2)
                      : 0.0
                    : 0.0}
                </div>
              </Col>
            </Row>
            <hr className="lineSeperator" />
            <Row className="mb-3 payColumnReverse">
              <Col sm={12} md={3} lg={3} xs={12}>
                <div className="payPeriod">{t("payroll.timeSheets.payPeriodLabel")}</div>
                <FormControl className="dateDropDown">
                  <MuiThemeProvider theme={dropdownTheme}>
                    <Select
                      displayEmpty
                      value={value}
                      variant="outlined"
                      className="selectDate"
                      onChange={this.selectPayPeriod.bind(this, payPeriods)}
                    >
                      <MenuItem value={0} disabled>
                        {t("payroll.timeSheets.selectPayPeriod")}
                      </MenuItem>
                      {payPeriods
                        ? payPeriods.map((period, key) => (
                            <MenuItem value={key + 1}>
                              {this.formatPayPeriod(
                                period.payPeriodStartDate,
                                period.payPeriodEndDate
                              )}
                            </MenuItem>
                          ))
                        : null}
                    </Select>
                  </MuiThemeProvider>
                </FormControl>
              </Col>
              <Col sm={12} md={6} lg={6} xs={12} />
              <Col sm={12} md={3} lg={3} xs={12}>
                {selectedPayPeriod ? (
                  <div className="payCheckDate">
                    {t("payroll.timeSheets.payCheckDate")} :{" "}
                    {moment.utc(selectedPayPeriod.payCheckDate).format("MM/DD/YYYY")}
                  </div>
                ) : (
                  <div />
                )}
              </Col>
            </Row>
            {selectedPayPeriod ? this.renderTable() : <div />}
          </div>
          <PayrollNoticeV2 />
        </Container>
      </div>
    );
  }

  getHeading(username, timesheetSize) {
    const timesheetHeading =
      `<h3 class="titlepaystub"><span class="capitalize">${username}</span>` +
      `, you have ${timesheetSize} timesheets!</h3>`;
    return timesheetHeading;
  }

  render() {
    const { assignment, username } = this.props;
    const payPeriods = assignment ? assignment.assignPayPeriods : null;
    const heading = this.getHeading(username, payPeriods ? payPeriods.length : 0);

    return (
      <Container fluid>
        <Row>
          <div id="paymentDeductionPg" className="payRollNavMargin">
            <div className={!isMobile ? "payrollNavWrap" : "myProfileSubNav"}>
              {isMobile ? <CustomNavMob type="payroll" /> : <PayrollNavV2 />}
            </div>
            {this.renderTimesheets(payPeriods, heading)}
          </div>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  assignment: state.payroll.latestAssignment,
  timeSheets: state.payroll.timeSheets,
  totalHours: state.payroll.totalHours,
  username: state.auth.firstName,
  companyCode: state.company.company,
  userId: state.auth.userId,
  applicantId: state.candidate.applicantId,
  company: state.company.company,
  email: state.auth.email,
  firstName: state.auth.firstName,
  lastName: state.auth.lastName,
  phone: state.auth.phone,
  recruiterDetails: state.consultant.recruiterDetails,
  onboardingSpecialtiesAndEducation: state.onBoarding.specialtiesAndEducation,
  onboardingBasicInfo: state.onBoarding.basicInfo,
});

const mapDispatchToProps = (dispatch) => ({
  fetchTimesheetsPerPayPeriod: (obj) => dispatch(fetchTimesheetsPerPayPeriod(obj)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(TimeSheet))
);
