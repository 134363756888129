import hash from "object-hash";
import { useEffect } from "react";

export default function useHash({ object, setHash, callBack, excludeKeyList, excludeQuickApplyFilterInHash }) {
  // unorderedArrays {true|false} Sort all arrays before hashing.
  // Note that this affects all collections, i.e. including typed arrays, Sets, Maps, etc.

  // unorderedSets {true|false} Sort Set and Map instances before hashing,
  // i.e. make hash(new Set([1, 2])) == hash(new Set([2, 1])) return true.
  useEffect(() => {
    if (excludeQuickApplyFilterInHash) excludeKeyList.push("shouldFilterByQuickApplyEligibility")
    const hashedValue = hash(object, {
      unorderedArrays: true,
      unorderedObjects: true,
      excludeKeys(key) {
        if (excludeKeyList.includes(key)) {
          return true;
        }
        return false;
      },
    });

    return setHash(hashedValue);
  }, [...callBack]);
}
