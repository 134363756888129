import { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Paper } from "@material-ui/core";
import Chip from "@mui/material/Chip";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { fetchLocation, detectLocationInputClick } from "src/redux/globalSearch/action";
import { Col, Container, Row } from "react-bootstrap";
import { v4 as uuidv4 } from "uuid";
import "./selectedList.scss";
import type { SelectedListProps } from "./index";

const styles = () => ({
  root: {
    padding: "2px 0px",
    display: "flex",
    alignItems: "center",
    overflow: "auto",
  },
  input: {
    marginLeft: "0px",
    flex: 3,
    fontSize: "1em",
  },
  iconButton: {
    padding: 10,
  },
  card: {
    backgroundColor: "#f5f5f5",
    display: "block",
    height: "200px",
    width: "203px",
  },
});

class SelectedList extends Component<SelectedListProps & RouteComponentProps> {
  isSelected = (item) => {
    const { selectedItems } = this.props;

    return selectedItems.includes(item.id);
  };

  fetchIndividualLocation = (item) => {
    const { fetchSingleLocation } = this.props;

    return fetchSingleLocation(item);
  };

  handleDelete = (item, locationType) => {
    const { deleteLocation } = this.props;
    deleteLocation(item, locationType);
  };

  clearAll = () => {
    const { deleteAllLocation } = this.props;
    deleteAllLocation();
  };

  render() {
    const { selectedItems, citySelectedItems, t,isUserType } = this.props;

    return (
      <div>
        <Container className="outerContainerStyle">
          <Row className="selectedTextStyleText">
            <Col sm={6} md={6} lg={6} xs={6}>
              <div>{t("globalSearch.location.selected")}</div>
            </Col>
            <Col sm={6} md={6} lg={6} xs={6} className="text-right p-0">
              {(selectedItems.length > 0 || citySelectedItems.length > 0) && (
                <button
                  type="button"
                  className="clearAllClass"
                  onClick={() => {
                    this.clearAll();
                  }}
                >
                  {t("globalSearch.location.clearAll")}
                </button>
              )}
            </Col>
          </Row>
          {selectedItems.length === 0 && citySelectedItems.length === 0 && (
            <Row className="descriptionRowStyle">
              <Col sm={12} md={8} lg={12} xs={12}>
                <div className="descriptionFontStyle">
                  {t("globalSearch.location.noSelectionText")}
                </div>
              </Col>
            </Row>
          )}
        </Container>
        <Paper className="selectedPaper" data-condition={isUserType}>
          {selectedItems.length > 0 && (
            <div className="cityStateClass">{t("globalSearch.location.states")}</div>
          )}
          {selectedItems.map((item) => (
            <Chip
              key={uuidv4()}
              label={
               item.isCompactState
                  ? `${this.fetchIndividualLocation(item)} (CS)`
                  : this.fetchIndividualLocation(item)
              }
              className="delecteIconColour"
              onDelete={() => this.handleDelete(item, "state")}
              sx={
              item.isCompactState
                  ? {
                      m: "5px",
                      bgcolor: "#6780A6 !important",
                      "& .MuiChip-label": {
                        color: "#ffffff !important",
                      },
                      "& .MuiChip-deleteIcon": {
                        color: "#ffffff !important",
                      },
                      height:"26px",
                      fontSize:"12px",
                      lineHeight:"14.52px",
                      fontFamily:"Nunito Sans"
                    }
                  : {
                      m: "5px",
                      bgcolor: "#E8ECF3 !important",
                      "& .MuiChip-deleteIcon": {
                        color: "#30333A !important",
                      },
                      height:"26px",
                      fontSize:"12px",
                      lineHeight:"14.52px",
                      fontFamily:"Nunito Sans"
                    }
              }
            />
          ))}
          {citySelectedItems.length > 0 && (
            <div className="cityStateClass">{t("globalSearch.location.cities")}</div>
          )}
          {citySelectedItems.map((item) => (
            <Chip
              key={uuidv4()}
              label={this.fetchIndividualLocation(item)}
              className="delecteIconColour"
              onDelete={() => this.handleDelete(item, "city")}
              sx={{
                m: "5px",
                bgcolor: "#E8ECF3  !important",
                "& .MuiChip-deleteIcon": {
                  color: "#30333A !important",
                },
                height:"26px",
                fontSize:"12px",
                lineHeight:"14.52px",
                fontFamily:"Nunito Sans"
              }}
            />
          ))}
        </Paper>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  locationAutosuggest: state.globalSearch.location,
  loading: state.globalSearch.loading,
  firstName: state.auth.firstName,
  value: state.value,
});

const mapDispatchToProps = (dispatch) => ({
  getLocation: (term) => dispatch(fetchLocation(term)),
  detectTheLocationInputClick: (value) => dispatch(detectLocationInputClick(value)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(withTranslation()(withRouter(SelectedList))));
