import Container from "@mui/material/Container";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { useEffect } from "react";
import { CUSTOMER_SERVICE_EMAIL, CUSTOMER_SERVICE_PHONE } from "../../../../constants/contact_info";
import ExpiredLinkImg from "../../../../imagesV2/ExpiredLinkImg.svg";

const useStyles = makeStyles((theme) => ({
  expLinkWrapper: {
    textAlign: "center",
    margin: "0 auto",
    marginTop: "6rem",
    "@media (max-width: 768px)": {
      marginTop: "2rem",
      padding: "0px 30px",
    },
  },
  ExpiredLinkImg: {
    "@media (max-width: 768px)": {
      width: "40%",
    },
  },
  expLinkHead: {
    fontFamily: "Nunito Sans, sans-serif",
    color: theme.palette.colorSecondary,
    fontSize: "1.8rem",
    fontWeight: "600",
    marginTop: "20px",
    "@media (max-width: 768px)": {
      marginTop: "30px",
    },
  },
  expLinkMainText: {
    fontFamily: "Nunito Sans, sans-serif",
    fontSize: "1.2rem",
    fontWeight: "600",
    "@media (max-width: 768px)": {
      marginTop: "5px",
      fontSize: "1rem",
    },
  },
  expLinkSubText: {
    fontFamily: "Nunito Sans, sans-serif",
    color: "#202124",
    fontSize: "1rem",
    "@media (max-width: 768px)": {
      marginTop: "30px",
      fontSize: "0.9rem",
      lineHeight: "28px",
    },
  },
  expLinkEmail: {
    marginTop: "10px",
    "& a": {
      color: "#7B7B7B",
      fontSize: "0.8rem",
      textDecoration: "underline",
      "@media (max-width: 768px)": {
        "&:hover": {
          color: theme.palette.colorGray,
        },
      },
    },
    "@media (max-width: 768px)": {
      marginTop: "30px",
    },
    "& a:hover": {
      color: theme.palette.colorSecondary,
    },
  },
  expLinkPhone: {
    marginTop: "15px",
    "& a": {
      color: theme.palette.colorGray,
      fontSize: "0.8rem",
      textDecoration: "underline",
      "@media (max-width: 768px)": {
        "&:hover": {
          color: theme.palette.colorGray,
        },
      },
    },
    "& a:hover": {
      color: theme.palette.colorSecondary,
    },
    "@media (max-width: 768px)": {
      marginTop: "10px",
    },
  },
}));
const ReferenceExpiredLink = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <div className={classes.expLinkWrapper}>
        <Container>
          <img
            src={ExpiredLinkImg}
            alt={t("referenceExpired.referenceExpiredHead")}
            title={t("referenceExpired.referenceExpiredHead")}
            className={classes.ExpiredLinkImg}
          />
          <p>
            <div className={classes.expLinkHead}>
              {t("referenceExpired.referenceExpiredHead")} <br />
            </div>
            <div className={classes.expLinkMainText}>
              {t("referenceExpired.referenceExpiredMainText")}
            </div>
          </p>
          <p className={classes.expLinkSubText}>{t("referenceExpired.referenceExpiredSubText")}</p>
          <div>
            <div className={classes.expLinkEmail}>
              <a href={`mailto:${CUSTOMER_SERVICE_EMAIL}`}> {CUSTOMER_SERVICE_EMAIL} </a>
            </div>
            <div className={classes.expLinkPhone}>
              <a href={`tel:${CUSTOMER_SERVICE_PHONE}`}>{CUSTOMER_SERVICE_PHONE}</a>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};
export default ReferenceExpiredLink;
