import API from "../../../../../../services/Api";

async function getAll(candidateId, type) {
  const url = `/candidates/${candidateId}/preferences`;
  const res = await API.get(url, {
    params: {
      type,
    },
  });
  return res.data;
}

async function createOrUpdate(candidateId, reqObj, type) {
  const url = `/candidates/${candidateId}/preferences`;
  const res = await API.post(url, reqObj, {
    headers: {
      "content-type": "application/json",
    },
    params: {
      type,
    },
  });

  return res.data;
}

export default {
  getAll,
  createOrUpdate,
};
