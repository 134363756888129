import { createTheme } from "@material-ui/core";
import variables from "./variables.scss";

export const THEME_DEFAULT = "default";
export const THEME_DARK = "dark";

const root = document.documentElement;

const themes = {
  default: createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 900,
        lg: 1200,
        xl: 1536,
      }
    },
    typography: {
      fontFamily: ["Nunito Sans", "Helvetica", "Arial", "sans-serif"].join(","),
    },
    fontFamilyPrimary: ["Nunito Sans", "Helvetica", "Arial", "sans-serif"].join(","),
    fontSize: 20,
    letterSpacing: "0.03em",
    palette: {
      background: {
        default: "#ffffff",
      },
      text: {
        primary: "#003366",
        secondary: "#888492",
        label: "#616B7C",
      },
      colorPrimary: "#003366",
      colorPrimaryHover: "#456595",
      colorPrimaryDark: "#003366",
      colorSecondary: "#D7282F",
      colorSecondaryHover: "#F83D34",
      colorSecondaryLight: "#F83D34",
      colorSecondaryLight50: "#FFEBEE",
      colorTertiary: "#4270C9",
      colorSuccess: "#05A317",
      colorDisabled: "#C4C4C4",
      bgColorDisabled: "#E1E1E1",
      colorError: "#CE0036",
      colorGray: "#7B7B7B",
      colorGreyBG: "#C4C4C4",
      colorDarkGrey: "#888888",
      mainBorder: "1px solid #c4c4c4",
      error: {
        light: "#ff8b68",
        main: "#d75b3c",
        dark: "#a02b13",
        contrastText: "#fff",
        background: "rgba(215,91,60,0.05)",
      },
    },
    overrides: {
      MuiButton: {
        contained: {
          backgroundColor: variables.colorPrimary,
          color: "#ffffff",
        },
        containedSecondary: {
          backgroundColor: "#D7282F",
          color: "#ffffff",
          "&:hover": {
            backgroundColor: "#D7282F",
          },
        }
      },
    },
  }),
  dark: createTheme({
    palette: {
      background: {
        default: variables.colorBackgroundDark,
      },
      mainBorder: "#c4c4c4",
    },
    overrides: {
      MuiButton: {
        contained: {
          backgroundColor: "#ffffff",
        },
      },
    },
  }),
};

export default themes;
