import { ReactNode } from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const DialogDefault = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(8),
    "@media (max-width: 768px)": {
      padding: theme.spacing(2),
    },
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "& .MuiPaper-root": {
    bottom: 0,
    minHeight: "30vh",
    width: "100vw",
    margin: "0 auto",
    marginBottom: 0,
    borderRadius: "25px 25px 25px 0px",
    "@media (max-width: 768px)": {
      borderRadius: "15px 15px 15px 0px",
    },
  },
}));

interface DefaultDialogsProps {
  open: boolean;
  handleClose: () => void;
  children: ReactNode;
}

export default function DefaultDialogs({ open, handleClose, ...props }: DefaultDialogsProps) {
  const { children } = props;
  return (
    <div>
      <DialogDefault disableScrollLock={false} onClose={handleClose} open={open}>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            zIndex: 9,
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <div>{children}</div>
        </DialogContent>
      </DialogDefault>
    </div>
  );
}
