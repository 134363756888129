import React, { useEffect, useState } from "react";
import { useTranslation, withTranslation } from "react-i18next";
import MenuItem from "@mui/material/MenuItem";
import { connect } from "react-redux";
import Box from "@mui/material/Box";
import AddIcon from "@material-ui/icons/Add";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

import Modal from "@mui/material/Modal";
import { makeStyles } from "@material-ui/core/styles";
import PrimaryButton from "../../Buttons/PrimaryButton";
import TertiaryButton from "../../Buttons/TertiaryButton";
import { updateOptionalSkills } from "../../../../redux/onBoarding/action";

const SelectMenuProps = {
  PaperProps: {
    style: {
      maxHeight: "40vh",
    },
  },
};

const useStyles = makeStyles((theme) => ({
  "input[type='checkbox']": {
    appearance: "none",
    backgroundColor: "#fff",
    margin: 0,
  },
  onBOptSkillCheck: {
    display: "flex",
    justifyContent: "center",
    "& .MuiSvgIcon-root": {
      paddingBottom: "3px",
    },
    "@media (max-width: 768px)": {
      width: "100%",
    },
  },
  addSkillsWrapper: {
    width: "35%",
    background: "#fff",
    boxShadow: "0px 0px 10px #00000029",
    fontFamily: theme.fontFamilyPrimary,
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    "@media (max-width: 768px)": {
      width: "auto",
      "& .MuiFormControlLabel-label": {
        whiteSpace: "pre-line",
      },
    },
  },
  addSkillsContent: {
    padding: "20px 20px 20px 0",
    "& ::-webkit-scrollbar": {
      width: "10px",
    },
    "& ::-webkit-scrollbar-track": {
      background: "#e6e6e6",
    },
    "& ::-webkit-scrollbar-thumb": {
      background: "#7e7e7e",
      borderRadius: "10px",
    },
    "& .MuiFormControlLabel-root": {
      marginBottom: "0",
      "& .Mui-checked": {
        "& .MuiSvgIcon-root": {
          fill: theme.palette.colorPrimary,
        },
        "& + .MuiFormControlLabel-label": {
          fontWeight: 600,
        },
      },
      "& .MuiFormControlLabel-label": {
        color: "#626C7D",
        fontFamily: theme.fontFamilyPrimary,
        fontSize: "0.875rem",
      },
    },
    "& #addSkills": {
      maxHeight: "300px",
      overflowY: "auto",
      display: "block",
      padding: "0 0 0 20px",
      "& .MuiSvgIcon-root": {
        color: "#626C7D",
        fontSize: "1.2rem",
      },
      "& li": {
        padding: "0",
        "&:hover": {
          background: "none",
        },
      },
    },
  },
  addSkillsHeading: {
    fontSize: "1rem",
    color: theme.palette.colorPrimary,
    fontWeight: "bold",
    marginBottom: "14px",
    paddingLeft: "20px",
  },
  addSkillsFooter: {
    justifyContent: "center",
    display: "flex",
    padding: "20px",
    borderTop: "1px solid #ececec",
    "& .MuiSvgIcon-root": {
      paddingBottom: "2px",
    },
  },
  addSkillsCancelBtn: {
    marginLeft: "20px",
    color: "#707070",
    border: "1px solid #707070",
  },
}));

function OnBoardingOptionalSkill(props) {
  const [skillChecklistDisabled, setSkillChecklistDisabled] = React.useState(true);

  const { t } = useTranslation();
  const classes = useStyles();
  const [selectedSkillsList, setSelectedSkillsList] = useState([]);
  const handleChange = (key) => (event) => {
    let skillList = selectedSkillsList;
    if (event.target.innerText === "") {
      if (selectedSkillsList.includes(key)) {
        skillList = selectedSkillsList.filter((skill) => skill !== key);
      } else {
        skillList.push(key);
      }
      setSelectedSkillsList(skillList);
    }
    if (skillList.length > 0) {
      setSkillChecklistDisabled(false);
    } else setSkillChecklistDisabled(true);
    props.updateOptionalSkills(skillList);
  };
  const handleAddBtn = () => {
    setSelectedSkillsList([]);
    props.addOptionalSkills();
    handleSkillChecklistClose();
    setSkillChecklistDisabled(true);
  };
  const handleCancel = () => {
    setSelectedSkillsList([]);
    setSkillChecklistDisabled(true);
    handleSkillChecklistClose();
  };

  const [skillChecklistOpen, setSkillChecklistOpen] = React.useState(false);
  const handleSkillChecklistOpen = () => setSkillChecklistOpen(true);
  const handleSkillChecklistClose = () => {
    setSkillChecklistOpen(false);
  };

  return (
    <div className={classes.onBOptSkillCheck}>
      <div className="mt-3 onBFullWidthMob">
        <PrimaryButton onClick={handleSkillChecklistOpen} size="medium" className="onBFullWidthMob">
          <AddIcon fontSize="small" />
          &nbsp; {t("onBoarding.skillChecklist.addSkillsLbl")}
        </PrimaryButton>
        <Modal
          open={skillChecklistOpen}
          onClose={handleCancel}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className={classes.addSkillsWrapper}>
            <div className={classes.addSkillsContent}>
              <h3 className={classes.addSkillsHeading}>
                {t("skillChecklist.addSkillCheckListHeading")}
              </h3>
              <FormGroup id="addSkills">
                {props.optionalDropDownValues
                  ? props.optionalDropDownValues
                      .sort((a, b) =>
                        a.name.toLowerCase() > b.name.toLowerCase()
                          ? 1
                          : b.name.toLowerCase() > a.name.toLowerCase()
                          ? -1
                          : 0
                      )
                      .map((skill) => (
                        <MenuItem
                          key={skill.id}
                          value={skill.name}
                          onClick={handleChange(skill.name)}
                        >
                          <FormControlLabel control={<Checkbox />} label={skill.name} />
                        </MenuItem>
                      ))
                  : null}
              </FormGroup>
            </div>
            <div className={classes.addSkillsFooter}>
              <PrimaryButton
                className={classes.addSkillsAddBtn}
                disabled={skillChecklistDisabled}
                size="medium"
                onClick={handleAddBtn}
              >
                <AddIcon fontSize="small" /> {t("onBoarding.skillChecklist.addBtn")}
              </PrimaryButton>
              <TertiaryButton
                size="medium"
                onClick={handleCancel}
                className={classes.addSkillsCancelBtn}
              >
                {t("onBoarding.skillChecklist.cancelBtn")}
              </TertiaryButton>
            </div>
          </Box>
        </Modal>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  selectedOptionalSkills: state.onBoarding.selectedOptionalSkills,
});

const mapDispatchToProps = (dispatch) => ({
  updateOptionalSkills: (value) => dispatch(updateOptionalSkills(value)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(OnBoardingOptionalSkill));
