import { push } from "connected-react-router";
import API from "../../../services/Api";
import { SIGN_UP } from "../../../constants/campaigns_constants";

export const POST_SIGNUP_REQUEST = "POST_SIGNUP_REQUEST";
export const postSignUpRequest = (payload) => ({ type: POST_SIGNUP_REQUEST, payload });

export const POST_SIGNUP_SUCCESS = "POST_SIGNUP_SUCCESS";
export const postSignUpSuccess = (payload) => ({ type: POST_SIGNUP_SUCCESS, payload });

export const POST_SIGNUP_FAILURE = "POST_SIGNUP_FAILURE";
export const postSignUpFailure = (payload) => ({ type: POST_SIGNUP_FAILURE, payload });

export const postSignUp = (req) => {
  const reqObj = { ...req, campaignCode: SIGN_UP };
  return (dispatch) => {
    dispatch(postSignUpRequest());
    API.post("/auth/signup", JSON.stringify(reqObj), {
      headers: {
        "content-type": "application/json",
      },
    })
      .then((response) => {
        let token;
        if (response.status === 200) {
          if (response.data.token) {
            token = response.data.token;
          }
          dispatch(postSignUpSuccess(response));
          dispatch(push(`/auth/relay/${token}`));
        } else dispatch(postSignUpFailure(response));
      })
      .catch((error) => {
        dispatch(postSignUpFailure(error));
      });
  };
};

export const SET_SIGNUP_FLAG_TO_DEFAULT = "SET_SIGNUP_FLAG_TO_DEFAULT";
export const setSignUpFlagToDefault = (payload) => ({ type: SET_SIGNUP_FLAG_TO_DEFAULT, payload });

export const SET_USER_EXISTS_FLAG = "SET_USER_EXISTS_FLAG";
export const setUserExistsFlag = (payload) => ({ type: SET_USER_EXISTS_FLAG, payload });
