export function getRotatingShiftLabel(shift: string): string {
  const shiftType = shift.toLocaleLowerCase();
  if (shiftType.includes("rotating d/e")) {
    const regEx = new RegExp("rotating d/e", "ig");
    return shift.replace(regEx, "Rotating d/m");
  }
  if (shiftType.includes("rotating e/n")) {
    const regEx = new RegExp("rotating e/n", "ig");
    return shift.replace(regEx, "Rotating m/n");
  }

  return shift;
}

export function recruiterConstraint(name: string): boolean {
  const regex = new RegExp("^[a-zA-Z ,.'-]*$");
  return regex.test(name);
}

export default { getRotatingShiftLabel, recruiterConstraint };
