import Analytics from "src/services/Analytics";

export const triggerAnalytics = (label: string, event) => {
  if (event && event.type === "click") {
    const segmentEventName = "Clicked Footer";
    const segmentEventProps = {
      segment_source_name: `${process.env.NODE_ENV}-web-gateway`,
      text: label,
    };
    Analytics.segmentTrackEvent(segmentEventName, segmentEventProps);
  }
};
