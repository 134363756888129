import { getAccessToken } from "axios-jwt";
import { useTranslation } from "react-i18next";

import Modal from "@material-ui/core/Modal";
import Analytics from "src/services/Analytics";
import CloseIcon from "@mui/icons-material/Close";
import { APIMethods, ReferencesStates } from "src/constants/constants";
import IconButton from "@material-ui/core/IconButton";
import ExitPrompt from "src/components/common/ExitPrompt/ExitPrompt";
import PrimaryButton from "src/components/common/Buttons/PrimaryButton";
import { graphqlRequest as request } from "src/services/GraphQL/GraphQL";
import SecondaryButton from "src/components/common/Buttons/SecondaryButton";
import LoaderSpinner from "src/components/common/LoaderSpinner/LoaderSpinner";
import { getCredentialsTypeQuery } from "src/employers/reference/GraphQL/graph.query";
import GenericErrorMessagePopup from "src/components/common/OnBoarding/GenericErrorMessagePopup";
import {
  SaveCandidateCredentials,
  UpdateCandidateCredentials,
} from "src/services/GraphQL/graph.query";
import CertificationsLicensesForm, {
  credentialsTypes,
} from "src/components/common/CertificationsLicenses/CertificationAndLicensesForm";

import { Dispatch, SetStateAction, memo, useEffect, useMemo, useState } from "react";
import {
  CredentialTypeResponse,
  SaveCredentialsResponse,
  UpdateCredentialsResponse,
} from "../../OnBoarding/CertificationsLicenses";
import { CandidateCredentialData } from "../../OnBoarding/CredentialItem";

import styles from "./CredentialModal.module.scss";

// Credential Modal Props Interface
interface Props {
  isCredentialModalOpen: boolean;
  setIsCredentialModalOpen: Dispatch<SetStateAction<boolean>>;
  quickApplyResponseCredentials: any;
  fetchCredentials: Function;
  setToasterState: Dispatch<
    SetStateAction<{ open: boolean; horizontal: string; vertical: string }>
  >;
  credentialsApiObject: { companyId: string; atsBullhornCandidateId: string };
  refreshQuickApplyCard: Function;
  candidateCredentials: any | null;
}

function CredentialModal({
  isCredentialModalOpen,
  setIsCredentialModalOpen,
  quickApplyResponseCredentials,
  fetchCredentials,
  setToasterState,
  credentialsApiObject,
  refreshQuickApplyCard,
  candidateCredentials,
}: Readonly<Props>) {
  const { t } = useTranslation();
  const [authToken, setAuthToken] = useState<string | undefined>(getAccessToken());
  const [displaySaveSpinner, setDisplaySaveSpinner] = useState<boolean>(false);
  const [isCredentialConfirm, setIsCredentialConfirm] = useState<boolean>(true);
  const [credentialTypeList, setCredentialTypeList] = useState<credentialsTypes[]>([]);
  const [candidateCredential, setCandidateCredential] = useState<null | CandidateCredentialData>(
    null
  );
  const [mode, setMode] = useState<string>(APIMethods.ADD);
  const [, setShowExitPrompt] = ExitPrompt(false);
  const [userMadeChanges, setUserMadeChanges] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);

  // filtering Credentials from the credentialTypeList based on the quickApplyResponseCredentials
  const filterCredentials = useMemo(() => {
    const quickApplyResponseCredentialWords = new Set<string>();
    quickApplyResponseCredentials.forEach((cred) => {
      cred.name.split(" ").forEach((word) => quickApplyResponseCredentialWords.add(word));
    });

    // Filter the credentialTypeList using the set
    return credentialTypeList.filter((item) => {
      const itemWords = item.label.split(" ");
      return itemWords.every((word) => quickApplyResponseCredentialWords.has(word));
    });
  }, [credentialTypeList, quickApplyResponseCredentials]);

  // updating the existing candidate Credentials for edit mechanism
  const updateCandidateCredential = () => {
    const foundMatchingCredential = candidateCredentials?.find(
      (cred) => cred?.name === filterCredentials?.[0]?.label
    );
    if (foundMatchingCredential?.status === ReferencesStates.EXPIRED){
      setCandidateCredential(foundMatchingCredential);
      setMode(APIMethods.EDIT)
    }
  };

  useEffect(() => setAuthToken(getAccessToken()), []);

  // default editing mechanism for credential modal
  useEffect(() => {
    if (filterCredentials.length === 1) {
      updateCandidateCredential();
    }
  }, [filterCredentials, candidateCredential]);

  const segmentCredentialEvent = (
    eventName: "Added" | "Deleted" | "Downloaded",
    eventProps: any
  ) => {
    const segmentEventName = t(`segment.credential${eventName}`);
    Analytics.segmentTrackEvent(segmentEventName, eventProps);
  };

  // fetching Credential's List
  const getCredentialsType = async () => {
    const query = {
      credentialsCategory: ["Certifications", "Licensure"],
      size: "500",
    };
    return new Promise((_, reject) => {
      request(getCredentialsTypeQuery, query, authToken)
        .then((res: CredentialTypeResponse) => {
          const items: credentialsTypes[] = res?.getCredentialsType?.credentialsTypes;
          if (items?.length > 0) {
            setCredentialTypeList(items);
            setIsError(false);
          }
        })
        .catch((error: Error) => {
          reject(error);
          setIsError(true);
        });
    });
  };

  useEffect(() => {
    if (credentialTypeList?.length === 0 && authToken && !isError) {
      getCredentialsType().catch(() => {
        setCredentialTypeList([]);
      });
    }
  }, []);

  const handleCredentialModalClose = (reason) => {
    if ((reason && reason === "backdropClick") || reason === "escapeKeyDown") return;
    if (userMadeChanges) setIsCredentialConfirm(!isCredentialConfirm);
    else {
      setIsCredentialModalOpen(false);
      setIsError(false);
      setIsCredentialConfirm(true);
    }
  };

  const handleCredentialConfirmation = () => {
    setIsCredentialConfirm(!isCredentialConfirm);
  };

  // Saving or Updating the uploaded/captured
  // credentials for the requested QuickApplyResponse Credentials
  const saveNewCredentials = async (
    certificationContent,
    certificationFiles,
    type,
    certificationId,
    certificationFileIds
  ) => {
    try {
      const token = authToken;
      const vars = {
        bhCandidateId: Number(credentialsApiObject.atsBullhornCandidateId),
        companyId: Number(credentialsApiObject.companyId),
        certificationContent,
        certificationFiles,
        candidateCertId: certificationId,
        certificationFileIds: certificationFileIds.filter((item) => item),
      };
      if (APIMethods.ADD === type) {
        delete vars.candidateCertId;
        setDisplaySaveSpinner(true);
        const {
          saveCandidateCredentials: { statusCode, userMessage },
        }: SaveCredentialsResponse = await request(SaveCandidateCredentials, vars, token);

        if (statusCode !== 200) {
          setIsError(true);
          console.error(userMessage);
        } else {
          segmentCredentialEvent("Added", { credentialName: certificationContent.name });
          fetchCredentials(
            credentialsApiObject.atsBullhornCandidateId,
            credentialsApiObject.companyId,
            token
          );
        }
      }

      if (APIMethods.EDIT === type) {
        setDisplaySaveSpinner(true);
        const {
          updateCandidateCredentials: { statusCode, userMessage },
        }: UpdateCredentialsResponse = await request(UpdateCandidateCredentials, vars, token);

        if (statusCode !== 200) {
          setIsError(true);
          console.error(userMessage);
        } else {
          fetchCredentials(
            credentialsApiObject.atsBullhornCandidateId,
            credentialsApiObject.companyId,
            token
          );
        }
      }

      setMode(APIMethods.ADD);
    } catch (err) {
      setDisplaySaveSpinner(false);
      setIsError(true);
      console.error(err);
    } finally {
      setTimeout(() => {
        setIsCredentialModalOpen(false);
        setDisplaySaveSpinner(false);
        setUserMadeChanges(false);
        setShowExitPrompt(false);
        setToasterState({
          open: true,
          vertical: "top",
          horizontal: "right",
        });
        refreshQuickApplyCard();
      }, 3000);
    }
  };

  // Dialog If the User Exits After filling the details partially
  const userMadeChangesCallback = (value: credentialsTypes | undefined) => {
    // edit options based on multiple selections
    if (value && value?.label !== candidateCredential?.name) {
      updateCandidateCredential();
    }
    setShowExitPrompt(true);
    setUserMadeChanges(true);
  };

  // Re-Setting The Default State for Discarding the Changes
  const handleDiscardChanges = () => {
    setUserMadeChanges(false);
    setIsCredentialModalOpen(false);
    setIsError(false);
    setIsCredentialConfirm(true);
    setCandidateCredential(null);
    setShowExitPrompt(false);
  };

  return (
    <div>
      <LoaderSpinner isOpen={displaySaveSpinner} size={70} />
      <Modal
        className="cerAndLicModal"
        open={isCredentialModalOpen && !displaySaveSpinner}
        onClose={(_, reason) => handleCredentialModalClose(reason)}
      >
        <div className={styles.certAndLic}>
          <IconButton
            aria-label="Close"
            onClick={handleCredentialModalClose}
            style={{ position: "absolute", right: 8, top: 8, zIndex: 9 }}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
          <div hidden={!isCredentialConfirm}>
            <CertificationsLicensesForm
              credentialTypeList={filterCredentials}
              saveNewCredentials={saveNewCredentials}
              candidateCredential={candidateCredential}
              apiMethod={mode}
              userMadeChangesCallback={userMadeChangesCallback}
            />
          </div>
          <div className={styles.confirmationBox} hidden={isCredentialConfirm}>
            <div className={styles.confirmTitle}>
              {t("certificationAndLicenses.unsavedChanges")}
            </div>
            <div className={styles.confirmSubTitle}>{t("certificationAndLicenses.pleaseExit")}</div>
            <SecondaryButton
              component="button"
              size="medium"
              className={styles.stayOnPage}
              onClick={handleCredentialConfirmation}
            >
              <span>{t("certificationAndLicenses.stayOnPage")}</span>
            </SecondaryButton>
            <PrimaryButton
              component="button"
              size="medium"
              className={styles.discard}
              onClick={handleDiscardChanges}
            >
              <span>{t("certificationAndLicenses.exitAndDiscard")}</span>
            </PrimaryButton>
          </div>
        </div>
      </Modal>
      {isError && (
        <GenericErrorMessagePopup
          informativeMessage={t("genericError.informativeMessage")}
          tryAgainMessage={t("genericError.tryAgainMessage")}
          callBack={handleCredentialModalClose}
        />
      )}
    </div>
  );
}

export default memo(CredentialModal);
