import {
  POST_JOB_APPLY_SUCCESS,
  POST_JOB_APPLY_REQUEST,
  POST_JOB_APPLY_FAILURE,
  POST_JOB_FAVORITE_SUCCESS,
  POST_JOB_FAVORITE_REQUEST,
  POST_JOB_FAVORITE_FAILURE,
  POST_JOB_VIEWED_SUCCESS,
  POST_JOB_VIEWED_REQUEST,
  POST_JOB_VIEWED_FAILURE,
  POST_JOB_EMAIL_SUCCESS,
  POST_JOB_EMAIL_REQUEST,
  POST_JOB_EMAIL_FAILURE,
  POST_JOB_QUICK_APPLY_REQUEST,
  POST_JOB_QUICK_APPLY_SUCCESS,
  POST_JOB_QUICK_APPLY_FAILURE
} from "./action";

const initialState = {
  jobApplySuccessResponse: [],
  applyError: null,
  applyLoading: false,
  jobApplySuccess: false,
  jobFavoriteSuccessResponse: [],
  jobFavoriteSuccess: false,
  favoriteError: null,
  favoriteLoading: false,
  viewedError: null,
  viewedSuccess: false,
  emailActionSuccess: [],
  emailActionError: null,
  emailLoading: false,
  jobId: null,
  jobQuickApplySuccessResponse: [],
  quickApplyError: null,
  quickApplyLoading: false,
  jobQuickApplySuccess: false,
};
const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case POST_JOB_APPLY_REQUEST:
      return {
        ...state,
        applyLoading: true,
        applyError: null,
        jobApplySuccess: false,
        jobId: action.payload,
      };
    case POST_JOB_APPLY_SUCCESS:
      return {
        ...state,
        applyLoading: false,
        jobApplySuccessResponse: action.payload,
        jobApplySuccess: true,
        applyError: null,
      };
    case POST_JOB_APPLY_FAILURE:
      return {
        ...state,
        applyLoading: false,
        applyError: action.payload,
        jobApplySuccessResponse: null,
        jobApplySuccess: false,
      };
    case POST_JOB_FAVORITE_REQUEST:
      return { ...state, favoriteLoading: true };
    case POST_JOB_FAVORITE_SUCCESS:
      return {
        ...state,
        jobFavoriteSuccessResponse: action.payload.data,
        favoriteError: null,
        favoriteLoading: false,
        jobFavoriteSuccess: true,
      };
    case POST_JOB_FAVORITE_FAILURE:
      return {
        ...state,
        jobFavoriteSuccessResponse: null,
        favoriteError: action.payload,
        favoriteLoading: false,
        jobFavoriteSuccess: false,
      };
    case POST_JOB_VIEWED_REQUEST:
      return { ...state, viewedLoading: true };
    case POST_JOB_VIEWED_SUCCESS:
      return {
        ...state,
        jobViewedSuccessResponse: action.payload.data,
        viewedError: null,
        viewedLoading: false,
        jobViewedSuccess: true,
      };
    case POST_JOB_VIEWED_FAILURE:
      return {
        ...state,
        jobViewedSuccessResponse: null,
        viewedError: action.payload,
        viewedLoading: false,
        jobViewedSuccess: false,
      };
    case POST_JOB_EMAIL_REQUEST:
      return { ...state, emailLoading: true };
    case POST_JOB_EMAIL_SUCCESS:
      return { ...state, emailActionSuccess: action.payload };
    case POST_JOB_EMAIL_FAILURE:
      return { ...state, emailActionError: action.payload };
    case POST_JOB_QUICK_APPLY_REQUEST:
      return {
        ...state,
        quickApplyLoading: true,
        quickApplyError: null,
        jobQuickApplySuccess: false,
        jobId: action.payload,
      };
    case POST_JOB_QUICK_APPLY_SUCCESS:
      return {
        ...state,
        quickApplyLoading: false,
        jobQuickApplySuccessResponse: action.payload,
        jobQuickApplySuccess: true,
        quickApplyError: null,
      };
    case POST_JOB_QUICK_APPLY_FAILURE:
      return {
        ...state,
        quickApplyLoading: false,
        quickApplyError: action.payload,
        jobQuickApplySuccessResponse: null,
        jobQuickApplySuccess: false,
      };
    default:
      return state;
  }
};

export default rootReducer;
