import { CognitoUserPool } from "amazon-cognito-identity-js";
import config from "../config";

const poolData = {
  UserPoolId: config.cognito.userPoolId!,
  ClientId: config.cognito.clientId!,
};

export const getUserPoolId = () => config.cognito.userPoolId!;

export const getRegionInfo = () => config.cognito.region!;

export default new CognitoUserPool(poolData);
