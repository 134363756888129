import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { isHamburgerMenuOpen, isHamburgerSubMenuOpen } from "../../../redux/navigation/action";
import HamburgerMenuIcon from "../../../imagesV2/HamburgerMenuIcon.svg";
import HamburgerMainMenuV2 from "./HamburgerMainMenuV2";
import { useHistory, useLocation } from "react-router-dom";

const drawerWidth = 360;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },

  drawerPaper: {
    drawerWidth,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
  },
}));

function HamburgerMenu(props) {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const [state, setState] = React.useState({
    openDrawer: false,
  });
  const handleDrawerOpen = () => {
    handleOverlay(true);
    props.setHamburgerMenuOpen(true);
  };

  const handleDrawerClose = () => {
    handleOverlay(false);
    props.setHamburgerMenuOpen(false);
    props.isHamburgerSubMenuOpen(false);
  };

  const handleOverlay = (status) => {
    setState({ openDrawer: status });
  };

  return (
    <div>
      <AppBar className="hamNavAppBar">
        <Toolbar>
          <IconButton aria-label="open drawer" edge="end" onClick={handleDrawerOpen}>
            <img src={HamburgerMenuIcon} alt="" className="hamburgerNavIcon" />
          </IconButton>
        </Toolbar>
      </AppBar>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: props.isMenuOpen,
        })}
      >
        <div className={classes.drawerHeader} />
      </main>
      <div id={state.openDrawer ? "hamOverlay" : "hamOverlayClose"}>
        <Drawer
          transitionDuration={{ enter: 400, exit: 400 }}
          className={classes.drawer}
          variant="persistent"
          anchor="right"
          open={props.isMenuOpen}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <HamburgerMainMenuV2
            handleDrawerClose={handleDrawerClose}
            handleOverlay={handleOverlay}
            handlePasswordPopup={props.handlePasswordPopup}
            location={location}
            history={history}
            classes={classes}
          />
        </Drawer>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  isSubMenuOpen: state.navigation.isHamburgerSubMenuOpen,
  isMenuOpen: state.navigation.isHamburgerMenuOpen,
});

const mapDispatchToProps = (dispatch) => ({
  isHamburgerSubMenuOpen: (data) => dispatch(isHamburgerSubMenuOpen(data)),
  setHamburgerMenuOpen: (data) => dispatch(isHamburgerMenuOpen(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(HamburgerMenu));
