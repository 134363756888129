import { PureComponent } from "react";
import { Switch } from "react-router-dom";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import { userAuthentication } from "./redux/userAction/login/action";
import HomePage from "./components/pages/HomePage";
import LoginPage from "./components/pages/LoginPage";
import LoggedInRoute from "./router/LoggedInRoute";
import LoggedOutRoute from "./router/LoggedOutRoute";

const styles = () => ({});

export const paths = {
  LOGIN: "/login",
  HOME: "/home",
};

class AppRouter extends PureComponent {
  render() {
    return (
      <Switch>
        <LoggedOutRoute path={paths.LOGIN} component={LoginPage} />
        <LoggedInRoute path={paths.HOME} component={HomePage} />
      </Switch>
    );
  }
}

const mapStateToProps = (state) => ({
  status: state.authentication.authenticated,
});

const mapDispatchToProps = (dispatch) => ({
  login: (req) => dispatch(userAuthentication(req)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(withTranslation()(AppRouter)));
