import {
  FETCH_LAT_LNG_FROM_ADDRESS_REQUEST,
  FETCH_LAT_LNG_FROM_ADDRESS_SUCCESS,
  FETCH_LAT_LNG_FROM_ADDRESS_FAILURE,
  SAVE_RSS_FEEDS,
} from "./action";
import { MAIN_OFFICE_LAT_LNG } from "../../constants/contact_info";

const initialState = {
  latLong: "",
  error: "",
  loading: false,
  mainOffcAddr: MAIN_OFFICE_LAT_LNG,
  currAssig: null,
  feeds: null,
};

const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_LAT_LNG_FROM_ADDRESS_REQUEST:
      return { ...state, loading: true };

    case FETCH_LAT_LNG_FROM_ADDRESS_SUCCESS: {
      const { mainOffcAddr } = action.payload;
      const { currAssig } = action.payload;
      return {
        ...state,
        loading: false,
        latLong: action.payload,
        error: "",
        mainOffcAddr: mainOffcAddr || state.mainOffcAddr,
        currAssig: currAssig || state.currAssig,
      };
    }
    case FETCH_LAT_LNG_FROM_ADDRESS_FAILURE:
      return { ...state, loading: false, error: action.payload, latLong: "" };

    case SAVE_RSS_FEEDS:
      return { ...state, feeds: action.payload };

    default:
      return state;
  }
};

export default rootReducer;
