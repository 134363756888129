import React from "react";
import MainLayout from "./MainLayout";
import JobsResultV2 from "../common/MainContent/JobsTab/MyJobs/JobsResultV2";

const JobResultsPage = (props) => (
  <>
    <MainLayout component={JobsResultV2} adjustFooter otherProps={props} />
  </>
);

export default JobResultsPage;
