export interface Address {
  streetAddress: string | null;
  apt: string | null;
  city: string | null;
  state: string | null;
  zip: string | null;
  country: string | null;
}

export interface CandidateBasicInfo {
  currentAddress: Address;
  dateAvailable: string;
  email: string;
  emergencyContact: string | null;
  emergencyPhone: string | null;
  firstname: string;
  lastname: string;
  interests: string[];
  isCurrAddressDiff: boolean;
  isHcp: boolean | null;
  permanentAddress: Address;
  phone: string;
  preferredName: string | null;
  preferredWorkEnv: string | null;
  professionalJourney: string | null;
  referredBy: string | null;
  relation: string | null;
  requestedRecruiter: string | null;
  shifts: string[];
  stateLicensesHeld: string[] | null;
  travelDestinations: string[];
  isSSNAvailable: boolean;
}

export interface SpecialtiesAndEducation {
  specialty?: {
    profession?: string;
    primarySpecialty?: string;
  };
}

export interface Reference {
  id: number;
  status: string;
  facilityName: string;
  specialty: string;
  dateCompleted: Date;
  endDate: Date;
}

export interface SkillsChecklists {
  active: string;
  categoryId: string;
  complete: string | null;
  completedDate: string | null;
  created: string;
  customerId: string;
  expiration: string;
  id: string;
  lastUpdated: string;
  name: string;
  required: string;
  useSubSkills: string;
}

export interface WorkExperience {
  profession: string;
  specialty: string;
  facilityName: string;
  facilityType: string;
  startDate: Date;
  endDate: Date;
  isCurrentlyWorking: boolean;
  reasonForLeaving: string;
  emrTechnology: string;
}

export interface CandidateCredentials {
  category?: string;
  certification?: {
    category?: string;
  };
  certificationFileAttachments: {
    id: number;
  }[];
  dateCompleted: number;
  dateExpiration: number;
  id: number;
  licenseNumber: string;
  name: string;
  status: string;
}
export interface OnboardingInfo {
  candidateCredentials: CandidateCredentials[];
  onboardingInformation: {
    onboarding: {
      basicInfo: CandidateBasicInfo;
      skillsChecklists: SkillsChecklists[];
      specialtiesAndEducation: SpecialtiesAndEducation;
    };
  };
  references: Reference[];
  workExperience: WorkExperience[];
  isCredentialsLoading: boolean;
}

const getCandidateInformation = (onboarding: OnboardingInfo) => {
  const { onboardingInformation, isCredentialsLoading } = onboarding;
  let normalizeBasicInfo: CandidateBasicInfo | null = null;
  let normalizeSpecialtiesAndEducation: SpecialtiesAndEducation | null = null;
  if (onboarding && !isCredentialsLoading) {
    if (onboardingInformation) {
      const skillsChecklists = onboardingInformation?.onboarding?.skillsChecklists?.map(
        (checklist) => ({
          active: checklist.active,
          categoryId: checklist.categoryId,
          complete: checklist.complete,
          completedDate: checklist.completedDate,
          created: checklist.created,
          customerId: checklist.customerId,
          id: checklist.id,
          lastUpdated: checklist.lastUpdated,
          name: checklist.name,
          required: checklist.required,
          useSubSkills: checklist.useSubSkills,
          expiration: checklist.expiration,
        })
      );

      const normalizeWorkExperiences = onboarding?.workExperience?.map((workHistory) => ({
        profession: workHistory?.profession,
        specialty: workHistory?.specialty,
        facilityName: workHistory?.facilityName,
        facilityType: workHistory?.facilityType,
        startDate: workHistory?.startDate,
        endDate: workHistory?.endDate,
        isCurrentlyWorking: workHistory?.isCurrentlyWorking,
        reasonForLeaving: workHistory?.reasonForLeaving,
        emrTechnology: workHistory?.emrTechnology,
      }));

      const normalizeReference = onboarding?.references?.map((reference) => ({
        id: reference?.id,
        status: reference?.status,
        specialty: reference?.specialty,
        facilityName: reference?.facilityName,
        dateCompleted: reference?.dateCompleted,
        endDate: reference?.endDate,
      }));

      if (onboardingInformation?.onboarding?.specialtiesAndEducation) {
        const { specialtiesAndEducation } = onboardingInformation?.onboarding;
        normalizeSpecialtiesAndEducation = {
          specialty: {
            profession: specialtiesAndEducation?.specialty?.profession,
            primarySpecialty: specialtiesAndEducation?.specialty?.primarySpecialty,
          },
        };
      }

      if (onboardingInformation?.onboarding?.basicInfo) {
        const { basicInfo } = onboardingInformation?.onboarding;
        normalizeBasicInfo = {
          firstname: basicInfo?.firstname,
          lastname: basicInfo?.lastname,
          email: basicInfo?.email,
          currentAddress: basicInfo?.currentAddress,
          permanentAddress: basicInfo?.permanentAddress,
          phone: basicInfo?.phone,
          dateAvailable: basicInfo?.dateAvailable,
          preferredName: basicInfo?.preferredName,
          preferredWorkEnv: basicInfo?.preferredWorkEnv,
          professionalJourney: basicInfo?.professionalJourney,
          referredBy: basicInfo?.referredBy,
          relation: basicInfo?.relation,
          requestedRecruiter: basicInfo?.requestedRecruiter,
          shifts: basicInfo?.shifts,
          stateLicensesHeld: basicInfo?.stateLicensesHeld,
          travelDestinations: basicInfo?.travelDestinations,
          interests: basicInfo?.interests,
          isCurrAddressDiff: basicInfo?.isCurrAddressDiff,
          isHcp: basicInfo?.isHcp,
          emergencyContact: basicInfo?.emergencyContact,
          emergencyPhone: basicInfo?.emergencyPhone,
          isSSNAvailable: basicInfo?.isSSNAvailable,
        };
      }

      return {
        credentials: onboarding?.candidateCredentials?.map((credential) => ({
          ...credential,
          category: credential?.certification?.category,
        })),
        onboarding: {
          ...onboardingInformation,
          onboarding: {
            basicInfo: normalizeBasicInfo,
            specialtiesAndEducation: normalizeSpecialtiesAndEducation,
            skillsChecklists,
          },
        },
        references: normalizeReference ?? [],
        workExperience: normalizeWorkExperiences ?? [],
      };
    }
  }
  return undefined;
};

export default getCandidateInformation;
