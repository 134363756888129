import { Checkbox, InputAdornment, TextField, makeStyles } from "@material-ui/core";
import { useTranslation, withTranslation } from "react-i18next";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import orderBy from "lodash/orderBy";
import { Search } from "@material-ui/icons";
import { isMobile } from "react-device-detect";
import Skeleton from "@material-ui/lab/Skeleton";
import { Box } from "@mui/material";

type facilitiesType = {
  facilityName: string,
  isActive: boolean,
  displayFacilityName: string 
}[]

interface FacilityComponentProps {
  facilities: facilitiesType;
  handleChange;
  setFacilitiesToEmpty;
  handleFacilityClearAll;
  showSkeleton: boolean;
}

const useStyles = makeStyles({
  root: {
    padding: "10px",
  },
  facilitySearchFieldWidthWithInput: {
    width: "243px",
    padding: "5px",
  },
  facilitySearchFieldWidthWithoutInput: {
    width: "270px",
    padding: "5px",
  },
  facilitiesContainerWidth: { width: isMobile ? "345px" : "100%" },
  ellipsis: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    marginLeft: isMobile ? "-5px" : "-10px",
  },
  noFacilityTxt: {
    color: "#003366",
    fontFamily: "Nunito Sans",
    fontSize: "16px",
    lineHeight: "20px",
    textAlign: "left",
  },
});

const Facilities = ({
  facilities,
  handleChange,
  setFacilitiesToEmpty,
  handleFacilityClearAll,
  showSkeleton,
}: FacilityComponentProps) => {
  const { search } = useLocation();
  const [isAnyfacilitySelected, setisAnyfacilitySelected] = useState<boolean>(false);
  const params = new URLSearchParams(search);
  const [clearAll, setClearAll] = useState<boolean>(false);
  const [isSearchBoxFocused, setIsSearchBoxFocused] = useState<boolean>(false);
  const [showClear, setShowClear] = useState<boolean>(false);
  const [selectedFacilities, setSelectedFacilities] = useState<facilitiesType>([]);
  const [selectedFacilitiesLocalCopy, setSelectedFacilitiesLocalCopy] = useState<facilitiesType>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const localStyleClasses = useStyles();
  const [filteredFacilities, setFilteredFacilities] = useState<facilitiesType>(facilities);

  useEffect(() => {
    if (facilities) {
      setSelectedFacilities(orderBy(Object.values(facilities), ["displayFacilityName"], ["asc"]));
    }
  }, [facilities, isAnyfacilitySelected]);

  useEffect(() => {
    const finalFacilitiesList: facilitiesType = [];
    selectedFacilities.forEach((f) => {
      if (f.facilityName.toLowerCase().includes(searchTerm.toLowerCase()) && !finalFacilitiesList.some(facility => facility.facilityName === f.facilityName)) {
        finalFacilitiesList.push({
          ...f,
          displayFacilityName: f.facilityName.trim(),
          isActive: (params.get("facilityNames") || "").split(",").includes(f.facilityName),
        });
      }
    });
    setFilteredFacilities(finalFacilitiesList);
  }, [searchTerm, selectedFacilities]);

  useEffect(() => {
    handleChange(selectedFacilitiesLocalCopy);
  }, [selectedFacilitiesLocalCopy]);

  useEffect(() => {
    if (setFacilitiesToEmpty) {
      setisAnyfacilitySelected(false);
      setSelectedFacilitiesLocalCopy([]);
      setShowClear(false);
      handleFacilityClearAll();
      setSelectedFacilities((prevFacilities) => {
        return prevFacilities.map((facilityObj) => {
          return { ...facilityObj, isActive: false };
        });
      });
    }
  }, [setFacilitiesToEmpty]);

  useEffect(() => {
    const facilitiesFromUrl = (params.get("facilityNames") || "").split(",");
    const initialFacilitiesData: facilitiesType =
      facilitiesFromUrl.map((fn) => {
        return { facilityName: fn, isActive: true, displayFacilityName: fn.trim() };
      });
    if (
      facilitiesFromUrl &&
      initialFacilitiesData.length > 0 &&
      initialFacilitiesData.every((facility) => facility.facilityName !== "")
    ) {
      setSelectedFacilitiesLocalCopy(initialFacilitiesData);
      setisAnyfacilitySelected(true);
      setShowClear(true);
    } else {
      if(selectedFacilitiesLocalCopy.length !== 0) {
        setSelectedFacilitiesLocalCopy([]);
      }
      setisAnyfacilitySelected(false);
      setShowClear(false);
    }
  }, [params.get("facilityNames")]);

  const clearAllFunc = () => {
    setSelectedFacilities([]);
    setFilteredFacilities([]);
    setSelectedFacilitiesLocalCopy([]);
    setClearAll(false);
    setisAnyfacilitySelected(false);
    setShowClear(false);
    handleFacilityClearAll();
  };

  useEffect(() => {
    if (selectedFacilitiesLocalCopy.length > 1) {
      setisAnyfacilitySelected(true);
    }
  }, [selectedFacilitiesLocalCopy]);


  const facilityCheckboxChange = (facilityName, isCheckValue) => {
    if (!selectedFacilitiesLocalCopy.some((f) => f.facilityName === facilityName)) {
      setSelectedFacilitiesLocalCopy([
        ...selectedFacilitiesLocalCopy,
        { facilityName, isActive: isCheckValue, displayFacilityName: facilityName.trim() },
      ]);
    }

    if (
      isCheckValue === false &&
      selectedFacilitiesLocalCopy.some((f) => f.facilityName === facilityName)
    ) {
      setSelectedFacilitiesLocalCopy(
        selectedFacilitiesLocalCopy.filter((f) => f.facilityName !== facilityName)
      );
    }

    const updatedFacilities = selectedFacilitiesLocalCopy.map((facilityObj) => {
      if (facilityObj.facilityName === facilityName) {
        return { ...facilityObj, isActive: isCheckValue };
      }
      return facilityObj;
    });

    const anyFacilitySelected = updatedFacilities.some((facilityObj) => facilityObj.isActive);
    setisAnyfacilitySelected(anyFacilitySelected);
    setShowClear(anyFacilitySelected);

    if (isCheckValue) {
      setisAnyfacilitySelected(true);
      setShowClear(true);
    } else {
      if (selectedFacilitiesLocalCopy.every((facility) => facility.isActive === false)) {
        clearAllFunc();
      }
      if (!selectedFacilitiesLocalCopy.some((f) => f.isActive)) {
        setShowClear(false);
        setFacilitiesToEmpty(true);
      }
    }
  };

  const getNoDataMessage = () => {
    if (selectedFacilities.length === 0) return "No results to show";
    if (filteredFacilities.length === 0) return "No matches found";
    return "";
  };

  const defaultIcon = clearAll ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />;

  const { t } = useTranslation();
  return (
    <>
      <div
        className="d-flex justify-content-between align-items-center"
        style={{ marginBottom: !isMobile ? "15px" : "" }}
      >
        <div>
          <span className="filterSubHeads">{t("jobResults.filters.Facilities")}</span>
        </div>
        <div>
          {showClear && (
            <button type="button" className="facilityClearAll" onClick={clearAllFunc}>
              Clear
            </button>
          )}
        </div>
      </div>

      {selectedFacilities.length > 5 && !showSkeleton && (
        <TextField
          InputProps={{
            disableUnderline: true,
            style: {
              border: `1px solid ${isSearchBoxFocused ? "#003366" : "#9EA4B0"}`,
              borderRadius: "4px",
              height: "56px",
            },
            classes: {
              input: searchTerm.length ? localStyleClasses.facilitySearchFieldWidthWithInput : localStyleClasses.facilitySearchFieldWidthWithoutInput,
              root: localStyleClasses.facilitiesContainerWidth,
            },
            startAdornment: (
              <InputAdornment position="end" style={{ padding: "0px 10px 0px 2px" }}>
                <Search />
              </InputAdornment>
            ),
            endAdornment: searchTerm && (
              <InputAdornment position="start" variant="outlined" onClick={() => setSearchTerm("")}>
                <svg
                  role="button"
                  width="19"
                  height="19"
                  viewBox="0 0 19 19"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="19" height="19" rx="9.5" fill="#D6D8DC" />
                  <path
                    d="M13.4429 6.33011C13.6415 6.1382 13.6468 5.82169 13.4549 5.62315C13.263 5.42462 12.9465 5.41924 12.7479 5.61115L5.55792 12.5607C5.35938 12.7526 5.354 13.0692 5.54592 13.2677C5.73784 13.4662 6.05438 13.4716 6.25292 13.2797L13.4429 6.33011Z"
                    fill="#656E7E"
                  />
                  <path
                    d="M6.38097 5.61815C6.18571 5.4229 5.86913 5.4229 5.67387 5.61815C5.4786 5.8134 5.4786 6.12996 5.67387 6.32521L12.7449 13.3959C12.9402 13.5911 13.2568 13.5911 13.452 13.3959C13.6473 13.2006 13.6473 12.884 13.452 12.6888L6.38097 5.61815Z"
                    fill="#656E7E"
                  />
                </svg>
              </InputAdornment>
            ),
          }}
          onFocus={() => setIsSearchBoxFocused(true)}
          onBlur={() => setIsSearchBoxFocused(false)}
          onChange={(event) => setSearchTerm(event.target.value)}
          value={searchTerm}
          placeholder="Find Facility"
          variant="standard"
        />
      )}

      <div className="facilitiesListContainer">
        {showSkeleton 
        && (
          <div>
            <div>
              <Skeleton variant="rect" width={isMobile ? 345 : 300} height={30} />
              <Box display="flex" alignItems="center" style={{ marginTop: 10 }}>
                <Skeleton variant="rect" width={25} height={25} />
                <Skeleton variant="text" width={265} height={35} style={{ marginLeft: 10 }} />
              </Box>
              <Box display="flex" alignItems="center" style={{ marginTop: 10 }}>
                <Skeleton variant="rect" width={25} height={25} />
                <Skeleton variant="text" width={265} height={35} style={{ marginLeft: 10 }} />
              </Box>
              <Box display="flex" alignItems="center" style={{ marginTop: 10 }}>
                <Skeleton variant="rect" width={25} height={25} />
                <Skeleton variant="text" width={265} height={35} style={{ marginLeft: 10 }} />
              </Box>
            </div>
          </div>
        )}
        {filteredFacilities &&
          filteredFacilities.length > 0 &&
          (!showSkeleton)
          && filteredFacilities.map((facility) => {
            return (
              <div className="row align-items-center">
                <div className="col-2 align-content-center justify-content-end">
                  <Checkbox
                    icon={defaultIcon}
                    checked={facility.isActive}
                    onChange={(event) => {
                      facilityCheckboxChange(facility.facilityName, event.target.checked);
                    }}
                  />
                </div>
                <div className={`${localStyleClasses.ellipsis} col-10 align-content-center`}>
                  {" "}
                  <span>{facility.displayFacilityName}</span>
                </div>
              </div>
            );
          })}
        {filteredFacilities && !showSkeleton && filteredFacilities.length === 0 && (
          <p className={`${localStyleClasses.noFacilityTxt} mt-2 ml-1`}> {getNoDataMessage()} </p>
        )}
      </div>
    </>
  );
};

export default withTranslation()(Facilities);
