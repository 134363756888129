import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import { withTranslation } from "react-i18next";
import Success from "../../../images/success_icon.png";

interface PasswordUpdateProps {
  t: (key: string) => string;
}

function PasswordUpdate({ t }: PasswordUpdateProps) {
  return (
    <Container>
      <Row className="passUpdateMob loginFormV2">
        <Col sm={12} md={5} lg={5} xs={12} className="pb-3 pt-3" />
        <Col sm={12} md={7} lg={7} xs={12} className="pb-3 pt-3 pwdUpdateback">
          <Image src={Success} className="pswSuccessImg" />
          <div className="passUpdate">
            <h3>{t("forgotPassword.passwordUpdateText")}</h3>
            <p>
              {t("forgotPassword.passwordChangedText")}
              <br />
              {t("forgotPassword.connectingToDashboard")}
            </p>
          </div>
        </Col>
        <Col sm={12} md={1} lg={1} xs={12} className="pb-3 pt-3" />
      </Row>
    </Container>
  );
}

export default withTranslation()(PasswordUpdate);
