import { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import { List, ListItem, Divider, Paper, Checkbox, ListItemIcon } from "@material-ui/core";
import { withTranslation } from "react-i18next";
import PinDropIcon from "@material-ui/icons/PinDrop";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { fetchLocation, detectLocationInputClick } from "src/redux/globalSearch/action";
import DoneIcon from "@mui/icons-material/Done";
import { Col, Container, Row } from "react-bootstrap";
import "./filterList.scss";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import type { FilterListProps } from "./index";

const styles = () => ({
  root: {
    padding: "2px 0px",
    display: "flex",
    alignItems: "center",
    overflow: "auto",
  },
  input: {
    marginLeft: "0px",
    flex: 3,
    fontSize: "1em",
    lineHeight: "0px",
  },
  iconButton: {
    padding: 10,
  },
  card: {
    backgroundColor: "#f5f5f5",
    display: "block",
    height: "200px",
    width: "203px",
  },
});

class FilterList extends Component<FilterListProps & RouteComponentProps> {
  getOptionText(option) {
    if (option) {
      const { plainState, city, stateCode } = option;
      return plainState ?? `${city}, ${stateCode}`;
    }
    const { filterData } = this.props;
    if (filterData.length > 0) {
      const { plainState, city: autoSuggestedCity, stateCode, plainStateCode } = filterData[0];
      const city = plainState !== null ? "" : autoSuggestedCity;
      let state = plainState !== null ? plainStateCode : stateCode;
      if (city) {
        state = plainState!;
        return state;
      }
      return `${city}, ${state}`;
    }
    return "";
  }

  compactStateChecked = (checkValue) => {
    const { onCompactStateClick } = this.props;
    onCompactStateClick(checkValue);
  };

  renderOption = (option) => {
    const optionText = this.getOptionText(option);
    return (
      <Row>
        <Col className="pinDropIconContainer" sm={12} md={8} lg={12} xs={12}>
          <PinDropIcon
            fontSize="small"
            className="locationIconColor"
            style={{ color: "#D4DCE8" }}
          />
        </Col>
        <Col sm={12} md={8} lg={12} xs={12} className="textSelectedList">
          {optionText}
        </Col>
      </Row>
    );
  };

  handleSelectItem = (item) => {
    const { onItemClick } = this.props;

    onItemClick(item);
  };

  isSelected = (item) => {
    const { selectedItems, citySelectedItems } = this.props;
    return item.plainState
      ? selectedItems.some((loc: any) => loc.cityStateCombination === item.cityStateCombination) ||
          selectedItems.some((loc: any) => loc.plainState === item.plainState)
      : citySelectedItems.some((loc: any) => loc.cityStateCombination === item.cityStateCombination);
  };

  render() {
    const {
      filterData,
      classes,
      inputValue,
      t,
      isCompactStateCheckValue,
      compactStateDeletedValue,
      isUserType,
    } = this.props;
    let filterDataClass = "filterListNoData";
    if (inputValue !== "" && filterData.length === 0) {
      filterDataClass = "filterListNoOptionsData";
    }
    if (inputValue !== "" && filterData.length > 0) {
      filterDataClass = "filterListWithData";
    }

    if (inputValue === "" && filterData.length === 0) {
      filterDataClass = "filterListNoData";
    }

    const defaultIcon = isCompactStateCheckValue ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />;

    const icon = compactStateDeletedValue ? <IndeterminateCheckBoxIcon /> : defaultIcon;

    return (
      <Paper elevation={4} className="filterListPaper">
        <Container className="containerStyle">
          <Row>
            <Col
              sm={12}
              md={12}
              lg={12}
              xs={12}
              className="compactStateStyle"
              data-condition={isUserType}
            >
              {isUserType && (
                <div className="checkboxColor">
                  <Checkbox
                    className="checkboxIcon"
                    checked={isCompactStateCheckValue}
                    checkedIcon={icon}
                    onChange={(event) => {
                      this.compactStateChecked(event.target.checked);
                    }}
                  />
                  <span className="addCompactStateText">
                    {t("globalSearch.location.addCompactStates")}
                  </span>
                  <span className="compactStateCSStyle">(CS)</span>
                </div>
              )}
            </Col>
          </Row>

          <Row>
            <Col className="resultsText" sm={12} md={8} lg={3} xs={12}>
              <div className="resultsClassCCA" data-condition={isUserType}>
                {t("globalSearch.location.results")}
              </div>
            </Col>
          </Row>
        </Container>
        {inputValue === "" && (
          <Col className="resultsText" sm={12} md={8} lg={4} xs={12}>
            <div className="typeToSelect px-1">{t("globalSearch.location.typeToShowResult")}</div>
          </Col>
        )}
        <div className={filterDataClass} data-condition={isUserType}>
          {inputValue !== "" && (
            <div className="filterContainer" data-condition={isUserType}>
              <List className={classes.input}>
                {filterData.length > 0 &&
                  filterData.map((item, index) => (
                    <div key={item.id}>
                      <div className="row">
                        <div className="col-9">
                          <ListItem
                            className="fetchList"
                            onClick={() => this.handleSelectItem(item)}
                          >
                            {this.renderOption(item)}
                          </ListItem>
                        </div>
                        <div className="col-3">
                          {" "}
                          {this.isSelected(item) && (
                            <ListItemIcon className="listItemClass">
                              <DoneIcon />
                            </ListItemIcon>
                          )}{" "}
                        </div>
                      </div>
                      {/* Separator */}
                      {/* Use a key to separate items if possible */}
                      {index !== filterData.length - 1 && (
                        <Divider className="listDivider" key={`divider-${item.id}`} />
                      )}
                    </div>
                  ))}
                {filterData.length === 0 && (
                  <Row className="descriptionRowStyle">
                    <Col sm={12} md={8} lg={12} xs={12}>
                      <div className="noOptionText">{t("globalSearch.location.noFilterList")}</div>
                    </Col>
                  </Row>
                )}
              </List>
            </div>
          )}
        </div>
      </Paper>
    );
  }
}

const mapStateToProps = (state) => ({
  locationAutosuggest: state.globalSearch.location,
  loading: state.globalSearch.loading,
  firstName: state.auth.firstName,
  value: state.value,
});

const mapDispatchToProps = (dispatch) => ({
  getLocation: (term) => dispatch(fetchLocation(term)),
  detectTheLocationInputClick: (value) => dispatch(detectLocationInputClick(value)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(withTranslation()(withRouter(FilterList))));
