import { Grid, Modal } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { AutocompleteRenderInputParams } from "@material-ui/lab/Autocomplete";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import moment from "moment";
import config from "src/config";
import CertificationsLicensesUpload from "src/components/common/CertificationsLicenses/CertificationsLicensesUpload";
import { fileToBase64, base64ToFile } from "src/services/utility";
import { getAccessToken } from "axios-jwt";
import { getCredentialsFile } from "src/services/GraphQL/graph.query";
import { graphqlRequest as request } from "src/services/GraphQL/GraphQL";
import { APIMethods } from "src/constants/constants";
import SecondaryButton from "src/components/common/Buttons/SecondaryButton";
import { CredentialFileResponse } from "src/components/common/OnBoarding/CredentialItem";
import CameraAltOutlinedIcon from "@mui/icons-material/CameraAltOutlined";
import { trackSegmentAnalyticsEvent } from "src/utils/trackSegmentAnalyticsEvent";
import PrimaryButton from "../Buttons/PrimaryButton";
import CertificationsLicensesWebcam from "./CertificationAndLicensesWebcam";

type Side = "front" | "back";
type UploadStatus = Record<Side, string | null>;
export interface credentialsTypes {
  id: number;
  label: string;
  defaultExpirationReminderDays: string;
  expirationDateOptional: boolean;
  hasLicenseNumber: string;
  isTwoSided: boolean;
  requireCompleteDate: string;
}

interface CertificationsLicensesFormProps {
  credentialTypeList: credentialsTypes[];
  saveNewCredentials: (
    content: CertificationContent,
    files: CertificationFile[],
    requestType: string,
    certificationId: number,
    candidateCertId: Number[] | null
  ) => void;
  candidateCredential: any | null;
  apiMethod: string;
  userMadeChangesCallback?: (licenseSelected?: credentialsTypes) => void;
}

interface FieldProps {
  value: string | Date | null;
  isError: boolean;
  errorText: string;
}

interface CertificationFile {
  base64FileContent: string;
  contentType: string;
  fileName: string;
}

interface CertificationContent {
  certificationId?: number;
  name: string;
  completedDate?: number;
  dateCertified?: number;
  dateExpiration?: number;
  isDeleted?: boolean;
  licenseNumber?: string;
  licenseType?: string;
  status?: string;
  thirdPartyFlag?: string;
}

const CertificationsLicensesForm = (props: CertificationsLicensesFormProps) => {
  const { t } = useTranslation();
  const {
    credentialTypeList,
    saveNewCredentials,
    candidateCredential,
    apiMethod,
    userMadeChangesCallback,
  } = props;

  const enableCertificationsLicensesWebcam: boolean =
    config.ENABLE_CERTIFICATIONS_LICENSES_WEBCAM === "true" ?? false;
  const [authToken, setAuthToken] = useState(getAccessToken());
  const [credentialType, setCredentialType] = useState<credentialsTypes | null>(
    credentialTypeList.find((item) => item?.label === candidateCredential?.name) || null
  );
  const [expirationDate, setExpirationDate] = useState<FieldProps>({
    value: candidateCredential?.dateExpiration || null,
    isError: !candidateCredential?.dateExpiration,
    errorText: candidateCredential?.dateExpiration ? "" : t("onBoarding.errorMessage.required"),
  });
  const [completionDate, setCompletionDate] = useState<FieldProps>({
    value: candidateCredential?.dateCompleted || null,
    isError: !candidateCredential?.dateCompleted,
    errorText: candidateCredential?.dateCompleted ? "" : t("onBoarding.errorMessage.required"),
  });
  const [licenseNumber, setLicenseNumber] = useState<FieldProps>({
    value: candidateCredential?.licenseNumber,
    isError: !candidateCredential?.licenseNumber,
    errorText: candidateCredential?.licenseNumber ? "" : t("onBoarding.errorMessage.required"),
  });
  const [uploadedFiles, setUploadedFiles] = useState<(File | null)[]>([]);
  const [uploadedFileIds, setUploadedFileIds] = useState<Number[] | null>([]);
  const [uploadedStatus, setUploadedStatus] = useState<UploadStatus>({ front: null, back: null });
  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState<boolean>(true);
  const [isFileDownloading, setIsFileDownloading] = useState<boolean>(false);
  const [capturing, setCapturing] = useState<boolean>(false);
  const [capturingSide, setCapturingSide] = useState<Side>("front");
  const [isWebcamAvailable, setIsWebcamAvailable] = useState<boolean>(false);
  const showLicenseNumber = credentialType?.hasLicenseNumber === "Yes";

  const validateForm = () => {
    let isDisable = uploadedFiles.includes(null);
    isDisable =
      !isDisable && credentialType?.requireCompleteDate === "Yes" ? completionDate.isError : true;
    if (credentialType?.expirationDateOptional === false) {
      isDisable =
        !isDisable && credentialType?.expirationDateOptional === false
          ? expirationDate.isError
          : true;
    }
    if (showLicenseNumber) {
      isDisable = !isDisable && showLicenseNumber ? licenseNumber.isError : true;
    }
    setIsSubmitButtonDisabled(isDisable);
  };

  const handleDateChange = (isExpirationDate: boolean, date: MaterialUiPickersDate) => {
    const formattedDate = moment(date).format("YYYY-MM-DD");
    const toDaysDate = moment(moment().toDate()).format("YYYY-MM-DD");
    const isAfter = moment(formattedDate).isSameOrAfter(toDaysDate);
    const isBefore = moment(formattedDate).isSameOrBefore(toDaysDate);
    let errorText = !credentialType || !date ? t("onBoarding.errorMessage.required") : "";
    if (isExpirationDate)
      errorText = !isAfter ? t("certificationAndLicenses.expirationDateError") : "";
    if (!isExpirationDate)
      errorText = !isBefore ? t("certificationAndLicenses.completionDateError") : "";
    const isError = !!errorText;
    errorText = !date ? t("onBoarding.errorMessage.required") : errorText;
    if (isExpirationDate) {
      setExpirationDate({ value: date, isError, errorText });
    } else {
      setCompletionDate({ value: date, isError, errorText });
    }
  };

  const handleLicenseNumberChange = (credentialLicenseNumber: string) => {
    const isValidLicenseNumber = /^[a-zA-Z0-9._-]*$/.test(credentialLicenseNumber);
    let errorText = !credentialLicenseNumber ? t("onBoarding.errorMessage.required") : "";
    if (errorText === "")
      errorText = !isValidLicenseNumber ? t("certificationAndLicenses.licenseNumberError") : "";
    const isError = !!errorText;
    setLicenseNumber({ value: credentialLicenseNumber, isError, errorText });
  };

  const reSetState = () => {
    setExpirationDate({
      value: null,
      isError: true,
      errorText: t("onBoarding.errorMessage.required"),
    });
    setCompletionDate({
      value: null,
      isError: true,
      errorText: t("onBoarding.errorMessage.required"),
    });
    setLicenseNumber({
      value: "",
      isError: true,
      errorText: t("onBoarding.errorMessage.required"),
    });
  };
  /* 
    setting the credential autocomplete to default value
    if the candidate has only one credential type in the list.
  */
  useEffect(() => {
    if(credentialTypeList?.length === 1){
      setCredentialType(credentialTypeList[0])
    }
  }, [credentialTypeList])

  useEffect(() => {
    setAuthToken(getAccessToken());
    setUploadedFiles(credentialType?.isTwoSided ? [null, null] : [null]);
  }, [credentialType]);

  const getCredentialsFileData = async (id: number) => {
    const query = { credentialsFileId: id };
    return new Promise<File>((resolve, reject) => {
      request(getCredentialsFile, query, authToken)
        .then((res: CredentialFileResponse) => {
          const [fileName, contentType, fetchData] = [
            res?.getCredentialsFile?.file?.name,
            res?.getCredentialsFile?.file?.contentType,
            res?.getCredentialsFile?.file?.fileContent,
          ];
          const myFile = fetchData
            ? base64ToFile(fetchData, fileName, contentType)
            : new File([], fileName, { type: contentType });
          resolve(myFile);
        })
        .catch((error) => {
          setIsFileDownloading(false);
          reject(error);
        });
    });
  };

  useEffect(() => {
    const initial = credentialType?.isTwoSided
      ? { front: "front", back: "back" }
      : { front: "front", back: "" };
    setUploadedStatus(initial);
  }, [credentialType?.isTwoSided]);

  useEffect(() => {
    const promiseArray: Promise<any>[] = [];
    const fileIds: Number[] = [];
    candidateCredential?.certificationFileAttachments?.forEach((obj) => {
      setIsFileDownloading(true);
      promiseArray.push(getCredentialsFileData(obj?.id));
      fileIds.push(obj?.id);
    });

    Promise.all(promiseArray).then((files: File[]) => {
      let isFront: Boolean = false;
      const sortedFiles: File[] = [];
      let [first, second] = [...files];
      const [firstId, secondId] = fileIds;
      isFront = files[0]?.name.split(".")?.[0].split("_").pop() === "front";

      if (!isFront) {
        const temp = first;
        first = second;
        second = temp;

        const tempId = firstId;
        fileIds[0] = secondId;
        if (credentialType?.isTwoSided) fileIds[1] = tempId;
      }
      first = first ?? null;
      sortedFiles.push(first);
      if (credentialType?.isTwoSided) {
        second = second ?? null;
        sortedFiles.push(second);
      }
      const initial = credentialType?.isTwoSided
        ? { front: first ? "front" : "", back: second ? "back" : "" }
        : { front: first ? "front" : "", back: "" };
      setUploadedStatus(initial);
      setUploadedFiles(sortedFiles);
      setIsFileDownloading(false);
    });

    setUploadedFileIds(fileIds);
  }, []);

  useEffect(() => {
    validateForm();
  }, [credentialType, expirationDate, completionDate, licenseNumber, uploadedFiles]);

  useEffect(() => {
    const detectWebcam = () => {
      const md = navigator.mediaDevices;
      if (!md || !md.enumerateDevices) {
        setIsWebcamAvailable(false);
      }

      const hasCamera = md
        .enumerateDevices()
        .then((devices) => devices.some((device) => device.kind === "videoinput"));

      const hasPermissions = navigator.permissions
        .query({ name: "camera" as PermissionName })
        .then((result) => result.state !== "denied");

      Promise.all([hasCamera, hasPermissions]).then((result) => {
        if (!result.includes(false)) setIsWebcamAvailable(true);
      });
    };

    if (enableCertificationsLicensesWebcam) {
      detectWebcam();
    }
  }, [navigator.permissions]);

  const saveCredentials = async () => {
    const certificationFiles: CertificationFile[] = await Promise.all(
      uploadedFiles.map(async (file) => ({
        fileName: file!.name,
        contentType: file!.type,
        base64FileContent: await fileToBase64(file),
      }))
    );

    const dateExpToUnix = expirationDate.value
      ? new Date(expirationDate.value as string).setHours(0, 0)
      : undefined;
    const isExpired = dateExpToUnix && dateExpToUnix < new Date().setHours(0, 0);

    const certificationContent: CertificationContent = {
      certificationId: credentialType!.id,
      name: credentialType!.label,
      dateExpiration: dateExpToUnix,
      completedDate: new Date(completionDate.value as string).setHours(0, 0),
      licenseNumber:
        credentialType!.hasLicenseNumber === "Yes" ? (licenseNumber.value as string) : undefined,
      status: isExpired ? "Expired" : "Automated",
    };

    if (APIMethods.EDIT === apiMethod) {
      delete certificationContent.certificationId;

      if (uploadedStatus.front === "front") {
        if (certificationFiles[0]?.fileName.split(".")?.[0].split("_").pop() === "front") {
          certificationFiles.shift();
        } else {
          certificationFiles.pop();
        }
      }

      if (uploadedStatus.back === "back") {
        if (certificationFiles[0]?.fileName.split(".")?.[0].split("_").pop() === "back") {
          certificationFiles.shift();
        } else {
          certificationFiles.pop();
        }
      }
      trackSegmentAnalyticsEvent(
        "segment.credentialEdited",
        {
          credentialName: credentialType!.label,
          credentialType: "Certifications",
        },
        t
      );
    }

    trackSegmentAnalyticsEvent(
      "segment.credentialEdited",
      {
        credentialName: credentialType!.label,
        credentialType: "Certifications",
      },
      t
    );

    setIsSubmitButtonDisabled(true);
    saveNewCredentials(
      certificationContent,
      certificationFiles,
      apiMethod,
      candidateCredential?.id,
      uploadedFileIds
    );
  };

  const renderDatePicker = (dateType: string): React.ReactElement => {
    const isExpirationDate: boolean = dateType === "expirationDate";
    return (
      <FormControl fullWidth className="dateControl onBtextfiled">
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            inputVariant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            className="formControlWidth"
            views={["year", "month", "date"]}
            allowKeyboardControl={false}
            autoOk
            variant="inline"
            format="MM/dd/yyyy"
            margin="normal"
            id={
              isExpirationDate ? "Expiration-Date-addCredential" : "Completion-Date-addCredential"
            }
            label={
              isExpirationDate
                ? `${t("certificationAndLicenses.expirationDate")} *`
                : `${t("certificationAndLicenses.completionDate")} *`
            }
            value={isExpirationDate ? expirationDate?.value : completionDate?.value}
            onChange={(date) => {
              handleDateChange(isExpirationDate, date);
              if (date && userMadeChangesCallback != null) userMadeChangesCallback();
            }}
            minDate={isExpirationDate ? new Date() : undefined}
            maxDate={!isExpirationDate ? new Date() : undefined}
            helperText={isExpirationDate ? expirationDate?.errorText : completionDate?.errorText}
            error={isExpirationDate ? expirationDate?.isError : completionDate?.isError}
          />
        </MuiPickersUtilsProvider>
      </FormControl>
    );
  };

  const renderTitle = () => {
    const title =
      apiMethod === "edit"
        ? t("certificationAndLicenses.EditFormTitle")
        : t("certificationAndLicenses.AddNewFormTitle");
    return title;
  };

  const showWebcam = (side: Side) => {
    if (navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices
        .getUserMedia({ video: true })
        .then(() => {
          setCapturing(true);
          setCapturingSide(side);
        })
        .catch(() => {
          setIsWebcamAvailable(false);
        });
    }
  };

  const webcamImageCapture = () => {
    userMadeChangesCallback?.();
  };

  const handleWebcamDialogComplete = () => {
    setCapturing(false);
  };

  const renderCaptureButtons = () => {
    if (credentialType?.isTwoSided) {
      return (
        <>
          <Box sx={{ paddingBottom: 2, marginTop: 2, textAlign: "center" }}>
            <PrimaryButton
              disabled={!isWebcamAvailable}
              component="button"
              size="medium"
              className="actionButton"
              onClick={() => showWebcam("front")}
            >
              <CameraAltOutlinedIcon fontSize="small" className="iconPaddingBottom" />
              <span className="addCredentialsButtonText">
                {t("certificationAndLicenses.captureFront")}
              </span>
            </PrimaryButton>
          </Box>
          <Box className="certOrText">
            <span className="orText">{t("certificationAndLicenses.or")}</span>
          </Box>
          <Box sx={{ paddingBottom: 2, marginTop: 2, textAlign: "center" }}>
            <PrimaryButton
              disabled={!isWebcamAvailable}
              component="button"
              size="medium"
              className="actionButton"
              onClick={() => showWebcam("back")}
            >
              <CameraAltOutlinedIcon fontSize="small" className="iconPaddingBottom" />
              <span className="addCredentialsButtonText">
                {t("certificationAndLicenses.captureBack")}
              </span>
            </PrimaryButton>
          </Box>
          <Box className="certOrText">
            <span className="orText">{t("certificationAndLicenses.or")}</span>
          </Box>
        </>
      );
    }
    return (
      <>
        <Box sx={{ paddingBottom: 2, textAlign: "center" }}>
          <PrimaryButton
            disabled={!isWebcamAvailable}
            component="button"
            size="medium"
            className="actionButton"
            onClick={() => showWebcam("front")}
          >
            <CameraAltOutlinedIcon fontSize="small" className="iconPaddingBottom" />
            <span className="addCredentialsButtonText">
              {t("certificationAndLicenses.capture")}
            </span>
          </PrimaryButton>
        </Box>
        <Box className="certOrText">
          <span className="orText">{t("certificationAndLicenses.or")}</span>
        </Box>
      </>
    );
  };
  
  const isDisabled = APIMethods.EDIT === apiMethod || credentialTypeList?.length === 1;

  return (
    <>
      <Box hidden={capturing} className="certForm onBMainTxtField">
        <div className="certTitle">
          <span className="addNewCredential">{renderTitle()}</span>
        </div>
        <Box>
          <Grid item xs={12} md={12}>
            <FormControl fullWidth className="onBtextfiled">
              <Autocomplete
                disabled={isDisabled}
                popupIcon={<ArrowDropDownIcon />}
                className="autoCompleteInput"
                renderInput={(params: AutocompleteRenderInputParams) => (
                  <TextField
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...params}
                    variant="outlined"
                    label={`${t("certificationAndLicenses.credentialType")} *`}
                    helperText={!credentialType ? t("onBoarding.errorMessage.required") : ""}
                    error={!credentialType}
                    InputProps={{
                      ...params.InputProps,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}
                options={credentialTypeList}
                getOptionLabel={({ label }) => label}
                onChange={(_, value: credentialsTypes | null) => {
                  setCredentialType(value);
                  if (value && userMadeChangesCallback != null) userMadeChangesCallback(value);
                  if (
                    expirationDate.value !== "" ||
                    completionDate.value !== "" ||
                    licenseNumber.value !== ""
                  )
                    reSetState();
                }}
                value={credentialType}
              />
            </FormControl>
            {credentialType?.expirationDateOptional === false && renderDatePicker("expirationDate")}
            {credentialType?.requireCompleteDate === "Yes" && renderDatePicker("")}
            {showLicenseNumber && (
              <div className="pt-2 pb-2">
                <FormControl fullWidth className="onBtextfiled">
                  <TextField
                    label={`${t("certificationAndLicenses.licenseNumber")} *`}
                    variant="outlined"
                    className="formControlWidth"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    type="TextField"
                    value={licenseNumber?.value}
                    onChange={(e) => {
                      handleLicenseNumberChange(e?.target?.value);
                      if (e?.target?.value && userMadeChangesCallback != null)
                        userMadeChangesCallback();
                    }}
                    helperText={showLicenseNumber ? licenseNumber?.errorText : ""}
                    error={licenseNumber?.isError}
                  />
                </FormControl>
              </div>
            )}
            {enableCertificationsLicensesWebcam && credentialType && renderCaptureButtons()}
            {credentialType && (
              <CertificationsLicensesUpload
                uploaded={uploadedFiles}
                setUploaded={setUploadedFiles}
                twoSided={credentialType.isTwoSided}
                uploadedFilesIds={uploadedFileIds}
                setUploadedFileIdsCallBack={setUploadedFileIds}
                setUploadedStatus={(ids) => {
                  setUploadedStatus(ids);
                  if (ids && userMadeChangesCallback != null) userMadeChangesCallback();
                }}
                uploadedStatus={uploadedStatus}
                isFileDownloading={isFileDownloading}
              />
            )}

            <div className="pt-2">
              <SecondaryButton
                component="button"
                size="medium"
                className="actionButton"
                disabled={isSubmitButtonDisabled}
                onClick={saveCredentials}
              >
                <span>{t("certificationAndLicenses.saveAndSubmit")}</span>
              </SecondaryButton>
            </div>
          </Grid>
        </Box>
      </Box>
      <Modal className="cerAndLicModal" open={capturing} onClose={handleWebcamDialogComplete}>
        <div className="certAndLic">
          <IconButton
            aria-label="Close"
            onClick={handleWebcamDialogComplete}
            style={{ position: "absolute", right: 8, top: 8, zIndex: 9 }}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
          <CertificationsLicensesWebcam
            uploaded={uploadedFiles}
            setUploaded={setUploadedFiles}
            setCapturing={setCapturing}
            setUploadedStatus={setUploadedStatus}
            uploadedStatus={uploadedStatus}
            webcamImageCapture={webcamImageCapture}
            capturingSide={capturingSide}
          />
        </div>
      </Modal>
    </>
  );
};

CertificationsLicensesForm.defaultProps = {
  userMadeChangesCallback: () => {},
};

export default CertificationsLicensesForm;
