import { Component } from "react";
import { Image, Row, Col, Container } from "react-bootstrap";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import moment from "moment";
import RecommendedJobsV2 from "src/components/common/MainContent/JobsTab/SearchCrossCountry/RecommendedJobsV2";
import random from "lodash/random";
import { connect } from "react-redux";
import { fetchMspJobs, fetchRecommendedJobs } from "src/redux/jobs/SearchCrossCountry/action";
import MyRecruiter from "src/components/common/MainContent/Home/MyRecruiter";
import { fetchRecruiterDetails } from "src/redux/consultant/action";
import config from "src/config";
import VerifyWorkExperience from "src/components/common/OnBoarding/OnBVerifyWorkExp";
import { DashboardProps, DashboardState } from "src/components/common/MainContent/Home";
import LocationSpecialities from "src/components/common/MainContent/Home/LocationSpecialities";
import DashboardBannerV2 from "src/components/common/MainContent/Home/DashboardBannerV2";
import MainLayout from "src/components/pages/MainLayout";
import SnackbarSuccess from "src/components/common/SnackbarSuccess/SnackbarSuccess";
import { getAllWorkExperiences } from "src/redux/auth/action"
import getCandidateInformation from "src/components/common/CandidateInformation/CandidateInformation";
import VectorIcon from "src/imagesV2/hotJobsRed.svg";
import JobCardContainer from "src/components/common/MainContent/JobResults/JobCardContainer";
import JobsCarousel from "src/components/common/MainContent/JobDescription/JobsCarousel";
import { HOT_JOBS, HOMEPAGE } from "src/constants/campaigns_constants";
import { isMobile } from "react-device-detect";
import { RecommendedJobsSourceTypes } from "src/types/receommendedJobsSource";
import Analytics from "src/services/Analytics";
import clsx from "clsx";
import JobEligibilityDashboardAdvertisement from "./JobDashboardAdvertisement/JobEligibilityDashboardAdvertisement";

const workHistoryAutomation = config.ENABLE_WORK_HISTORY_AUTOMATION === "true";
const enableHotJobs = config.ENABLE_HOT_JOBS === "true";
const mspJobsSize = config.MSP_JOBS_COUNT;
const enableDashboardAdvertisement = config.Dashboard_ADVERTISEMENT === "true";
class DashboardV2 extends Component<DashboardProps & RouteComponentProps, DashboardState> {

  constructor(props) {
    super(props);
    localStorage.removeItem("completedSteps");

    const historyPathName = localStorage.getItem("historyPathName");
    if (historyPathName?.includes("/auth/relay")) this.triggerRecruitics();

    const images = {
      am: [
        "dashboardBannerBkgrdAM1",
        "dashboardBannerBkgrdAM2",
        "dashboardBannerBkgrdAM3",
        "dashboardBannerBkgrdAM4",
        "dashboardBannerBkgrdAM6",
      ],
      pm: [
        "dashboardBannerBkgrdPM1",
        "dashboardBannerBkgrdPM2",
        "dashboardBannerBkgrdPM3",
        "dashboardBannerBkgrdPM4",
        "dashboardBannerBkgrdPM5",
        "dashboardBannerBkgrdPM6",
      ],
    };
    const amStartVal = "06:00";
    const amEndVal = "18:00";
    const amStart = moment(amStartVal, "HH:mm");
    const amEnd = moment(amEndVal, "HH:mm");
    const ampm = moment().isBetween(amStart, amEnd) ? "am" : "pm";

    this.state = {
      imgSrc: images[`${ampm}`][random(0, images[`${ampm}`].length - 1)],
      recruiterDetailsCallCounter: 0,
      recommendedJobsCallCounter: 0,
      quickApplyAdvJobs: [],
      isJobSearchWithViewsLoading: false,
      isRecommendedJobsEventTracked: false
    };
    this.updateQuickApplyAdvJobs = this.updateQuickApplyAdvJobs.bind(this);
    this.toggleJobsViewsLoader = this.toggleJobsViewsLoader.bind(this)
  }


  componentDidMount() {
    if (window.location.pathname === "/login") return;
    window.scrollTo(0, 0);
    const {
      companyCode,
      recruiterDetails,
      atsBullhornCandidateId,
      fetchRecommendedJobs: fetchTheRecommendedJobs,
      fetchRecruiterDetails: fetchTheRecruiterDetails,
      candidateId,
      email,
      onboardingData,
      bhId,
      onboardingInfo,
      getAllWorkExperiences: fetchAllWorkExperienceRecords,
      onboardingSpecialtiesAndEducation,
      fetchMspJobs: fetchTheMspJobs,
      recommendedJobsCarouselItems
    } = this.props;
    const candidateInformation = getCandidateInformation(onboardingInfo)

    const data = {
      sort: "relevant",
      companyId: companyCode.id,
      size: config.JOB_COUNT,
      candidateId: (atsBullhornCandidateId || bhId) ?? null,
      candidateInformation
    };
    fetchTheRecommendedJobs(data);
    const {isRecommendedJobsEventTracked} = this.state
    if(!isRecommendedJobsEventTracked && recommendedJobsCarouselItems.length > 0) {
      this.setState({isRecommendedJobsEventTracked: !isRecommendedJobsEventTracked})
      this.triggerSegmentEvent()
    }

    const query: any = {};
    query.profession = onboardingSpecialtiesAndEducation?.specialty?.profession;
    query.specialty = onboardingSpecialtiesAndEducation?.specialty?.primarySpecialty;
    query.companyId = companyCode.id;
    query.size = parseInt(mspJobsSize!, 10);
    if (enableHotJobs && query.profession && query.specialty) {
      fetchTheMspJobs(query)
    }

    if (email) {
      const onboardingDataObject = {
        response: onboardingData
      };
      fetchAllWorkExperienceRecords(email, onboardingDataObject)
    }

    if (!recruiterDetails && candidateId) {
      fetchTheRecruiterDetails(candidateId);
    }
  }

  componentDidUpdate(prevProps) {
    if (window.location.pathname === "/login") return;
    const {
      recruiterDetails,
      candidateId,
      recruiterDetailsLoading,
      fetchRecommendedJobs: fetchTheRecommendedJobs,
      fetchRecruiterDetails: fetchTheRecruiterDetails,
      recommendedJobsCarouselItems,
      recommendedJobsLoading,
      companyCode,
      atsBullhornCandidateId,
      email,
      onboardingData,
      bhId,
      onboardingInfo,
      getAllWorkExperiences: fetchAllWorkExperienceRecords,
      onboardingSpecialtiesAndEducation,
      fetchMspJobs: fetchTheMspJobs,
      mspJobs,
      mspJobsLoading
    } = this.props;
    const { recruiterDetailsCallCounter, recommendedJobsCallCounter, isRecommendedJobsEventTracked } = this.state;
    if (
      !recruiterDetails &&
      !recruiterDetailsLoading &&
      recruiterDetailsCallCounter < 10 &&
      candidateId
    ) {
      this.updateCounter("recruiterDetailsCallCounter", recruiterDetailsCallCounter);
      fetchTheRecruiterDetails(candidateId);
    }
    const candidateInformation = getCandidateInformation(onboardingInfo);
    if (
      (recommendedJobsCarouselItems.length === 0 &&
        recommendedJobsCallCounter < 10 &&
        candidateInformation?.onboarding &&
        !recommendedJobsLoading) ||
      (onboardingInfo?.onboardingInformation && prevProps.onboardingInfo?.onboardingInformation !== onboardingInfo?.onboardingInformation) ||
      (bhId && prevProps.bhId !== bhId) ||
      prevProps.onboardingInfo?.isCredentialsLoading !== onboardingInfo?.isCredentialsLoading
    ) {
      const data = {
        sort: "relevant",
        companyId: companyCode.id,
        size: config.JOB_COUNT,
        candidateId: atsBullhornCandidateId || bhId,
        candidateInformation
      };
      this.updateCounter("recommendedJobsCallCounter", recommendedJobsCallCounter);
      fetchTheRecommendedJobs(data);
    }
    if(!isRecommendedJobsEventTracked && recommendedJobsCarouselItems.length > 0) {
      this.setState({isRecommendedJobsEventTracked: !isRecommendedJobsEventTracked})
      this.triggerSegmentEvent()
    }

    if (email && prevProps.email !== email) {
      const onboardingDataObject = {
        response: onboardingData
      };
      fetchAllWorkExperienceRecords(email, onboardingDataObject)
    }
    if (enableHotJobs &&
      (mspJobs === null) &&
      !mspJobsLoading && onboardingSpecialtiesAndEducation?.specialty?.profession && onboardingSpecialtiesAndEducation?.specialty?.primarySpecialty
    ) {
      const query: any = {};
      query.profession = onboardingSpecialtiesAndEducation?.specialty?.profession;
      query.specialty = onboardingSpecialtiesAndEducation?.specialty?.primarySpecialty;
      query.companyId = companyCode.id;
      query.size = 16;
      fetchTheMspJobs(query);
    }
  }

  hotJobsCarousel = (items) => {
    const cardArray: any[] = [];
    const jobCount: number = items?.length
    items?.forEach((job) => {
      cardArray.push(
        <JobCardContainer
          job={job}
          jobCount={jobCount}
          hotJobs
          campaignCode={HOT_JOBS}
          sourceType={HOMEPAGE}
          jobsSource={RecommendedJobsSourceTypes.NONE}
        />
      );
    });
    return cardArray
  }

  quickApplyAdvCarousel = (items) => {
    const cardArray: any[] = [];
    const jobCount: number = items?.length
    items?.forEach((job) => {
      cardArray.push(
        <JobCardContainer
          job={job}
          jobCount={jobCount}
          isQuickApplyAdvCard
          sourceType={HOMEPAGE}
          jobsSource={RecommendedJobsSourceTypes.NONE}
        />
      );
    });
    return cardArray
  }

  getHotJobsTitle = (jobsTitle) => {
    const hotJobsTitleClasses = `hotJobsTitle ${isMobile ? "text-center" : "ml-4"}`;
    return (
      <div className={hotJobsTitleClasses}>
        <Image src={VectorIcon} className="hotJobsIcon" />
        <span className="hotJobsTitleText">
          {jobsTitle}
        </span>
      </div>
    )
  }


  getDashboard = () => {
    const { imgSrc, quickApplyAdvJobs, isJobSearchWithViewsLoading } = this.state;
    const { recruiterDetails, verifyEmailPopUp, passwordSuccessPopUp, mspJobs, onboardingInfo, onBoardingCompletedSteps, isInitApiLoading, t} = this.props;
    const componentName = t("globalSearch.location.componentDashboard");
    const jobsTitle = t("dashboard.hotJobs.hotJobsHeadingText")
    const hotJobsTitle = this.getHotJobsTitle(jobsTitle);
    let hotJobsCarouselItems: string[] = []
    let quickApplyAdvCarouselItems: string[] = []
    if (enableHotJobs && mspJobs && mspJobs?.embedded?.results?.length !== 0 && mspJobs?.embedded?.results !== null) {
      hotJobsCarouselItems = this.hotJobsCarousel(mspJobs.embedded.results)
    }
    if (enableDashboardAdvertisement && quickApplyAdvJobs.length !== 0 && quickApplyAdvJobs.length !== null) {
      quickApplyAdvCarouselItems = this.quickApplyAdvCarousel(quickApplyAdvJobs)
    }

    return (

      <>
        {verifyEmailPopUp && <SnackbarSuccess message={t("emailVerification.success")} />}
        {passwordSuccessPopUp && (
          <span className="createPasswordSuccess">
            <SnackbarSuccess message={t("createPassword.passwordUpdated")} />
          </span>
        )}
        <div>
          {workHistoryAutomation && this.displayBanner() && (
            <div className="payRateNoteDashB">
              <VerifyWorkExperience props={undefined} />
            </div>
          )}
          <DashboardBannerV2 componentName={componentName} bgImgClass={imgSrc} onboardingInfo={onboardingInfo} updateQuickApplyAdvJobs={this.updateQuickApplyAdvJobs} toggleJobsViewsLoader={this.toggleJobsViewsLoader} />
          {
            !enableDashboardAdvertisement || (enableDashboardAdvertisement && Object.keys(onBoardingCompletedSteps).length < 5) ? (
              <Container
                className={clsx(
                  enableHotJobs && mspJobs?.embedded?.results?.length !== 0 && mspJobs?.embedded?.results !== null
                    ? "jobSearchContainerWithHotJobs"
                    : "jobSearchContainer"
                  )
                }
              >
                <Row>
                  <Col xs={12}>
                    <LocationSpecialities enableDashboardAd={false} translate={t} componentName={componentName} />
                  </Col>
                </Row>
              </Container>
            ) : null
          }

          {enableHotJobs &&
            mspJobs &&
            mspJobs?.embedded?.results?.length !== 0 &&
            mspJobs?.embedded?.results !== null && (
              <Container fluid className="hotJobsContainer">
                <Container className="recomdJobCardPadding">
                  <JobsCarousel card={hotJobsCarouselItems!} title={hotJobsTitle} hotJobs />
                </Container>
              </Container>
            )}

          {enableDashboardAdvertisement ?
            <JobEligibilityDashboardAdvertisement isJobSearchWithViewsLoading={isJobSearchWithViewsLoading} isInitApiLoading={isInitApiLoading} quickApplyAdvCarouselItems={quickApplyAdvCarouselItems} quickApplyAdvJobs={quickApplyAdvJobs} />
            : null}

          <Container fluid className="mainCarousel">
            <Container className="recomdJobCardPadding">
              <h3 className="jobmasterheading">
                {t("dashboard.recommendedJobs.recommendedJobsHeadingText")}
              </h3>
              <RecommendedJobsV2 viewMyJobs={false} isRecommendedJobsOnResult={false} recommended />
            </Container>
          </Container>
          <Container fluid className="myRecruiterMain">
            <Container>
              <MyRecruiter recruiterDetails={recruiterDetails} />
            </Container>
          </Container>
        </div>
      </>
    );
  };

  triggerRecruitics = () => {
    if (sessionStorage.getItem("isRecruiticsCalled") === null) {
      sessionStorage.setItem("isRecruiticsCalled", "true");
      setTimeout(() => {
        window.rx.trigger({
          triggerName: "apply",
          applyFlowId: window.rx.getViewerId(),
        });
      }, 10000);
    }
  };

  toggleJobsViewsLoader = (shouldLoad: boolean) => {
    const { quickApplyAdvJobs } = this.state
    if (quickApplyAdvJobs?.length > 0 && !shouldLoad) {
      this.setState({ isJobSearchWithViewsLoading: shouldLoad })
    }
    else if (!quickApplyAdvJobs || quickApplyAdvJobs.length === 0) {
      this.setState({ isJobSearchWithViewsLoading: shouldLoad })
    }
  }

  updateQuickApplyAdvJobs = (newJobs) => {

    if (newJobs) {
      this.setState({ quickApplyAdvJobs: newJobs })
    }

  }

  triggerSegmentEvent() {
    const { t } = this.props;
    const segmentEventName = t("segment.recommendedJobsLoaded")
    Analytics.segmentTrackEvent(segmentEventName, null);
  }

  updateCounter(counterName: string, counter: number) {
    this.setState({
      [counterName]: counter + 1,
    });
  }

  displayBanner() {
    const { onboardingWorkExp } = this.props;
    const newWorkExpWithNoEndDateArray = onboardingWorkExp?.filter((item) => {
      if (item) {
        const { workAutomationNotificationDate, isVerified } = item;
        if (!isVerified) {
          if (workAutomationNotificationDate) {
            const isSameOrBeforeDate =
              moment(workAutomationNotificationDate).isValid() &&
              moment(workAutomationNotificationDate).isSameOrBefore(moment().toDate());
            return isSameOrBeforeDate;
          }
          return true;
        }
      }
      return false;
    });

    const showBanner = newWorkExpWithNoEndDateArray?.length > 0;
    return showBanner;
  }


  render() {

    return <MainLayout component={this.getDashboard} />;
  }
}

const mapStateToProps = (state) => ({
  recommendedJobsCarouselItems: state.jobs.recommendedJobsCarouselItems,
  companyCode: state.company.company,
  candidateId: state.auth.candidateId,
  recruiterDetails: state.consultant.recruiterDetails,
  recruiterDetailsLoading: state.consultant.loading,
  recommendedJobsLoading: state.jobs.recommendedJobsLoading,
  origin: state.auth.origin,
  email: state.auth.email,
  userId: state.auth.userId,
  firstName: state.auth.firstName,
  lastName: state.auth.lastName,
  phone: state.auth.phone,
  onboardingSpecialtiesAndEducation: state.onBoarding.specialtiesAndEducation,
  onboardingWorkExp: state.onBoarding.workExperience,
  company: state.company.company,
  onboardingBasicInfo: state.onBoarding.basicInfo,
  isUserLoggedIn: state.auth.isUserLoggedIn,
  atsBullhornCandidateId: state.candidate.atsBullhornCandidateId,
  bhId: state.candidate.bullhornIdFromBullhorn,
  onboardingData: state.onBoarding.onboardingInformation,
  onboardingInfo: state.onBoarding,
  mspJobs: state.jobs.mspJobs,
  onBoardingCompletedSteps: state.onBoarding.completedSteps,
  isInitApiLoading: state.candidate.isInitApiLoading
});

const mapDispatchToProps = (dispatch) => ({
  fetchRecommendedJobs: (obj, nav) => dispatch(fetchRecommendedJobs(obj, nav)),
  fetchRecruiterDetails: (candidateId) => dispatch(fetchRecruiterDetails(candidateId)),
  getAllWorkExperiences: (email, onboardingData) => dispatch(getAllWorkExperiences(email, onboardingData)),
  fetchMspJobs: (query) => dispatch(fetchMspJobs(query)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withRouter(DashboardV2)));
