import { makeStyles, useTheme } from "@material-ui/core/styles";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  row: {
    display: "flex",
    alignItems: "center",
    marginTop: 7,
  },
  text: {
    margin: "0 4px",
    color: theme.palette.error.main,
    fontSize: 14,
    fontWeight: 600,
  },
}));

function TextInputError({ id, value }) {
  const classes = useStyles();

  if (!value) {
    return null;
  }

  return (
    <div className={classes.row} id={id}>
      <p className={classes.text}>{value}</p>
    </div>
  );
}

TextInputError.propTypes = {
  id: PropTypes.string,
  value: PropTypes.string,
};

export default TextInputError;
