import { makeStyles } from "@material-ui/core/styles";
import NoResultsBrowser from "src/imagesV2/NoResults.svg";
import NoResultsMob from "src/imagesV2/NoResults-mobile.svg";
import { quickApplyEligibilty } from "src/constants/filterConstants";
import { EDIT_MY_PROFILE } from "src/router/routes";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { PrimaryButton } from "../../Buttons";

const useStyles = makeStyles(() => {
  return {
    noResults: {
      backgroundColor: "#f5f6f9",
      marginBottom: "10px",
      display: "inline-flex",
      padding: "1% 1% 1% 3%",
      width: "94%",
      marginLeft: "1%",
      "@media(min-width: 0px) and (max-width: 768px)": {
        marginLeft: "3%",
        border: "1px solid #E0E0E2",
        padding: "0px 10px 0px 0px",
        borderRadius: "5px",
        marginTop: "1rem",
      },
    },
    noResultsCenter: {
      backgroundColor: "#f5f6f9",
      marginBottom: "10px",
      display: "flex",
      padding: "1% 18% 1% 18%",
      marginLeft: "0px",
      "@media(min-width: 0px) and (max-width: 768px)": {
        marginLeft: "3%",
        border: "1px solid #E0E0E2",
        padding: "0px 10px 0px 0px",
        borderRadius: "5px",
        marginTop: "1rem",
      },
    },
    noResultsImgWrapper: {
      "@media(min-width: 0px) and (max-width: 768px)": {
        padding: "12px 0px 2px 0px",
        justifyContent: "center",
      },
    },
    noResultsTxtWrapper: {
      padding: "1.4rem 0.4rem",
      "@media(min-width: 0px) and (max-width: 768px)": {
        padding: "5% 0%",
        marginLeft: "10px",
      },
    },
    noResultsImg: {
      backgroundImage: `url(${NoResultsBrowser})`,
      backgroundRepeat: "no-repeat",
      height: "93px",
      width: "210px",
      backgroundPosition: "center center",
      "@media(min-width: 0px) and (max-width: 768px)": {
        backgroundImage: `url(${NoResultsMob})`,
        height: "75px",
        width: "100px",
      },
    },
    noResultsTxt: {
      color: "#626C7D",
      fontSize: "1.2rem",
      fontWeight: "bold",
      "@media(min-width: 0px) and (max-width: 768px)": {
        fontSize: "0.75rem",
      },
    },
    noResultsSubTxt: {
      color: "#626C7D",
      fontSize: "0.9rem",
      "@media(min-width: 0px) and (max-width: 768px)": {
        fontSize: "0.65rem",
      },
    },
    editMyProfile:{
      // margin: "30px 0px 0px 110px",
      display:"flex",
      flex:1,
      alignItems:"center",
      "@media (max-width: 768px)": {
        margin: "0px 0px 25px 10px", 
        
        
      },
      "@media(min-width: 1024px)":{
        justifyContent:"center"
      }
    },
    noResultsContainer:{
      display: "flex",
      flex:1,
        flexDirection: "row",
      "@media(min-width: 0px) and (max-width: 1024px)": {
        flexDirection: "column",
      },
    }
  };
});

interface NoResultsPorps {
  isCenter: string;
  noResultsTxt: string;
  noResultsSubTxt: string;
  showEditProfileButton?:boolean
}

export default function NoResults(props: NoResultsPorps) {
  const history = useHistory();
  const { isCenter, noResultsTxt, noResultsSubTxt,showEditProfileButton} = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const handleClick = () => {
    history.push(EDIT_MY_PROFILE);
  };

  return (
    <div className={isCenter ? classes.noResultsCenter : classes.noResults}>
      <div className={classes.noResultsImgWrapper}>
        <div className={classes.noResultsImg} />
      </div>
      <div className={classes.noResultsContainer}>
      <div className={classes.noResultsTxtWrapper}>
        <div className={classes.noResultsTxt}>{noResultsTxt}</div>
        <div className={classes.noResultsSubTxt}>{noResultsSubTxt}</div>
      </div> 
      {quickApplyEligibilty[0].isActive && showEditProfileButton &&
      <div className={classes.editMyProfile}>
      <PrimaryButton
          component="button"
          size="medium"
          onClick={handleClick}
          startIcon=""
        >
          {t("noJobsMatch.editProfile")}
        </PrimaryButton> 
      </div>
      }
      </div>
    </div>
  );
}

NoResults.defaultProps={
  showEditProfileButton:false
}