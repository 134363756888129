import { FC } from "react";
import IframeResizer from "iframe-resizer-react";
import { useSelector } from "react-redux";

export interface RootState {
  candidate: {
    applicantStatus: string;
  };
  onBoarding: {
    portalComplete: boolean;
    useProfileIframe: boolean;
  };
}

export interface IFrameLoaderProps {
  src: string;
}

const IFrameLoader: FC<IFrameLoaderProps> = ({ src }) => {
  const applicantStatus = useSelector((state: RootState) => state.candidate.applicantStatus);
  const portalComplete = useSelector((state: RootState) => state.onBoarding.portalComplete);
  const useProfileIframe = useSelector((state: RootState) => state.onBoarding.useProfileIframe);

  const iframeID =
    portalComplete || applicantStatus === "COMPLETE"
      ? "profileIframePage"
      : "incompleteProfilePage";
  return (
    <>
      <IframeResizer
        className="iframePage"
        id={useProfileIframe ? "ableIframeId" : iframeID}
        scrolling
        src={src}
        heightCalculationMethod="documentElementScroll"
      />
    </>
  );
};

export default IFrameLoader;
