import { useState, KeyboardEvent, MouseEvent } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import { isMobile } from "react-device-detect";
import { TFunction, withTranslation } from "react-i18next";

const useStylesBootstrap = makeStyles((theme) => ({
  arrow: {
    color: "#f5f5f9",
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
  },
}));

interface BootstrapTooltipProps {
  title: string;
  children: JSX.Element;
  openToolTip: boolean;
  placement: "bottom" | "top" | "left" | "right";
}

function BootstrapTooltip({ title, children, openToolTip, placement }: BootstrapTooltipProps) {
  const classes = useStylesBootstrap();
  return (
    <Tooltip arrow placement={placement} classes={classes} title={title} open={openToolTip}>
      {children}
    </Tooltip>
  );
}

interface JobDisclaimerToolTipProps {
  isSalary: boolean;
  isJobDetail?: boolean;
  salaryHourly?: number;
  salaryWeekly?: number;
  defaultMessage?: boolean;
  isJobViews?: boolean;
  translate: TFunction<"translation", undefined>;
}

function JobDisclaimerToolTip({
  translate,
  defaultMessage,
  isSalary,
  isJobDetail,
  salaryHourly,
  salaryWeekly,
  isJobViews,
}: JobDisclaimerToolTipProps) {
  const [openDisclaimerToolTip, setOpenDisclaimerToolTip] = useState(false);
  const handleTooltip = <T extends KeyboardEvent | MouseEvent>(event: T): NodeJS.Timeout | null => {
    if (!isMobile) return null;
    event.stopPropagation();
    setOpenDisclaimerToolTip(true);
    return setTimeout(() => {
      setOpenDisclaimerToolTip(false);
    }, 2000);
  };
  const disclaimerTitle1 = translate("jobs.jobsCard.disclaimerTitle1");
  const disclaimerTitle2 = translate("jobs.jobsCard.disclaimerTitle2");
  const disclaimerTitle3 = translate("jobs.jobsCard.disclaimerTitle3");

  const translatedDefaultMessage = translate("jobs.jobsCard.defaultMessage");

  const titleDesc = defaultMessage
    ? translatedDefaultMessage
    : `${disclaimerTitle1}${salaryHourly?.toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}${disclaimerTitle2}${salaryWeekly?.toLocaleString("en-US", {
        maximumFractionDigits: 0,
      })}${disclaimerTitle3}`;

  const toolTipType = (): string => {
    let toolTip: string;

    if (isSalary && isJobDetail) {
      toolTip = "jobDetailInfoToolTip";
    } else if (isSalary) {
      toolTip = "jobCardInfoToolTip";
    } else {
      toolTip = "jobCardInfoWithoutPayrateToolTip";
    }
    return toolTip;
  };

  return (
    <span
      className={toolTipType()}
      role="button"
      tabIndex={0}
      aria-label="tool tip button"
      onKeyPress={(event) => handleTooltip(event)}
      onClick={(event) => handleTooltip(event)}
      onMouseOver={() => !isMobile && setOpenDisclaimerToolTip(true)}
      onFocus={() => !isMobile && setOpenDisclaimerToolTip(true)}
      onMouseOut={() => !isMobile && setOpenDisclaimerToolTip(false)}
      onBlur={() => !isMobile && setOpenDisclaimerToolTip(false)}
    >
      <BootstrapTooltip title={titleDesc} placement="top" openToolTip={openDisclaimerToolTip}>
        <span className="material-icons">
          <svg
            className={isJobViews ? "jobViewsPayRate":"payRateSvg" }
            xmlns="http://www.w3.org/2000/svg"
            height="24px"
            viewBox="0 0 24 24"
            width="24px"
            fill="#000000"
          >
            <path d="M0 0h24v24H0z" fill="none" />
            <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z" />
          </svg>
        </span>
      </BootstrapTooltip>
    </span>
  );
}

JobDisclaimerToolTip.defaultProps = {
  defaultMessage: false,
  isJobDetail: false,
  salaryHourly: 0,
  salaryWeekly: 0,
  isJobViews:false
};

export default withTranslation()(JobDisclaimerToolTip);
