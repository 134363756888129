import { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Fade, IconButton, Modal } from "@mui/material";
import { useTranslation } from "react-i18next";
import styles from "src/components/common/QuickApply/Acknowledgement/AcknowledgementOverlay.module.scss";
import Chip from "@mui/material/Chip";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { isMobile } from "react-device-detect";
import Checkbox from "@mui/material/Checkbox";
import dayjs, { type Dayjs }  from "dayjs";
import utc from "dayjs/plugin/utc";
import { makeStyles } from "@material-ui/core/styles";
import MultiDatePicker from "src/components/common/Date/MultiDatePicker";
import clsx from "clsx";
import AcknowledgementCloseModal from "src/components/common/QuickApply/Acknowledgement/AcknowledgementCloseModal"

const useStyles = makeStyles({
  root: {
    width: "100%",
    "& .MuiOutlinedInput-root": {
      padding: "15.5px 12.6px 17.5px 13.1px",
      "@media (max-width: 1280px)": {
        padding: "12.5px 9.6px 12.5px 9.1px",
      },
      "@media (max-width: 1440px)": {
        padding: "13.5px 9.6px 13.5px 9.1px",
      }
    },
    "&.MuiInputBase-input": {
      color: styles?.colorPrimary,
      fontWeight: "700",
      cursor: "pointer",
      paddingTop: "6px",
    },
    "& .MuiOutlinedInput-input": {
      padding: "5px 9px !important",
      fontSize: "16px",
      fontWeight: 600,
      color: styles?.colorPrimary,
      "@media (max-width: 768px)": {
        color: (preferredStartDate) => (!preferredStartDate ? styles?.colorPrimary : "#babec6"),
      },
    },
    "&.MuiFormControl-marginNormal": {
      marginBottom: "0px",
    },
    "& .Mui-error": {
      color: "#ce0036 !important",

      "&.MuiFormHelperText-root.Mui-error": {
        color: "#ce0036",
        fontSize: "12px",
        marginTop: "1px",
        fontFamily: "Nunito Sans, Helvetica, Arial, sans-serif",
      },
    },
    "& .MuiInputLabel-root": {
      textAlign: "left",
      color: styles?.colorPrimary,
      "@media (max-width: 768px)": {
        margin: "0 9px 1px 0",
        fontWeight: 500,
        lineHeight: "normal",
        letterSpacing: "normal",
      },
    },
    "& .MuiInputLabel-outlined": {
      transform: "translate(14px, -6px) scale(0.75)",
    },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      border: "solid 1px #d6d8dc",
    },
    "&:hover .MuiOutlinedInput-input": {
      color: styles?.colorPrimary,
      fontSize: "16px",
      "@media (max-width: 768px)": {
        color: (preferredStartDate) => (!preferredStartDate ? styles?.colorPrimary : "#babec6"),
        fontSize: (preferredStartDate) => (!preferredStartDate ? "16px" : "12px"),
      }
    },
    "&:hover .MuiInputLabel-root": {
      color: styles?.colorPrimary,
      fontWeight: 500,
      "@media (max-width: 768px)": {
        color: "#000",
      },
    },
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      border: "1px solid $color-primary",
      borderColor: styles?.colorPrimary,
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-input": {
      color: styles?.colorPrimary,
      "@media (max-width: 768px)": {
        color: (preferredStartDate) => (!preferredStartDate ? styles?.colorPrimary : "#babec6"),
      }
    },
    "& .MuiInputLabel-root.Mui-focused": {
      color: styles?.colorPrimary,
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: styles?.colorPrimary,
    },

    "& fieldset[class*=MuiOutlinedInput-notchedOutline]": {
      "@media (max-width: 768px)": {
        "& legend": {
          fontSize: "12px !important",
          visibility: "hidden !important"
        },
      }
    }
  }
});
dayjs.extend(utc);
interface IAcknowledgementOverlay {
  handleClose: Function;
  openModal: boolean;
  quickApplyDetails: any;
  handleSubmit: Function;
  handleAgreeAndAccept: Function;
  agreeAndAccept: boolean;
}
const AcknowledgementOverlay = ({
  handleClose,
  openModal,
  quickApplyDetails,
  handleSubmit,
  handleAgreeAndAccept,
  agreeAndAccept,
}: IAcknowledgementOverlay) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState<boolean>(openModal);
  const [preferredStartDate, setPreferredStartDate] = useState<Date | null>(null);
  const [reqTimeOffOptions] = useState<string[]>(["Yes", "No"]);
  const [reqTimeOff, setReqTimeOff] = useState<string>("No");
  const [reqTimeOffSelectedDates, setReqTimeOffSelectedDates] = useState<Date[]>([]);
  const [acknowledgementOpen, setAcknowledgementOpen] = useState<boolean>(false);
  const [agreement] = useState<string>("false");
  const [selectDate] = useState<string>(isMobile ? t("acknowledgements.selectDate") : "");
  const [checked, setChecked] = useState<boolean>(agreeAndAccept);
  const [rtoError, setRtoError] = useState<boolean>(false)
  const [prePopulatedStartDate, setPrePopulatedStartDate] = useState<Date | null>(null);
  const [tentativeEndDate, setTentativeEndDate] = useState<string | null>(null);
  const [acknowledgement, setOpenAcknowledgement] = useState<boolean>(false)
  const [jobStartDate] = useState<Date>(quickApplyDetails?.job?.startDate)
  const [lengthOfContract] = useState<number>(quickApplyDetails?.job?.duration)
  const [isConfirm, setIsConfirm] = useState<boolean>(true);
  const [userMadeChanges, setUserMadeChanges] = useState<boolean>(false);

  const classes = useStyles(!preferredStartDate);

  const getTentativeEndDate = (startDate: Dayjs) => {
    const selectedPreferredStartDate = dayjs.utc(startDate);
    const tentativeEndDateValue = selectedPreferredStartDate
      .clone()
      .add(lengthOfContract, "weeks")
      .format("MM/DD/YYYY")
    setTentativeEndDate(tentativeEndDateValue);
    return tentativeEndDateValue;
  };

  const getPrePopulatePreferredStartDate = () => {
    const contractStartDate = dayjs.utc(jobStartDate);
    const todayPlusTwoWeeks = dayjs().add(2, "week").utc();
    let calculatedPreferredStartDate;
    if (dayjs.utc(contractStartDate)?.isAfter(todayPlusTwoWeeks)) {
      calculatedPreferredStartDate = contractStartDate;
    } else {
      calculatedPreferredStartDate = todayPlusTwoWeeks;
    }
    setPrePopulatedStartDate(calculatedPreferredStartDate);
    setPreferredStartDate(calculatedPreferredStartDate.toDate());
    getTentativeEndDate(calculatedPreferredStartDate);
  };

  useEffect(() => {
    setOpen(true);
    getPrePopulatePreferredStartDate();
  }, [openModal]);

  const handleCloseModal = (_event, reason) => {
    if ((reason && reason === "backdropClick") || reason === "escapeKeyDown") return;
    if (!(reason === "backdropClick")) {
      setOpen(false);
      handleClose(false);
    }
    if (userMadeChanges) setIsConfirm(!isConfirm)
  };

  const handleCloseOverlay = () => {
    if (userMadeChanges) setIsConfirm(!isConfirm)
    else {
      setOpen(false);
      handleClose(false);
      handleAgreeAndAccept(false);
    }
  };

  const handleDateChange = (arrayValue) => {
    const value = arrayValue[0];

    setPreferredStartDate(value);
    const tentativeEndDateValue = getTentativeEndDate(value);
    if (reqTimeOff === "Yes") {
      const filteredTimeOffSelectedDates = reqTimeOffSelectedDates.filter((date) => {
        const dayJsDate = dayjs.utc(date);
        return (
          (dayJsDate.isAfter(value)) && (dayJsDate.isSame(tentativeEndDateValue) || dayJsDate.isBefore(tentativeEndDateValue))
        );
      });
      setReqTimeOffSelectedDates(filteredTimeOffSelectedDates);
    }
    if (value) setUserMadeChanges(true);
  };

  const handleOnReqTimeOffChipClick = (value: string) => {
    setReqTimeOff(value);
    if (value) setUserMadeChanges(true);
    if (value === "No") {
      setRtoError(false)
      setReqTimeOffSelectedDates([]);
    }
  }

  const handleOnReqTimeOffDateSelect = (selections: Date[]) => {
    setReqTimeOffSelectedDates(selections);
  }


  const handleAgreeAndAcceptClick = () => {
    setAcknowledgementOpen(false);
    handleClose(true);
    setOpen(true);
  };

  const handleChangeCheck = () => {
    handleAgreeAndAccept(!checked);
    setChecked(!checked);
    setUserMadeChanges(true);
  };

  const rtoErrorHandling = () => {
    // Check if RTO is requested but no dates are selected
    if (reqTimeOff === "Yes" && !(reqTimeOffSelectedDates.length > 0)) {
      setRtoError(true)
      return true
    }
    return false
  }

  const submitHandleChange = () => {
    // Proceed if there is no RTO error
    if (!rtoErrorHandling()) {
      setOpen(false);
      handleClose(false);
      handleSubmit(
        quickApplyDetails,
        dayjs.utc(preferredStartDate).format("MM/DD/YYYY"),
        tentativeEndDate,
        reqTimeOffSelectedDates.map((date) => dayjs.utc(date).format("MM/DD/YYYY"))
      );
    }
  };

  const handleOpenAcknowledgement = () => {
    setAcknowledgementOpen(true);
  };

  const openAcknowledgementModal = () => {
    setOpenAcknowledgement(true)
  }
  const closeAcknowledgementModal = () => {
    setOpenAcknowledgement(false)

  }

  const acknowledgementDesktopContainer = () => {
    return (
      <>
        <Modal open={acknowledgement} onClose={handleCloseModal} closeAfterTransition>
          <Fade in={acknowledgement}>
            <div className={styles.ackFrame}>
              <div className={styles.text}>
                <div className={styles.acknowledgement}>
                  {t("acknowledgements.acknowledgement")}
                </div>
                <div className={styles.ackText}>
                  <p className={styles.ackTextFirst}>
                    {t("acknowledgements.acknowledgementText1")}
                    <a href="http://nlrb.gov/poster" target="_blank" rel="noreferrer">
                      {t("acknowledgements.acknowledgementTextLink")}
                    </a>
                    {t("acknowledgements.acknowledgementText5")}
                  </p>
                  <p>{t("acknowledgements.acknowledgementText2")}</p>
                  <p>{t("acknowledgements.acknowledgementText3")}</p>
                  <p>{t("acknowledgements.acknowledgementText4")}</p>
                </div>
                <button
                  type="button"
                  onClick={closeAcknowledgementModal}
                  className={styles.closeButton}
                >
                  {t("acknowledgements.close")}
                </button>
              </div>
            </div>
          </Fade>
        </Modal>
      </>
    );
  };

  const acknowledgementMobileContainer = () => {
    return (
      <>
        {acknowledgementOpen && (
          <Modal open={open || acknowledgementOpen} onClose={handleCloseModal} closeAfterTransition>
            <Fade in={open || acknowledgementOpen}>
              <div className={styles.ackModal}>
                <div className={styles.ackUpper}>
                  <div className={styles.ackHeading}>{t("acknowledgements.acknowledgement")}</div>
                </div>
                <div className={styles.ackTextMain}>
                  <p className={styles.ackTextFirst}>
                    {t("acknowledgements.acknowledgementText1")}
                    <a href="http://nlrb.gov/poster" target="_blank" rel="noreferrer">
                      {t("acknowledgements.acknowledgementTextLink")}
                    </a>
                    {t("acknowledgements.acknowledgementText5")}
                  </p>
                  <p className={styles.ackTextFirst}>{t("acknowledgements.acknowledgementText2")}</p>
                  <p className={styles.ackTextFirst}>{t("acknowledgements.acknowledgementText3")}</p>
                  <p className={styles.ackTextFirst}>{t("acknowledgements.acknowledgementText4")}</p>
                </div>
                <button
                  type="button"
                  onClick={handleAgreeAndAcceptClick}
                  className={styles.viewAcknowledgement}
                >
                  {t("acknowledgements.close")}
                </button>
              </div>
            </Fade>
          </Modal>
        )}
      </>
    );
  };

  return (
    <Modal open={open} onClose={handleCloseModal} closeAfterTransition>
      <Fade in={open}>
        <div>
          <div
            hidden={!isConfirm}
            className={clsx(
              !isMobile && styles.modal,
              isMobile && styles.modalMobile,
              isMobile && reqTimeOff === "No" && styles.modalMobileWithoutRtoDates
            )}
          >
            {!isMobile ? (
              <div>
                <IconButton
                  className={styles.close}
                  aria-label="close"
                  onClick={handleCloseOverlay}
                >
                  <CloseIcon className="changPswCloseBtn" />
                </IconButton>
                <div className={styles.heading}>{t("acknowledgements.heading")}</div>
              </div>
            ) : (
              <div className={styles.upperSection}>
                <div>
                  <IconButton
                    className={styles.closeMobile}
                    aria-label="close"
                    onClick={handleCloseOverlay}
                  >
                    <CloseIcon />
                  </IconButton>
                </div>
                <div className={styles.heading}>{t("acknowledgements.heading")}</div>
              </div>
            )}
            <div className={styles.submissionModal}>
              <Container
                className={clsx(
                  styles.preferredContainer,
                  isMobile && reqTimeOff === "Yes" && styles.preferredContainerWithoutRtoDates
                )}
              >
                <Row>
                  <Col
                    sm={12}
                    xs={12}
                    md={6}
                    lg={6}
                    className={`${styles.divider} ${styles.pdate}`}
                    style={{ marginTop: "10px" }}
                  >
                    <div className={styles.preferredDatePick}>
                      <MultiDatePicker
                        label={t("acknowledgements.preferencesForm")}
                        emptyLabel={selectDate}
                        className={classes.root}
                        selectedDates={preferredStartDate ? [preferredStartDate] : []}
                        onSelectedDatesChange={handleDateChange}
                        maxDate={dayjs.utc(prePopulatedStartDate).add(30, "days")}
                        minDate={dayjs.utc(prePopulatedStartDate)}
                        isMobile={isMobile}
                        isSingleDate
                      />
                      <span className={styles.datePickerText}>
                        {t("acknowledgements.datePickerText")}
                      </span>
                    </div>
                    <>
                      <div className={styles.tentativeEndDate}>
                        {t("acknowledgements.tentativeEndDateText")}
                      </div>
                      <div className={styles.dateText}>{tentativeEndDate}</div>
                    </>
                  </Col>
                  <Col sm={12} xs={12} md={6} lg={6} className={` ${styles.rtoDivider}`}>
                    <div className={styles.needToRequestText}>
                      {t("acknowledgements.needToReqText")}
                    </div>
                    {reqTimeOffOptions.map((option) => (
                      <Chip
                        key={option}
                        label={option}
                        size="medium"
                        onClick={() => handleOnReqTimeOffChipClick(option)}
                        sx={
                          option === reqTimeOff
                            ? {
                              "& .MuiChip-label": {
                                color: "white !important",
                              },
                            }
                            : {
                              "& .MuiChip-label": {
                                color: "$color-primary !important",
                              },
                            }
                        }
                        className={
                          option === reqTimeOff
                            ? styles.chipCardSelected
                            : styles.chipCardUnselected
                        }
                      />
                    ))}

                    {reqTimeOff === "Yes" && (
                      <div className={styles.requestTimeOffContainer}>
                        <MultiDatePicker
                          label={t("acknowledgements.rtoDates")}
                          className={classes.root}
                          selectedDates={reqTimeOffSelectedDates}
                          onSelectedDatesChange={handleOnReqTimeOffDateSelect}
                          minDate={dayjs.utc(preferredStartDate ?? new Date()).add(1, "day")}
                          maxDate={tentativeEndDate ?  dayjs.utc(preferredStartDate).clone().add(lengthOfContract, "weeks"): undefined}
                          isMobile={isMobile}
                          error={rtoError}
                          setRtoErrorFlag={(error) => setRtoError(error)}
                        />

                        <div className={styles.datePickerText}>
                          {t("acknowledgements.reqTimeOffDatePickerText")}
                        </div>
                      </div>
                    )}
                  </Col>
                </Row>
              </Container>
              <Row sm={12} xs={12} md={12} lg={12} className={styles.textBody}>
                <Checkbox
                  className={styles.checkBox}
                  checked={agreeAndAccept}
                  onChange={handleChangeCheck}
                  sx={{
                    "&.Mui-checked": {
                      color: "#d7282f",
                    },
                  }}
                />
                <p className={styles.acceptAndAgreeText}>
                  {t("acknowledgements.agreeAndAccept")}
                  <span
                    role="button"
                    className={styles.quickApplyAcknowledgment}
                    tabIndex={0}
                    onClick={!isMobile ? openAcknowledgementModal : handleOpenAcknowledgement}
                    onKeyDown={(event) => {
                      if (event.key === "Enter") {
                        openAcknowledgementModal();
                      }
                    }}
                  >
                    {t("acknowledgements.quickApplyAcknowledgement")}
                  </span>
                  .
                </p>
              </Row>
              {isMobile && (
                <>
                  <button
                    type="button"
                    onClick={submitHandleChange}
                    disabled={!(agreeAndAccept && preferredStartDate)}
                    className={
                      agreeAndAccept && preferredStartDate
                        ? styles.submitActive
                        : styles.submitButtonM
                    }
                  >
                    {t("acknowledgements.submit")}
                  </button>
                </>
              )}

              {!isMobile && (
                <button
                  type="button"
                  onClick={submitHandleChange}
                  disabled={!(agreeAndAccept && preferredStartDate)}
                  className={styles.submitButton}
                  value={agreement}
                >
                  <div className={styles.submitText}>{t("acknowledgements.submit")}</div>
                </button>
              )}
              {!isMobile && acknowledgementDesktopContainer()}
              {isMobile && acknowledgementMobileContainer()}
            </div>
          </div>
          <AcknowledgementCloseModal
            isConfirm={isConfirm}
            setIsConfirm={setIsConfirm}
            setUserMadeChanges={setUserMadeChanges}
            setOpen={setOpen}
            handleClose={handleClose}
            handleAgreeAndAccept={handleAgreeAndAccept}
          />
        </div>
      </Fade>
    </Modal>
  );
};

export default AcknowledgementOverlay;