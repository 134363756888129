import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { passwordStrength } from "check-password-strength";
import { useTranslation } from "react-i18next";

interface PasswordRequirementsProps {
  password: string;
  changePassword: Boolean;
}

function PasswordRequirements({ password, changePassword }: PasswordRequirementsProps) {
  
  const { t } = useTranslation();
  const testedResult = passwordStrength(password);

  return (
    <div className={changePassword ? "changePassword" : "passwordErrors"}>
      <div className="pt-2 pb-2">
        <b>{t("createPassword.requirement")}</b>
      </div>
      <div>
        {testedResult.length >= 8 ? (
          <CheckIcon className="noErrorIcon" />
        ) : (
          <span><CloseIcon className="errorIcon" /></span>
        )}
        <span className="errorText">{t("createPassword.checkChar")}</span>
      </div>
      <div>
        {testedResult?.contains?.includes("lowercase") ||
        testedResult?.contains?.includes("uppercase") ? (
          <CheckIcon className="noErrorIcon" />
        ) : (
          <span><CloseIcon className="errorIcon" /></span>
        )}
        <span className="errorText">{t("createPassword.checkLetter")}</span>
      </div>
      <div>
        {testedResult?.contains?.includes("number") ? (
          <CheckIcon className="noErrorIcon" />
        ) : (
          <span><CloseIcon className="errorIcon" /></span>
        )}
        <span className="errorText">{t("createPassword.checkNumber")}</span>
      </div>
      <div className="flexDisplayMobile">
        {/[!@#^&*_+\-~$]/.test(password) ? (
          <CheckIcon className="noErrorIcon" />
        ) : (
          <span><CloseIcon className="errorIcon" /></span>
        )}
        <span className="errorText errorTextMobileMobile">{t("createPassword.checkSymbol")}</span>
      </div>
    </div>
  );
}

export default PasswordRequirements;
