import Button from "@material-ui/core/Button";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import styles from "src/components/common/OnBoarding/SelfIdentification/SelfIdentification.module.scss";
import { TFunction } from "react-i18next";
import { isMobile } from "react-device-detect";

interface EthnicityAndGenderLearnMoreOverlayProps {
  t: TFunction;
  openPopup: boolean;
  setOpenPopup: (open: boolean) => void;
}

const EthnicityAndGenderLearnMoreOverlay = ({
  t,
  openPopup,
  setOpenPopup,
}: EthnicityAndGenderLearnMoreOverlayProps) => {
  const handleClose = () => {
    setOpenPopup(false);
  };
  return (
    <Modal
      className={styles.modal}
      open={openPopup}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      disableBackdropClick
    >
      <Fade in={openPopup}>
        <div className={styles.modalLayout}>
          <div className={styles.modalBox}>
            <div className={styles.modalHeadings}>
              {t("onBoarding.basicInfo.equalOpportunityEmpHeading")}
            </div>
            <div className={styles.modalContent}>
              {t("onBoarding.basicInfo.equalOpportunityEmpContent")}
            </div>
            <div className={styles.modalHeadings}>
              {t("onBoarding.basicInfo.equalOpportunityPolicyHeading")}
            </div>
            <div className={styles.modalContent}>
              {t("onBoarding.basicInfo.equalOpportunityPolicyContent")}
            </div>
            <div className={isMobile ? styles.modalHeadingsSingleLine : styles.modalHeadings}>
              {t("onBoarding.basicInfo.benefitHeading")}
            </div>
            <div className={styles.modalContent}>{t("onBoarding.basicInfo.benefitContent")}</div>
            <div className={isMobile ? styles.modalHeadingsSingleLine : styles.modalHeadings}>
              {t("onBoarding.basicInfo.learnMoreHeading")}
            </div>
            <div className={styles.modalContent}>{t("onBoarding.basicInfo.learnMoreContent")}</div>
            <div>
              <Button variant="contained" className={styles.close} onClick={() => handleClose()}>
                <div className={styles.closeText}>{t("onBoarding.basicInfo.closeText")}</div>
              </Button>
            </div>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

export default EthnicityAndGenderLearnMoreOverlay;
