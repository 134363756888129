import {
  DETECT_JOB_CARD_CLICK,
  FETCH_SIMILAR_JOBS_REQUEST,
  FETCH_SIMILAR_JOBS_SUCCESS,
  FETCH_SIMILAR_JOBS_FAILURE,
  FETCH_COLLABORATED_JOBS_REQUEST,
  FETCH_COLLABORATED_JOBS_SUCCESS,
  FETCH_COLLABORATED_JOBS_FAILURE,
  UPDATE_SIMILAR_JOBS_CAROUSEL,
  UPDATE_COLLABORATED_JOBS_CAROUSEL,
  UPDATE_SIMILAR_JOB_OBJECT,
  UPDATE_COLLABORATED_JOB_OBJECT,
  UPDATE_JOB_DETAILS,
  SET_JOB_QUICK_APPLY_REQUIREMENTS,
  SET_ELIGIBILITY_PERCENTAGE
} from "./action";

const initialState = {
  similarJobs: null,
  similarJobsError: null,
  similarJobsLoading: false,
  collaboratedJobs: null,
  collaboratedJobsError: null,
  collaboratedJobsLoading: false,
  similarJobsCarouselItems: [],
  collaboratedJobsCarouselItems: [],
  jobDetails: [],
  jobQuickApplyRequirements: null,
  error: "",
  loading: false,
  viewJobDetails: false,
  isRecommendedJobsOnResult: false,
  eligibilityPercentage: null,
};
const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case DETECT_JOB_CARD_CLICK:
      return {
        ...state,
        viewJobDetails: action.payload.isJobClicked,
        jobDetails: action.payload.job,
        isRecommendedJobsOnResult: action.payload.isRecommendedJobsOnResult,
        campaignCode: action.payload.campaignCode,
      };
    case FETCH_SIMILAR_JOBS_REQUEST:
      return { ...state, similarJobsLoading: true };

    case FETCH_SIMILAR_JOBS_SUCCESS:
      return {
        ...state,
        similarJobsLoading: false,
        similarJobs: action.payload,
        similarJobsError: null,
      };

    case FETCH_SIMILAR_JOBS_FAILURE:
      return {
        ...state,
        similarJobsLoading: false,
        similarJobsError: action.payload,
        similarJobsCarouselItems: [],
        similarJobs: [],
      };

    case FETCH_COLLABORATED_JOBS_REQUEST:
      return { ...state, collaboratedJobsLoading: true };

    case FETCH_COLLABORATED_JOBS_SUCCESS:
      return {
        ...state,
        collaboratedJobsLoading: false,
        collaboratedJobs: action.payload,
        collaboratedJobsError: null,
      };

    case FETCH_COLLABORATED_JOBS_FAILURE:
      return {
        ...state,
        collaboratedJobsLoading: false,
        collaboratedJobsError: action.payload,
        collaboratedJobs: null,
      };

    case UPDATE_SIMILAR_JOBS_CAROUSEL:
      return { ...state, similarJobsCarouselItems: action.payload };
    case UPDATE_COLLABORATED_JOBS_CAROUSEL:
      return { ...state, collaboratedJobsCarouselItems: action.payload };
    case UPDATE_SIMILAR_JOB_OBJECT: {
      const similar = { ...action.payload };
      return { ...state, similarJobsCarouselItems: similar.similarJobs };
    }
    case UPDATE_COLLABORATED_JOB_OBJECT: {
      const collaborated = { ...action.payload };
      return { ...state, collaboratedJobsCarouselItems: collaborated.collaboratedJobs };
    }
    case UPDATE_JOB_DETAILS: {
      const jobDetails = { ...action.payloadUpdated.jobDetails };
      return { ...state, jobDetails };
    }
    case SET_JOB_QUICK_APPLY_REQUIREMENTS: {
      const jobQuickApplyRequirements = { ...action.payload };
      return { ...state, jobQuickApplyRequirements };
    }
    case SET_ELIGIBILITY_PERCENTAGE: {
      return {
        ...state,
        eligibilityPercentage: action.payload,
      };
    }
    default:
      return state;
  }
};

export default rootReducer;
