import React from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import MainLayout from "./MainLayout";
import { userAuthentication } from "../../redux/userAction/login/action";

const styles = (theme) => ({});

class LoginPage extends React.Component {
  static propTypes = {};

  static defaultProps = {};

  state = {};

  handleClick() {
    this.props.login("call home");
  }

  render() {
    const me = this;
    return (
      <MainLayout
        component={
          <div>
            <button onClick={me.handleClick.bind(me)}>{this.props.t("login.loginLabel")}</button>
          </div>
        }
      />
    );
  }
}

const mapStateToProps = (state) => ({
  status: state.authentication.authenticated,
});

const mapDispatchToProps = (dispatch) => ({
  login: (req) => dispatch(userAuthentication(req)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(withTranslation()(LoginPage)));
