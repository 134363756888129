import API from "../../services/Api";
import Analytics from "../../services/Analytics";

export const FETCH_RECRUITER_DETAILS_REQUEST = "FETCH_RECRUITER_DETAILS_REQUEST";
export const fetchRecruiterDetailsRequest = (payload) => ({
  type: FETCH_RECRUITER_DETAILS_REQUEST,
  payload,
});

export const FETCH_RECRUITER_DETAILS_SUCCESS = "FETCH_RECRUITER_DETAILS_SUCCESS";
export const fetchRecruiterDetailsSuccess = (payload) => ({
  type: FETCH_RECRUITER_DETAILS_SUCCESS,
  payload,
});

export const FETCH_RECRUITER_DETAILS_FAILURE = "FETCH_RECRUITER_DETAILS_FAILURE";
export const fetchRecruiterDetailsFailure = (payload) => ({
  type: FETCH_RECRUITER_DETAILS_FAILURE,
  payload,
});

export const fetchRecruiterDetails = (candidateId) => (dispatch) => {
  dispatch(fetchRecruiterDetailsRequest(candidateId));
  API.get(`candidates/${candidateId}/allocations`)
    .then((response) => {
      dispatch(fetchRecruiterDetailsSuccess(response.data.content));
      const gaVariable = {
        dimension3: response.data.content ? response.data.content.consultantId : null,
      };
      Analytics.setGlobalVariables(gaVariable);
    })
    .catch((error) => {
      dispatch(fetchRecruiterDetailsFailure(error));
    });
};
