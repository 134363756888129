import { useTranslation, withTranslation } from "react-i18next";
import { payStubDetailsTypes } from "src/components/common/MainContent/Payroll/Paystubs/Tables";
import { formatCurrency } from "src/utils/formatCurrency";
import styles from "./TaxTable.module.scss";


interface TaxTableProps {
  payStubDetails: payStubDetailsTypes;
}

const TaxTable = ({ payStubDetails }: TaxTableProps) => {
  const { t } = useTranslation();

  return (
    <>
      <div className={styles.paystubsTable}>
        <h3 className={styles.tableHeading}>
          {t("payStubs.taxes")} &nbsp;&nbsp; {formatCurrency(payStubDetails?.totalTaxAmount)}
        </h3>
        <table>
          <thead>
            <tr>
              <th>{t("payStubs.item")}</th>
              <th>{t("payStubs.amount")}</th>
            </tr>
          </thead>
          <tbody>
            {payStubDetails?.taxes?.map((tax) => (
              <tr key={tax.amount + tax.tax}>
                <td>{tax.tax}</td>
                <td>{formatCurrency(tax.amount)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};
export default withTranslation()(TaxTable);
