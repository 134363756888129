import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Popper from "@mui/material/Popper";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { Radio } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useMutation } from "@apollo/client";
import { makeUseAxios } from "axios-hooks";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { ReferencesStates } from "../../../../constants/constants";
import { SET_REFERENCES } from "../../../../services/GraphQL/graph.query";
import validationsService from "../../../../services/Validations";
import onboardingFields from "../../../../constants/onboardingFieldNames.json";
import API from "../../../../services/Api";
import config from "../../../../config";
import Analytics from "../../../../services/Analytics";
import { REFERENCES_SURVEY_THANK_YOU } from "../../../../router/routes";
import surveyConstraint from "../../../../services/ReferencesSurveyConstraint";

const useStyles = makeStyles((theme) => ({
  referSurveyFormWrap: {
    boxShadow: "0 3px 10px #00000017",
    border: "1px solid #E0E0E0",
    borderRadius: "14px",
    margin: "51px 0 100px",
    padding: "39px 35px 50px 35px",
    "@media (max-width: 768px)": {
      boxShadow: "none",
      border: "0",
      marginTop: "48px",
      padding: "0 4px",
    },
  },
  helloUsername: {
    fontSize: "1.1rem",
    color: theme.palette.colorPrimary,
    margin: "0",
    fontWeight: "bold",
    paddingBottom: "18px",
    fontFamily: "Nunito Sans",
    textTransform: "capitalize",
  },
  sectionContent: {
    fontSize: "0.875rem",
    color: "#626C7D",
    margin: "0",
    lineHeight: "24px",
  },
  highlightedContent: {
    fontSize: "1.1rem",
    color: theme.palette.colorPrimary,
    display: "block",
    margin: "30px 0",
  },
  referTermsAndCondition: {
    color: "#626C7D",
    textDecoration: "underline",
    "&:hover": {
      color: theme.palette.colorSecondary,
      "@media (max-width: 768px)": {
        color: theme.palette.colorPrimary,
      },
    },
  },
  referSectionTitle: {
    fontSize: "1.25rem",
    color: theme.palette.colorPrimary,
    paddingBottom: "32px",
    fontWeight: "bold",
    fontFamily: "Nunito Sans",
    margin: "0",
    "@media (max-width: 768px)": {
      paddingBottom: "37px",
    },
  },
  referContactInfo: {
    "& #referContactInfoInnerWrapper": {
      "& label": {
        color: "#00000099",
        backgroundColor: "#fff",
        padding: "0 5px 0 3px",
      },
      "& label.Mui-focused": {
        color: theme.palette.colorPrimary,
      },
      "& label.Mui-focused.Mui-error": {
        color: theme.palette.colorError,
      },
      "& form input": {
        padding: "18px 17px !important",
        "&:focus": {
          border: `2px solid${theme.palette.colorPrimary}`,
          borderRadius: "4px",
        },
      },
      "& fieldset": {
        border: "2px solid #00000033",
      },
      "& div[class*=Mui-error]": {
        "& fieldset[class*=MuiOutlinedInput-notchedOutline]": {
          borderColor: theme.palette.colorError,
          borderWidth: "1.8px",
          padding: "0 8px 0 13px",
          top: "-4.6px",
          "& legend span": {
            padding: "0",
          },
        },
      },
    },
  },
  referenceInnerInfo: {
    "@media (max-width: 768px)": {
      paddingLeft: "20px",
    },
  },
  formControlWrapper: {
    "& .MuiFormControl-root": {
      width: "100%",
      padding: "0 30px 35px 0",
      "@media (max-width: 768px)": {
        width: "100%",
        padding: "0 0 35px",
      },
    },
    "& input, .MuiSelect-select": {
      padding: "18px 17px !important",
      color: `${theme.palette.colorPrimary} !important`,
      fontFamily: "inherit",
      fontWeight: "500",
      textTransform: "capitalize",
      borderRadius: "4px",
      "&:focus": {
        border: `1.8px solid ${theme.palette.colorPrimary}`,
        borderRadius: "4px",
        padding: "16px !important",
      },
      "&:-webkit-autofill": {
        WebkitBoxShadow: " 0 0 0 30px #ffffff inset !important",
        WebkitTextFillColor: `${theme.palette.colorPrimary} !important`,
      },
    },
    "& .MuiSelect-select.MuiSelect-outlined": {
      "&:focus": {
        color: `${theme.palette.colorPrimary} !important`,
        padding: "16px !important",
        fontWeight: "500 !important",
        "@media (max-width: 768px)": {
          padding: "18px 17px !important",
        },
      },
    },
    "& #email-address": {
      textTransform: "none",
    },
    "& #selectDropdown": {
      "& div[aria-expanded=true] fieldset": {
        border: `2px solid ${theme.palette.colorPrimary}`,
        zIndex: 9,
      },
      "& .Mui-focused.MuiInputBase-formControl fieldset": {
        border: `2px solid ${theme.palette.colorPrimary}`,
        zIndex: 9,
      },
    },
  },
  dropDownErrorMessageText: {
    color: theme.palette.colorError,
    margin: "3px 14px",
    fontSize: "0.75rem",
    padding: "0",
    lineHeight: "1.66",
  },
  menuItem: {
    fontSize: "0.875rem !important",
    padding: "15px 20px !important",
    color: "#707070 !important",
    "@media (max-width: 768px)": {
      minWidth: "100vw",
      left: "0",
    },
  },
  referenceInfo: {
    borderTop: "1px solid #00000033",
    paddingTop: "40px",
    marginTop: "30px",
    "@media (max-width: 768px)": {
      paddingTop: "30px",
    },
    "& #formTextArea": {
      "& fieldset": {
        border: "1px solid #00000033",
      },
      "& label": {
        color: "#00000099",
        zIndex: "inherit",
      },
      "& label.MuiInputLabel-outlined": {
        background: "#fff",
        padding: "0",
        width: "auto",
      },
      "& label.Mui-focused": {
        color: theme.palette.colorPrimary,
        "& + .MuiInputBase-multiline": {
          "& fieldset": {
            border: `2px solid ${theme.palette.colorPrimary}`,
          },
        },
      },
      "& .MuiInputBase-formControl": {
        color: theme.palette.colorPrimary,
        fontWeight: "500",
        fontFamily: "inherit",
        marginBottom: "60px",
        width: "335px",
        paddingBottom: "40px",
        "@media (max-width: 768px)": {
          width: "100%",
          paddingBottom: "22px",
        },
      },
    },
  },
  refernceInfoDetailsWrapper: {
    paddingBottom: "30px",
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    width: "90%",
    "@media (max-width: 768px)": {
      flexDirection: "column",
      width: "100%",
    },
  },
  refernceInfoDetails: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "20px",
  },
  refernceInfoValue: {
    fontSize: "1.1rem",
    color: "#626C7D",
    fontWeight: "bold",
    textTransform: "capitalize !important",
  },
  refernceInfoLabel: {
    color: "#626C7D",
    fontSize: "1rem",
    marginBottom: "10px",
  },
  radioBarWrapper: {
    boxShadow: "0px 3px 6px rgb(0 0 0 / 5%)",
    border: "1px solid #e0e0e0",
    padding: "15px 20px",
    color: "#707070",
    borderRadius: "5px",
    fontSize: "1rem",
    marginBottom: "30px",
    display: "flex",
    alignItems: "center",
    "@media (max-width: 992px)": {
      flexDirection: "column",
      alignItems: "flex-start",
    },
    "& .MuiIconButton-label svg": {
      fontSize: "1rem",
    },
    "& label": {
      margin: "0",
      "& span[class*=Mui-checked]": {
        color: theme.palette.colorSecondary,
        "& + .MuiFormControlLabel-label": {
          color: theme.palette.colorPrimary,
          fontWeight: "500",
        },
      },
    },
    "& .onBAddRadioBtnWrap": {
      paddingRight: "5px",
      "@media (max-width: 768px)": {
        paddingRight: "20px",
        position: "relative",
        left: "-10px",
      },
    },
    "& .MuiFormGroup-row": {
      alignItems: "center",
      paddingLeft: "15px",
      "@media (max-width: 768px)": {
        marginTop: "15px",
        paddingLeft: "0",
      },
    },
  },
  errorMessageText: {
    fontSize: "0.875rem",
    color: theme.palette.colorError,
    paddingLeft: "17px",
    "@media (max-width: 768px)": {
      paddingLeft: "0",
    },
  },
  refernceButtonWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "stretch",
    position: " relative",
    "@media (max-width: 768px)": {
      flexDirection: "column",
      width: "65%",
      margin: "auto",
    },
  },
  refernceButtonSubmit: {
    backgroundColor: theme.palette.colorPrimary,
    borderRadius: "4px",
    color: "#fff",
    border: "0",
    padding: "12px 71px",
    textTransform: "uppercase",
    fontWeight: "bold",
    fontSize: "1.1rem",
    fontFamily: "Nunito Sans",
    lineHeight: "inherit",
    marginRight: "20px",
    minWidth: "200px",
    "@media (max-width: 768px)": {
      width: "100%",
      margin: "auto",
    },
    "&:hover": {
      backgroundColor: theme.palette.colorPrimary,
      color: "#fff",
      boxShadow: "0px 4px 2px -2px #00000070",
    },
    "&:disabled ": {
      backgroundColor: "#E2E2E2",
      border: "0",
      color: "#707070",
      boxShadow: "none",
    },
  },
  formTextareaReference: {
    "@media (max-width: 768px)": {
      width: "100%",
      paddingBottom: "22px",
    },
    "& #additional-comment": {
      padding: "0 !important",
    },
  },

  referencePopoverButton: {
    backgroundColor: "#fff",
    borderRadius: "5px",
    padding: "12px 23px",
    border: "1px solid #707070",
    textTransform: "uppercase",
    fontWeight: "bold",
    fontSize: "1.1rem",
    fontFamily: "Nunito Sans",
    color: "#707070",
    minWidth: "200px",
    "&:hover": {
      background: "#F2F2F7",
      boxShadow: "0px 4px 2px -2px #00000070",
    },
    "@media (max-width: 768px)": {
      width: "100%",
      margin: "15px auto",
    },
  },
  referencePopoverBox: {
    width: "555px",
    boxShadow: " 0 3px 20px #00000029",
    background: "#fff",
    borderRadius: "10px",
    padding: "30px 30px 38px",
    position: "relative",
    bottom: "-60px",
    left: "-70px",
    "@media (max-width: 768px)": {
      width: "auto",
      margin: "0 20px",
      padding: "19px 13px 39px",
      left: "0",
    },
  },
  referencePopoverTitle: {
    color: "#8E8E8E",
    margin: "0",
    fontSize: "1.25rem",
    fontWeight: "500",
    "& span": {
      fontSize: "0.875rem",
      "@media (max-width: 768px)": {
        marginTop: "4px",
      },
    },
    "@media (max-width: 768px)": {
      fontSize: "1rem",
      display: "flex",
      flexDirection: "column",
    },
  },
  referencePopoverRadioGroup: {
    padding: "12px 0 18px",
    "& label .MuiIconButton-label svg": {
      height: "20px",
      width: "20px",
    },
    "& .MuiFormControlLabel-label": {
      color: "#8E8E8E",
      fontWeight: "500",
      margin: "6px 0 1px 0",
      "@media (max-width: 768px)": {
        margin: "0 0 3px 0",
      },
    },
    "& label": {
      marginBottom: "0",
      "& span[class*=Mui-checked]": {
        color: theme.palette.colorSecondary,
      },
    },
    "& .MuiFormControlLabel-root": {
      alignItems: "flex-start",
    },
    "& .MuiIconButton-root": {
      "@media (max-width: 768px)": {
        position: "relative",
        top: "-6px",
      },
    },
  },

  referencePopoverButtonGroup: {
    justifyContent: "center",
    display: "flex",
    "@media (max-width: 768px)": {
      flexDirection: "column",
      width: "70%",
      margin: "0 auto",
    },
  },
  emailAndPhoneContainer: {
    width: "97%",
    display: "flex",
    alignItems: "baseline",
    margin: "15px 0 0",
    border: "1px solid #E0E0E0",
    padding: "30px 25px 32px 20px",
    borderRadius: "5px",
    position: "relative",
    color: "#707070",
    fontSize: "0.875rem",
    "@media (max-width:768px)": {
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      marginTop: "10px",
      width: "100%",
    },
    "& .MuiFormControl-root": {
      width: "100%",
      padding: "0",
    },
    "& .MuiGrid-root": {
      width: "100%",
    },
  },
  emailAndPhoneContainerLabel: {
    position: "relative",
    top: "20px",
    margin: "0 10px",
    "@media (max-width:768px)": {
      margin: "30px 0",
      top: "0",
      "&:before": {
        width: "70px",
        height: "1px",
        content: '""',
        background: "#f3f3f3",
        position: "absolute",
        top: "9px",
        right: "85px",
      },
      "&:after": {
        width: "70px",
        height: "1px",
        content: '""',
        background: "#f3f3f3",
        position: "absolute",
        top: "9px",
        left: "85px",
      },
    },
  },
  emailAndPhoneContainerInputLabel: {
    position: "absolute",
    top: "-11px",
    left: "10px",
    background: "#fff",
    padding: "0 5px",
  },
  errorMessageText: {
    color: "#d32f2f",
    margin: "8px 0 3px 10px",
    fontSize: "0.75rem",
    "@media (max-width:768px)": {
      margin: "8px 0 2px",
    },
  },
}));

const useAxios = makeUseAxios({ axios: API });

const ReferenceSurveyForm = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const [addReferenceToDb, { data: addRefResponse, loading: addRefLoading, error: addRefError }] =
    useMutation(SET_REFERENCES);
  const requiredErrMsg = t("onBoarding.errorMessage.required");
  const formFields = onboardingFields.referenceSurveyForm;
  const [userDetails, setUserDetails] = useState(null);
  const [userFetched, setUserFetched] = useState(false);
  useEffect(() => {
    if (props.userEmail && !userFetched) {
      fetch(`${config.API_BASE_HOST}/ui/v1/users/search?userName=${props.userEmail}`)
        .then((data) => data.json())
        .then((data) => {
          setUserDetails(data);
          setUserFetched(true);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [props.userEmail, userFetched]);
  const [statusInProgress, setStatusInProgress] = useState(false);
  const [firstName, setFirstName] = useState({
    value: "",
    errors: true,
    errorText: requiredErrMsg,
  });
  const [lastName, setLastName] = useState({
    value: "",
    errors: true,
    errorText: requiredErrMsg,
  });
  const [phoneNumber, setPhoneNumber] = useState({
    value: "",
    errors: false,
    errorText: "",
  });
  const [emailAddress, setEmailAddress] = useState({
    value: "",
    errors: false,
    errorText: "",
  });
  const [title, setTitle] = React.useState({
    value: "",
    errors: true,
    errorText: requiredErrMsg,
  });
  const [professionalStandards, setProfessionalStandards] = React.useState({
    value: "",
    errors: true,
    errorText: requiredErrMsg,
  });
  const [attendanceStandards, setAttendanceStandards] = React.useState({
    value: "",
    errors: true,
    errorText: requiredErrMsg,
  });
  const [clinicalStandards, setClinicalStandards] = React.useState({
    value: "",
    errors: true,
    errorText: requiredErrMsg,
  });
  const [additionalComments, setAdditionalComments] = React.useState("");
  const [declinationReason, setDeclinationReason] = React.useState(null);
  const [enableSubmit, setEnableSubmit] = React.useState(false);
  const [enableDeclineSubmit, setEnableDeclineSubmit] = React.useState(false);
  const titleList = [
    "Charge Nurse",
    "Director",
    "Manager",
    "Nurse Educator",
    "Supervisor",
    "Team Lead",
  ];
  const [pageLoadTracked, setPageLoadTracked] = React.useState(false);

  useEffect(() => {
    if (!pageLoadTracked) {
      const segmentEventName = t("segment.pageLoaded");
      const segmentEventProps = {
        URL: document.location.href,
        UTMParameters: {
          utmCampaign: config.REFERENCES_UTM_CAMPAIGN,
          utmSource: config.REFERENCES_LEAD_SOURCE,
        },
      };
      Analytics.segmentTrackEvent(segmentEventName, segmentEventProps);
      setPageLoadTracked(true);
    }
  }, [pageLoadTracked]);
  useEffect(() => {
    if (props.referenceInfo && !statusInProgress) {
      const json = normalizeJson("InProgress");
      if (props.referenceInfo.references) {
        const referenceStored = props.referenceInfo.references[0];
        formFields.forEach((field) => {
          switch (field) {
            case "firstName":
              handleChange(referenceStored.supervisorFirstName, field);
              break;
            case "lastName":
              handleChange(referenceStored.supervisorLastName, field);
              break;
            case "emailAddress":
              handleChange(referenceStored.supervisorEmail, field);
              break;
            case "phoneNumber":
              handleChange(referenceStored.supervisorPhone, field);
              break;
            case "title":
              handleChange(referenceStored.supervisorTitle, field);
              break;
          }
        });
      }
      if (json) {
        addReferenceToDb({ variables: { referencesInput: json } });
        setStatusInProgress(true);
      }
    }
  }, [props.referenceInfo, statusInProgress]);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
    setDeclinationReason(null);
    setEnableDeclineSubmit(false);
  };
  const open = Boolean(anchorEl);
  const idOpen = open ? "simple-popper" : undefined;

  const updateObj = (prevObj, fieldValue, validators) => {
    prevObj.value = fieldValue;
    prevObj.errors = !!validators;
    prevObj.errorText = validators ? validators[0] : null;
    return prevObj;
  };

  const handleDeclineSubmit = async (event) => {
    const json = await normalizeJson("decline");
    try {
      await addReferenceToDb({ variables: { referencesInput: json } });
      //navigate to thank you page
      const segmentEventName = t("segment.referenceDeclined");
      Analytics.segmentTrackEvent(segmentEventName, null);
      history.push(REFERENCES_SURVEY_THANK_YOU);
    } catch (err) {
      console.error("Error while updating reference:", err);
    }
  };
  const handleChange = (value, fieldType) => {
    const fieldValue = value && value.trim() !== "" ? value : "";
    const validators = validationsService.validateSingleField(
      fieldValue,
      surveyConstraint.referencesSurveyConstraint[fieldType]
    );
    let obj = {};
    switch (fieldType) {
      case "firstName":
        obj = updateObj(firstName, fieldValue, validators);
        setFirstName((firstName) => ({
          ...firstName,
          ...obj,
        }));
        break;
      case "lastName":
        obj = updateObj(lastName, fieldValue, validators);
        setLastName((lastName) => ({
          ...lastName,
          ...obj,
        }));
        break;
      case "emailAddress":
        obj = updateObj(emailAddress, fieldValue, validators);
        setEmailAddress((emailAddress) => ({
          ...emailAddress,
          ...obj,
        }));
        break;
      case "phoneNumber":
        obj = updateObj(phoneNumber, fieldValue, validators);
        setPhoneNumber((phoneNumber) => ({
          ...phoneNumber,
          ...obj,
        }));
        break;
      case "title":
        obj = updateObj(title, fieldValue, validators);
        setTitle((title) => ({
          ...title,
          ...obj,
        }));
        break;
    }
    verifySubmitBtnStatus();
    verifyDeclineBtnStatus();
  };

  const verifySubmitBtnStatus = async () => {
    const surveyObj = {
      firstName: firstName.value,
      lastName: lastName.value,
      title: title.value,
      phoneNumber: phoneNumber.value,
      emailAddress: emailAddress.value,
      professionalStandards: professionalStandards.value,
      attendanceStandards: attendanceStandards.value,
      clinicalStandards: clinicalStandards.value,
    };
    const validators = validationsService.validateSetOfFields(
      surveyObj,
      surveyConstraint.referencesSurveyConstraint
    );
    if (validators || !(emailAddress.value || phoneNumber.value)) {
      setEnableSubmit(false);
    } else {
      setEnableSubmit(true);
    }
  };

  const verifyDeclineBtnStatus = async () => {
    const surveyObj = {
      firstName: firstName.value,
      lastName: lastName.value,
      title: title.value,
      phoneNumber: phoneNumber.value,
      emailAddress: emailAddress.value,
    };
    const validators = validationsService.validateSetOfFields(
      surveyObj,
      surveyConstraint.referencesSurveyConstraintForDecline
    );
    if (validators || !(emailAddress.value || phoneNumber.value)) {
      setEnableDeclineSubmit(false);
    } else {
      if (declinationReason) {
        setEnableDeclineSubmit(true);
      }
    }
  };

  useEffect(() => {
    verifyDeclineBtnStatus();
  }, [declinationReason]);

  const normalizeJson = (type) => {
    const DATE_FORMAT = "YYYY-MM-DDTHH:mm:ssZ";
    const userTimeZone = moment().utcOffset();
    const dateCompleted = moment().utcOffset(userTimeZone).format(DATE_FORMAT);

    let referenceReq;
    if (type) {
      const referenceInfo = props.referenceInfo ? props.referenceInfo.references[0] : null;
      const referenceMeta = props.referenceInfo ? props.referenceInfo.meta : null;
      referenceReq = {
        candidateReference: {
          meta: referenceMeta,
          references: [
            {
              dateCompleted: type === "submit" ? dateCompleted : null,
              dateCreated: referenceInfo.dateCreated,
              dateLastUpdated: dateCompleted,
              endDate: referenceInfo.endDate,
              facilityName: referenceInfo.facilityName,
              id: referenceInfo.id,
              notificationSent: true,
              profession: referenceInfo.profession,
              specialty: referenceInfo.specialty,
              startDate: referenceInfo.startDate,
              referenceId: referenceInfo.referenceId,
              atsBullhornCandidateId: referenceInfo.atsBullhornCandidateId,
              status:
                type === "decline"
                  ? ReferencesStates.DECLINED
                  : type === "submit"
                  ? ReferencesStates.COMPLETE
                  : ReferencesStates.INPROGRESS,
              supervisorEmail:
                type === "submit" || type === "decline"
                  ? emailAddress.value
                  : referenceInfo.supervisorEmail,
              supervisorFirstName:
                type === "submit" || type === "decline"
                  ? firstName.value
                  : referenceInfo.supervisorFirstName,
              supervisorLastName:
                type === "submit" || type === "decline"
                  ? lastName.value
                  : referenceInfo.supervisorLastName,
              supervisorPhone:
                type === "submit" || type === "decline"
                  ? phoneNumber.value
                  : referenceInfo.supervisorPhone,
              supervisorTitle:
                type === "submit" || type === "decline"
                  ? title.value
                  : referenceInfo.supervisorTitle,
              token: referenceInfo.token,
              declinationReason: type === "decline" ? declinationReason : null,
              responseOne: type === "submit" ? attendanceStandards.value : null,
              responseTwo: type === "submit" ? professionalStandards.value : null,
              responseThree: type === "submit" ? clinicalStandards.value : null,
              comments: type === "submit" || type === "decline" ? additionalComments : null,
              dateNotified: referenceInfo.dateNotified,
              contactConsent: referenceInfo.contactConsent,
              facilityCityState: referenceInfo.facilityCityState,
              employed: referenceInfo.employed,
            },
          ],
        },
        companyId: parseInt(props.userCompany),
        email: props.userEmail,
        event: "updateReference",
        isDeleted: false,
      };
    }

    return referenceReq;
  };
  const handleSubmitBtn = async () => {
    const json = normalizeJson("submit");
    try {
      await addReferenceToDb({ variables: { referencesInput: json } });
      const segmentEventName = t("segment.referenceCompleted");
      Analytics.segmentTrackEvent(segmentEventName, null);
      history.push(REFERENCES_SURVEY_THANK_YOU);
    } catch (error) {
      console.error("Error while updating reference:", error);
    }
  };

  const radioBtnHandleChange = (e, fieldType) => {
    const fieldValue = e.target.value;
    const validators = validationsService.validateSingleField(
      fieldValue,
      surveyConstraint.referencesSurveyConstraint[fieldType]
    );
    let obj = {};
    switch (fieldType) {
      case "professionalStandards":
        obj = updateObj(professionalStandards, fieldValue, validators);
        setProfessionalStandards((professionalStandards) => ({
          ...professionalStandards,
          ...obj,
        }));
        break;
      case "attendanceStandards":
        obj = updateObj(attendanceStandards, fieldValue, validators);
        setAttendanceStandards((attendanceStandards) => ({
          ...attendanceStandards,
          ...obj,
        }));
        break;
      case "clinicalStandards":
        obj = updateObj(clinicalStandards, fieldValue, validators);
        setClinicalStandards((clinicalStandards) => ({
          ...clinicalStandards,
          ...obj,
        }));
        break;
    }
    verifySubmitBtnStatus();
  };

  return (
    <>
      <div className={classes.referSurveyFormWrap}>
        <div>
          <h3 className={classes.helloUsername}>
            {t("referSurvey.referUserName")} <span>{firstName.value}</span>!
          </h3>
          <p className={classes.sectionContent}>
            {t("referSurvey.contentInfo")}&nbsp;
            <a
              className={classes.referTermsAndCondition}
              href="https://content.ccrncloud.com/content/docs/terms-and-conditions/reference/terms-and-conditions.pdf"
              target="_blank"
              rel="noreferrer"
            >
              {t("referSurvey.referTermsAndCondition")}
            </a>
            &nbsp;{t("referSurvey.contentInfoText")}
          </p>
          <b className={classes.highlightedContent}>{t("referSurvey.contentInfoHighlighted")}</b>
        </div>

        <div className={classes.referContactInfo}>
          <h3 className={classes.referSectionTitle}>{t("referSurvey.yourContactHeading")}</h3>
          {/* Refer Contact Info Form */}
          <Box id="referContactInfoInnerWrapper" sx={{ flexGrow: 1 }}>
            <Grid container>
              <Grid xs={12} md={4} className={classes.formControlWrapper}>
                <TextField
                  id="first-name"
                  variant="outlined"
                  label={t("referSurvey.referFirstNameLabel")}
                  className={classes.formControl}
                  value={firstName.value}
                  onChange={(event) => handleChange(event.target.value, "firstName")}
                  error={!!firstName.errors}
                  helperText={firstName.errors ? firstName.errorText : null}
                />
              </Grid>
              <Grid xs={12} md={4} className={classes.formControlWrapper}>
                <TextField
                  id="last-name"
                  label={t("referSurvey.referLastNameLabel")}
                  variant="outlined"
                  value={lastName.value}
                  onChange={(event) => handleChange(event.target.value, "lastName")}
                  error={!!lastName.errors}
                  helperText={lastName.errors ? lastName.errorText : null}
                />
              </Grid>
              <Grid xs={12} md={4} className={classes.formControlWrapper}>
                <FormControl fullWidth id="selectDropdown">
                  <InputLabel>{t("referSurvey.referTitleLabel")}</InputLabel>
                  <Select
                    label={t("referSurvey.referTitleLabel")}
                    onChange={(event) => handleChange(event.target.value, "title")}
                    value={title.value}
                    error={!!title.errors}
                    helperText={title.errors ? title.errorText : null}
                  >
                    {titleList.map((option) => (
                      <MenuItem key={option} value={option} className={classes.menuItem}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                  {title.errors && (
                    <span className={classes.dropDownErrorMessageText}> {title.errorText}</span>
                  )}
                </FormControl>
              </Grid>
              <div className={classes.emailAndPhoneContainer}>
                <div className={classes.emailAndPhoneContainerInputLabel}>
                  {t("addReferences.emailAndPhoneLabel")}
                </div>
                <Grid xs={12} md={4} className={classes.formControlWrapper}>
                  <TextField
                    id="email-address"
                    label={t("referSurvey.referEmailLabel")}
                    variant="outlined"
                    value={emailAddress.value}
                    onChange={(event) => handleChange(event.target.value, "emailAddress")}
                    error={!!emailAddress.errors}
                    helperText={emailAddress.errors ? emailAddress.errorText : null}
                  />
                </Grid>
                <label className={classes.emailAndPhoneContainerLabel}>
                  {t("addReferences.andOrLabel")}
                </label>
                <Grid xs={12} md={4} className={classes.formControlWrapper}>
                  <TextField
                    id="phone"
                    label={t("referSurvey.referPhoneNumberLabel")}
                    variant="outlined"
                    value={phoneNumber.value}
                    onChange={(event) => handleChange(event.target.value, "phoneNumber")}
                    error={!!phoneNumber.errors}
                    helperText={phoneNumber.errors ? phoneNumber.errorText : null}
                  />
                </Grid>
              </div>
            </Grid>
          </Box>
        </div>
        {emailAddress.value || phoneNumber.value ? (
          ""
        ) : (
          <p className={classes.errorMessageText}>{requiredErrMsg}</p>
        )}
        <div className={classes.referenceInfo}>
          <div className={classes.referenceInnerInfo}>
            <h3 className={classes.referSectionTitle}>{t("referSurvey.referenceHeading")}</h3>

            {/* Refer Info Details */}
            <div className={classes.refernceInfoDetailsWrapper}>
              <div className={classes.refernceInfoDetails}>
                <lable className={classes.refernceInfoLabel}>
                  {t("referSurvey.referEmployeeLabel")}
                </lable>
                <div className={classes.refernceInfoValue}>
                  {userDetails
                    ? userDetails.firstname && userDetails.lastname
                      ? `${userDetails.firstname} ${userDetails.lastname}`
                      : userDetails.firstname
                      ? userDetails.firstname
                      : userDetails.lastname
                      ? userDetails.lastname
                      : ""
                    : ""}
                </div>
              </div>
              <div className={classes.refernceInfoDetails}>
                <lable className={classes.refernceInfoLabel}>
                  {t("referSurvey.referProfessionSpecialty")}
                </lable>
                <div className={classes.refernceInfoValue}>
                  {props.referenceInfo &&
                  props.referenceInfo.references &&
                  props.referenceInfo.references.length > 0
                    ? props.referenceInfo.references[0].profession &&
                      props.referenceInfo.references[0].specialty
                      ? `${props.referenceInfo.references[0].profession}/${props.referenceInfo.references[0].specialty}`
                      : props.referenceInfo.references[0].profession
                      ? props.referenceInfo.references[0].profession
                      : props.referenceInfo.references[0].specialty
                      ? props.referenceInfo.references[0].specialty
                      : ""
                    : ""}
                </div>
              </div>
              <div className={classes.refernceInfoDetails}>
                <lable className={classes.refernceInfoLabel}>
                  {t("referSurvey.referFacilityLabel")}
                </lable>
                <div className={classes.refernceInfoValue}>
                  {props.referenceInfo &&
                  props.referenceInfo.references &&
                  props.referenceInfo.references.length > 0
                    ? props.referenceInfo.references[0].facilityName
                    : ""}
                </div>
              </div>
              <div className={classes.refernceInfoDetails}>
                <lable className={classes.refernceInfoLabel}>
                  {t("referSurvey.referEmploymentDatesLabel")}
                </lable>
                <div className={classes.refernceInfoValue}>
                  {props.referenceInfo &&
                  props.referenceInfo.references &&
                  props.referenceInfo.references.length > 0
                    ? `${props.referenceInfo.references[0].startDate} - ${
                        props.referenceInfo.references[0].endDate
                          ? props.referenceInfo.references[0].endDate
                          : t("addReferences.presentLabel")
                      }`
                    : ""}
                </div>
              </div>
            </div>
          </div>
          {/* ReferenceRadioSection */}
          <div className={classes.radioBarWrapper}>
            <label>{t("referSurvey.referAttendanceStandards")}</label>
            <RadioGroup
              row
              className={classes.referRadioGroup}
              value={attendanceStandards.value}
              onChange={(event) => radioBtnHandleChange(event, "attendanceStandards")}
            >
              <FormControlLabel
                value="No"
                className="onBAddRadioBtnWrap"
                control={<Radio name="checkedPunctuality" size="small" />}
                label="No"
              />
              <FormControlLabel
                value="Yes"
                className="onBAddRadioBtnWrap"
                control={<Radio name="checkedPunctuality" size="small" />}
                label="Yes"
              />
              {attendanceStandards.errors ? (
                <span className={classes.errorMessageText}>{attendanceStandards.errorText}</span>
              ) : null}
            </RadioGroup>
          </div>
          <div className={classes.radioBarWrapper}>
            <label>{t("referSurvey.referProfessionalStandards")}</label>
            <RadioGroup
              row
              className={classes.referRadioGroup}
              value={professionalStandards.value}
              onChange={(event) => radioBtnHandleChange(event, "professionalStandards")}
            >
              <FormControlLabel
                value="No"
                className="onBAddRadioBtnWrap"
                control={<Radio name="checkedProfessionals" size="small" />}
                label="No"
              />
              <FormControlLabel
                value="Yes"
                className="onBAddRadioBtnWrap"
                control={<Radio name="checkedProfessionals" size="small" />}
                label="Yes"
              />
              {professionalStandards.errors ? (
                <span className={classes.errorMessageText}>{professionalStandards.errorText}</span>
              ) : null}
            </RadioGroup>
          </div>
          <div className={classes.radioBarWrapper}>
            <label>{t("referSurvey.referClinicalStandards")}</label>
            <RadioGroup
              row
              className={classes.referRadioGroup}
              value={clinicalStandards.value}
              onChange={(event) => radioBtnHandleChange(event, "clinicalStandards")}
            >
              <FormControlLabel
                value="No"
                className="onBAddRadioBtnWrap"
                control={<Radio name="checkedClinical" size="small" />}
                label="No"
              />
              <FormControlLabel
                value="Yes"
                className="onBAddRadioBtnWrap"
                control={<Radio name="checkedClinical" size="small" />}
                label="Yes"
              />
              {clinicalStandards.errors ? (
                <span className={classes.errorMessageText}>{clinicalStandards.errorText}</span>
              ) : null}
            </RadioGroup>
          </div>

          <div id="formTextArea">
            <TextField
              id="additional-comment"
              label={t("referSurvey.referAdditionCommentLabel")}
              multiline
              rows={2}
              className={classes.formTextareaReference}
              onChange={(event) => setAdditionalComments(event.target.value)}
            />
          </div>

          <div className={classes.refernceButtonWrapper}>
            <button
              variant="contained"
              className={classes.refernceButtonSubmit}
              onClick={handleSubmitBtn}
              disabled={!enableSubmit}
            >
              {t("referSurvey.referenceSubmitBtnLabel")}
            </button>
            <div className={classes.referencePopoverButtonWrapper}>
              <button
                className={classes.referencePopoverButton}
                aria-describedby={idOpen}
                type="button"
                onClick={handleClick}
              >
                {t("referSurvey.referenceDecLabel")}
              </button>
              <Popper id={idOpen} open={open} anchorEl={anchorEl}>
                <Box className={classes.referencePopoverBox}>
                  <h4 className={classes.referencePopoverTitle}>
                    {t("referSurvey.popoverHeading")}
                    <span> {t("referSurvey.popoverSubHeading")}</span>
                  </h4>
                  <div className={classes.referencePopoverRadioGroup}>
                    <FormControl>
                      <RadioGroup
                        name="radio-buttons-group"
                        onChange={(event) => setDeclinationReason(event.target.value)}
                      >
                        <FormControlLabel
                          value={t("referSurvey.dontKnowTheEmp")}
                          control={<Radio />}
                          label={t("referSurvey.dontKnowTheEmp")}
                        />
                        <FormControlLabel
                          value={t("referSurvey.wishEmp")}
                          control={<Radio />}
                          label={t("referSurvey.wishEmp")}
                        />
                        <FormControlLabel
                          value={t("referSurvey.dontKnowTheManageEmp")}
                          control={<Radio />}
                          label={t("referSurvey.dontKnowTheManageEmp")}
                        />
                        <FormControlLabel
                          value={t("referSurvey.againstPolicy")}
                          control={<Radio />}
                          label={t("referSurvey.againstPolicy")}
                        />
                        <FormControlLabel
                          value={t("referSurvey.popoverOther")}
                          control={<Radio />}
                          label={t("referSurvey.popoverOther")}
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>

                  <div className={classes.referencePopoverButtonGroup}>
                    <button
                      className={classes.refernceButtonSubmit}
                      onClick={handleDeclineSubmit}
                      disabled={!enableDeclineSubmit}
                    >
                      {t("referSurvey.popoverSubmitBtnLabel")}
                    </button>
                    <button
                      type="button"
                      onClick={handleClick}
                      className={classes.referencePopoverButton}
                    >
                      {t("referSurvey.popoverCancelBtnLabel")}
                    </button>
                  </div>
                </Box>
              </Popper>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReferenceSurveyForm;
