import React, { useState, useEffect } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import isEmpty from "lodash/isEmpty";
import orderBy from "lodash/orderBy";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Skeleton from "@material-ui/lab/Skeleton";
import usePrevious from "../../../../hooks/usePrevious";
import { PrimaryButton } from "../../Buttons";
import SaveSearchService from "./Services/Api/SaveSearchService";
import { MY_JOBS } from "../../../../router/routes";
import SavedSearchesMaster from "./SavedSearchesMaster";
import NoResults from "../Home/NoResults";

const useStyles = makeStyles((theme) => ({
  saveSearchPgWrap: {
    minHeight: "500px",
    "@media(min-width: 0px) and (max-width: 768px)": {
      minHeight: "350px",
    },
  },
  saveSearchPgHead: {
    fontSize: "1.5rem",
    color: theme.palette.colorPrimary,
    margin: "40px 15px 25px 0px",
    fontWeight: "600",
    "@media(min-width: 0px) and (max-width: 768px)": {
      display: "flex",
      justifyContent: "center",
      fontSize: "1.3rem",
    },
  },
  marginZero: {
    margin: "0 auto",
  },
  savedSearchSkeletonWrap: {
    marginTop: "-3rem",
  },
  savedSearchSkeleton: {
    width: "100%",
    height: "150px",
    marginTop: "10px",
  },
}));
function SavedSearches() {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const candidateId = useSelector((state) => state.auth.candidateId);
  const [savedSearches, setSavedSearches] = useState({});
  const [isOutSideClicked, setOutSideClickedFlag] = useState(false);
  const prevId = usePrevious(candidateId);
  const [savedSearchesLoading, setSavedSearchesLoading] = useState(true);

  useEffect(() => {
    if (candidateId && candidateId !== prevId) {
      SaveSearchService.getAll(candidateId, "savedSearches").then((result) => {
        setSavedSearchesLoading(false);
        const savedSearchesSortedObj = sortSavedSearchesByDateCreated(result.savedSearches);
        setSavedSearches(savedSearchesSortedObj);
      });
    }
  }, [candidateId]);

  const sortSavedSearchesByDateCreated = (savedSearches) => {
    let sortedObj = {};
    if (!isEmpty(savedSearches) && Object.keys(savedSearches).length > 0) {
      const dataArray = orderBy(Object.values(savedSearches), ["dateCreated"], ["desc"]);
      const sortedArray = [];
      dataArray.forEach((value) => {
        const key = Object.keys(savedSearches).find((key) => savedSearches[key] === value);
        const obj = [key, value];
        sortedArray.push(obj);
      });
      sortedObj = Object.fromEntries(sortedArray);
    }
    return sortedObj;
  };

  const handleButtonClick = () => {
    history.push(MY_JOBS);
  };

  const UpdateSavedSearches = (data) => {
    setSavedSearches(data);
  };

  const handleClickAway = () => {
    setOutSideClickedFlag(true);
  };

  return (
    <Container className={classes.saveSearchPgWrap}>
      <Row>
        <Col sm={12} md={1} lg={1} xs={12} />
        <Col sm={12} md={10} lg={10} xs={12}>
          <h3 className={classes.saveSearchPgHead}>{t("headerV2.savedSearches")}</h3>
          {savedSearchesLoading ? (
            <Container className={classes.savedSearchSkeletonWrap}>
              <Row>
                <Col sm={12} md={12} lg={12} xs={12}>
                  <Skeleton animation="wave" className={classes.savedSearchSkeleton} />
                </Col>
              </Row>
            </Container>
          ) : savedSearches && !isEmpty(savedSearches) && Object.keys(savedSearches).length > 0 ? (
            <ClickAwayListener onClickAway={handleClickAway}>
              <Row>
                <SavedSearchesMaster
                  results={savedSearches}
                  UpdateSavedSearches={(data) => UpdateSavedSearches(data)}
                  isOutSideClicked={isOutSideClicked}
                  setOutSideClickedFlag={setOutSideClickedFlag}
                />
              </Row>
            </ClickAwayListener>
          ) : (
            <Container>
              <NoResults
                isCenter
                noResultsTxt={t("savedSearches.noSavedSearchesTxt")}
                noResultsSubTxt={t("savedSearches.noSavedSearchesSubTxt")}
              />
            </Container>
          )}
          <br />
          <br />
          <PrimaryButton onClick={handleButtonClick} className={classes.marginZero}>
            {!isEmpty(savedSearches) && Object.keys(savedSearches).length > 0
              ? t("savedSearches.newJobSearch")
              : t("savedSearches.searchJobsBtn")}
          </PrimaryButton>
          <br />
          <br />
        </Col>
        <Col sm={12} md={1} lg={1} xs={12} />
      </Row>
    </Container>
  );
}

export default SavedSearches;
