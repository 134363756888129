import { push } from "connected-react-router";
import { MY_PROFILE } from "../../router/routes";

export const UPDATE_LOCAL_NAVIGATION_KEY = "UPDATE_LOCAL_NAVIGATION_KEY";
export const updateLocalNavigationKey = (payload) => ({
  type: UPDATE_LOCAL_NAVIGATION_KEY,
  payload,
});

export const UPDATE_GLOBAL_NAVIGATION_KEY = "UPDATE_GLOBAL_NAVIGATION_KEY";
export const updateGlobalNavigationKey = (payload) => ({
  type: UPDATE_GLOBAL_NAVIGATION_KEY,
  payload,
});

export const UPDATE_SUB_NAVIGATION_KEY = "UPDATE_SUB_NAVIGATION_KEY";
export const updateSubNavigationKey = (payload) => ({ type: UPDATE_SUB_NAVIGATION_KEY, payload });

export const IS_HAMBURGER_SUBMENU_OPEN = "IS_HAMBURGER_SUBMENU_OPEN";
export const isHamburgerSubMenuOpen = (payload) => ({ type: IS_HAMBURGER_SUBMENU_OPEN, payload });

export const IS_HAMBURGER_MENU_OPEN = "IS_HAMBURGER_MENU_OPEN";
export const isHamburgerMenuOpen = (payload) => ({ type: IS_HAMBURGER_MENU_OPEN, payload });

export const ON_BOARDING_NAVIGATION_CLICKED = "ON_BOARDING_NAVIGATION_CLICKED";
export const onBoardingNavigationClicked = (payload) => ({
  type: ON_BOARDING_NAVIGATION_CLICKED,
  payload,
});

export const SET_COMPLETED_STEP = "SET_COMPLETED_STEP";
export const setCompletedStep = (payload) => ({ type: SET_COMPLETED_STEP, payload });

export const handleSelectedLocalNavigation = (payload) => (dispatch) => {
  if (payload) {
    if (!payload.includes("http")) {
      dispatch(updateLocalNavigationKey(payload));
      if (payload === MY_PROFILE) dispatch(push(payload));
      else dispatch(push(payload));
    }
  }
};

export const handleSelectedGlobalNavigation = (payload) => (dispatch) => {
  if (payload) {
    if (!payload.includes("http")) {
      dispatch(push(payload));
      dispatch(updateGlobalNavigationKey(payload));
    }
  }
};

export const handleSelectedSubNavigation = (payload) => (dispatch) => {
  if (payload) {
    if (!payload.includes("http")) {
      dispatch(push(payload));
      dispatch(updateSubNavigationKey(payload));
    }
  }
};
