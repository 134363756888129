import { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { Typography, useMediaQuery } from "@mui/material";
import QuickApplyBolt from "src/imagesV2/bolt.svg";
import QuickApplyBoltWhite from "src/imagesV2/boltWhite.svg";
import { HOMEPAGE, QUICK_APPLY_ELIGIBLE } from "src/constants/campaigns_constants";
import { RecommendedJobsSourceTypes } from "src/types/receommendedJobsSource";
import PropTypes from "prop-types"
import { useTranslation, withTranslation } from "react-i18next";
import JobCardContainer from "../../JobResults/JobCardContainer";
import JobsCarousel from "../../JobDescription/JobsCarousel";
import JobViewsSkeleton from "./JobViewsSkeleton";
import styles from "./JobEligibilityDashboardAdvertisement.module.scss";

interface RootState {
  onBoarding: {
    specialtiesAndEducation: any;
    completedSections: any;
  };
}

const JobEligibilityDashboardAdvertisement = ({ quickApplyAdvJobs, quickApplyAdvCarouselItems, isJobSearchWithViewsLoading, isInitApiLoading }) => {
  const { t } = useTranslation();
  const [showSection, setShowSection] = useState(false)
  const completedSections = useSelector((state: RootState) => state.onBoarding.completedSections);
  const isProfileDataLoaded = completedSections && Object.keys(completedSections).filter(key => completedSections[`${[key]}`] === true);
  const isProfileIncomplete = isProfileDataLoaded && isProfileDataLoaded?.length < 5;
  const isCompact = useMediaQuery("(max-width: 768px)");

  useEffect(() => {
    if (!isProfileIncomplete && isJobSearchWithViewsLoading) setShowSection(true);
    if (!isJobSearchWithViewsLoading && !isInitApiLoading) {
      if (quickApplyAdvJobs?.length === 0 || (completedSections && isProfileDataLoaded?.length === 0)) {
        setShowSection(false);
      } else {
        const allHaveZeroQuickApplyPercentage = quickApplyAdvJobs.every((job) => job.quickApplyPercentage === 0);
        if (allHaveZeroQuickApplyPercentage || isProfileIncomplete === null || isProfileIncomplete) {
          setShowSection(false);
        } else {
          setShowSection(true);
        }
      }
    }

  }, [isJobSearchWithViewsLoading, isInitApiLoading, completedSections, quickApplyAdvJobs]);

  const isLoading = isInitApiLoading && isJobSearchWithViewsLoading;

  if (!showSection) {
    return null;
  }

  return (
    <div className={styles.quickApplyEligibility}>
      <div className={styles.quickApplyEligibilityContainer}>
        <Typography className={styles.header}>
          <img className={styles.icon} src={QuickApplyBolt} alt="bolt" />
          Quick Apply Eligible
        </Typography>
        <div className={styles.quickApplyEligibilityGrid}>
          <div className={styles.descriptionContainer}>
            <Typography className={styles.title}>
              {t("dashboard.jobEligibility.heading")}
            </Typography>


            {isCompact && (<>
              <Typography className={styles.text}>
                {t("dashboard.jobEligibility.quickApplyMobileText")}
              </Typography>
            </>)}

            {!isCompact && (<>
              <Typography className={styles.text}>
                {t("dashboard.jobEligibility.quickApplyDescStart")}
                <strong>
                  <img className={styles.icon} src={QuickApplyBoltWhite} alt="bolt" />
                  {t("dashboard.jobEligibility.quickApply")}
                </strong>
                {t("dashboard.jobEligibility.quickApplyDescEnd")}
              </Typography>
              <Typography className={styles.text}>
                {t("dashboard.jobEligibility.quickApplyDescStart2")}
                <strong>
                  <img className={styles.icon} src={QuickApplyBoltWhite} alt="bolt" />
                  {t("dashboard.jobEligibility.quickApply")}
                </strong>
                {t("dashboard.jobEligibility.quickApplyDescEnd2")}
              </Typography>
            </>)}

          </div>
          <div className={styles.cardContainer}>
            {isCompact && (<>
              {isLoading && (
                <JobViewsSkeleton height={170} shouldWrap width={330} isEligibilityCard />
              )}

              {!isLoading && (<>
                {quickApplyAdvJobs && quickApplyAdvJobs?.length > 0 && (
                  <div className="quickApplyCarouselContainer">
                    <JobsCarousel card={quickApplyAdvCarouselItems} isQuickApplyAdvCard />
                  </div>
                )}
              </>)}
            </>)}

            {!isCompact && (<>
              {isLoading && (
                <JobViewsSkeleton height={180} shouldWrap width={425} isEligibilityCard />
              )}

              {!isLoading && (<>
                {quickApplyAdvJobs && quickApplyAdvJobs.map((item) => (
                  <JobCardContainer
                    key={item.id}
                    job={item}
                    isQuickApplyAdvCard
                    sourceType={HOMEPAGE}
                    campaignCode={QUICK_APPLY_ELIGIBLE}
                    jobsSource={RecommendedJobsSourceTypes.NONE}
                  />
                ))}
              </>)}
            </>)}
          </div>
        </div>
      </div>
    </div>
  );
};

JobEligibilityDashboardAdvertisement.propTypes = {
  quickApplyAdvJobs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    })
  ).isRequired,
  quickApplyAdvCarouselItems: PropTypes.arrayOf(PropTypes.string).isRequired,
  isJobSearchWithViewsLoading: PropTypes.bool.isRequired,
  isInitApiLoading: PropTypes.bool.isRequired,
};


export default connect()(withTranslation()(JobEligibilityDashboardAdvertisement));
