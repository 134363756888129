import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Skeleton from "@material-ui/lab/Skeleton";

function Media({ loading }: { loading: boolean }) {
  return (
    <Card className="cardSkeleton">
      {loading ? (
        <Skeleton animation="wave" variant="rect" className="imgSkeleton" />
      ) : (
        <CardMedia className="imgSkeleton" />
      )}
      <Row>
        <Col sm={12} md={12} lg={12} xs={12}>
          <CardContent>
            <>
              <Skeleton animation="wave" className="skeletonTxtFormat" />
              <Skeleton animation="wave" className="skeletonSubTxtFormat" />
            </>
            <br />
            <>
              <Skeleton animation="wave" className="skeletonSubTxtFormat" />
            </>
          </CardContent>
        </Col>

        <Col sm={12} md={12} lg={12} xs={12}>
          <CardContent>
            <>
              <Skeleton animation="wave" className="skeletonTxtFormat" />
              <Skeleton animation="wave" className="skeletonTxtFormat" />
            </>
          </CardContent>
        </Col>
        <Col sm={12} md={12} lg={12} xs={12}>
          <>
            <Skeleton animation="wave" className="skeletonBtn" />
          </>
        </Col>
      </Row>
    </Card>
  );
}
export default function Facebook() {
  return (
    <div>
      <Media loading />
    </div>
  );
}
