import Geocode from "react-geocode";
import config from "../../config";

export const FETCH_LAT_LNG_FROM_ADDRESS_REQUEST = "FETCH_LAT_LNG_FROM_ADDRESS_REQUEST";
export const fetchLatLngFromAddressRequest = (payload) => ({
  type: FETCH_LAT_LNG_FROM_ADDRESS_REQUEST,
  payload,
});

export const FETCH_LAT_LNG_FROM_ADDRESS_SUCCESS = "FETCH_LAT_LNG_FROM_ADDRESS_SUCCESS";
export const fetchLatLngFromAddressSuccess = (payload) => ({
  type: FETCH_LAT_LNG_FROM_ADDRESS_SUCCESS,
  payload,
});

export const FETCH_LAT_LNG_FROM_ADDRESS_FAILURE = "FETCH_LAT_LNG_FROM_ADDRESS_FAILURE";
export const fetchLatLngFromAddressFailure = (payload) => ({
  type: FETCH_LAT_LNG_FROM_ADDRESS_FAILURE,
  payload,
});

export const SAVE_RSS_FEEDS = "SAVE_RSS_FEEDS";
export const saveRssFeeds = (payload) => ({ type: SAVE_RSS_FEEDS, payload });
export const fetchLatLngFromAddress = (address, type) => {
  const latLngObj = {
    mainOffcAddr: null,
    currAssig: null,
  };
  return async (dispatch) => {
    dispatch(fetchLatLngFromAddressRequest());
    Geocode.setApiKey(config.googleMapsAPIKey);
    await Geocode.fromAddress(address)
      .then((response) => {
        const { lat, lng } = response.results[0].geometry.location;
        const latLong = `${lat},${lng}`;
        latLngObj[String(type)] = latLong;
        dispatch(fetchLatLngFromAddressSuccess(latLngObj, type));
      })
      .catch((error) => {
        dispatch(fetchLatLngFromAddressFailure(error.message));
      });
  };
};
