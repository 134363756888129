import "date-fns";
import Grid from "@material-ui/core/Grid";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";

interface IProps {
  palette: {
    mainBorder: string;
    colorPrimary: string;
  };
}

const useStyles = makeStyles((theme: IProps) => ({
  root: {
    "& .MuiInputBase-root": {
      border: theme.palette.mainBorder,
      padding: 5,
      borderRadius: 5,
      "& .MuiInputBase-input": {
        paddingLeft: 10,
        fontSize: "0.8rem",
      },
    },
    " & .MuiFormLabel-root": {
      paddingLeft: 15,
      paddingTop: 5,
      fontSize: "0.8rem",
    },
    "& .MuiFormLabel-root.Mui-focused": {
      color: theme.palette.colorPrimary,
    },
    "& .MuiInputBase-root.Mui-error": {
      marginTop: 16,
    },
  },
}));

interface FilterDateProps {
  startDate: string | null;
  handleStartDateChange: (newDate: MaterialUiPickersDate) => void;
}

const FilterDate = ({ startDate, handleStartDateChange }: FilterDateProps) => {
  const classes = useStyles();

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid container justifyContent="flex-start">
        <KeyboardDatePicker
          className={classes.root}
          minDate={new Date()}
          allowKeyboardControl={false}
          autoOk
          variant="inline"
          format="MM/dd/yyyy"
          id="date-picker-inline-filter"
          views={["year", "month", "date"]}
          label={!startDate ? "MM/DD/YYYY" : ""}
          InputProps={{
            disableUnderline: true,
          }}
          InputLabelProps={{ shrink: false }}
          value={startDate ? moment.utc(startDate).format("MM/DD/YYYY") : null}
          onChange={handleStartDateChange}
        />
      </Grid>
    </MuiPickersUtilsProvider>
  );
};

FilterDate.propTypes = {
  handleStartDateChange: PropTypes.func.isRequired,
};

export default FilterDate;
