const config = {
  API_BASE_HOST: process.env.REACT_APP_API_BASE_HOST,
  cognito: {
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    region: process.env.REACT_APP_COGNITO_REGION,
    clientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
    groupName: process.env.REACT_APP_COGNITO_GROUP_NAME,
  },
  PASSWORD_REGEX: process.env.REACT_APP_PASSWORD_REGEX,
  EMAIL_REGEX:
    "^(?:[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+(?:[.A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+)*)@(?:(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\\.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?)$",
  NAME_REGEX: '^[^0-9~`!@#$%^&*()_+=,"{}|:;"\\\\/<>?\\[\\]]+$',
  NAME_REGEX_BASIC_INFO: '^[^~`!@#$%^&*,"()_+={}|:;"\\\\\\\\/<>?\\\\[\\\\]]+$',
  PHONE_REGEX: "^[0-9]{10}$",
  PHONE_REGEX_BASIC_INFO: "^\\d{10}$",
  DOB_REGEX: "^((1[0-2]|0[1-9])/(3[01]|[12][0-9]|0[1-9])/[0-9]{4})$",
  ZIP_REGEX: "^\\d{5}$",
  COUNTRY_REGEX: "^[a-zA-Z]{2}$",
  CHAR_NUMBER: "^$|^(?!')(?!.*-)(?!.*\\.\\.)[A-Za-z\\d\\sàâçéèêëîïôûùüÿñæœÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]+$",
  CANADA_ZIP_REGEX:
    "^[ABCEGHJ-NPRSTVXYabceghj-nprstvxy]\\d[ABCEGHJ-NPRSTV-Zabceghj-nprstv-z][ -]?\\d[ABCEGHJ-NPRSTV-Zabceghj-nprstv-z]\\d$",
  NUMBER_REGEX: "^\\d+$",
  STREET_REGEX:
    "^$|^(?!,)(?!.*--)(?!.*\\.\\.)(?!.*//)[A-Za-zâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ\\d\\s+./,-\\\\(\\)'s]+$",
  CITY_REGEX: "^$|^(?!')(?!.*--)(?!.*\\.\\.)[A-Za-z\\s+.'àâçéèêëîïôûùüÿñæœÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ’-]+$",
  CANADA_CITY_REGEX: "^[A-Za-z\\s+.'àâçéèêëîïôûùüÿñæœÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ’-]+$",
  WORK_EXP_CITY_REGEX: "^$|^(?!')(?!.*--)(?!.*\\.\\.)[A-Za-z\\s+.',]+$",
  APT_REGEX: "^$|^(?!')(?!.*--)(?!.*\\.\\.)[A-Za-z\\d\\s+.'àâçéèêëîïôûùüÿñæœÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ’-]+$",
  CANADA_APT_REGEX: "^[A-Za-z\\d\\s+.'-àâçéèêëîïôûùüÿñæœÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ’]+$",
  EMAIL_REGEX_BASIC_INFO:
    "^$|^(?!.*--)(?!.*\\.\\.)\\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,3})+$",
  SCHOOL_REGEX: "^$|^(?!')(?!.*--)(?!.*\\.\\.)[A-Za-z\\s+'.,-]+$",
  DEGREE_REGEX: "/^$|^(?!')(?!.*--)(?!.*\\.\\.)[A-Za-z\\s+'.,-]+$",
  SSN_REGEX:
    process.env.REACT_APP_ENABLE_SSN_VALIDATION_V2 === "true"
      ? /^(?!666|000|9\d{2})\d{3}[- ]{0,1}(?!00)\d{2}[- ]{0,1}(?!0{4})\d{4}$|^$/
      : "^(\\d{3}-\\d{2}-\\d{4}|)$",
  WCM_URL: process.env.REACT_APP_WCM_URL,
  googleMapsAPIKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  addThisUrl: process.env.REACT_APP_ADD_THIS_URL,
  googleAnalyticsTrackingId: process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID,
  EMPLOYEE_STREAM_PROFILE_BASIC_INFO: process.env.REACT_APP_EMPLOYEE_STREAM_PROFILE_BASIC_INFO,
  EMPLOYEE_STREAM_PROFILE_TASK_OVERVIEW:
    process.env.REACT_APP_EMPLOYEE_STREAM_PROFILE_TASK_OVERVIEW,
  EMPLOYEE_STREAM_PROFILE_JOB_PREFERENCES:
    process.env.REACT_APP_EMPLOYEE_STREAM_PROFILE_JOB_PREFERENCES,
  EMPLOYEE_STREAM_PROFILE_EDUCATION_HISTORY:
    process.env.REACT_APP_EMPLOYEE_STREAM_PROFILE_EDUCATION_HISTORY,
  EMPLOYEE_STREAM_PROFILE_WORK_EXPERIENCE:
    process.env.REACT_APP_EMPLOYEE_STREAM_PROFILE_WORK_EXPERIENCE,
  EMPLOYEE_STREAM_PROFILE_SKILLS_CHECKLIST:
    process.env.REACT_APP_EMPLOYEE_STREAM_PROFILE_SKILLS_CHECKLIST,
  EMPLOYEE_STREAM_PROFILE_ADDITIONAL_INFO:
    process.env.REACT_APP_EMPLOYEE_STREAM_PROFILE_ADDITIONAL_INFO,
  EMPLOYEE_STREAM_PROFILE_CERTIFICATES_LICENSES:
    process.env.REACT_APP_EMPLOYEE_STREAM_PROFILE_CERTIFICATES_LICENSES,
  EMPLOYEE_STREAM_TASKS: process.env.REACT_APP_EMPLOYEE_STREAM_TASKS,
  EMPLOYEE_STREAM_BASIC_INFO: process.env.REACT_APP_EMPLOYEE_STREAM_BASIC_INFO,
  EMPLOYEE_STREAM_APPLY_COMPLETE_API_URL:
    process.env.REACT_APP_EMPLOYEE_STREAM_APPLY_COMPLETE_API_URL,
  CCA_URL: process.env.REACT_APP_CCA_URL,
  CCN_URL: process.env.REACT_APP_CCN_URL,
  CCA_PROD_URL: process.env.REACT_APP_CCA_URL_PROD_URL,
  CCN_PROD_URL: process.env.REACT_APP_CCN_URL_PROD_URL,
  CCA_URL_REGEX: process.env.REACT_APP_CCA_URL_REGEX,
  CCN_URL_REGEX: process.env.REACT_APP_CCN_URL_REGEX,
  GENERIC_URL: process.env.REACT_APP_GENERIC_URL,
  COMPANY_CCN: {
    id: process.env.REACT_APP_COMPANY_ID_CCN,
    name: process.env.REACT_APP_COMPANY_NAME_CCN,
    code: process.env.REACT_APP_COMPANY_CCN,
    url: process.env.REACT_APP_CCN_URL,
  },
  COMPANY_CCA: {
    id: process.env.REACT_APP_COMPANY_ID_CCA,
    name: process.env.REACT_APP_COMPANY_NAME_CCA,
    code: process.env.REACT_APP_COMPANY_CCA,
    url: process.env.REACT_APP_CCA_URL,
  },
  ERROR_CODES: {
    NO_APPLICANT_ID: process.env.REACT_APP_NO_APPLICANT_ID,
    NO_CANDIDATES: process.env.REACT_APP_NO_CANDIDATES,
  },
  SHARE_LINK_CCN: process.env.REACT_APP_SHARE_LINK_CCN,
  SHARE_LINK_CCA: process.env.REACT_APP_SHARE_LINK_CCA,
  JWT_REDIRECT_SECRET_KEY: process.env.REACT_APP_JWT_REDIRECT_SECRET_KEY,
  JOB_CARD_IMAGE_URI_DOMAIN: process.env.REACT_APP_JOB_CARD_IMAGE_URI_DOMAIN,
  JOB_CARD_DEFAULT_IMAGE: process.env.REACT_APP_JOB_CARD_DEFAULT_IMAGE,
  GEO_LOCATION_RADIUS: process.env.REACT_APP_GEO_LOCATION_RADIUS,
  JOB_CARD_DEFAULT_IMAGE_LG: process.env.REACT_APP_JOB_CARD_DEFAULT_IMAGE_LG,
  JOB_CARD_DEFAULT_IMAGE_SM: process.env.REACT_APP_JOB_CARD_DEFAULT_IMAGE_SM,
  openEnrollmentVideoUrl: "https://content.ccrncloud.com/content/video/openEnrollment/720p.mp4",
  openEnrollmentVideoImg:
    "https://content.ccrncloud.com/content/images/web/icons/openEnrollment.png",
  ABLE_APPLY_BASIC_INFO: process.env.REACT_APP_ABLE_APPLY_BASIC_INFO,
  ABLE_APPLY_EDUCATION_HISTORY: process.env.REACT_APP_ABLE_APPLY_EDUCATION_HISTORY,
  ABLE_APPLY_WORK_HISTORY: process.env.REACT_APP_ABLE_APPLY_WORK_HISTORY,
  ABLE_APPLY_REFERENCES: process.env.REACT_APP_ABLE_APPLY_REFERENCES,
  ABLE_APPLY_SKILL_CHECKLIST: process.env.REACT_APP_ABLE_APPLY_SKILL_CHECKLIST,
  ABLE_APPLY_CERTIFICATIONS_LICENSES: process.env.REACT_APP_ABLE_APPLY_CERTIFICATIONS_LICENSES,
  JOB_COUNT: process.env.REACT_APP_JOB_COUNT,
  TIMEZONE_OFFSET_TOTAL_DAYS: "-330",
  ENABLE_FILTERS: process.env.REACT_APP_ENABLE_FILTERS,
  ENABLE_EXCLUDE_JOBS: process.env.REACT_APP_ENABLE_EXCLUDE_JOBS,
  ENABLE_SAVED_SEARCH: process.env.REACT_APP_ENABLE_SAVED_SEARCH,
  ENABLE_REFERENCES: process.env.REACT_APP_ENABLE_REFERENCES,
  SIGN_UP_URL: process.env.REACT_APP_SIGN_UP_URL,
  FILTERS_TO_COUNT: [
    "shiftTypes",
    "shiftLengths",
    "durations",
    "startDate",
    "jobDetails",
    "salaryMax",
    "facilityNames",
    "shouldFilterByQuickApplyEligibility"
  ],
  REFERENCES_IS_DELETED: process.env.REACT_APP_REFERENCES_IS_DELETED,
  REFERENCES_LEAD_SOURCE: "Reference Survey",
  REFERENCES_UTM_CAMPAIGN: "Default",
  REFERENCE_SURVEY_NAME_REGEX: '^[^0-9~`!@#$%^&*()_+="{}|:;"\\\\/<>?\\[\\]]+$',
  REFER_FRIEND_URL: process.env.REACT_APP_REFER_FRIEND_URL,
  ENABLE_NOTIFICATION: process.env.REACT_APP_ENABLE_NOTIFICATION,
  LOAD_COURIER: process.env.REACT_APP_LOAD_COURIER,
  ENABLE_JOB_DETAILS_FILTER: process.env.REACT_APP_ENABLE_JOB_DETAILS_FILTER,
  ENABLE_MY_TEAM: process.env.REACT_APP_ENABLE_MY_TEAM,
  ENABLE_WORK_HISTORY_AUTOMATION: process.env.REACT_APP_ENABLE_WORK_HISTORY_AUTOMATION,
  ENABLE_QUICK_APPLY: process.env.REACT_APP_ENABLE_QUICK_APPLY,
  ENABLE_EMAIL_VERIFICATION: process.env.REACT_APP_ENABLE_EMAIL_VERIFICATION,
  REFRESH_TOKEN_REVOKE_URL: process.env.REACT_APP_REFRESH_TOKEN_REVOKE_URL,
  WORK_HISTORY_AUTOMATION_XPERIENCE_ID: process.env.REACT_APP_WORK_HISTORY_AUTOMATION_XPERIENCE_ID,
  ENABLE_MOBILE_APP_INFO: process.env.REACT_APP_ENABLE_MOBILE_APP_INFO,
  ENABLE_PAYSTUBS: process.env.REACT_APP_ENABLE_PAYSTUBS,
  ENABLE_CERTIFICATIONS_LICENSES: process.env.REACT_APP_ENABLE_CERTIFICATIONS_LICENSES,
  ENABLE_MY_TASKS: process.env.REACT_APP_ENABLE_MY_TASKS,
  ENABLE_CERTIFICATIONS_LICENSES_WEBCAM:
    process.env.REACT_APP_ENABLE_CERTIFICATIONS_LICENSES_WEBCAM,
  ENABLE_QUICK_APPLY_V2: process.env.REACT_APP_ENABLE_QUICK_APPLY_V2,
  ENABLE_WORK_HISTORY: process.env.REACT_APP_ENABLE_WORK_HISTORY,
  ENABLE_ADD_LEAD_SOURCE: process.env.REACT_APP_ENABLE_ADD_LEAD_SOURCE,
  WORK_EXPERIENCES_IS_DELETED: process.env.REACT_APP_WORK_EXPERIENCES_IS_DELETED,
  ENABLE_PAY_BREAKDOWN: process.env.REACT_APP_ENABLE_PAY_BREAKDOWN,
  ENABLE_MORE_INFO_JOB_DETAILS: process.env.REACT_APP_ENABLE_MORE_INFO_JOB_DETAILS,
  ETHNICITY_AND_GENDER: process.env.REACT_APP_ETHNICITY_AND_GENDER,
  ENABLE_SSN_AND_DOB_FIELDS: process.env.REACT_APP_ENABLE_SSN_AND_DOB_FIELDS,
  DISABLE_SHIFT_TYPE_FILTER: process.env.REACT_APP_DISABLE_SHIFT_TYPE_FILTER,
  ENABLE_HOUSING_DECISION: process.env.REACT_APP_ENABLE_HOUSING_DECISION,
  ENABLE_BENEFITS_AND_INFO_BANNER: process.env.REACT_APP_ENABLE_BENEFITS_AND_INFO_BANNER,
  WEATHER_DATA_BASE_URL: process.env.REACT_APP_WEATHER_DATA_BASE_URL,
  ENABLE_W2_CERIDIAN: process.env.REACT_APP_ENABLE_W2_CERIDIAN,
  ENABLE_SPECIALTIES_DROPDOWN_V2: process.env.REACT_APP_ENABLE_SPECIALTIES_DROPDOWN_V2,
  ENABLE_SEARCH_JOB_LOCATION:process.env.REACT_APP_ENABLE_SEARCH_JOB_LOCATION,
  ENABLE_PII_DATA: process.env.REACT_APP_ENABLE_PII_DATA,
  COMPACT_STATES_URL: process.env.REACT_APP_COMPACT_STATES_URL,
  ENABLE_HIDE_GENDER_AND_ETHNICITY_SECTION: process.env.REACT_APP_HIDE_GENDER_AND_ETHNICITY_SECTION,
  ENABLE_EMAIL_RECRUITER: process.env.REACT_APP_EMAIL_RECRUITER,
  FACILITY_MODAL: process.env.REACT_APP_FACILITY_MODAL,
  ENABLE_FACILITY_FILTER: process.env.REACT_APP_ENABLE_FACILITY_FILTER,
  ENABLE_ELIGIBILITY_METER: process.env.REACT_APP_ELIGIBILITY_METER,
  JOB_SHARE_CAMPAIGN_CODE: process.env.REACT_APP_JOB_SHARE_CAMPAIGN_CODE,
  ENABLE_HOT_JOBS: process.env.REACT_APP_ENABLE_HOT_JOBS,
  MSP_JOBS_COUNT: process.env.REACT_APP_MSP_JOBS_COUNT,
  Dashboard_ADVERTISEMENT : process.env.REACT_APP_QUICK_APPLY_DASHBOARD_ADVERTISEMENT,
  ENABLE_SPECIALTY_GROUPING_FOR_JOB_RESULTS: process.env.REACT_APP_ENABLE_SPECIALTY_GROUPING_FOR_JOB_RESULTS,
  ENABLE_SEARCH_BY_QUICKAPPLY_ELIGIBLE_FILTER: process.env.REACT_APP_ENABLE_SEARCH_BY_QUICKAPPLY_ELIGIBLE_FILTER,
  ENABLE_IS_LEGAL_QUESTION: process.env.REACT_APP_ENABLE_IS_LEGAL_QUESTION,
  ENABLE_DASHBOARD_V3: process.env.REACT_APP_ENABLE_DASHBOARD_V3

};
export default config;
