import dayjs from "dayjs";

export const backgroundHost = "https://d308dt1i66cme4.cloudfront.net/content/images/backgrounds/dashboard";

export interface BackgroundImage {
  url: string;
  time: "AM" | "PM";
}

export const backgroundImages: BackgroundImage[] = [
  { url: "/am/bg-am-lg-1.jpg", time: "AM" },
  { url: "/am/bg-am-lg-2.jpg", time: "AM" },
  { url: "/am/bg-am-lg-3.jpg", time: "AM" },
  { url: "/am/bg-am-lg-4.jpg", time: "AM" },
  { url: "/am/bg-am-lg-6.jpg", time: "AM" },
  { url: "/pm/bg-pm-lg-1.jpg", time: "PM" },
  { url: "/pm/bg-pm-lg-2.jpg", time: "PM" },
  { url: "/pm/bg-pm-lg-3.jpg", time: "PM" },
  { url: "/pm/bg-pm-lg-4.jpg", time: "PM" },
  { url: "/pm/bg-pm-lg-5.jpg", time: "PM" },
  { url: "/pm/bg-pm-lg-6.jpg", time: "PM" },
];

export function getRandomBackgroundImage() {
  const amTimeStart = "06:00";
  const amTimeEnd = "18:00";
  const isAM = dayjs().format("HH:mm") >= amTimeStart && dayjs().format("HH:mm") <= amTimeEnd;
  const imagesFiltered = backgroundImages.filter((bg) => bg.time === (isAM ? "AM" : "PM"));
  const randomImage = imagesFiltered[Math.floor(Math.random() * imagesFiltered.length)];

  return backgroundHost + randomImage.url;
}