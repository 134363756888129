import { useEffect } from "react";

interface IScriptData {
  url: string;
  id: string;
  domain: string;
}

const preconnectDomain = (domain: string) => {
  const linkElmnt = document.createElement("link");
  linkElmnt.rel = "preconnect";
  linkElmnt.href = domain;
  document.head.appendChild(linkElmnt);
};

const attachScriptToBody = (url: string, id: string) => {
  const scriptElmnt = document.createElement("script");
  scriptElmnt.type = "text/javascript";
  scriptElmnt.defer = true;
  scriptElmnt.src = url;
  scriptElmnt.id = id;
  document.body.appendChild(scriptElmnt);
};

const scriptInjector = (data: IScriptData) => {
  const { url, id, domain } = data;

  useEffect(() => {
    const hasScript = document.getElementById(id);

    if (!hasScript) {
      preconnectDomain(domain);
      attachScriptToBody(url, id);
    }
  });
};

export default scriptInjector;
