import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { useEffect, useState } from "react";
import ThankYou from "../../../../imagesV2/application_form_success.svg";
import ReferenceSurveyThankYouForm from "./ReferenceSurveyThankYouForm";
import ReferenceHeader from "./ReferenceHeader";

const useStyles = makeStyles((theme) => ({
  referenceSurveySuccessPageWrapper: {
    "@media (max-width: 768px)": {
      // height: "80vh",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  },
  referenceSurveySuccessContent: {
    margin: "79px auto 79px",
    textAlign: "center",
    boxShadow: "0px 3px 10px #00000017",
    border: "1px solid #E0E0E0",
    borderRadius: "14px",
    width: "30%",
    padding: "35px 25px 100px",
    "@media (max-width: 768px)": {
      width: "100%",
      margin: "0 30px",
      padding: "43px 0px 100px",
      border: "none",
      boxShadow: "none",
    },
    "@media(min-width: 768px) and (max-width: 1024px)": {
      width: "50%",
      boxShadow: "0px 3px 10px #00000017",
      border: "1px solid #E0E0E0",
      padding: "43px 25px",
    },
  },
  "& img": {
    "@media (max-width: 768px)": {
      width: "25%",
    },
  },
  referenceSurveySuccessHeading: {
    fontSize: "2.625rem",
    minHeight: "74px",
    color: theme.palette.colorPrimary,
    margin: "0",
    fontWeight: "bold",
    padding: "14px 0",
    fontFamily: "Nunito Sans",
    "@media (max-width: 768px)": {
      fontSize: "2rem",
    },
  },
  referThankYouFormBoldText: {
    color: theme.palette.colorPrimary,
    fontWeight: "600",
  },
  referenceSurveySuccessMessage: {
    color: "#202124",
    fontSize: "1.1rem",
    fontFamily: "Nunito Sans",
    margin: "0",
  },
  referThankYouFormMessage: {
    color: "#797979",
    fontSize: "1.1rem",
    margin: "0",
    lineHeight: "28px",
    fontFamily: "Nunito Sans",
    "@media(min-width: 1024px) and (max-width: 1280px)": {
      fontSize: "1rem",
    },
    "& a": {
      color: "#797979",
      textDecoration: "underline",
      "&:hover": {
        color: theme.palette.colorSecondary,
        "@media (max-width: 768px)": {
          color: "#797979",
        },
      },
    },
    "@media (max-width: 768px)": {
      fontSize: "0.9rem",
    },
  },
  thankYouImage: {
    maxWidth: "72px",
  },
  thankYouPageWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    margin: "79px 0",
    textAlign: "center",
  },
}));

const ReferenceSurveyThankYou = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [isRedirectionUrlInvalid, setIsRedirectionUrlInvalid] = useState(false);
  const handleThankYouSubmitBtn = (value) => {
    setIsRedirectionUrlInvalid(value);
  };

  return (
    <>
      <ReferenceHeader />
      {isRedirectionUrlInvalid ? (
        <div className={classes.thankYouPageWrapper}>
          <img
            className={classes.thankYouImage}
            src={ThankYou}
            alt={t("referSurvey.referThankYouHeading")}
            title={t("referSurvey.referThankYouHeading")}
          />
          <h3 className={classes.referenceSurveySuccessHeading}>
            {t("referSurvey.referThankYouHeading")}
          </h3>
          <p className={classes.referThankYouFormMessage}>
            {t("referSurvey.referThankYouMessage")}&nbsp;
            <br />
            {t("referSurvey.referThankYouSubText")}&nbsp;
            <a
              className={classes.referThankYouFormHyperlink}
              href="https://www.crosscountry.com"
              target="_blank"
              rel="noreferrer"
            >
              {t("referSurvey.referThankYouHyperlink")}
            </a>
          </p>
        </div>
      ) : (
        <div className={classes.referenceSurveySuccessPageWrapper}>
          <div className={classes.referenceSurveySuccessContent}>
            <img
              src={ThankYou}
              className={classes.thankYouImage}
              alt={t("referSurvey.referThankYouHeading")}
              title={t("referSurvey.referThankYouHeading")}
            />
            <h3 className={classes.referenceSurveySuccessHeading}>
              {t("referSurvey.referThankYouHeading")}
            </h3>
            {/* Refer Thank You Form Content */}
            <p className={classes.referThankYouFormMessage}>
              {t("referSurvey.referThankYouMessage")}&nbsp;
              <br />
              {t("referSurvey.referThankYouSubText")}&nbsp;
              <a
                className={classes.referThankYouFormHyperlink}
                href="https://www.crosscountry.com"
                target="_blank"
                rel="noreferrer"
              >
                {t("referSurvey.referThankYouHyperlink")}
              </a>
              <div>
                <b className={classes.referThankYouFormBoldText}>
                  {t("referSurvey.referThankYouMessageForm")}
                </b>
              </div>
            </p>
            <ReferenceSurveyThankYouForm handleThankYouSubmitBtn={handleThankYouSubmitBtn} />
          </div>
        </div>
      )}
    </>
  );
};

export default ReferenceSurveyThankYou;
