import ReactGA from "react-ga";
import config from "../config";
import { segmentScript } from "../components/common/ThirdPartyScripts/thirdPartyData";
import i18n from "src/translations/i18n"

const globalVariable = {};
const getGlobalVariables = () => globalVariable;

let myJobsData 
const getMyJobsData = () => myJobsData;

let recommendedJobsSourceType 
const getRecommendedJobsSourceType = () => recommendedJobsSourceType;

let profileCompletion = {};
const getProfileCompletion = () => profileCompletion;

const setGlobalVariables = (variables) => {
  const dimensionsToSet = ["1", "2", "3", "4"];
  dimensionsToSet.forEach((dimension) => {
    const dimensionName = `dimension${dimension}`;
    if (variables[String(dimensionName)]) {
      globalVariable[String(dimensionName)] = variables[String(dimensionName)];
    }
  });
};


const init = () => {
  segmentScript();
  // Enable debug mode on the local development environment:  { debug: true }
  ReactGA.initialize(config.googleAnalyticsTrackingId!, { debug: false });
};

const sendEvent = (payload) => {
  ReactGA.event(payload);
};

const sendPageview = (location, dimensions?: any, eventWGlobalVarOnly?: any) => {
  if (eventWGlobalVarOnly || dimensions) {
    const globalGAVariables = getGlobalVariables();
    const eventDimensions = dimensions
      ? { ...dimensions, ...globalGAVariables }
      : globalGAVariables;
    ReactGA.set(eventDimensions);
  }
  ReactGA.pageview(location.pathname);
};

const segmentPageView = () => {
  window.analytics.page();
};

const segmentIdentify = (obj) => {
  if (!obj) return;
  window.analytics.identify(obj.userId, {
    firstName: obj.firstName,
    lastName: obj.lastName,
    email: obj.email,
    phone: obj.phone,
    company: {
      id: obj.company?.id,
      name: obj.company?.name,
    },
    profession: obj?.profession,
    specialties: obj.specialties,
    allocation: {
      firstName: obj.recruiterFname,
      lastName: obj?.recruiterLname,
      emailId: obj.recruiterEmail,
    },
    address: {
      street: obj.streetAddress,
      city: obj.city,
      state: obj.state,
      zip: obj.zip,
    },
    lastSeenDate: obj.lastSeenDate,
  });
};

const fetchMyJobsData = (latestJobStatus) => {
  myJobsData = latestJobStatus;
};

const fetchRecommendedJobsSourceType = (jobSourceType) => {
  recommendedJobsSourceType = jobSourceType;
};

const fetchProfileCompletion = (profileCompletionData) => {
  profileCompletion = profileCompletionData;
};

const memoizeSearchParams = (urlSearchParams: URLSearchParams) => {
  const cache = new Map();

  return (paramName: string): string | null => {
    if (cache.has(paramName)) {
      return cache.get(paramName);
    }
    const paramValue = urlSearchParams.get(paramName);

    // Perform some computation with paramValue here, if needed.
    cache.set(paramName, paramValue);
    return paramValue;
  };
};

const updatingEventPropsWithUTMParams = (eventProps) => {
  // fetching Search Params in a MAP Format
  const searchParams = new URLSearchParams(window.location.search);
  // memoizing search params
  const memoizedSearchParam = memoizeSearchParams(searchParams);
  // assigning assignment status of HCP
  const myJobs = getMyJobsData();
  const perStepPercentage = 20;
  const profileCompletionCount = getProfileCompletion();
  const profileCompletionPercentage  = (profileCompletionCount as number) * perStepPercentage;
  let myJobsStatus;
  switch (myJobs) {
    case i18n.t("assignmentStatus.completed"):
    case null:
      myJobsStatus = i18n.t("assignmentStatus.noAssignment");
      break;
    case i18n.t("assignmentStatus.upcomingAssignment"):
      myJobsStatus = i18n.t("assignmentStatus.approved");
      break;
    default:
      myJobsStatus = myJobs?.toLowerCase();
  }
  const jobSourceType = getRecommendedJobsSourceType();
 // updating event props with UTM Params
  return {
    ...eventProps,
    source: memoizedSearchParam("utm_source"),
    medium: memoizedSearchParam("utm_medium"),
    campaign: memoizedSearchParam("utm_campaign"),
    term: memoizedSearchParam("utm_term"),
    content: memoizedSearchParam("utm_content"),
    assignmentStatus: myJobsStatus || null,
    profileCompletion: profileCompletionPercentage,
    activeTests: { recommendedJobsTest: jobSourceType || null }
  };
};

const segmentTrackEvent = (eventName, eventProps) => {
  const updatedEventProps = updatingEventPropsWithUTMParams(eventProps);
  window.analytics.track(eventName, updatedEventProps);
};

export default {
  init,
  sendEvent,
  sendPageview,
  setGlobalVariables,
  segmentPageView,
  segmentIdentify,
  segmentTrackEvent,
  fetchMyJobsData,
  fetchRecommendedJobsSourceType,
  fetchProfileCompletion
};
