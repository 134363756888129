import { makeStyles } from "@material-ui/core/styles";
import SvgIcon from "@material-ui/core/SvgIcon";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "1.4rem",
    height: "1.2rem"
  },
}));

export default function MidIcon(props) {
  const classes = useStyles();
  const { color } = props;
  return (
    <SvgIcon
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      width="inherit"
      height="inherit"
      viewBox="0 0 1024 1024"
      className={`${classes.root} ${props.className}`}
    >
      <path
        fill={color}
        d="M215.552 494.976c8.056 6.873 18.589 11.054 30.1 11.054 25.685 0 46.507-20.822 46.507-46.507 0-11.542-4.205-22.102-11.166-30.232l0.053 0.063-49.408-49.365c-8.080-6.954-18.674-11.189-30.258-11.189-25.685 0-46.507 20.822-46.507 46.507 0 11.604 4.25 22.216 11.279 30.363l-0.051-0.060z"
      />

      <path
        fill={color}
        d="M806.528 494.976l49.237-49.323c6.978-8.087 11.228-18.699 11.228-30.303 0-25.685-20.822-46.507-46.507-46.507-11.583 0-22.177 4.235-30.319 11.24l0.061-0.051-49.237 49.323c-6.908 8.067-11.113 18.627-11.113 30.169 0 25.685 20.822 46.507 46.507 46.507 11.51 0 22.044-4.181 30.165-11.108l-0.065 0.054z"
      />

      <path
        fill={color}
        d="M511.061 399.573c25.667-0.024 46.464-20.837 46.464-46.507 0-0.015 0-0.030 0-0.045v0.002-93.099c0.014-0.42 0.021-0.913 0.021-1.408 0-25.661-20.803-46.464-46.464-46.464s-46.464 20.803-46.464 46.464c0 0.495 0.008 0.988 0.023 1.48l-0.002-0.072v93.099c0 0.013 0 0.028 0 0.043 0 25.67 20.797 46.482 46.462 46.507h0.002z"
      />

      <path
        fill={color}
        d="M185.813 725.376c0-0.013 0-0.028 0-0.043 0-25.67-20.797-46.482-46.462-46.507h-92.888c-25.708 0-46.549 20.841-46.549 46.549s20.841 46.549 46.549 46.549h92.928c25.667-0.024 46.464-20.837 46.464-46.507 0-0.015 0-0.030 0-0.045v0.002z"
      />

      <path
        fill={color}
        d="M371.669 725.419c0-76.984 62.408-139.392 139.392-139.392s139.392 62.408 139.392 139.392v0c-0.022 0.565-0.034 1.229-0.034 1.896 0 27.802 21.636 50.55 48.988 52.326l0.155 0.008c35.627 0 43.776-35.541 43.776-54.229 0.014-0.936 0.021-2.041 0.021-3.148 0-128.307-104.013-232.32-232.32-232.32s-232.32 104.013-232.32 232.32c0 1.107 0.008 2.212 0.023 3.315l-0.002-0.167c-0.083 1.086-0.13 2.352-0.13 3.629 0 26.944 21.023 48.978 47.561 50.593l0.142 0.007c34.688 0 45.312-34.987 45.312-54.229z"
      />

      <path
        fill={color}
        d="M975.701 678.827h-92.971c-25.708 0-46.549 20.841-46.549 46.549s20.841 46.549 46.549 46.549h92.971c25.708 0 46.549-20.841 46.549-46.549s-20.841-46.549-46.549-46.549v0z"
      />
    </SvgIcon>
  );
}
