import React, { Component } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Container from "react-bootstrap/Container";
import { connect } from "react-redux";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import { isMobile } from "react-device-detect";
import moment from "moment";
import PayrollNoticeV2 from "../Timesheets/PayrollNoticeV2";
import PayrollNavV2 from "../PayrollNavV2";
import Analytics from "../../../../../services/Analytics";
import CustomNavMob from "../../../Navigation/CustomNavMob";
import analyticsSegmentIdentify from "../../../../../services/SegmentIdentify";

/* Payroll Payment Deduction First Table start */
function paymentData(pdDate, pdPeriod, pdDescription, pdPayments) {
  return { pdDate, pdPeriod, pdDescription, pdPayments };
}
const rowspayment = [paymentData("No Items to show", "", "", "", "", "", "")];

/* Payroll Payment  Deduction Second Table End */

class PaymentDeductions extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
    if (this.props.userId && this.props.email) {
      analyticsSegmentIdentify(this.props);
    }
    const event = {
      category: this.props.t("googleAnalytics.category.payroll"),
      action: this.props.t("googleAnalytics.action.openPaymentAndDeductions"),
      label: this.props.t("googleAnalytics.label.paymentAndDeductions"),
    };

    Analytics.sendPageview(this.props.history.location, null, true);
    Analytics.sendEvent(event);
    const segmentEventName = this.props.t("segment.paymentDeductionViewed");
    Analytics.segmentTrackEvent(segmentEventName, null);
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.email !== prevProps.email ||
      this.props.recruiterDetails !== prevProps.recruiterDetails
    ) {
      analyticsSegmentIdentify(this.props);
    }
  }

  renderPaymentTable(assignment) {
    const payPeriods = [];
    let PaymentDeductions = [];
    if (assignment.assignPayPeriods) {
      assignment.assignPayPeriods.forEach((payPeriod) => {
        let singlePayPeriod = {};
        PaymentDeductions = [];

        if (payPeriod.paymentDeductions) {
          payPeriod.paymentDeductions.forEach((payment) => {
            if (payment.type === "PAYMENT") {
              PaymentDeductions.push(payment);
            }
          });
        }
        singlePayPeriod = { ...payPeriod };
        singlePayPeriod.paymentDeductions = Object.assign([], PaymentDeductions);
        if (singlePayPeriod.paymentDeductions.length > 0) {
          payPeriods.push(singlePayPeriod);
        }
      });

      return (
        <TableBody>
          {payPeriods.length === 0 ? (
            <TableBody className="tableRowHover">
              {rowspayment.map((row, key) => (
                <TableRow key={key}>
                  <TableCell component="th" scope="row">
                    {row.pdDate}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          ) : (
            payPeriods
              .sort((a, b) => new Date(b.payPeriodStartDate) - new Date(a.payPeriodEndDate))
              .map((payPeriod) => (
                <TableRow>
                  <TableCell component="th" scope="row">
                    {payPeriod.payCheckDate
                      ? moment.utc(payPeriod.payCheckDate).format("MMMM DD, YYYY")
                      : ""}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {`${
                      payPeriod.payPeriodStartDate
                        ? moment.utc(payPeriod.payPeriodStartDate).format("MMMM DD, YYYY")
                        : ""
                    } - ${
                      payPeriod.payPeriodEndDate
                        ? moment.utc(payPeriod.payPeriodEndDate).format("MMMM DD, YYYY")
                        : ""
                    }`}
                  </TableCell>
                  <TableCell component="th" scope="row" className="descriptionMob">
                    {payPeriod.paymentDeductions
                      ? payPeriod.paymentDeductions.map((paymentDeduction) => (
                          <div>{paymentDeduction.description}</div>
                        ))
                      : ""}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {payPeriod.paymentDeductions
                      ? payPeriod.paymentDeductions.map((paymentDeduction) => (
                          <div>{this.checkDeductionAmount(paymentDeduction.amount)}</div>
                        ))
                      : ""}
                  </TableCell>
                </TableRow>
              ))
          )}
        </TableBody>
      );
    }
  }

  renderDeductionTable(assignment) {
    const payPeriods = [];
    let PaymentDeductions = [];
    if (assignment.assignPayPeriods) {
      assignment.assignPayPeriods.forEach((payPeriod) => {
        PaymentDeductions = [];
        let singlePayPeriod = {};

        if (payPeriod.paymentDeductions) {
          payPeriod.paymentDeductions.forEach((deduction) => {
            if (deduction.type === "DEDUCTION") {
              PaymentDeductions.push(deduction);
            }
          });
        }
        singlePayPeriod = { ...payPeriod };
        singlePayPeriod.paymentDeductions = Object.assign([], PaymentDeductions);
        if (singlePayPeriod.paymentDeductions.length > 0) {
          payPeriods.push(singlePayPeriod);
        }
      });
      return (
        <TableBody>
          {payPeriods.length === 0 ? (
            <TableBody className="tableRowHover">
              {rowspayment.map((row, key) => (
                <TableRow key={key}>
                  <TableCell component="th" scope="row">
                    {row.pdDate}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          ) : (
            payPeriods
              .sort((a, b) => new Date(b.payPeriodStartDate) - new Date(a.payPeriodEndDate))
              .map((payPeriod) => (
                <TableRow>
                  <TableCell component="th" scope="row">
                    {payPeriod.payCheckDate
                      ? moment.utc(payPeriod.payCheckDate).format("MMMM DD, YYYY")
                      : ""}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {`${
                      payPeriod.payPeriodStartDate
                        ? moment.utc(payPeriod.payPeriodStartDate).format("MMMM DD, YYYY")
                        : ""
                    } - ${
                      payPeriod.payPeriodEndDate
                        ? moment.utc(payPeriod.payPeriodEndDate).format("MMMM DD, YYYY")
                        : ""
                    }`}
                  </TableCell>
                  <TableCell component="th" scope="row" className="descriptionMob">
                    {payPeriod.paymentDeductions
                      ? payPeriod.paymentDeductions.map((paymentDeduction) => (
                          <div>{paymentDeduction.description}</div>
                        ))
                      : ""}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {payPeriod.paymentDeductions
                      ? payPeriod.paymentDeductions.map((paymentDeduction) => (
                          <div>{this.checkDeductionAmount(paymentDeduction.amount)}</div>
                        ))
                      : ""}
                  </TableCell>
                </TableRow>
              ))
          )}
        </TableBody>
      );
    }
  }

  checkDeductionAmount(amount) {
    let amt;
    if (amount) {
      amt = amount.toFixed(2);
      if (amt < 0) amt = amt.replace("-", "-$");
      else amt = `$${amt}`;
      return amt;
    }
    amt = "$0.00";
    return amt;
  }

  getHeading(username, assignmentSize) {
    const paymentDeductionHeading = `<h3 class="titlepaystub"><span class="capitalize">${username}</span>, you have ${assignmentSize} assignments showing!</h3>`;
    return paymentDeductionHeading;
  }

  render() {
    const { assignments, isAssignmentEmpty, username, t } = this.props;
    let heading;
    if (isAssignmentEmpty) heading = this.getHeading(username, 0);
    else if (assignments) heading = this.getHeading(username, assignments.assignments.length);
    else heading = this.getHeading(username, 0);

    return (
      <Container fluid>
        <Row>
          <div id="paymentDeductionPg" className="payRollNavMargin">
            <div className="payrollNavWrap">
              {isMobile ? <CustomNavMob type="payroll" /> : <PayrollNavV2 />}
            </div>
            <div className="mainPaystub">
              <Container>
                <div dangerouslySetInnerHTML={{ __html: heading }} />
                {isAssignmentEmpty ? (
                  <div className="payDeductionNoRec">
                    {" "}
                    {t("payroll.paymentDeduction.noRecordsLabel")}
                  </div>
                ) : assignments ? (
                  assignments.assignments.map((assignment) => (
                    <>
                      <div className="payDeduction deductionTblMrgin">
                        <Row>
                          <Col sm={12} md={12} lg={12} xs={12}>
                            <div className="paymentDeduct">
                              {t("payroll.paymentDeduction.paymentsLabel")}
                            </div>
                            <div>
                              <b>
                                {" "}
                                {`${assignment.hospital.name}, ${assignment.hospital.city} ${assignment.hospital.state}`}
                              </b>
                            </div>
                            <div className="payDateText">
                              {`${
                                assignment.assignmentStartDate
                                  ? moment
                                      .utc(assignment.assignmentStartDate)
                                      .format("MMMM DD, YYYY")
                                  : ""
                              } - ${
                                assignment.assignmentEndDate
                                  ? moment.utc(assignment.assignmentEndDate).format("MMMM DD, YYYY")
                                  : ""
                              } (${assignment.weeks} Weeks)`}
                            </div>
                          </Col>
                        </Row>
                      </div>
                      <Row className="paystubtable">
                        <Col sm={12} md={12} lg={12} xs={12}>
                          <TableContainer component={Paper} className="payDetails payStubDetails">
                            <Table
                              aria-label="customized table"
                              className="payDeskTable"
                              id="payTable"
                            >
                              <TableHead>
                                <TableRow className="payheading">
                                  <TableCell>{t("payroll.paymentDeduction.payDate")}</TableCell>
                                  <TableCell>{t("payroll.paymentDeduction.payPeriod")}</TableCell>
                                  <TableCell>{t("payroll.paymentDeduction.description")}</TableCell>
                                  <TableCell>{t("payroll.paymentDeduction.payments")}</TableCell>
                                </TableRow>
                              </TableHead>
                              {this.renderPaymentTable(Object.assign([], assignment))}
                            </Table>
                          </TableContainer>
                        </Col>
                      </Row>
                      <div className="payDeduction deductionTblMrgin">
                        <Row>
                          <Col sm={12} md={12} lg={12} xs={12}>
                            <div className="paymentDeduct">
                              {t("payroll.paymentDeduction.deductionLabel")}
                            </div>
                            <div>
                              <b>
                                {" "}
                                {`${assignment.hospital.name}, ${assignment.hospital.city} ${assignment.hospital.state}`}
                              </b>
                            </div>
                            <div className="payDateText">
                              {`${
                                assignment.assignmentStartDate
                                  ? moment
                                      .utc(assignment.assignmentStartDate)
                                      .format("MMMM DD, YYYY")
                                  : ""
                              } - ${
                                assignment.assignmentEndDate
                                  ? moment.utc(assignment.assignmentEndDate).format("MMMM DD, YYYY")
                                  : ""
                              } (${assignment.weeks} Weeks)`}
                            </div>
                          </Col>
                        </Row>
                      </div>
                      <Row className="paystubtable">
                        <Col sm={12} md={12} lg={12} xs={12}>
                          <TableContainer component={Paper} className="payDetails payStubDetails">
                            <Table
                              aria-label="customized table"
                              className="payDeskTable"
                              id="payTable"
                            >
                              <TableHead>
                                <TableRow className="payheading">
                                  <TableCell>{t("payroll.paymentDeduction.payDate")}</TableCell>
                                  <TableCell>{t("payroll.paymentDeduction.payPeriod")}</TableCell>
                                  <TableCell>{t("payroll.paymentDeduction.description")}</TableCell>
                                  <TableCell>{t("payroll.paymentDeduction.deductions")}</TableCell>
                                </TableRow>
                              </TableHead>
                              {this.renderDeductionTable(Object.assign([], assignment))}
                            </Table>
                          </TableContainer>
                        </Col>
                      </Row>
                    </>
                  ))
                ) : (
                  <div className="payDeductionNoRec">
                    {" "}
                    {t("payroll.paymentDeduction.noRecordsLabel")}
                  </div>
                )}
                <PayrollNoticeV2 />
              </Container>
            </div>
          </div>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  assignments: state.payroll.assignments,
  isAssignmentEmpty: state.payroll.isAssignmentEmpty,
  username: state.auth.firstName,
  userId: state.auth.userId,
  applicantId: state.candidate.applicantId,
  company: state.company.company,
  email: state.auth.email,
  firstName: state.auth.firstName,
  lastName: state.auth.lastName,
  phone: state.auth.phone,
  recruiterDetails: state.consultant.recruiterDetails,
  onboardingSpecialtiesAndEducation: state.onBoarding.specialtiesAndEducation,
  onboardingBasicInfo: state.onBoarding.basicInfo,
});

export default withRouter(connect(mapStateToProps, null)(withTranslation()(PaymentDeductions)));
