import { Theme, withStyles } from "@material-ui/core/styles";
import BaseButton from "./BaseButton";

const SuccessButton = withStyles((theme) => {
  const themeWithProps = theme as Theme & {
    palette: { colorSuccess: string; colorGreyBG: string };
  };
  return {
    root: {
      display: "flex",
      backgroundColor: "#FFFFFF",
      fontWeight: "bold",
      padding: "4px 10px",
      color: themeWithProps.palette.colorSuccess,
      border: `1px solid${themeWithProps.palette.colorGreyBG}`,
      "&:hover": {
        boxShadow: "none",
        backgroundColor: "#F2F2F7",
      },
    },
  };
})(BaseButton);

export default SuccessButton;
