import API from "../../../services/Api";

export const FETCH_FACILITY_USING_POS_RAD_REQ = "FETCH_FACILITY_USING_POS_RAD_REQ";
export const fetchFacilitiesUsingPosRadReq = (payload) => ({
  type: FETCH_FACILITY_USING_POS_RAD_REQ,
  payload,
});

export const RESPONSE_FACILITIES_SUCCESS = "RESPONSE_FACILITIES_SUCCESS";
export const responseFacilitiesSuccess = (payload) => ({
  type: RESPONSE_FACILITIES_SUCCESS,
  payload,
});

export const RESPONSE_FACILITIES_FAILURE = "RESPONSE_FACILITIES_FAILURE";
export const responseFacilitiesFailure = (payload) => ({
  type: RESPONSE_FACILITIES_FAILURE,
  payload,
});

export const fetchFacilitiesUsingLatLngRadius = (reqObj) => (dispatch) => {
  dispatch(fetchFacilitiesUsingPosRadReq());
  API.get("facilities/search", {
    params: {
      latitude: reqObj.position.lat,
      longitude: reqObj.position.lng,
      size: reqObj.size ? reqObj.size : 10,
      page: reqObj.page ? reqObj.page : 1,
      distance: `${reqObj.distance ? reqObj.distance : 50}mi`,
    },
  })
    .then((response) => {
      if (response.status === 200) {
        const facilities = {
          response: response.data,
          request: reqObj,
        };
        dispatch(responseFacilitiesSuccess(facilities));
      }
    })
    .catch((error) => {
      dispatch(responseFacilitiesFailure(error.message));
    });
};

export const FETCH_FACILITY_SEARCH_REQ_OBJECT = "FETCH_FACILITY_SEARCH_REQ_OBJECT";
export const facilitySearchReqObj = (payload) => ({
  type: FETCH_FACILITY_SEARCH_REQ_OBJECT,
  payload,
});

export const FACILITIES_UPDATE_CRITERIA = "FACILITIES_UPDATE_CRITERIA";
export const facilitiesUpdateCriteria = (payload) => ({
  type: FACILITIES_UPDATE_CRITERIA,
  payload,
});

export const FETCH_CANDIDATE_FACILITIES_REQUEST = "FETCH_CANDIDATE_FACILITIES_REQUEST";
export const fetchCandidateFacilitiesRequest = (payload) => ({
  type: FETCH_CANDIDATE_FACILITIES_REQUEST,
  payload,
});

export const FETCH_CANDIDATE_FACILITIES_SUCCESS = "FETCH_CANDIDATE_FACILITIES_SUCCESS";
export const fetchCandidateFacilitiesSuccess = (payload) => ({
  type: FETCH_CANDIDATE_FACILITIES_SUCCESS,
  payload,
});

export const FETCH_CANDIDATE_FACILITIES_FAILURE = "FETCH_CANDIDATE_FACILITIES_FAILURE";
export const fetchCandidateFacilitiesFailure = (payload) => ({
  type: FETCH_CANDIDATE_FACILITIES_FAILURE,
  payload,
});

export const addRemoveFacilityPreference = (reqObj, candidateId) => (dispatch) => {
  API.post(`/candidates/${candidateId}/preferences`, JSON.stringify(reqObj), {
    headers: {
      "content-type": "application/json",
    },
  })
    .then((response) => {
      if (response.data.statusCode === 200) {
        dispatch(fetchCandidateFacilitiesSuccess(reqObj));
      }
    })
    .catch((error) => {
      console.error("Error:", error);
    });
};

export const fetchCandidatePreferences = (reqId) => (dispatch) => {
  dispatch(fetchCandidateFacilitiesRequest());
  API.get(`/candidates/${reqId}`)
    .then((response) => {
      dispatch(fetchCandidateFacilitiesSuccess(response.data));
    })
    .catch((error) => {
      dispatch(fetchCandidateFacilitiesFailure(error.message));
    });
};
