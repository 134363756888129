import React, { Component } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import Button from "@material-ui/core/Button";
import { withTranslation } from "react-i18next";
import Tooltip from "@material-ui/core/Tooltip";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import filter from "lodash/filter";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import dateFormat from "dateformat";
import { withStyles } from "@material-ui/core/styles";
import config from "../../../../config";
import { SKILLS_CHECKLIST } from "../../../../constants/onboardingEventName";
import analyticsSegmentIdentify from "../../../../services/SegmentIdentify";
import { TertiaryButton, PrimaryButton, SecondaryButton } from "../../Buttons";
import Analytics from "../../../../services/Analytics";
import {
  fetchSkillChecklistByChecklistId,
  updateSkillChecklistStatus,
  saveOptionalSkillChecklist,
  deleteSkillChecklistById,
  fetchSkillChecklists,
  saveSkillChecklistSelected,
  saveSkillChecklistGroupCompleteStatus,
  updateSelectedSkillCompleteFlag,
  saveSkillChecklistGroupSkills,
  updateCompleteFlag,
  updateActiveFlag,
} from "../../../../redux/onBoarding/action";
import {
  WORK_EXP_PAGE,
  SKILL_CHECKLIST_DETAILS_PAGE,
  DASHBOARD,
  EDU_SPEC_PAGE,
  CERTIFICATIONS_LICENSES,
  REFERENCES_PAGE,
  BASIC_INFO_PAGE,
} from "../../../../router/routes";
import OnBoardingOptionalSkill from "./OnBoardingOptionalSkill";
import SnackbarReturnToSource from "src/components/common/SnackbarReturnToSource/SnackbarReturnToSource.tsx";
import MyProfileNavWrapper from "../MyProfileNavWrapper";
import { GET_SKILL_CHECKLIST } from "src/services/GraphQL/graph.query";
import { graphqlRequest as request } from "src/services/GraphQL/GraphQL";

const perStepPercentage = 20;

const styles = (theme) => ({
  outlineBtnColor: {
    border: "1px solid #707070 !important",
    color: "#707070",
  },
  editButton: {
    "&:hover": {
      background: `${theme.palette.colorPrimary} !important`,
      color: "#fff",
    },
    "& span:hover": {
      background: `${theme.palette.colorPrimary} !important`,
      color: "#fff",
    },
  },
  nextBtnMarginleft: {
    marginLeft: "auto",
  },
});

class OnBoardingYourSkills extends Component {
  constructor(props) {
    super(props);
    this.state = {
      requiredChecklists: [],
      optionalChecklists: [],
      optionalDropDownValues: [],
      isAddBtnClicked: false,
      openRate: false,
      deleteRowIndex: 0,
      skillChecklistName: null,
      sendAnalyticsEvent: true,
      openModal: false,
      isPageCompleted: false,
      source: "",
      isUpdateNowClicked: false,
      image: "",
    };
    this.setInitialValues();
  }

  async componentWillMount() {
    window.scrollTo(0, 0);
    this.setState({
      source: this.props?.history?.location?.state?.source,
      isUpdateNowClicked: this.props?.history?.location?.state?.isUpdateNowClicked,
      image: this.props?.history?.location?.state?.image,
    });
    this.setInitialValues();
    if (this.props.userId && this.props.email) {
      analyticsSegmentIdentify(this.props);
    }
    const profileProgress = this.profileProgressInPercentage();
    const segmentEventName = this.props.t("segment.profileViewed");
    const segmentEventProps = {
      section: this.props.t("myProfileSubNav.skillChecklist"),
      progress: profileProgress,
      origin: document.referrer,
    };
    Analytics.segmentTrackEvent(segmentEventName, segmentEventProps);
  }

  profileProgressInPercentage() {
    let completedPercentage = 0;
    let noOfStepsCompleted = 0;
    for (const prop in this.props.onBoardingCompletedSteps) {
      if (Object.prototype.hasOwnProperty.call(this.props.onBoardingCompletedSteps, prop)) {
        noOfStepsCompleted = noOfStepsCompleted + 1;
      }
    }

    completedPercentage = noOfStepsCompleted * perStepPercentage;
    return completedPercentage;
  }

  async componentDidUpdate(prevProps) {
    if (
      this.props.email !== prevProps.email ||
      this.props.recruiterDetails !== prevProps.recruiterDetails
    ) {
      analyticsSegmentIdentify(this.props);
    }
    if (
      this.props.onboardingSpecialtiesAndEducation !== prevProps.onboardingSpecialtiesAndEducation
    ) {
      this.setInitialValues();
    }
  }

  trackEvent(event, props) {
    const dimensions = {
      dimension9: props.candidateSpecialties,
    };
    Analytics.sendPageview(props.history.location, dimensions);
    Analytics.sendEvent(event);
    this.setState({ sendAnalyticsEvent: false });
  }

  setInitialValues = async () => {
    const onBoardingSpecialties = this.props.onboardingSpecialtiesAndEducation
      ? this.props.onboardingSpecialtiesAndEducation.specialty
        ? this.props.onboardingSpecialtiesAndEducation.specialty
        : null
      : null;
    const profession =
      onBoardingSpecialties && onBoardingSpecialties.profession
        ? onBoardingSpecialties.profession
        : "";
    const primarySpecialty =
      onBoardingSpecialties && onBoardingSpecialties.primarySpecialty
        ? onBoardingSpecialties.primarySpecialty
        : "";
    const skillChecklistReqObj = {
      email: this.props.email,
      companyId: parseInt(this.props.company.id),
      professionLongCode: profession,
      specialtyLongCode: primarySpecialty,
    };
    await this.props.fetchSkillChecklists(skillChecklistReqObj).then(
      (response) => {
        this.setState({
          requiredChecklists:
            this.props.onBoardingSkillChecklists && this.props.onBoardingSkillChecklists.length > 0
              ? filter(
                  this.props.onBoardingSkillChecklists,
                  (o) => o.required && o.required !== "false"
                )
              : [],
          optionalChecklists:
            this.props.onBoardingSkillChecklists && this.props.onBoardingSkillChecklists.length > 0
              ? filter(
                  this.props.onBoardingSkillChecklists,
                  (o) => o.required === "false" && o.id !== null
                )
              : [],

        });
      },
      (error) => {}
    );
    await request(GET_SKILL_CHECKLIST).then(
      (response) => {
        this.setState({
          optionalDropDownValues:
            response?.getSkillschecklists?.skillschecklists &&
            response?.getSkillschecklists?.skillschecklists.length > 0
              ? response?.getSkillschecklists?.skillschecklists
              : this.props.onBoardingOptionalSkillChecklists &&
                this.props.onBoardingOptionalSkillChecklists.length > 0
              ? this.props.onBoardingOptionalSkillChecklists
              : [],
        });
      },
      (error) => {
        this.setState({
          optionalDropDownValues:
            this.props.onBoardingOptionalSkillChecklists &&
            this.props.onBoardingOptionalSkillChecklists.length > 0
              ? this.props.onBoardingOptionalSkillChecklists
              : [],
        });
      }
    );
    const requiredAndOptionalChecklist = [
      ...this.state.requiredChecklists,
      ...this.state.optionalChecklists,
    ];
    if (requiredAndOptionalChecklist.length > 0 && this.state.optionalDropDownValues.length > 0) {
      let filterDropDownValuesArray = requiredAndOptionalChecklist
        .map((optionalSkill) =>
          filter(this.state.optionalDropDownValues, {
            name: optionalSkill.name,
          })
        )
        .flat();
      filterDropDownValuesArray = this.state.optionalDropDownValues.filter(
        (val) => !filterDropDownValuesArray.includes(val)
      );
      await this.setState({
        optionalDropDownValues: filterDropDownValuesArray.flat(),
      });
    }
  };

  handleBackBtnClick = async () => {
    const { history } = this.props;
    this.props.updateActiveFlag(3);
    history.push(REFERENCES_PAGE);
  };

  handleNavigation(skillChecklistSelected) {
    const completedGroups = [];
    const skillChecklistGroupSkills = [];
    if (skillChecklistSelected.groups) {
      let isSkillChecklistCompleted = true;
      for (const group of skillChecklistSelected.groups) {
        let isGroupCompleted = true;
        for (const skill of group.skills) {
          if (skill.value !== null) {
            skillChecklistGroupSkills.push({
              atsId: skill.id,
              name: skill.name,
              value: skill.value,
            });
          } else {
            isGroupCompleted = false;
          }
        }
        if (isGroupCompleted && !completedGroups.includes(group.name)) {
          completedGroups.push(group.name);
        }
      }
      isSkillChecklistCompleted = completedGroups.length === skillChecklistSelected.groups.length;
      this.props.saveSkillChecklistGroupCompleteStatus(completedGroups);
      this.props.saveSkillChecklistSelected(skillChecklistSelected);
      this.props.saveSkillChecklistGroupSkills(skillChecklistGroupSkills);
      const isSkillComplete = skillChecklistSelected.complete
        ? skillChecklistSelected.complete
        : !!isSkillChecklistCompleted;
      this.props.updateSelectedSkillCompleteFlag(isSkillComplete);
    }
    if (!skillChecklistSelected.complete) {
      const event = {
        category: this.props.t("googleAnalytics.category.onboarding"),
        action: this.props.t("googleAnalytics.action.startSkillsChecklist"),
        label: this.props.t("googleAnalytics.label.skillsChecklist"),
      };
      this.trackEvent(event, this.props);
    }
    const { history } = this.props;
    history.push(SKILL_CHECKLIST_DETAILS_PAGE, {
      source: this.state.source,
      isUpdateNowClicked: this.state.isUpdateNowClicked,
      image: this.state.image,
    });
  }

  checkSkillChecklistCompleteStatus = (skillChecklistAdded) => {
    const skillChecklistAddedLocal = skillChecklistAdded;
    const skillChecklists =
      this.props.onBoardingSkillChecklists && this.props.onBoardingSkillChecklists.length > 0
        ? filter(this.props.onBoardingSkillChecklists, (o) => o.id !== null)
        : [];
    const optionalSkillChecklists = [];
    if (skillChecklists.length > 0) {
      skillChecklists.forEach((skillChecklist) => {
        if (skillChecklist.required === "0") {
          if (skillChecklist.complete) {
            this.setState({ isPageCompleted: true });
            return true;
          }
          return false;
        }
        optionalSkillChecklists.push(skillChecklist);

        const isOptionalSkillsCompleted = optionalSkillChecklists.every(
          (skillChecklist) => skillChecklist.complete
        );
        if (skillChecklistAddedLocal.complete && isOptionalSkillsCompleted) {
          this.setState({ isPageCompleted: true });
          return true;
        }
        return false;
      });
    } else {
      return false;
    }
  };

  addOptionalSkills = async () => {
    await this.setState({ isAddBtnClicked: true });
    const selectedOptionalSkillsArray = this.props.selectedOptionalSkills
      .map((optionalSkill) => filter(this.state.optionalDropDownValues, { name: optionalSkill }))
      .flat();
    for (let i = 0; i < selectedOptionalSkillsArray.length; i++) {
      const now = new Date();
      await this.props.fetchSkillChecklistByChecklistId(selectedOptionalSkillsArray[i].id).then(
        (response) => {
          const updatedResponse = {
            ...this.props.skillChecklistById,
            required: "false",
            created: dateFormat(now, "yyyy-mm-dd HH:MM:ss"),
            lastUpdated: dateFormat(now, "yyyy-mm-dd HH:MM:ss"),
          };
          const isSkillChecklistPageComplete =
            this.checkSkillChecklistCompleteStatus(updatedResponse);
          const saveChecklistReqObj = {
            event: SKILLS_CHECKLIST,
            candidateId: this.props.candidateId,
            email: this.props.email,
            companyId: parseInt(this.props.company.id),
            sqsEvent: true,
            profile: {
              meta: {
                lastVisitedPage: "4",
                completedSections: {
                  basicInfo: !!this.props.onBoardingCompletedSteps[0],
                  specialtiesAndEducation: !!this.props.onBoardingCompletedSteps[1],
                  workExperience: !!this.props.onBoardingCompletedSteps[2],
                  skillsChecklists: !!this.state.isPageCompleted,
                  references: !!this.props.onBoardingCompletedSteps[3],
                },
              },
              skillsChecklist: updatedResponse,
            },
          };
          this.props.saveOptionalSkillChecklist(saveChecklistReqObj, this.props.onboardingBasicInfo).then(
            (response) => {
              this.updateCompleteFlagStatus();
            },
            (error) => {
              console.error("Error while fetching skillchecklist by id:", error);
            }
          );
          this.setState((preState) => ({
            optionalChecklists: preState.optionalChecklists.concat(updatedResponse),
          }));
        },
        (error) => {
          console.error("Error while saving skillchecklist:", error);
        }
      );
    }
    await this.setState({ isAddBtnClicked: false });
    if (this.state.optionalChecklists.length > 0) {
      let filterDropDownValuesArray = this.state.optionalChecklists
        .map((optionalSkill) =>
          filter(this.state.optionalDropDownValues, {
            name: optionalSkill.name,
          })
        )
        .flat();
      filterDropDownValuesArray = this.state.optionalDropDownValues.filter(
        (val) => !filterDropDownValuesArray.includes(val)
      );
      await this.setState({
        optionalDropDownValues: filterDropDownValuesArray.flat(),
      });
    }
  };

  deleteOptionalSkills = async (index) => {
    const filteredArray = this.state.optionalChecklists.filter((_, i) => i !== index);
    const deletedArray = this.state.optionalChecklists.filter((_, i) => i === index);
    const deleteSkillReqObj = {
      email: this.props.email,
      companyId: parseInt(this.props.company.id),
      skillCheckListId: deletedArray[0].id,
    };
    await this.props.deleteSkillChecklistById(deleteSkillReqObj, this.props.onboardingBasicInfo).then(
      (response) => {
        this.setState((preState) => ({
          openRate: false,
          deleteRowIndex: 0,
          skillChecklistName: null,
          optionalChecklists: filteredArray,
          optionalDropDownValues: preState.optionalDropDownValues.concat(deletedArray),
        }));
        this.updateCompleteFlagStatus();
      },
      (error) => {
        console.error("Error while deleting skillchecklist:", error);
      }
    );
  };

  updateCompleteFlagStatus() {
    const { onBoardingCompletedSteps } = this.props;
    if (
      this.props.onboardingSkillChecklistInformation !== undefined &&
      this.props.onboardingSkillChecklistInformation &&
      this.props.onboardingSkillChecklistInformation.onboarding
    ) {
      if (
        this.props.onboardingSkillChecklistInformation.onboarding.meta.completedSections
          .skillsChecklists
      ) {
        onBoardingCompletedSteps[4] = true;
        this.props.updateCompleteFlag(onBoardingCompletedSteps);
      } else {
        delete onBoardingCompletedSteps[4];
        this.props.updateCompleteFlag(onBoardingCompletedSteps);
      }
    }
  }

  handleDoneOrNextBtnClick(redirection) {
    const {
      onboardingSaveSkillChecklistResponse,
      updateCompleteFlag,
      t,
      onBoardingCompletedSteps,
    } = this.props;
    const onBoardingCompletedStepsFinal = onBoardingCompletedSteps;
    if (onboardingSaveSkillChecklistResponse?.onboarding) {
      const { onboarding } = onboardingSaveSkillChecklistResponse;
      if (onboarding.meta.completedSections.skillsChecklists) {
        onBoardingCompletedStepsFinal[4] = true;
        updateCompleteFlag(onBoardingCompletedStepsFinal);
        const event = {
          category: t("googleAnalytics.category.onboarding"),
          action: t("googleAnalytics.action.completeApplication"),
          label: t("googleAnalytics.label.application"),
        };
        this.trackEvent(event, this.props);
      } else if (onboarding.meta.completedSections.skillsChecklists) {
        onBoardingCompletedStepsFinal[4] = true;
        updateCompleteFlag(onBoardingCompletedStepsFinal);
        const event = {
          category: t("googleAnalytics.category.onboarding"),
          action: t("googleAnalytics.action.completeApplication"),
          label: t("googleAnalytics.label.application"),
        };
        this.trackEvent(event, this.props);
      }
    }
    const onboardingStepsLinks = [
      BASIC_INFO_PAGE,
      EDU_SPEC_PAGE,
      WORK_EXP_PAGE,
      REFERENCES_PAGE,
      SKILL_CHECKLIST_DETAILS_PAGE,
    ];
    let index = 0;
    for (let i = 0; i <= 5; i++) {
      if (onBoardingCompletedSteps[i] === undefined) {
        index = i;
        break;
      }
    }
    const { history } = this.props;
    if (redirection === "Done") {
      history.push(DASHBOARD);
    } else {
      history.push(onboardingStepsLinks[index]);
    }
  }

  openDeleteModal(index, skillChecklistName) {
    if (!this.state.openRate) {
      this.setState({
        openRate: !this.state.openRate,
        deleteRowIndex: index,
        skillChecklistName,
      });
    }
  }

  closePopover(e) {
    this.setState({
      openRate: false,
      deleteRowIndex: 0,
      skillChecklistName: null,
    });
  }

  hasAtLeastThreeSteps() {
    const { onBoardingCompletedSteps } = this.props;
    return Object.keys(onBoardingCompletedSteps || {}).length >= 5;
  }

  shouldRenderButton() {
    const { onBoardingCompletedSteps, classes, t } = this.props;
    const onboardingStepsValues = Object.values(onBoardingCompletedSteps || {});
    const skillsChecklistStep = onBoardingCompletedSteps[4];
    const everyStepCompleted =
      onboardingStepsValues.length === 5 && onboardingStepsValues.every((value) => value === true);
    const isSkillChecklistOnlyItemLeftToComplete =
      !everyStepCompleted &&
      onboardingStepsValues.length === 4 &&
      skillsChecklistStep === undefined;

    return (
      <SecondaryButton
        className={classes.nextBtnMarginleft}
        size="medium"
        onClick={() =>
          this.handleDoneOrNextBtnClick(
            everyStepCompleted || isSkillChecklistOnlyItemLeftToComplete ? "Done" : "Next"
          )
        }
      >
        {t(
          everyStepCompleted || isSkillChecklistOnlyItemLeftToComplete
            ? "onBoarding.skillChecklist.completeAppBtnLbl"
            : "onBoarding.basicInfo.btnLabel"
        )}
      </SecondaryButton>
    );
  }

  render() {
    return (
      <div>
        {this.state.isUpdateNowClicked && (
          <div className="returnToSourceWrapper">
            <SnackbarReturnToSource
              topRight
              message={this.props.t("QuickApplyCard.QuickApplyBackToJobDetail")}
              source={this.state.source}
              image={this.state.image}
            />
          </div>
        )}
        {/*  My Profile Navigation */}
        <Container fluid className="myProfileWrap">
          <Row>
            <MyProfileNavWrapper />
            <div className="myProfilePageWrap">
              <Container fluid className="" />
              <Container className="onBYourSkillWrap onBFormBGMobile">
                <Row>
                  <Col sm={12} xs={12} md={12} lg={12} className="onBSkillCheckBanner" />
                </Row>
                <div className="d-flex">
                  <OnBoardingOptionalSkill
                    optionalDropDownValues={this.state.optionalDropDownValues}
                    isAddBtnClicked={this.state.isAddBtnClicked}
                    selectedOptionalSkills={this.props.selectedOptionalSkills}
                    addOptionalSkills={this.addOptionalSkills.bind(this)}
                  />
                </div>
                <div className="onBMainTxtField">
                  <Row>
                    <Col sm={12} xs={12} md={12} lg={12}>
                      {this.state.requiredChecklists.length > 0 ? (
                        <div>
                          <Row>
                            <Col sm={12} xs={12} md={12} lg={12}>
                              <div className="onBSubHeading">
                                {this.props.t("onBoarding.skillChecklist.requiredSkillHeading")}
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col sm={12} md={12} lg={12} xs={12}>
                              <br />
                              <Table id="OnBSkill" className="onBSkillsTable">
                                <Thead>
                                  <Tr className="onBTableHeadSkill">
                                    <Th style={{ width: "10%" }}>
                                      {this.props.t("onBoarding.skillChecklist.statusLabel")}
                                    </Th>
                                    <Th style={{ width: "40%" }}>
                                      {this.props.t("onBoarding.skillChecklist.checklistLabel")}
                                    </Th>
                                    <Th style={{ width: "20%" }}>
                                      {this.props.t("onBoarding.skillChecklist.typeLabel")}
                                    </Th>
                                    <Th style={{ width: "20%" }}>
                                      {this.props.t("onBoarding.skillChecklist.actionLabel")}
                                    </Th>
                                    <Th style={{ width: "10%" }} />
                                  </Tr>
                                </Thead>
                                <Tbody className="tableRowHover">
                                  {this.state.requiredChecklists ? (
                                    this.state.requiredChecklists
                                      .sort((a, b) =>
                                        a.name.toLowerCase() > b.name.toLowerCase()
                                          ? 1
                                          : b.name.toLowerCase() > a.name.toLowerCase()
                                          ? -1
                                          : 0
                                      )
                                      .map((skillChecklist) =>
                                        skillChecklist.required ? (
                                          <Tr className="onBTableCellSkill">
                                            <Td style={{ width: "10%" }}>
                                              <div
                                                className={
                                                  skillChecklist.complete
                                                    ? "onBCompleteStatus"
                                                    : "onBIncompleteStatus"
                                                }
                                              >
                                                {skillChecklist.complete
                                                  ? this.props.t(
                                                      "onBoarding.skillChecklist.completeLabel"
                                                    )
                                                  : this.props.t(
                                                      "onBoarding.skillChecklist.incompleteLabel"
                                                    )}{" "}
                                              </div>
                                            </Td>
                                            <Td style={{ width: "40%" }}>{skillChecklist.name}</Td>
                                            <Td style={{ width: "20%" }}>
                                              {this.props.t(
                                                "onBoarding.skillChecklist.requiredLabel"
                                              )}
                                            </Td>
                                            <Td style={{ width: "20%" }}>
                                              <Tooltip
                                                title="View Documents"
                                                aria-label="View Documents"
                                                placement="top"
                                              >
                                                <span>
                                                  {skillChecklist.complete ? (
                                                    <Button
                                                      className="onBViewIcon"
                                                      onClick={this.handleNavigation.bind(
                                                        this,
                                                        skillChecklist
                                                      )}
                                                    >
                                                      <div>
                                                        <svg
                                                          id="view_document_alt-24px"
                                                          data-name="view document_alt-24px"
                                                          xmlns="http://www.w3.org/2000/svg"
                                                          width="24"
                                                          height="24"
                                                          viewBox="0 0 24 24"
                                                        >
                                                          <path
                                                            id="file"
                                                            d="M7.344,19.219A.781.781,0,0,1,6.563,20H3.125A3.129,3.129,0,0,1,0,16.875V3.125A3.129,3.129,0,0,1,3.125,0H12.73a3.129,3.129,0,0,1,3.125,3.125v7.461a.781.781,0,1,1-1.563,0V3.125A1.564,1.564,0,0,0,12.73,1.563H3.125A1.564,1.564,0,0,0,1.563,3.125v13.75a1.564,1.564,0,0,0,1.563,1.563H6.563A.781.781,0,0,1,7.344,19.219ZM11.949,4.688H3.9a.781.781,0,0,0,0,1.563h8.047a.781.781,0,0,0,0-1.563Zm.781,3.906a.781.781,0,0,0-.781-.781H3.9a.781.781,0,1,0,0,1.563h8.047A.781.781,0,0,0,12.73,8.594ZM3.9,10.938a.781.781,0,0,0,0,1.563H8.008a.781.781,0,1,0,0-1.563ZM17.788,16.66c-.027.039-.122.172-.18.246a10.994,10.994,0,0,1-1.726,1.78A5.358,5.358,0,0,1,12.539,20,5.358,5.358,0,0,1,9.2,18.686a10.991,10.991,0,0,1-1.726-1.78c-.059-.074-.153-.207-.18-.246a.781.781,0,0,1,0-.9c.027-.039.122-.172.18-.246A10.992,10.992,0,0,1,9.2,13.736a5.358,5.358,0,0,1,3.342-1.314,5.358,5.358,0,0,1,3.342,1.314,10.991,10.991,0,0,1,1.726,1.78c.059.074.153.207.18.246A.781.781,0,0,1,17.788,16.66Zm-1.63-.449c-1.242-1.478-2.459-2.227-3.619-2.227s-2.377.749-3.619,2.227c1.242,1.478,2.459,2.227,3.619,2.227S14.916,17.689,16.158,16.211Zm-3.58-1.484a1.484,1.484,0,1,0,1.484,1.484A1.484,1.484,0,0,0,12.578,14.727Zm0,0"
                                                            transform="translate(3 2)"
                                                          />
                                                          <rect
                                                            id="Rectangle_3334"
                                                            data-name="Rectangle 3334"
                                                            width="24"
                                                            height="24"
                                                            fill="none"
                                                          />
                                                        </svg>
                                                      </div>
                                                    </Button>
                                                  ) : (
                                                    <Button className="onBDeleteIcon" disabled>
                                                      <div>
                                                        <svg
                                                          id="view_document_alt-24px"
                                                          data-name="view document_alt-24px"
                                                          xmlns="http://www.w3.org/2000/svg"
                                                          width="24"
                                                          height="24"
                                                          viewBox="0 0 24 24"
                                                        >
                                                          <path
                                                            id="file"
                                                            d="M7.344,19.219A.781.781,0,0,1,6.563,20H3.125A3.129,3.129,0,0,1,0,16.875V3.125A3.129,3.129,0,0,1,3.125,0H12.73a3.129,3.129,0,0,1,3.125,3.125v7.461a.781.781,0,1,1-1.563,0V3.125A1.564,1.564,0,0,0,12.73,1.563H3.125A1.564,1.564,0,0,0,1.563,3.125v13.75a1.564,1.564,0,0,0,1.563,1.563H6.563A.781.781,0,0,1,7.344,19.219ZM11.949,4.688H3.9a.781.781,0,0,0,0,1.563h8.047a.781.781,0,0,0,0-1.563Zm.781,3.906a.781.781,0,0,0-.781-.781H3.9a.781.781,0,1,0,0,1.563h8.047A.781.781,0,0,0,12.73,8.594ZM3.9,10.938a.781.781,0,0,0,0,1.563H8.008a.781.781,0,1,0,0-1.563ZM17.788,16.66c-.027.039-.122.172-.18.246a10.994,10.994,0,0,1-1.726,1.78A5.358,5.358,0,0,1,12.539,20,5.358,5.358,0,0,1,9.2,18.686a10.991,10.991,0,0,1-1.726-1.78c-.059-.074-.153-.207-.18-.246a.781.781,0,0,1,0-.9c.027-.039.122-.172.18-.246A10.992,10.992,0,0,1,9.2,13.736a5.358,5.358,0,0,1,3.342-1.314,5.358,5.358,0,0,1,3.342,1.314,10.991,10.991,0,0,1,1.726,1.78c.059.074.153.207.18.246A.781.781,0,0,1,17.788,16.66Zm-1.63-.449c-1.242-1.478-2.459-2.227-3.619-2.227s-2.377.749-3.619,2.227c1.242,1.478,2.459,2.227,3.619,2.227S14.916,17.689,16.158,16.211Zm-3.58-1.484a1.484,1.484,0,1,0,1.484,1.484A1.484,1.484,0,0,0,12.578,14.727Zm0,0"
                                                            transform="translate(3 2)"
                                                          />
                                                          <rect
                                                            id="Rectangle_3334"
                                                            data-name="Rectangle 3334"
                                                            width="24"
                                                            height="24"
                                                            fill="none"
                                                          />
                                                        </svg>
                                                      </div>
                                                    </Button>
                                                  )}
                                                </span>
                                              </Tooltip>
                                            </Td>
                                            <Td style={{ width: "10%" }}>
                                              <PrimaryButton
                                                className={this.props.classes.editButton}
                                                onClick={this.handleNavigation.bind(
                                                  this,
                                                  skillChecklist
                                                )}
                                              >
                                                {skillChecklist.complete
                                                  ? this.props.t(
                                                      "onBoarding.skillChecklist.editBtn"
                                                    )
                                                  : this.props.t(
                                                      "onBoarding.skillChecklist.startBtn"
                                                    )}
                                              </PrimaryButton>
                                            </Td>
                                          </Tr>
                                        ) : null
                                      )
                                  ) : (
                                    <></>
                                  )}
                                </Tbody>
                              </Table>
                            </Col>
                          </Row>
                        </div>
                      ) : null}
                      {/* Skill Checklist Table Ends */}

                      {this.state.optionalChecklists.length > 0 ? (
                        <div>
                          <Row>
                            <Col sm={12} xs={12} md={12} lg={12}>
                              <div className="onBSubHeading">
                                {this.props.t("onBoarding.skillChecklist.optionalLabel")}:{" "}
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <Col sm={12} md={12} lg={12} xs={12}>
                              <br />
                              <Table id="OnBSkill" className="onBSkillsTable">
                                <Thead>
                                  <Tr className="onBTableHeadSkill">
                                    <Th style={{ width: "10%" }}>
                                      {this.props.t("onBoarding.skillChecklist.statusLabel")}
                                    </Th>
                                    <Th style={{ width: "40%" }}>
                                      {this.props.t("onBoarding.skillChecklist.checklistLabel")}
                                    </Th>
                                    <Th style={{ width: "20%" }}>
                                      {this.props.t("onBoarding.skillChecklist.typeLabel")}
                                    </Th>
                                    <Th style={{ width: "20%" }}>
                                      {this.props.t("onBoarding.skillChecklist.actionLabel")}
                                    </Th>
                                    <Th style={{ width: "10%" }} />
                                  </Tr>
                                </Thead>
                                <Tbody className="tableRowHover">
                                  {this.state.optionalChecklists ? (
                                    this.state.optionalChecklists
                                      .sort((a, b) =>
                                        a.name.toLowerCase() > b.name.toLowerCase()
                                          ? 1
                                          : b.name.toLowerCase() > a.name.toLowerCase()
                                          ? -1
                                          : 0
                                      )
                                      .map((skillChecklist, index) => (
                                        <Tr className="onBTableCellSkill" key={index}>
                                          <Td style={{ width: "10%" }}>
                                            <div
                                              className={
                                                skillChecklist.complete
                                                  ? "onBCompleteStatus"
                                                  : "onBIncompleteStatus"
                                              }
                                            >
                                              {skillChecklist.complete
                                                ? this.props.t(
                                                    "onBoarding.skillChecklist.completeLabel"
                                                  )
                                                : this.props.t(
                                                    "onBoarding.skillChecklist.incompleteLabel"
                                                  )}{" "}
                                            </div>
                                          </Td>
                                          <Td style={{ width: "40%" }}>{skillChecklist.name}</Td>
                                          <Td style={{ width: "20%" }}>
                                            {this.props.t(
                                              "onBoarding.skillChecklist.optionalLabel"
                                            )}
                                          </Td>
                                          <Td style={{ width: "20%" }}>
                                            <Tooltip
                                              title="View Documents"
                                              aria-label="View Documents"
                                              placement="top"
                                            >
                                              <span>
                                                {skillChecklist.complete ? (
                                                  <Button
                                                    className="onBViewIcon"
                                                    onClick={this.handleNavigation.bind(
                                                      this,
                                                      skillChecklist
                                                    )}
                                                  >
                                                    <div>
                                                      <svg
                                                        id="view_document_alt-24px"
                                                        data-name="view document_alt-24px"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="24"
                                                        height="24"
                                                        viewBox="0 0 24 24"
                                                      >
                                                        <path
                                                          id="file"
                                                          d="M7.344,19.219A.781.781,0,0,1,6.563,20H3.125A3.129,3.129,0,0,1,0,16.875V3.125A3.129,3.129,0,0,1,3.125,0H12.73a3.129,3.129,0,0,1,3.125,3.125v7.461a.781.781,0,1,1-1.563,0V3.125A1.564,1.564,0,0,0,12.73,1.563H3.125A1.564,1.564,0,0,0,1.563,3.125v13.75a1.564,1.564,0,0,0,1.563,1.563H6.563A.781.781,0,0,1,7.344,19.219ZM11.949,4.688H3.9a.781.781,0,0,0,0,1.563h8.047a.781.781,0,0,0,0-1.563Zm.781,3.906a.781.781,0,0,0-.781-.781H3.9a.781.781,0,1,0,0,1.563h8.047A.781.781,0,0,0,12.73,8.594ZM3.9,10.938a.781.781,0,0,0,0,1.563H8.008a.781.781,0,1,0,0-1.563ZM17.788,16.66c-.027.039-.122.172-.18.246a10.994,10.994,0,0,1-1.726,1.78A5.358,5.358,0,0,1,12.539,20,5.358,5.358,0,0,1,9.2,18.686a10.991,10.991,0,0,1-1.726-1.78c-.059-.074-.153-.207-.18-.246a.781.781,0,0,1,0-.9c.027-.039.122-.172.18-.246A10.992,10.992,0,0,1,9.2,13.736a5.358,5.358,0,0,1,3.342-1.314,5.358,5.358,0,0,1,3.342,1.314,10.991,10.991,0,0,1,1.726,1.78c.059.074.153.207.18.246A.781.781,0,0,1,17.788,16.66Zm-1.63-.449c-1.242-1.478-2.459-2.227-3.619-2.227s-2.377.749-3.619,2.227c1.242,1.478,2.459,2.227,3.619,2.227S14.916,17.689,16.158,16.211Zm-3.58-1.484a1.484,1.484,0,1,0,1.484,1.484A1.484,1.484,0,0,0,12.578,14.727Zm0,0"
                                                          transform="translate(3 2)"
                                                        />
                                                        <rect
                                                          id="Rectangle_3334"
                                                          data-name="Rectangle 3334"
                                                          width="24"
                                                          height="24"
                                                          fill="none"
                                                        />
                                                      </svg>
                                                    </div>
                                                  </Button>
                                                ) : (
                                                  <Button className="onBViewIcon" disabled>
                                                    <div>
                                                      <svg
                                                        id="view_document_alt-24px"
                                                        data-name="view document_alt-24px"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="24"
                                                        height="24"
                                                        viewBox="0 0 24 24"
                                                      >
                                                        <path
                                                          id="file"
                                                          d="M7.344,19.219A.781.781,0,0,1,6.563,20H3.125A3.129,3.129,0,0,1,0,16.875V3.125A3.129,3.129,0,0,1,3.125,0H12.73a3.129,3.129,0,0,1,3.125,3.125v7.461a.781.781,0,1,1-1.563,0V3.125A1.564,1.564,0,0,0,12.73,1.563H3.125A1.564,1.564,0,0,0,1.563,3.125v13.75a1.564,1.564,0,0,0,1.563,1.563H6.563A.781.781,0,0,1,7.344,19.219ZM11.949,4.688H3.9a.781.781,0,0,0,0,1.563h8.047a.781.781,0,0,0,0-1.563Zm.781,3.906a.781.781,0,0,0-.781-.781H3.9a.781.781,0,1,0,0,1.563h8.047A.781.781,0,0,0,12.73,8.594ZM3.9,10.938a.781.781,0,0,0,0,1.563H8.008a.781.781,0,1,0,0-1.563ZM17.788,16.66c-.027.039-.122.172-.18.246a10.994,10.994,0,0,1-1.726,1.78A5.358,5.358,0,0,1,12.539,20,5.358,5.358,0,0,1,9.2,18.686a10.991,10.991,0,0,1-1.726-1.78c-.059-.074-.153-.207-.18-.246a.781.781,0,0,1,0-.9c.027-.039.122-.172.18-.246A10.992,10.992,0,0,1,9.2,13.736a5.358,5.358,0,0,1,3.342-1.314,5.358,5.358,0,0,1,3.342,1.314,10.991,10.991,0,0,1,1.726,1.78c.059.074.153.207.18.246A.781.781,0,0,1,17.788,16.66Zm-1.63-.449c-1.242-1.478-2.459-2.227-3.619-2.227s-2.377.749-3.619,2.227c1.242,1.478,2.459,2.227,3.619,2.227S14.916,17.689,16.158,16.211Zm-3.58-1.484a1.484,1.484,0,1,0,1.484,1.484A1.484,1.484,0,0,0,12.578,14.727Zm0,0"
                                                          transform="translate(3 2)"
                                                        />
                                                        <rect
                                                          id="Rectangle_3334"
                                                          data-name="Rectangle 3334"
                                                          width="24"
                                                          height="24"
                                                          fill="none"
                                                        />
                                                      </svg>
                                                    </div>
                                                  </Button>
                                                )}
                                              </span>
                                            </Tooltip>
                                            <Tooltip
                                              title="Delete"
                                              aria-label="Delete"
                                              placement="top"
                                            >
                                              <Button
                                                className="onBDeleteIcon"
                                                onClick={this.openDeleteModal.bind(
                                                  this,
                                                  index,
                                                  skillChecklist.name
                                                )}
                                              >
                                                <div>
                                                  <svg
                                                    className="onBDeleteIconSVG"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    height="24"
                                                    viewBox="0 0 24 24"
                                                    width="24"
                                                  >
                                                    <path d="M0 0h24v24H0V0z" fill="none" />
                                                    <path d="M16 9v10H8V9h8m-1.5-6h-5l-1 1H5v2h14V4h-3.5l-1-1zM18 7H6v12c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7z" />
                                                  </svg>
                                                </div>
                                              </Button>
                                            </Tooltip>
                                          </Td>
                                          <Td style={{ width: "10%" }}>
                                            <PrimaryButton
                                              className={this.props.classes.editButton}
                                              onClick={this.handleNavigation.bind(
                                                this,
                                                skillChecklist
                                              )}
                                            >
                                              {skillChecklist.complete
                                                ? this.props.t("onBoarding.skillChecklist.editBtn")
                                                : this.props.t(
                                                    "onBoarding.skillChecklist.startBtn"
                                                  )}
                                            </PrimaryButton>
                                          </Td>
                                        </Tr>
                                      ))
                                  ) : (
                                    <></>
                                  )}
                                </Tbody>
                              </Table>
                            </Col>
                          </Row>
                        </div>
                      ) : null}

                      {this.state.requiredChecklists.length < 1 &&
                      this.state.optionalChecklists.length < 1 ? (
                        <div>
                          <Row>
                            <Col sm={12} md={12} lg={12} xs={12}>
                              <br />
                              <Table id="OnBEmptySkill" className="onBSkillsTable responsiveTable">
                                <Thead>
                                  <Tr className="onBTableHeadSkill">
                                    <Th style={{ width: "10%" }}>
                                      {this.props.t("onBoarding.skillChecklist.statusLabel")}
                                    </Th>
                                    <Th style={{ width: "40%" }}>
                                      {this.props.t("onBoarding.skillChecklist.checklistLabel")}
                                    </Th>
                                    <Th style={{ width: "20%" }}>
                                      {this.props.t("onBoarding.skillChecklist.typeLabel")}
                                    </Th>
                                    <Th style={{ width: "20%" }}>
                                      {this.props.t("onBoarding.skillChecklist.actionLabel")}
                                    </Th>
                                    <Th style={{ width: "10%" }} />
                                  </Tr>
                                </Thead>
                                <Tbody className="tableRowHover">
                                  <Tr className="emptySkillLabel">
                                    <Td height="40" colSpan="6">
                                      &nbsp;&nbsp;
                                      {this.props.t("myProfileSubNav.skillChecklistEmptyCellLabel")}
                                    </Td>
                                  </Tr>
                                </Tbody>
                              </Table>
                            </Col>
                          </Row>
                        </div>
                      ) : null}
                      {/* Back and Next Buttons */}
                      <Row className="mt-4">
                        <Col sm={12} xs={12} md={12} lg={12}>
                          <div className="onBNextBtn d-flex">
                            <TertiaryButton
                              size="medium"
                              className={this.props.classes.outlineBtnColor}
                              onClick={this.handleBackBtnClick.bind(this)}
                            >
                              {this.props.t("onBoarding.workExp.backBtnLbl")}
                            </TertiaryButton>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            {this.shouldRenderButton()}
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
              </Container>
              <Modal
                className="onBSkillDeleteModal"
                open={this.state.openRate}
                onClose={this.closePopover.bind(this)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                  timeout: 500,
                }}
              >
                <Fade in={this.state.openRate}>
                  <div className="onBSkillDeletePopupWrap">
                    <div className="textCenter onBSkillDeletePopupTxt">
                      {" "}
                      {this.props.t("onBoarding.skillChecklist.skillRecordDeleteTxt1")}{" "}
                      {this.props.onboardingBasicInfo && this.props.onboardingBasicInfo.firstname
                        ? this.props.onboardingBasicInfo.firstname
                        : ""}
                      {", "}
                      {this.props.t("onBoarding.skillChecklist.skillRecordDeleteTxt2")}{" "}
                      <span className="onBSkillDeletePopupTxt onBSkillChecklistName">
                        {this.state.skillChecklistName} ?
                      </span>
                    </div>

                    <div className="d-flex justify-content-center">
                      <SecondaryButton
                        onClick={this.deleteOptionalSkills.bind(this, this.state.deleteRowIndex)}
                      >
                        {" "}
                        {this.props.t("onBoarding.skillChecklist.yesBtnLbl")}{" "}
                      </SecondaryButton>
                      &nbsp; &nbsp;
                      <PrimaryButton onClick={this.closePopover.bind(this)}>
                        {this.props.t("onBoarding.skillChecklist.noBtnLbl")}
                      </PrimaryButton>
                    </div>
                  </div>
                </Fade>
              </Modal>
            </div>
            {/* Parent Column Ends  */}
          </Row>
        </Container>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  onBoardingSkillChecklists: state.onBoarding.skillChecklists,
  onBoardingOptionalSkillChecklists: state.onBoarding.optionalSkillChecklists,
  selectedOptionalSkills: state.onBoarding.selectedOptionalSkills,
  skillChecklistById: state.onBoarding.skillChecklistById,
  deleteSkillChecklistResponse: state.onBoarding.deleteSkillChecklistResponse,
  email: state.auth.email,
  onboardingSpecialtiesAndEducation: state.onBoarding.specialtiesAndEducation,
  onboardingBasicInfo: state.onBoarding.basicInfo,
  completedSkillsCheckList: state.onBoarding.completedSkillsCheckList,
  company: state.company.company,
  applicantId: state.candidate.applicantId,
  userId: state.auth.userId,
  candidateSpecialties: state.auth.candidateSpecialties,
  onBoardingCompletedSteps: state.onBoarding.completedSteps,
  firstName: state.auth.firstName,
  lastName: state.auth.lastName,
  phone: state.auth.phone,
  recruiterDetails: state.consultant.recruiterDetails,
  onboardingSaveSkillChecklistResponse: state.onBoarding.saveSkillChecklistResponse,
  onboardingSkillChecklistInformation: state.onBoarding.skillChecklistInformation,
});

const mapDispatchToProps = (dispatch) => ({
  fetchSkillChecklistByChecklistId: (val) => dispatch(fetchSkillChecklistByChecklistId(val)),
  updateSkillChecklistStatus: (value) => dispatch(updateSkillChecklistStatus(value)),
  saveOptionalSkillChecklist: (reqObj, previousOnboardingBasicInfo) => dispatch(saveOptionalSkillChecklist(reqObj, previousOnboardingBasicInfo)),
  deleteSkillChecklistById: (reqObj, previousOnboardingBasicInfo) => dispatch(deleteSkillChecklistById(reqObj, previousOnboardingBasicInfo)),
  fetchSkillChecklists: (skillChecklistReqObj) =>
    dispatch(fetchSkillChecklists(skillChecklistReqObj)),
  saveSkillChecklistSelected: (skillChecklistSelected) =>
    dispatch(saveSkillChecklistSelected(skillChecklistSelected)),
  saveSkillChecklistGroupCompleteStatus: (completedGroups) =>
    dispatch(saveSkillChecklistGroupCompleteStatus(completedGroups)),
  saveSkillChecklistGroupSkills: (skillsChecklistGroupSkillsFromStore) =>
    dispatch(saveSkillChecklistGroupSkills(skillsChecklistGroupSkillsFromStore)),
  updateSelectedSkillCompleteFlag: (completeFlag) =>
    dispatch(updateSelectedSkillCompleteFlag(completeFlag)),
  updateCompleteFlag: (completedSteps) => dispatch(updateCompleteFlag(completedSteps)),
  updateActiveFlag: (activeSteps) => dispatch(updateActiveFlag(activeSteps)),
});

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withRouter(OnBoardingYourSkills)))
);
