import { gql } from "@apollo/client";

export const getUserByExternalId = gql`
  query userByExternalId($type: String!, $externalId: String!) {
    userByExternalId(type: $type, externalId: $externalId) {
      id
      email
      firstname
      lastname
      phone
      candidates {
        id
        companyId
        userId
      }
    }
  }
`;

export const getAllReferencesQuery = gql`
  query GetAllReferences($email: String!, $isDeleted: Boolean!) {
    getAllReferences(email: $email, isDeleted: $isDeleted) {
      status
      userMessage
      content {
        companyId
        email
        candidateReference {
          meta {
            lastVisitedPage
            completedSections {
              references
            }
          }
          references {
            id
            dateCompleted
            dateCreated
            dateLastUpdated
            token
            status
            notificationSent
            facilityName
            startDate
            endDate
            profession
            specialty
            supervisorFirstName
            supervisorLastName
            supervisorTitle
            supervisorEmail
            supervisorPhone
            responseOne
            responseTwo
            responseThree
            comments
            isDeleted
            declinationReason
            dateNotified
            referenceId
            atsBullhornCandidateId
            contactConsent
            facilityCityState
            employed
          }
        }
      }
    }
  }
`;

export const setReferenceQuery = gql`
  mutation SetReferences($referencesInput: ReferencesRequest!) {
    setReferences(referencesInput: $referencesInput) {
      status
      userMessage
      content {
        id
        companyId
        email
        dateCreated
        dateLastUpdated
        createdBy
        updatedBy
        isActive
        isDeleted
        candidateReference {
          meta {
            lastVisitedPage
            completedSections {
              references
            }
          }
          references {
            id
            dateCompleted
            dateCreated
            dateLastUpdated
            token
            status
            notificationSent
            facilityName
            startDate
            endDate
            profession
            specialty
            supervisorFirstName
            supervisorLastName
            supervisorTitle
            supervisorEmail
            supervisorPhone
            responseOne
            responseTwo
            responseThree
            comments
            isDeleted
            declinationReason
            dateNotified
            referenceId
            atsBullhornCandidateId
            contactConsent
            facilityCityState
            employed
          }
        }
      }
    }
  }
`;

export const deleteReferenceQuery = gql`
  mutation DeleteReferences($referencesInput: ReferencesRequest!) {
    deleteReferences(referencesInput: $referencesInput) {
      status
      userMessage
      content {
        id
        companyId
        email
        dateCreated
        dateLastUpdated
        createdBy
        updatedBy
        isActive
        isDeleted
        candidateReference {
          meta {
            lastVisitedPage
            completedSections {
              references
            }
          }
          references {
            id
            dateCompleted
            dateCreated
            dateLastUpdated
            token
            status
            notificationSent
            facilityName
            startDate
            endDate
            profession
            specialty
            supervisorFirstName
            supervisorLastName
            supervisorTitle
            supervisorEmail
            supervisorPhone
            responseOne
            responseTwo
            responseThree
            comments
            declinationReason
            isDeleted
            referenceId
            atsBullhornCandidateId
            contactConsent
            facilityCityState
            employed
          }
        }
      }
    }
  }
`;

export const syncProfileDataFromAbleQuery = gql`
  query syncProfileDataFromAble($user: UserInput!) {
    syncProfileDataFromAble(user: $user) {
      content {
        id
        companyId
        email
      }
    }
  }
`;

export const getCredentialsTypeQuery = gql`
  query GetCredentialsType($credentialsCategory: [String!]!) {
    getCredentialsType(credentialsCategory: $credentialsCategory) {
      isSuccess
      code
      developerMessage
      userMessage
      credentialsTypes {
        id
        label
        defaultExpirationReminderDays
        expirationDateOptional
        hasLicenseNumber
        isTwoSided
        requireCompleteDate
      }
      count
    }
  }
`;
export const GET_ALL_WORK_EXPERIENCE = gql`
  query GetAllWorkExperience($email: String!, $isDeleted: Boolean) {
        getAllWorkExperience(email: $email, isDeleted: $isDeleted) {
      status
      content {
        id
        companyId
        email
        dateCreated
        dateLastUpdated
        createdBy
        updatedBy
        isActive
        isDeleted
        candidateWorkExperience {
          workExperience {
            meta {
              id
              createdDate
              createdBy
              updatedDate
              associations {
                tenantId
                tenantEntityId
                tenantLastIngressSyncDate
                tenantLastEgressSyncDate
                isDeleted
              }
            }
            facilityName
            city
            profession
            specialty
            position
            supervisor
            shiftWorked
            facilityType
            emrTechnology
            patientRatio
            floatingPlace
            teachingFacility
            chargeExperience
            startDate
            endDate
            isCurrentlyWorking
            reasonForLeaving
            staffingAgency
            isTempJob
            isVerified
            atsBullhornCandidateId
          }
        }
      }
      userMessage
    }
  }
`;

export const syncProfileDataFromBullhornQuery = gql`
 query SyncProfileDataFromBullhorn($email: String!, $companyId: String!, $bullhornId: String!) {
  syncProfileDataFromBullhorn(email: $email, companyId: $companyId, bullhornId: $bullhornId) {  
    content {
      id
      companyId
      email
      bullhornId
    }
    status
    success
    userMessage
  }
}
`;