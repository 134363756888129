/* eslint-disable react/jsx-props-no-spreading */
import clsx from "clsx";
import Slider, { Settings } from "react-slick";
import { useMediaQuery, useTheme } from "@material-ui/core";
import { v4 as uuidv4 } from "uuid";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import styles from "./JobSlider.module.scss";
import JobCard, { JobCardProps } from "../JobCard";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./slick.scss";

interface QuickApplyEligibleProps extends Settings {
  jobCardOptions: JobCardProps[];
  cardClassName?: string;
  settings?: {
    xs?: Settings;
    sm?: Settings;
    md?: Settings;
    lg?: Settings;
    xl?: Settings;
  };
  className?: string;
}

const QuickApplyEligible = ({
  jobCardOptions,
  settings,
  className,
  ...props
}: QuickApplyEligibleProps) => {
  const jobCards = jobCardOptions.map((options, index) => {
    const firstCard = index === 0;
    const lastCard = jobCardOptions.length > 1 && index === jobCardOptions.length - 1;

    return (
      <JobCard
        key={uuidv4()}
        {...options}
        className={clsx(
          styles.card,
          firstCard && styles.firstCard,
          lastCard && styles.lastCard,
          options.className
        )}
      />
    );
  });

  const theme = useTheme();
  const isSmallOrUp = useMediaQuery(theme.breakpoints.up("sm"));
  const isMediumOrUp = useMediaQuery(theme.breakpoints.up("md"));
  const isLargeOrUp = useMediaQuery(theme.breakpoints.up("lg"));
  const isXLargeOrUp = useMediaQuery(theme.breakpoints.up("xl"));

  const getSliderSettings = (): Settings => {
    let defaultSettings: Settings = {
      arrows: false,
      dots: false,
      infinite: false,
      slidesToShow: 1.1,
      slidesToScroll: 1,
      ...settings?.xs,
    };

    if (isSmallOrUp) {
      defaultSettings = {
        ...defaultSettings,
        ...settings?.sm
      }
    }

    if (isMediumOrUp) {
      defaultSettings = {
        ...defaultSettings,
        arrows: true,
        dots: true,
        slidesToShow: 2,
        slidesToScroll: 2,
        prevArrow: <ChevronLeftIcon />,
        nextArrow: <ChevronRightIcon />,
        ...settings?.md
      }
    }

    if (isLargeOrUp) {
      defaultSettings = {
        ...defaultSettings,
        slidesToShow: 3,
        slidesToScroll: 3,
        ...settings?.lg
      }
    }

    if (isXLargeOrUp) {
      defaultSettings = {
        ...defaultSettings,
        slidesToShow: 4,
        slidesToScroll: 4,
        ...settings?.xl
      }
    }

    return {
      ...defaultSettings,
      ...props
    };
  };

  return (
    <div className={clsx(styles.main, className)}>
      <Slider
        {...getSliderSettings()}
        {...props}
        key={uuidv4()}
      >
        {jobCards}
      </Slider>
    </div>
  );
}

QuickApplyEligible.defaultProps = {
  cardClassName: "",
  settings: undefined,
  className: ""
}

export default QuickApplyEligible;