import JobCardHeaderV2 from "src/components/common/MainContent/JobResults/JobCardHeaderV2";
import JobCardsMainContentV2 from "src/components/common/MainContent/JobResults/JobCardsMainContentV2";
import { EmailRecruiterV2Props } from "src/components/common/MainContent/JobResults/common/index";
import { RecommendedJobsSourceTypes } from "src/types/receommendedJobsSource";


interface JobCardSingleV2Props {
  job: EmailRecruiterV2Props["job"];
  handleEmailCLick: (jobId: string) => void;
  similarOrViewed: EmailRecruiterV2Props["similarOrViewed"];
  recommended: EmailRecruiterV2Props["recommended"];
  isRecommendedJobsOnResult: EmailRecruiterV2Props["isRecommendedJobsOnResult"];
  viewMyJobs: EmailRecruiterV2Props["viewMyJobs"];
  campaignCode: EmailRecruiterV2Props["campaignCode"];
  sourceType: EmailRecruiterV2Props["sourceType"];
  jobsSource: RecommendedJobsSourceTypes;
  isQuickApplyAdvCard?: boolean
}


const JobCardSingleV2 = ({
  job,
  handleEmailCLick,
  recommended,
  isRecommendedJobsOnResult,
  similarOrViewed,
  viewMyJobs,
  campaignCode,
  sourceType,
  jobsSource,
  isQuickApplyAdvCard = false
}: JobCardSingleV2Props) => {
  return (
    <div className={`jobCardMainWrapV2 ${isQuickApplyAdvCard ? "quickApplyCard" : ""}`}>
      <div>
        <JobCardHeaderV2
          job={job}
          handleEmailCLick={handleEmailCLick}
          similarOrViewed={similarOrViewed}
          recommended={recommended}
          isRecommendedJobsOnResult={isRecommendedJobsOnResult}
          viewMyJobs={viewMyJobs}
          campaignCode={campaignCode}
          sourceType={sourceType}
          jobsSource={jobsSource}
          isQuickApplyAdvCard={isQuickApplyAdvCard}
        />
        <JobCardsMainContentV2
          isJobDetailsPage
          job={job}
          similarOrViewed={similarOrViewed}
          recommended={recommended}
          isRecommendedJobsOnResult={isRecommendedJobsOnResult}
          viewMyJobs={viewMyJobs}
          campaignCode={campaignCode}
          sourceType={sourceType}
          jobsSource={jobsSource}
          isQuickApplyAdvCard={isQuickApplyAdvCard}
        />
      </div>
    </div>
  );
};
JobCardSingleV2.defaultProps = {
  isQuickApplyAdvCard: false,
};

export default JobCardSingleV2;
