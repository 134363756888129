import i18n from "../translations/i18n";
import config from "../config";

const referencesSurveyConstraint = {
  firstName: {
    presence: { allowEmpty: false },
    format: {
      pattern: config.NAME_REGEX,
      message: i18n.t("onBoarding.errorMessage.refName"),
    },
    length: {
      maximum: 50,
      message: i18n.t("onBoarding.errorMessage.refNameLength"),
    },
  },
  lastName: {
    presence: { allowEmpty: false },
    format: {
      pattern: config.NAME_REGEX,
      message: i18n.t("onBoarding.errorMessage.refName"),
    },
    length: {
      maximum: 50,
      message: i18n.t("onBoarding.errorMessage.refNameLength"),
    },
  },
  title: {
    presence: { allowEmpty: false },
  },
  emailAddress: {
    format: {
      pattern: `^$|${config.EMAIL_REGEX}`,
      message: i18n.t("onBoarding.errorMessage.email"),
    },
  },
  phoneNumber: {
    format: {
      pattern: `^$|${config.PHONE_REGEX_BASIC_INFO}`,
      message: i18n.t("onBoarding.errorMessage.phone"),
    },
  },
  professionalStandards: {
    presence: { allowEmpty: false },
  },
  attendanceStandards: {
    presence: { allowEmpty: false },
  },
  clinicalStandards: {
    presence: { allowEmpty: false },
  },
};

const referencesSurveyConstraintForDecline = {
  firstName: {
    presence: { allowEmpty: false },
    format: {
      pattern: config.NAME_REGEX,
      message: i18n.t("onBoarding.errorMessage.refName"),
    },
    length: {
      maximum: 50,
      message: i18n.t("onBoarding.errorMessage.refNameLength"),
    },
  },
  lastName: {
    presence: { allowEmpty: false },
    format: {
      pattern: config.NAME_REGEX,
      message: i18n.t("onBoarding.errorMessage.refName"),
    },
    length: {
      maximum: 50,
      message: i18n.t("onBoarding.errorMessage.refNameLength"),
    },
  },
  title: {
    presence: { allowEmpty: false },
  },
  emailAddress: {
    format: {
      pattern: `^$|${config.EMAIL_REGEX}`,
      message: i18n.t("onBoarding.errorMessage.email"),
    },
  },
  phoneNumber: {
    format: {
      pattern: `^$|${config.PHONE_REGEX_BASIC_INFO}`,
      message: i18n.t("onBoarding.errorMessage.phone"),
    },
  }
};

const referencesSurveyFormConstraints = {
  references: {
    surveyListCustom: true,
  },
};
export default { referencesSurveyConstraint, referencesSurveyFormConstraints, referencesSurveyConstraintForDecline };
