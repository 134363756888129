import React from "react";
import { connect } from "react-redux";
import MainLayout from "../MainLayout";
import PayrollTimesheet from "../../common/MainContent/Payroll/Timesheets/TimeSheets";

const PayrollTimesheetPage = (props) => <MainLayout component={PayrollTimesheet} />;

const mapStateToProps = (state) => ({
  company: state.company.company,
});

export default connect(mapStateToProps, null)(PayrollTimesheetPage);
