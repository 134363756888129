import { useEffect, useState } from "react";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import MailOutlinedIcon from "@material-ui/icons/MailOutlined";
import MailIcon from "@material-ui/icons/Mail";
import { TFunction, withTranslation } from "react-i18next";
import { jobDetailType } from "src/components/common/MainContent/JobResults/common/index";

interface ControlledEmailTooltipProps {
  job: jobDetailType;
  success: boolean;
  error: boolean;
  actionJobId: string;
  emailSuccessResponse: {
    appliedJobId: string;
    message: string;
    status: Response["status"];
    statusCode: Response["statusText"];
  };
  handleEmailClick: (jobId: string) => void;
  t: TFunction<"translation", undefined>;
}

const ControlledEmailTooltip = ({
  job,
  success,
  error,
  actionJobId,
  emailSuccessResponse,
  handleEmailClick,
  t,
}: ControlledEmailTooltipProps) => {
  const [showMsg, setShowMsg] = useState(true);

  useEffect(() => {
    if (success || error) {
      setShowMsg(true);
    }
    setTimeout(() => {
      setShowMsg(false);
    }, 2000);
  }, []);

  let tooltipMessage;
  const appliedJobId = actionJobId;
  if (appliedJobId === job.id) {
    if (success) tooltipMessage = t("jobs.emailRecruiter.emailSuccessMessage");
    if (error) {
      tooltipMessage = (
        <span className="emailFailMessage">{t("jobs.emailRecruiter.emailNotSentError")}</span>
      );
    }
  }
  setTimeout(() => {
    tooltipMessage = t("jobs.emailRecruiter.contactRecruiter");
  }, 1000);
  if (showMsg) {
    return (
      <Tooltip
        title={tooltipMessage}
        aria-label="Recruiter"
        placement="top"
        open={appliedJobId === job.id && (success || error)}
        disableFocusListener={!!success}
        disableHoverListener={!!success}
        disableTouchListener={!!success}
        leaveDelay={500}
        className="toolTipMsg"
      >
        <IconButton size="small" component="button" onClick={() => handleEmailClick(actionJobId)}>
          {job.isEmailed || (emailSuccessResponse.status === 200 && appliedJobId === job.id) ? (
            <MailIcon className="headerIcon recruiterIconFilled label-root" />
          ) : (
            <MailOutlinedIcon className="headerIcon recruiterIcon label-root" />
          )}
        </IconButton>
      </Tooltip>
    );
  }
  return (
    <IconButton size="small" component="button" onClick={() => handleEmailClick(actionJobId)}>
      {job.isEmailed || (emailSuccessResponse.status === 200 && appliedJobId === job.id) ? (
        <MailIcon className="headerIcon recruiterIconFilled label-root" />
      ) : (
        <MailOutlinedIcon className="headerIcon recruiterIcon label-root" />
      )}
    </IconButton>
  );
};

export default withTranslation()(ControlledEmailTooltip);
