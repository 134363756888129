module.exports = (testedResult, password) => {
  let result;

  if (
    testedResult.length >= 8 &&
    (testedResult?.contains?.includes("lowercase") ||
      testedResult?.contains?.includes("uppercase")) &&
    testedResult?.contains?.includes("number") &&
    /[!@#^&*_+\-~$]/.test(password)
  ) {
    result = true;
  } else {
    result = false;
  }
  return result;
};
