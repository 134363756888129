import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@mui/material/Dialog";

export interface LoaderSpinnerProps {
  isOpen: boolean;
  size: number;
}

const LoaderSpinner = (props: LoaderSpinnerProps) => {
  const { isOpen, size } = props;

  return (
    <Dialog open={isOpen} className="spinnerDialog">
      <CircularProgress size={size} />
    </Dialog>
  );
};

export default LoaderSpinner;
