import i18n from "../translations/i18n";
import config from "../config";

const referencesFormConstraints = {
  firstName: {
    presence: { allowEmpty: false },
    format: {
      pattern: config.NAME_REGEX,
      message: i18n.t("onBoarding.errorMessage.refName"),
    },
    length: {
      maximum: 50,
      message: i18n.t("onBoarding.errorMessage.refNameLength"),
    },
  },
  lastName: {
    presence: { allowEmpty: false },
    format: {
      pattern: config.NAME_REGEX,
      message: i18n.t("onBoarding.errorMessage.refName"),
    },
    length: {
      maximum: 50,
      message: i18n.t("onBoarding.errorMessage.refNameLength"),
    },
  },
  title: {
    presence: { allowEmpty: false },
  },
  facilityNameDropDown: {
    presence: { allowEmpty: false },
  },
  email: {
    format: {
      pattern: `^$|${config.EMAIL_REGEX}`,
      message: i18n.t("onBoarding.errorMessage.email"),
    },
  },
  phone: {
    format: {
      pattern: `^$|${config.PHONE_REGEX_BASIC_INFO}`,
      message: i18n.t("onBoarding.errorMessage.phone"),
    },
  },
};
const referencesPageConstraints = {
  references: {
    referencesListCustom: true,
  },
};
export default { referencesFormConstraints, referencesPageConstraints };
