import {
  LOGIN_SUCCESS,
  LOGIN_REQUEST,
  LOGIN_FAILURE,
  FORGOT_PASSWORD,
  DETECT_EMPTY_TOKEN,
  INACTIVE_USER,
  IS_CANDIDATE_LIST_EMPTY,
  CHANGE_IS_FIRST_LOGIN_FLAG,
  LOGIN_STATUS,
  JOB_PREFERENCES_FLAG,
} from "./action";

const initialState = {
  isAuthenticated: false,
  isDisabled: null,
  loading: false,
  loginLoading: false,
  username: "",
  candidateProfession: "",
  candidateSpecialties: [],
  userId: null,
  firstName: "",
  lastName: "",
  email: "",
  showResend: false,
  candidateId: 0,
  isTokenEmpty: false,
  loginFailureData: {},
  passResetEmailSent: false,
  forgotPassLoading: false,
  resetPassAttempts: 3,
  resetPassApiError: false,
  isCandidateListEmpty: false,
  candidateState: "",
  candidateZip: "",
  candidateDateAvailable: "",
  isFirstLogin: false,
  isUserLoggedIn: false,
  origin: null,
  error: null,
  useProfileIframe: false,
  isJobPreferences: sessionStorage.getItem("isJobPreferences") === "true",
  initCalled: false,
};

const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS: {
      const { preferences } = action.payload.candidates[0];
      if (preferences.facilities === null) preferences.facilities = [];
      if (action.payload.isFirstLogin) {
        localStorage.setItem("isFirstLogin", action.payload.isFirstLogin);
      }
      return {
        ...state,
        isAuthenticated: true,
        isDisabled: false,
        loginLoading: false,
        username: action.payload.user.username,
        candidateProfession: action.payload.candidates[0].profession,
        candidateSpecialties: action.payload.candidates[0].specialties,
        candidateId: action.payload.candidates[0].id,
        firstName: action.payload.user.firstname,
        lastName: action.payload.user.lastname,
        email: action.payload.user.email,
        phone: action.payload.user.phone,
        userId: action.payload.user.id,
        candidateState: action.payload.candidates[0].state,
        candidateZip: action.payload.candidates[0].zip,
        candidateDateAvailable: action.payload.candidates[0].dateAvailable,
        isFirstLogin: localStorage.getItem("isFirstLogin") ? true : action.payload.isFirstLogin,
        error: null,
        useProfileIframe: action.payload.useProfileIframe,
      };
    }
    case LOGIN_REQUEST:
      return {
        ...state,
        isAuthenticated: false,
        isDisabled: null,
        loginLoading: true,
        username: null,
        candidateProfession: null,
        candidateSpecialties: null,
        candidateId: null,
        firstName: null,
        lastName: null,
        email: null,
        phone: null,
        candidateState: null,
        candidateZip: null,
        candidateDateAvailable: null,
        error: null,
        initCalled: true,
      };
    case LOGIN_FAILURE:
      return {
        ...state,
        isAuthenticated: false,
        isDisabled: null,
        loginLoading: false,
        username: null,
        candidateProfession: null,
        candidateSpecialties: null,
        candidateId: null,
        firstName: null,
        lastName: null,
        email: null,
        phone: null,
        candidateState: null,
        candidateZip: null,
        candidateDateAvailable: null,
        error: action.payload,
        initCalled: false,
      };
    case FORGOT_PASSWORD:
      return {
        ...state,
        showResend: action.payload.showResend ? action.payload.showResend : state.showResend,
        userDoesNotExist: action.payload.userDoesNotExist ? action.payload.userDoesNotExist : false,
        passResetEmailSent: action.payload.passResetEmailSent
          ? action.payload.passResetEmailSent
          : false,
        forgotPassLoading: action.payload.forgotPassLoading
          ? action.payload.forgotPassLoading
          : false,
        resetPassAttempts: action.payload.resetPassAttempts
          ? state.resetPassAttempts - 1
          : state.resetPassAttempts,
        resetPassApiError: action.payload.resetPassApiError
          ? action.payload.resetPassApiError
          : false,
      };
    case INACTIVE_USER: {
      const temp = action;
      return {
        ...state,
        loginFailureData: temp.payload.user,
        isAuthenticated: false,
        isDisabled: !action.payload.user.isActive,
      };
    }
    case DETECT_EMPTY_TOKEN:
      return { ...state, isTokenEmpty: action.payload };
    case IS_CANDIDATE_LIST_EMPTY:
      return { ...state, loading: false, isCandidateListEmpty: action.payload };
    case CHANGE_IS_FIRST_LOGIN_FLAG:
      localStorage.removeItem("isFirstLogin");
      return { ...state, isFirstLogin: false };
    case LOGIN_STATUS:
      return { ...state, isUserLoggedIn: action.payload, origin: action.origin };
    case JOB_PREFERENCES_FLAG:
      sessionStorage.setItem("isJobPreferences", action.payload);
      return { ...state, isJobPreferences: sessionStorage.getItem("isJobPreferences") === "true" };
    default:
      return state;
  }
};

export default rootReducer;
