import {
  FETCH_FACILITY_USING_POS_RAD_REQ,
  RESPONSE_FACILITIES_FAILURE,
  RESPONSE_FACILITIES_SUCCESS,
  FACILITIES_UPDATE_CRITERIA,
  FETCH_CANDIDATE_FACILITIES_REQUEST,
  FETCH_CANDIDATE_FACILITIES_SUCCESS,
  FETCH_CANDIDATE_FACILITIES_FAILURE,
} from "./action";

const initialState = {
  loading: false,
  facilities: {},
  error: "",
  selectedFacilities: [],
  candidatePreferences: {
    facilities: [],
  },
  dateAvailable: "",
};
const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_FACILITY_USING_POS_RAD_REQ:
      return { ...state, loading: true };

    case RESPONSE_FACILITIES_SUCCESS:
      return { ...state, loading: false, facilities: action.payload.response, error: "" };

    case RESPONSE_FACILITIES_FAILURE:
      return { ...state, loading: false, error: action.payload };

    case FACILITIES_UPDATE_CRITERIA:
      return { ...state, updatedFacilities: action.payload };

    case FETCH_CANDIDATE_FACILITIES_REQUEST:
      return { ...state, loading: true };

    case FETCH_CANDIDATE_FACILITIES_SUCCESS: {
      const preferences = { ...action.payload.preferences };
      const { dateAvailable } = action.payload;
      return {
        ...state,
        loading: false,
        candidatePreferences: preferences,
        dateAvailable,
        error: "",
      };
    }
    case FETCH_CANDIDATE_FACILITIES_FAILURE: {
      const noPreferences = { ...initialState.candidatePreferences };
      return { ...state, loading: false, candidatePreferences: noPreferences, error: "" };
    }
    default:
      return state;
  }
};

export default rootReducer;
