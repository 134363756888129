import HorizontalChips from "../../HorizontalChips";

type items = {
  label: string;
  value: string;
  startIcon: React.ReactElement;
  activeStartIcon: React.ReactElement;
  isActive: boolean;
}

interface FilterChipProp {
  chips: items[];
  handleChange: (values: items) => void;
}

function FilterChip({ chips, handleChange }: FilterChipProp) {
  return <HorizontalChips items={chips} onClick={handleChange}/>;
}

export default FilterChip;
