import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { Dialog } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import Analytics from "../../../services/Analytics";
import {
  BASIC_INFO_PAGE,
  EDU_SPEC_PAGE,
  WORK_EXP_PAGE,
  SKILL_CHECKLIST_OVERVIEW_PAGE,
  SKILL_CHECKLIST_DETAILS_PAGE,
} from "../../../router/routes";
import { PrimaryButton, SecondaryButton, TertiaryButton } from "../Buttons";
import config from "../../../config";

const enableReferences = config.ENABLE_REFERENCES === "true";
const perStepPercentage = enableReferences ? 20 : 25;

const styles = (theme) => ({});
export const OnBoardingConfirmModal = ({ enabled, source, props }) => {
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const unblockHandle = useRef();
  const unblockHandleDestination = useRef();
  const location = useLocation();
  const onBoardingCompletedSteps = useSelector((state) => state.onBoarding.completedSteps);

  const handleShowModal = () => setOpen(true);
  const handleHideModal = () => {
    setOpen(false);
    const profileSection = pageVisited();
    const profileProgress = profileProgressInPercentage();
    const segmentEventName = "Profile Unsaved  Stayed On Page";
    const segmentEventProps = {
      section: profileSection,
      progress: profileProgress,
      origin: document.referrer,
    };
    Analytics.segmentTrackEvent(segmentEventName, segmentEventProps);
  };

  useEffect(() => {
    if (enabled) {
      unblockHandle.current = history.block((targetLocation) => {
        if (window.location.pathname !== targetLocation.pathname) {
          unblockHandleDestination.current = targetLocation;
          handleShowModal();
        }
        return false;
      });
      return function () {
        unblockHandle.current && unblockHandle.current();
      };
    }
  });

  useEffect(() => {
    if (!enabled && unblockHandle?.current) {
      handleConfirm();
    }
  });
  const pageVisited = () => {
    let page = null;
    if (location.pathname === BASIC_INFO_PAGE) {
      page = "Basic Information";
    } else if (location.pathname === EDU_SPEC_PAGE) {
      page = "Specialties & Education";
    } else if (location.pathname === WORK_EXP_PAGE) {
      page = "Work Experience";
    } else if (
      location.pathname === SKILL_CHECKLIST_OVERVIEW_PAGE ||
      location.pathname === SKILL_CHECKLIST_DETAILS_PAGE
    ) {
      page = "Skills Checklist";
    }
    return page;
  };

  const profileProgressInPercentage = () => {
    let completedPercentage = 0;
    let noOfStepsCompleted = 0;
    for (const prop in onBoardingCompletedSteps) {
      if (Object.prototype.hasOwnProperty.call(onBoardingCompletedSteps, prop)) {
        noOfStepsCompleted = noOfStepsCompleted + 1;
      }
    }

    completedPercentage = noOfStepsCompleted * perStepPercentage;

    return completedPercentage;
  };

  const handleConfirm = () => {
    const profileSection = pageVisited();
    const profileProgress = profileProgressInPercentage();
    const segmentEventName = "Profile Unsaved Changes Discarded";
    const segmentEventProps = {
      section: profileSection,
      progress: profileProgress,
      origin: document.referrer,
    };
    Analytics.segmentTrackEvent(segmentEventName, segmentEventProps);
    if (unblockHandle) {
      unblockHandle.current();
    }
    if(source) {
      history.push(source)
    }
    else {
      const tx = unblockHandleDestination.current;
      if (tx) history.push(tx.pathname);
    }
  };

  return (
    <Dialog open={open} onClose={handleHideModal}>
      <div className="onBconfirmPopupWrap">
        <p className="onBconfirmPopupWrapTxt">You have unsaved changes!</p>
        <div>
          <p className="onBconfirmTxt">
            If you leave the page without saving, your changes will be lost.
            <br />
            To save, be sure to click the “NEXT” button at the bottom of the page.
          </p>

          <div className="unSavedhangesChangesWrapper">
            <SecondaryButton className="StayPageButton" onClick={handleHideModal} size="medium">
              STAY ON PAGE
            </SecondaryButton>
            <PrimaryButton className="discardButton" onClick={handleConfirm} size="medium">
              DISCARD CHANGES
            </PrimaryButton>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default withStyles(styles)(OnBoardingConfirmModal);
