import { withTranslation, useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useMemo } from "react";
import clsx from "clsx";
import { Skeleton, useMediaQuery } from "@mui/material";
import styles from "./Search.module.scss";
import LocationSpecialities from "../../../Home/LocationSpecialities";
import { getRandomBackgroundImage } from "../background";
import "./override.scss";

interface SearchProps {
  completedSteps: {
    [key: string]: boolean;
  };
  useProfileIframe: boolean;
  firstName?: string;
}

const Search = ({
  completedSteps,
  useProfileIframe,
  firstName
}: SearchProps) => {
  const { t } = useTranslation();

  const isCompact = useMediaQuery("(max-width: 768px)");

  const isLoading = firstName == null;

  const requiredStepsAmount = useProfileIframe ? 4 : 5;
  const completedStepsAmount = completedSteps != null ? Object.keys(completedSteps).length : 0;
  const isProfileComplete = completedStepsAmount >= requiredStepsAmount;
  const randomBackgroundImage = useMemo(() => getRandomBackgroundImage(), []);

  const searchBar = isLoading ? (
    <div className={styles.skeleton}>
      {!isCompact && (
        <Skeleton />
      )}

      {isCompact && (
        <>
        <Skeleton />
        <Skeleton />
        <Skeleton />
        </>
      )}
    </div>
  ) : (
    <LocationSpecialities
      hideHeading
      enableDashboardAd={false}
      translate={t}
      componentName={t("globalSearch.location.componentDashboard")}
    />
  );

  if (isProfileComplete === false) {
    return (
      <div className={styles.main}>
        <div className={styles.sub}>
          <div className={styles.nextDestinationHeading}>
            {t("dashboard.searchBar.nextDestinationHeading")}
          </div>

          <div>
            {searchBar}
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className={clsx(styles.main, styles.completeProfile)} style={{
      backgroundImage: `linear-gradient(0deg, rgba(0, 51, 102, 0.60) 0%, rgba(0, 51, 102, 0.60) 100%), url("${randomBackgroundImage}")`,
    }}>
      <div className={styles.sub}>
        <div id="job-search-heading" className={styles.welcomeHeading}>
          {t("dashboard.banner.dashProfileBannerTxt1")}{" "}
          {firstName}{"! "}
          {t("dashboard.banner.myProfileNavCompleteTxt2")}
        </div>
        <div id="job-search-heading" className={styles.exploreSubHeading}>
          {t("dashboard.banner.dashProfileBannerTxt4")} {" "}
          {t("dashboard.banner.dashProfileBannerTxt5")} {" "}
          {t("dashboard.banner.dashProfileBannerTxt6")}
        </div>

        <div>
          {searchBar}
        </div>
      </div>
    </div>
  );
}

Search.defaultProps = {
  firstName: null
}

const mapStateToProps = (state) => ({
  completedSteps: state.onBoarding.completedSteps,
  useProfileIframe: state.auth.useProfileIframe,
  firstName: state.auth.firstName
});

export default connect(mapStateToProps)(withTranslation()(Search));