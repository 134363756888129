/* eslint-disable react/jsx-props-no-spreading */ // Spread operator needed for abstracting MUI components

import { styled } from "@mui/material/styles";
import { PickersDay, PickersDayProps } from "@mui/x-date-pickers/PickersDay";
import dayjs, { type Dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";
import DialogActions from "@mui/material/DialogActions";
import { PickersActionBarProps } from "@mui/x-date-pickers/PickersActionBar";
import { PropsWithChildren, RefAttributes, useState } from "react";
import { Button, IconButton, TextField, InputAdornment } from "@mui/material";
import Box from "@mui/material/Box";
import { TextFieldProps } from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { ArrowDropDownIcon, ArrowLeftIcon, ArrowRightIcon, DatePicker, DatePickerProps, DateView, PickersCalendarHeaderProps } from "@mui/x-date-pickers";
import clsx from "clsx";
import EventIcon from "@mui/icons-material/Event";
import { useTranslation } from "react-i18next";
import CheckIcon from "@mui/icons-material/Check";

import styles from "./MultiDatePicker.module.scss";

dayjs.extend(utc);

function getLastDayOfMonth(date: Date): number {
  const nextMonth = new Date(date);

  nextMonth.setMonth(nextMonth.getMonth() + 1);
  nextMonth.setDate(0);

  return nextMonth.getDate();
}

function groupDatesByContinuousIntervals(toGroupDates: Date[]): Date[][] {
  const dates = [...toGroupDates];

  if (dates.length === 0) {
    return [];
  }

  if (dates.length === 1) {
    return [[dates[0]]];
  }

  dates.sort((dateOne, dateTwo) => dateOne.getTime() - dateTwo.getTime());

  const result: Date[][] = [];
  let currentGroup: Date[] = [dates[0]];

  dates.slice(1).forEach((current, index) => {
    const previous = currentGroup[currentGroup.length - 1];
    const millisecondsInADay = 24 * 60 * 60 * 1000;
    const isConsecutive = (
      (current.getTime() - previous.getTime() === millisecondsInADay) ||
      (current.getDate() === 1 && previous.getDate() === getLastDayOfMonth(previous))
    );

    if (isConsecutive) {
      currentGroup.push(current);
    } else {
      result.push(currentGroup);
      currentGroup = [current];
    }

    if (index === dates.length - 2) {
      result.push(currentGroup);
    }
  });

  return result;
}

function formatDatesToString(datesGroups: Date[][], isSingleDate?: boolean): string {
  const formattedDates: string[] = [];
  if (datesGroups.length === 0) {
    return "";
  }

  datesGroups.forEach(group => {
    if (group.length === 1) {
      const groupDate = dayjs(group[0]).utc()
      formattedDates.push(isSingleDate ? groupDate.format("MM/DD/YYYY") : groupDate.format("MM/DD"));
    } else {
      const startDate = dayjs(group[0]).utc().format("MM/DD");
      const endDate = dayjs(group[group.length - 1]).utc().format("MM/DD");

      formattedDates.push(`${startDate} - ${endDate}`);
    }
  });

  return formattedDates.join(", ");
}

interface DayWithStylesProps {
  isSelected: boolean;
  isEnabled: boolean;
  isStartOfRange: boolean;
  isMiddleOfRange: boolean;
  isEndOfRange: boolean;
}

const DayWithStyles = styled(PickersDay<Dayjs>, {
  shouldForwardProp: (prop) => prop !== "isSelected" && prop !== "isEnabled" && prop !== "isEndisDisabledOfRange" &&
    prop !== "isStartOfRange" && prop !== "isMiddleOfRange" && prop !== "isEndOfRange"
})<DayWithStylesProps>(({ isSelected, isEnabled, isStartOfRange, isMiddleOfRange, isEndOfRange }) => {
  let backgroundColor = "white";
  let color = styles?.colorPrimary;
  let borderTopLeftRadius = "50%";
  let borderBottomLeftRadius = "50%";
  let borderTopRightRadius = "50%";
  let borderBottomRightRadius = "50%";

  if (isStartOfRange) {
    borderTopRightRadius = "0%"
    borderBottomRightRadius = "0%"
  } else if (isMiddleOfRange) {
    borderTopLeftRadius = "0%"
    borderBottomLeftRadius = "0%"
    borderTopRightRadius = "0%"
    borderBottomRightRadius = "0%"
  } else if (isEndOfRange) {
    borderTopLeftRadius = "0%"
    borderBottomLeftRadius = "0%"
  }

  if (isSelected) {
    backgroundColor = styles?.colorPrimary;
    color = "white";
  }

  if (!isEnabled) {
    backgroundColor = "white";
    color = "rgba(0, 0, 0, 0.38)";
  }

  return {
    borderTopLeftRadius,
    borderBottomLeftRadius,
    borderTopRightRadius,
    borderBottomRightRadius,
    backgroundColor: `${backgroundColor} !important`,
    color: `${color} !important`,
    height: "48px",
    width: "48px"
  };
});

type DayProps = {
  selectedDates: Date[];
  dateRanges: Date[][];
  mindate: Dayjs | null;
  maxdate: Dayjs | null;
  onDateSelect: (date: Dayjs | null) => void;
} & PickersDayProps<Dayjs>;

const Day = ({ selectedDates, dateRanges, mindate, maxdate, onDateSelect, ...props }: DayProps) => {
  const { day } = props;

  if (!selectedDates) {
    return <PickersDay  {...props} />;
  }

  const selection = selectedDates.find((selectedDate: Date) => {
    return dayjs.utc(selectedDate).isSame(day, "date");
  });

  let isStartOfRange = false;
  let isMiddleOfRange = false;
  let isEndOfRange = false;

  if (selection) {
    const foundDateRange = dateRanges.find((dateRange: Date[]) => {
      return dateRange.includes(selection);
    });

    if (foundDateRange) {
      isStartOfRange = dayjs.utc(foundDateRange[0]).isSame(dayjs.utc(selection));
      isEndOfRange = dayjs.utc(foundDateRange[foundDateRange.length - 1]).isSame(dayjs.utc(selection));
      isMiddleOfRange = foundDateRange.length > 2 && !isStartOfRange && !isEndOfRange;
    }
  }

  const isSelected = selection != null;
  const isBeforeMinDate = mindate && dayjs.utc(day).isBefore(mindate, "date");
  const isAfterMaxDate = maxdate && dayjs.utc(day).isAfter(maxdate, "date");
  const isEnabled = !isBeforeMinDate && !isAfterMaxDate;

  return (
    <DayWithStyles
      {...props}
      disableMargin
      isSelected={isSelected}
      isEnabled={isEnabled}
      isStartOfRange={isStartOfRange}
      isMiddleOfRange={isMiddleOfRange}
      isEndOfRange={isEndOfRange}
      onClick={() => onDateSelect(day)}
    />
  );
};

type ActionBarProps = {
  isAcceptDisabled: boolean;
  showClearButton: boolean;
  isSingleDate: boolean;
} & PickersActionBarProps;

const ActionBar = ({ isAcceptDisabled, showClearButton, isSingleDate, className, onClear, onCancel, onAccept }: ActionBarProps) => {
  const { t } = useTranslation();

  return (
    <DialogActions className={clsx(className)} sx={{ justifyContent: "space-between" }}>
      <div>
        {!isSingleDate && showClearButton && (
          <Button
            className={styles.actionButton}
            sx={{ textTransform: "none", color: "red", ":hover": { backgroundColor: "transparent" } }}
            onClick={onClear}
          >
            {t("datePicker.clear")}
          </Button>
        )}
      </div>

      <div>
        <Button
          type="button"
          className={clsx(styles.actionButton, styles.cancelButton)}
          sx={{ textTransform: "none", color: styles?.colorPrimary, ":hover": { backgroundColor: "transparent" } }}
          onClick={onCancel}
        >
          {t("datePicker.cancel")}
        </Button>

        <Button
          type="button"
          className={styles.actionButton}
          onClick={onAccept}
          sx={{ textTransform: "none", color: styles?.colorPrimary, ":hover": { backgroundColor: "transparent" } }}
          disabled={isAcceptDisabled}
        >
          {t("datePicker.ok")}
        </Button>
      </div>
    </DialogActions>
  )
}

type BrowserFieldProps = {
  ownerState: object;
  selectedDates: string;
  isSingleDate: boolean;
  isMobile: boolean;
  error: boolean;
  emptyLabel: string;
  handleclicked: () => void;
} & TextFieldProps;

const BrowserField = (props: BrowserFieldProps) => {
  const { t } = useTranslation();

  const {
    id,
    InputProps: { ref: containerRef } = {},
    inputProps,
    error,
    focused,
    size,
    ref,
    defaultValue,
    value,
    ownerState,
    selectedDates,
    isSingleDate,
    isMobile,
    className,
    classes,
    handleclicked,
    emptyLabel,
    ...other
  } = props;

  const isValueEmpty = selectedDates.trim().length <= 0;
  const emptyValue = emptyLabel ?? (isMobile ? t("datePicker.multiDatePickerSelect") : "");

  const createFieldStyles = () => {
    let fieldStyles = {};

    if (isMobile) {
      fieldStyles = {
        ...fieldStyles,
        ".MuiOutlinedInput-notchedOutline": {
          "& legend": {
            fontSize: "12px !important"
          }
        }
      }
    }

    return {
      ...fieldStyles,
      "& .MuiInputBase-input": {
        color: isValueEmpty ? "#babec6" : styles?.colorPrimary
      }
    };
  }
  const fieldStyles = createFieldStyles();

  return (
    <Box
      id={id}
      ref={containerRef ?? ref}
    >
      <TextField
        {...other}
        className={clsx(className, classes, styles.textField)}
        variant="outlined"
        value={isValueEmpty ? emptyValue : selectedDates}
        onClick={() => handleclicked?.()}
        placeholder={isSingleDate ? "MM/DD/YYYY" : "MM/DD"}
        error={error}
        helperText={error ? t("acknowledgements.rtoErrorMessage") : ""}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end" className={styles.calendarAdornment}>
              <EventIcon />
            </InputAdornment>
          ),
          inputProps
        }}
        InputLabelProps={{
          shrink: true
        }}
        sx={fieldStyles}
      />
    </Box>
  );
};

type CalendarHeadProps = {
  headerLabel: string | undefined;
} & PickersCalendarHeaderProps<dayjs.Dayjs>;

const CalendarHead = (props: CalendarHeadProps) => {
  const {
    currentMonth,
    minDate,
    maxDate,
    view,
    onMonthChange,
    onViewChange,
    headerLabel
  } = props;

  const isYearView = view === "year";

  const isPrevMonthDisabled = minDate && currentMonth.utc().clone().subtract(1, "month").isBefore(minDate, "month");
  const isNextMonthDisabled = maxDate && currentMonth.utc().clone().add(1, "month").isAfter(maxDate, "month");

  const onPrevClick = () => {
    onMonthChange(currentMonth.utc().subtract(1, "month"), "right");
  }

  const onSwitchViewClick = () => {
    if (onViewChange) {
      if (view === "day") {
        onViewChange("year");
      } else if (view === "year") {
        onViewChange("day");
      }
    }
  }

  const onNextClick = () => {
    onMonthChange(currentMonth.utc().add(1, "month"), "left");
  }

  return (
    <div className={clsx(styles.calendarHeader, styles.topLargeRadius)}>
      {headerLabel && (
        <div className={clsx(styles.calenderHeaderLabel, styles.topLargeRadius)}>{headerLabel}</div>
      )}
      <Box sx={{ marginTop: "0px" }} className={clsx(styles.calendarHeaderSelector, !headerLabel && styles.topLargeRadius)}>
        <IconButton
          title="Previous month"
          aria-label="Previous month"
          disabled={isPrevMonthDisabled || isYearView}
          onClick={onPrevClick}
          sx={{ padding: "0px 12px 0px 0px" }}
        >
          <ArrowLeftIcon
            className={clsx(
              isPrevMonthDisabled && styles.calendarHeaderDisabledArrow,
              !isPrevMonthDisabled && styles.calendarHeaderEnabledArrow,
              isYearView && styles.disabledMonthSwitcher
            )}
          />
        </IconButton>

        <div className={styles.calendarHeaderLabelAndSwitch}>
          <div className={styles.calendarHeaderLabel}>
            {dayjs(currentMonth).utc().format("MMM YYYY")}
          </div>

          <IconButton
            title="Switch view"
            aria-label="Switch view"
            onClick={onSwitchViewClick}
            sx={{ padding: "0px" }}
          >
            <ArrowDropDownIcon
              fontSize="small"
              className={clsx(styles.calendarHeaderEnabledArrow, view === "year" && styles.calendarHeaderArrowFlipped)}
            />
          </IconButton>
        </div>

        <IconButton
          title="Next month"
          aria-label="Next month"
          disabled={isNextMonthDisabled || isYearView}
          onClick={onNextClick}
          sx={{ padding: "0px 0px 0px 8px" }}
        >
          <ArrowRightIcon
            className={clsx(
              isNextMonthDisabled && styles.calendarHeaderDisabledArrow,
              !isNextMonthDisabled && styles.calendarHeaderEnabledArrow,
              isYearView && styles.disabledMonthSwitcher
            )}
          />
        </IconButton>
      </Box>
    </div>
  );
}

type YearSelectorProps = {
  onMonthChange: (date: Dayjs) => void;
  onViewChange: (view: DateView) => void;
  view: DateView;
  minDate: Dayjs | undefined;
  maxDate: Dayjs | undefined;
  headerLabel: string | undefined;
  currentMonth: Dayjs;
  paperStyles: Record<string, any>;
}

const YearSelector = ({
  onMonthChange,
  onViewChange,
  view,
  minDate,
  maxDate,
  headerLabel,
  currentMonth,
  paperStyles
}: YearSelectorProps) => {
  const yearMinDate = minDate ?? dayjs().utc();
  const yearMaxDate = maxDate ?? dayjs().utc();

  const months: { name: string; year: number; }[] = [];
  let currentDate = yearMinDate.clone().startOf("month");
  while (currentDate.isBefore(yearMaxDate) || currentDate.isSame(yearMaxDate, "month")) {
    months.push({
      name: currentDate.format("MMMM"),
      year: currentDate.year()
    });

    currentDate = currentDate.add(1, "month");
  }

  return (
    <Box sx={paperStyles}>
      <CalendarHead
        onMonthChange={onMonthChange}
        minDate={yearMinDate}
        maxDate={yearMaxDate}
        view={view}
        onViewChange={onViewChange}
        headerLabel={headerLabel}
        currentMonth={currentMonth}
        views={["year", "day"]}
        reduceAnimations={false}
        timezone="default"
      />

      <Box className={styles.yearViewList}>
        {months.map((month) => {
          const thisMonth = dayjs(`${month.name} ${month.year}`, "MMMM YYYY").date(15).utc();
          const isActive = thisMonth.format("MMMM YYYY") === currentMonth.format("MMMM YYYY");

          const handleClick = () => {
            onViewChange?.("day");
            onMonthChange?.(thisMonth);
          }

          return (
            <button
              key={`${month.name}-${month.year}`}
              type="button"
              onClick={handleClick}
              className={clsx(styles.yearViewItem, isActive && styles.activeYearViewItem)}
            >
              <Box>{isActive && <CheckIcon />}</Box>
              <span>{month.name}</span>
              <span>{month.year}</span>
            </button>
          );
        })}
      </Box>
    </Box>
  );
}

const usePaperStyles = (weekContainerCount: number) => {
  const additionalHeight = weekContainerCount === 6 ? 48 : 0;

  return {
    minWidth: "360px",
    width: "360px",
    minHeight: `${402 + additionalHeight}px`,
    height: `${412 + additionalHeight}px`,
    overflow: "hidden",
    "@media (max-width: 720px)": {
      minWidth: "320px",
      width: "320px",
    },
    "& .MuiDateCalendar-root": {
      maxHeight: `${347 + additionalHeight}px`,
      height: `${347 + additionalHeight}px`,
      width: "360px",
      "@media (max-width: 720px)": {
        width: "320px",
      },
      "& .MuiDayCalendar-slideTransition": {
        minHeight: `${242 + additionalHeight}px`,
        overflowX: "clip"
      },
    },
    borderRadius: "30px",
    ".MuiPickersFadeTransitionGroup-root": {
      minHeight: `${312 + additionalHeight}px`,
    },
    "& .MuiPickersYear-yearButton.Mui-selected": {
      backgroundColor: `${styles?.colorPrimary} !important`
    },
    "& .MuiButton-text": {
      fontFamily: "'Nunito Sans', Helvetica, Arial, sans-serif"
    },
    "& .MuiTypography-root": {
      fontFamily: "'Nunito Sans', Helvetica, Arial, sans-serif",
    },
    "& .MuiDayCalendar-weekDayLabel": {
      fontFamily: "'Nunito Sans', Helvetica, Arial, sans-serif",
      color: "#1d1b20",
      height: "48px",
      width: "48px",
      fontSize: "16px"
    },
    "& .MuiPickersDay-root": {
      fontFamily: "'Nunito Sans', Helvetica, Arial, sans-serif",
      color: "#24252b",
      fontSize: "16px",
    },
    "& .MuiDayCalendar-weekContainer": {
      margin: "0px"
    },
    "& .MuiDayCalendar-header": {
      margin: "0px 10px",
      "@media (max-width: 720px)": {
        margin: "0px"
      }
    }
  };
}

type MultiDatePickerProps = {
  selectedDates: Date[];
  isSingleDate?: boolean;
  isMobile?: boolean;
  headerLabel?: string;
  emptyLabel?: string;
  error?: boolean;
  setRtoErrorFlag?: (error: boolean) => void;
  onSelectedDatesChange: (dates: Date[]) => void;
} & DatePickerProps<Dayjs> & RefAttributes<HTMLDivElement>;

export default function MultiDatePicker({ selectedDates, isSingleDate, isMobile, error, setRtoErrorFlag, headerLabel, emptyLabel, onSelectedDatesChange, ...props }: MultiDatePickerProps) {
  const { minDate, maxDate } = props;

  const [weekContainerCount, setWeekContainerCount] = useState<number>(5);

  const paperStyles = usePaperStyles(weekContainerCount);

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [view, setView] = useState<DateView>("day");

  const getDefaultCurrentMonth = () => {
    let firstOfMonth = minDate ? minDate.startOf("month").utc() : dayjs().utc().startOf("month");

    if (selectedDates.length > 0) {
      const sortedSelectedDates = [...selectedDates];

      sortedSelectedDates.sort((a, b) => a.valueOf() - b.valueOf());

      firstOfMonth = dayjs(sortedSelectedDates[0]).utc().startOf("month");
    }

    return firstOfMonth;
  }
  const [currentMonth, setCurrentMonth] = useState<Dayjs>(getDefaultCurrentMonth());

  const [tempSelectedDates, setTempSelectedDates] = useState<Date[]>([]);
  const tempDateRanges = groupDatesByContinuousIntervals(tempSelectedDates).filter(group => group.length > 1);

  const displayedSelectedDates = formatDatesToString(groupDatesByContinuousIntervals(selectedDates), isSingleDate);

  function calculateWeekContainerCount(month: Dayjs) {
    const firstDayOfMonth = month.utc().startOf("month");
    const firstDayWeekday = firstDayOfMonth.utc().day();
    const totalDaysInMonth = firstDayOfMonth.utc().daysInMonth();
    const totalDisplayDays = firstDayWeekday + totalDaysInMonth;
    const numRows = Math.ceil(totalDisplayDays / 7);

    return numRows;
  }

  const handleDateSelect = (newDate: Dayjs | null) => {
    let newSelectedDates = [...tempSelectedDates];

    if (isSingleDate) {
      if (!(newSelectedDates.length > 0 && newDate && dayjs.utc(newSelectedDates[0]).isSame(newDate, "date"))) {
        if (newDate) {
          newSelectedDates = [newDate.toDate()];
        }
      }
    } else if (newDate) {
      const selection = newSelectedDates.find(selectedDate => {
        return dayjs.utc(selectedDate).isSame(newDate, "date");
      });

      if (selection) {
        newSelectedDates = newSelectedDates.filter(selectedDate => {
          return !dayjs.utc(selectedDate).isSame(selection);
        });
      } else {
        newSelectedDates.push(newDate.toDate());
      }
    }

    setTempSelectedDates(newSelectedDates);
  };

  const handleViewChange = (newView: DateView) => {
    setView(newView);
  }

  const handleMonthChange = (month: Dayjs) => {
    const firstOfMonth = month.startOf("month").date(15).utc();

    setCurrentMonth(firstOfMonth);
    setWeekContainerCount(calculateWeekContainerCount(firstOfMonth));
  }

  const handleOpen = () => {
    const newCurrentMonth = getDefaultCurrentMonth();

    handleMonthChange(newCurrentMonth);
    setRtoErrorFlag?.(false)
    setTempSelectedDates(selectedDates);
    setIsOpen(true);
  }

  const handleClear = () => {
    setTempSelectedDates([]);
  }

  const handleClose = () => {
    setIsOpen(false);
    setTempSelectedDates([]);
    setView("day");
  }

  const handleAccept = () => {
    onSelectedDatesChange(tempSelectedDates);
    handleClose();
  }

  const handleCancel = () => {
    handleClose();
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        {...props}
        className={clsx(props.className)}
        closeOnSelect={false}
        open={isOpen}
        onOpen={handleOpen}
        onClose={handleClose}
        onMonthChange={handleMonthChange}
        view={view}
        onViewChange={handleViewChange}
        value={currentMonth}
        defaultCalendarMonth={currentMonth}
        viewRenderers={{
          year: (yearProps) => {
            const handleMonthChangeWithinYear = (month: Dayjs) => {
              if (yearProps.onMonthChange) {
                yearProps.onMonthChange(month);
              }
            }

            const handleViewChangeWithinYear = (newView: DateView) => {
              if (yearProps.onViewChange) {
                yearProps.onViewChange(newView);
              }
            }

            return (
              <YearSelector
                onMonthChange={handleMonthChangeWithinYear}
                onViewChange={handleViewChangeWithinYear}
                view={view}
                minDate={yearProps.minDate}
                maxDate={yearProps.maxDate}
                headerLabel={headerLabel}
                currentMonth={currentMonth}
                paperStyles={paperStyles}
              />
            );
          }
        }}
        desktopModeMediaQuery={!isMobile ? "@media (pointer:coarse)" : "@media (pointer:none)"}
        slots={{
          actionBar: (properties) => {
            if (view === "year") {
              return null;
            }

            return (
              <ActionBar
                {...properties}
                isAcceptDisabled={selectedDates.length === 0 && tempSelectedDates.length === 0}
                showClearButton={tempSelectedDates.length > 0}
                onAccept={handleAccept}
                onClear={handleClear}
                onCancel={handleCancel}
                isSingleDate={isSingleDate ?? false}
              />
            );
          },
          calendarHeader: (properties) => {
            return (
              <CalendarHead
                {...properties}
                headerLabel={headerLabel}
                currentMonth={currentMonth}
              />
            );
          },
          day: (properties: PropsWithChildren<PickersDayProps<Dayjs>>) => {
            return (
              <Day
                {...properties}
                selectedDates={tempSelectedDates}
                dateRanges={tempDateRanges}
                mindate={minDate ?? null}
                maxdate={maxDate ?? null}
                onDateSelect={handleDateSelect}
              />
            );
          },
          textField: BrowserField as any
        }}
        slotProps={{
          textField: {
            selectedDates: displayedSelectedDates,
            handleclicked: handleOpen,
            isSingleDate,
            isMobile,
            error,
            emptyLabel
          } as BrowserFieldProps,
          toolbar: {
            hidden: true
          },
          desktopPaper: {
            sx: paperStyles
          },
          mobilePaper: {
            sx: paperStyles
          }
        }}
      />
    </LocalizationProvider>
  );
}

MultiDatePicker.defaultProps = {
  isSingleDate: false,
  isMobile: false,
  headerLabel: undefined,
  emptyLabel: undefined,
  error: false,
  setRtoErrorFlag: undefined
};