import {  useLayoutEffect, useRef, useState } from "react";
import Card from "@material-ui/core/Card";
import Image from "react-bootstrap/Image";
import { connect, useSelector, useDispatch } from "react-redux";
import MapIconJobDetail from "src/imagesV2/MapIconJobDetail.svg";
import { withTranslation, useTranslation } from "react-i18next";
import Typography from "@material-ui/core/Typography";
import JobDisclaimerToolTip from "src/components/common/MainContent/JobResults/JobDisclaimerToolTip";
import JobViewsChipIcon from "src/imagesV2/View.svg";
import Chip from "@material-ui/core/Chip";
import config from "src/config";
import { useHistory } from "react-router-dom";
import { JOB_VIEWS, HOMEPAGE } from "src/constants/campaigns_constants";
import { JOBS_DETAILS } from "src/router/routes";
import jobsearchQueryBuilder from "src/services/QueryBuilder/JobSearchQueryBuilder";
import { detectJobCardClick } from "src/redux/jobDetails/action";
import {
  jobDetailType,
  MyJobsFilters,
} from "src/components/common/MainContent/JobResults/common/index";
import getCandidateInformation, {
  OnboardingInfo,
} from "src/components/common/CandidateInformation/CandidateInformation";
import { useMediaQuery } from "@material-ui/core";
import { useTheme } from "@mui/material/styles";
import style from "./JobViewsDashboardAdvertisement.module.scss";
import JobsCarousel from "../../JobDescription/JobsCarousel";
import JobViewsSkeleton from "./JobViewsSkeleton";


interface RootState {
  job: jobDetailType;
  company: {
    company: {
      id: string;
    };
  };
  isRecommendedJobsOnResult?: boolean;
  campaignCode: string;
  sourceType: string;
  handleEmailCLick: (jobId: string) => void;
  similarOrViewed: string;
  recommended: boolean;
  viewMyJobs: boolean;
  myJobsFilter: MyJobsFilters;
  onBoarding: OnboardingInfo;
  candidate: {
    atsBullhornCandidateId: string;
    bullhornIdFromBullhorn: string;
  };
  bhId: string;
  isHotJobsCard?: boolean;
  myJobs: {
    filterSelected: MyJobsFilters;
  };
}
interface JobViewsInterface {
  jobs: any;
  loading: boolean;
}

const JobViewsDashboardAdvertisement = ({ jobs, loading }: JobViewsInterface) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const companyId = useSelector((state: RootState) => state.company.company.id);
  const atsBullhornCandidateId = useSelector(
    (state: RootState) => state.candidate.atsBullhornCandidateId
  );
  const bhId = useSelector((state: RootState) => state.candidate.bullhornIdFromBullhorn);
  const onboardingInfo = useSelector((state: RootState) => state.onBoarding);
  const myJobsFilter = useSelector((state: RootState) => state.myJobs.filterSelected);
  const history = useHistory();
  const theme = useTheme();
  const isXSmall = useMediaQuery(theme.breakpoints.down("xs"));
  const isSmall = useMediaQuery(theme.breakpoints.between("xs", "sm"));
  const jobViewCardContainerRef = useRef<HTMLDivElement | null>(null);
  const jobViewCardRef = useRef<HTMLDivElement | null>(null);
  const [numberOfCards,setNumberOfCards] = useState(4)
  const [titleContainerComputedMargin,setTitleContainerComputedMargin]=useState(0)

  const maxSalary = (max) => {
    return (
      <b>
        ${max}/{t("jobs.jobsCard.weekly")}
      </b>
    );
  };

  const renderSalaryToolTip = (salary, job) => {
    if (!salary) {
      return (
        <JobDisclaimerToolTip translate={t} isJobDetail={false} defaultMessage isSalary={false} />
      );
    }
    if (job?.salaryHourly && job?.salaryExpenseReimbursementWeekly) {
      return (
        <JobDisclaimerToolTip
          translate={t}
          isJobDetail={false}
          salaryHourly={job?.salaryHourly}
          salaryWeekly={job?.salaryExpenseReimbursementWeekly}
          isSalary
          isJobViews
        />
      );
    }
    return <JobDisclaimerToolTip translate={t} isJobDetail={false} defaultMessage isSalary />;
  };

  const handleClick = (job): void => {
    if (myJobsFilter === "assignments") {
      return;
    }

    window.scrollTo(0, 0);
    sessionStorage.setItem("scrollPosition", window.pageYOffset.toString());
    const candidateInformation = getCandidateInformation(onboardingInfo);
    const obj = {
      isCardClicked: true,
      job,
      isRecommended: false,
      campaignCode: JOB_VIEWS,
      companyId,
      size: config.JOB_COUNT,
      candidateId: atsBullhornCandidateId || bhId,
      candidateInformation,
    };
    dispatch(detectJobCardClick(obj));
    const sourceType = HOMEPAGE;
    const queryString = `jobId=${job?.id}&companyId=${companyId}&campaignCode=${obj.campaignCode
      }&isRecommended=${false}&isApplied=${job?.isApplied}&sourceType=${sourceType}`;
    jobsearchQueryBuilder.setOpenJobDetails(true);
    history.push({
      pathname: JOBS_DETAILS,
      search: queryString,
    });
  };

  const getJobCardDetails = (maxCardsToShow:number) => {
    return jobs?.slice(0, maxCardsToShow).map((job) => (
      <div
        role="button"
        tabIndex={0}
        onKeyDown={() => handleClick(job)}
        onClick={() => handleClick(job)}
      >
        <Card ref ={jobViewCardRef } className={style.jobCardViewMain}>
          <div >
            <div>
              <span className={style.jobCardViewsHeader}>
                <span className={style.jobCardViewsCityName}>
                  <Image src={MapIconJobDetail} className="mr-1" />
                  {job?.city}, {job?.state}
                </span>

                <span className={style.jobViewsAdvChip}>
                  {job?.views > 10 && (
                    <Chip
                      size="medium"
                      icon={<Image src={JobViewsChipIcon} />}
                      className={style.jobViewsChip}
                      label={`${job?.views} ${t("dashboard.jobsViews.views")}`}
                    />
                  )}
                </span>
              </span>
              <div className={style.jobFacilityName}>
                <span>{job?.facilityName}</span>
              </div>
            </div>
          </div>

          <Typography>
            <span>
              {job?.salaryMax && job?.salaryHourly && job?.salaryExpenseReimbursementWeekly ? (
                <span className={style.jobCardSalary}>
                  {job.salaryMax && job.salaryMax !== 0 && maxSalary(job.salaryMax)}
                </span>
              ) : null}
            </span>
            {renderSalaryToolTip(job?.salaryMax, job)}
          </Typography>

          <div className={style.jobCardTitle}>{job?.title}</div>
        </Card>
      </div>
    ));
  };
  useLayoutEffect(() => {
    const showCardsOnResize = () => {
      const jobViewCardElement = document.getElementsByClassName(style.jobCardViewMain)[0] as HTMLElement|| {offsetWidth:340}
      if(jobViewCardContainerRef.current && jobViewCardElement){
        const cardContainerWidth = jobViewCardContainerRef.current.offsetWidth
        const cardWidth = jobViewCardElement.offsetWidth
        const cardWidthWithGridGap = cardWidth + 15
        const totalCardWidth = cardWidthWithGridGap * Math.min(4,Math.floor(cardContainerWidth/cardWidthWithGridGap))
        const containerPadding = ((cardContainerWidth-totalCardWidth)/2)+7
        setTitleContainerComputedMargin(containerPadding)
        setNumberOfCards(Math.floor(cardContainerWidth/cardWidthWithGridGap))
        getJobCardDetails(Math.floor(cardContainerWidth/cardWidthWithGridGap))
        }
    }
    showCardsOnResize();
    window.addEventListener("resize", showCardsOnResize);
    return () => window.removeEventListener("resize", showCardsOnResize);
  }, [ jobs]);

  if (loading) {
    return (
      <div ref={jobViewCardContainerRef}  className={style.jobViewCard}>
        <JobViewsSkeleton />
      </div>
    );
  }
  if (isXSmall || isSmall) {
    return (
      <div ref={jobViewCardContainerRef}  className={style.jobViewCard}>
        {jobs && jobs.length > 0 && (
          <JobsCarousel
            card={getJobCardDetails(4)}
            jobViews
            title={t("dashboard.jobsViews.ProfileIncMobile")}
          />
        )}
      </div>
    );
  }

  return (
    <>
    {jobs && jobs.length > 0 && (
      <div>
        <h2 className={style.incompleteProfileTitle} style={{ marginLeft: `${titleContainerComputedMargin}px` }}>{t("dashboard.jobsViews.profileIncomplete")}</h2>
        <div ref={jobViewCardContainerRef} className={style.jobViewCard} style={{ marginLeft: `${titleContainerComputedMargin}px` }}>
          {getJobCardDetails(numberOfCards)}
        </div>
      </div>
    )}
    </>
  );
};

export default connect()(withTranslation()(JobViewsDashboardAdvertisement));
