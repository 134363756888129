import API from "../../services/Api";

export const UPDATE_USER_REQUEST = "UPDATE_USER_REQUEST";
export const updateUserRequest = (payload) => ({ type: UPDATE_USER_REQUEST, payload });

export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const updateUserSuccess = (payload) => ({ type: UPDATE_USER_SUCCESS, payload });

export const UPDATE_USER_FAILURE = "UPDATE_USER_FAILURE";
export const updateUserFailure = (payload) => ({ type: UPDATE_USER_FAILURE, payload });

export const updateUserLastLoginDate = (reqObj) => (dispatch) => {
  dispatch(updateUserRequest());
  const obj = {
    lastLoginDate: reqObj.lastLoginDate,
  };
  API.post(`/users/${reqObj.email}/lastLoginDate`, JSON.stringify(obj), {
    headers: {
      "content-type": "application/json",
    },
  })
    .then((response) => {
      if (response.status === "200") {
        dispatch(updateUserSuccess(response.data.content));
      } else dispatch(updateUserFailure(response));
    })
    .catch((error) => {
      dispatch(updateUserFailure(error));
    });
};
