import { render } from "react-dom";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router/immutable";
import { I18nextProvider } from "react-i18next";
import init from "./redux/init";
import configureStore from "./redux/store";
import history from "./redux/history";
import i18n from "./translations/i18n";
import Analytics from "./services/Analytics";
import App from "./App";
import "bootstrap/dist/css/bootstrap.min.css";
import ThemeSelector from "./theme/ThemeSelector";

console.info("NODE_ENV", process.env.NODE_ENV);

if (process.env.NODE_ENV === "development") {
  const { worker } = require("./mocks/browser.ts");
  worker.start();
}

Analytics.init();

let prevPath: string;
let prevPageUrl: string;
history.listen((location) => {
  localStorage.setItem("previousPageUrl", prevPageUrl);
  Analytics.sendPageview(location);
  if (location.pathname !== prevPath) {
    prevPath = location.pathname;
    prevPageUrl = window.location.href;
    Analytics.segmentPageView();
  }
});

const store = configureStore({ settings: { theme: "default" } });
init(store);

const target = document.querySelector("#root");
render(
  <ThemeSelector>
    <I18nextProvider i18n={i18n}>
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <App />
        </ConnectedRouter>
      </Provider>
    </I18nextProvider>
  </ThemeSelector>,
  target
);
