import WeatherWidget from "src/components/common/MainContent/JobDescription/MoreInfo/WeatherWidget/WeatherWidget";

interface IMoreInfo {
  weatherData: { highTemp: number[]; lowTemp: number[]; precipitation: number[] };
  trackDragEvent: () => void
}

const MoreInfo = ({ weatherData, trackDragEvent }: IMoreInfo) => {
  return (
    <>
      <WeatherWidget weatherData={weatherData} trackDragEvent={trackDragEvent} />
    </>
  );
};

export default MoreInfo;
