import styles from "./NoNotification.module.scss";

const NoNotification = () => (
  <div className={styles.NoNotification}>
    <div className={styles.NoNotificationHeader}>No notifications yet!</div>
    <div className={styles.NoNotificationText}>We&#39;ll notify you when something arrives.</div>
  </div>
);

export default NoNotification;
