import { Theme, withStyles } from "@material-ui/core/styles";
import BaseButton from "./BaseButton";

const PrimaryButton = withStyles((theme) => {
  const themeWithProps = theme as Theme & {
    palette: { colorPrimary: string; colorPrimaryHover: string };
  };
  return {
    root: {
      display: "flex",
      backgroundColor: themeWithProps.palette.colorPrimary,
      color: "#ffffff",
      border: "none",
      "&:hover": {
        boxShadow: "0px 4px 2px -2px #00000070",
        backgroundColor: themeWithProps.palette.colorPrimaryHover,
      },
    },
  };
})(BaseButton);

export default PrimaryButton;
