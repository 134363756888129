import {
  FETCH_ASSIGNMENTS_REQUEST,
  FETCH_ASSIGNMENTS_SUCCESS,
  FETCH_ASSIGNMENTS_FAILURE,
  PAYROLL_UPDATE_NAVIGATION_KEY,
  FETCH_TIMESHEETS,
  NO_ASSIGNMENTS_FOUND,
  FETCH_PAYCHECKS,
  FETCH_PAYCHECKS_PAGINATION,
} from "./action";
import { PAYROLL_TIMESHEET } from "../../router/routes";

const initialState = {
  loading: false,
  error: null,
  assignments: null,
  latestAssignment: null,
  timeSheets: null,
  payPeriods: null,
  navigationKey: localStorage.getItem("payrollNavigationKey")
    ? localStorage.getItem("navigationKey")
    : PAYROLL_TIMESHEET,
  isAssignmentEmpty: false,
  payChecks: null,
  paginatedPaychecks: null,
  paycheckNumber: 0,
  payCheckPageSelected: 1,
};
const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case PAYROLL_UPDATE_NAVIGATION_KEY:
      localStorage.setItem("payrollNavigationKey", action.payload);
      return { ...state, navigationKey: action.payload };
    case FETCH_ASSIGNMENTS_REQUEST:
      return { ...state, loading: true, error: false, assignments: null };
    case FETCH_ASSIGNMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        assignments: action.payload,
        latestAssignment: action.payload.assignments[0],
      };
    case FETCH_ASSIGNMENTS_FAILURE:
      return { ...state, loading: false, error: action.payload, assignments: null };
    case FETCH_TIMESHEETS:
      return { ...state, timeSheets: action.obj.timesheets, totalHours: action.obj.totalHours };
    case NO_ASSIGNMENTS_FOUND:
      return { ...state, isAssignmentEmpty: action.payload };
    case FETCH_PAYCHECKS:
      return {
        ...state,
        payChecks: action.paychecksAsPerPagination,
        paycheckNumber: action.totalPaychecks,
        paginatedPaychecks: action.paginatedPaychecks,
      };
    case FETCH_PAYCHECKS_PAGINATION:
      return {
        ...state,
        paginatedPaychecks: action.paychecksAsPerPagination,
        payCheckPageSelected: action.pageNumber,
      };
    default:
      return state;
  }
};

export default rootReducer;
