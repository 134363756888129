/* eslint-disable consistent-return */
import { useState, useEffect } from "react";

const initBeforeUnLoad = (showExitPrompt: boolean) => {
  window.onbeforeunload = (event) => {
    if (showExitPrompt) {
      const e = event || window.event;
      e.preventDefault();
      if (e) e.returnValue = "exit";
      return "true";
    }
  };
};

const useExitPrompt = (isExit: boolean) => {
  const [showExitPrompt, setShowExitPrompt] = useState(isExit);

  window.onload = () => initBeforeUnLoad(showExitPrompt);

  useEffect(() => {
    initBeforeUnLoad(showExitPrompt);
  }, [showExitPrompt]);

  return [showExitPrompt, setShowExitPrompt] as const;
};

export default useExitPrompt;
