import React from "react";
import { connect } from "react-redux";
import { Route } from "react-router-dom";
import { push } from "connected-react-router";
import { isLoggedIn } from "axios-jwt";
import { AUTH, HOME } from "./routes";
import getCompanyFromDomain from "../services/Company";

const query = new URLSearchParams(window.location.search);

const LoggedOutRoute = ({ component: Component, ...otherProps }) => {
  let computedMatch = null;
  const redirectionPage = query?.has("redirectionPage") ? query.get("redirectionPage") : null;
  query?.delete("redirectionPage");

  if (otherProps.computedMatch !== undefined) computedMatch = otherProps.computedMatch;

  if (isLoggedIn()) {
    otherProps.push(HOME);
  }
  const company = getCompanyFromDomain();
  if (company.id === "1" && otherProps.path === AUTH) {
    otherProps.push(HOME);
  }

  return (
    <Route
      render={(otherProps) => (
        <Component
          {...otherProps}
          computedMatch={computedMatch}
          redirectionPage={redirectionPage}
        />
      )}
    />
  );
};

export default connect(null, { push })(LoggedOutRoute);
