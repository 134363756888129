import { FC, ReactElement, useState, useRef, useEffect, useCallback } from "react";
import Webcam from "react-webcam";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Box, makeStyles } from "@material-ui/core";
import ReactCrop, { Crop, PixelCrop } from "react-image-crop";
import CameraAltOutlinedIcon from "@mui/icons-material/CameraAltOutlined";
import CropOutlinedIcon from "@mui/icons-material/CropOutlined";
import RotateLeftOutlinedIcon from "@mui/icons-material/RotateLeftOutlined";
import RotateRightOutlinedIcon from "@mui/icons-material/RotateRightOutlined";
import IconButton from "@material-ui/core/IconButton";
import "react-image-crop/dist/ReactCrop.css";
import { base64ToFile } from "src/services/utility";
import TertiaryButton from "../Buttons/TertiaryButton";
import PrimaryButton from "../Buttons/PrimaryButton";

const useStyles = makeStyles(() => ({
    actionButton: {
        width: "100%",
        padding: "9px",
        fontWeight: "bold",
        fontSize: "16px",
        border: "none",
    },
    nextBtnMarginleft: {
        marginLeft: "auto",
        fontWeight: "bold",
        fontSize: "16px",
        border: "none",
    },
    outlineBtnColor: {
        border: "1px solid #707070 !important",
        color: "#707070",
        fontWeight: "bold",
        fontSize: "16px",
    },
    roundedButton: {
        width: "45px",
        height: "45px",
        background: "#FFFFFF",
        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        borderRadius: "50%",
        zIndex: 1,
        "&:hover": {
            background: "#FFFFFF",
        },
    },
    iconButtonGroupRow: {
        position: "sticky",
        width: "fit-content",
        marginTop: "-120pt",
        marginLeft: "4pt",
    },
    iconButtonRow: {
        marginBottom: "5pt",
    },
    previewImgBox: {
        textAlign: "center",
        margin: "auto",
        width: "fit-content",
        height: "fit-content",
        minHeight: "30vh",
    }
}));

type Side = "front" | "back";

interface CertificationsLicensesWebcamProps {
    uploaded: (File | null)[];
    setUploaded: (file: (File | null)[]) => void;
    setCapturing: (capture: boolean) => void;
    setUploadedStatus: (rec: Record<Side, string | null>) => void;
    uploadedStatus: Record<Side, string | null>;
    webcamImageCapture: () => void;
    capturingSide: Side;
}

const sideToIndex = {
    front: 0,
    back: 1,
};

const CertificationsLicensesWebcam: FC<CertificationsLicensesWebcamProps> = ({
    uploaded,
    setUploaded,
    setCapturing,
    setUploadedStatus,
    uploadedStatus,
    webcamImageCapture,
    capturingSide,
}): ReactElement => {
    const { t } = useTranslation();
    const classes = useStyles();
    const videoConstraints = {
        width: 1920,
        height: 1680,
        facingMode: "user"
    };
    const imgRef = useRef<HTMLImageElement>(null);
    const imagePreviewCanvasRef = useRef<HTMLCanvasElement>(null);
    const webcamRef = useRef<Webcam>(null);
    const [imgSrc, setImgSrc] = useState<string | null>(null);
    const [crop, setCrop] = useState<Crop>();
    const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
    const [cropping, setCropping] = useState<boolean>(false);
    const [scale, setScale] = useState<number>(1);
    const [rotation, setRotation] = useState<number>(0);
    const [imgRotation, setImgRotation] = useState<number>(0);
    const [edited, setEdited] = useState<boolean>(false);

    const setRotationStates = (angleArg: number) => {
        setRotation(angleArg);
        setImgRotation(angleArg);
    }

    const resetCropStates = () => {
        setCrop(undefined);
        setCompletedCrop(undefined);
    }

    const getBase64FromImgSrc = (imageSrc: string | null) => {
        const encodedImageData = (imageSrc && imageSrc?.split(",")?.length > 1) ? imageSrc?.split(",")[1] : null;
        return encodedImageData;
    }

    const handleOnCropChange = (c: Crop) => {
        setEdited(true);
        setCrop(c);
    }

    const handleRetake = () => {
        setImgSrc(null);
        setCropping(false);
        setRotationStates(0);
        setScale(1);
        setEdited(false);
    }

    const useCapturedImage = () => {
        let encodedImgData: string | null = null;
        if (edited) {
            const canvasRef = imagePreviewCanvasRef.current;
            let imageSrc: string | null = canvasRef?.toDataURL("image/png") || null;
            encodedImgData = getBase64FromImgSrc(imageSrc);

            const ctx = canvasRef?.getContext("2d");
            if (!canvasRef || !ctx || !imageSrc) return;
            ctx?.scale(1, 1);
            ctx?.translate(0, 0);
            const image = new Image();
            image.onload = () => {
                ctx?.drawImage(image, 0, 0);
            }
            image.src = imageSrc;
            imageSrc = canvasRef?.toDataURL("image/png") || null;
            encodedImgData = getBase64FromImgSrc(imageSrc);
        } else {
            encodedImgData = getBase64FromImgSrc(imgSrc);
        }
        const currentTime = new Date().getTime();
        const file = base64ToFile(encodedImgData, `webcam_${currentTime}_${capturingSide?.toLowerCase()}.png`, "image/png");
        const upload = [...uploaded];
        upload[sideToIndex[String(capturingSide?.toLowerCase())]] = file;
        setUploaded(upload);
        setUploadedStatus({ ...uploadedStatus, front: null });
        setCapturing(false);
        webcamImageCapture?.();
    }

    const onCropButtonClick = () => {
        setCropping(true);
    }

    const handleCancelCropping = () => {
        setCropping(false);
        resetCropStates();
    }

    const handleUseCroppedImage = () => {
        const canvasRef = imagePreviewCanvasRef.current;
        const imageSrc: string | null = canvasRef?.toDataURL("image/png") || null;
        setImgSrc(imageSrc);
        setCropping(false);
        resetCropStates();
        setImgRotation(0);
        setRotation(0);
    }

    const canvasPreview = (
        image: HTMLImageElement,
        canvas: HTMLCanvasElement,
        pixelCropArg: PixelCrop | null,
        scaleArg = 1,
        rotate = 0,
    ) => {
        const ctx = canvas.getContext("2d")
        if (!ctx) {
            return;
        }
        let [pixelCropX, pixelCropY, pixelCropWidth, pixelCropHeight] = [0, 0, 0, 0]
        const [scaleX, scaleY] = [image.naturalWidth / image.width, image.naturalHeight / image.height];
        const pixelRatio = window.devicePixelRatio

        if (pixelCropArg) {
            [pixelCropX, pixelCropY, pixelCropWidth, pixelCropHeight] = [pixelCropArg.x, pixelCropArg.y, pixelCropArg.width, pixelCropArg.height]
        } else {
            [pixelCropX, pixelCropY, pixelCropWidth, pixelCropHeight] = [0, 0, image.naturalWidth, image.naturalHeight]
        }

        canvas.setAttribute("width", Math.floor(pixelCropWidth * scaleX * pixelRatio).toString());
        canvas.setAttribute("height", Math.floor(pixelCropHeight * scaleY * pixelRatio).toString());

        ctx.scale(pixelRatio, pixelRatio);
        ctx.imageSmoothingQuality = "high";

        const [cropX, cropY] = [pixelCropX * scaleX, pixelCropY * scaleY];

        const rotateRads = rotate * Math.PI / 180;
        const [centerX, centerY] = [image.naturalWidth / 2, image.naturalHeight / 2];
        ctx.save()
        ctx.translate(-cropX, -cropY)
        ctx.translate(centerX, centerY)
        ctx.rotate(rotateRads)
        if ((pixelCropArg?.width && pixelCropArg?.height) || (rotation === 90 || rotation === 270)) {
            ctx.scale(scaleArg, scaleArg);
        }
        ctx.translate(-centerX, -centerY)
        ctx.drawImage(
            image,
            0,
            0,
            image.naturalWidth,
            image.naturalHeight,
            0,
            0,
            image.naturalWidth,
            image.naturalHeight,
        )
        ctx.restore()
    }

    const callCanvasPreview = () => {
        if (
            imgRef.current &&
            imagePreviewCanvasRef.current
        ) {
            canvasPreview(
                imgRef.current,
                imagePreviewCanvasRef.current,
                completedCrop ?? null,
                scale,
                rotation,
            )
        }
    }

    useEffect(() => {
        setTimeout(() => {
            callCanvasPreview();
        }, 100)
    }, [completedCrop, rotation])

    const rotateImage = async (angleIndex: number) => {
        setEdited(true);
        const angles = [0, 90, 180, 270];
        const reverseAngles = [270, 180, 90, 0];
        const canvas = imgRef.current;
        if (!canvas) return;
        let index = -1;
        if (angleIndex > 0) {
            index = angles.indexOf(rotation);
            setRotationStates(angles[(index + angleIndex) % angles.length]);
            if (index % 2 === 0 && scale === 1) {
                setScale(canvas.height / canvas.width);
            } else {
                setScale(1);
            }
        } else {
            index = reverseAngles.indexOf(rotation);
            setRotationStates(reverseAngles[(index - angleIndex) % reverseAngles.length]);
            if (index % 2 === 1 && scale === 1) {
                setScale(canvas.height / canvas.width);
            } else {
                setScale(1);
            }
        }
    }

    const capture = useCallback(
        () => {
            const imageSrc = webcamRef.current ? webcamRef.current.getScreenshot() : null;
            setImgSrc(imageSrc);
        },
        [webcamRef, setImgSrc]
    );

    return (
        <>
            <Box>
                {!imgSrc && (
                    <>
                        <Box>
                            <Webcam
                                audio={false}
                                ref={webcamRef}
                                screenshotFormat="image/jpeg"
                                videoConstraints={videoConstraints}
                                style={{ width: "100%", height: "100%" }}
                            />
                        </Box>
                        <Box paddingBottom={2} textAlign="center">
                            <PrimaryButton
                                component="button"
                                size="medium"
                                className={classes.actionButton}
                                onClick={capture}
                            >
                                <CameraAltOutlinedIcon fontSize="small" className="iconPaddingBottom" />
                                <span className="addCredentialsButtonText">
                                    {t("certificationAndLicenses.capture")}
                                </span>
                            </PrimaryButton>
                        </Box>
                    </>
                )}
                {imgSrc && (
                    <>
                        <Box>
                            {!cropping && (
                                <Box className={classes.previewImgBox}>
                                    <img alt="Crop preview"
                                        style={{ transform: `scale(${scale}) rotate(${imgRotation}deg)`, width: "100%", height: "100%" }}
                                        src={imgSrc} />
                                </Box>
                            )}

                            <Box hidden={!cropping} style={{ textAlign: "center" }}>
                                <ReactCrop crop={crop}
                                    onComplete={(c) => setCompletedCrop(c)}
                                    onChange={handleOnCropChange}>
                                    <img ref={imgRef} alt="Crop preview" style={{ transform: `scale(${scale}) rotate(${rotation}deg)` }} src={imgSrc} />
                                </ReactCrop>
                            </Box>
                            {!cropping && (<Row className={classes.iconButtonGroupRow}>
                                <Col sm={12} xs={12} md={2} lg={2}>
                                    <Row className={classes.iconButtonRow}>
                                        <IconButton size="small" component="button" className={classes.roundedButton} onClick={onCropButtonClick}>
                                            <CropOutlinedIcon fontSize="small" className="iconPaddingBottom" />
                                        </IconButton>
                                    </Row>
                                    <Row className={classes.iconButtonRow}>
                                        <IconButton size="small" component="button" className={classes.roundedButton} onClick={() => rotateImage(1)}>
                                            <RotateRightOutlinedIcon fontSize="small" className="iconPaddingBottom" />
                                        </IconButton>
                                    </Row>
                                    <Row className={classes.iconButtonRow}>
                                        <IconButton size="small" component="button" className={classes.roundedButton} onClick={() => rotateImage(-1)}>
                                            <RotateLeftOutlinedIcon fontSize="small" className="iconPaddingBottom" />
                                        </IconButton>
                                    </Row>
                                </Col>
                            </Row>)}
                        </Box>
                        {!cropping && (<Box>
                            <div className="onBNextBtn d-flex">
                                <TertiaryButton
                                    component="button"
                                    size="medium"
                                    className={classes.outlineBtnColor}
                                    onClick={handleRetake}
                                >
                                    <CameraAltOutlinedIcon fontSize="small" className="iconPaddingBottom" />
                                    <span className="addCredentialsButtonText">
                                        {t("certificationAndLicenses.retake")}
                                    </span>
                                </TertiaryButton>
                                <PrimaryButton
                                    component="button"
                                    size="medium"
                                    className={classes.nextBtnMarginleft}
                                    onClick={useCapturedImage}
                                >
                                    <span className="addCredentialsButtonText">
                                        {t("certificationAndLicenses.useThisImage")}
                                    </span>
                                </PrimaryButton>
                            </div>
                        </Box>)}
                        {cropping && (<Box>
                            <div className="onBNextBtn d-flex">
                                <TertiaryButton
                                    component="button"
                                    size="medium"
                                    className={classes.outlineBtnColor}
                                    onClick={handleCancelCropping}
                                >
                                    <span className="addCredentialsButtonText">
                                        {t("certificationAndLicenses.cancel")}
                                    </span>
                                </TertiaryButton>
                                <PrimaryButton
                                    component="button"
                                    size="medium"
                                    className={classes.nextBtnMarginleft}
                                    onClick={handleUseCroppedImage}
                                    disabled={!crop?.width || !crop?.height}
                                >
                                    <CropOutlinedIcon fontSize="small" className="iconPaddingBottom" />
                                    <span className="addCredentialsButtonText">
                                        {t("certificationAndLicenses.cropImage")}
                                    </span>
                                </PrimaryButton>
                            </div>
                        </Box>)}
                        <canvas ref={imagePreviewCanvasRef} style={{ display: "none" }} />
                    </>
                )}
            </Box>
        </>
    )
}
export default CertificationsLicensesWebcam;
