import config from "../../config";

export default class JobSearchQueryParamsDefaults {
  specialty = "";

  location = "";

  tags = "noTags";

  sort = "relevant";

  view = "list";

  offset = "1";

  companyId = "1";

  geoLocationRadius = config.GEO_LOCATION_RADIUS;

  msp = "";

  term = "";
}
