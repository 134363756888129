import {
  SET_COMPANY_FROM_DOMAIN,
  REQUEST_SET_COMPANY_DOMAIN_FOR_IFRAME,
  SET_COMPANY_DOMAIN_FOR_IFRAME_SUCCESS,
  SET_COMPANY_DOMAIN_FOR_IFRAME_FAILURE,
} from "./action";

const initialState = {
  company: null,
  companySetForIframe: false,
  loading: false,
};

const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_COMPANY_FROM_DOMAIN:
      return { ...state, company: action.payload };
    case REQUEST_SET_COMPANY_DOMAIN_FOR_IFRAME:
      return { ...state, loading: true };
    case SET_COMPANY_DOMAIN_FOR_IFRAME_SUCCESS:
      return { ...state, companySetForIframe: true, loading: false };
    case SET_COMPANY_DOMAIN_FOR_IFRAME_FAILURE:
      return { ...state, companySetForIframe: false, loading: false };
    default:
      return state;
  }
};

export default rootReducer;
