import { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import Container from "react-bootstrap/Container";
import ReactLoading from "react-loading";
import { getConitoTokenByJwt } from "src/redux/auth/action";
import Logo from "src/components/common/Header/Logo";

interface LoginJwtProps {
  getToken: (jwt: string, companyCode: string) => void;
  companyCode: {
    code: string;
    id: string;
  };
  t: (key: string) => string;
  computedMatch: {
    params: {
      jwt: string;
    };
  };
}

class LoginJwt extends Component<LoginJwtProps> {
  componentDidMount() {
    this.props.getToken(this.props.computedMatch.params.jwt, this.props.companyCode.id);
  }

  render() {
    return (
      <Container fluid className="loaderPage">
        <Container>
          <div>
            <Logo />
          </div>
          <div className="loadingTxt">{this.props.t("login.loginSuccess")}</div>
          <ReactLoading type="bubbles" className="loaderColor" />
        </Container>
      </Container>
    );
  }
}
const mapStateToProps = (state) => ({
  companyCode: state.company.company,
});
const matchDispatchToProps = (dispatch) => ({
  getToken: (jwtToken, companyId) => dispatch(getConitoTokenByJwt(jwtToken, companyId)),
});

export default connect(mapStateToProps, matchDispatchToProps)(withTranslation()(LoginJwt));
