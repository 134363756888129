import { Theme, makeStyles } from "@material-ui/core/styles";
import Slider from "@material-ui/core/Slider";
import Typography from "@material-ui/core/Typography";

interface FilterSliderProps {
  weeklyPay: string | (string | null)[] | null;
  minVal: number;
  maxVal: number;
  handleSliderChange: (event: any, value: any) => void;
  filterSliderPayTxt?: string;
}

const useStyles = makeStyles((theme) => {
  const themeWithProps = theme as Theme & {
    palette: { colorPrimary: string; colorGreyBG: string; color: string };
  };
  return {
    filterSliderPayTxt: {
      color: themeWithProps.palette.colorPrimary,
      fontWeight: 600,
      fontSize: "0.8rem",
    },
    filterSliderCust: {
      color: themeWithProps.palette.colorPrimary,
      "& .MuiSlider-track": {
        backgroundColor: themeWithProps.palette.colorGreyBG + "!important",
      },
      "& .MuiSlider-thumb.Mui-focusVisible, .MuiSlider-thumb:hover": {
        boxShadow: "0px 0px 0px 8px #003366!important;",
      },
    },
  };
});

function FilterSlider({ weeklyPay, minVal, maxVal, handleSliderChange }: FilterSliderProps) {
  const classes = useStyles();

  let weeklyPayValue;

  if (weeklyPay && typeof weeklyPay === "string") {
    weeklyPayValue = parseInt(weeklyPay);
  }

  function convertToLowerString(weeklyPay: number, minVal: number) {
    if (weeklyPay) {
      return weeklyPayValue.toLocaleString();
    } else if (minVal) {
      return weeklyPayValue.toLocaleString();
    }
    return minVal;
  }

  return (
    <div>
      <Typography className={classes.filterSliderPayTxt}>
        $
        {`${convertToLowerString(weeklyPayValue, minVal)} - $${
          maxVal ? maxVal.toLocaleString() : maxVal
        }`}
      </Typography>
      <Slider
        className={classes.filterSliderCust}
        value={typeof weeklyPayValue === "number" ? weeklyPayValue : minVal}
        onChangeCommitted={handleSliderChange}
        aria-labelledby="input-slider"
        step={500}
        min={minVal}
        max={maxVal}
        track="inverted"
      />
    </div>
  );
}

export default FilterSlider;
