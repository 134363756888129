import React, { Component } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "@material-ui/core/Card";
import JobCardSingleV2 from "./common/JobCardSingleV2";
import EmailRecruiterV2 from "./common/EmailRecruiterV2";
import HotJobsCardMaster from "src/components/common/MainContent/Home/HotJobs/HotJobsCardMaster"

class JobCardContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openEmail: false,
      emailJobId: null,
      emailJobIdArray: [],
      favJobIdArray: [],
    };
  }

  handleEmailClick(jobId) {
    this.setState((prevState) => ({
      openEmail: true,
      emailJobId: jobId,
      emailJobIdArray: prevState.emailJobIdArray.includes(jobId)
        ? [...prevState.emailJobIdArray]
        : [...prevState.emailJobIdArray, jobId],
    }));
  }

  handleEmailClose(e) {
    this.setState({ openEmail: false });
  }

  renderJobResultsCards() {
    const { jobs, jobsSource } = this.props;
    return (
      <Row>
        {jobs.map((job, index) => (
          <Col sm={12} md={4} lg={4} xs={12} key={job.id} className="pb-3 jobCardHolder">
            <Card className="jobCardMain">
              {this.state.openEmail && this.state.emailJobId === job.id ? (
                <div className="emailCard">
                  <EmailRecruiterV2
                    job={job}
                    handleClose={this.handleEmailClose.bind(this)}
                    isRecommendedJobsOnResult={this.props.isRecommendedJobsOnResult}
                    viewMyJobs={this.props.viewMyJobs}
                    campaignCode={this.props.campaignCode}
                    sourceType={this.props.sourceType}
                    jobsSource={jobsSource}
                  />
                </div>
              ) : (
                <JobCardSingleV2
                  job={job}
                  handleEmailCLick={this.handleEmailClick.bind(this, job.id)}
                  emailJobId={this.state.emailJobId}
                  similarOrViewed={this.props.similarOrViewed}
                  currentIndex={index}
                  isRecommendedJobsOnResult={this.props.isRecommendedJobsOnResult}
                  viewMyJobs={this.props.viewMyJobs}
                  campaignCode={this.props.campaignCode}
                  sourceType={this.props.sourceType}
                />
              )}
            </Card>
          </Col>
        ))}
      </Row>
    );
  }

  renderSimilarViewedRecommendedCards() {
    const { job, jobsSource } = this.props;
    return (
      <Card className="jobCardMain" style={{ margin: "10px" }}>
        {this.state.openEmail && this.state.emailJobId === job.id ? (
          <div className="emailCard">
            <EmailRecruiterV2
              job={job}
              handleClose={this.handleEmailClose.bind(this)}
              similarOrViewed={this.props.similarOrViewed}
              recommended={this.props.recommended}
              isRecommendedJobsOnResult={this.props.isRecommendedJobsOnResult}
              campaignCode={this.props.campaignCode}
              sourceType={this.props.sourceType}
              jobsSource={jobsSource}
            />
          </div>
        ) : (
          <JobCardSingleV2
            job={job}
            handleEmailCLick={this.handleEmailClick.bind(this, job.id)}
            similarOrViewed={this.props.similarOrViewed}
            recommended={this.props.recommended}
            emailJobId={job.id}
            isRecommendedJobsOnResult={this.props.isRecommendedJobsOnResult}
            campaignCode={this.props.campaignCode}
            sourceType={this.props.sourceType}
          />
        )}
      </Card>
    );
  }

  renderHotJobsCards() {
    const { job } = this.props;
    return (
      <Card className="jobCardMain">
        <HotJobsCardMaster
          job={job}
          jobCount={this.props.jobCount}
          isHotJobsCard={this.props.hotJobs}
          campaignCode={this.props.campaignCode}
          sourceType={this.props.sourceType}
        />
      </Card>
    );
  }

  renderQuickApplyAdvCard() {
    const { job } = this.props
    return (
      <JobCardSingleV2
        job={job}
        isQuickApplyAdvCard
        campaignCode={this.props.campaignCode}
        sourceType={this.props.sourceType}
      />
    )
  }

  render() {
    if (this.props.isQuickApplyAdvCard === true) {
      return this.renderQuickApplyAdvCard();
    }

    return (
      <div>
        {this.props.similarOrViewed || this.props.recommended
          ? this.renderSimilarViewedRecommendedCards()
          : this.props.hotJobs
            ? this.renderHotJobsCards()
            : this.props.isQuickApplyAdvCard ?
              this.renderQuickApplyAdvCard()
              : this.renderJobResultsCards()}
      </div>
    );
  }
}

export default JobCardContainer;
