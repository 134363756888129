const Dynatrace = () => {
  const identify = (email) => {
    if (email) {
      window.dtrum.identifyUser(email);
    }
  };

  const closeSession = () => {
    window.dtrum.endSession();
  };

  const disable = () => {
    const hostName = window.location.hostname;
    const isLocalhost = hostName.toLowerCase() === "localhost" || hostName.toLowerCase() === "127.0.0.1";
    return isLocalhost;
  };

  return {
    identify,
    closeSession,
    disable,
  };
};

export default Dynatrace;
