import { Component } from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { connect } from "react-redux";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import WbSunnyIcon from "@material-ui/icons/WbSunny";
import LoopIcon from "@material-ui/icons/Loop";
import Brightness4Icon from "@material-ui/icons/Brightness4";
import { withTranslation } from "react-i18next";
import { RouteComponentProps, withRouter } from "react-router-dom";
import Image from "react-bootstrap/Image";
import DateRangeIcon from "@material-ui/icons/DateRange";
import { getRotatingShiftLabel } from "src/services/CommonUtility";
import { fetchRecruiterDetails } from "src/redux/consultant/action";
import NightIcon from "src/imagesV2/nightIcon.svg";
import TimeIcon from "src/imagesV2/timeIcon.svg";
import MapIconJobDetail from "src/imagesV2/MapIconJobDetail.svg";
import Analytics from "src/services/Analytics";
import { postEmail } from "src/redux/userAction/emailRecruiter/action";
import eligibilityPercentageCalc from "src/components/common/EligibilityMeter/EligibilityPercentage";
import {
  EmailRecruiterV2Props,
  EmailRecruiterV2State,
  reqObjType,
} from "src/components/common/MainContent/JobResults/common/index.d";
import { event } from "react-ga";

class EmailRecruiterV2 extends Component<
  EmailRecruiterV2Props & RouteComponentProps,
  EmailRecruiterV2State
> {
  constructor(props: EmailRecruiterV2Props & RouteComponentProps) {
    super(props);

    this.state = {
      jobId: props.job.id,
      message: "",
    };
  }

  componentDidMount() {
    const {
      recruiterDetails,
      fetchRecruiterDetails: fetchTheRecruiterDetails,
      candidateId,
    } = this.props;
    if (!recruiterDetails && candidateId) {
      fetchTheRecruiterDetails(candidateId);
    }
  }

  handleChange = (e) => {
    this.setState({
      message: e.target.value.trim(),
    });
  };

  sendTheEmail = () => {
    const {
      candidateId,
      email,
      allJobs,
      similarJobsCarouselItems,
      collaboratedJobsCarouselItems,
      similarOrViewed,
      jobDetails,
      recommended,
      recommendedJobs,
      isRecommendedJobsOnResult,
      viewMyJobs,
      myJobs,
      recruiterDetails,
      sendEmail,
      handleClose,
    } = this.props;

    const { jobId, message } = this.state;
    const recruiterEmail = recruiterDetails ? recruiterDetails.emailId : "";
    const reqObj = {
      jobId,
      action: "email",
      userId: 2,
      emailReqObj: {
        messageToSend: "",
        candidateId,
        toAddress: recruiterEmail,
        fromAddress: email,
      },
      request: {
        isActive: true,
        jobId,
      },
      jobs: allJobs,
      similarJobs: similarJobsCarouselItems,
      collaboratedJobs: collaboratedJobsCarouselItems,
      similarOrViewed,
      jobDetails,
      recommended,
      recommendedJobs,
      isRecommendedJobsOnResult,
      viewMyJobs,
      myJobs,
    };

    const newMessage = message.replaceAll("\n", "\\n").replaceAll("\"", "\\\"");
    reqObj.emailReqObj.messageToSend = newMessage;

    sendEmail(reqObj);
    this.trackSegmentEvent();
    handleClose(event);
  };

  trackSegmentEvent() {
    const { job, isRecommended } = this.props;
    const eligibilityPercentage = eligibilityPercentageCalc(job || isRecommended);
    const {
      t,
      job: {
        atsJobId,
        professionCode,
        specialtyCode,
        state,
        city,
        zip,
        type,
        shift,
        salaryMax,
        facilityName,
      },
      sourceType,
      campaignCode
    } = this.props;
    const segmentEventName = t("segment.jobRecruiterEmailed");
    const segmentEventProps = {
      jobId: atsJobId,
      origin: document.referrer,
      profession: professionCode,
      specialty: specialtyCode,
      state,
      city,
      zip,
      shift,
      type,
      weeklyPay: salaryMax,
      facilityName,
      sourceType,
      sourceSubArea: campaignCode,
      eligibilityPercent: eligibilityPercentage,
    };
    Analytics.segmentTrackEvent(segmentEventName, segmentEventProps);
  }

  isShift(shift) {
    const { t } = this.props;
    const theShift = shift.toLowerCase();
    const shiftIcons = [
      {
        name: t("jobs.jobsCard.night"),
        icon: <img className="nightIcon" src={NightIcon} alt="" />,
        className: "nightIcon",
      },
      {
        name: t("jobs.jobsCard.day"),
        icon: <WbSunnyIcon className="dayIcon" fontSize="small" />,
        className: "dayIcon",
      },
      {
        name: t("jobs.jobsCard.rotation"),
        icon: <LoopIcon className="rotationIcon" fontSize="small" />,
        className: "rotationIcon",
        label: getRotatingShiftLabel,
      },
      {
        name: t("jobs.jobsCard.evening"),
        icon: <Brightness4Icon className="eveningIcon" fontSize="small" />,
        className: "eveningIcon",
        label: (shiftItem) => shiftItem.replace("evenings", "mid"),
      },
    ];

    const matchingShiftIcon = shiftIcons.find((shiftIcon) => theShift.includes(shiftIcon.name));

    if (matchingShiftIcon) {
      return (
        <b>
          {" "}
          {matchingShiftIcon.icon}{" "}
          <span className="iconLabel">
            {matchingShiftIcon.label ? matchingShiftIcon.label(theShift) : theShift}
          </span>{" "}
        </b>
      );
    }

    return null;
  }

  hoursPerWeek(hoursPerWeek) {
    const { t } = this.props;
    if (hoursPerWeek) {
      return <> {`${hoursPerWeek} ${t("jobs.jobsCard.hrsPerWeek")}`} </>;
    }
    return null;
  }

  render() {
    const { t, job, handleClose } = this.props;
    const { message } = this.state;
    const hoursPerWeek = job.hoursPerWeek ? this.hoursPerWeek(job.hoursPerWeek) : null;
    return (
      <div style={{ height: "100%" }}>
        <Card className="jobCardMain jobCardMainV2">
          <CardContent
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "start",
              height: "100%",
            }}
          >
            <IconButton aria-label="Close" className="emailCardCloseButton" onClick={handleClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
            <div className="emailRecHospitalNameV2">
              <p>
                <span>
                  <Image src={MapIconJobDetail} className="mapIconJobCard" />
                </span>
                {job.city}, {job.state} <br />
                <div className="jobFacilityName pt-1">{job.facilityName}</div>
              </p>
              <TextField
                className="emailTextArea"
                id="filled-textarea"
                minRows="8"
                placeholder="Write a message for recruiter....."
                size="small"
                multiline
                variant="filled"
                onChange={this.handleChange}
                InputProps={{
                  disableUnderline: true,
                  inputProps: {
                    maxLength: 500,
                    required: true,
                  },
                }}
              />
            </div>
            <div>
              <div className="emailRecIcons pb-3">
                {this.isShift(job.shift)}
                {job.duration && (
                  <div className="emailRecMinHrsMob">
                    <b>
                      <img src={TimeIcon} className="timeIcon" alt="hours per week" />{" "}
                      <span className="iconLabel"> {hoursPerWeek} </span>
                    </b>
                    <b className="weeksMob">
                      <DateRangeIcon className="weeksIcon" />
                      <span className="iconLabel">
                        {job.duration} {t("jobs.jobsCard.weeks")}
                      </span>
                    </b>
                  </div>
                )}
              </div>
            </div>
            <div className="emailRecButtonRowV2">
              <div>
                <Button
                  variant="contained"
                  size="medium"
                  className="sendEmailBtnV2"
                  onClick={this.sendTheEmail}
                  disabled={message.length === 0}
                >
                  Send
                </Button>
              </div>
            </div>
          </CardContent>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  allJobs: state.jobResults.jobs,
  similarJobsCarouselItems: state.jobDetails.similarJobsCarouselItems,
  collaboratedJobsCarouselItems: state.jobDetails.collaboratedJobsCarouselItems,
  recommendedJobsCarouselItems: state.jobs.recommendedJobsCarouselItems,
  jobDetails: state.jobDetails.jobDetails,
  myJobs: state.myJobs.myJobs,
  recommendedJobs: state.jobs.recommendedJobs,
  candidateId: state.auth.candidateId,
  recruiterDetails: state.consultant.recruiterDetails,
  email: state.auth.email,
});

const mapDispatchToProps = (dispatch) => ({
  sendEmail: (reqObj: reqObjType) => dispatch(postEmail(reqObj)),
  fetchRecruiterDetails: (candidateId: number) => dispatch(fetchRecruiterDetails(candidateId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withRouter(EmailRecruiterV2)));
