const SnapChatIcon = () => (
  <svg viewBox="0 0 600 600">
    <g>
      <path
        fill="#003366"
        d="M391.56 240.24q0 27.105-18.33 50.115t-50.017 36.367-68.933 13.358q-16.77 0-34.32-3.12-24.18 17.16-54.21 24.96-7.02 1.755-16.77 3.12h-.585q-2.145 0-3.998-1.56t-2.242-4.095q-.195-.585-.195-1.268t.098-1.267.39-1.17l.487-.975.682-1.073.78-.975.878-.975.78-.877q.975-1.17 4.485-4.875t5.07-5.753 4.388-5.655 4.875-7.507 3.997-8.58q-24.18-14.04-38.025-34.515T117 240.24q0-27.105 18.33-50.115t50.017-36.367T254.28 140.4t68.933 13.357 50.017 36.368 18.33 50.115zm74.88 49.92q0 23.4-13.845 43.777t-38.025 34.418q1.95 4.68 3.998 8.58t4.875 7.508 4.387 5.655 5.07 5.752 4.485 4.875q.195.195.78.877t.877.975.78.975.683 1.073l.487.975.39 1.17.098 1.268-.195 1.267q-.585 2.73-2.535 4.29t-4.29 1.365q-9.75-1.365-16.77-3.12-30.03-7.8-54.21-24.96-17.55 3.12-34.32 3.12-52.845 0-92.04-25.74 11.31.78 17.16.78 31.395 0 60.255-8.775t51.48-25.155q24.375-17.94 37.44-41.34t13.065-49.53q0-15.015-4.485-29.64 25.155 13.845 39.78 34.71t14.625 44.85z"
      />
    </g>
  </svg>
);

export default SnapChatIcon;
