export default function imageResolution(url, device, imageCallDetails) {
  const urlImage = url ? url.split(".") : null;
  let newUrl;

  switch (device) {
    case "Desktop":
      newUrl = imageCallDetails === "jobDescription" ? `${urlImage[0]}-lg.${urlImage[1]}` : url;
      break;

    case "Mobile":
      newUrl = imageCallDetails === "jobDescription" ? `${urlImage[0]}-sm.${urlImage[1]}` : url;
      break;
    default:
      break;
  }

  return newUrl;
}
