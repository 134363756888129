import Image from "react-bootstrap/Image";
import ListViewActive from "src/imagesV2/Cardview-Active.svg";
import ListViewDefault from "src/imagesV2/Cardview-Default.svg";
import MapIconActive from "src/imagesV2/mapview-Active.svg";
import MapIconDefault from "src/imagesV2/mapview-Default.svg";
import MapIconDisabled from "src/imagesV2/mapview-Disabled.svg";
import jobSearchQueryBuilder from "src/services/QueryBuilder/JobSearchQueryBuilder";

interface JobListMapViewProps {
  viewMap: boolean;
  disabled: boolean;
}

function JobListMapView({ viewMap, disabled }: JobListMapViewProps): JSX.Element {
  const toggleMapSection = (bool: boolean): void => {
    jobSearchQueryBuilder.handleParamsChange({
      view: bool ? "map" : "list",
    });
  };

  return (
    <div className="listMapViewButtons">
      <span className="gridView">
        {viewMap ? (
          <Image src={ListViewDefault} onClick={() => toggleMapSection(false)} />
        ) : (
          <Image src={ListViewActive} />
        )}
      </span>
      <span className="mapView">
        {viewMap ? (
          <Image src={MapIconActive} />
        ) : (
          <Image
            src={disabled ? MapIconDisabled : MapIconDefault}
            onClick={() => toggleMapSection(true)}
          />
        )}
      </span>
    </div>
  );
}

export default JobListMapView;
