import { useTranslation } from "react-i18next";
import LightbulbOutlinedIcon from "@mui/icons-material/LightbulbOutlined";
import BoltIcon from "@mui/icons-material/Bolt";

import "src/components/common/QuickApply/QuickApplyCard.scss";
import { CircularProgress } from "@material-ui/core";
import config from "src/config";

const enableSsnAndDobFields = config.ENABLE_SSN_AND_DOB_FIELDS === "true";

const QuickApplyCardSkeleton = () => {
  const { t } = useTranslation() as any;

  return (
    <div className="quickApplyCardWrapper">
      <div className="quickApplyCardHead">
        <h2>{t("QuickApplyCard.QuickApplyHeading")}</h2>
        <div className="applicationTip">
          <div className="applicationTipHead">
            <span className="applicationTipIcon">
              <LightbulbOutlinedIcon />
            </span>
            {t("QuickApplyCard.QuickApplyApplicationTipHeading")}
          </div>
          <p className="applicationTipContent">
            Get submitted to this job faster with
            <b> {t("QuickApplyCard.QuickApplyApplicationTipHighlightedContent")}! </b>
          </p>
        </div>
      </div>
      <div
        style={{
          width: "100%",
          height: "100%",
          position: "relative",
        }}
      >
        <div
          style={{
            width: "100%",
            height: "100%",
            position: "absolute",
            top: 0,
            left: 0,
            zIndex: 10,
          }}
          className="d-flex justify-content-center align-items-center"
        >
          <CircularProgress thickness={5} size={100} />
        </div>
        <div
          style={{
            width: "100%",
            height: "100%",
            position: "absolute",
            top: 0,
            left: 0,
            background: "rgba(237, 241, 255, 0.6)",
          }}
        />
        <div className="quickApplyCardBody">
          {enableSsnAndDobFields && (
            <div className="quickApplyCardItem">
              <div className="quickApplyCardItemRow">
                <div>
                  <div className="itemHeading">Social Security Number & Date of Birth</div>
                </div>
              </div>
            </div>
          )}
          <div className="quickApplyCardItem">
            <div className="quickApplyCardItemRow">
              <div>
                <div className="itemHeading">1+ year(s) of Experience</div>
                <div className="itemSubHeading">
                  At least 12 months experience within last 24 months
                </div>
              </div>
            </div>
          </div>
          <div className="quickApplyCardItem">
            <div className="quickApplyCardItemRow">
              <div>
                <div className="itemHeading">1+ Reference(s)</div>
                <div className="itemSubHeading">From a job worked within the last 12 months</div>
              </div>
            </div>
          </div>
          <div className="quickApplyCardItem">
            <div className="quickApplyCardItemRow">
              <div>
                <div className="itemHeading">License verification(s)</div>
                <div className="itemSubHeading" />
              </div>
            </div>
          </div>
          <div className="quickApplyCardItem">
            <div className="quickApplyCardItemRow">
              <div>
                <div className="itemHeading">Skills Checklist(s)</div>
                <div className="itemSubHeading" />
              </div>
            </div>
          </div>
        </div>
        <div className="quickApplyCardFooter">
          <div className="toasterWrapper">
            <button type="button" disabled className="quickApplyButton">
              <BoltIcon />
              {t("QuickApplyCard.QuickApplyButton")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuickApplyCardSkeleton;
