import { createStore, applyMiddleware, compose, combineReducers } from "redux";
import thunk from "redux-thunk";
import { createLogger } from "redux-logger";
import { connectRouter, routerMiddleware } from "connected-react-router";
import history from "./history";
import authReducer from "./auth/reducer";
import settingsReducer from "./settings/reducer";
import globalSearchReducer from "./globalSearch/reducer";
import jobSearchReducer from "./jobResults/reducer";
import emailRecruiterReducer from "./userAction/emailRecruiter/reducer";
import jobDetailsReducer from "./jobDetails/reducer";
import applyFavEmailReducer from "./userAction/applyFavEmail/reducer";
import jobsReducer from "./jobs/SearchCrossCountry/reducer";
import myJobReducer from "./jobs/MyJob/reducer";
import facilityReducer from "./jobs/MyHospitals/reducer";
import candidateReducer from "./candidate/reducer";
import professionReducer from "./profession/reducer";
import signUpReducer from "./userAction/signUp/reducer";
import accountSettingsReducer from "./accountSettings/reducer";
import payrollReducer from "./payroll/reducer";
import dashboardReducer from "./dashboard/reducer";
import consultantReducer from "./consultant/reducer";
import navigationReducer from "./navigation/reducer";
import companyReducer from "./company/reducer";
import onBoardingReducer from "./onBoarding/reducer";

const createRootReducer = (historyData) =>
  combineReducers({
    router: connectRouter(historyData),
    settings: settingsReducer,
    auth: authReducer,
    globalSearch: globalSearchReducer,
    jobResults: jobSearchReducer,
    emailRecruiter: emailRecruiterReducer,
    jobDetails: jobDetailsReducer,
    applyFavEmail: applyFavEmailReducer,
    jobs: jobsReducer,
    myJobs: myJobReducer,
    facility: facilityReducer,
    candidate: candidateReducer,
    profession: professionReducer,
    signUp: signUpReducer,
    accountSettings: accountSettingsReducer,
    payroll: payrollReducer,
    dashboard: dashboardReducer,
    consultant: consultantReducer,
    navigation: navigationReducer,
    company: companyReducer,
    onBoarding: onBoardingReducer,
  });

export default (preloadedState) => {
  const urlParams = new URLSearchParams(window.location.search);
  const debugMode = urlParams.get("CCH-DEBUG");
  const debug = debugMode === "true";
  const checkDebugMode = () => {
    if (debug) {
      return applyMiddleware(createLogger(), routerMiddleware(history), thunk);
    }
    return applyMiddleware(routerMiddleware(history), thunk);
  };

  const store = createStore(createRootReducer(history), preloadedState, compose(checkDebugMode()));
  return store;
};
