import Container from "@mui/material/Container";
import { makeStyles } from "@material-ui/core/styles";
import Logo from "src/components/common/Header/Logo";

const useStyles = makeStyles(() => ({
  referenceHeaderWrapper: {
    padding: "36px 0",
    backgroundColor: "#fff",
    boxShadow: "0px 3px 20px #00000012",
    "@media (max-width: 480px)": {
      padding: "23px 0",
    },
  },
  referenceContainer: {
    "@media (max-width: 480px)": {
      display: "flex !important",
      justifyContent: "center",
    },
  },
  referenceHeaderLogo: {
    height: "48px",
    width: "unset !important",
    margin: "0 !important",
    "@media (max-width: 480px)": {
      height: "32px",
    },
  },
}));

const ReferenceHeader = () => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.referenceHeaderWrapper}>
        <Container className={classes.referenceContainer}>
          <Logo className={classes.referenceHeaderLogo} />
        </Container>
      </div>
    </>
  );
};

export default ReferenceHeader;
