import React from "react";
import { CircularProgressbarWithChildren, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { Box, Typography } from "@mui/material";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

const perStepPercentage = 20;

class MyProfileStatus extends React.Component {
  constructor(props) {
    super(props);
    let completedPercentage = 0;
    let noOfStepsCompleted = 0;
    for (const prop in this.props.onBoardingCompletedSteps) {
      if (Object.prototype.hasOwnProperty.call(this.props.onBoardingCompletedSteps, prop)) {
        noOfStepsCompleted = noOfStepsCompleted + 1;
      }
    }

    completedPercentage = noOfStepsCompleted * perStepPercentage;

    this.state = {
      completedPercentage,
      noOfStepsCompleted,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.onBoardingCompletedSteps !== prevProps.onBoardingCompletedSteps) {
      let completedPercentage = 0;
      let noOfStepsCompleted = 0;
      for (const prop in this.props.onBoardingCompletedSteps) {
        if (Object.prototype.hasOwnProperty.call(this.props.onBoardingCompletedSteps, prop)) {
          noOfStepsCompleted = noOfStepsCompleted + 1;
        }
      }

      completedPercentage = noOfStepsCompleted * perStepPercentage;

      this.setState({
        completedPercentage,
        noOfStepsCompleted,
      });
    }
  }

  render() {
    return (
      <>
        <Box position="relative" display="inline-flex">
          <CircularProgressbarWithChildren
            value={this.state.completedPercentage}
            className="myProfileCircProgress"
            styles={buildStyles({
              thickness: 30,
              color: "cecfdb",
              rotation: 0.25,
              strokeColor: "#cecfdb",
              strokeLinecap: "butt",
              textSize: "14px",
              pathColor: "#39cd7e",
              textColor: "#cecfdb",
              trailColor: "#cecfdb",
              backgroundColor: "#cecfdb",
            })}
          />
          <Box
            p={1}
            justifyContent="center"
            top={0}
            left={0}
            bottom={0}
            right={0}
            position="absolute"
            display="flex"
            alignItems="center"
          >
            <Typography
              variant="caption"
              component="div"
              color="textSecondary"
              className="myProfPercent"
            >
              {`${this.state.completedPercentage}%`}
            </Typography>
          </Box>
        </Box>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  onBoardingCompletedSteps: state.onBoarding.completedSteps,
});

export default connect(mapStateToProps, null)(withRouter(MyProfileStatus));
