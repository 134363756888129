import { Component } from "react";
import { Map, GoogleApiWrapper, GoogleAPI } from "google-maps-react";
import Marker from "google-maps-react/dist/components/Marker";
import InfoWindow from "google-maps-react/dist/components/InfoWindow";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import DirectionsIcon from "@material-ui/icons/Directions";
import { TFunction, withTranslation } from "react-i18next";
import config from "src/config";
import { GOOGLEMAPS_FROM_URL, GOOGLEMAPS_TO_URL } from "src/constants/external_links";
import { EmailRecruiterV2Props } from "src/components/common/MainContent/JobResults/common/index";
import { MarkerIcon } from "src/imagesV2/MarkerIcon";
import { MarkerIconClicked } from "src/imagesV2/MarkerIconClicked";

const mapStyles = {
  width: "100%",
  height: "60vh",
  left: "15px",
};
let bounds: google.maps.LatLngBounds;

interface SearchResultProps {
  google: GoogleAPI;
  jobs: EmailRecruiterV2Props["allJobs"];
  t: TFunction<"translation", undefined>;
}

interface SearchResultState {
  showingInfoWindow: boolean;
  activeMarker: google.maps.MarkerOptions;
  selectedPlace: any;
  hcfCoOrdinates: any;
}

type Markers = {
  id: string;
  name: string;
  city: string;
  state: string;
  latLngStr: string;
  pos: {
    lat: number;
    lng: number;
  };
};

class SearchResultMap extends Component<SearchResultProps, SearchResultState> {
  constructor(props) {
    super(props);
    this.state = {
      showingInfoWindow: false,
      activeMarker: {},
      selectedPlace: {},
      hcfCoOrdinates: [],
    };
  }

  componentDidMount() {
    const { jobs, google } = this.props;
    this.getLatLngFromJobs(jobs, google);
  }

  componentDidUpdate(nextProps) {
    if (this.props !== nextProps) {
      this.onInfoWindowClose();
      const { jobs, google } = nextProps;
      this.getLatLngFromJobs(jobs, google);
    }
  }

  getLatLngFromJobs(jobs: SearchResultProps["jobs"], google: SearchResultProps["google"]): void {
    const allMarkers: Markers[] = [];
    const allJobs: EmailRecruiterV2Props["allJobs"] = jobs ? jobs.concat() : [];
    if (allJobs.length === 0) return;
    const jobMap = {};
    allJobs.forEach((job) => {
      const key = `${job.latitude},${job.longitude}`;
      if (!jobMap[`${key}`]) {
        jobMap[`${key}`] = job;
      }
    });
    const uniqueJobs: EmailRecruiterV2Props["allJobs"] = Object.values(jobMap);
    bounds = new google.maps.LatLngBounds();
    uniqueJobs.forEach((job: EmailRecruiterV2Props["allJobs"][0]) => {
      if (`${job.latitude},${job.longitude}` !== "0,0") {
        const point = {
          id: job.id,
          name: job.facilityName,
          city: job.city,
          state: job.state,
          latLngStr: `${job.latitude},${job.longitude}`,
          pos: {
            lat: job.latitude,
            lng: job.longitude,
          },
        };
        bounds.extend(point.pos);
        allMarkers.push(point);
      }
    });
    this.setState({ hcfCoOrdinates: allMarkers });
  }

  onMarkerClick = (props, marker) => {
    this.setState({
      selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: true,
    });
  };

  onInfoWindowClose = () => {
    this.setState({
      activeMarker: {},
      showingInfoWindow: false,
    });
  };

  onMapClicked = () => {
    this.setState({
      activeMarker: {},
      showingInfoWindow: false,
    });
  };

  marker(hcf) {
    return (
      <Marker
        onClick={this.onMarkerClick}
        name={hcf.name}
        city={hcf.city}
        state={hcf.state}
        position={hcf.pos}
        latLngStr={hcf.latLngStr}
        key={hcf.id}
        icon={{
          url: `data:image/svg+xml;charset=utf-8, ${encodeURIComponent(MarkerIcon)}`,
        }}
      />
    );
  }

  infoWindow() {
    const googleMapsFromUrl = GOOGLEMAPS_FROM_URL;
    const googleMapsToUrl = GOOGLEMAPS_TO_URL;
    const { t, google } = this.props;
    const { activeMarker, showingInfoWindow, selectedPlace } = this.state;
    return (
      <InfoWindow
        onClose={this.onInfoWindowClose}
        google={google}
        map="map"
        marker={activeMarker}
        visible={showingInfoWindow}
      >
        <div className="infoWindow">
          <Row className="mapHospName">
            <Col sm={12} md={12} lg={12} xs={12}>
              {selectedPlace.name}
              <br />
              {selectedPlace.city}, {selectedPlace.state}
            </Col>
          </Row>
          <hr />
          <Row>
            <Col sm={12} md={12} lg={12} xs={12}>
              <span>
                <DirectionsIcon className="directionIcon" />
              </span>
              <span className="mapFromText">
                <span>
                  <a
                    href={googleMapsFromUrl + selectedPlace.latLngStr}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t("jobResults.searchResult.formHere")}
                  </a>
                </span>
                &nbsp; - &nbsp;
                <a
                  href={googleMapsToUrl + selectedPlace.latLngStr}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t("jobResults.searchResult.toHere")}
                </a>
              </span>
            </Col>
          </Row>
        </div>
      </InfoWindow>
    );
  }

  populateMap() {
    const { google } = this.props;
    const { hcfCoOrdinates } = this.state;
    return (
      <Map google={google} onClick={this.onMapClicked} style={mapStyles} bounds={bounds}>
        {hcfCoOrdinates.map((hcf) => this.marker(hcf))}
        {this.infoWindow()}
      </Map>
    );
  }

  markerClickedIcon() {
    const { t } = this.props;
    return (
      <Marker
        onClick={this.onMarkerClick}
        name={t("searchResults.searchResultMap.currentLocationTxt")}
        icon={{
          url: `data:image/svg+xml;charset=utf-8,${encodeURIComponent(MarkerIconClicked)}`,
        }}
      />
    );
  }

  render() {
    return <div>{this.populateMap()}</div>;
  }
}

export default withTranslation()(
  GoogleApiWrapper({ apiKey: config.googleMapsAPIKey! })(SearchResultMap)
);
