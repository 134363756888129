import { ChangeEvent,useEffect, useState } from "react";
import { Fade, Modal, Button, IconButton, Box } from "@material-ui/core";
import BasicInfoSsnAndDOB from "src/components/common/OnBoarding/BasicInfoSsnAndDOB";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import CloseIcon from "@material-ui/icons/Close";
import { isMobile } from "react-device-detect";
import validationService from "src/services/Validations";

import basicInfoConstraints from "src/services/BasicInfoConstraint";
import styles from "./SsnDobModal.module.scss";

const useStyles = makeStyles({
  root: {
    "& .MuiButton-contained": {
      marginTop: "44px",
      backgroundColor: styles?.primaryColor,
      color: "#FFF",
      borderRadius: "15px 15px 15px 0px",
      fontFamily: "'Nunito Sans', Helvetica, Arial, sans-serif",
      fontWeight: "bold",
      fontSize: "18px",
      width: "325px",
      height: "46px",
      "&:disabled": {
        color: "#656e7e",
        backgroundColor: "#efefef"
      }
    }
  }
});

interface SsnDobModalProps {
  isSSNAvailable: boolean;
  socialSecurityNumber: string;
  dateOfBirth: string;
  isOpen: boolean;
  initialSsn: string;
  initialNoSsn: boolean;
  initialDob: string;
  onSubmit: (ssn: string | null, dob: string | null, noSsn: boolean | null) => void;
  onClose: () => void;
}

interface ssnAndDobField {
  value: string | null;
  errors: boolean;
  errorText: string;
}

interface noSsnField {
  value: boolean | null;
  errors: boolean;
  errorText: string;
}

export default function SsnDobModal({isSSNAvailable, socialSecurityNumber,dateOfBirth,isOpen, initialSsn, initialNoSsn, initialDob, onSubmit, onClose }: SsnDobModalProps) {
  const { t } = useTranslation();
  const classes = useStyles();

  const [ssn, setSsn] = useState<ssnAndDobField>({ value: initialSsn, errors: false, errorText:"" });
  const [noSsn, setNoSsn] = useState<noSsnField>({ value: initialNoSsn, errors: false, errorText:"" });
  const [dob, setDob] = useState<ssnAndDobField>({ value: initialDob, errors: false, errorText:"" });

 useEffect(() => {
   validationService.init();
   validationService.setCustomBasicInfoValidations();
  });

  const validatedSsn = (value) => {
    const isSsnValid = validationService.validateSingleField(value, basicInfoConstraints.socialSecurityNumber);
  
    return {
      value,
      errors: !!isSsnValid,
      errorText: isSsnValid ? isSsnValid[0] : null, 
    };
  }

  const validatedDob = (value) => {
    const isDobValid = validationService.validateSingleField(value, basicInfoConstraints.dateOfBirth);

    return {
      value,
      errors: !!isDobValid,
      errorText: isDobValid ? isDobValid[0] : null, 
    };
  }

  const handleInputChange = (key: string, event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
        
    switch (key) {
      case "socialSecurityNumber": {
        setSsn(validatedSsn(value));
        if(value.length === 11){
          setNoSsn({
              value: true,
              errors: false,
              errorText: "",
            })
          }
        break;
      }
      case "isSSNAvailable": {
        setNoSsn({
          value: typeof value === "boolean" ? !value : null,
          errors: false,
          errorText: ""
        });
        setSsn({
          value: null,  
          errors: false,
          errorText: ""
        });
        break;
      }
      case "dateOfBirth": {
        setDob(validatedDob(value));
        break;
      }
      default: {
        break;
      }
    }
}

  const handleClose = () => {
    setSsn({ value: initialSsn, errors: false , errorText:""});
    setNoSsn({ value: initialNoSsn, errors: false , errorText:""});
    setDob({ value: initialDob, errors: false , errorText:""});
    onClose();
  };

  const handleSubmit = () => {

    const isSsnValid = !ssn.errors;
    const isDobValid = !dob.errors;

    if (isSsnValid && isDobValid) {
      onSubmit(ssn.value , dob.value , noSsn.value);
      handleClose();
    }
  }

    const handleButtonDisabled = () => {
      const hasSsnValue = ssn.value == null || ssn.value === "";
      const hasDobValue = dob.value == null || dob.value === "";
      const noSsnValue  = typeof noSsn.value === "boolean" ? noSsn.value : true;
      if ((hasSsnValue && noSsnValue) || (hasDobValue) ){
        return true;
      }
      return ssn.errors || dob.errors;
    };

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      closeAfterTransition
      className={clsx(styles.modal, classes.root)}
    >
      <Fade in={isOpen}>
        <div className={styles.container}>
          <div className={styles.closeButton}>
            <IconButton aria-label="close" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </div>
          <div className={styles.content}>
            <div>
              <div className={styles.header}>
              {t("onBoarding.basicInfo.SsnAndDobModal.SsnAndDob")}
              </div>

              <Box sx={{ paddingBottom: isMobile ? "30px" : 0, marginTop: isMobile ? "-34.5px" : 0 }} className={ssn.errors || dob.errors ? styles.ssnDobConatinerError : styles.ssnDobConatiner}>
                <BasicInfoSsnAndDOB
                  isSSNAvailable ={isSSNAvailable}
                  socialSecurityNumber={socialSecurityNumber}
                  dateOfBirth={dateOfBirth}
                  ssnValue={ssn}
                  noSsn={noSsn}
                  dobValue={dob}
                  onInputChange={handleInputChange}
                  handleChange={handleInputChange}
                  parentType="modal"
                />
              </Box>

              <Button
                variant="contained"
                style={{ marginTop: isMobile ? "-34.5px" : "44px" }}
                onClick={handleSubmit}
                className={styles.updateButton}
                disabled = {handleButtonDisabled()}
              >
                {t("onBoarding.basicInfo.SsnAndDobModal.UPDATE")}
              </Button>
            </div>
          </div>
        </div>
      </Fade>
    </Modal>
  );
}