import { useEffect, useRef } from "react";
import Container from "react-bootstrap/Container";
import { Row, Col } from "react-bootstrap";
import Image from "react-bootstrap/Image";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";
import MedicalPlan from "src/images/Medicalplanoptions.svg";
import DentalPlan from "src/images/Dentalplanoptions.svg";
import VisionPlan from "src/images/VisionPlanthroughCigna.svg";
import BasicLife from "src/images/BasicLife.svg";
import VoluntaryLifeInsurance from "src/images/VoluntaryLifeInsurance.svg";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import Infoarmor from "src/images/infoarmor.png";
import Retirementplan from "src/images/fidelity.png";
import GooglePlay from "src/images/Android.svg";
import IOS from "src/images/iOS.svg";
import { useSelector } from "react-redux";
import {
  RETAIL_DISCOUNT_PERKSPOT,
  MYCIGNA_APP_STORE,
  MYCIGNA_PLAY_STORE,
  FIDELITY_BENEFITS,
} from "src/constants/external_links";
import {
  HR_BENEFITS_EMAIL,
  HR_BENEFITS_PHONE,
  FIDELITY_BENEFITS_CONTACT,
} from "src/constants/contact_info";
import { useTranslation } from "react-i18next";
import { OPEN_ENROLLMENT } from "src/router/routes";
import analyticsSegmentIdentify from "src/services/SegmentIdentify";
import config from "src/config";

interface BenefitsReduxState {
  company: {
    company: {
      code: number;
    };
  };
  auth: {
    email: string;
    userId: string;
    firstName: string;
    lastName: string;
    phone: number;
  };
  consultant: {
    recruiterDetails: string;
  };
  onBoarding: {
    specialtiesAndEducation: string;
    basicInfo: string;
  };
}

const Benefits = () => {
  const companyCode = useSelector((state: BenefitsReduxState) => state.company.company);
  const { t } = useTranslation();
  const obj = {
    email: useSelector((state: BenefitsReduxState) => state.auth.email),
    company: useSelector((state: BenefitsReduxState) => state.company.company),
    userId: useSelector((state: BenefitsReduxState) => state.auth.userId),
    firstName: useSelector((state: BenefitsReduxState) => state.auth.firstName),
    lastName: useSelector((state: BenefitsReduxState) => state.auth.lastName),
    phone: useSelector((state: BenefitsReduxState) => state.auth.phone),
    recruiterDetails: useSelector((state: BenefitsReduxState) => state.consultant.recruiterDetails),
    onboardingSpecialtiesAndEducation: useSelector(
      (state: BenefitsReduxState) => state.onBoarding.specialtiesAndEducation
    ),
    onboardingBasicInfo: useSelector((state: BenefitsReduxState) => state.onBoarding.basicInfo),
  };
  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    }, [value]);
    return ref.current;
  };
  const prevEmail = usePrevious(obj.email);
  const prevRecruiterDetails = usePrevious(obj.recruiterDetails);
  const enableBenefitsAndInfoBanner = config.ENABLE_BENEFITS_AND_INFO_BANNER === "true";
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (
      obj &&
      obj.userId &&
      (obj.email !== prevEmail || obj.recruiterDetails !== prevRecruiterDetails)
    ) {
      analyticsSegmentIdentify(obj);
    }
  }, [obj, prevEmail, prevRecruiterDetails]);

  const scrollToEnrollment = () => {
    document.getElementById("enrollmentPeriod")!.scrollIntoView();
  };
  const scrollTo401k = () => {
    document.getElementById("401k")!.scrollIntoView();
  };
  const handleEnrollmentKeyPress = (event) => {
    if (event.key === "Enter") scrollToEnrollment();
  };
  const handle401KeyPress = (event) => {
    if (event.key === "Enter") scrollTo401k();
  };

  return (
    <div className="benefitsV2">
      <Container fluid className="benefitsBanner">
        <Container>
          <div className="benefitsBannerText">{t("benefits.bannerText")}</div>
        </Container>
      </Container>
      {enableBenefitsAndInfoBanner && (
        <Container fluid className="openEnrollmentBenefits">
          <Row>
            <>
              <h5 className="openEnrollmentHeading">{t("enrollment.openEnrollmentBenefits")}</h5>
              <a href={OPEN_ENROLLMENT} className="LearnMoreLink">
                <Button variant="contained" className="learnMoreButton">
                  {t("benefits.LearnMoreText")}
                </Button>
              </a>
            </>
          </Row>
        </Container>
      )}
      <Container>
        <h3 className="benefitHead">{t("benefits.benefitHeading")}</h3>
        <Row>
          <Col xs={12} md={4} lg={4} sm={12}>
            <div className="benefitOfferMob ">
              <div className="textcenter">
                <Image src={MedicalPlan} className="planOption" />
              </div>
              <div className="benefitSubHeads textcenter">{t("benefits.medicalPlan")}</div>
              <div className="medicalPlan">
                <p className="benefitOffer">{t("benefits.medicalPlanOpt1")}</p>
              </div>
            </div>
          </Col>
          <Col xs={12} md={4} lg={4} sm={12}>
            <div className="benefitOfferMob ">
              <div className="textcenter">
                <Image src={DentalPlan} className="planOption " />
              </div>
              <div className="benefitSubHeads textcenter">{t("benefits.dentalPlan")}</div>
              <div className="dentalPlan">
                <p className="benefitOffer">{t("benefits.dentalPlanOpt1")}</p>
              </div>
            </div>
          </Col>
          <Col xs={12} md={4} lg={4} sm={12}>
            <div className="benefitOfferMob textcenter">
              <div>
                <Image src={VisionPlan} className="planOption" />
              </div>
              <div className="benefitSubHeads">{t("benefits.visionPlan")}</div>
              <p className="benefitOffer">
                {`${t("benefits.visionPlanInfo")} `}
                <Link className="benefitLinks" href={`mailto:${HR_BENEFITS_EMAIL}`}>
                  {HR_BENEFITS_EMAIL}
                </Link>
              </p>
            </div>
          </Col>
        </Row>
        <Row className="benefitRowMar">
          <Col xs={12} md={2} lg={2} sm={12} />
          <Col xs={12} md={4} lg={4} sm={12}>
            <div className="benefitOfferMob textcenter">
              <div>
                <Image src={BasicLife} className="planOption" />
              </div>
              <div className="benefitSubHeads">{t("benefits.basicLifePlan")}</div>
              <p className="benefitOffer">{t("benefits.basicLifeInfo")}</p>
            </div>
          </Col>
          <Col xs={12} md={4} lg={4} sm={12}>
            <div className="benefitOfferMob textcenter">
              <div>
                <Image src={VoluntaryLifeInsurance} className="planOption" />
              </div>
              <div className="benefitSubHeads">{t("benefits.lifeInsurancePlan")}</div>
              <p className="benefitOffer">{t("benefits.lifeInsuranceLineInfo")}</p>
            </div>
          </Col>
          <Col xs={12} md={2} lg={2} sm={12} />
        </Row>
        <Row>
          <Col xs={12} md={2} lg={2} sm={12} />
          <Col xs={12} md={8} lg={8} sm={12}>
            <div className="enrollBenefitHead textcenter">
              <div
                onClick={() => scrollToEnrollment()}
                role="button"
                tabIndex={0}
                onKeyPress={(event) => handleEnrollmentKeyPress(event)}
              >
                {`${t("benefits.enrollInfo")} `}
                <ArrowForwardIcon fontSize="small" className="benefitsArrow" />
              </div>
            </div>
          </Col>
          <Col xs={12} md={2} lg={2} sm={12} />
        </Row>
      </Container>

      <Container fluid className="beneOfferings">
        <Container>
          <div className="benefitHead">{t("benefits.voluntaryOffering")}</div>
          <div className="beneCards">
            <div className="offeringDetails">
              <div className="retirementPlan">
                <img src={Retirementplan} alt="Retirement Plan " />
              </div>
              <div>
                {t("benefits.retirementPlan")}
                <br />
                {t("benefits.retirementPlan1")}
              </div>
              <div
                className="moreDetailsTextDiv"
                onClick={() => scrollTo401k()}
                role="button"
                tabIndex={0}
                onKeyPress={(event) => handle401KeyPress(event)}
              >
                <div className="moreDetailsText">{t("benefits.moreDetails")}</div>
                <span className="arrowRightIcon">
                  <ArrowRightIcon />
                </span>
              </div>
              <a href={FIDELITY_BENEFITS} target="_blank" rel="noreferrer">
                <Button variant="contained" className="registerBtn">
                  {t("benefits.interested")}
                </Button>
              </a>
            </div>
            <div className="offeringDetails">
              <div className="beneInfoImage">
                <img src={Infoarmor} alt="InfoArmor" />
              </div>
              <div>
                {t("benefits.infoArmor1")}
                <br />
                {t("benefits.infoArmor2")}
                <br />
                {t("benefits.infoArmor3")}
              </div>
            </div>
            <div className="offeringDetails workLifeText">
              <div>
                {t("benefits.transamericaDisability1")},<br />
                {t("benefits.transamericaIllness")}
              </div>
            </div>
            <div className="offeringDetails workLifeText">
              <div>{t(`benefits.hospitalIndemnity-${companyCode.code}`)}</div>
            </div>
            <div className="offeringDetails workLifeText">
              {t("benefits.workLifeText1")}
              <br />
              {t("benefits.workLifeText2")}
            </div>
          </div>
        </Container>
      </Container>

      <Container fluid className="retailPanel">
        <Container fluid className="retailDiscountImg">
          <div>
            <span className="retailHead">{t("benefits.retailDiscount")} </span>
            <div className="retailSubText" id="401k">
              {`${t("benefits.retailInfo")} `}
              <b> {t(`benefits.text-${companyCode.code}`)}</b>
              <p>
                <br />
                {t(`benefits.infoText-${companyCode.code}`)}
              </p>
            </div>
            <Button
              variant="contained"
              className="registerBtn applyButton mb-3"
              id="beneMobBtn"
              href={RETAIL_DISCOUNT_PERKSPOT}
              target="_blank"
            >
              {t("benefits.interested")}
            </Button>
          </div>
        </Container>
      </Container>

      <Container>
        <div className="beneAboutText">{t("benefits.401Plan")}</div>
        <div className="beneSubText">
          <ul>
            <li>
              <span>{t("benefits.401PlanInfo")}</span>
            </li>
            <li>
              <span>{t("benefits.401PlanInfo1")}</span>
            </li>
            <li>
              <span>{t("benefits.401PlanInfo2")}</span>
            </li>
            <li>
              <span>
                {`${t("benefits.401Register")} - `}
                <b className="benefitLinks">
                  <u>
                    <Link className="benefitLinks" href={FIDELITY_BENEFITS} target="_blank">
                      {FIDELITY_BENEFITS}
                    </Link>{" "}
                  </u>
                </b>
              </span>
            </li>
            <li>
              <span>
                {`${t("benefits.contactBenefits")} `}
                <Link className="benifitUrl" href={`tel:${FIDELITY_BENEFITS_CONTACT}`}>
                  {FIDELITY_BENEFITS_CONTACT}
                </Link>
              </span>
            </li>
          </ul>
        </div>
      </Container>

      <Container className="planSection">
        <Row className="benefitWrapper" id="enrollmentPeriod">
          <Col xs={12} md={6} lg={6} sm={12}>
            <div className="benefitSubHeads">{t("benefits.planYear")}</div>
            <p>{t("benefits.planYearInfo")}</p>
            <div className="benefitSubHeads">{t("benefits.eligibilty")}</div>
            <p>{t("benefits.eligibilityText")}</p>
            <div className="benefitSubHeads">{t("benefits.enrollment")}</div>
            <p>
              {t("benefits.enrollmentText")}&nbsp;
              {enableBenefitsAndInfoBanner ? (
                <a className="benefitsLearnMore" href={OPEN_ENROLLMENT}>
                  {t("benefits.LearnMoreText")}
                </a>
              ) : (
                t("benefits.openEnrollmentText")
              )}
            </p>
            <div className="benefitSubHeads">{t("benefits.idCard")}</div>
            <p>{t("benefits.idCardText")}</p>
          </Col>
          <Col xs={12} md={6} lg={6} sm={12}>
            <div className="benefitSubHeads">{t("benefits.communication")}</div>
            <p>{t("benefits.communicationText1")}</p>
            <p>
              {t("benefits.communicationText2")}
              <br />
              {`${t("benefits.communicationText3")} `}
              <Link className="benefitLinks" href={`mailto:${HR_BENEFITS_EMAIL}`}>
                {HR_BENEFITS_EMAIL}
              </Link>
              {` ${t("benefits.communicationText4")}`}
            </p>
            <div className="benefitSubHeads">{t("benefits.app")}</div>
            <p>
              {t("benefits.the")}
              <b>{t("benefits.myCigna")}</b>
              {`${t("benefits.myCignaLine1")} ${t("benefits.myCignaLine2")}`}
            </p>
            <p className="mb-2">{t("benefits.myCignaLine3")}</p>
            <div className="benefitsAppGpay">
              <a href={MYCIGNA_APP_STORE} target="_blank" rel="noreferrer">
                <Image src={IOS} alt="ios" className="benefitAppstore" />
              </a>
              <a href={MYCIGNA_PLAY_STORE} target="_blank" rel="noreferrer">
                <Image src={GooglePlay} alt="android" className="benefitgplay " />
              </a>
            </div>
          </Col>
        </Row>
      </Container>
      <Container fluid className="premiumsSection">
        <Container className="premiumsSection">
          <Row className="benefitWrapper" id="enrollBenefit">
            <Col xs={12} md={6} lg={6} sm={12}>
              <div className="benefitSubHeads">{t("benefits.premiums")}</div>
              <p>{t("benefits.premiumText")}</p>
              <div className="benefitSubHeads">{t("benefits.missedDeductions")}</div>
              <p>{t("benefits.missedDeductionsText")}</p>
              <div className="benefitSubHeads">{t("benefits.cobra")}</div>
              <p>{t("benefits.cobraText")}</p>
              <div className="benefitSubHeads">{t("benefits.contacts")}</div>
              <p>
                {`${t("benefits.contactsText")} `}
                <Link className="benefitLinks" href={`mailto:${HR_BENEFITS_EMAIL}`}>
                  {HR_BENEFITS_EMAIL}
                </Link>
                {`. ${t("benefits.contactsText1")} `}
                <b>{t("benefits.contactMonday")}</b>
                {` ${t("benefits.contactThrough")} `}
                <b>{t("benefits.contactsFriday")}</b>
                {` ${t("benefits.contactTime")} `}
                <b>
                  <Link className="benifitUrl" href={`tel:${HR_BENEFITS_PHONE}`}>
                    {HR_BENEFITS_PHONE}
                  </Link>
                  .
                </b>
              </p>
            </Col>
            <Col xs={12} md={6} lg={6} sm={12} className="benePremiums">
              <div className="benefitSubHeads">{t("benefits.benefitContinuation")}</div>
              <p>{t("benefits.benefitContinuationText")}</p>
            </Col>
          </Row>
        </Container>
      </Container>
    </div>
  );
};

export default Benefits;
