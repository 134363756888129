import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en_common from "./en/common.json";
import en_commonV2 from "./en/commonV2.json";
import en_nurse from "./en/nurse.json";
import en_physician from "./en/physician.json";
import es_common from "./es/common.json";
import es_nurse from "./es/nurse.json";
import es_physician from "./es/physician.json";

i18n.use(initReactI18next).init({
  lng: "en",
  fallbackLng: ["en"],
  interpolation: { escapeValue: false },
  ns: ["nurse", "common", "commonV2"],
  defaultNS: "nurse",
  fallbackNS: ["common", "commonV2"],
  resources: {
    en: {
      common: en_common,
      commonV2: en_commonV2,
      nurse: en_nurse,
      physician: en_physician,
    },
    es: {
      common: es_common,
      nurse: es_nurse,
      physician: es_physician,
    },
  },
});

export default i18n;
