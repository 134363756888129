import {
  POST_SIGNUP_SUCCESS,
  POST_SIGNUP_REQUEST,
  POST_SIGNUP_FAILURE,
  SET_SIGNUP_FLAG_TO_DEFAULT,
  SET_USER_EXISTS_FLAG,
} from "./action";

const initialState = {
  signUpSuccessResponse: [],
  error: null,
  loading: false,
  signUpSuccess: false,
  userExists: false,
};
const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case POST_SIGNUP_REQUEST:
      return { ...state, loading: true };
    case POST_SIGNUP_SUCCESS:
      return {
        ...state,
        loading: false,
        signUpSuccessResponse: action.payload.data,
        signUpSuccess: true,
        error: "",
        userExists: false,
      };
    case POST_SIGNUP_FAILURE: {
      const { response } = action.payload;
      const userExist =
        response && response.data && response.data.message && response.data.message.includes("409");

      return {
        ...state,
        loading: false,
        signUpSuccess: false,
        error: response,
        userExists: userExist,
      };
    }
    case SET_SIGNUP_FLAG_TO_DEFAULT:
      return { ...state, signUpSuccess: false };
    case SET_USER_EXISTS_FLAG:
      return { ...state, userExists: action.payload };
    default:
      return state;
  }
};

export default rootReducer;
