import React from "react";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import Modal from "@material-ui/core/Modal";
import { BrowserView, MobileView, isMobile } from "react-device-detect";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import {
  saveSkillChecklistGroupSkills,
  saveSkillChecklistGroupCompleteStatus,
  saveSkillChecklistSelected,
  updateSelectedSkillCompleteFlag,
  updatedSkillsChecklist,
} from "../../../../redux/onBoarding/action";
import { SecondaryButton, PrimaryButton } from "../../Buttons";

function OnBoardingSkillRadio(props) {
  const useStyles = makeStyles((theme) => ({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  }));

  const classes = useStyles();
  const [skillChecklistSelected] = React.useState(props.skillChecklistSelected);
  const [openRate, setOpenRate] = React.useState(false);
  const [skillsChecklistGroupSkills] = React.useState(props.skillsChecklistGroupSkills);
  const [bulkUpdateValue, setBulkUpdateValue] = React.useState("");
  let initialValue = "";
  if (props.skillCheckListGroupSkillAsProp && !props.isBulkUpdate && skillChecklistSelected) {
    for (let i = 0; i < skillChecklistSelected.groups.length; i++) {
      if (skillChecklistSelected.groups[i].name === props.skillChecklistGroupName) {
        for (let j = 0; j < skillChecklistSelected.groups[i].skills.length; j++) {
          if (
            skillChecklistSelected.groups[i].skills[j].name ===
            props.skillCheckListGroupSkillAsProp.name
          ) {
            initialValue = skillChecklistSelected.groups[i].skills[j].value;
          }
        }
      }
    }
  } else {
    initialValue =
      localStorage.getItem(`groupn${props.skillChecklistGroupName}`) ===
      props.skillChecklistGroupName
        ? localStorage.getItem(`groupv${props.skillChecklistGroupName}`)
        : "";
  }

  const [value, setValue] = React.useState(initialValue);

  const checkSkillCompleteStatus = () => {
    const groupsList = [];
    skillChecklistSelected.groups.forEach((group) => {
      groupsList.push(group.name);
    });
    const isIncluded = (currentValue) => props.completedSkillsCheckList.includes(currentValue);
    props.updateSelectedSkillCompleteFlag(groupsList.every(isIncluded));
  };

  const handleCloseRate = () => {
    setValue("");
    setOpenRate(false);
  };

  const handleBulkChange = (event) => {
    setValue(event.target.value);
    setBulkUpdateValue(event.target.value);
    localStorage.setItem(`groupn${props.skillChecklistGroupName}`, props.skillChecklistGroupName);
    localStorage.setItem(`groupv${props.skillChecklistGroupName}`, event.target.value);
    setOpenRate(true);
  };
  const closeRate = (event) => {
    setValue("");
    setOpenRate(false);
  };

  const handleChange = (event) => {
    setOpenRate(false);
    props.skillUpdated(true);
    setValue(event.target.value ? event.target.value : bulkUpdateValue);
    const skillsChecklistGroupSkillsFromStore = skillsChecklistGroupSkills;
    const skillChecklistSelectedLocal = skillChecklistSelected;
    if (!props.isBulkUpdate) {
      const skillsCheckListResponse = {
        atsId: props.skillCheckListGroupSkillAsProp.id,
        name: props.skillCheckListGroupSkillAsProp.name,
        value: event.target.value,
      };

      if (
        props.skillsChecklistGroupSkills.some(
          (skillsChecklistGroupSkill) =>
            skillsChecklistGroupSkill.name === props.skillCheckListGroupSkillAsProp.name
        )
      ) {
        const index = skillsChecklistGroupSkillsFromStore.findIndex(
          (skillsChecklistGroupSkill) =>
            skillsChecklistGroupSkill.name === props.skillCheckListGroupSkillAsProp.name
        );
        skillsChecklistGroupSkillsFromStore[index] = skillsCheckListResponse;
        props.saveSkillChecklistGroupSkills(skillsChecklistGroupSkillsFromStore);
      } else {
        skillsChecklistGroupSkillsFromStore.push(skillsCheckListResponse);
        props.saveSkillChecklistGroupSkills(skillsChecklistGroupSkillsFromStore);
      }
      props.updatedSkillsChecklist(skillsCheckListResponse);
      if (isMobile) props.deselectBulkUpdate();
    } else {
      const skillChecklistFromStore = skillChecklistSelected;
      const index = skillChecklistSelected.groups.findIndex(
        (skillChecklist) => skillChecklist.name === props.skillChecklistGroupName
      );
      for (let i = 0; i < skillChecklistFromStore.groups[index].skills.length; i++) {
        const skillsCheckListResponse = {
          atsId: skillChecklistFromStore.groups[index].skills[i].id,
          name: skillChecklistFromStore.groups[index].skills[i].name,
          value: event.target.value ? event.target.value : bulkUpdateValue,
        };
        if (
          props.skillsChecklistGroupSkills.some(
            (skillsChecklistGroupSkill) =>
              skillsChecklistGroupSkill.name ===
              skillChecklistFromStore.groups[index].skills[i].name
          )
        ) {
          const skillsIndex = skillsChecklistGroupSkillsFromStore.findIndex(
            (skillsChecklistGroupSkill) =>
              skillsChecklistGroupSkill.name ===
              skillChecklistFromStore.groups[index].skills[i].name
          );
          skillsChecklistGroupSkillsFromStore[skillsIndex] = skillsCheckListResponse;
        } else {
          skillsChecklistGroupSkillsFromStore.push(skillsCheckListResponse);
        }
        skillChecklistFromStore.groups[index].skills[i].value = event.target.value
          ? event.target.value
          : bulkUpdateValue;
      }
      if (isMobile) props.confirmBulkUpdate(bulkUpdateValue, props.skillChecklistGroupName);
      props.saveSkillChecklistGroupSkills(skillsChecklistGroupSkillsFromStore);
      props.saveSkillChecklistSelected(skillChecklistFromStore);
    }

    const completedSkillsGroupFromStore = props.completedSkillsCheckList;
    for (let i = 0; i < skillChecklistSelected.groups.length; i++) {
      let isPresent = true;
      for (let j = 0; j < skillChecklistSelected.groups[i].skills.length; j++) {
        if (
          props.skillsChecklistGroupSkills.some(
            (skillsChecklistGroupSkill) =>
              skillsChecklistGroupSkill.name === skillChecklistSelected.groups[i].skills[j].name
          )
        ) {
          isPresent = isPresent && true;
        } else {
          isPresent = isPresent && false;
        }
        if (
          !props.isBulkUpdate &&
          skillChecklistSelectedLocal &&
          skillChecklistSelectedLocal.groups[i].skills[j].name ===
            props.skillCheckListGroupSkillAsProp.name
        ) {
          skillChecklistSelectedLocal.groups[i].skills[j].value = event.target.value;
        }
      }
      if (
        isPresent &&
        !completedSkillsGroupFromStore.includes(skillChecklistSelected.groups[i].name)
      ) {
        completedSkillsGroupFromStore.push(skillChecklistSelected.groups[i].name);
        props.saveSkillChecklistGroupCompleteStatus(completedSkillsGroupFromStore);
        checkSkillCompleteStatus();
      }
      if (props.isBulkUpdate) {
        completedSkillsGroupFromStore.push("Dummy");
        props.saveSkillChecklistGroupCompleteStatus(completedSkillsGroupFromStore);
      }
    }
    props.saveSkillChecklistSelected(skillChecklistSelectedLocal);
  };

  return (
    <div>
      <BrowserView>
        <FormControl component="fieldset">
          <RadioGroup
            aria-label="skill"
            name="skill"
            value={value}
            onChange={props.isBulkUpdate ? handleBulkChange : handleChange}
            className="onBSkillGroups"
          >
            <FormControlLabel
              value={props.t("onBoarding.skillChecklist.notApplicable")}
              control={<Radio />}
              label={props.t("onBoarding.skillChecklist.notApplicable")}
            />
            <FormControlLabel
              value={props.t("onBoarding.skillChecklist.noExperience")}
              control={<Radio />}
              label={props.t("onBoarding.skillChecklist.noExperience")}
            />
            <FormControlLabel
              value={props.t("onBoarding.skillChecklist.someExperience")}
              control={<Radio />}
              label={props.t("onBoarding.skillChecklist.someExperience")}
            />
            <FormControlLabel
              value={props.t("onBoarding.skillChecklist.intermittentExperience")}
              control={<Radio />}
              label={props.t("onBoarding.skillChecklist.intermittentExperience")}
            />
            <FormControlLabel
              value={props.t("onBoarding.skillChecklist.experienced")}
              control={<Radio />}
              label={props.t("onBoarding.skillChecklist.experienced")}
            />
            <FormControlLabel
              value={props.t("onBoarding.skillChecklist.veryExperienced")}
              control={<Radio />}
              label={props.t("onBoarding.skillChecklist.veryExperienced")}
            />
          </RadioGroup>
        </FormControl>
      </BrowserView>
      <MobileView>
        <FormControl component="fieldset" className="onBSkillRadioMob">
          <RadioGroup
            aria-label="skill"
            name="skill1"
            value={value}
            onChange={props.isBulkUpdate ? handleBulkChange : handleChange}
            className="onBSkillGroups"
          >
            <div>
              <FormControlLabel
                value={props.t("onBoarding.skillChecklist.notApplicable")}
                control={<Radio />}
                label={props.t("onBoarding.skillChecklist.notApplicable")}
              />
            </div>
            <div>
              <FormControlLabel
                value={props.t("onBoarding.skillChecklist.noExperience")}
                control={<Radio />}
                label={props.t("onBoarding.skillChecklist.noExperience")}
              />
            </div>
            <div>
              <FormControlLabel
                value={props.t("onBoarding.skillChecklist.someExperience")}
                control={<Radio />}
                label={props.t("onBoarding.skillChecklist.someExperience")}
              />
            </div>
            <div>
              <FormControlLabel
                value={props.t("onBoarding.skillChecklist.intermittentExperience")}
                control={<Radio />}
                label={props.t("onBoarding.skillChecklist.intermittentExperience")}
              />
            </div>
            <div>
              <FormControlLabel
                value={props.t("onBoarding.skillChecklist.experienced")}
                control={<Radio />}
                label={props.t("onBoarding.skillChecklist.experienced")}
              />
            </div>
            <div>
              <FormControlLabel
                value={props.t("onBoarding.skillChecklist.veryExperienced")}
                control={<Radio />}
                label={props.t("onBoarding.skillChecklist.veryExperienced")}
              />
            </div>
          </RadioGroup>
        </FormControl>
      </MobileView>
      <Modal
        className={classes.modal}
        open={openRate}
        onClose={handleCloseRate}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openRate}>
          <div className="onBSkillsCheckPopUpDesk">
            <div className="textCenter onBSkillRatingDesk">
              {props.t("onBoarding.skillChecklist.quickSetPopupMsg")} <b>{value}</b>{" "}
              {props.t("onBoarding.skillChecklist.inThisGroup")}
            </div>
            <br />

            <div className="d-flex justify-content-center">
              {" "}
              <SecondaryButton onClick={handleChange}>
                {props.t("onBoarding.skillChecklist.yesBtnLbl")}
              </SecondaryButton>
              &nbsp;&nbsp;
              <PrimaryButton onClick={closeRate}>
                {props.t("onBoarding.skillChecklist.noBtnLbl")}
              </PrimaryButton>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}

const mapStateToProps = (state) => ({
  skillChecklistSelected: state.onBoarding.skillChecklistSelected,
  skillsChecklistGroupSkills: state.onBoarding.skillsChecklistGroupSkills,
  completedSkillsCheckList: state.onBoarding.completedSkillsCheckList,
});

const mapDispatchToProps = (dispatch) => ({
  saveSkillChecklistGroupSkills: (skillsChecklistGroupSkillsFromStore) =>
    dispatch(saveSkillChecklistGroupSkills(skillsChecklistGroupSkillsFromStore)),
  saveSkillChecklistGroupCompleteStatus: (completeStatusList) =>
    dispatch(saveSkillChecklistGroupCompleteStatus(completeStatusList)),
  saveSkillChecklistSelected: (skillChecklistSelected) =>
    dispatch(saveSkillChecklistSelected(skillChecklistSelected)),
  updateSelectedSkillCompleteFlag: (completedFlag) =>
    dispatch(updateSelectedSkillCompleteFlag(completedFlag)),
  updatedSkillsChecklist: (skillsCheckListResponse) =>
    dispatch(updatedSkillsChecklist(skillsCheckListResponse)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withRouter(OnBoardingSkillRadio)));
