import { Component } from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Link from "@material-ui/core/Link";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  isUserPresentInSystem,
  forgotPassword,
} from "../../../redux/auth/action";
import config from "../../../config";
import { paths } from "../../../AppRouter";
import { LOGIN_HELP_EMAIL, CUSTOMER_SERVICE_PHONE } from "../../../constants/contact_info";
import { History } from "history";

const styles = () => ({});

interface IProps {
  t?: (string) => string;
  isUserAvailable?: (email: string, companyCode: string, companyId: string) => string;
  forgotPassword?: (string) => string;
  resetPassApiError?: string;
  showResend?: boolean;
  userDoesNotExist?: boolean;
  passResetEmailSent?: boolean;
  forgotPassLoading?: boolean;
  resendBtnLoading?: boolean;
  ifPassResetEmailSent?: boolean;
  resetPassAttempts?: number;
  email?: string | (string | null)[] | null;
  company?: {
    code?: string;
    id?: string;
  };
  otherProps?: {
    history: History;
  };
  needHelpClicked?: boolean;
}
class HelpSignIn extends Component<IProps> {
  componentDidMount() {
    if (this.props.email) {
      if (
        (this.props.email as string).trim().length === 0 ||
        !(this.props.email as string).match(config.EMAIL_REGEX)
      ) {
        this.setState({
          error: true,
        });
      } else {
        this.setState({
          email: this.props.email,
          error: false,
        });
      }
    }
  }

  state = {
    email: "",
    error: true,
    loading: false,
  };

  trySignInAgainHandler = () => {
    this.props.otherProps!.history.push({
      pathname: paths.LOGIN,
      state: { needHelpClicked: this.props.needHelpClicked },
    });
    this.props.forgotPassword?.({
      showResend: false,
      userDoesNotExist: false,
      passResetEmailSent: false,
    });
  };

  resetErrorFlags = () => {
    this.setState({
      error: false,
    });
  };

  recoveryButtonHandler = (isResend) => {
    let forgotPassLoading;
    isResend ? (forgotPassLoading = true) : (forgotPassLoading = false);
    this.props.forgotPassword?.({
      userDoesNotExist: false,
      passResetEmailSent: false,
      forgotPassLoading,
    });
    this.props.isUserAvailable?.(
      this.state.email.trim().toLowerCase(),
      this.props.company!.code!,
      this.props.company!.id!
    );
  };

  onEmailAddressChange = (event) => {
    let errorFound = false;
    this.props.forgotPassword?.({ userDoesNotExist: false, passResetEmailSent: false });
    this.setState({
      [event.target.name]: event.target.value,
    });
    if (event.target.value.trim().length === 0 || !event.target.value.match(config.EMAIL_REGEX)) {
      errorFound = true;
    }
    this.setState({
      error: errorFound,
    });
  };

  resendButton() {
    return (
      <div className="">
        <Button
          variant="contained"
          className="secondaryBtn helpSignInButtonDisabled"
          onClick={this.recoveryButtonHandler.bind(this, true)}
          disabled={this.props.resendBtnLoading}
        >
          {this.props.t?.("forgotPassword.resendEmailBtn")}{" "}
          {this.props.resendBtnLoading && (
            <CircularProgress size={23} className="ml-2 loaderResendBtn" />
          )}
        </Button>
      </div>
    );
  }

  render() {
    console.error("API error:", this.props.resetPassApiError);
    const helperText = this.props.ifPassResetEmailSent
      ? this.props.t?.("forgotPassword.emailSentSuccess")
      : this.props.userDoesNotExist
      ? this.props.t?.("forgotPassword.userDoesNotExistError")
      : this.props.resetPassApiError
      ? this.props.t?.("forgotPassword.defaultErrorMsg")
      : "";
    const showResetCount = !!(
      this.props.resetPassAttempts! < 3 && this.props.resetPassAttempts! > 0
    );
    const showHelpSignIn = this.props.showResend === false;

    return (
      <div>
        <div className="genericLogo" />
        <div className="HelpSignIn">
          <h3 className="forgotpass">{this.props.t?.("forgotPassword.forgotPassHead")}</h3>
          <div className="recoverTxt">
            {showHelpSignIn
              ? this.props.t?.("forgotPassword.recoveryText1")
              : this.props.t?.("forgotPassword.resendEmailText")}
          </div>
          <TextField
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            className={
              this.props.ifPassResetEmailSent
                ? "helpSignInEmailSent helpSignInField"
                : "helpSignInField helpSignInDefault"
            }
            name="email"
            helperText={helperText}
            error={(this.props.userDoesNotExist as boolean) || !!this.props.resetPassApiError}
            defaultValue={this.props.email}
            label={this.props.t?.("login.emailLabel")}
            autoComplete="current-email"
            disabled={this.props.showResend}
            onChange={(event) => {
              this.onEmailAddressChange(event);
            }}
          />
          <div className="sendBtnMargin forgotPassTxt">
            <div className="signInBtnAlign">
              {this.props.resetPassAttempts === 0 ? (
                <div className="helpsignBtmTxt">
                  {" "}
                  {this.props.t?.("forgotPassword.loginHelpMessage")}&nbsp;
                  {this.props.t?.("forgotPassword.loginHelpMessageCall")}&nbsp;
                  <Link href={`tel:${CUSTOMER_SERVICE_PHONE}`} className="loginHelpEmail">
                    {CUSTOMER_SERVICE_PHONE}
                  </Link>
                  &nbsp;
                  {this.props.t?.("forgotPassword.loginHelpMessage1")}
                  <Link
                    href={`mailto:${LOGIN_HELP_EMAIL}?subject=${this.props.t?.(
                      "forgotPassword.loginHelpEmailSubject"
                    )}`}
                    className="loginHelpEmail"
                  >
                    {LOGIN_HELP_EMAIL}
                  </Link>
                </div>
              ) : showHelpSignIn ? (
                <Button
                  className="signInButton helpSignInButtonDisabled"
                  onClick={this.recoveryButtonHandler.bind(this, false)}
                  disabled={!!this.state.error}
                >
                  {this.props.t?.("forgotPassword.recoverLink")}
                </Button>
              ) : (
                this.resendButton()
              )}
            </div>

            {showResetCount ? (
              <div className="attemptsLeft">
                {this.props.t?.("forgotPassword.attemptText1")! +
                  this.props.resetPassAttempts! +
                  this.props.t?.("forgotPassword.attemptText2")}
              </div>
            ) : null}
            <div className="signInBtnAlign mb-3">
              <span
                className={
                  this.props.resetPassAttempts === 0
                    ? "signUpLabel goBackLabel pl-0 pt-0"
                    : "signUpLabel goBackLabel pl-1"
                }
              >
                <KeyboardBackspaceIcon className="backArrowSignIn" />
                <Link className="goBackToSign" href="#" onClick={this.trySignInAgainHandler}>
                  {this.props.t?.("forgotPassword.signInLink")}
                </Link>
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  isUserAvailable: (email, companyCode, companyId) =>
    dispatch(isUserPresentInSystem(email, companyCode, companyId)),
  forgotPassword: (obj) => dispatch(forgotPassword(obj)),
});

const mapStateToProps = (state) => ({
  showResend: state.auth.showResend,
  userDoesNotExist: state.auth.userDoesNotExist,
  ifPassResetEmailSent: state.auth.passResetEmailSent,
  resendBtnLoading: state.auth.forgotPassLoading,
  resetPassAttempts: state.auth.resetPassAttempts,
  resetPassApiError: state.auth.resetPassApiError,
  company: state.company.company,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(withTranslation()(HelpSignIn)));
