// CCN Links

export const RETAIL_DISCOUNT_PERKSPOT = "https://crosscountry.perkspot.com/Login";
export const MYCIGNA_APP_STORE = "https://apps.apple.com/app/id569266174";
export const MYCIGNA_PLAY_STORE =
  "https://play.google.com/store/apps/details?id=com.cigna.mobile.mycigna&WT.z_nav=about-us/cigna-mobile";
export const FIDELITY_BENEFITS = "https://netbenefits.com";
export const SOCIAL_SECURITY_CALCULATOR =
  "https://content.cctc.com/cctcContent/resources/pdf/benefits/Social-Security-Calculator.pdf";
export const PAYCHECK_CITY = "https://www.paycheckcity.com/";
export const WORKERS_COMPENSATION_DOCUMENT =
  "http://content.cctc.com/cctcContent/resources/pdf/benefits/CCTC%20Worker's%20Compensation%20and%20Risk%20Management.pdf";
export const GOOGLEMAPS_FROM_URL = "https://www.google.com/maps/dir/";
export const GOOGLEMAPS_TO_URL = "https://www.google.com/maps/dir//";
export const REFER_FRIEND_URL_CCN = "https://www.crosscountry.com/refer-a-friend";
export const EZSTUBS_URL = "https://my.doculivery.com/External/CCH/Login.aspx";
export const CERIDIAN_URL = "https://myrecords.ceridian.com";
export const BOARD_OF_NURSING_URL = "https://www.ncsbn.org/contact-bon.htm";
export const DIGITAL_INTERVIEW_URL = "https://www.crosscountrynurses.com/ivs-specialties";
export const DIGITAL_INTERVIEW_URL_CCN = "https://www.crosscountrynurses.com/ivs-specialties";
export const CAREER_CARE_IOS = "https://apps.apple.com/us/app/careercare/id1389959090";
export const CAREER_CARE_ANDROID =
  "https://play.google.com/store/apps/details?id=com.crosscountry.careercare";
export const FOLLOW_US_FACEBOOK_URL_CCN = "https://www.facebook.com/CrossCountryNursesAndAllied";
export const FOLLOW_US_INSTAGRAM_URL_CCN = "https://www.instagram.com/crosscountrynursesandallied/";
export const FOLLOW_US_LINKEDIN_URL_CCN =
  "https://www.linkedin.com/company/cross-country-healthcare";
export const FOLLOW_US_YOUTUBE_URL_CCN = "https://www.youtube.com/@crosscountryhealthcare";
export const FOLLOW_US_TIKTOK_URL_CCN = "https://www.tiktok.com/@crosscountryhealthcare";
// Links For The Documents In Payroll Notices, Wage & Pay Section
export const DIRECT_DEPOSIT_FORM_DOCUMENT =
  "https://assets.crosscountry.com/payroll/woasset_document_payroll_directdepositenrollment.pdf";
export const ONBOARDING_PAYMENT_DOCUMENT =
  "https://assets.crosscountry.com/payroll/woasset_document_payroll_cchonboardingpayment.pdf";
export const REIMBURSEMENT_REQUEST_DOCUMENT =
  "https://assets.crosscountry.com/payroll/woasset_document_payroll_cchpreimbursementrequest.pdf";
export const SAN_FRANCISCO_SICK_PAY_NOTICE_DOCUMENT =
  "https://assets.crosscountry.com/payroll/woasset_document_payroll_sanfransickpaynotice.pdf";
export const SAN_FRANCISCO_MINIMUM_WAGE_DOCUMENT =
  "https://assets.crosscountry.com/payroll/woasset_document_payroll_sfminwage.pdf";
export const PORTLAND_SICK_PAY_NOTICE_DOCUMENT =
  "https://assets.crosscountry.com/payroll/woasset_document_payroll_portlandsickpaynotice.pdf";
export const NEW_JERSEY_SICK_PAY_NOTICE_DOCUMENT =
  "https://assets.crosscountry.com/payroll/woasset_document_payroll_njsickpaynotice.pdf";
export const CALIFORNIA_PAID_SICK_NOTICE_DOCUMENT =
  "https://assets.crosscountry.com/payroll/woasset_document_payroll_paidsickdayspostertemplate.pdf";
export const EARNED_SICK_TIME_NOTICE_DOCUMENT =
  "https://assets.crosscountry.com/payroll/woasset_document_payroll_estemployeenotice.pdf";
// Link for Open Enrollment Page
export const WORKER_COMPENSATION_URL =
  "https://www.crosscountryhealthcare.com/docs/cch workers comp process.pdf";
export const CCRN_HRINTOUCH_URL = "https://ccrn.hrintouch.com";
export const CCRN_RELIANCEMATRIX_URL="http://rsli.acieap.com";

export const NEWS_BLOG_URL_CCN =
  "https://www.crosscountrynurses.com/sites/Satellite?c=FW_BlogParent&cid=1530205590378&pagename=ccn%2FFW_BlogRSS&renderTemplate=%2FCCH%2FBlog%2FLayout&site=ccn&spubid=1530201935391";
export const CORS_PROXY_URL = "https://cors-anywhere.herokuapp.com/";

// CCA Links
export const REFER_FRIEND_URL_CCA = "https://www.crosscountryallied.com/referrals";
export const FOLLOW_US_FACEBOOK_URL_CCA = "https://www.facebook.com/CrossCountryNursesAndAllied";
export const FOLLOW_US_INSTAGRAM_URL_CCA = "https://www.instagram.com/crosscountrynursesandallied/";
export const FOLLOW_US_LINKEDIN_URL_CCA =
  "https://www.linkedin.com/company/cross-country-healthcare";
export const FOLLOW_US_YOUTUBE_URL_CCA = "https://www.youtube.com/@crosscountryhealthcare";
export const FOLLOW_US_TIKTOK_URL_CCA = "https://www.tiktok.com/@crosscountryhealthcare";
export const DIGITAL_INTERVIEW_URL_CCA = "https://www.crosscountryallied.com/cca-interactive-app";
export const NEWS_BLOG_URL_CCA =
  "https://www.crosscountryallied.com/sites/Satellite?c=FW_BlogParent&cid=1385644584454&pagename=cca%2FFW_BlogRSS&renderTemplate=%2FCCH%2FBlog%2FLayout&site=cca&spubid=1530202418967";
export const PAYROLL_CCA = "https://my.doculivery.com/External/CCH/Login.aspx";

export const PRIVACY_POLICY_URL = "https://www.crosscountry.com/privacy-policy";
