import parsePhoneNumber, { ParseError } from "libphonenumber-js";
import Link from "@material-ui/core/Link";
import { recruiterConstraint } from "src/services/CommonUtility";
import {
  RECRUITER_DEFAULT_CARE_TEAM_NAME,
  RECRUITER_DEFAULT_CARE_TEAM_PHONE,
  RECRUITER_DEFAULT_CARE_TEAM_EMAIL,
} from "src/constants/contact_info";
import { memo } from "react";
import { DashboardProps } from "src/components/common/MainContent/Home/index";

interface RecruiterInformationProps {
  recruiterDetails: DashboardProps["recruiterDetails"];
  displayRecruiterPhone?: boolean;
  displayRecruiterName?: boolean;
  displayRecruiterEmail?: boolean;
}

const RecruiterInformation = ({
  recruiterDetails,
  displayRecruiterEmail,
  displayRecruiterName,
  displayRecruiterPhone,
}: RecruiterInformationProps): JSX.Element => {
  const getTrimmedNumber = (
    recruiterDetail: RecruiterInformationProps["recruiterDetails"]
  ): string => {
    const validPhoneNumber =
      recruiterDetail?.phone &&
      !Number.isNaN(recruiterDetail?.phone) &&
      recruiterDetail?.phone.length === 10;

    if (validPhoneNumber) {
      try {
        const parsedNumber = parsePhoneNumber(recruiterDetail.phone, "US")?.nationalNumber.trim();
        return `${parsedNumber?.substring(0, 3)}-
        ${parsedNumber?.substring(3, 6)}-
        ${parsedNumber?.substring(6, parsedNumber.length)}`;
      } catch (error) {
        if (error instanceof ParseError) {
          throw new Error("Not a phone number");
        } else {
          throw error;
        }
      }
    }
    return RECRUITER_DEFAULT_CARE_TEAM_PHONE;
  };

  const getRecruiterName = (
    recruiterDetail: RecruiterInformationProps["recruiterDetails"]
  ): string => {
    if (!recruiterDetail?.firstName || !recruiterDetail?.lastName) {
      return RECRUITER_DEFAULT_CARE_TEAM_NAME;
    }
    const validFirstName = recruiterConstraint(recruiterDetail.firstName);
    const validLastName = recruiterConstraint(recruiterDetail.lastName);
    if (validFirstName && validLastName) {
      return `${recruiterDetail.firstName} ${recruiterDetail.lastName}`;
    }
    return RECRUITER_DEFAULT_CARE_TEAM_NAME;
  };

  const recruiterPhoneNumberTrim = getTrimmedNumber(recruiterDetails);
  const recruiterName = getRecruiterName(recruiterDetails);
  const recruiterEmail = recruiterDetails?.emailId || RECRUITER_DEFAULT_CARE_TEAM_EMAIL;

  return (
    <>
      {displayRecruiterPhone && recruiterPhoneNumberTrim && (
        <Link href={`tel:${recruiterPhoneNumberTrim || RECRUITER_DEFAULT_CARE_TEAM_PHONE}`}>
          {" "}
          {recruiterPhoneNumberTrim || RECRUITER_DEFAULT_CARE_TEAM_PHONE}
        </Link>
      )}
      {displayRecruiterName && recruiterName}
      {displayRecruiterEmail && recruiterEmail && (
        <Link href={`mailto:${recruiterDetails?.emailId || RECRUITER_DEFAULT_CARE_TEAM_EMAIL}`}>
          {recruiterDetails?.emailId || RECRUITER_DEFAULT_CARE_TEAM_EMAIL}
        </Link>
      )}
    </>
  );
};

RecruiterInformation.defaultProps = {
  displayRecruiterEmail: false,
  displayRecruiterName: false,
  displayRecruiterPhone: false,
};

export default memo(RecruiterInformation);
