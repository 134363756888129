import { useInbox } from "@trycourier/react-inbox";
import { useEffect, FunctionComponent } from "react";
import config from "src/config";
import styles from "./Footer.module.scss";

const Footer: FunctionComponent = () => {
  const loadCourier = config.LOAD_COURIER === "true";

  const { markAllAsRead, unreadMessageCount, getUnreadMessageCount } = useInbox();

  useEffect(() => {
    getUnreadMessageCount();
  }, []);

  const handleClearAll = () => {
    try {
      markAllAsRead();
    } catch (err) {
      console.error(err);
    }
  };

  if (unreadMessageCount > 0 && loadCourier) {
    return (
      <button onClick={handleClearAll} className={styles.Footer} type="button">
        Mark all as read
      </button>
    );
  }
  return <></>;
};

export default Footer;
