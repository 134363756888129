import { Component } from "react";
import moment from "moment";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "@material-ui/core/Button";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ShareIcon from "@material-ui/icons/Share";
import Chip from "@material-ui/core/Chip";
import SchoolIcon from "@material-ui/icons/School";
import RoomOutlinedIcon from "@material-ui/icons/RoomOutlined";
import WorkOutlineIcon from "@material-ui/icons/WorkOutline";
import { BrowserView, MobileView } from "react-device-detect";
import IconButton from "@material-ui/core/IconButton";
import { connect } from "react-redux";
import { withTranslation, TFunction } from "react-i18next";
import { RouteComponentProps, withRouter } from "react-router-dom";
import Image from "react-bootstrap/Image";
import Popover from "react-bootstrap/Popover";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import JobDescriptionEmail from "src/components/common/MainContent/JobDescription/JobDescriptionEmail";
import { detectJobCardClick, setJobQuickApplyRequirements } from "src/redux/jobDetails/action";
import ShareJob from "src/components/common/MainContent/JobDescription/ShareJob/ShareJob";
import JobDisclaimerToolTip from "src/components/common/MainContent/JobResults/JobDisclaimerToolTip";
import Analytics from "src/services/Analytics";
import MapIconJobDetail from "src/imagesV2/MapIconJobDetail.svg";
import jobsearchQueryBuilder from "src/services/QueryBuilder/JobSearchQueryBuilder";
import QuickOfferImg from "src/imagesV2/quickoffer.svg";
import { getRotatingShiftLabel } from "src/services/CommonUtility";
import { JOBS_DETAILS } from "src/constants/campaigns_constants";
import MainJobDetail from "src/components/common/MainContent/JobDescription/MainJobDetail";
import {
  facilityCustomContentResult,
  jobDetailType,
  MyJobsFiltered,
} from "src/components/common/MainContent/JobResults/common/index";
import config from "src/config";
import { RecommendedJobsSourceTypes } from "src/types/receommendedJobsSource";
import FacilityDetailsModal from "./FacilityDetails/FacilityDetailsModal";

const enableQuickApply = config.ENABLE_QUICK_APPLY === "true";
const facilityModalApplicabale = config.FACILITY_MODAL === "true";
interface JobDetailsObjectProps {
  atsJobId: string;
  professionCode: string;
  specialty: string;
  state: string;
  city: string;
  zip: string;
  shift: string;
  type: string;
  weeklyPay: string;
  facilityName: string;
  sourceSubArea: string;
  specialtyCode: string;
  salaryMax: number;
  isQuickApplied: boolean;
}
interface JobDetailsV2Props {
  t: TFunction<"translation", undefined>;
  jobDetailsObject: JobDetailsObjectProps;
  jobQuickApplyRequirements: any;
  campaignCode: string;
  history: RouteComponentProps["history"];
  sourceType: string;
  jobDetails: jobDetailType;
  isRecommended: boolean;
  companyId: string;
  jobId: number;
  company: { id: number };
  myJobs: MyJobsFiltered;
  eligibilityPercentage: number | null;
  addQuickApplyReqToStore: (data) => void;
  facilityCustomContent: facilityCustomContentResult[];
  jobsSource: RecommendedJobsSourceTypes;
}
interface StateProps {
  showFacilityText: boolean;
  scrollY: number;
  shareBtnActive: boolean;
  gaCalled: boolean;
  jobId: string | null;
  openFacilityDetailsModal: boolean;
}

class JobDetailsV2 extends Component<JobDetailsV2Props & RouteComponentProps, StateProps> {
  constructor(props) {
    super(props);
    this.state = {
      showFacilityText: false,
      scrollY: 0,
      shareBtnActive: false,
      gaCalled: false,
      jobId: null,
      openFacilityDetailsModal: false
    };
    this.popOverOpen = this.popOverOpen.bind(this);
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll.bind(this));
    localStorage.setItem("historyCounter", "0");
  }

  componentDidUpdate() {
    const { jobQuickApplyRequirements } = this.props;

    const openJobDetailsClicked = jobsearchQueryBuilder.getOpenJobDetails();
    if (
      this.props.jobDetails.id !== this.state.jobId &&
      openJobDetailsClicked &&
      ((jobQuickApplyRequirements && Object.keys(jobQuickApplyRequirements).length > 0) ||
        this.props.jobDetailsObject?.isQuickApplied)
    ) {
      this.setState({ jobId: this.props.jobDetails.id });
      this.handleGAEvent();
      this.handleSegmentEvent();
      jobsearchQueryBuilder.setOpenJobDetails(false);
    }
  }

  componentWillUnmount() {
    localStorage.removeItem("historyCounter");
    this.props.addQuickApplyReqToStore(null);
  }

  handleGAEvent() {
    const dimensions = {
      dimension9: this.props.jobDetailsObject.specialtyCode,
      dimension10: this.props.campaignCode,
      dimension7: this.props.jobDetailsObject.atsJobId,
    };
    const event = {
      category: this.props.t("googleAnalytics.category.jobs"),
      action: this.props.t("googleAnalytics.action.openJobDetails"),
      label: this.props.t("googleAnalytics.label.jobDetails"),
    };
    Analytics.sendPageview(this.props.history.location, dimensions);
    Analytics.sendEvent(event);
  }

  handleSegmentEvent() {
    const segmentEventName = this.props.t("segment.jobViewed");
    const segmentEventProps: any = {
      jobId: this.props.jobDetailsObject.atsJobId,
      origin: document.referrer,
      profession: this.props.jobDetailsObject.professionCode,
      specialty: this.props.jobDetailsObject.specialtyCode,
      state: this.props.jobDetailsObject.state,
      city: this.props.jobDetailsObject.city,
      zip: this.props.jobDetailsObject.zip,
      shift: this.props.jobDetailsObject.shift,
      type: this.props.jobDetailsObject.type,
      weeklyPay: this.props.jobDetailsObject.salaryMax,
      facilityName: this.props.jobDetailsObject.facilityName,
      sourceType: this.props.sourceType,
      sourceSubArea: this.props.campaignCode,
      eligibilityPercent : this.props.eligibilityPercentage,
    
    };

    const { totalCount, completionPercentage, appRequirementsNotMet } =
      this.getQuickApplyProperties();

    // if already quick applied then skip this step
    if (!this.props.jobDetailsObject.isQuickApplied) {
      segmentEventProps.applicationRequirementsTotal = totalCount;
      segmentEventProps.applicationRequirementsSatisfiedPercentage = completionPercentage;
      segmentEventProps.applicationRequirementsNotMet = appRequirementsNotMet;
    }
    Analytics.segmentTrackEvent(segmentEventName, segmentEventProps);
  }

  getQuickApplyProperties() {
    const { totalCount, reqCompletedCount } = this.getApplicationRequirementsInfo(
      this.props.jobQuickApplyRequirements?.qualifications
    );
    return {
      totalCount,
      completionPercentage: (reqCompletedCount / totalCount) * 100,
      appRequirementsNotMet: this.props.jobQuickApplyRequirements?.quickApply ? "No" : "Yes",
    };
  }

  getApplicationRequirementsInfo(requirements) {
    const excludeKeys = ["eligibility", "status", "specialtyProfession"];
    let reqCount = 0;
    let reqComplete = 0;

    for (const key in requirements) {
      if (!excludeKeys.includes(key)) {
        reqCount++;
        if (key === "credential") {
          if (requirements[key]?.credentials?.length > 0) {
            if (requirements[key]?.quickApply) {
              reqComplete++;
            }
          } else {
            reqCount--;
          }
        } else if (requirements[key]?.quickApply) {
          reqComplete++;
        }
      }
    }

    return { totalCount: reqCount, reqCompletedCount: reqComplete };
  }

  onShareButtonClick() {
    this.setState({ scrollY: window.pageYOffset });
    this.trackShareJobEvent();
    this.trackSegmentEvent();
  }

  trackShareJobEvent() {
    const dimensions = {
      dimension9: this.props.jobDetailsObject.specialtyCode,
      dimension7: this.props.jobDetailsObject.atsJobId,
    };
    const event = {
      category: this.props.t("googleAnalytics.category.jobs"),
      action: this.props.t("googleAnalytics.action.shareJob"),
      label: this.props.t("googleAnalytics.label.shareJob"),
    };

    Analytics.sendPageview(this.props.history.location, dimensions);
    Analytics.sendEvent(event);
  }

  trackSegmentEvent() {
    const segmentEventName = this.props.t("segment.jobShared");
    const segmentEventProps = {
      jobId: this.props.jobDetailsObject.atsJobId,
      origin: document.referrer,
      profession: this.props.jobDetailsObject.professionCode,
      specialty: this.props.jobDetailsObject.specialtyCode,
      state: this.props.jobDetailsObject.state,
      city: this.props.jobDetailsObject.city,
      zip: this.props.jobDetailsObject.zip,
      shift: this.props.jobDetailsObject.shift,
      type: this.props.jobDetailsObject.type,
      weeklyPay: this.props.jobDetailsObject.salaryMax,
      facilityName: this.props.jobDetailsObject.facilityName,
      sourceType: JOBS_DETAILS,
      sourceSubArea: JOBS_DETAILS,
    };
    Analytics.segmentTrackEvent(segmentEventName, segmentEventProps);
  }

  handleScroll(scrollY) {
    if (scrollY !== window.pageYOffset) {
      if (this.refs.shareJobPopover) {
        this.setState({ shareBtnActive: false });
      }
    }
  }

  handleBackButtonClick() {
    this.props.history.go(-1 - parseInt(localStorage.getItem("historyCounter") || ""));
  }

  exclusiveChip() {
    return (
      <Chip
        size="medium"
        label={this.props.t("jobs.jobDescription.exclusive")}
        className="exclusiveLabel"
      />
    );
  }

  quickOfferChip() {
    return (
      <Chip
        size="medium"
        label={this.props.t("jobCardV2.quickOffer")}
        className="quickOfferLabel mr-1"
      />
    );
  }

  preApprovedChip() {
    return (
      <Chip
        size="medium"
        label={this.props.t("jobs.jobDescription.preApproved")}
        className="getpreLabel"
      />
    );
  }

  showFacilityText(val) {
    this.setState({
      showFacilityText: val,
    });
  }

  teachingChip() {
    return (
      <IconButton
        size="small"
        className="removePointerEvents"
        onMouseEnter={this.showFacilityText.bind(this, true)}
        onMouseLeave={this.showFacilityText.bind(this, false)}
      >
        <SchoolIcon fontSize="small" className="teachingFacility" />
        <span className={this.state.showFacilityText ? "showFacilityText" : "hideFacilityText"}>
          {this.props.t("jobs.jobDescription.teachingFacility")}
        </span>
      </IconButton>
    );
  }

  teachingChipMob() {
    return (
      <Chip
        icon={<SchoolIcon style={{ color: "#fff" }} />}
        size="medium"
        label={this.props.t("jobs.jobDescription.teachingFacility")}
        className="teachingFacilityMob"
      />
    );
  }

  newChip() {
    return (
      <Chip
        size="medium"
        label={this.props.t("jobs.jobDescription.newLabel")}
        className="newLabel"
      />
    );
  }

  dateDiff(job) {
    const date1 = moment(moment.utc().format("MMMM DD, YYYY"));
    const date2 = moment(moment.utc(job.datePosted).format("MMMM DD, YYYY"));
    const diff = date1.diff(date2, "days");
    return { diff };
  }

  displayMultipleOpening() {
    return (
      <Col xs={12} sm={12} md={6} lg={6}>
        <p className="pJobDetails addressjobdetails">
          <WorkOutlineIcon fontSize="small" />
          &nbsp;{this.props.t("jobs.jobDescription.multipleOpeningsText")}
        </p>
      </Col>
    );
  }

  displayFacilityAddress(job) {
    return (
      <Col xs={12} sm={12} md={6} lg={6}>
        <p className="pJobDetails addressjobdetails">
          <RoomOutlinedIcon fontSize="small" />
          {job.location}, {job.city ? `${job.city}, ${job.state}` : job.state}
          {job.zip}
        </p>
      </Col>
    );
  }

  displayColumn(colName, colVal) {
    const {
      t,
      jobDetails: { salaryHourly, salaryExpenseReimbursementWeekly, salaryMin, salaryMax },
    } = this.props;
    return (
      <Col xs={6} sm={6} md={2} lg={2} className="jobDetailsPadding">
        <p className="pJobDetails">
          <span className="jobDetailsColName">{colName}</span>
          {colName === this.props.t("jobDetails.grossPayRateColHeadingV2") ? (
            salaryMax ? (
              salaryHourly && salaryExpenseReimbursementWeekly && salaryMin ? (
                <JobDisclaimerToolTip
                  translate={t}
                  isJobDetail
                  salaryHourly={salaryHourly}
                  salaryWeekly={salaryExpenseReimbursementWeekly}
                  isSalary
                />
              ) : (
                <JobDisclaimerToolTip translate={t} isJobDetail defaultMessage isSalary />
              )
            ) : (
              <JobDisclaimerToolTip translate={t} isJobDetail defaultMessage isSalary={false} />
            )
          ) : null}
          <br />
          <span className="payrateJobDetails">
            <p>{colVal}</p>
          </span>
        </p>
      </Col>
    );
  }

  popOverOpen(isPopOverOpen) {
    this.setState({ shareBtnActive: isPopOverOpen });
  }

  toggleFacilityDetails(){
    // Modal Viewed Event For Facility Popup 
    const segmentEventName = this.props.t("segment.modalViewedEvent");
    const segmentEventProps = {
      modal_type: this.props.t("jobDetails.modalName"),
      segment_source_name: `${process.env.NODE_ENV}-web-gateway`,
    };
    Analytics.segmentTrackEvent(segmentEventName, segmentEventProps);
    this.setState({openFacilityDetailsModal: !this.state.openFacilityDetailsModal})
  }

  getFacilityNameHeading(){
    if(facilityModalApplicabale && this.props.jobDetails?.facilityName && this.props.jobDetails?.isCustomContentEnabled){
      return (
        <span>{ this.props.jobDetails?.facilityName}                   
          <a style={{width: "fit-content"}} onClick={this.toggleFacilityDetails.bind(this)}>
            {this.props.t("jobDetails.viewFacilityDetails")}
          </a>
        </span> 
          )
    }else if(this.props.jobDetails?.facilityName){
      return (
        <span>{this.props.jobDetails?.facilityName}</span>
      )
    }else return ""
  }

  render() {
    const job = this.props.jobDetails;
    const { campaignCode, isRecommended, companyId, company, myJobs } = this.props;
    const popover = (
      <Popover id="shareJobOverlay" className="jobSharePopover">
        <ShareJob jobId={job.id} company={company} />
      </Popover>
    );
    let mspJob;
    let teachingJob;
    let newJob;
    let teachingJobMob;
    let quickOffer;
    let isMultipleOpening;
    let startDate;
    let submissionDate;
    let grossPay;
    let noOfBeds;
    let shiftType;
    let workHours;
    let hrsPerWeek;
    if (job.msp === "Y") mspJob = this.exclusiveChip();
    if (job.isIvs === "Y") quickOffer = this.quickOfferChip();
    if (job.type === "Teaching") {
      teachingJob = this.teachingChip();
      teachingJobMob = this.teachingChipMob();
    }
    const dateDiff = this.dateDiff(job);
    if (dateDiff.diff <= 1) newJob = this.newChip();
    if (job.numberOfOpen && job.numberOfOpen > 1) isMultipleOpening = this.displayMultipleOpening();
    if (job.startDate) {
      const dateStarted = moment.utc(job.startDate).format("MMMM DD, YYYY");
      startDate = this.displayColumn(this.props.t("jobDetails.startDateColHeadingV2"), dateStarted);
    }
    const isApplied =
      myJobs && myJobs.quickApply._embedded.results.find((myJob) => myJob.id === job.id);
    if (isApplied && isApplied.jobStatus !== "" && isApplied.jobStatus !== "Applied") {
      const dateSubmitted = moment.utc(job.startDate).format("MMMM DD, YYYY");
      submissionDate = this.displayColumn(
        this.props.t("jobDetails.submissionDateColHeadingV2"),
        dateSubmitted
      );
    }
    if (
      job.salaryMax &&
      job.salaryMax !== 0 &&
      job.salaryHourly &&
      job.salaryExpenseReimbursementWeekly &&
      job.salaryMin
    ) {
      const payRate = job.salaryMax.toLocaleString();
      grossPay = this.displayColumn(
        this.props.t("jobDetails.grossPayRateColHeadingV2"),
        <span className="grossPayValue"> ${payRate} </span>
      );
    } else {
      grossPay = this.displayColumn(this.props.t("jobDetails.grossPayRateColHeadingV2"), "");
    }

    if (job.numberOfBeds > 0)
      noOfBeds = this.displayColumn(
        <span className="jobDetailsBed">{this.props.t("jobDetails.bedsColHeadingV2")}</span>,
        <span className="jobDetailsBed">{job.numberOfBeds}</span>
      );

    if (job.hoursPerWeek)
      hrsPerWeek = this.displayColumn(
        <span className="jobDetailsBedMob">{this.props.t("payroll.paystubMain.hours")}</span>,
        <span className="jobDetailsBedMob">
          {job.hoursPerWeek + this.props.t("jobs.jobsCard.hrsPerWeek")}
        </span>
      );
    if (job.shift) {
      const shiftArr = job.shift.toLowerCase().split(" ");
      const firstNonNumericIndex = shiftArr[0].substring(0).search(/[A-Za-z]/);

      let shift = `${
        shiftArr[0].substring(0, firstNonNumericIndex) +
        shiftArr[0].charAt(firstNonNumericIndex).toUpperCase() +
        shiftArr[0].substring(firstNonNumericIndex + 1)
      } ${shiftArr[1].charAt(0).toUpperCase()}${shiftArr[1].substring(1)}`;

      if (shiftArr.length === 3) {
        shift = `${shift} ${shiftArr[2]}`;
      }

      if (shift.toLocaleLowerCase().includes(this.props.t("jobs.jobsCard.rotation"))) {
        shift = getRotatingShiftLabel(shift);
      }

      if (shift.toLocaleLowerCase().includes(this.props.t("jobs.jobsCard.evening"))) {
        shift = shift.replace("Evenings", "Mid");
      }

      shiftType = this.displayColumn(this.props.t("jobDetails.shiftTypeColHeadingV2"), shift);
    }
    if (job.duration > 0)
      workHours = this.displayColumn(
        this.props.t("jobDetails.lengthColHeadingV2"),
        `${job.duration} ${this.props.t("jobs.jobDescription.weeksColHeading")}`
      );
    return (
      <div>
        <Row>
          <Col xs={12} sm={12} md={12} lg={12} className="backButtonV2">
            <div className="mainjobdetailsbutton">
              <Button
                className="backButtonJobdetails"
                onClick={this.handleBackButtonClick.bind(this)}
              >
                <ArrowBackIcon fontSize="small" className="arrowBackIcon" />
                <span className="jobdetailsbuttonlabel">
                  {this.props.t("jobs.jobDescription.backButton")}
                </span>
              </Button>
              <OverlayTrigger trigger="click" placement="bottom" overlay={popover} rootClose>
                <Button
                  className={
                    this.state.shareBtnActive ? "shareButtonActive" : "shareButtonJobdetails"
                  }
                  onClick={this.onShareButtonClick.bind(this)}
                >
                  <span className="jobdetailsbuttonlabel">
                    <ShareIcon fontSize="small" className="iconsizejobdetails" />
                    <span className="JobDetailShare">
                      {this.props.t("jobs.jobDescription.shareButton")}
                    </span>
                  </span>
                </Button>
              </OverlayTrigger>
            </div>
          </Col>
        </Row>

        <div className={enableQuickApply ? "quickApplyFlagOn" : ""}>
          <div className="jobDetailsRow">
            <div className="jobDetailsInfo">
              <div>
                <BrowserView>
                  {job.isIvs === "Y" && (
                    <div className="jDetailsQuickOffer">
                      <div>
                        <Image src={QuickOfferImg} className="quickOfferImg" />
                        <span className="quickOfferTxtJD">
                          {this.props.t("jobCardV2.quickOffer")}
                        </span>
                        <span className="quickOfferSubTxtJD">
                          {this.props.t("jobDetails.quickOfferText")}
                        </span>
                      </div>
                    </div>
                  )}
                  {quickOffer}
                  {mspJob}
                  {newJob}
                  {teachingJob}
                </BrowserView>
                <MobileView>
                  {job.isIvs === "Y" && (
                    <div className="jDetailsQuickOffer">
                      <div className="jDQuickOfferImgMob">
                        <Image src={QuickOfferImg} className="quickOfferImg" />
                      </div>
                      <div className="jdQuickOfferTxtMob">
                        <span className="quickOfferTxtJD">
                          {this.props.t("jobCardV2.quickOffer")}
                        </span>
                        <span className="quickOfferSubTxtJD">
                          {this.props.t("jobDetails.quickOfferText")}
                        </span>
                      </div>
                    </div>
                  )}
                  {quickOffer}
                  {mspJob}
                  {newJob}
                  {teachingJobMob}
                </MobileView>
              </div>
              <div className="specialityJobdetails">
                {job.title ? job.title.replace("/", "-") : job.title}
                <MobileView />
              </div>
              <div className="d-flex flex-wrap">
                <span className="jobDetailsState">
                  <Image src={MapIconJobDetail} className="mapIconJobDetail" />
                  {job.city ? `${job.city}, ${job.state}` : job.state}
                  <br />
                  {this.getFacilityNameHeading()}
                </span>
                {grossPay}
              </div>
              <div className="d-flex borderlineJobdetails">{isMultipleOpening}</div>
              <div className="d-flex specialitydetails">
                {startDate}
                {submissionDate}
                {noOfBeds}
                {shiftType}
                {hrsPerWeek}
                {workHours}
              </div>
            </div>

            <div className="descriptiontab">
              <div className="borderBottom">
                <MainJobDetail jobDetails={job} />
              </div>
            </div>

            <div className="jobDescLayoutV2">
              <JobDescriptionEmail
                shouldOpen={true}
                job={job}
                campaignCode={campaignCode}
                isFavorite={false}
                className=""
                companyId={companyId}
                isRecommended={isRecommended}
                jobsSource={this.props.jobsSource}
              />
            </div>
          </div>
        </div>
        {
          facilityModalApplicabale && this.state.openFacilityDetailsModal ? (
            <FacilityDetailsModal
              facilityCustomContent={this.props.facilityCustomContent?.[0]?.data?.info}
              isOpen={this.state.openFacilityDetailsModal}
              handleClose={this.toggleFacilityDetails.bind(this)}
              // jobDetailsObject={this.props.jobDetailsObject}
            />
          ) : null
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  company: state.company.company,
  campaignCode: state.jobDetails.campaignCode,
  jobDetailsObject: state.jobDetails.jobDetails,
  facilities: state.facility.facilities,
  searchReqObject: state.jobResults.jobSearchReqObject,
  candidateSpecialties: state.auth?.candidateSpecialties?.[0] ?? null,
  myJobs: state.myJobs.filteredJobs,
  jobQuickApplyRequirements: state.jobDetails.jobQuickApplyRequirements,
  eligibilityPercentage: state.jobDetails.eligibilityPercentage,
});

const mapDispatchToProps = (dispatch) => ({
  detectJobCardClick: (obj) => dispatch(detectJobCardClick(obj)),
  addQuickApplyReqToStore: (reqObj) => dispatch(setJobQuickApplyRequirements(reqObj)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withRouter(JobDetailsV2)));
