import { connect } from "react-redux";
import { Navbar, Nav } from "react-bootstrap";
import { TFunction, withTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import config from "src/config";
import {
  DASHBOARD,
  MY_JOBS,
  PAYROLL_TIMESHEET,
  BENEFITS,
  MY_PROFILE,
  LATEST_TASKS,
  BASIC_INFO_PAGE,
  EDU_SPEC_PAGE,
  WORK_EXP_PAGE,
  SKILL_CHECKLIST_OVERVIEW_PAGE,
  LEGACY_PAGE,
  CERTIFICATIONS_LICENSES,
  REFERENCES_PAGE,
  MY_TEAM_PAGE,
} from "src/router/routes";
import { isHamburgerSubMenuOpen, isHamburgerMenuOpen } from "src/redux/navigation/action";
import { updateActiveFlag } from "src/redux/onBoarding/action";
import { handleSelectedJobMenu } from "src/redux/jobs/SearchCrossCountry/action";
import Analytics from "src/services/Analytics";

interface LocalNavV2Props {
  t: TFunction<"translation", undefined>;
  isTheHamburgerSubMenuOpen: (data: boolean) => void;
  setHamburgerMenuOpen: (data: boolean) => void;
  onBoardingCompletedSteps: { [key: number]: boolean };
  useProfileIframe: boolean;
  updateTheActiveFlag: (data: number) => void;
}

const LocalNavV2 = ({
  t,
  isTheHamburgerSubMenuOpen,
  setHamburgerMenuOpen,
  onBoardingCompletedSteps,
  useProfileIframe,
  updateTheActiveFlag,
}: LocalNavV2Props) => {
  const history = useHistory();
  const location = useLocation();
  const enableMyTeam = config.ENABLE_MY_TEAM === "true";

  function getPageFromPathname(pathname) {
    if (pathname === "/") {
      return DASHBOARD;
    }

    if (
      pathname.includes("my-profile") ||
      (!useProfileIframe && pathname.includes("certifications-and-licenses"))
    ) {
      return MY_PROFILE;
    }

    if (pathname.includes("need-help")) {
      return BENEFITS;
    }

    if (pathname.includes("payroll")) {
      return PAYROLL_TIMESHEET;
    }

    if (pathname.includes("my-jobs/jobs")) {
      return MY_JOBS;
    }

    if (pathname.includes("certifications-and-licenses")) {
      return CERTIFICATIONS_LICENSES;
    }

    if (enableMyTeam && pathname.includes("my-team")) {
      return MY_TEAM_PAGE;
    }

    return pathname;
  }

  const trackSegmentEvent = (eventKey) => {
    const segmentEventName = t("segment.clickedHeader");
    const segmentEventProps = {
      segment_source_name: `${process.env.NODE_ENV}-web-gateway`,
      parent_text: null,
      text: null,
    };
    switch (eventKey) {
      case DASHBOARD:
        segmentEventProps.parent_text = t("footer.profile.dashboard");
        segmentEventProps.text = t("footer.profile.dashboard");
        break;
      case LATEST_TASKS:
        segmentEventProps.parent_text = t("headerV2.myTasks");
        segmentEventProps.text = t("headerV2.myTasks");
        break;
      case PAYROLL_TIMESHEET:
        segmentEventProps.parent_text = t("footer.profile.payroll");
        segmentEventProps.text = t("footer.profile.payroll");
        break;
      case BENEFITS:
        segmentEventProps.parent_text = t("headerV2.benefits");
        segmentEventProps.text = t("headerV2.benefits");
        break;
      case MY_TEAM_PAGE:
        segmentEventProps.parent_text = t("headerV2.myTeam");
        segmentEventProps.text = t("headerV2.myTeam");
        break;
      case CERTIFICATIONS_LICENSES:
        segmentEventProps.parent_text = t("footer.profile.certificatesLicenses");
        segmentEventProps.text = t("footer.profile.certificatesLicenses");
        break;
      case MY_JOBS:
        segmentEventProps.parent_text = t("header.jobsLink");
        segmentEventProps.text = t("header.jobsLink");
        break;
      case MY_PROFILE:
        segmentEventProps.parent_text = t("footer.profile.profileDocs");
        segmentEventProps.text = t("footer.profile.profileDocs");
        break;
      default:
        break;
    }
    Analytics.segmentTrackEvent(segmentEventName, segmentEventProps);
  };

  const navigateToMyProfilePage = () => {
    let pageToRedirect = BASIC_INFO_PAGE;
    if (!onBoardingCompletedSteps[0]) {
      pageToRedirect = BASIC_INFO_PAGE;
      updateTheActiveFlag(0);
    } else if (!onBoardingCompletedSteps[1]) {
      pageToRedirect = EDU_SPEC_PAGE;
      updateTheActiveFlag(1);
    } else if (!onBoardingCompletedSteps[2]) {
      pageToRedirect = WORK_EXP_PAGE;
      updateTheActiveFlag(2);
    } else if (!onBoardingCompletedSteps[3]) {
      pageToRedirect = SKILL_CHECKLIST_OVERVIEW_PAGE;
      updateTheActiveFlag(3);
    } else if (!onBoardingCompletedSteps[4]) {
      pageToRedirect = REFERENCES_PAGE;
      updateTheActiveFlag(4);
    } else {
      pageToRedirect = BASIC_INFO_PAGE;
      updateTheActiveFlag(0);
    }
    history.push(pageToRedirect);
  };

  const handleSelect = (eventKey) => {
    trackSegmentEvent(eventKey);
    if (eventKey === MY_PROFILE) {
      isTheHamburgerSubMenuOpen(false);
      setHamburgerMenuOpen(false);
      if (useProfileIframe) {
        const url = {
          pathname: LEGACY_PAGE,
          search: "?page=basic-info",
        };
        history.push(url);
      } else {
        navigateToMyProfilePage();
      }
    } else if (!eventKey.includes("https")) {
      isTheHamburgerSubMenuOpen(false);
      setHamburgerMenuOpen(false);
      history.push(eventKey);
    } else {
      isTheHamburgerSubMenuOpen(false);
      setHamburgerMenuOpen(false);
    }
  };

  const renderNavItems = (pageName) => {
    const pageMap = {
      [DASHBOARD]: t("footer.profile.dashboard"),
      [MY_JOBS]: t("header.jobsLink"),
      [MY_PROFILE]: t("footer.profile.profileDocs"),
      [CERTIFICATIONS_LICENSES]: t("footer.profile.certificatesLicenses"),
      [LATEST_TASKS]: t("headerV2.myTasks"),
      [MY_TEAM_PAGE]: t("headerV2.myTeamTitle"),
      [PAYROLL_TIMESHEET]: t("footer.profile.payroll"),
      [BENEFITS]: t("headerV2.benefits"),
    };
    return (
      <Nav.Item data-testid="NavLinks" key={Math.random()}>
        <Nav.Link eventKey={pageName}>
          <div className="navItemHeight">{pageMap[`${pageName}`]}</div>
          <span className="myActivelink" />
        </Nav.Link>
      </Nav.Item>
    );
  };

  const pages = [
    DASHBOARD,
    MY_JOBS,
    MY_PROFILE,
    CERTIFICATIONS_LICENSES,
    LATEST_TASKS,
    MY_TEAM_PAGE,
    PAYROLL_TIMESHEET,
    BENEFITS
  ];

  if (
    location.pathname.includes("profile") ||
    location.pathname === "/" ||
    location.pathname.includes("jobs") ||
    location.pathname.includes("saved-searches")
  ) {
    return (
      <div className="globalNavbar container-fluid">
        <div className="container">
          <Navbar>
            <Nav activeKey={getPageFromPathname(location.pathname)} onSelect={handleSelect}>
              {pages.map((page) => {
                if (page === CERTIFICATIONS_LICENSES && !useProfileIframe) return null;
                if (page === MY_TEAM_PAGE && !enableMyTeam) return null;
                return renderNavItems(page);
              })}
            </Nav>
          </Navbar>
        </div>
      </div>
    );
  }
  return <></>;
};

const mapStateToProps = (state) => ({
  localNavigationKey: state.navigation.localNavigationKey,
  globalNavigationKey: state.navigation.globalNavigationKey,
  company: state.company.company,
  onBoardingCompletedSteps: state.onBoarding.completedSteps,
  jobsFilterCriteria: state.myJobs.jobFilterReqObj,
  useProfileIframe: state.auth.useProfileIframe,
});

const mapDispatchToProps = (dispatch) => ({
  isTheHamburgerSubMenuOpen: (data) => dispatch(isHamburgerSubMenuOpen(data)),
  setHamburgerMenuOpen: (data) => dispatch(isHamburgerMenuOpen(data)),
  updateTheActiveFlag: (data) => dispatch(updateActiveFlag(data)),
  handleSelectedJobMenu: (obj) => dispatch(handleSelectedJobMenu(obj)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(LocalNavV2));
