import { useEffect, useState } from "react";
import { useSelector, useDispatch, connect } from "react-redux";
import { useHistory } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import orderBy from "lodash/orderBy";
import moment from "moment";
import { useMutation } from "@apollo/client";
import { uuid } from "uuidv4";
import jsonwebtoken from "jsonwebtoken";
import Skeleton from "@material-ui/lab/Skeleton";
import SnackbarReturnToSource from "src/components/common/SnackbarReturnToSource/SnackbarReturnToSource";
import usePrevious from "src/hooks/usePrevious";
import { APIMethods, ReferencesStates } from "src/constants/constants";
import {
  SET_REFERENCES,
  DELETE_REFERENCES,
  getAllReferencesQuery,
  GET_ALL_WORK_EXPERIENCE,
} from "src/services/GraphQL/graph.query";
import config from "src/config";
import { graphqlRequest as request } from "src/services/GraphQL/GraphQL";
import combinedReferences from "src/services/CombinedReferences";
import Analytics from "src/services/Analytics";
import {
  updateActiveFlag,
  updateCompleteFlag,
  saveProfileSuccess,
} from "src/redux/onBoarding/action";
import { WORK_EXP_PAGE, DASHBOARD, SKILL_CHECKLIST_OVERVIEW_PAGE } from "src/router/routes";
import validationsService from "src/services/Validations";
import referencesConstraint from "src/services/ReferencesConstraint";
import onboardingFields from "src/constants/onboardingFieldNames.json";
import AddRefBannerMob from "src/imagesV2/AddRefBanner-Mobile.svg";
import AddRefBanner from "src/imagesV2/AddReferencesBanner.svg";
import { PrimaryButton, SecondaryButton, TertiaryButton } from "src/components/common/Buttons";
import { getAccessToken } from "axios-jwt";
import MultipleSelect from "./onBBasicInfoSelect";
import { OnBoardingConfirmModal } from "./OnBoardingConfirmModal";
import OnBoardingBackBtnConfirmBox from "./OnBoardingBackBtnConfirmBox";
import OnBoardingReferencesTable from "./OnBoardingReferencesTable";
import MyProfileNavWrapper from "./MyProfileNavWrapper";

const enableWorkHistory = config.ENABLE_WORK_HISTORY === "true";
const workExpIsDeleted = config.WORK_EXPERIENCES_IS_DELETED === "true";
const perStepPercentage = 20;

const useStyles = makeStyles((theme: any) => ({
  onBoardingReferencesButtonHolder: {
    border: "2px solid blue",
  },
  showReferenceForm: {
    display: "block",
  },
  hideReferenceForm: {
    display: "none",
  },
  nextBtnMarginleft: {
    marginLeft: "auto",
    border: "none",
  },
  outlineBtnColor: {
    border: "1px solid #707070 !important",
    color: "#707070",
  },
  addRefText: {
    fontSize: "0.85rem",
    color: "#707070",
  },
  addRefSubHead: {
    fontSize: "1rem",
    color: theme.palette.colorPrimary,
    fontWeight: "bold",
    marginTop: "25px",
    marginBottom: "0",
  },
  referTermsAndCondition: {
    color: "#626C7D",
    textDecoration: "underline",
    "&:hover": {
      color: theme.palette.colorSecondary,
      "@media (max-width: 768px)": {
        color: theme.palette.colorPrimary,
      },
    },
  },
  addRefBanner: {
    backgroundImage: `url(${AddRefBanner})`,
    height: "130px",
    marginTop: "0rem",
    marginBottom: "1rem",
    backgroundSize: "100%",
    backgroundRepeat: "no-repeat",
    "@media(min-width: 1024px) and (max-width: 1280px)": {
      marginBottom: "0rem",
      height: "100px",
    },
    "@media(min-width: 0px) and (max-width: 768px)": {
      backgroundImage: `url(${AddRefBannerMob})`,
      height: "73px",
      marginTop: "60px",
      marginBottom: "0rem",
    },
  },
  onBEditIcon: {
    fontSize: "1.2rem !important",
    color: "#373c73",
    fill: "#373c73",
    cursor: "pointer",
    minWidth: "24px !important",
    padding: "0px 15px 0px 5px !important",
    "&:hover": {
      fill: theme.palette.colorSecondary,
      color: theme.palette.colorSecondary,
      backgroundColor: "transparent !important",
    },
    "@media(min-width: 0px) and (max-width: 768px)": {
      padding: "0px 20px 0px 0px !important",
      marginTop: "-8px",
      marginBottom: "-5px",
    },
  },
  iconPaddingBottom: {
    paddingBottom: 2,
  },
  referencesFormWrapper: {
    "& .referenceForm": {
      marginBottom: "30px",
      marginTop: "15px!important",
      "@media (max-width: 768px)": {
        marginBottom: "0",
      },
    },
    "& .referenceFormMargin": {
      marginBottom: "30px",
      marginTop: "1rem!important",
      "@media (max-width: 768px)": {
        marginBottom: "0",
        marginTop: "0 !important",
      },
    },
  },
  titleDropdown: {
    marginTop: "16px",
    "@media (max-width: 768px)": {
      marginTop: "0",
    },
  },
  multipleSelectDropdown: {
    marginTop: "15px !important",
    "@media (max-width: 768px)": {
      marginTop: "0 !important",
    },
  },
  emailAndPhoneContainer: {
    display: "flex",
    alignItems: "baseline",
    justifyContent: "center",
    marginTop: "15px",
    border: "1px solid #E0E0E0",
    padding: "0 25px 32px 20px",
    borderRadius: "5px",
    position: "relative",
    color: "#707070",
    fontSize: "0.875rem",
    "@media (max-width:768px)": {
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      marginTop: "30px",
      padding: "0 7px 34px 7px",
    },
  },
  emailAndPhoneContainerLabel: {
    position: "relative",
    top: "16px",
    padding: "0px 30px",
    "@media (max-width:768px)": {
      margin: "40px 0 0",
      top: "0",
      "&:before": {
        width: "70px",
        height: "1px",
        content: "''",
        background: "#f3f3f3",
        position: "absolute",
        top: "9px",
        right: "85px",
      },
      "&:after": {
        width: "70px",
        height: "1px",
        content: "''",
        background: "#f3f3f3",
        position: "absolute",
        top: "9px",
        left: "85px",
      },
    },
  },
  emailAndPhoneContainerInputLabel: {
    position: "absolute",
    top: "-11px",
    left: "10px",
    background: "#fff",
    padding: "0 5px",
    "@media (max-width:376px)": {
      fontSize: "0.75rem",
      left: "20px",
    },
    "@media (max-width: 384px) and (max-height: 824px)":{
      whiteSpace: "nowrap",
    },
  },
  errorMessageText: {
    color: theme.palette.colorError,
    margin: "8px 0 0",
    fontSize: "0.8rem",
    fontWeight: 500,
  },
  referenceSkeleton: {
    height: "50px",
  },
}));

interface onboardingReferencesProps {
  updateTheCompleteFlag: (flag: number) => void;
  updateTheActiveFlag: (flag: number) => void;
}

interface RootState {
  auth: {
    email: string;
    candidateId: string;
    userId: string;
  };
  candidate: {
    atsBullhornCandidateId: string;
  };
  company: {
    company: {
      id: string;
    };
  };
  onBoarding: {
    onboardingInformation: any;
    completedSteps: any;
    workExperience: any;
    references: any;
    referencesLoading: boolean;
  };
}
interface WorkExperience {
  city: string;
  meta: {
    id: string;
    createdBy: string;
    createdDate: Date;
    updatedDate: Date;
    associations: {
      tenantId: string;
      isDeleted: boolean;
      tenantEntityId: string;
      tenantLastEgressSyncDate: string;
      tenantLastIngressSyncDate: string;
    }[];
  };
  endDate: Date;
  position: string;
  isTempJob: boolean;
  specialty: string;
  startDate: Date;
  isVerified: boolean;
  profession: string;
  supervisor: string;
  shiftWorked: string;
  facilityName: string;
  facilityType: string;
  patientRatio: string;
  emrTechnology: string;
  floatingPlace: string;
  staffingAgency: string;
  chargeExperience: boolean;
  reasonForLeaving: string;
  teachingFacility: boolean;
  isCurrentlyWorking: boolean;
  atsBullhornCandidateId: string;
}

interface Query {
  email: string;
  isDeleted: boolean;
}

const OnBoardingReferences = ({
  updateTheCompleteFlag,
  updateTheActiveFlag,
}: onboardingReferencesProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const company = useSelector((state: RootState) => state.company.company);
  const userEmail = useSelector((state: RootState) => state.auth.email);
  const atsBullhornCandidateId = useSelector(
    (state: RootState) => state.candidate.atsBullhornCandidateId
  );
  const onboardingInformation = useSelector(
    (state: RootState) => state.onBoarding.onboardingInformation
  );
  const onBoardingCompletedSteps = useSelector(
    (state: RootState) => state.onBoarding.completedSteps
  );
  let onboardingWorkExperience;
  if (!enableWorkHistory) {
    onboardingWorkExperience = useSelector((state: RootState) => state.onBoarding.workExperience);
  }
  const onboardingReferences = useSelector((state: RootState) => state.onBoarding.references);
  const referencesLoading = useSelector((state: RootState) => state.onBoarding.referencesLoading);
  const onboardingReferencesFromDbArray: any[] = [];
  const referencesFields = onboardingFields.references;
  const prevOnboardingReferences = usePrevious(onboardingReferences);
  const requiredErrMsg = t("onBoarding.errorMessage.required");
  const referencesIsDeleted = config.REFERENCES_IS_DELETED === "true";
  const [firstName, setFirstName] = useState({
    value: "",
    errors: true,
    errorText: requiredErrMsg,
  });
  const [lastName, setLastName] = useState({
    value: "",
    errors: true,
    errorText: requiredErrMsg,
  });
  const [title, setTitle] = useState({
    value: "",
    errors: true,
    errorText: requiredErrMsg,
  });
  const [facilityNameDropDown, setFacilityNameDrop] = useState({
    value: "",
    errors: true,
    errorText: requiredErrMsg,
  });
  const [isAllWorkExperienceQueryRunning, setIsAllWorkExperienceQueryRunning] = useState(false);
  const [facilityName, setFacilityName] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [companyId, setCompanyId] = useState(null);
  const [profession, setProfession] = useState(null);
  const [specialty, setSpecialty] = useState(null);
  const [facilityLocation, setFacilityLocation] = useState(null);
  const [employed, setEmployed] = useState(null);
  const [existingToken, setExistingToken] = useState(null);
  const [email, setEmail] = useState({
    value: "",
    errors: false,
    errorText: "",
  });
  const [phone, setPhone] = useState({
    value: "",
    errors: false,
    errorText: "",
  });
  const [facilityList, setFacilityList] = useState<any[]>();
  const [facilityObjectList, setFacilityObjectList] = useState<any[]>();
  const titleList = [
    "Charge Nurse",
    "Director",
    "Manager",
    "Nurse Educator",
    "Supervisor",
    "Team Lead",
  ];
  const [isSaveBtnDisabled, setIsSaveBtnDisabled] = useState(true);
  const [isCancelBtnDisabled, setIsCancelBtnDisabled] = useState(true);
  const [references, setReferences] = useState<any>();
  const [mode, setMode] = useState<string | null>();
  const [editIndex, setEditIndex] = useState<null | number>();
  const [isReferenceForm, setIsReferenceForm] = useState<boolean>();
  const [isBackButtonClicked, setIsBackButtonClicked] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const [addReferenceToDb, { data: addRefResponse }] = useMutation(SET_REFERENCES);
  const [deleteReferenceFromDb, { data: delRefResponse }] = useMutation(DELETE_REFERENCES);
  const workExperience = onboardingWorkExperience;
  const [workHistory, setWorkHistory] = useState<WorkExperience[]>([]);
  const location = JSON.parse(JSON.stringify(history?.location?.state || ""));
  const isUpdateNowClicked = location?.isUpdateNowClicked;
  const source = location?.source;
  const image = location?.image;

  const accessToken = getAccessToken();

  const profileProgressInPercentage = () => {
    let completedPercentage = 0;
    const keys = Object.keys(onBoardingCompletedSteps);
    const noOfStepsCompleted = keys.length;
    completedPercentage = noOfStepsCompleted * perStepPercentage;
    return completedPercentage;
  };

  const trackSegment = (eventType) => {
    const profileProgress = profileProgressInPercentage();
    const segmentEventName = eventType;
    const segmentEventProps = {
      section: t("myProfileSubNav.references"),
      progress: profileProgress,
      origin: document.location.origin,
      segment_source_name: `${process.env.NODE_ENV}-web-gateway`,
      lineItem: t("onBoarding.workExp.supervisorInfo"),
    };
    Analytics.segmentTrackEvent(segmentEventName, segmentEventProps);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    validationsService.init();
    validationsService.setCustomReferencesValidations();
    trackSegment(t("segment.profileViewed"));
  }, []);

  const fetchAllWorkExperienceRecords = async () => {
    try {
      if (userEmail) {
        setIsAllWorkExperienceQueryRunning(true);
        const query: Query = { email: userEmail, isDeleted: workExpIsDeleted };
        let workExperienceRecords: WorkExperience[] = [];
        const workExperienceResponse = await request(GET_ALL_WORK_EXPERIENCE, query);
        const workHistoryContent = workExperienceResponse?.getAllWorkExperience?.content;
        if (workHistoryContent && workHistoryContent.length > 0) {
          workExperienceRecords =
            workHistoryContent[0]?.candidateWorkExperience?.workExperience || [];
          setWorkHistory(workExperienceRecords);
        }
        setIsAllWorkExperienceQueryRunning(false);
      }
    } catch (error) {
      setIsAllWorkExperienceQueryRunning(false);
      console.error("Error while fetching work exp for HCP:", error);
    }
  };

  useEffect(() => {
    if (enableWorkHistory) fetchAllWorkExperienceRecords();
  }, [userEmail]);

  const renderFacilityDropDownName = (reference) => {
    if (reference?.facilityName) {
      if (reference?.startDate) {
        return `${reference.facilityName} - ${reference.startDate} - ${
          reference.endDate ? reference.endDate : t("addReferences.presentLabel")
        }`;
      }
      return reference.facilityName;
    }
    return "-";
  };

  const processOnboardingReferences = () => {
    if (
      onboardingReferences &&
      onboardingReferences !== prevOnboardingReferences &&
      onboardingReferences.length > 0
    ) {
      onboardingReferences.forEach((reference) => {
        const onboardingReferenceFromDb = {
          firstName: reference.supervisorFirstName ?? null,
          lastName: reference.supervisorLastName ?? null,
          title: reference.supervisorTitle ?? null,
          facilityName: reference.facilityName ?? null,
          facilityNameDropDown: renderFacilityDropDownName(reference),
          startDate: reference.startDate ?? null,
          endDate: reference.endDate ?? null,
          email: reference.supervisorEmail ?? "",
          phone: reference.supervisorPhone ?? "",
          status: reference.status ?? null,
          id: reference.id ?? null,
          dateCreated: reference.dateCreated ?? null,
          dateCompleted: reference.dateCompleted ?? null,
          dateNotified: reference.dateNotified,
          atsBullhornCandidateId: reference.atsBullhornCandidateId,
          referenceId: reference.referenceId,
          token: reference.token,
          companyId: reference.companyId,
        };
        onboardingReferencesFromDbArray.push(onboardingReferenceFromDb);
      });
    }

    setReferences(orderBy(onboardingReferencesFromDbArray, (o) => moment(o.dateCreated), ["desc"]));
    setIsReferenceForm(
      !!(onboardingReferencesFromDbArray && !(onboardingReferencesFromDbArray.length > 0))
    );
  };

  const generateWorkExpList = () => {
    const facilityNameList: any[] = [];
    const theFacilityObjectList: any[] = [];
    const workExperienceRecords = enableWorkHistory ? workHistory : workExperience;
    const sortWorkExp = orderBy(
      workExperienceRecords,
      (experience) => moment(experience.startDate).format("YYYYMMDD"),
      ["desc"]
    );

    sortWorkExp.forEach((workExp) => {
      const facilityObject = {
        facilityName: workExp.facilityName ? workExp.facilityName : null,
        startDate: workExp.startDate ? moment.utc(workExp.startDate).format("MM/DD/YYYY") : null,
        endDate: workExp.endDate ? moment.utc(workExp.endDate).format("MM/DD/YYYY") : null,
        facilityDropdownVal:
          `${workExp.facilityName ? workExp.facilityName : "NA"}` +
          " - " +
          `${workExp.startDate ? moment.utc(workExp.startDate).format("MM/DD/YYYY") : "NA"}` +
          " - " +
          `${
            workExp.endDate
              ? moment.utc(workExp.endDate).format("MM/DD/YYYY")
              : t("addReferences.presentLabel")
          }`,
        profession: workExp.profession,
        specialty: workExp.specialty,
        facilityCityState: workExp.city,
        employed: workExp.endDate ? "No" : "Yes",
      };
      theFacilityObjectList.push(facilityObject);
      facilityNameList.push(
        `${workExp.facilityName ? workExp.facilityName : "NA"}` +
          " - " +
          `${workExp.startDate ? moment.utc(workExp.startDate).format("MM/DD/YYYY") : "NA"}` +
          " - " +
          `${
            workExp.endDate
              ? moment.utc(workExp.endDate).format("MM/DD/YYYY")
              : t("addReferences.presentLabel")
          }`
      );
    });
    setFacilityList(facilityNameList);
    setFacilityObjectList(theFacilityObjectList);
  };

  const handleReferencesUpdate = async () => {
    try {
      const tempObj = onboardingInformation;
      if (addRefResponse?.setReferences?.status === 200) {
        // Need to explicitly blank data based on new code changes(Profile table)
        tempObj.onboarding.meta.completedSections.references = false;
        tempObj.onboarding.references = [];
        const query: any = {};
        query.email = userEmail;
        query.isDeleted = referencesIsDeleted;
        const referencesResponse = await request(getAllReferencesQuery, query, accessToken);
        const consolidatedReferences = combinedReferences(referencesResponse);
        if (addRefResponse?.setReferences?.content?.candidateReference) {
          const referencesMeta =
            addRefResponse?.setReferences?.content?.candidateReference?.meta?.completedSections
              ?.references;
          tempObj.onboarding.meta.completedSections.references = referencesMeta;
          tempObj.onboarding.references = consolidatedReferences;
        }
        dispatch(saveProfileSuccess(tempObj));
        addRefResponse.setReferences.status = null;
      }
      if (delRefResponse?.deleteReferences?.status === 200) {
        // Need to explicitly blank data based on new code changes(Profile table)
        tempObj.onboarding.meta.completedSections.references = false;
        tempObj.onboarding.references = [];
        const query: any = {};
        query.email = userEmail;
        query.isDeleted = referencesIsDeleted;
        const referencesResponse = await request(getAllReferencesQuery, query, accessToken);
        const consolidatedReferences = combinedReferences(referencesResponse);
        if (delRefResponse?.deleteReferences?.content?.candidateReference) {
          const referencesMeta =
            delRefResponse?.deleteReferences?.content?.candidateReference?.meta?.completedSections
              ?.references;
          tempObj.onboarding.meta.completedSections.references = referencesMeta;
          tempObj.onboarding.references = consolidatedReferences;
        }
        dispatch(saveProfileSuccess(tempObj));
        delRefResponse.deleteReferences.status = null;
      }
    } catch (error: any) {
      throw new Error(`error while updating references ${error.message}`);
    }
  };

  useEffect(() => {
    processOnboardingReferences();
  }, [onboardingReferences]);

  useEffect(() => {
    generateWorkExpList();
  }, [workExperience, workHistory]);

  useEffect(() => {
    handleReferencesUpdate();
  }, [addRefResponse, delRefResponse]);

  const updateObj = (
    prevObj,
    fieldValue: string,
    errorExists: any
  ): { value: string; errors: boolean; errorText: string } => {
    const prevObjCopy = { ...prevObj };
    const isEmpty = fieldValue.trim() === "";
    const isInvalidInput = !!(errorExists || isEmpty);
    prevObjCopy.value = fieldValue;
    prevObjCopy.errors = isInvalidInput;
    prevObjCopy.errorText = isInvalidInput ? errorExists?.[0] : null;
    return prevObjCopy;
  };

  const getFieldData = (field) => {
    if (field === "firstName") {
      return firstName?.value.trim() !== "" ? firstName.value : null;
    }
    if (field === "lastName") {
      return lastName?.value.trim() !== "" ? lastName.value : null;
    }
    if (field === "title") {
      return title?.value.trim() !== "" ? title.value : null;
    }
    if (field === "facilityNameDropDown") {
      return facilityNameDropDown?.value.trim() !== "" ? facilityNameDropDown.value : null;
    }
    if (field === "email") {
      return email?.value?.trim() !== "" ? email.value : "";
    }
    if (field === "phone") {
      return phone?.value.trim() !== "" ? phone.value : "";
    }
    return "";
  };

  const checkSaveBtnStatus = () => {
    const referenceRecord = {};
    referencesFields.forEach((field) => {
      referenceRecord[`${field}`] = getFieldData(field);
    });
    const validators = validationsService.validateSetOfFields(
      referenceRecord,
      referencesConstraint.referencesFormConstraints
    );
    if (
      (email.value === "" && phone.value === "") ||
      (email.value === "" && phone.value === null) ||
      (email.value === null && phone.value === "") ||
      (email.value === null && phone.value === null)
    ) {
      return true;
    }

    if (validators) {
      return true;
    }
    return false;
  };

  const handleChange = ({ target: { value } }, fieldType) => {
    setOpenModal(true);
    const fieldValue = value?.trim() !== "" ? value : "";
    /**
     * @param validators if validators returns **_undefined_**, then it is valid
     */
    const validators = validationsService.validateSingleField(
      fieldValue,
      referencesConstraint.referencesFormConstraints[`${fieldType}`]
    );
    let obj: { value: string; errors: boolean; errorText: string; notificationSent?: boolean };
    switch (fieldType) {
      case "firstName":
        obj = updateObj(firstName, fieldValue, validators);
        setFirstName({
          ...obj,
        });
        break;
      case "lastName":
        obj = updateObj(lastName, fieldValue, validators);
        setLastName({
          ...obj,
        });
        break;
      case "email":
        obj = updateObj(email, fieldValue, validators);
        obj.notificationSent = false;
        setEmail({ ...obj });
        break;
      case "phone":
        obj = updateObj(phone, fieldValue, validators);
        obj.notificationSent = false;
        setPhone({
          ...obj,
        });
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    setIsSaveBtnDisabled(checkSaveBtnStatus);
    setIsCancelBtnDisabled(false);
  }, [email, phone, firstName, lastName, facilityNameDropDown, title]);

  const handleMultipleSelect = (event, type) => {
    setOpenModal(true);
    const fieldValue =
      event.target.value && event.target.value.trim() !== "" ? event.target.value : "";
    let obj: any = {};
    if (type === "facilityName") {
      obj = updateObj(facilityNameDropDown, fieldValue, false);
      setFacilityNameDrop({
        ...obj,
      });
      let facilityObj;
      facilityObjectList?.forEach((facility) => {
        if (facility.facilityDropdownVal === event.target.value) {
          facilityObj = facility;
        }
      });
      setFacilityName(facilityObj.facilityName);
      setCompanyId(facilityObj.companyId);
      setStartDate(facilityObj.startDate);
      setEndDate(facilityObj.endDate);
      setProfession(facilityObj.profession);
      setSpecialty(facilityObj.specialty);
      setFacilityLocation(facilityObj.facilityCityState);
      setEmployed(facilityObj.employed);
    }
    if (type === "title") {
      obj = updateObj(title, fieldValue, false);
      setTitle({
        ...obj,
      });
    }
  };

  const getProfSpecByWorkExp = async (name) => {
    const workExperienceRecords = enableWorkHistory ? workHistory : workExperience;
    const selectedWorkExp = workExperienceRecords.filter(
      (work) => work.facilityName?.toLowerCase() === name?.toLowerCase()
    );
    return selectedWorkExp;
  };

  const validatePage = (reqObj) => {
    const reqObjCopy = { ...reqObj };
    reqObjCopy.references?.forEach((obj) => {
      if (!obj.facilityNameDropDown) {
        return {
          ...obj,
          facilityNameDropDown: obj.facilityName,
        };
      }
      return obj;
    });
    const validators = validationsService.validateSetOfFields(
      reqObjCopy,
      referencesConstraint.referencesPageConstraints
    );
    let canBeSavedPartially;
    if (validators && validators.references && !validators.references.includes("* Required")) {
      canBeSavedPartially = false;
    } else canBeSavedPartially = true;
    return { validators, canBeSavedPartially };
  };

  const normalizeReferenceJson = (currentReference) => {
    const currentReferenceCopy = [...currentReference];
    const pageValidationObj = {
      references: currentReferenceCopy,
    };
    const { canBeSavedPartially } = validatePage(pageValidationObj);
    let reqObj;
    currentReferenceCopy.map((reference) => {
      const { facilityNameDropDown: tempValue, ...rest } = reference;
      return rest;
    });
    if (canBeSavedPartially) {
      const candidateReferences: any[] = [];
      const normalizedJson: any = {};
      const tokenObject: any = {};
      currentReferenceCopy.forEach((reference) => {
        tokenObject.referenceId = reference.id;
        tokenObject.dateNotified = reference.dateNotified;
        tokenObject.companyId = parseInt(reference.companyId ?? company.id, 10);
        tokenObject.email = userEmail;
        const token = jsonwebtoken.sign(tokenObject, config.JWT_REDIRECT_SECRET_KEY, {
          expiresIn: "72h",
        });
        normalizedJson.id = reference.id;
        normalizedJson.startDate = reference.startDate;
        normalizedJson.endDate = reference.endDate;
        normalizedJson.facilityName = reference.facilityName;
        normalizedJson.supervisorFirstName = reference.firstName;
        normalizedJson.supervisorLastName = reference.lastName;
        normalizedJson.supervisorTitle = reference.title;
        normalizedJson.supervisorEmail = reference.email;
        normalizedJson.supervisorPhone = reference.phone;
        normalizedJson.notificationSent = reference.notificationSent;
        normalizedJson.status = reference.status;
        normalizedJson.dateCompleted = reference.dateCompleted;
        normalizedJson.dateCreated = reference.dateCreated;
        normalizedJson.dateLastUpdated = reference.dateLastUpdated;
        normalizedJson.token = reference.notificationSent ? existingToken : token;
        normalizedJson.profession = reference.profession;
        normalizedJson.specialty = reference.specialty;
        normalizedJson.isDeleted = reference.isDeleted || false;
        normalizedJson.dateNotified = reference.dateNotified;
        normalizedJson.referenceId = reference?.referenceId || null;
        normalizedJson.atsBullhornCandidateId = reference?.atsBullhornCandidateId || null;
        normalizedJson.facilityCityState = reference.facilityCityState;
        normalizedJson.employed = reference.employed;
      });
      candidateReferences.push(normalizedJson);
      reqObj = {
        event:
          onboardingReferences && onboardingReferences.length > 0
            ? "updateReference"
            : "addReference",
        email: userEmail,
        companyId: currentReferenceCopy[0].companyId
          ? currentReferenceCopy[0].companyId
          : +company.id,
        isDeleted: referencesIsDeleted,
        candidateReference: {
          meta: {
            lastVisitedPage: "3",
            completedSections: {
              references: onBoardingCompletedSteps[3]
                ? !!onBoardingCompletedSteps[3]
                : candidateReferences?.length > 0,
            },
          },
          references: candidateReferences,
        },
      };
    }
    return { reqObj, canBeSavedPartially };
  };

  const handleSaveReferences = async (reqObj) => {
    try {
      await addReferenceToDb({ variables: { referencesInput: reqObj } });
      trackSegment(t("segment.profileUpdated"));
    } catch (error) {
      console.error("Error while saving references:", error);
    }
  };

  const resetStates = () => {
    setIsEdit(false);
    setFirstName({
      value: "",
      errors: true,
      errorText: t("onBoarding.errorMessage.required"),
    });
    setLastName({
      value: "",
      errors: true,
      errorText: t("onBoarding.errorMessage.required"),
    });
    setTitle({
      value: "",
      errors: true,
      errorText: t("onBoarding.errorMessage.required"),
    });
    setFacilityNameDrop({
      value: "",
      errors: true,
      errorText: t("onBoarding.errorMessage.required"),
    });
    setEmail({
      value: "",
      errors: false,
      errorText: "",
    });
    setPhone({ value: "", errors: false, errorText: "" });
    setIsCancelBtnDisabled(true);
    setIsSaveBtnDisabled(checkSaveBtnStatus);
    setIsReferenceForm(false);
    setMode(null);
    setEditIndex(null);
  };

  const handleDeleteReferences = async (reference) => {
    try {
      const candidateReference: any = [];
      const normalizedJson: any = {};
      normalizedJson.id = reference.id;
      normalizedJson.startDate = reference.startDate;
      normalizedJson.endDate = reference.endDate;
      normalizedJson.facilityName = reference.facilityName;
      normalizedJson.supervisorFirstName = reference.firstName;
      normalizedJson.supervisorLastName = reference.lastName;
      normalizedJson.supervisorTitle = reference.title;
      normalizedJson.supervisorEmail = reference.email;
      normalizedJson.supervisorPhone = reference.phone;
      normalizedJson.notificationSent = reference.notificationSent;
      normalizedJson.status = reference.status;
      normalizedJson.dateCompleted = reference.dateCompleted;
      normalizedJson.dateCreated = reference.dateCreated;
      normalizedJson.dateLastUpdated = reference.dateLastUpdated;
      normalizedJson.profession = reference.profession;
      normalizedJson.specialty = reference.specialty;
      normalizedJson.token = reference.token;
      normalizedJson.referenceId = reference?.referenceId || null;
      normalizedJson.atsBullhornCandidateId = reference?.atsBullhornCandidateId || null;
      candidateReference.push(normalizedJson);
      const reqObj = {
        email: userEmail,
        companyId: reference.companyId ? reference.companyId : parseInt(company.id, 10),
        isDeleted: referencesIsDeleted,
        candidateReference: {
          meta: {
            lastVisitedPage: "4",
            completedSections: {
              references: references?.length > 0,
            },
          },
          references: candidateReference,
        },
      };
      await deleteReferenceFromDb({ variables: { referencesInput: reqObj } });
    } catch (error) {
      console.error("Error while deleting references:", error);
    }
  };

  const editReference = async () => {
    setOpenModal(false);
    const record: any = {};
    referencesFields.forEach((field) => {
      record[`${field}`] = getFieldData(field);
    });
    record.facilityName = facilityName;
    record.startDate = startDate;
    record.endDate = endDate;
    record.companyId = companyId;
    record.status = ReferencesStates.PENDING;
    record.dateLastUpdated = moment.utc().format();
    const referencesArr = references;
    const reference =
      typeof editIndex === "number" && referencesArr.length >= editIndex
        ? referencesArr[`${editIndex}`]
        : null;
    record.status = reference?.status;
    const renderWorkExperienceHelper = (workExp) => {
      if (workExp) {
        return workExp?.[0]?.endDate ? "No" : "Yes";
      }
      return reference.employed ?? null;
    };

    if (reference) {
      let workExp;
      if (!reference.specialty && !reference.profession) {
        workExp = await getProfSpecByWorkExp(facilityName);
      }
      record.id = reference.id;
      // [TODO - need to handle subsequent edit]
      record.referenceId = reference?.referenceId || null;
      record.atsBullhornCandidateId = reference?.atsBullhornCandidateId || null;
      record.specialty = workExp?.[0]?.specialty ? workExp?.[0]?.specialty : reference?.specialty;
      record.profession = workExp?.[0]?.profession
        ? workExp?.[0]?.profession
        : reference?.profession;
      record.facilityCityState = workExp?.[0]?.city
        ? workExp?.[0]?.city
        : reference?.facilityCityState;
      record.employed = renderWorkExperienceHelper(workExp);
      record.notificationSent = !(
        reference?.email?.toLowerCase() !== record?.email?.toLowerCase() ||
        reference?.phone !== record?.phone
      );
      record.dateCompleted = reference.dateCompleted;
      record.dateCreated = reference.dateCreated;
      record.dateNotified = reference.dateNotified;
      referencesArr[`${editIndex}`] = record;
      setReferences(referencesArr);
      const { reqObj } = normalizeReferenceJson([record]);
      handleSaveReferences(reqObj);
    }
    resetStates();
  };

  const deleteReference = (index, reference) => {
    setOpenModal(false);
    references.splice(index, 1);
    setReferences(references);
    resetStates();
    if (references?.length === 0) {
      setIsReferenceForm(true);
    }
    handleDeleteReferences(reference);
  };

  const saveReferences = () => {
    const record: any = {};
    referencesFields.forEach((field) => {
      record[`${field}`] = getFieldData(field);
    });
    record.facilityName = facilityName;
    record.startDate = startDate;
    record.endDate = endDate;
    record.status = ReferencesStates.PENDING;
    record.id = uuid();
    record.notificationSent = false;
    record.profession = profession;
    record.specialty = specialty;
    record.facilityCityState = facilityLocation;
    record.employed = employed;
    record.referenceId = null;
    record.atsBullhornCandidateId = atsBullhornCandidateId;
    record.dateCompleted = null;
    record.dateCreated = moment.utc().format();
    record.dateLastUpdated = moment.utc().format();
    record.dateNotified = moment.utc().format(); // date to calculate expiry from - only updated when email or phone is updated
    const referencesArr = references;
    referencesArr.push(record);
    setReferences(referencesArr);
    const { reqObj } = normalizeReferenceJson([record]);
    handleSaveReferences(reqObj);
    setOpenModal(false);
    resetStates();
  };

  const addReferences = () => {
    setMode(APIMethods.ADD);
    setIsReferenceForm(true);
    setIsCancelBtnDisabled(false);
    setIsSaveBtnDisabled(checkSaveBtnStatus);
  };

  const cancelUpdate = () => {
    setOpenModal(false);
    resetStates();
  };

  const handlePageRedirection = (redirection) => {
    const pageValidationObj = {
      references,
    };
    const { validators } = validatePage(pageValidationObj);
    if (redirection === "Back") {
      dispatch(updateActiveFlag(2));
      history.push(WORK_EXP_PAGE);
    } else {
      const onBoardingCompletedStepsFinal = onBoardingCompletedSteps;
      if (!validators) {
        onBoardingCompletedStepsFinal[3] = true;
      } else if (onBoardingCompletedStepsFinal[3]) {
        delete onBoardingCompletedStepsFinal["3"];
      }
      updateTheCompleteFlag(onBoardingCompletedStepsFinal);
      // check incomplete step and redirect
      if (redirection === "Next") {
        dispatch(updateTheActiveFlag(4));
        history.push(SKILL_CHECKLIST_OVERVIEW_PAGE);
      } else if (redirection === "Done") {
        if (!validators) {
          history.push(DASHBOARD);
        }
      }
    }
  };

  const noScroll = () => {
    document?.getElementById("backButtonPopUp")?.scrollIntoView({ block: "center" });
  };

  const disableScroll = () => {
    window.addEventListener("scroll", noScroll);
  };

  const enableScroll = () => {
    window.removeEventListener("scroll", noScroll);
  };

  const handleYesClicked = () => {
    handlePageRedirection("Back");
    enableScroll();
  };

  const handleNoClicked = () => {
    setIsBackButtonClicked(false);
    dispatch(updateActiveFlag(2));
    enableScroll();
    history.push(WORK_EXP_PAGE);
  };

  const handleClose = async () => {
    setIsBackButtonClicked(false);
  };

  const handleBtnClick = (redirection) => {
    setOpenModal(false);
    if (redirection === "Back") {
      setIsBackButtonClicked(true);
      disableScroll();
    } else if (redirection === "Next" || redirection === "Done") {
      handlePageRedirection(redirection);
    }
  };

  const renderAddMoreReferenceBtn = () => {
    if (!isReferenceForm) {
      return (
        <Row>
          <Col sm={12} xs={12} md={12} lg={12} className="d-flex pt-4">
            <div className="d-flex justify-content-center onBFullWidthMob">
              <PrimaryButton
                component="div"
                onClick={addReferences}
                size="medium"
                className="onBFullWidthMob"
              >
                <AddIcon fontSize="small" className={classes.iconPaddingBottom} />
                &nbsp;{t("addReferences.addRefBtn")}
              </PrimaryButton>
            </div>
          </Col>
        </Row>
      );
    }
    return null;
  };

  const renderButtons = () => (
    <Row>
      <Col sm={12} xs={12} md={12} lg={12}>
        <div className="onBWorkExpSaveBtn">
          <div>
            {references?.length > 0 ? (
              <div className="d-flex justify-content-center onBoardingReferencesButtonHolder">
                <PrimaryButton
                  component="button"
                  onClick={mode === APIMethods.ADD ? saveReferences : editReference}
                  disabled={isSaveBtnDisabled}
                  size="medium"
                >
                  {mode === APIMethods.ADD
                    ? t("onBoarding.workExp.saveBtnLbl")
                    : t("addReferences.editRefBtn")}
                </PrimaryButton>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <TertiaryButton
                  component="button"
                  size="medium"
                  className={classes.outlineBtnColor}
                  onClick={cancelUpdate}
                  disabled={isCancelBtnDisabled}
                >
                  {t("onBoarding.edu&spec.cancelBtnLabel")}
                </TertiaryButton>
              </div>
            ) : (
              <div className="d-flex justify-content-center">
                <PrimaryButton
                  component="button"
                  onClick={saveReferences}
                  disabled={isSaveBtnDisabled}
                  size="medium"
                >
                  {t("onBoarding.workExp.saveBtnLbl")}
                </PrimaryButton>
              </div>
            )}
          </div>
        </div>
        <div className="onBBorderBtm" />
      </Col>
    </Row>
  );

  const handleEdit = (ref, index) => {
    setMode(APIMethods.EDIT);
    setEditIndex(index);
    setIsEdit(true);
    setIsReferenceForm(true);
    setIsCancelBtnDisabled(false);
    setIsSaveBtnDisabled(true);
    setFirstName({
      value: ref.firstName,
      errors: false,
      errorText: t("onBoarding.errorMessage.required"),
    });
    setLastName({
      value: ref.lastName,
      errors: false,
      errorText: t("onBoarding.errorMessage.required"),
    });
    setTitle({
      value: ref.title,
      errors: false,
      errorText: t("onBoarding.errorMessage.required"),
    });
    setFacilityNameDrop({
      value: ref.facilityNameDropDown,
      errors: false,
      errorText: t("onBoarding.errorMessage.required"),
    });
    setEmail({
      value: ref.email,
      errors: false,
      errorText: "",
    });
    setPhone({
      value: ref.phone,
      errors: false,
      errorText: "",
    });
    setExistingToken(ref.token);
    setFacilityName(ref.facilityName);
    setCompanyId(ref.companyId);
    setStartDate(ref.startDate);
    setEndDate(ref.endDate);
    setProfession(ref.profession);
    setSpecialty(ref.specialty);
  };

  const onlyStepLeftToComplete =
    onBoardingCompletedSteps[0] &&
    onBoardingCompletedSteps[1] &&
    onBoardingCompletedSteps[2] &&
    onBoardingCompletedSteps[4] &&
    !onBoardingCompletedSteps[3];

  const renderHelperText = () => {
    const workExperienceRecords = enableWorkHistory ? workHistory : workExperience;
    if (!isAllWorkExperienceQueryRunning && facilityNameDropDown.errors) {
      if (workExperienceRecords?.length === 0) {
        return t("addReferences.workexpOneYearError");
      }
      return facilityNameDropDown.errorText;
    }
    if (isAllWorkExperienceQueryRunning && facilityNameDropDown.errors) {
      return facilityNameDropDown.errorText;
    }
    return null;
  };

  return (
    <div>
      {isUpdateNowClicked && (
        <div className="returnToSourceWrapper">
          <SnackbarReturnToSource
            topRight
            message={t("QuickApplyCard.QuickApplyBackToJobDetail")}
            source={source}
            image={image}
          />
        </div>
      )}
      {openModal ? (
        <OnBoardingConfirmModal enabled={openModal} source={source} props={undefined} />
      ) : (
        <></>
      )}
      <Container fluid className="myProfileWrap">
        <Row>
          <MyProfileNavWrapper />
          <div className="myProfilePageWrap">
            <Container>
              <Row>
                <Col sm={12} xs={12} md={12} lg={12} className={classes.addRefBanner} />
              </Row>
            </Container>
            <Container className="onBFormBGMobile">
              <Row>
                <Col sm={12} xs={12} md={12} lg={12}>
                  <div className="onBSubHeading">{t("addReferences.addRefMainTitle")}</div>
                  <p className={classes.addRefText}>
                    {t("addReferences.addRefText")}
                    <a
                      className={classes.referTermsAndCondition}
                      href="https://content.ccrncloud.com/content/docs/terms-and-conditions/reference/terms-and-conditions.pdf"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {t("referSurvey.referTermsAndCondition")}
                    </a>
                    &nbsp;{t("addReferences.addRefSubText")}
                  </p>
                  <p className={classes.addRefSubHead}>{t("addReferences.addRefSubHead")}</p>
                </Col>
              </Row>
            </Container>
            <div className="onBMainTxtField">
              {referencesLoading ? (
                <Container className="mt-2">
                  <Row>
                    <Col sm={4} md={14} lg={4} xs={12}>
                      <Skeleton animation="wave" className={classes.referenceSkeleton} />
                      <Skeleton animation="wave" className="" />
                      <Skeleton animation="wave" className="" />
                    </Col>

                    <Col sm={4} md={14} lg={4} xs={12}>
                      <Skeleton animation="wave" className={classes.referenceSkeleton} />
                      <Skeleton animation="wave" className="" />
                      <Skeleton animation="wave" className="" />
                    </Col>

                    <Col sm={4} md={14} lg={4} xs={12}>
                      <Skeleton animation="wave" className={classes.referenceSkeleton} />
                      <Skeleton animation="wave" className="" />
                      <Skeleton animation="wave" className="" />
                    </Col>
                  </Row>
                </Container>
              ) : (
                <Container>
                  <Row>
                    <Col sm={12} xs={12} md={12} lg={12} className="onBFormBGMobile">
                      {renderAddMoreReferenceBtn()}
                      <div
                        className={
                          isReferenceForm ? classes.showReferenceForm : classes.hideReferenceForm
                        }
                      >
                        <div className={classes.referencesFormWrapper}>
                          <div className="onBboxShadow mb-3 mt-3">
                            <Row
                              className={
                                references?.length > 0 ? "referenceFormMargin" : "referenceForm"
                              }
                            >
                              <Col
                                sm={12}
                                xs={12}
                                md={4}
                                lg={4}
                                className={
                                  facilityNameDropDown.errors
                                    ? "onBTextFieldReq onBtextfiled Mui-error"
                                    : "onBtextfiled "
                                }
                              >
                                <div>
                                  <MultipleSelect
                                    fieldValue={facilityNameDropDown}
                                    fieldLabel={t("addReferences.workexpLabel")}
                                    fieldName="facilityName"
                                    fieldOptions={facilityList}
                                    handleMultipleSelect={(e) =>
                                      handleMultipleSelect(e, "facilityName")
                                    }
                                    error={!!facilityNameDropDown.errors}
                                    helperText={renderHelperText()}
                                    isMultiple={false}
                                  />
                                </div>
                              </Col>
                              <Col
                                sm={12}
                                xs={12}
                                md={4}
                                lg={4}
                                className={
                                  firstName.errors
                                    ? "onBTextFieldReq onBtextfiled"
                                    : "onBtextfiled "
                                }
                              >
                                <TextField
                                  label={t("addReferences.firstNameLabel")}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  variant="outlined"
                                  type="TextField"
                                  value={firstName.value}
                                  onChange={(e) => handleChange(e, "firstName")}
                                  error={firstName.errors}
                                  helperText={firstName.errors ? firstName.errorText : ""}
                                />
                              </Col>
                              <Col
                                sm={12}
                                xs={12}
                                md={4}
                                lg={4}
                                className={
                                  lastName.errors ? "onBTextFieldReq onBtextfiled" : "onBtextfiled "
                                }
                              >
                                <TextField
                                  label={t("addReferences.lastNameLabel")}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                  variant="outlined"
                                  type="TextField"
                                  value={lastName.value}
                                  onChange={(e) => handleChange(e, "lastName")}
                                  error={lastName.errors}
                                  helperText={lastName.errors ? lastName.errorText : ""}
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col
                                sm={12}
                                xs={12}
                                md={4}
                                lg={4}
                                className={
                                  title.errors
                                    ? "onBTextFieldReq onBtextfiled Mui-error"
                                    : "onBtextfiled "
                                }
                              >
                                <div className={classes.titleDropdown}>
                                  <MultipleSelect
                                    fieldValue={title || null}
                                    fieldLabel={t("addReferences.titleLabel")}
                                    fieldName="title"
                                    fieldOptions={titleList || null}
                                    handleMultipleSelect={(e) => handleMultipleSelect(e, "title")}
                                    error={!!title.errors}
                                    helperText={title.errors ? title.errorText : ""}
                                    isMultiple={false}
                                  />
                                </div>
                              </Col>
                              <Col sm={12} xs={12} md={8} lg={8}>
                                <div className={classes.emailAndPhoneContainer}>
                                  <div className={classes.emailAndPhoneContainerInputLabel}>
                                    {t("addReferences.emailAndPhoneLabel")}
                                  </div>
                                  <div
                                    className={
                                      email.errors
                                        ? "onBTextFieldReq onBtextfiled"
                                        : "onBtextfiled "
                                    }
                                  >
                                    <TextField
                                      label={t("addReferences.emailLabel")}
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      variant="outlined"
                                      type="TextField"
                                      value={email.value}
                                      onChange={(e) => handleChange(e, "email")}
                                      error={email.errors}
                                      helperText={email.errors ? email.errorText : ""}
                                      className="normalText"
                                    />
                                  </div>
                                  <div className={classes.emailAndPhoneContainerLabel}>
                                    {t("addReferences.andOrLabel")}
                                  </div>
                                  <div
                                    className={
                                      phone.errors
                                        ? "onBTextFieldReq onBtextfiled"
                                        : "onBtextfiled "
                                    }
                                  >
                                    <TextField
                                      label={t("addReferences.phoneLabel")}
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      variant="outlined"
                                      type="TextField"
                                      value={phone.value}
                                      onChange={(e) => handleChange(e, "phone")}
                                      error={phone.errors}
                                      helperText={phone.errors ? phone.errorText : ""}
                                    />
                                  </div>
                                </div>
                                {email.value || phone.value ? (
                                  ""
                                ) : (
                                  <p className={classes.errorMessageText}>{requiredErrMsg}</p>
                                )}
                              </Col>
                            </Row>
                            {renderButtons()}
                          </div>
                        </div>
                      </div>
                      {references?.length > 0 ? (
                        <OnBoardingReferencesTable
                          mode={mode}
                          references={references}
                          handelEdit={handleEdit}
                          handelDelete={deleteReference}
                          isEdit={isEdit}
                        />
                      ) : null}
                      <Row>
                        <Col sm={12} xs={12} md={12} lg={12}>
                          <div className="onBNextBtn d-flex">
                            {!isBackButtonClicked && (
                              <>
                                <TertiaryButton
                                  component="button"
                                  size="medium"
                                  className={classes.outlineBtnColor}
                                  onClick={() => handleBtnClick("Back")}
                                >
                                  {t("onBoarding.workExp.backBtnLbl")}
                                </TertiaryButton>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <SecondaryButton
                                  component="button"
                                  size="medium"
                                  className={classes.nextBtnMarginleft}
                                  onClick={() =>
                                    handleBtnClick(onlyStepLeftToComplete ? "Done" : "Next")
                                  }
                                >
                                  {onlyStepLeftToComplete
                                    ? t("onBoarding.skillChecklist.completeAppBtnLbl")
                                    : t("onBoarding.basicInfo.btnLabel")}
                                </SecondaryButton>
                              </>
                            )}
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Container>
              )}
            </div>
          </div>
        </Row>
      </Container>
      {isBackButtonClicked && (
        <OnBoardingBackBtnConfirmBox
          handleClose={handleClose}
          handleYesClicked={handleYesClicked}
          handleNoClicked={handleNoClicked}
        />
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  updateTheCompleteFlag: (completedSteps) => dispatch(updateCompleteFlag(completedSteps)),
  updateTheActiveFlag: (activeSteps) => dispatch(updateActiveFlag(activeSteps)),
});

export default connect(null, mapDispatchToProps)(OnBoardingReferences);
