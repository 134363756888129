import React from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import IFrameLoader from "src/components/common/IFrameLoader";
import config from "src/config";
import MissingApplicantId from "src/components/common/MainContent/Home/MissingApplicantIdError";
import Analytics from "src/services/Analytics";
import { fetchAccessToken } from "src/redux/candidate/action";
import analyticsSegmentIdentify from "src/services/SegmentIdentify";
import { initApp } from "src/redux/auth/action";
import API from "src/services/Api";

class MyTasksV2 extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      accessToken: null,
    };
  }

  componentDidMount() {
    if (!this.props.isCandidateListEmpty && this.props.applicantId) {
      this.fetchTasks();
    } else {
      this.props.initApp(this.props.company.id);
    }

    if (this.props.userId && this.props.email) {
      analyticsSegmentIdentify(this.props);
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.applicantId !== prevProps.applicantId &&
      !this.props.isCandidateListEmpty &&
      this.props.applicantId
    ) {
      this.fetchTasks();
    }
    if (
      this.props.email !== prevProps.email ||
      this.props.recruiterDetails !== prevProps.recruiterDetails
    ) {
      analyticsSegmentIdentify(this.props);
    }
  }

  fetchTasks() {
    API.get(`${config.API_BASE_HOST}/ui/v1/users/es/token/${this.props.applicantId}`)
      .then((response) => {
        this.setState({ accessToken: response.data.response.accessToken });
      })
      .catch(this.setState({ accessToken: null }));
    const segmentEventName = this.props.t("segment.taskViewed");
    Analytics.segmentTrackEvent(segmentEventName, null);
  }

  iFrame() {
    const { applicantId } = this.props;
    const { accessToken } = this.state;
    let src = "";
    if (applicantId && accessToken) {
      src = `${config.EMPLOYEE_STREAM_TASKS}?access_token=${accessToken}&applicant_id=${applicantId}`;
    }
    return (
      <>
        <div>
          {applicantId && accessToken ? (
            <IFrameLoader src={src} />
          ) : applicantId === null ? (
            <MissingApplicantId />
          ) : (
            <IFrameLoader />
          )}
        </div>
      </>
    );
  }

  render() {
    return this.iFrame();
  }
}
const mapStateToProps = (state) => ({
  applicantId: state.candidate.applicantId,
  isCandidateListEmpty: state.auth.isCandidateListEmpty,
  company: state.company.company,
  email: state.auth.email,
  userId: state.auth.userId,
  firstName: state.auth.firstName,
  lastName: state.auth.lastName,
  phone: state.auth.phone,
  recruiterDetails: state.consultant.recruiterDetails,
  profession: state.auth.candidateProfession,
  specialties: state.auth.candidateSpecialties,
  onboardingBasicInfo: state.onBoarding.basicInfo,
});
const mapDispatchToProps = (dispatch) => ({
  fetchAccessToken: (payload) => dispatch(fetchAccessToken(payload)),
  initApp: (companyId) => dispatch(initApp(companyId)),
});
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(MyTasksV2));
