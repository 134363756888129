import { push } from "connected-react-router";
import { getAccessToken } from "axios-jwt";
import { BASIC_INFO } from "src/constants/onboardingEventName";
import config from "src/config";
import API from "../../services/Api";
import { onBoardingNavigationClicked } from "../navigation/action";
import {
  EDU_SPEC_PAGE,
  BASIC_INFO_PAGE,
  WORK_EXP_PAGE,
  REFERENCES_PAGE,
} from "../../router/routes";
import { setApplicantId, fetchAccessToken } from "../candidate/action";
import {
  GET_PII_TOKEN_INFORMATION,
  SET_PII_TOKEN_INFORMATION,
  SET_PII_TOKEN_VALUE,
  UPDATE_PII_TOKEN_VALUE,
  getCandidateCredentials as getCandidateCredentialsQuery,
} from "../../services/GraphQL/graph.query";
import { apolloClient, graphqlRequest } from "../../services/GraphQL/GraphQL";

const enableWorkHistory = config.ENABLE_WORK_HISTORY === "true";
const enablePIIData = config.ENABLE_PII_DATA === "true";

export const FETCH_SELECTED_DESTINATIONS = "FETCH_SELECTED_DESTINATIONS";
export const fetchSelectedDestinations = (payload) => ({
  type: FETCH_SELECTED_DESTINATIONS,
  payload,
});

export const SAVE_BASIC_INFO_REQUEST = "SAVE_BASIC_INFO_REQUEST";
export const saveBasicInfoRequest = (payload) => ({ type: SAVE_BASIC_INFO_REQUEST, payload });

export const SAVE_BASIC_INFO_SUCCESS = "SAVE_BASIC_INFO_SUCCESS";
export const saveBasicInfoSuccess = (payload) => ({ type: SAVE_BASIC_INFO_SUCCESS, payload });

export const SAVE_BASIC_INFO_FAILURE = "SAVE_BASIC_INFO_FAILURE";
export const saveBasicInfoFailure = (payload) => ({ type: SAVE_BASIC_INFO_FAILURE, payload });

export const getApiEndPoint = (pageRedirectedFrom) => {
  switch (pageRedirectedFrom) {
    case 0:
      return "/onboardings/basicInfo";
    case 1:
      return "/onboardings/specialtiesAndEducation";
    case 2:
      return "/onboardings/workExperience";
    default:
      return "/onboardings/basicInfo";
  }
};

export const FETCH_STATES_REQUEST = "FETCH_STATES_REQUEST";
export const fetchAllStatesRequest = (payload) => ({ type: FETCH_STATES_REQUEST, payload });

export const FETCH_STATES_SUCCESS = "FETCH_STATES_SUCCESS";
export const fetchAllStatesSuccess = (payload) => ({ type: FETCH_STATES_SUCCESS, payload });

export const FETCH_STATES_FAILURE = "FETCH_STATES_FAILURE";
export const fetchAllStatesFailure = (payload) => ({ type: FETCH_STATES_FAILURE, payload });

export const fetchAllStates = () => (dispatch) => {
  dispatch(fetchAllStatesRequest());
  API.get("locations/states")
    .then((response) => {
      let items = [];
      if (response.data.length > 0) {
        items = response.data;
      }
      dispatch(fetchAllStatesSuccess(items));
    })
    .catch((error) => {
      dispatch(fetchAllStatesFailure(error.message));
    });
};

export const FETCH_ONBOARDING_INFO_REQUEST = "FETCH_ONBOARDING_INFO_REQUEST";
export const fetchOnboardingInfoRequest = (payload) => ({
  type: FETCH_ONBOARDING_INFO_REQUEST,
  payload,
});

export const FETCH_ONBOARDING_INFO_SUCCESS = "FETCH_ONBOARDING_INFO_SUCCESS";
export const fetchOnboardingInfoSuccess = (payload) => ({
  type: FETCH_ONBOARDING_INFO_SUCCESS,
  payload,
});

export const FETCH_ONBOARDING_INFO_FAILURE = "FETCH_ONBOARDING_INFO_FAILURE";
export const fetchOnboardingInfoFailure = (payload) => ({
  type: FETCH_ONBOARDING_INFO_FAILURE,
  payload,
});

export const fetchOnboardingInformation =
  (email, companyId, triggeringPoint, updateAbleLoginParams) => (dispatch) => {
    dispatch(fetchOnboardingInfoRequest());
    API.get("/onboardings/search", {
      params: {
        email,
        companyId,
      },
    })
      .then((response) => {
        if (response.data.status === "FOUND") {
          if (updateAbleLoginParams) {
            if (response.data.content.applicantId) {
              if (updateAbleLoginParams.applicantId) {
                dispatch(setApplicantId(response.data.content.applicantId));
              }
              if (updateAbleLoginParams.accessToken) {
                dispatch(fetchAccessToken(response.data.content.applicantId));
              }
            }
          }
          const onBoardingDataObject = {
            response: response.data.content,
            triggeringPoint,
          };
          dispatch(fetchOnboardingInfoSuccess(onBoardingDataObject));
        } else dispatch(fetchOnboardingInfoFailure(response));
      })
      .catch((error) => {
        dispatch(fetchOnboardingInfoFailure(error));
      });
  };

export const saveBasicInformation = (
  reqObj,
  pageRedirectedFrom,
  pageToRedirect,
  triggeringPoint,
  updateAbleLoginParams
) => {
  const endPoint = getApiEndPoint(pageRedirectedFrom);
  return (dispatch) => {
    dispatch(saveBasicInfoRequest());
    API.post(endPoint, JSON.stringify(reqObj), {
      headers: {
        "content-type": "application/json",
      },
    })
      .then((response) => {
        if (response.status === 200) {
          const onBoardingDataObject = {
            response,
            triggeringPoint,
          };
          dispatch(saveBasicInfoSuccess(onBoardingDataObject));
          if (response.data.status !== "BAD_REQUEST") {
            dispatch(
              fetchOnboardingInformation(
                reqObj.email,
                reqObj.companyId,
                "onboarding",
                updateAbleLoginParams
              )
            );
          }

          if (pageToRedirect === 0 && response.data.status !== "BAD_REQUEST") {
            dispatch(onBoardingNavigationClicked(0));
            dispatch(push(BASIC_INFO_PAGE));
          }
          if (pageToRedirect === 1 && response.data.status !== "BAD_REQUEST") {
            dispatch(onBoardingNavigationClicked(1));
            dispatch(push(EDU_SPEC_PAGE));
          }
          if (pageToRedirect === 2 && response.data.status !== "BAD_REQUEST") {
            dispatch(onBoardingNavigationClicked(2));
            dispatch(push(WORK_EXP_PAGE));
          }
          if (pageToRedirect === 3 && response.data.status !== "BAD_REQUEST") {
            dispatch(onBoardingNavigationClicked(3));
            dispatch(push(REFERENCES_PAGE));
          }
          if (pageToRedirect === 4 && response.data.status !== "BAD_REQUEST") {
            dispatch(onBoardingNavigationClicked(4));
            dispatch(push(SKILL_CHECKLIST_OVERVIEW_PAGE));
          }
        } else dispatch(saveBasicInfoFailure(response));
      })
      .catch((error) => {
        dispatch(saveBasicInfoFailure(error));
      });
  };
};

export const UPDATE_COMPLETE_FLAG = "UPDATE_COMPLETE_FLAG";
export const updateCompleteFlag = (payload) => ({ type: UPDATE_COMPLETE_FLAG, payload });

export const UPDATE_ACTIVE_FLAG = "UPDATE_ACTIVE_FLAG";
export const updateActiveFlag = (payload) => ({ type: UPDATE_ACTIVE_FLAG, payload });

export const SEND_EMAIL_TO_SUPPORT_TEAM_REQUEST = "SEND_EMAIL_TO_SUPPORT_TEAM_REQUEST";
export const sendEmailToSupportTeamRequest = (payload) => ({
  type: SEND_EMAIL_TO_SUPPORT_TEAM_REQUEST,
  payload,
});

export const SEND_EMAIL_TO_SUPPORT_TEAM_SUCCESS = "SEND_EMAIL_TO_SUPPORT_TEAM_SUCCESS";
export const sendEmailToSupportTeamSuccess = (payload) => ({
  type: SEND_EMAIL_TO_SUPPORT_TEAM_SUCCESS,
  payload,
});

export const SEND_EMAIL_TO_SUPPORT_TEAM_FAILURE = "SEND_EMAIL_TO_SUPPORT_TEAM_FAILURE";
export const sendEmailToSupportTeamFailure = (payload) => ({
  type: SEND_EMAIL_TO_SUPPORT_TEAM_FAILURE,
  payload,
});

export const UPDATE_ONBOARDING_COMPLETED_SECTIONS = "UPDATE_ONBOARDING_COMPLETED_SECTIONS";
export const updateOnboardingCompletedSections = (payload) => ({
  type: UPDATE_ONBOARDING_COMPLETED_SECTIONS,
  payload,
});

export const UPDATE_WORK_EXPERIENCE = "UPDATE_WORK_EXPERIENCE";
export const updateWorkExperience = (payload) => ({
  type: UPDATE_WORK_EXPERIENCE,
  payload,
});

export const sendEmailToSupportTeam = (emailParams) => (dispatch) => {
  dispatch(sendEmailToSupportTeamRequest());
  API.post("/onboardings/email", JSON.stringify(emailParams), {
    headers: {
      "content-type": "application/json",
    },
  })
    .then((response) => {
      if (response.data.statusCode === 200) {
        dispatch(sendEmailToSupportTeamSuccess(response));
      } else dispatch(sendEmailToSupportTeamFailure(response));
    })
    .catch((error) => {
      dispatch(sendEmailToSupportTeamFailure(error));
    });
};

export const CLEAR_BASIC_INFO_RESPONSE = "CLEAR_BASIC_INFO_RESPONSE";
export const clearBasicInfoResponse = () => ({ type: CLEAR_BASIC_INFO_RESPONSE });

export const FETCH_SKILL_CHECKLIST_REQUEST = "FETCH_SKILL_CHECKLIST_REQUEST";
export const fetchSkillChecklistsRequest = (payload) => ({
  type: FETCH_SKILL_CHECKLIST_REQUEST,
  payload,
});

export const FETCH_SKILL_CHECKLIST_SUCCESS = "FETCH_SKILL_CHECKLIST_SUCCESS";
export const fetchSkillChecklistsSuccess = (payload) => ({
  type: FETCH_SKILL_CHECKLIST_SUCCESS,
  payload,
});

export const FETCH_SKILL_CHECKLIST_FAILURE = "FETCH_SKILL_CHECKLIST_FAILURE";
export const fetchSkillChecklistsFailure = (payload) => ({
  type: FETCH_SKILL_CHECKLIST_FAILURE,
  payload,
});

export const fetchSkillChecklists = (reqObj) =>
  function fn(dispatch) {
    return new Promise((resolve, reject) => {
      dispatch(fetchSkillChecklistsRequest());
      API.get("/onboardings/skillsChecklist/search", {
        params: {
          email: reqObj.email,
          companyId: reqObj.companyId,
          professionLongCode: reqObj.professionLongCode,
          specialtyLongCode: reqObj.specialtyLongCode,
        },
      })
        .then((response) => {
          if (
            response.data.status === "OK" ||
            response.data.status === "FOUND" ||
            response.data.status === "NOT_FOUND"
          ) {
            dispatch(fetchSkillChecklistsSuccess(response.data.content));
            resolve(response.data.content);
          } else {
            dispatch(fetchSkillChecklistsFailure(response));
            reject(response);
          }
        })
        .catch((error) => {
          dispatch(fetchSkillChecklistsFailure(error));
          reject(error);
        });
    });
  };

export const FETCH_SKILL_CHECKLIST_BY_ID_REQUEST = "FETCH_SKILL_CHECKLIST_BY_ID_REQUEST";
export const fetchSkillChecklistByIdRequest = (payload) => ({
  type: FETCH_SKILL_CHECKLIST_BY_ID_REQUEST,
  payload,
});

export const FETCH_SKILL_CHECKLIST_BY_ID_SUCCESS = "FETCH_SKILL_CHECKLIST_BY_ID_SUCCESS";
export const fetchSkillChecklistByIdSuccess = (payload) => ({
  type: FETCH_SKILL_CHECKLIST_BY_ID_SUCCESS,
  payload,
});

export const FETCH_SKILL_CHECKLIST_BY_ID_FAILURE = "FETCH_SKILL_CHECKLIST_BY_ID_FAILURE";
export const fetchSkillChecklistByIdFailure = (payload) => ({
  type: FETCH_SKILL_CHECKLIST_BY_ID_FAILURE,
  payload,
});

export const fetchSkillChecklistByChecklistId = (id) =>
  function fn(dispatch) {
    return new Promise((resolve, reject) => {
      dispatch(fetchSkillChecklistByIdRequest());
      API.get("/skillschecklists/search", {
        params: {
          skillChecklistId: id,
        },
      })
        .then((response) => {
          if (response.data.status === "FOUND") {
            dispatch(fetchSkillChecklistByIdSuccess(response.data.content));
            resolve(response.data.content);
          } else {
            dispatch(fetchSkillChecklistByIdFailure(response));
            reject(response);
          }
        })
        .catch((error) => {
          dispatch(fetchSkillChecklistByIdFailure(error));
          reject(error);
        });
    });
  };

export const UPDATE_OPTIONAL_SKILLS = "UPDATE_OPTIONAL_SKILLS";
export const updateOptionalSkills = (payload) => ({ type: UPDATE_OPTIONAL_SKILLS, payload });

export const UPDATE_SKILL_CHECKLIST_STATUS = "UPDATE_SKILL_CHECKLIST_STATUS";
export const updateSkillChecklistStatus = (payload) => ({
  type: UPDATE_SKILL_CHECKLIST_STATUS,
  payload,
});

export const SAVE_OPTIONAL_CHECKLIST_REQUEST = "SAVE_OPTIONAL_CHECKLIST_REQUEST";
export const saveOptionalSkillChecklistRequest = (payload) => ({
  type: SAVE_OPTIONAL_CHECKLIST_REQUEST,
  payload,
});

export const SAVE_OPTIONAL_CHECKLIST_SUCCESS = "SAVE_OPTIONAL_CHECKLIST_SUCCESS";
export const saveOptionalSkillChecklistSuccess = (payload) => ({
  type: SAVE_OPTIONAL_CHECKLIST_SUCCESS,
  payload,
});

export const SAVE_OPTIONAL_CHECKLIST_FAILURE = "SAVE_OPTIONAL_CHECKLIST_FAILURE";
export const saveOptionalSkillChecklistFailure = (payload) => ({
  type: SAVE_OPTIONAL_CHECKLIST_FAILURE,
  payload,
});

export const saveOptionalSkillChecklist = (reqObj, previousOnboardingBasicInfo) =>
  function fn(dispatch) {
    return new Promise((resolve, reject) => {
      dispatch(saveOptionalSkillChecklistRequest());
      API.patch("/profile", JSON.stringify(reqObj), {
        headers: {
          "content-type": "application/json",
        },
      })
        .then((response) => {
          resolve(response);
          if (response.data.status === "OK") {
            const newData = response.data.content;

            if (previousOnboardingBasicInfo) {
              newData.onboarding.basicInfo = {
                ...newData.onboarding.basicInfo,
                socialSecurityNumber: previousOnboardingBasicInfo.socialSecurityNumber,
                dateOfBirth: previousOnboardingBasicInfo.dateOfBirth,
                isSSNAvailable: previousOnboardingBasicInfo.isSSNAvailable,
                gender: previousOnboardingBasicInfo.gender,
                ethnicity: previousOnboardingBasicInfo.ethnicity,
              };
            }

            dispatch(saveOptionalSkillChecklistSuccess(newData));
          } else dispatch(saveOptionalSkillChecklistFailure(response));
        })
        .catch((error) => {
          reject(error);
          dispatch(saveOptionalSkillChecklistFailure(error));
        });
    });
  };

export const DELETE_SKILL_CHECKLIST_BY_ID_REQUEST = "DELETE_SKILL_CHECKLIST_BY_ID_REQUEST";
export const deleteSkillChecklistByIdRequest = (payload) => ({
  type: DELETE_SKILL_CHECKLIST_BY_ID_REQUEST,
  payload,
});

export const DELETE_SKILL_CHECKLIST_BY_ID_SUCCESS = "DELETE_SKILL_CHECKLIST_BY_ID_SUCCESS";
export const deleteSkillChecklistByIdSuccess = (payload) => ({
  type: DELETE_SKILL_CHECKLIST_BY_ID_SUCCESS,
  payload,
});

export const DELETE_SKILL_CHECKLIST_BY_ID_FAILURE = "DELETE_SKILL_CHECKLIST_BY_ID_FAILURE";
export const deleteSkillChecklistByIdFailure = (payload) => ({
  type: DELETE_SKILL_CHECKLIST_BY_ID_FAILURE,
  payload,
});

export const deleteSkillChecklistById = (reqObj, previousOnboardingBasicInfo) =>
  function fn(dispatch) {
    return new Promise((resolve, reject) => {
      dispatch(deleteSkillChecklistByIdRequest());
      API.delete("/profile/skillsChecklist/delete", {
        params: {
          email: reqObj.email,
          companyId: reqObj.companyId,
          skillCheckListId: reqObj.skillCheckListId,
        },
      })
        .then((response) => {
          resolve(response);
          if (response.data.status === "OK") {
            const newData = response.data.content;

            if (previousOnboardingBasicInfo) {
              newData.onboarding.basicInfo = {
                ...newData.onboarding.basicInfo,
                socialSecurityNumber: previousOnboardingBasicInfo.socialSecurityNumber,
                dateOfBirth: previousOnboardingBasicInfo.dateOfBirth,
                isSSNAvailable: previousOnboardingBasicInfo.isSSNAvailable,
                gender: previousOnboardingBasicInfo.gender,
                ethnicity: previousOnboardingBasicInfo.ethnicity,
              };
            }

            dispatch(deleteSkillChecklistByIdSuccess(newData));
          } else dispatch(deleteSkillChecklistByIdFailure(response));
        })
        .catch((error) => {
          reject(error);
          dispatch(deleteSkillChecklistByIdFailure(error));
        });
    });
  };

export const SAVE_SKILLS_CHECKLIST_SELECTED = "SAVE_SKILLS_CHECKLIST_SELECTED";
export const saveSkillChecklistSelected = (payload) => ({
  type: SAVE_SKILLS_CHECKLIST_SELECTED,
  payload,
});

export const SAVE_SKILLS_CHECKLIST_GROUP_SKILLS = "SAVE_SKILLS_CHECKLIST_GROUP_SKILLS";
export const saveSkillChecklistGroupSkills = (payload) => ({
  type: SAVE_SKILLS_CHECKLIST_GROUP_SKILLS,
  payload,
});

export const SAVE_SKILLS_CHECKLIST_GROUP_COMPLETE_STATUS =
  "SAVE_SKILLS_CHECKLIST_GROUP_COMPLETE_STATUS";
export const saveSkillChecklistGroupCompleteStatus = (payload) => ({
  type: SAVE_SKILLS_CHECKLIST_GROUP_COMPLETE_STATUS,
  payload,
});

export const UPDATED_SKILLS_CHECKLIST = "UPDATED_SKILLS_CHECKLIST";
export const updatedSkillsChecklist = (payload) => ({ type: UPDATED_SKILLS_CHECKLIST, payload });

export const UPDATE_SELECTED_SKILL_COMPLETE_FLAG = "UPDATE_SELECTED_SKILL_COMPLETE_FLAG";
export const updateSelectedSkillCompleteFlag = (payload) => ({
  type: UPDATE_SELECTED_SKILL_COMPLETE_FLAG,
  payload,
});

export const saveOnboardingComplete =
  (reqObj, triggeringPoint, skillChecklistCompleteStatus) => (dispatch) => {
    dispatch(fetchOnboardingInfoRequest());
    API.post("/onboardings/complete", JSON.stringify(reqObj), {
      headers: {
        "content-type": "application/json",
      },
    })
      .then((response) => {
        if (response.data.status === "OK") {
          const onBoardingDataObject = {
            response: response.data.content,
            triggeringPoint,
          };
          dispatch(fetchOnboardingInfoSuccess(onBoardingDataObject));
          dispatch(updateSkillChecklistStatus(skillChecklistCompleteStatus));
        } else dispatch(fetchOnboardingInfoFailure(response));
      })
      .catch((error) => {
        dispatch(fetchOnboardingInfoFailure(error));
      });
  };

export const FETCH_ALL_SKILL_CHECKLIST_REQUEST = "FETCH_ALL_SKILL_CHECKLIST_REQUEST";
export const fetchAllSkillChecklistsRequest = (payload) => ({
  type: FETCH_ALL_SKILL_CHECKLIST_REQUEST,
  payload,
});

export const FETCH_ALL_SKILL_CHECKLIST_SUCCESS = "FETCH_ALL_SKILL_CHECKLIST_SUCCESS";
export const fetchAllSkillChecklistsSuccess = (payload) => ({
  type: FETCH_ALL_SKILL_CHECKLIST_SUCCESS,
  payload,
});

export const FETCH_ALL_SKILL_CHECKLIST_FAILURE = "FETCH_ALL_SKILL_CHECKLIST_FAILURE";
export const fetchAllSkillChecklistsFailure = (payload) => ({
  type: FETCH_ALL_SKILL_CHECKLIST_FAILURE,
  payload,
});

export const fetchAllSkillChecklists = (applicantId) =>
  function fn(dispatch) {
    return new Promise((resolve, reject) => {
      dispatch(fetchAllSkillChecklistsRequest());
      API.get("/onboardings/allskillschecklists/search", {
        params: {
          applicantId,
        },
      })
        .then((response) => {
          dispatch(fetchAllSkillChecklistsSuccess(response.data));
          resolve(response.data);
        })
        .catch((error) => {
          dispatch(fetchAllSkillChecklistsFailure(error));
          reject(error);
        });
    });
  };

export const NAVIGATE_TO_SKILL_CHECKLIST_DETAILS_FLAG = "NAVIGATE_TO_SKILL_CHECKLIST_DETAILS_FLAG";
export const isNavigateToSkillChecklistDetails = (payload) => {
  sessionStorage.setItem("isNavigateToSkillChecklistDetails", payload);
  return { type: NAVIGATE_TO_SKILL_CHECKLIST_DETAILS_FLAG, payload };
};

export const SAVE_CHECKLIST_IN_ABLE_REQUEST = "SAVE_CHECKLIST_IN_ABLE_REQUEST";
export const saveSkillChecklistInAbleRequest = (payload) => ({
  type: SAVE_CHECKLIST_IN_ABLE_REQUEST,
  payload,
});

export const SAVE_CHECKLIST_IN_ABLE_SUCCESS = "SAVE_CHECKLIST_IN_ABLE_SUCCESS";
export const saveSkillChecklistInAbleSuccess = (payload) => ({
  type: SAVE_CHECKLIST_IN_ABLE_SUCCESS,
  payload,
});

export const SAVE_CHECKLIST_IN_ABLE_FAILURE = "SAVE_CHECKLIST_IN_ABLE_FAILURE";
export const saveSkillChecklistInAbleFailure = (payload) => ({
  type: SAVE_CHECKLIST_IN_ABLE_FAILURE,
  payload,
});

export const saveSkillChecklistInAble = (reqObj) =>
  function fn(dispatch) {
    return new Promise((resolve, reject) => {
      dispatch(saveSkillChecklistInAbleRequest());
      API.post("/onboardings/syncskillchecklist", JSON.stringify(reqObj), {
        headers: {
          "content-type": "application/json",
        },
      })
        .then((response) => {
          resolve(response);
          if (response.data.status === 200) {
            dispatch(saveSkillChecklistInAbleSuccess(response.data.response));
          } else dispatch(saveSkillChecklistInAbleFailure(response));
        })
        .catch((error) => {
          dispatch(saveSkillChecklistInAbleFailure(error));
          reject(error);
        });
    });
  };

export const SAVE_PROFILE_REQUEST = "SAVE_PROFILE_REQUEST";
export const saveProfileRequest = (payload) => ({ type: SAVE_PROFILE_REQUEST, payload });

export const SAVE_PROFILE_SUCCESS = "SAVE_PROFILE_SUCCESS";
export const saveProfileSuccess = (payload) => ({ type: SAVE_PROFILE_SUCCESS, payload });

export const SAVE_PROFILE_FAILURE = "SAVE_PROFILE_FAILURE";
export const saveProfileFailure = (payload) => ({ type: SAVE_PROFILE_FAILURE, payload });

const savePIIData = (reqObj) => {
  const getToken = new Promise((resolve, reject) => {
    // PII Data Saving Enabled Check
    if (enablePIIData && reqObj.event === BASIC_INFO) {
      const piiValues = {
        ss_num: reqObj.profile.basicInfo.socialSecurityNumber || "",
        dob: reqObj.profile.basicInfo.dateOfBirth || "",
        gender: reqObj.profile.basicInfo.gender || "",
        ethnicity: reqObj.profile.basicInfo.ethnicity || "",
      };

      // fetching the fields which are filled

      const fields = `${piiValues?.ss_num !== "" ? "ss_num" : ""} ${
        piiValues?.dob !== "" ? "dob" : ""
      } ${piiValues?.gender !== "" ? "gender" : ""} ${
        piiValues?.ethnicity !== "" ? "ethnicity" : ""
      }`;

      if (fields.trim() !== "") {
        // retrieving the PII related token Information
        graphqlRequest(GET_PII_TOKEN_INFORMATION(fields), { email: reqObj.email }, getAccessToken())
          .then((res) => {
            const fieldsWithoutTokens = fields
              .split(" ")
              .filter((item) => res.getPIITokens[`${item}`] === null)
              .filter((field) => field !== "");
            const fieldsWithTokens = fields
              .split(" ")
              .filter((item) => res.getPIITokens[`${item}`] !== null)
              .filter((field) => field !== "");

            // creating new tokens for fields without the tokens generated already
            const setTokenPromise = new Promise((resolveToken, rejectToken) => {
              if (fieldsWithoutTokens.length > 0) {
                graphqlRequest(
                  SET_PII_TOKEN_INFORMATION(fieldsWithoutTokens.join(" ")),
                  { email: reqObj.email },
                  getAccessToken()
                ).then((response) => {
                  // logic for setting the new values in the PII Database through API Gateway

                  const piiTokenVariables = {};
                  let argumentString = "";
                  fieldsWithoutTokens.forEach((field) => {
                    piiTokenVariables[`${field}Token`] = response.generatePIITokens[`${field}`];
                    piiTokenVariables[`${field}Value`] = piiValues[`${field}`];
                    if (argumentString.length > 0) argumentString += ", ";
                    argumentString += `$${field}Token: String!, $${field}Value: String!`;
                  });

                  graphqlRequest(
                    SET_PII_TOKEN_VALUE(piiTokenVariables, argumentString),
                    piiTokenVariables,
                    getAccessToken()
                  )
                    .then((resp) => {
                      const piiSavedDataInfo = JSON.parse(JSON.stringify(resp));
                      resolveToken({ ...piiSavedDataInfo, piiSaveSuccess: true});
                    })
                    .catch((err) => {
                      const piiSaveSuccess = false;
                      rejectToken(err, piiSaveSuccess);
                    });
                });
              }
            });
            // updating the data in the PII Database with existing tokens

            const updateTokenPromise = new Promise((resolveToken, rejectToken) => {
              if (fieldsWithTokens.length > 0) {
                const piiTokenVariables = {};
                let argumentString = "";
                fieldsWithTokens.forEach((field) => {
                  piiTokenVariables[`${field}Token`] = res.getPIITokens[`${field}`];
                  piiTokenVariables[`${field}Value`] = piiValues[`${field}`];
                  if (argumentString.length > 0) argumentString += ", ";
                  argumentString += `$${field}Token: String!, $${field}Value: String!`;
                });

                graphqlRequest(
                  UPDATE_PII_TOKEN_VALUE(piiTokenVariables, argumentString),
                  piiTokenVariables,
                  getAccessToken()
                )
                  .then((resp) => {
                    const piiUpdatedDataInfo = JSON.parse(JSON.stringify(resp));
                    resolveToken({ ...piiUpdatedDataInfo, piiSaveSuccess: true });
                  })
                  .catch((err) => {
                    const piiSaveSuccess = false;
                    rejectToken(err, piiSaveSuccess);
                  });
              }
            });

            const promiseArray = [];
            if (fieldsWithoutTokens.length > 0) promiseArray.push(setTokenPromise);
            if (fieldsWithTokens.length > 0) promiseArray.push(updateTokenPromise);
            Promise.all(promiseArray)
              .then((responseToken) => {
                const mergedPiiData = Object.assign({}, ...responseToken);
                resolve(mergedPiiData);
              })
              .catch((err) => {
                const piiSaveSuccess = false;
                reject(err, piiSaveSuccess);
              });
          })
          .catch((err) => {
            const piiSaveSuccess = false;
            reject(err, piiSaveSuccess);
          });
      }else{
        resolve({})
      }
    } else {
      resolve({});
    }
  });
  return getToken;
};

export const saveUserProfile = (reqObj, referenceObj, workExperienceObj, basicInfoObj) =>
  function fn(dispatch) {
    // Creating a deep copy for reqObj

    const reqObject = JSON.parse(JSON.stringify(reqObj));

    return new Promise((resolve, reject) => {
      dispatch(saveProfileRequest());

      savePIIData(reqObject)
        .then((piiData) => {
          // deleting the reqObject key value pairs for PII Related Data
          // for not getting stored in profile table
          if (enablePIIData && piiData.piiSaveSuccess) {
            delete reqObject.profile.basicInfo.dateOfBirth;
            delete reqObject.profile.basicInfo.socialSecurityNumber;
            delete reqObject.profile.basicInfo.gender;
            delete reqObject.profile.basicInfo.ethnicity;
          }
          API.patch("/profile", JSON.stringify(reqObject), {
            headers: {
              "content-type": "application/json",
            },
          })
            .then((response) => {
              if (response.status === 200) {
                if (response.data && response.data.content && referenceObj) {
                  response.data.content.onboarding.references = referenceObj;
                  if (enableWorkHistory && workExperienceObj) {
                    response.data.content.onboarding.workExperience = workExperienceObj;
                  } if (
                    // Storing PII data in the state
                    enablePIIData &&reqObj.event !== BASIC_INFO &&
                    response?.data?.content && basicInfoObj
                  ) {
                    response.data.content.onboarding.basicInfo.dateOfBirth =
                      basicInfoObj?.dateOfBirth || null;
                    response.data.content.onboarding.basicInfo.socialSecurityNumber =
                      basicInfoObj?.socialSecurityNumber || null;
                    response.data.content.onboarding.basicInfo.gender =
                      basicInfoObj?.gender || null;
                    response.data.content.onboarding.basicInfo.ethnicity =
                      basicInfoObj?.ethnicity || null;
                  }
                }
                // Assigning the saved PII Data to the response object
                if (
                  response.data &&
                  response.data.content &&
                  enablePIIData &&
                  piiData.piiSaveSuccess
                ) {
                  response.data.content.onboarding.basicInfo.dateOfBirth =
                    piiData?.dob?.value || null;
                  response.data.content.onboarding.basicInfo.socialSecurityNumber =
                    piiData?.ssn?.value || null;
                  response.data.content.onboarding.basicInfo.gender = piiData?.gender?.value || null;
                  response.data.content.onboarding.basicInfo.ethnicity =
                    piiData?.ethnicity?.value || null;
                }

                dispatch(saveProfileSuccess(response.data.content));
                resolve(response);
              } else {
                dispatch(saveProfileFailure(response));
                reject(new Error("Response Empty"));
              }
            })
            .catch((error) => {
              dispatch(saveProfileFailure(error));
              reject(error);
            });
        })
        .catch((err) => {
          dispatch(saveProfileFailure(response));
          reject(new Error(err));
        });
    });
  };

export const UPDATE_REFERENCES_LOADING_FLAG = "UPDATE_REFERENCES_LOADING_FLAG";
export const updateReferencesLoadingFlag = (payload) => ({
  type: UPDATE_REFERENCES_LOADING_FLAG,
  payload,
});

export const FETCH_CANDIDATE_CREDENTIALS_REQUEST = "FETCH_CANDIDATE_CREDENTIALS_REQUEST";
export const fetchCandidateCredentialsRequest = (payload) => ({
  type: FETCH_CANDIDATE_CREDENTIALS_REQUEST,
  payload,
});
export const fetchCandidateCredentials =
  (atsBullhornCandidateId, companyId, authToken) => (dispatch) => {
    const queryVariables = {
      bhCandidateId: Number(atsBullhornCandidateId),
      certificationList: true,
      companyId: Number(companyId),
    };

    graphqlRequest(getCandidateCredentialsQuery, queryVariables, authToken)
      .then((candidateCredentialsResponse) => {
        let responseData = [];
        if (candidateCredentialsResponse?.getCandidateCredentials?.statusCode === 200)
          responseData =
            candidateCredentialsResponse?.getCandidateCredentials?.certificationList || [];
        else console.error(candidateCredentialsResponse?.getCandidateCredentials?.developerMessage);

        dispatch(fetchCandidateCredentialsRequest(responseData));
      })
      .catch((error) => {
        console.error(error);
        dispatch(fetchCandidateCredentialsRequest([]));
      });
  };
