import {
  FETCH_RECOMMENDED_JOBS_REQUEST,
  FETCH_RECOMMENDED_JOBS_SUCCESS,
  FETCH_RECOMMENDED_JOBS_FAILURE,
  UPDATE_RECOMMENDED_JOBS_CAROUSEL,
  UPDATE_RECOMMENDED_JOB_OBJECT,
  UPDATE_NAVIGATION_KEY,
  FETCH_MSP_JOBS_REQUEST,
  FETCH_MSP_JOBS_SUCCESS,
  FETCH_MSP_JOBS_FAILURE,
  UPDATE_MSP_JOB_OBJECT
} from "./action";

const initialState = {
  jobDetails: [],
  error: "",
  loading: false,
  viewJobDetails: false,
  recommendedJobs: null,
  recommendedJobsError: null,
  recommendedJobsLoading: false,
  recommendedJobsCarouselItems: [],
  totalRecommendedJobs: null,
  navigationKey: "search-jobs",
  mspJobs: null,
  mspJobsLoading: false,
  mspJobsError: null
};
const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_RECOMMENDED_JOBS_REQUEST:
      return { ...state, recommendedJobsLoading: true };

    case FETCH_RECOMMENDED_JOBS_SUCCESS: {
      return {
        ...state,
        recommendedJobsLoading: false,
        recommendedJobs: action.payload,
        recommendedJobsError: null,
        totalRecommendedJobs: action.payload.page.totalElements,
      };
    }
    case FETCH_RECOMMENDED_JOBS_FAILURE:
      return {
        ...state,
        recommendedJobsLoading: false,
        recommendedJobsError: action.payload,
        recommendedJobsCarouselItems: [],
        recommendedJobs: [],
      };
    case UPDATE_RECOMMENDED_JOB_OBJECT: {
      return {
        ...state,
        recommendedJobsCarouselItems: recommended,
        recommendedJobs: action.payload.recommendedJobs,
      };
    }
    case UPDATE_RECOMMENDED_JOBS_CAROUSEL:
      return { ...state, recommendedJobsCarouselItems: action.payload };
    case UPDATE_NAVIGATION_KEY:
      return { ...state, navigationKey: action.payload };
    case FETCH_MSP_JOBS_REQUEST:
      return { ...state, mspJobsLoading: true };
    case FETCH_MSP_JOBS_SUCCESS: {
      return {
        ...state,
        mspJobsLoading: false,
        mspJobs: action.payload,
      };
    }
    case FETCH_MSP_JOBS_FAILURE:
      return {
        ...state,
        mspJobsLoading: false,
        mspJobsError: action.payload,
      };
    case UPDATE_MSP_JOB_OBJECT:
       return {
        ...state,
        mspJobs: action.payload
       }
    default:
      return state;
  }
};

export default rootReducer;
