import { fetchRecruiterDetails } from "src/redux/consultant/action";
import { connect } from "react-redux";
import { useTranslation, withTranslation } from "react-i18next";
import { CUSTOMER_SERVICE_PHONE, CUSTOMER_SERVICE_EMAIL, RECRUITER_DEFAULT_CARE_TEAM_NAME, RECRUITER_DEFAULT_CARE_TEAM_EMAIL, RECRUITER_DEFAULT_CARE_TEAM_PHONE } from "src/constants/contact_info";
import clsx from "clsx";
import { useEffect } from "react";
import { recruiterConstraint } from "src/services/CommonUtility";
import { ParseError, parsePhoneNumber } from "libphonenumber-js";
import { Skeleton } from "@mui/material";
import CustomerServiceImage from "../../../../../../imagesV2/CustomerService.svg";
import RecruiterImage from "../../../../../../imagesV2/Recruiter.svg";
import styles from "./Support.module.scss";

interface RecruiterDetails {
  id: number;
  isActive: boolean;
  isDeleted: boolean;
  candidateId: number;
  companyId: number;
  revenueCenter: string;
  consultantId: string;
  firstName: string;
  lastName: string;
  emailId: string;
  phone: string;
  dateCreated: string;
  dateLastUpdated: string;
};

interface SupportProps {
  candidateId: number | null;
  recruiterDetails: RecruiterDetails | null;
  recruiterDetailsLoading: boolean;
  fetchRecruiterDetails: (candidateId: number) => void;
}

const Support = ({
  candidateId,
  recruiterDetails,
  recruiterDetailsLoading,
  fetchRecruiterDetails: getRecruiterDetails
}: SupportProps) => {
  const { t } = useTranslation();
  const isLoading = candidateId == null || recruiterDetails == null || recruiterDetailsLoading;

  useEffect(() => {
    if (candidateId) {
      getRecruiterDetails(candidateId);
    }
  }, [candidateId]);

  const getRecruiterName = (): string => {
    const { firstName, lastName } = recruiterDetails || {};

    if (firstName && lastName && recruiterConstraint(firstName) && recruiterConstraint(lastName)) {
      return `${firstName} ${lastName}`;
    }

    return RECRUITER_DEFAULT_CARE_TEAM_NAME;
  };

  const getRecruiterPhoneNumber = (): string => {
    const phone = recruiterDetails?.phone;

    if (phone && !Number.isNaN(phone) && phone.length === 10) {
      try {
        const parsedNumber = parsePhoneNumber(phone, "US")?.nationalNumber.trim();
        if (parsedNumber) {
          return `${parsedNumber.substring(0, 3)}-${parsedNumber.substring(3, 6)}-${parsedNumber.substring(6)}`;
        }
      } catch (error) {
        if (error instanceof ParseError) {
          throw new Error("Not a phone number");
        } else {
          throw error;
        }
      }
    }

    return RECRUITER_DEFAULT_CARE_TEAM_PHONE;
  };

  const recruiterName = getRecruiterName();
  const recruiterPhoneNumber = getRecruiterPhoneNumber();
  const recruiterEmail = recruiterDetails?.emailId || RECRUITER_DEFAULT_CARE_TEAM_EMAIL;

  return (
    <div className={styles.main}>
      <div className={styles.sub}>
        <div className={styles.card}>
          <div className={styles.cardTitle}>
            {t("dashboard.recruiterDetails.customerService")}
          </div>
          <div className={styles.cardContent}>
            <div className={styles.cardHeader}>
              <img src={CustomerServiceImage} alt="Customer Service" className={styles.cardHeaderImage} />
            </div>
            <div className={styles.cardBody}>
              <div className={styles.cardBodyText}>
                <div className={styles.cardBodyLabel}>
                  {t("dashboard.recruiterDetails.call")}
                </div>
                {isLoading && (
                  <div className={styles.cardBodyValue}>
                    <Skeleton variant="text" width={100} height={20} />
                  </div>
                )}

                {!isLoading && (
                  <a href={`tel:${CUSTOMER_SERVICE_PHONE}`} className={styles.cardBodyValue}>
                    {CUSTOMER_SERVICE_PHONE}
                  </a>
                )}
              </div>
              <div className={styles.cardBodyText}>
                <div className={styles.cardBodyLabel}>
                  {t("dashboard.recruiterDetails.email")}
                </div>

                {isLoading && (
                  <div className={styles.cardBodyValue}>
                    <Skeleton variant="text" width={100} height={20} />
                  </div>
                )}

                {!isLoading && (
                  <a href={`mailto:${CUSTOMER_SERVICE_EMAIL}`} className={clsx(styles.cardBodyValue, styles.email)}>
                    {CUSTOMER_SERVICE_EMAIL}
                  </a>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className={styles.card}>
          <div className={styles.cardTitle}>
            {t("dashboard.recruiterDetails.recruiter")}
          </div>
          <div className={styles.cardContent}>
            <div className={styles.cardHeader}>
              <img src={RecruiterImage} alt="Customer Service" className={styles.cardHeaderImage} />
            </div>
            <div className={styles.cardBody}>
              <div className={clsx(styles.cardBodyText, styles.recruiterName)}>
                <div className={styles.cardBodyLabel}>
                  {t("dashboard.recruiterDetails.name")}
                </div>

                {isLoading && (
                  <div className={styles.cardBodyValue}>
                    <Skeleton variant="text" width={100} height={20} />
                  </div>
                )}

                {!isLoading && (
                  <div className={styles.cardBodyValue}>
                    {recruiterName}
                  </div>
                )}
              </div>
              <div className={styles.cardBodyText}>
                <div className={styles.cardBodyLabel}>
                  {t("dashboard.recruiterDetails.contact")}
                </div>

                {isLoading && (
                  <div className={styles.cardBodyValue}>
                    <Skeleton variant="text" width={100} height={20} />
                  </div>

                )}

                {!isLoading && (
                  <>
                    {recruiterPhoneNumber && (
                      <a href={`tel:${recruiterPhoneNumber}`} className={styles.cardBodyValue}>
                        {recruiterPhoneNumber}
                      </a>
                    )}
                    {recruiterPhoneNumber == null && recruiterEmail && (
                      <a href={`mailto:${recruiterEmail}`} className={clsx(styles.cardBodyValue, styles.email)}>
                        {recruiterEmail}
                      </a>
                    )}
                    {recruiterPhoneNumber == null && recruiterEmail == null && (
                      <div className={styles.cardBodyValue}>
                        {t("dashboard.recruiterDetails.unknown")}
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  candidateId: state.auth.candidateId,
  recruiterDetails: state.consultant.recruiterDetails,
  recruiterDetailsLoading: state.consultant.loading,
});

const mapDispatchToProps = (dispatch) => ({
  fetchRecruiterDetails: (candidateId) => dispatch(fetchRecruiterDetails(candidateId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Support));

