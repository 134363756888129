import { useEffect,useState } from "react";
import Col from "react-bootstrap/Col";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Grid from "@material-ui/core/Grid";
import { withTranslation, useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import InputMask from "react-input-mask";
import { isMobile } from "react-device-detect";
import styles from "src/components/common/OnBoarding/BasicInfoSsnAndDOB.module.scss";
import clsx from "clsx";

const useStyles = makeStyles({
  item: {
    marginLeft: 61,
  }
});
export interface SsnAndDobInterface {
  isSSNAvailable: boolean;
  socialSecurityNumber: string;
  dateOfBirth: string;
  dobValue: ssnAndDobField;
  ssnValue: ssnAndDobField;
  noSsn: noSsnField;
  onInputChange: Function;
  handleChange: Function;
  parentType: string;
}
interface ssnAndDobField {
  value: string | null;
  errors: boolean;
  errorText: string;
}

interface noSsnField {
  value: boolean | null;
  errors: boolean;
  errorText: string;
}
const BasicInfoSsnAndDOB = ({
  isSSNAvailable,
  socialSecurityNumber,
  dateOfBirth,
  dobValue,
  ssnValue,
  noSsn,
  onInputChange,
  handleChange,
  parentType = "basicInfo"
}: SsnAndDobInterface) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [ssn, setSsnValue] = useState<string | null>(ssnValue.value);
  const [ssnMaskedValue, setSsnMaskedValue] = useState<string | null>(null);
  const [ssnOnBlurMasking, setSsnOnBlurMasking] = useState<boolean>(false);
  const [isSsnFieldFocused, setIsSsnFieldFocused] = useState<boolean>(false);
  const [dobOriginalValue, setDobOriginalValue] = useState<string | null>(dobValue.value);
  const [dobMaskedValue, setDobMaskedValue] = useState<string | null>(null);
  const [isDobBlurred, setIsDobBlurred] = useState<boolean>(false);
  const [isSsnDisabled, setIsSsnDisabled ] = useState<boolean>(false);
  const [disableDOB, setDisableDOB] = useState<boolean>(false);

  const fromModal = parentType === "modal";
  const ssnExists = typeof isSSNAvailable === "boolean" ? !isSSNAvailable : null;
  const readOnlySSNAndDOB = parentType === "basicInfo" &&  isSsnDisabled && disableDOB;
  const addDobMarginLeft = parentType === "basicInfo" && isSsnDisabled;
  const ssnAndDobFields = readOnlySSNAndDOB ? 4 : 5
  const readOnlyState = fromModal ? 12 : ssnAndDobFields;

  // handles dob input focus
  const handleDobFocus = () => {
    setDobMaskedValue(dobOriginalValue);
    setIsDobBlurred(false);
  };

  // Event handler for input blur (clicking outside the input) for dob
  const handleDobBlur = (value) => {
    if(value.length === 10)
    {
    setDobMaskedValue( value.slice(0, -4));
    setIsDobBlurred(true);
    }
  };

  const handleSetSsnCheckboxSelected = (e) => {
    setSsnOnBlurMasking(false);
    const isChecked = e.target.checked;
    if (isChecked) {
      setSsnMaskedValue("");
      setSsnValue("")
      setIsSsnDisabled(true);
    }else {
      setIsSsnDisabled(false);
    }
  };

  const handleSsnInputBlur = (value) => {
    if(value.length === 11)
    {
      setSsnMaskedValue( `•••-••-${value.slice(-4)}`);
      setSsnOnBlurMasking(true);
    }
    setIsSsnFieldFocused(false);
  };

  useEffect(() => {
    if (socialSecurityNumber) {
      handleSsnInputBlur(socialSecurityNumber);
      setIsSsnDisabled(true);
    } else if (ssnExists) {
      setIsSsnDisabled(true);
    }

    if (dateOfBirth) {
      handleDobBlur(dateOfBirth);
      setDobOriginalValue(dateOfBirth);
      setDisableDOB(true)
    }
  }, [socialSecurityNumber, isSSNAvailable, dateOfBirth]);

  const handleSsnInputFocus = () => {
    setSsnOnBlurMasking(false);
    setSsnMaskedValue(ssn)
    setIsSsnFieldFocused(true);
  };

  const getSsnClassName = (): string =>{
    if (fromModal) {
      return socialSecurityNumber && isMobile
        ? `${styles.ssnContainer} ${styles.removeCheckboxOnModal}`
        : styles.ssnContainer;
    }
     if(!fromModal) {
      return socialSecurityNumber && isMobile
        ? `${styles.removeCheckboxOnModal}`
        : "";
    }
    return ""
  }

  return (
    <Col sm={12} xs={12} md={12} lg={12}
      className={
        ssnValue.errors ? "onBMainTxtField onBAbtTravDrop" : "onBMainTxtField onBAbtTravDrop"
      }
    >
      <Grid item xs={12} md={readOnlySSNAndDOB ? 12 : 10}>
        <Box component="div">
        <fieldset className={clsx(styles.customBox, fromModal && styles.noBorder, readOnlySSNAndDOB && styles.readOnlyBox)}>
            {!fromModal && (
              <>
                <legend className={clsx(styles.SsnAndDobLabel, readOnlySSNAndDOB && styles.noDisplay)}>
                  {t("onBoarding.basicInfo.securityInfo")}
                </legend>
                <span className={clsx(styles.mobileView, styles.SsnAndDobLabel, readOnlySSNAndDOB && styles.noDisplay)}>
                  {t("onBoarding.basicInfo.securityInfo")}
                </span>
              </>
            )}
            <Grid
              container
              spacing={isMobile || fromModal ? 3 : 0}
              className={clsx(!socialSecurityNumber ? styles.ssnBox : styles.withoutCheckbox, readOnlySSNAndDOB && "mt-4")}
            >
              <Grid item xs={12} md={readOnlyState} className={getSsnClassName()}>
                {!fromModal &&  isSsnDisabled ? (
                  <>
                    <div className="onBReadOnlyLabel">
                      {t("onBoarding.basicInfo.SSN")}
                    </div>
                    <div className="onBReadOnlyValue">
                      {(ssnValue?.value ?? "").replace(/^\d{3}-\d{2}/, "••••-••")}
                    </div>
                  </>
                ) : (
                  <InputMask
                    mask="999-99-9999"
                    maskChar={ssnOnBlurMasking ? "•" : null}
                    value={ssnMaskedValue}
                    onChange={(e: { target: { value: any; }; }) => {
                      const value = e?.target.value
                      onInputChange("socialSecurityNumber", e);
                      setSsnValue(value)
                      setSsnMaskedValue(value)
                    }}
                    onBlur={(e) => {
                      const value = e?.target.value
                      handleSsnInputBlur(value);
                    }}
                    onFocus={handleSsnInputFocus}
                    disabled={isSsnDisabled}
                  >
                    {(inputProps: any) => (
                      <TextField
                        id="standard-basic-ssn"
                        label={t("onBoarding.basicInfo.SSN")}
                        inputProps={inputProps}
                        InputLabelProps={{
                          shrink: true,
                          className: styles.ssnTextfieldLabel,
                        }}
                        variant="outlined"
                        disabled={isSsnDisabled}
                        placeholder={isSsnFieldFocused ? "___-__-____" : ""}
                        error={ssnValue.errors}
                        helperText={ssnValue.errors ? ssnValue.errorText : ""}
                        fullWidth
                        type="text"
                        className={`${styles.inputText}`}
                      />
                    )}
                  </InputMask>
                )}
              { !socialSecurityNumber  &&
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        style={{ padding: "0 5px 0 15px" }}
                        checked={noSsn.value !== null ? !noSsn.value : false}
                        onClick={(e) => {
                          onInputChange("isSSNAvailable", {
                            target: { value: (e.target as HTMLInputElement).checked },
                          });
                          handleSetSsnCheckboxSelected(e);
                        }}
                      />
                    }
                    label={
                      <span className={styles.checkBoxLabel}>
                        {t("onBoarding.basicInfo.NoSSN")}
                      </span>
                    }
                  />
                  }
              </Grid>
              <Grid item xs={12} md={readOnlyState} className={clsx(!isMobile && !fromModal && !addDobMarginLeft && classes.item)}>
              {!fromModal && disableDOB ? (
                  <div className={`${styles.dobReadOnly} ${clsx(!isMobile && styles.dobReadonly)}`}>
                    <div className="onBReadOnlyLabel">
                      {t("onBoarding.basicInfo.DOB")}
                    </div>
                    <div className="onBReadOnlyValue">
                      {(dobOriginalValue ?? "").replace(/.{4}$/, "••••")}
                    </div>
                  </div>
                ) : (
                  <InputMask
                    mask="99/99/9999"
                    maskChar={isDobBlurred ? "•" : null}
                    value={dobMaskedValue}
                    onChange={(e: { target: { value: any; }; }) => {
                      const value = e?.target.value
                      setDobOriginalValue(value);
                      setDobMaskedValue(value);
                      handleChange("dateOfBirth", e);
                    }}
                    onFocus={handleDobFocus}
                    onBlur={(e) =>{
                      const value = e?.target.value
                      handleDobBlur (value)}}
                    disabled={disableDOB}
                  >
                    {(inputProps: any) => (
                      <TextField
                        id="standard-basic-dob"
                        label={t("onBoarding.basicInfo.DOB")}
                        inputProps={inputProps}
                        InputLabelProps={{
                          shrink: true,
                          className: styles.ssnTextfieldLabel,
                        }}
                        error={dobValue.errors}
                        helperText={dobValue.errors ? dobValue.errorText : ""}
                        placeholder="MM/DD/YYYY"
                        variant="outlined"
                        fullWidth
                        type="TextField"
                        className={`${styles.inputText}`}
                        disabled={disableDOB}
                      />
                    )}
                  </InputMask>
                )}
              </Grid>
            </Grid>
          </fieldset>
        </Box>
      </Grid>
    </Col>
  );
};

export default withTranslation()(BasicInfoSsnAndDOB);
