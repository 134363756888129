import API from "../../services/Api";

export const FETCH_PROFESSIONS_REQUEST = "FETCH_PROFESSIONS_REQUEST";
export const fetchProfessionsRequest = (payload) => ({ type: FETCH_PROFESSIONS_REQUEST, payload });

export const FETCH_PROFESSIONS_SUCCESS = "FETCH_PROFESSIONS_SUCCESS";
export const fetchProfessionsSuccess = (payload) => ({ type: FETCH_PROFESSIONS_SUCCESS, payload });

export const FETCH_PROFESSIONS_FAILURE = "FETCH_PROFESSIONS_FAILURE";
export const fetchProfessionsFailure = (payload) => ({ type: FETCH_PROFESSIONS_FAILURE, payload });

export const fetchProfessions = (term, companyId) => (dispatch) => {
  dispatch(fetchProfessionsRequest());
  API.get("professions/search", {
    params: {
      q: term,
      cid: companyId,
    },
  })
    .then((response) => {
      if (response.status === 200) {
        dispatch(fetchProfessionsSuccess(response.data[0].body));
      }
    })
    .catch((error) => {
      dispatch(fetchProfessionsFailure(error.message));
    });
};
