import validate from "validate.js";
import moment from "moment";
import isUndefined from "lodash/isUndefined";
import config from "src/config";
import i18n from "../translations/i18n";
import educationConstraints from "./EducationConstraint";
import workExpConstraints from "./WorkExpConstraint";
import basicInfoConstraints from "./BasicInfoConstraint";
import referencesConstraint from "./ReferencesConstraint";
import surveyConstraint from "./ReferencesSurveyConstraint";

let travelDestinations;
let globalDOB;
const init = () => {
  // Configure ValidateJS Options
  validate.options = { fullMessages: false };
  validate.validators.presence.options = {
    message: i18n.t("onBoarding.errorMessage.required"),
  };
  validate.validators.datetime.options = {
    message: i18n.t("onBoarding.errorMessage.validDateError"),
  };
  // Date Configurations
  validate.extend(validate.validators.datetime, {
    parse(value) {
      return +moment.utc(value);
    },
    format(value) {
      return moment.utc(value).format("YYYY-MM-DD");
    },
  });
};

const setTravelDestinations = (travelDestinationList) => {
  travelDestinations = travelDestinationList;
};

const validateSetOfFields = (formObject, constraint) => validate(formObject, constraint);

const setCustomBasicInfoValidations = () => {
  validate.validators.currentAddressCustom = (value, options, key, attributes) => {
        let result;
    const validateFields = (val, opts) => {
      if (val && val.length > 0) {
        if (!val.match(opts.format.pattern)) {
          return opts.format.message;
        }
        if (opts.length) {
          if (val.length > opts.length.max) {
            return opts.length.message;
          }
        }
      } else if (!val && opts.required) {
        return i18n.t("onBoarding.errorMessage.required");
      } else return null;
      return undefined;
    };
    if (attributes && attributes.isCurrAddressDiff) {
      if (attributes.isCurrAddressDiff === "true" || attributes.isCurrAddressDiff === true) {
        result = validateFields(value, options);
      } else return null;
    } else {
      result = validateFields(value, options);
    }
    return result;
  };
  validate.validators.travelDestinationCustom = (value) => {
    if (value && value.length > 0 && travelDestinations) {
      const vals = value.map((val) => val.toLowerCase());
      travelDestinations = travelDestinations.map((dest) => dest.toLowerCase());
      const result = vals.every((dest) => travelDestinations.includes(dest));
      if (result) {
        return null;
      }
      return "Please select a valid value";
    }
    return undefined;
  };
  validate.validators.multipleSelectCustom = (value, options) => {
    if (value && value.length > 0 && options) {
      const vals = value.map((val) => val.toLowerCase());
      const opts = options.map((opt) => opt.toLowerCase());
      const result = vals.every((option) => opts.includes(option));
      if (result) {
        return null;
      }
      return "Please select a valid value";
    }
    return undefined;
  };
  validate.validators.singleSelectCustom = (value, options) => {
    if (value && value.length > 0 && options) {
      const opts = options.map((opt) => opt.toLowerCase());
      const result = opts.includes(value.toLowerCase());
      if (result) {
        return null;
      }
      return "Please select a valid value";
    }
    return undefined;
  };
  validate.validators.dateAvailableCustom = (value) => {
    if (!value || (value && value.trim === "")) {
      return "* Required";
    }
    if (moment.utc(value).format("YYYY-MM-DD") < moment.utc().format("YYYY-MM-DD")) {
      return i18n.t("onBoarding.basicInfo.minDateMessage");
    }
    return undefined;
  };
  // custom validation for the DOB Basic Info
  validate.validators.dateOfBirthCustom = (value, options, key, attributes) => {
    // eslint-disable-next-line security/detect-non-literal-regexp
    const valueTest = new RegExp(config.DOB_REGEX);

    if (
      value !== null &&
      value !== "" &&
      (moment.utc(value).format("YYYY-MM-DD") >= moment.utc().format("YYYY-MM-DD") ||
        !valueTest.test(value))
    ) {
      return i18n.t("onBoarding.errorMessage.dob");
    }
    return undefined;
  };
};

const setCustomEducationValidations = () => {
  validate.validators.gradDateCustom = (value) => {
    if (!value || (value && value.trim === "")) {
      return "* Required";
    }
    if (moment.utc(value).format("YYYY-MM-DD") > moment.utc().format("YYYY-MM-DD")) {
      return i18n.t("onBoarding.edu&spec.gradMaxDateMessage");
    }
    return undefined;
  };
  validate.validators.educationListCustom = (value) => {
    if (value && value.length > 0) {
      const validator = [];
      value.forEach((record) => {
        validator.push(validateSetOfFields(record, educationConstraints.educationFormConstraints));
      });
      const checkErrors = validator.every((val) => isUndefined(val));
      if (checkErrors) {
        return null;
      }
      return validator;
    }
    return i18n.t("onBoarding.errorMessage.required");
  };
  validate.validators.singleSelectCustom = (value, options) => {
    if (value && value.length > 0 && options) {
      const opts = options.map((opt) => opt.toLowerCase());
      const result = opts.includes(value.toLowerCase());
      if (result) {
        return null;
      }
      return "Please select a valid value";
    }
    return undefined;
  };
};

const setCustomWorkExpValidations = () => {
  validate.validators.startDateCustom = (value, options, key, attributes) => {
    if (!value || (value && value.trim === "")) {
      return "* Required";
    }
    if (
      moment.utc(value).format("YYYY-MM-DD") <
        moment.utc(attributes.endDate).format("YYYY-MM-DD") &&
      moment.utc(value).format("YYYY-MM-DD") > moment.utc().format("YYYY-MM-DD")
    ) {
      return i18n.t("onBoarding.workExp.startDateMessage");
    }
    return undefined;
  };
  validate.validators.endDateCustom = (value, options, key, attributes) => {
    if (attributes.isCurrentlyWorking === "false") {
      if (!value || (value && value.trim === "")) {
        return "* Required";
      }
      if (
        moment.utc(value).format("YYYY-MM-DD") <
          moment.utc(attributes.startDate).format("YYYY-MM-DD") ||
        moment.utc(value).format("YYYY-MM-DD") > moment.utc().format("YYYY-MM-DD")
      ) {
        return i18n.t("onBoarding.workExp.endDateMaxMessage");
      }
    } else return null;

    return undefined;
  };
  validate.validators.staffingAgencyCustom = (value, options, key, attributes) => {
    if (attributes.isTempJob) {
      if (attributes.isTempJob === "true" || attributes.isTempJob === true) {
        if (value === null || (value && value.trim === "")) {
          return "* Required";
        }
        if (value && value.length > 255) {
          return i18n.t("onBoarding.errorMessage.staffingAgencyLength");
        }
      } else return null;
    } else {
      if (attributes.isTempJob === null) {
        return "* Required";
      }
      if (value && value.length > 255) {
        return i18n.t("onBoarding.errorMessage.staffingAgencyLength");
      }
    }
    return undefined;
  };
  validate.validators.reasonForLeavingCustom = (value, options, key, attributes) => {
    if (attributes.isCurrentlyWorking === "false") {
      if (!value || (value && value.trim === "")) {
        return "* Required";
      }
      return null;
    }
    return undefined;
  };
  validate.validators.workExperienceListCustom = (value) => {
    if (value && value.length > 0) {
      const validator = [];
      value.forEach((record) => {
        validator.push(validateSetOfFields(record, workExpConstraints.workExpFormConstraints));
      });
      const checkErrors = validator.every((val) => isUndefined(val));
      if (checkErrors) {
        return null;
      }
      return validator;
    }
    return i18n.t("onBoarding.errorMessage.required");
  };
  validate.validators.singleSelectCustom = (value, options) => {
    if (value && value.length > 0 && options) {
      const opts = options.map((opt) => opt.toLowerCase());
      const result = opts.includes(value.toLowerCase());
      if (result) {
        return null;
      }
      return "Please select a valid value";
    }
    return undefined;
  };
};

const validateSingleField = (fieldValue, constraint) => {
  const test = validate.single(fieldValue, constraint);
  return test;
};

const verifyDropdownValues = (type, fieldName, value, options, profileType) => {
  let profileConstraint;
  if (profileType === "basicInfo") {
    profileConstraint = basicInfoConstraints;
  } else if (profileType === "specialtiesAndEducation") {
    profileConstraint = educationConstraints.educationFormConstraints;
  } else if (profileType === "workExperience") {
    profileConstraint = workExpConstraints.workExpFormConstraints;
  }
  if (profileConstraint) {
    const validator = validateSingleField(value, profileConstraint[String(fieldName)]);
    if (validator) {
      if (type === "multiple") {
        return value.filter((val) => options.includes(val));
      }
      if (options.includes(value)) {
        return value;
      }
      return "";
    }
    return value;
  }
  // eslint-disable-next-line no-constant-condition
  if ("multiple") {
    return [];
  }
  return "";
};

const setCustomReferencesValidations = () => {
  validate.validators.referencesListCustom = (value) => {
    if (value && value.length > 0) {
      const validator = [];
      value.forEach((record) => {
        validator.push(validateSetOfFields(record, referencesConstraint.referencesFormConstraints));
      });
      const checkErrors = validator.every((val) => isUndefined(val));
      if (checkErrors) {
        return null;
      }
      return validator;
    }
    return i18n.t("onBoarding.errorMessage.required");
  };
};

const setCustomReferencesSurveyValidations = () => {
  validate.validators.surveyListCustom = (value) => {
    if (value && value.length > 0) {
      const validator = [];
      value.forEach((record) => {
        validator.push(validateSetOfFields(record, surveyConstraint.referencesSurveyConstraint));
      });
      const checkErrors = validator.every((val) => isUndefined(val));
      if (checkErrors) {
        return null;
      }
      return validator;
    }
    return i18n.t("onBoarding.errorMessage.required");
  };
};

const setCustomThankYouSurveyValidations = () => {
  validate.validators.startDateSurveyCustom = (value) => {
    if (value && value.split(" ")[0] === "Invalid") {
      return i18n.t("onBoarding.errorMessage.validDateError");
    }
    if (!value || (value && value.trim === "")) {
      return i18n.t("onBoarding.errorMessage.required");
    }
    if (moment.utc(value).format("YYYY-MM-DD") < moment.utc().format("YYYY-MM-DD")) {
      return i18n.t("onBoarding.basicInfo.minDateMessage");
    }
    return undefined;
  };
};
export default {
  init,
  validateSetOfFields,
  validateSingleField,
  setCustomBasicInfoValidations,
  setTravelDestinations,
  setCustomEducationValidations,
  setCustomWorkExpValidations,
  setCustomReferencesValidations,
  verifyDropdownValues,
  setCustomReferencesSurveyValidations,
  setCustomThankYouSurveyValidations,
};
