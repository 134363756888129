import { GET_RELATED_SPECIALTIES } from "src/services/GraphQL/graph.query";
import { graphqlRequest as request } from "src/services/GraphQL/GraphQL";
import { getAccessToken} from "axios-jwt";
import API from "../../services/Api";

const _ = require("lodash");

export const FETCH_LOCATION_REQUEST = "FETCH_LOCATION_REQUEST";
export const fetchLocationRequest = (payload) => ({ type: FETCH_LOCATION_REQUEST, payload });

export const FETCH_LOCATION_SUCCESS = "FETCH_LOCATION_SUCCESS";
export const fetchLocationSuccess = (payload) => ({ type: FETCH_LOCATION_SUCCESS, payload });

export const FETCH_LOCATION_FAILURE = "FETCH_LOCATION_FAILURE";
export const fetchLocationFailure = (payload) => ({ type: FETCH_LOCATION_FAILURE, payload });

export const DETECT_LOCATION_INPUT_CLICK = "DETECT_LOCATION_INPUT_CLICK";
export const detectLocationInputClick = (payload) => ({
  type: DETECT_LOCATION_INPUT_CLICK,
  payload,
});

export const fetchLocation = (term) => (dispatch) => {
  dispatch(fetchLocationRequest());
  API.get("locations/search", {
    params: {
      q: term,
    },
  })
    .then((response) => {
      let items = [];
      if (response.data[0].body.length > 0) {
        items = response.data[0].body;
      }
      dispatch(fetchLocationSuccess(items));
    })
    .catch((error) => {
      dispatch(fetchLocationFailure(error.message));
    });
};

export const FETCH_SPECIALTIES_REQUEST = "FETCH_SPECIALTIES_REQUEST";
export const fetchSpecialtiesRequest = (payload) => ({ type: FETCH_SPECIALTIES_REQUEST, payload });

export const FETCH_SPECIALTIES_SUCCESS = "FETCH_SPECIALTIES_SUCCESS";
export const fetchSpecialtiesSuccess = (payload) => ({ type: FETCH_SPECIALTIES_SUCCESS, payload });

export const FETCH_SPECIALTIES_FAILURE = "FETCH_SPECIALTIES_FAILURE";
export const fetchSpecialtiesFailure = (payload) => ({ type: FETCH_SPECIALTIES_FAILURE, payload });

export const FETCH_PRIMARY_SPECIALTIES_REQUEST = "FETCH_SPECIALTIES_REQUEST";
export const fetchPrimarySpecialtiesRequest = (payload) => ({
  type: FETCH_PRIMARY_SPECIALTIES_REQUEST,
  payload,
});

export const FETCH_PRIMARY_SPECIALTIES_SUCCESS = "FETCH_SPECIALTIES_SUCCESS";
export const fetchPrimarySpecialtiesSuccess = (payload) => ({
  type: FETCH_PRIMARY_SPECIALTIES_SUCCESS,
  payload,
});

export const FETCH_PRIMARY_SPECIALTIES_FAILURE = "FETCH_SPECIALTIES_FAILURE";
export const fetchPrimarySpecialtiesFailure = (payload) => ({
  type: FETCH_PRIMARY_SPECIALTIES_FAILURE,
  payload,
});

export const FLUSH_SPECIALTIES = "FLUSH_SPECIALTIES";
export const flushSpecialties = () => ({ type: FLUSH_SPECIALTIES });

export const FETCH_SELECTED_SPECIALTIES = "FETCH_SELECTED_SPECIALTIES";
export const fetchSelectedSpecialties = (payload, specialtiesWithCode, specialtyCode) => ({
  type: FETCH_SELECTED_SPECIALTIES,
  payload,
  specialtiesWithCode,
  specialtyCode,
});

export const fetchSpecialties = (initialTerm, isInitialSpecialty, companyId, professionCode) => {
  const term =
    isInitialSpecialty && initialTerm.indexOf(" ") >= 0
      ? initialTerm.substr(0, initialTerm.indexOf(" "))
      : initialTerm;

  let params = null;
  if (professionCode != null) {
    params = {
      q: term,
      cid: companyId,
      professionCode,
    };
  } else {
    params = {
      q: term,
      cid: companyId,
    };
  }

  return (dispatch) => {
    if (!term) {
      dispatch(flushSpecialties());
      return undefined;
    }

    return new Promise((resolve, reject) => {
      dispatch(fetchSpecialtiesRequest());
      API.get("/specialties/search", {
        params,
      })
        .then((response) => {
          const items = response.data[0].body;
          if (isInitialSpecialty) {
            const filteredUsers = _.filter(items, { code: initialTerm });
            const singleItem = filteredUsers.length > 0 ? filteredUsers[0] : null;
            let specialtiesWithCode;
            if (singleItem) {
              specialtiesWithCode = [`${singleItem.name.trim()} (${singleItem.code})`];
              dispatch(
                fetchSelectedSpecialties([singleItem.name], specialtiesWithCode, [singleItem.code])
              );
            }
          }
          dispatch(fetchSpecialtiesSuccess(items));
          resolve(items);
        })
        .catch((error) => {
          dispatch(fetchSpecialtiesFailure(error.message));
          reject(error);
        });
    });
  };
};

export const fetchPrimarySpecialties = (
  initialTerm,
  isInitialSpecialty,
  companyId,
  professionCode
) => {
  const term =
    isInitialSpecialty && initialTerm.indexOf(" ") >= 0
      ? initialTerm.substr(0, initialTerm.indexOf(" "))
      : initialTerm;

  let params = null;
  if (professionCode != null) {
    params = {
      q: term,
      cid: companyId,
      professionCode,
    };
  } else {
    params = {
      q: term,
      cid: companyId,
    };
  }
  return (dispatch) => {
    if (!term) {
      dispatch(flushSpecialties());
      return undefined;
    }


    return new Promise((resolve, reject) => {
      dispatch(fetchPrimarySpecialtiesRequest());
      API.get("/specialties/primary/search", {
        params,
      })
        .then((response) => {
          const items = response.data[0].body;
          if (isInitialSpecialty) {
            const filteredUsers = _.filter(items, { code: initialTerm });
            const singleItem = filteredUsers.length > 0 ? filteredUsers[0] : null;
            let specialtiesWithCode;
            if (singleItem) {
              specialtiesWithCode = [`${singleItem.name.trim()} (${singleItem.code})`];
              dispatch(
                fetchSelectedSpecialties([singleItem.name], specialtiesWithCode, [singleItem.code])
              );
            }
          }
          dispatch(fetchPrimarySpecialtiesSuccess(items));
          resolve(items);
        })
        .catch((error) => {
          dispatch(fetchPrimarySpecialtiesFailure(error.message));
          reject(error);
        });
    });
  };
};

/**
 * Expects below parameters and returns the related specialties for the given profession(s), specialties and companyId.
 * @param { professionDescriptions: string[]; specialtyDescriptions: string[]; companyId: number } query 
 * @returns relatedSpecialties: string[]
 */
export const fetchRelatedSpecialties = async (query) => {
  let relatedSpecialtiesResponse;
  try {
    const response = await request(GET_RELATED_SPECIALTIES, query, getAccessToken());
    if (response?.getRelatedSpecialtiesSearch?.statusCode === 200) {
      relatedSpecialtiesResponse = response?.getRelatedSpecialtiesSearch?.content?.relatedSpecialties?.map(relatedSpecialty => relatedSpecialty?.name);
    } else {
      relatedSpecialtiesResponse = [];
    }
  } catch (error) {
    relatedSpecialtiesResponse = [];
  }
  return relatedSpecialtiesResponse;
};
