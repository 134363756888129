import { Component } from "react";
import CardContent from "@material-ui/core/CardContent";
import Row from "react-bootstrap/Row";
import clsx from "clsx";
import Col from "react-bootstrap/Col";
import Typography from "@material-ui/core/Typography";
import WbSunnyIcon from "@material-ui/icons/WbSunny";
import LoopIcon from "@material-ui/icons/Loop";
import Brightness4Icon from "@material-ui/icons/Brightness4";
import Button from "@material-ui/core/Button";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { RouteComponentProps, withRouter } from "react-router-dom";
import DateRangeIcon from "@material-ui/icons/DateRange";
import moment from "moment";
import { postJobApplicationFavoriteEmail } from "src/redux/userAction/applyFavEmail/action";
import { detectJobCardClick } from "src/redux/jobDetails/action";
import JobDisclaimerToolTip from "src/components/common/MainContent/JobResults/JobDisclaimerToolTip";
import Analytics from "src/services/Analytics";
import { JOBS_DETAILS } from "src/router/routes";
import NightIcon from "src/imagesV2/nightIcon.svg";
import TimeIcon from "src/imagesV2/timeIcon.svg";
import ImInterestedModal from "src/components/common/MainContent/JobResults/ImInterestedModal";
import jobsearchQueryBuilder from "src/services/QueryBuilder/JobSearchQueryBuilder";
import config from "src/config";
import { getRotatingShiftLabel } from "src/services/CommonUtility";
import  eligibilityPercentageCalc from "src/components/common/EligibilityMeter/EligibilityPercentage"
import {
  EmailRecruiterV2Props,
  MyJobsFilters,
  jobDetailType,
  reqObjType,
} from "src/components/common/MainContent/JobResults/common/index";
import { DashboardProps } from "src/components/common/MainContent/Home/index";
import { MY_JOBS } from "src/constants/campaigns_constants";
import { RecommendedJobsSourceTypes } from "src/types/receommendedJobsSource";
import getCandidateInformation, {OnboardingInfo} from "../../CandidateInformation/CandidateInformation";


const enableAddLeadSource = config.ENABLE_ADD_LEAD_SOURCE === "true";
interface JobCardsMainContentV2Props {
  t: EmailRecruiterV2Props["t"];
  job: EmailRecruiterV2Props["job"];
  allJobs: EmailRecruiterV2Props["allJobs"];
  campaignCode: EmailRecruiterV2Props["campaignCode"];
  companyCode: DashboardProps["companyCode"];
  jobDetails: EmailRecruiterV2Props["jobDetails"];
  similarJobsCarouselItems: EmailRecruiterV2Props["similarJobsCarouselItems"];
  similarOrViewed: EmailRecruiterV2Props["similarOrViewed"];
  collaboratedJobsCarouselItems: EmailRecruiterV2Props["collaboratedJobsCarouselItems"];
  recommended: EmailRecruiterV2Props["recommended"];
  recommendedJobs: EmailRecruiterV2Props["recommendedJobs"];
  isRecommendedJobsOnResult: EmailRecruiterV2Props["isRecommendedJobsOnResult"];
  viewMyJobs: EmailRecruiterV2Props["viewMyJobs"];
  myJobs: EmailRecruiterV2Props["myJobs"];
  history: EmailRecruiterV2Props["history"];
  isJobDetailsPage: boolean;
  sourceType: string;
  jobApplySuccess: boolean;
  applyError: boolean;
  jobId: string;
  applyLoading: boolean;
  applyJob: (reqObj: reqObjType) => void;
  detectTheJobCardClick: (obj: {
    isCardClicked: boolean;
    job: jobDetailType;
    isRecommended: boolean | null;
    campaignCode: string;
    companyId: string;
    size: string | undefined;
  }) => void;
  myJobsFilter: MyJobsFilters;
  onboardingInfo: OnboardingInfo;
  atsBullhornCandidateId: string;
  bhId: string;
  isHotJobsCard?: boolean;
  mspJobs: any;
  jobsSource: RecommendedJobsSourceTypes;
  isQuickApplyAdvCard?:boolean | null
}


interface JobCardsMainContentV2State {
  jobIndexArray: (string | number)[];
  isDisabled: boolean;
  reqObject: reqObjType;
  isInterestedClicked: boolean;
}

class JobCardsMainContentV2 extends Component<
  JobCardsMainContentV2Props & RouteComponentProps,
  JobCardsMainContentV2State
> {
  constructor(props) {
    super(props);
    this.state = {
      jobIndexArray: [],
      isDisabled: false,
      reqObject: {} as reqObjType,
      isInterestedClicked: false,
    };
  }

  componentDidMount() {
    this.handleScrollPosition();
  }

  handleScrollPosition = () => {
    if (sessionStorage.getItem("scrollPosition")) {
      window.scrollTo(0, parseInt(sessionStorage.getItem("scrollPosition")!, 10));
    }
    sessionStorage.removeItem("scrollPosition");
  };

  handleColumnClick = (event) => {
    event.stopPropagation();
  };

  handleClick = (job) => {
    const {
      isRecommendedJobsOnResult,
      campaignCode,
      history,
      detectTheJobCardClick,
      companyCode,
      sourceType,
      myJobsFilter,
      onboardingInfo,
      atsBullhornCandidateId,
      bhId,
      jobsSource
    } = this.props;

    if (myJobsFilter === "assignments") {
      return;
    }

    sessionStorage.setItem("scrollPosition", window.pageYOffset.toString());
    const candidateInformation = getCandidateInformation(onboardingInfo)
    const obj = {
      isCardClicked: true,
      job,
      isRecommended: isRecommendedJobsOnResult ? true : null,
      campaignCode,
      companyId: companyCode.id,
      size: config.JOB_COUNT,
      candidateId: atsBullhornCandidateId || bhId,
      candidateInformation,
    };
    detectTheJobCardClick(obj);
    const jobsSourceParam = (jobsSource === RecommendedJobsSourceTypes.NONE || jobsSource === undefined) ? "" : `&jobsSource=${jobsSource}`;
    const queryString = `jobId=${job.id}&companyId=${
      companyCode.id
    }&campaignCode=${campaignCode}&isRecommended=${
      isRecommendedJobsOnResult ? true : null
    }&isApplied=${job.isApplied}&sourceType=${sourceType}${jobsSourceParam}`;
    jobsearchQueryBuilder.setOpenJobDetails(true);
    history.push({
      pathname: JOBS_DETAILS,
      search: queryString,
    });
  };

  applyJob = (event) => {
    const {
      t,
      job: { id: jobId },
      allJobs,
      campaignCode,
      companyCode,
      jobDetails,
      similarJobsCarouselItems,
      similarOrViewed,
      collaboratedJobsCarouselItems,
      recommended,
      recommendedJobs,
      isRecommendedJobsOnResult,
      viewMyJobs,
      myJobs,
      applyJob,
      mspJobs,
      isHotJobsCard
    } = this.props;
    event.stopPropagation();
    const reqObj = {
      jobId,
      userId: 2,
      action: "apply",
      jobs: allJobs,
      request: {
        isActive: true,
        campaignCode,
        companyId: companyCode.code,
        leadSource: enableAddLeadSource ? t("jobCardV2.leadSource") : null
      },
      jobDetails,
      similarJobs: similarJobsCarouselItems,
      similarOrViewed,
      collaboratedJobs: collaboratedJobsCarouselItems,
      recommended,
      recommendedJobs,
      isRecommendedJobsOnResult,
      viewMyJobs,
      myJobs,
      isHotJobsCard,
      mspJobs
    };
    applyJob(reqObj);

    this.setState((prevState) => ({
      jobIndexArray: prevState.jobIndexArray.includes(jobId)
        ? [...prevState.jobIndexArray]
        : [...prevState.jobIndexArray, jobId],
      isDisabled: true,
      reqObject: reqObj,
      isInterestedClicked: true,
    }));
    const gaEvent = {
      category: t("googleAnalytics.category.apply"),
      action: t("googleAnalytics.action.applyForJob"),
      label: t("googleAnalytics.label.applyForJob"),
    };
    const segmentEventName = t("segment.jobInterestShowed");
    this.trackEvent(gaEvent, this.props);
    this.trackSegmentEvent(segmentEventName, this.props);
  };

  dynamicDateMessage = () => {
    return <Typography className="postedDayLabel" />;
  };

  trackEvent = (event, props) => {
    const { history } = props;
    const dimensions = {
      dimension7: props.job.atsJobId,
      dimension9: props.job.specialtyCode,
      dimension10: props.campaignCode,
    };
    Analytics.sendPageview(history.location, dimensions);
    Analytics.sendEvent(event);
  };

  trackSegmentEvent = (eventName, props) => {
    const { sourceType, campaignCode, job } = props;
    const eligibilityPercentage = eligibilityPercentageCalc(job);
    const segmentEventName = eventName;
    const segmentEventProps = {
      jobId: props.job.atsJobId,
      origin: document.referrer,
      profession: props.job.professionCode,
      specialty: props.job.specialtyCode,
      state: props.job.state,
      city: props.job.city,
      zip: props.job.zip,
      shift: props.job.shift,
      type: props.job.type,
      weeklyPay: props.job.salaryMax,
      facilityName: props.job.facilityName,
      sourceType,
      sourceSubArea: campaignCode,
      eligibilityPercent: eligibilityPercentage,
    
    };
    Analytics.segmentTrackEvent(segmentEventName, segmentEventProps);
  };

  maxSalary(max) {
    const { t } = this.props;
    return (
      <b>
        ${max}/{t("jobs.jobsCard.weekly")}
      </b>
    );
  }

  isWorkHours(duration) {
    const { t } = this.props;
    return (
      <b>
        <DateRangeIcon className="weeksIcon" />
        <span className="iconLabel"> {`${duration} ${t("jobs.jobsCard.weeks")}`}</span>
      </b>
    );
  }

  hoursPerWeek(hoursPerWeek) {
    const { t } = this.props;
    if (hoursPerWeek) {
      return (
        <b>
          {" "}
          <img src={TimeIcon} className="timeIcon" alt="" />{" "}
          <span className="iconLabel">{hoursPerWeek + t("jobs.jobsCard.hrsPerWeek")} </span>
        </b>
      );
    }
    return <></>;
  }

  isShift(shift): JSX.Element {
    const { t } = this.props;
    const theShift = shift.toLowerCase();
    if (theShift.includes(t("jobs.jobsCard.night")))
      return (
        <b>
          <img className="nightIcon" src={NightIcon} alt="" />{" "}
          <span className="iconLabel ">{theShift}</span>{" "}
        </b>
      );
    if (theShift.includes(t("jobs.jobsCard.day")))
      return (
        <b>
          <WbSunnyIcon className="dayIcon" fontSize="small" />{" "}
          <span className="iconLabel ">{theShift}</span>{" "}
        </b>
      );
    if (theShift.includes(t("jobs.jobsCard.rotation")))
      return (
        <b>
          <LoopIcon className="rotationIcon" fontSize="small" />{" "}
          <span className="iconLabel ">{getRotatingShiftLabel(theShift)}</span>{" "}
        </b>
      );
    if (theShift.includes(t("jobs.jobsCard.evening")))
      return (
        <b>
          <Brightness4Icon className="eveningIcon" fontSize="small" />{" "}
          <span className="iconLabel ">{theShift.replace("evenings", "mid")}</span>{" "}
        </b>
      );
    return <></>;
  }

  renderApplyButton() {
    const { t, job, jobApplySuccess, applyError, jobId, applyLoading } = this.props;
    const {
      jobIndexArray,
      isDisabled: isDisabledFromState,
      isInterestedClicked,
      reqObject,
    } = this.state;
    let isDisabled;
    let buttonName = t("jobCardV2.interestedBtn");
    let btnClassname = "imInterestedBtn";

    if (job.jobStatus && job.jobStatus !== "") {
      isDisabled = true;
      buttonName = t(`jobCardV2.jobStatuses.${job.jobStatus}`, t("jobCardV2.jobStatuses.Applied"));
      btnClassname = `withStatus ${job.jobStatus
        .toLowerCase()
        .replace(/ /g, "_")
        .replace("-", "")}`;
    } else if (job.isQuickApplied) {
      isDisabled = true;
      buttonName = t("jobCardV2.jobStatuses.Applied");
      btnClassname = "withStatus applied";
    } else if (job.isApplied || (jobIndexArray.includes(job.id) && jobApplySuccess)) {
      isDisabled = true;
    } else {
      isDisabled = false;
    }

    if (jobId === job.id && applyError) {
      isDisabled = false;
    }

    return (
      <>
        <Button
          variant="contained"
          size="medium"
          className={`imInterestedBtn ${btnClassname}`}
          disabled={isDisabled || isDisabledFromState}
          onClick={this.applyJob}
        >
          {" "}
          {buttonName}
        </Button>
        <ImInterestedModal
          shouldOpen={jobId === job.id && !applyLoading && applyError && isInterestedClicked}
          reqObject={reqObject}
        />
      </>
    );
  }

  renderSalaryToolTip = (salary, job) => {
    const { t } = this.props;
    if (!salary) {
      return (
        <JobDisclaimerToolTip translate={t} isJobDetail={false} defaultMessage isSalary={false} />
      );
    }
    if (job.salaryHourly && job.salaryExpenseReimbursementWeekly) {
      return (
        <JobDisclaimerToolTip
          translate={t}
          isJobDetail={false}
          salaryHourly={job.salaryHourly}
          salaryWeekly={job.salaryExpenseReimbursementWeekly}
          isSalary
        />
      );
    }
    return <JobDisclaimerToolTip translate={t} isJobDetail={false} defaultMessage isSalary />;
  };

  render() {
    const { job, t, sourceType, myJobsFilter,isQuickApplyAdvCard } = this.props;
   
    let shift;
    let workHours;
    let salary;
    let salaryMax;
    let hoursPerWeek;
    if (job.salaryMax && job.salaryMax !== 0) salaryMax = job.salaryMax.toLocaleString();
    if (job.shift) shift = this.isShift(job.shift);
    if (job.hoursPerWeek) hoursPerWeek = this.hoursPerWeek(job.hoursPerWeek);
    if (job.duration > 0) workHours = this.isWorkHours(job.duration);
    if (job.salaryMax && job.salaryMax !== 0) salary = this.maxSalary(salaryMax);

    let submissionDate: string | null = null;
    if (
      sourceType === MY_JOBS &&
      job.jobStatus !== "" &&
      job.jobStatus !== "Applied" &&
      myJobsFilter === "quickApply"
    ) {
      submissionDate = moment(job.submissionDate).format("ll");
    }

    return (
      <div
        role="button"
        tabIndex={0}
        onKeyDown={() => this.handleClick(job)}
        onClick={() => this.handleClick(job)}
      >
        
        <CardContent>
          {!submissionDate && (
            <Typography className="specialtyJobCardWrap">
              <span className={clsx("specialtyNameJobCardV2 ",isQuickApplyAdvCard && "quickApplyAdvJobCard")}>
                {salary && job.salaryHourly && job.salaryExpenseReimbursementWeekly ? (
                  <span className="jobCardSalary">{salary}</span>
                ) : null}
              </span>
              {this.renderSalaryToolTip(salary, job)}
            </Typography>
          )}
          <div className={clsx("jobTitleV2", isQuickApplyAdvCard && "quickApplyTitle")}>{job.title}</div>

          {submissionDate ? (
            <div className="jobCardDate">
              <div>
                <p>{t("jobCardV2.submissionDate")}</p>
                {submissionDate}
              </div>
            </div>
          ) : (
            <Row className="imInterestedBtnrow">
              <Col sm={12} md={12} lg={12} xs={12} className="jobCardIcons pb-3">
                {shift}
                {hoursPerWeek}
                {workHours}
              </Col>
            </Row>
          )}
          {
            isQuickApplyAdvCard?null:(
              <div
              className="interestedBtnPos"
              role="button"
              tabIndex={0}
              onKeyDown={this.handleColumnClick}
              onClick={this.handleColumnClick}
            >
              {this.renderApplyButton()}
            </div>
            )
          }
         
        </CardContent>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  jobApplySuccess: state.applyFavEmail.jobApplySuccess,
  allJobs: state.jobResults.jobs,
  jobDetails: state.jobDetails.jobDetails,
  similarJobsCarouselItems: state.jobDetails.similarJobsCarouselItems,
  collaboratedJobsCarouselItems: state.jobDetails.collaboratedJobsCarouselItems,
  recommendedJobs: state.jobs.recommendedJobs,
  myJobs: state.myJobs.myJobs,
  myJobsFilter: state.myJobs.filterSelected,
  companyCode: state.company.company,
  applyLoading: state.applyFavEmail.applyLoading,
  applyError: state.applyFavEmail.applyError,
  jobId: state.applyFavEmail.jobId,
  onboardingInfo: state.onBoarding,
  atsBullhornCandidateId: state.candidate.atsBullhornCandidateId,
  bhId: state.candidate.bullhornIdFromBullhorn,
  mspJobs: state.jobs.mspJobs
});

const mapDispatchToProps = (dispatch) => ({
  detectTheJobCardClick: (obj) => dispatch(detectJobCardClick(obj)),
  applyJob: (reqObj) => dispatch(postJobApplicationFavoriteEmail(reqObj)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withRouter(JobCardsMainContentV2)));
