import i18n from "../translations/i18n";
import config from "../config";
import degrees from "../constants/onBoardingDegree.json";
import countries from "../constants/trainingCountries.json";

const educationFormConstraints = {
  degree: {
    presence: { allowEmpty: false },
    singleSelectCustom: degrees.degrees,
  },
  graduationDate: {
    gradDateCustom: true,
  },
  vocationalSchool: {
    presence: { allowEmpty: false },
    format: {
      pattern: config.SCHOOL_REGEX,
      message: i18n.t("onBoarding.errorMessage.school"),
    },
    length: {
      maximum: 200,
      message: i18n.t("onBoarding.errorMessage.schoolLength"),
    },
  },
  city: {
    presence: { allowEmpty: false },
    format: {
      pattern: config.CITY_REGEX,
      message: i18n.t("onBoarding.errorMessage.city"),
    },
    length: {
      maximum: 30,
      message: i18n.t("onBoarding.errorMessage.cityLength"),
    },
  },
  state: {
    presence: { allowEmpty: false },
    format: {
      pattern: config.CITY_REGEX,
      message: i18n.t("onBoarding.errorMessage.state"),
    },
    length: {
      maximum: 30,
      message: i18n.t("onBoarding.errorMessage.stateLength"),
    },
  },
  nameOnDegree: {
    presence: { allowEmpty: false },
    format: {
      pattern: config.STREET_REGEX,
      message: i18n.t("onBoarding.errorMessage.nameOnDegree"),
    },
    length: {
      maximum: 50,
      message: i18n.t("onBoarding.errorMessage.nameOnDegreeLength"),
    },
  },
  course: {
    format: {
      pattern: config.STREET_REGEX,
      message: i18n.t("onBoarding.errorMessage.course"),
    },
    length: {
      maximum: 200,
      message: i18n.t("onBoarding.errorMessage.courseLength"),
    },
  },
  trainingCountry: {
    singleSelectCustom: countries.country,
  },
  certificationExpirationDate: {
    datetime: {
      dateOnly: true,
      message: i18n.t("onBoarding.errorMessage.validDateError"),
    },
  },
  visaScreenExpiration: {
    datetime: {
      dateOnly: true,
      message: i18n.t("onBoarding.errorMessage.validDateError"),
    },
  },
};
const educationPageConstraints = {
  "specialty.profession": {
    presence: { allowEmpty: false },
  },
  educationList: {
    educationListCustom: true,
  },
  "specialty.yearsOfExperience": {
    presence: { allowEmpty: false },
    format: {
      pattern: config.NUMBER_REGEX,
      message: i18n.t("onBoarding.errorMessage.experience"),
    },
    length: {
      maximum: 30,
      message: i18n.t("onBoarding.errorMessage.experienceLength"),
    },
  },
};
export default { educationFormConstraints, educationPageConstraints };
