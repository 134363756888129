/* eslint-disable react/jsx-props-no-spreading */
import { EmailRecruiterV2Props, jobDetailType, MyJobsFilters, reqObjType } from "src/components/common/MainContent/JobResults/common";
import { Button, ButtonProps } from "@material-ui/core";
import clsx from "clsx";
import { Skeleton } from "@mui/material";
import { withTranslation, useTranslation } from "react-i18next";
import ApplyStatusIcon from "src/imagesV2/applyStatusIcon.svg";
import NewOfferStatusIcon from "src/imagesV2/newOfferStatusIcon.svg";
import CheckIcon from "src/imagesV2/checkIcon.svg";
import { connect } from "react-redux";
import { useState } from "react";
import { OnboardingInfo } from "src/components/common/CandidateInformation/CandidateInformation";
import { postJobApplicationFavoriteEmail } from "src/redux/userAction/applyFavEmail/action";
import { RecommendedJobsSourceTypes } from "src/types/receommendedJobsSource";
import config from "src/config";
import { DashboardProps } from "src/components/common/MainContent/Home/index";
import Analytics from "src/services/Analytics";
import { useHistory } from "react-router-dom";
import  eligibilityPercentageCalc from "src/components/common/EligibilityMeter/EligibilityPercentage"
import styles from "./JobCardFooter.module.scss";
import ImInterestedModal from "../../../JobResults/ImInterestedModal";

const enableAddLeadSource = config.ENABLE_ADD_LEAD_SOURCE === "true";

export interface JobCardFooterProps {
  job?: jobDetailType;
  allJobs: EmailRecruiterV2Props["allJobs"];
  campaignCode: EmailRecruiterV2Props["campaignCode"];
  companyCode: DashboardProps["companyCode"];
  jobDetails: EmailRecruiterV2Props["jobDetails"];
  similarJobsCarouselItems: EmailRecruiterV2Props["similarJobsCarouselItems"];
  similarOrViewed: EmailRecruiterV2Props["similarOrViewed"];
  collaboratedJobsCarouselItems: EmailRecruiterV2Props["collaboratedJobsCarouselItems"];
  recommended: EmailRecruiterV2Props["recommended"];
  recommendedJobs: EmailRecruiterV2Props["recommendedJobs"];
  isRecommendedJobsOnResult: EmailRecruiterV2Props["isRecommendedJobsOnResult"];
  viewMyJobs: EmailRecruiterV2Props["viewMyJobs"];
  myJobs: EmailRecruiterV2Props["myJobs"];
  history: EmailRecruiterV2Props["history"];
  isJobDetailsPage: boolean;
  sourceType: string;
  jobApplySuccess: boolean;
  applyError: boolean;
  jobId: string;
  applyLoading: boolean;
  applyJob: (reqObj: reqObjType) => void;
  detectTheJobCardClick: (obj: {
    isCardClicked: boolean;
    job: jobDetailType;
    isRecommended: boolean | null;
    campaignCode: string;
    companyId: string;
    size: string | undefined;
  }) => void;
  myJobsFilter: MyJobsFilters;
  onboardingInfo: OnboardingInfo;
  atsBullhornCandidateId: string;
  bhId: string;
  mspJobs: any;
  jobsSource: RecommendedJobsSourceTypes;
  className?: string;
  modifyJob?: (partialJob: Partial<jobDetailType>) => void;
}

const JobCardFooter = ({
  job,
  allJobs,
  campaignCode,
  companyCode,
  jobDetails,
  similarJobsCarouselItems,
  similarOrViewed,
  collaboratedJobsCarouselItems,
  recommended,
  recommendedJobs,
  isRecommendedJobsOnResult,
  viewMyJobs,
  myJobs,
  applyError,
  jobId,
  applyLoading,
  applyJob,
  mspJobs,
  sourceType,
  className,
  modifyJob,
}: JobCardFooterProps) => {
  const { t } = useTranslation();
  const history = useHistory();

  const [isInterestedClicked, setIsInterestedClicked] = useState<boolean>(false);
  const [reqObject, setReqObject] = useState<reqObjType>({} as reqObjType);

  if (job == null) {
    return (
      <div className={clsx(styles.main, className)}>
        <Skeleton variant="rectangular" className={styles.skeleton} height="100%" width="100%">
          <Button>
            {t("jobCardV2.interestedBtn")}
          </Button>
        </Skeleton>
      </div>
    )
  }

  const onClick = () => {
    setIsInterestedClicked(true);
    modifyJob?.({ ...job, isApplied: true });

    const reqObj = {
      jobId: job.id,
      userId: 2,
      action: "apply",
      jobs: allJobs,
      request: {
        isActive: true,
        campaignCode,
        companyId: companyCode.code,
        leadSource: enableAddLeadSource ? t("jobCardV2.leadSource") : null
      },
      jobDetails,
      similarJobs: similarJobsCarouselItems,
      similarOrViewed,
      collaboratedJobs: collaboratedJobsCarouselItems,
      recommended,
      recommendedJobs,
      isRecommendedJobsOnResult,
      viewMyJobs,
      myJobs,
      isHotJobsCard: false,
      mspJobs
    };

    setReqObject(reqObj);
    applyJob(reqObj);

    Analytics.sendPageview(history.location, {
      dimension7: job.atsJobId,
      dimension9: job.specialtyCode,
      dimension10: campaignCode,
    });
    Analytics.sendEvent({
      category: t("googleAnalytics.category.apply"),
      action: t("googleAnalytics.action.applyForJob"),
      label: t("googleAnalytics.label.applyForJob"),
    });
    Analytics.segmentTrackEvent(t("segment.jobInterestShowed"), {
      jobId: job.atsJobId,
      origin: document.referrer,
      profession: job.professionCode,
      specialty: job.specialtyCode,
      state: job.state,
      city: job.city,
      zip: job.zip,
      shift: job.shift,
      type: job.type,
      weeklyPay: job.salaryMax,
      facilityName: job.facilityName,
      sourceType,
      sourceSubArea: campaignCode,
      eligibilityPercent: eligibilityPercentageCalc(job),
    });
  }

  const { jobStatus } = job;
  const buttonProps: ButtonProps = {
    children: t("jobCardV2.interestedBtn"),
  };

  if (isInterestedClicked === true) {
    buttonProps.disabled = true;
    buttonProps.className = clsx(styles.isInterestedClicked);
    buttonProps.children = (
      <>
        <img src={CheckIcon} alt="Check" />
        {t("jobCardV2.interestedBtn")}
      </>
    );
  } else if ((jobStatus != null && jobStatus !== "") || job.isQuickApplied || job.isApplied) {
    const buttonText = t(`jobCardV2.jobStatuses.${jobStatus}`, t("jobCardV2.jobStatuses.Applied"));
    const camelJobStatus = (job.isQuickApplied || job.isApplied || jobStatus == null) ? "applied" : jobStatus
      .toLowerCase()
      .replace(/ - /g, " ")
      .replace(/(\s+.)/g, match => match.trim().toUpperCase());

    buttonProps.disabled = true;
    // eslint-disable-next-line security/detect-object-injection
    buttonProps.className = clsx(styles.hasStatus, styles[camelJobStatus]);
    buttonProps.children = buttonText;

    switch (camelJobStatus) {
      case "applied":
      case "appliedsubmitted": {
        buttonProps.children = (
          <>
            <img src={ApplyStatusIcon} alt="Applied" />
            {buttonText}
          </>
        );
        break;
      }
      case "appliedNewOffer": {
        buttonProps.children = (
          <>
            <img src={NewOfferStatusIcon} alt="New Offer" />
            {buttonText}
          </>
        );
        break;
      }
      default:
        break;
    }
  } else {
    buttonProps.disabled = false;
    buttonProps.onClick = onClick;
  }

  return (
    <div className={clsx(styles.main, className)}>
      <Button {...buttonProps} />
      <ImInterestedModal
        shouldOpen={jobId === job.id && !applyLoading && applyError && isInterestedClicked}
        reqObject={reqObject}
      />
    </div>
  );
}

JobCardFooter.defaultProps = {
  job: undefined,
  className: "",
  modifyJob: undefined,
}

const mapStateToProps = (state) => ({
  jobApplySuccess: state.applyFavEmail.jobApplySuccess,
  allJobs: state.jobResults.jobs,
  jobDetails: state.jobDetails.jobDetails,
  similarJobsCarouselItems: state.jobDetails.similarJobsCarouselItems,
  collaboratedJobsCarouselItems: state.jobDetails.collaboratedJobsCarouselItems,
  recommendedJobs: state.jobs.recommendedJobs,
  myJobs: state.myJobs.myJobs,
  myJobsFilter: state.myJobs.filterSelected,
  companyCode: state.company.company,
  applyLoading: state.applyFavEmail.applyLoading,
  applyError: state.applyFavEmail.applyError,
  jobId: state.applyFavEmail.jobId,
  onboardingInfo: state.onBoarding,
  atsBullhornCandidateId: state.candidate.atsBullhornCandidateId,
  bhId: state.candidate.bullhornIdFromBullhorn,
  mspJobs: state.jobs.mspJobs
});

const mapDispatchToProps = (dispatch) => ({
  applyJob: (reqObj) => dispatch(postJobApplicationFavoriteEmail(reqObj)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(JobCardFooter));
