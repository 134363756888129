import { Component } from "react";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Image from "react-bootstrap/Image";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { connect } from "react-redux";
import { TFunction, withTranslation } from "react-i18next";
import { Navbar, Nav } from "react-bootstrap";
import onClickOutside from "react-onclickoutside";
import JobsIcon from "src/images/Hamburger-Nav-Icon/JobsIcon.svg";
import JobsActive from "src/imagesV2/Hamburger-Nav-IconV2/JobsActiveIcon.svg";
import DashboardIcon from "src/images/Hamburger-Nav-Icon/DashboardnewIcon.svg";
import DashboardActive from "src/imagesV2/Hamburger-Nav-IconV2/DashboardActiveIcon.svg";
import MyProfileIcon from "src/images/Hamburger-Nav-Icon/AboutMe.svg";
import MyProfileActive from "src/imagesV2/Hamburger-Nav-IconV2/MyProfileActiveIcon.svg";
import MyTasksIcon from "src/images/Hamburger-Nav-Icon/DocumentIcon.svg";
import MyTasksActive from "src/imagesV2/Hamburger-Nav-IconV2/MyTasksActiveIcon.svg";
import MyTeamIcon from "src/imagesV2/Hamburger-Nav-IconV2/MyTeamIcon.svg";
import MyTeamActive from "src/imagesV2/Hamburger-Nav-IconV2/MyTeamActiveIcon.svg";
import PayrollIcon from "src/images/Hamburger-Nav-Icon/Payroll.svg";
import PayrollActive from "src/imagesV2/Hamburger-Nav-IconV2/PayrollActiveIcon.svg";
import UserInfoIcon from "src/imagesV2/UserInfoIcon.svg";
import LogoutIcon from "src/images/Hamburger-Nav-Icon/Logout.svg";
import BenefitsInfoIcon from "src/imagesV2/Hamburger-Nav-IconV2/BenefitsInfoIcon.svg";
import BenefitsInfoActive from "src/imagesV2/Hamburger-Nav-IconV2/BenefitsActiveIcon.svg";
import ChangePswIcon from "src/imagesV2/Hamburger-Nav-IconV2/ChangePswIcon.svg";
import SavedSearchesIcon from "src/imagesV2/Hamburger-Nav-IconV2/SavedSearchesIcon.svg";
import SavedSearchesIconActive from "src/imagesV2/Hamburger-Nav-IconV2/SavedSearchesIconActive.svg";
import { isHamburgerMenuOpen, isHamburgerSubMenuOpen } from "src/redux/navigation/action";
import {
  DASHBOARD,
  LATEST_TASKS,
  ACCOUNT_SETTINGS,
  MY_JOBS,
  MY_PROFILE,
  PAYROLL_TIMESHEET,
  BENEFITS,
  LOGOUT,
  SAVED_SEARCHES_PAGE,
  MY_TEAM_PAGE,
} from "src/router/routes";
import { updateActiveFlag } from "src/redux/onBoarding/action";
import { revokeRefreshToken } from "src/redux/auth/action";
import config from "src/config";
import { History } from "history";
import { ClassNameMap } from "@mui/styles";
import HamburgerSubMenuV2 from "./HamburgerSubMenuV2";

interface HamburgerMainMenuV2Props {
  handleOverlay: (value: boolean) => void;
  setHamburgerMenuOpen: (value: boolean) => void;
  localNavigationKey: string;
  t: TFunction<"translation", undefined>;
  handleDrawerClose: () => void;
  handlePasswordPopup: (value: boolean) => void;
  isSubMenuOpen: boolean;
  firstName: string;
  isTheHamburgerSubMenuOpen: (value: boolean | { isSubMenuOpen: boolean; type: string }) => void;
  location: Location;
  history: History;
  classes: ClassNameMap<"root" | "drawerPaper" | "drawerHeader">;
}

interface HamburgerMainMenuV2State {
  dashboard: string;
  jobs: string;
  myProfile: string;
  myTasks: string;
  myTeam: string;
  payroll: string;
  benefits: string;
  savedSearches: string;
}

class HamburgerMainMenuV2 extends Component<HamburgerMainMenuV2Props, HamburgerMainMenuV2State> {
  enableSavedSearch = config.ENABLE_SAVED_SEARCH === "true";

  enableMyTeam = config.ENABLE_MY_TEAM === "true";

  constructor(props) {
    super(props);
    this.state = {
      dashboard: DashboardIcon,
      jobs: JobsIcon,
      myProfile: MyProfileIcon,
      myTasks: MyTasksIcon,
      myTeam: MyTeamIcon,
      payroll: PayrollIcon,
      benefits: BenefitsInfoIcon,
      savedSearches: SavedSearchesIcon,
    };
  }

  componentDidUpdate(prevProps) {
    const { localNavigationKey, location } = this.props;
    if (
      location.pathname !== prevProps.location.pathname ||
      localNavigationKey !== prevProps.localNavigationKey
    ) {
      this.updateMenuIcons(location.pathname);
    }
  }

  handleClickOutside = () => {
    const { handleOverlay, setHamburgerMenuOpen, isTheHamburgerSubMenuOpen } = this.props;
    handleOverlay(false);
    setHamburgerMenuOpen(false);
    isTheHamburgerSubMenuOpen(false);
  };

  loadHamburgerSubMenu = (type) => {
    const { isTheHamburgerSubMenuOpen } = this.props;
    const subMenuObj = {
      isSubMenuOpen: true,
      type,
    };
    isTheHamburgerSubMenuOpen(subMenuObj);
  };

  handleLogOut = async () => {
    const { handleOverlay, setHamburgerMenuOpen, isTheHamburgerSubMenuOpen, history } = this.props;
    setHamburgerMenuOpen(false);
    isTheHamburgerSubMenuOpen(false);
    handleOverlay(false);
    revokeRefreshToken();
    history.push(LOGOUT);
  };

  updateNavigationKeys = () => {
    const { setHamburgerMenuOpen, handleOverlay } = this.props;
    setHamburgerMenuOpen(false);
    handleOverlay(false);
  };

  handleSelect = (eventKey) => {
    const { handleDrawerClose, handlePasswordPopup, history } = this.props;
    switch (eventKey) {
      case MY_JOBS:
        this.updateNavigationKeys();
        history.push(eventKey);
        break;
      case DASHBOARD:
        this.updateNavigationKeys();
        history.push(eventKey);
        break;
      case LATEST_TASKS:
        this.updateNavigationKeys();
        history.push(eventKey);
        break;
      case MY_TEAM_PAGE:
        this.updateNavigationKeys();
        history.push(eventKey);
        break;
      case MY_PROFILE:
        this.loadHamburgerSubMenu("myProfile");
        break;
      case PAYROLL_TIMESHEET:
        this.loadHamburgerSubMenu("payroll");
        break;
      case BENEFITS:
        this.updateNavigationKeys();
        history.push(eventKey);
        break;
      case ACCOUNT_SETTINGS:
        handleDrawerClose();
        handlePasswordPopup(true);
        break;
      case SAVED_SEARCHES_PAGE:
        this.updateNavigationKeys();
        history.push(eventKey);
        break;
      default:
        this.updateNavigationKeys();
        break;
    }
  };

  getPageName = (pathname: string): string => {
    if (pathname === "/") {
      return DASHBOARD;
    }
    if (pathname.includes("my-profile") || pathname.includes("certifications-and-licenses")) {
      return MY_PROFILE;
    }
    if (pathname.includes("need-help")) {
      return BENEFITS;
    }
    if (pathname.includes("payroll")) {
      return PAYROLL_TIMESHEET;
    }
    if (pathname.includes("jobs")) {
      return MY_JOBS;
    }
    if (pathname.includes("saved-searches")) {
      return SAVED_SEARCHES_PAGE;
    }
    return pathname;
  };

  updateMenuIcons(pathname) {
    if (pathname === "/profile/dashboard" || pathname === "/") {
      this.setState({ dashboard: DashboardActive });
    } else if (pathname.includes("jobs")) {
      this.setState({ jobs: JobsActive });
    } else if (pathname.includes("tasks")) {
      this.setState({ myTasks: MyTasksActive });
    } else if (config.ENABLE_MY_TEAM && pathname.includes("my-team")) {
      this.setState({ myTeam: MyTeamActive });
    } else if (pathname.includes("payroll")) {
      this.setState({ payroll: PayrollActive });
    } else if (pathname.includes("need-help")) {
      this.setState({ benefits: BenefitsInfoActive });
    } else if (
      pathname.includes("my-profile") ||
      pathname.includes("certifications-and-licenses")
    ) {
      this.setState({ myProfile: MyProfileActive });
    } else if (pathname.includes("saved-searches")) {
      this.setState({ savedSearches: SavedSearchesIconActive });
    } else {
      this.setState({ dashboard: DashboardIcon });
      this.setState({ jobs: JobsIcon });
      this.setState({ myProfile: MyProfileIcon });
      this.setState({ myTasks: MyTasksIcon });
      this.setState({ myTeam: MyTeamIcon });
      this.setState({ payroll: PayrollIcon });
      this.setState({ benefits: BenefitsInfoIcon });
      this.setState({ savedSearches: SavedSearchesIcon });
    }
  }

  render() {
    const { t, firstName, handleDrawerClose, handleOverlay, isSubMenuOpen, classes, location } =
      this.props;
    const { dashboard, jobs, myProfile, myTasks, myTeam, payroll, benefits, savedSearches } =
      this.state;
    return (
      <div>
        {isSubMenuOpen ? (
          <HamburgerSubMenuV2 handleOverlay={handleOverlay} />
        ) : (
          <div>
            <div className={classes.drawerHeader}>
              <div className="hamUserNameWrap">
                <Image className="userIcon" src={UserInfoIcon} />
                <span className="hamUserName">
                  {t("headerV2.helloText")}, {firstName}{" "}
                </span>
              </div>
              <IconButton onClick={handleDrawerClose} className="hamCloseButton">
                <CloseIcon />
              </IconButton>
            </div>

            <Divider className="hamNavLine" />

            <div className="hamMenuNavBar">
              <Navbar>
                <Nav activeKey={this.getPageName(location.pathname)} onSelect={this.handleSelect}>
                  <Nav.Item>
                    <Nav.Link eventKey={DASHBOARD} className="hamMenu">
                      <Image src={dashboard} className="hamNavIcon" />
                      <span className="hamNavLabel">{t("footer.profile.dashboard")}</span>
                    </Nav.Link>
                  </Nav.Item>

                  <Nav.Item>
                    <Nav.Link data-testid="my_jobs" eventKey={MY_JOBS} className="hamMenu">
                      <Image src={jobs} className="hamNavIcon" />
                      <span className="hamNavLabel">{t("header.jobsLink")}</span>
                    </Nav.Link>
                  </Nav.Item>

                  <Nav.Item>
                    <Nav.Link eventKey={MY_PROFILE} className="hamMenu">
                      <Image src={myProfile} className="hamNavIcon" />
                      <span className="hamNavLabel">{t("footer.profile.profileDocs")}</span>
                      <span className="hamNavRightIcon">
                        <ChevronRightIcon />
                      </span>
                    </Nav.Link>
                  </Nav.Item>

                  <Nav.Item>
                    <Nav.Link eventKey={LATEST_TASKS} className="hamMenu">
                      <Image src={myTasks} className="hamNavIcon" />
                      <span className="hamNavLabel">{t("headerV2.myTasks")}</span>
                    </Nav.Link>
                  </Nav.Item>
                  {this.enableMyTeam && (
                    <Nav.Item>
                      <Nav.Link eventKey={MY_TEAM_PAGE} className="hamMenu">
                        <Image src={myTeam} className="hamNavIcon" />
                        <span className="hamNavLabel">{t("headerV2.myTeamTitle")}</span>
                      </Nav.Link>
                    </Nav.Item>
                  )}
                  <Nav.Item>
                    <Nav.Link eventKey={PAYROLL_TIMESHEET} className="hamMenu">
                      <Image src={payroll} className="hamNavIcon" />
                      <span className="hamNavLabel">{t("footer.profile.payroll")}</span>
                      <span className="hamNavRightIcon">
                        <ChevronRightIcon />
                      </span>
                    </Nav.Link>
                  </Nav.Item>

                  <Nav.Item>
                    <Nav.Link eventKey={BENEFITS} className="hamMenu">
                      <Image src={benefits} className="hamNavIcon" />
                      <span className="hamNavLabel">{t("headerV2.benefits")}</span>
                    </Nav.Link>
                  </Nav.Item>
                  <br />
                  <Divider className="hamNavLine" />

                  {this.enableSavedSearch && (
                    <Nav.Item>
                      <Nav.Link className="hamMenu" eventKey={SAVED_SEARCHES_PAGE}>
                        <Image src={savedSearches} className="hamNavIcon" />
                        <span className="hamNavLabel">{t("headerV2.savedSearches")}</span>
                      </Nav.Link>
                    </Nav.Item>
                  )}

                  <Nav.Item>
                    <Nav.Link className="hamMenu" eventKey={ACCOUNT_SETTINGS}>
                      <Image src={ChangePswIcon} className="hamNavIcon" />
                      <span className="hamNavLabel">{t("headerV2.changePassword")}</span>
                    </Nav.Link>
                  </Nav.Item>
                  <br />
                  <Divider className="hamNavLine" />
                  <Nav.Item>
                    <Nav.Link className="hamMenu" onClick={this.handleLogOut}>
                      <Image src={LogoutIcon} className="hamNavIcon" />
                      <span className="hamNavLabel">{t("footer.profile.logout")}</span>
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </Navbar>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  isSubMenuOpen: state.navigation.isHamburgerSubMenuOpen,
  email: state.auth.email,
  firstName: state.auth.firstName,
  lastName: state.auth.lastName,
  company: state.company.company,
  onBoardingCompletedSteps: state.onBoarding.completedSteps,
});

const mapDispatchToProps = (dispatch) => ({
  isTheHamburgerSubMenuOpen: (data) => dispatch(isHamburgerSubMenuOpen(data)),
  setHamburgerMenuOpen: (data) => dispatch(isHamburgerMenuOpen(data)),
  updateActiveFlag: (data) => dispatch(updateActiveFlag(data)),
  revokeRefreshToken: () => dispatch(revokeRefreshToken()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(onClickOutside(HamburgerMainMenuV2)));
