/* eslint-disable import/no-cycle */
import API from "../../../services/Api";
import { updateJobObject } from "../../jobResults/action";
import { updateCarouselJobObject, updateJobDetails } from "../../jobDetails/action";
import { updateCarouselRecommendedJobObject, updateMspJobsObject } from "../../jobs/SearchCrossCountry/action";
import { updateMyJobsObject } from "../../jobs/MyJob/action";

export const POST_JOB_APPLY_REQUEST = "POST_JOB_APPLY_REQUEST";
export const postJobApply = (payload) => ({ type: POST_JOB_APPLY_REQUEST, payload });

export const POST_JOB_APPLY_SUCCESS = "POST_JOB_APPLY_SUCCESS";
export const postApplyJobSuccess = (payload) => ({ type: POST_JOB_APPLY_SUCCESS, payload });

export const POST_JOB_APPLY_FAILURE = "POST_JOB_APPLY_FAILURE";
export const postJobApplyFailure = (payload) => ({ type: POST_JOB_APPLY_FAILURE, payload });

export const POST_JOB_FAVORITE_REQUEST = "POST_JOB_FAVORITE_REQUEST";
export const postJobFavorite = (payload) => ({ type: POST_JOB_FAVORITE_REQUEST, payload });

export const POST_JOB_FAVORITE_SUCCESS = "POST_JOB_FAVORITE_SUCCESS";
export const postFavoriteJobSuccess = (payload) => ({ type: POST_JOB_FAVORITE_SUCCESS, payload });

export const POST_JOB_FAVORITE_FAILURE = "POST_JOB_FAVORITE_FAILURE";
export const postFavoriteJobFailure = (payload) => ({ type: POST_JOB_FAVORITE_FAILURE, payload });

export const POST_JOB_VIEWED_REQUEST = "POST_JOB_VIEWED_REQUEST";
export const postJobViewed = (payload) => ({ type: POST_JOB_VIEWED_REQUEST, payload });

export const POST_JOB_VIEWED_SUCCESS = "POST_JOB_VIEWED_SUCCESS";
export const postViewedJobSuccess = (payload) => ({ type: POST_JOB_VIEWED_SUCCESS, payload });

export const POST_JOB_VIEWED_FAILURE = "POST_JOB_VIEWED_FAILURE";
export const postViewedJobFailure = (payload) => ({ type: POST_JOB_VIEWED_FAILURE, payload });

export const POST_JOB_EMAIL_REQUEST = "POST_JOB_EMAIL_REQUEST";
export const postJobEmail = (payload) => ({ type: POST_JOB_EMAIL_REQUEST, payload });

export const POST_JOB_EMAIL_SUCCESS = "POST_JOB_EMAIL_SUCCESS";
export const postEmailJobSuccess = (payload) => ({ type: POST_JOB_EMAIL_SUCCESS, payload });

export const POST_JOB_EMAIL_FAILURE = "POST_JOB_EMAIL_FAILURE";
export const postEmailJobFailure = (payload) => ({ type: POST_JOB_EMAIL_FAILURE, payload });

export const POST_JOB_QUICK_APPLY_REQUEST = "POST_JOB_QUICK_APPLY_REQUEST";
export const postJobQuickApply = (payload) => ({ type: POST_JOB_QUICK_APPLY_REQUEST, payload });

export const POST_JOB_QUICK_APPLY_SUCCESS = "POST_JOB_QUICK_APPLY_SUCCESS";
export const postJobQuickApplySuccess = (payload) => ({
  type: POST_JOB_QUICK_APPLY_SUCCESS,
  payload,
});

export const POST_JOB_QUICK_APPLY_FAILURE = "POST_JOB_QUICK_APPLY_FAILURE";
export const postJobQuickApplyFailure = (payload) => ({
  type: POST_JOB_QUICK_APPLY_FAILURE,
  payload,
});

export const postJobApplicationFavoriteEmail = (req) => {
  const reqObj = { ...req };
  if (reqObj) {
    const { action } = reqObj;
    return (dispatch) => {
      switch (action) {
        case "apply":
          dispatch(postJobApply(reqObj.jobId));
          if (reqObj.isHotJobsCard) dispatch(updateMspJobsObject(reqObj));
          if (reqObj.viewMyJobs) dispatch(updateMyJobsObject(reqObj));
          else if (reqObj.similarOrViewed) dispatch(updateCarouselJobObject(reqObj));
          else if (reqObj.recommended || reqObj.isRecommendedJobsOnResult)
            dispatch(updateCarouselRecommendedJobObject(reqObj));
          else if (reqObj?.jobDetails) dispatch(updateJobDetails(reqObj));
          else reqObj.jobDetails = null;
          break;
        case "favorite":
          dispatch(postJobFavorite());
          break;
        case "email":
          dispatch(postJobEmail());
          break;
        case "viewed":
          dispatch(postJobViewed());
          break;
        case "quickApply":
          dispatch(postJobQuickApply(reqObj.jobId));
          if (reqObj.jobDetails) dispatch(updateJobDetails(reqObj));
          else reqObj.jobDetails = null;
          break;
        default:
          break;
      }
      API.post(`jobs/${reqObj.jobId}/users/actions/${action}`, JSON.stringify(reqObj.request), {
        headers: {
          "content-type": "application/json",
        },
      })
        .then((response) => {
          if (response.data.statusCode === 200 || response.data.statusCode === 409) {
            switch (action) {
              case "apply":
                dispatch(postApplyJobSuccess(response));
                break;
              case "favorite":
                dispatch(postFavoriteJobSuccess(response));
                break;
              case "email":
                dispatch(postEmailJobSuccess(response));
                break;
              case "quickApply":
                dispatch(postJobQuickApplySuccess(response));
                break;
              default:
                break;
            }
            if (reqObj.isHotJobsCard) dispatch(updateMspJobsObject(reqObj));
            if (reqObj.viewMyJobs) dispatch(updateMyJobsObject(reqObj));
            else if (reqObj.similarOrViewed) dispatch(updateCarouselJobObject(reqObj));
            else if (reqObj.recommended || reqObj.isRecommendedJobsOnResult)
              dispatch(updateCarouselRecommendedJobObject(reqObj));
            else if (reqObj?.jobDetails) dispatch(updateJobDetails(reqObj));
            else reqObj.jobDetails = null;

            dispatch(updateJobObject(reqObj));
          } else {
            switch (action) {
              case "apply":
                dispatch(postJobApplyFailure(response));
                break;
              case "favorite":
                dispatch(postFavoriteJobFailure(response));
                break;
              case "email":
                dispatch(postEmailJobFailure(response));
                break;
              case "quickApply":
                dispatch(postJobQuickApplyFailure(response));
                break;
              default:
                break;
            }
          }
        })
        .catch((error) => {
          reqObj.request.isActive = false;

          switch (action) {
            case "apply":
              dispatch(postJobApplyFailure(error));
              break;
            case "favorite":
              dispatch(postFavoriteJobFailure(error));
              break;
            case "email":
              dispatch(postEmailJobFailure(error));
              break;
            case "quickApply":
              dispatch(postJobQuickApplyFailure(error));
              break;
            default:
              break;
          }
          if (reqObj.isHotJobsCard) dispatch(updateMspJobsObject(reqObj));
          if (reqObj.viewMyJobs) dispatch(updateMyJobsObject(reqObj));
          else if (reqObj.similarOrViewed) dispatch(updateCarouselJobObject(reqObj));
          else if (reqObj.recommended || reqObj.isRecommendedJobsOnResult)
            dispatch(updateCarouselRecommendedJobObject(reqObj));
          else if (reqObj?.jobDetails) dispatch(updateJobDetails(reqObj));
          else reqObj.jobDetails = null;
        });
    };
  }
  return undefined;
};
