/* eslint-disable react/jsx-props-no-spreading */
import clsx from "clsx";
import { v4 as uuidv4 } from "uuid";
import styles from "./JobCardList.module.scss";
import JobCard, { JobCardProps } from "../JobCard";

interface JobCardListProps {
  jobCardOptions: JobCardProps[];
}

const JobCardList = ({
  jobCardOptions
}: JobCardListProps) => {
  return (
    <div className={styles.main}>
      <div className={clsx(styles.sub, "jobCardListSub")}>
        {jobCardOptions.map((jobCardOption) => (
          <JobCard
            key={uuidv4()}
            {...jobCardOption}
            className={clsx(styles.card, jobCardOption.className)}
          />
        ))}
      </div>
    </div>
  );
}

export default JobCardList;
