import React from "react";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Button from "@material-ui/core/Button";
import { withTranslation } from "react-i18next";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

function PopupModal(props) {
  return (
    <Modal
      className={"onBSkillDeleteModal"}
      open={props.open}
      onClose={props.closePopover}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={props.open}>
        <div className="onBModalPopupWrap">
          <IconButton className="onBClosePopupBtn" aria-label="close" onClick={props.closePopover}>
            <CloseIcon />
          </IconButton>
          <div className="onBModalPopupIcon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="#fff"
              width="20px"
              height="20px"
            >
              <path d="M0 0h24v24H0V0z" fill="none" />
              <path d="M11 7h2v2h-2zm0 4h2v6h-2zm1-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z" />
            </svg>
          </div>
          <div className="onBInvalidTxt">{props.t("onBoarding.basicInfo.invalidEntriesText")}</div>
          <div className="onBInvalidSubTxt">
            {props.t("onBoarding.basicInfo.invalidEntriesText1")}
          </div>
          <div className="textCenter">
            <Button className="onBSecondaryBtnNew" onClick={props.handleLeaveBtnClick}>
              {" "}
              {props.t("onBoarding.leaveBtnLbl")}{" "}
            </Button>
            &nbsp; &nbsp;
            <Button className="onBCancelBtn onBYesNoBtn" onClick={props.closePopover}>
              {props.t("onBoarding.cancelBtnLbl")}
            </Button>
          </div>
        </div>
      </Fade>
    </Modal>
  );
}

export default withTranslation()(PopupModal);
