const COMPLETE_STATUS_LIST = [
  "automated",
  "reviewed",
  "needs review",
  "reviewed",
  "validated",
  "needs eligibility review",
  "eligibility cleared",
  "validated",
];

export default COMPLETE_STATUS_LIST;
