import {
  FETCH_JOBS_REQUEST,
  FETCH_JOBS_SUCCESS,
  FETCH_JOBS_FAILURE,
  FETCH_JOB_SEARCH_REQ_OBJECT,
  FETCH_SELECTED_TAG,
  UPDATE_JOB_OBJECT,
  SHOW_MAP,
  UPDATE_RECOMMENDED_JOB_OBJECT,
  IS_FILTERS_TAB_OPEN_ON_MOB,
  UPDATE_AGGREGATIONS,
} from "./action";

const initialState = {
  jobs: [],
  error: "",
  loading: false,
  jobSearchReqObject: {
    city: null,
    state: null,
    country: null,
    zip: null,
    latitude: null,
    longitude: null,
    metro: null,
    specialty: null,
    page: null,
    size: null,
    tags: null,
    sort: null,
  },
  selectedTags: "",
  totalJobs: null,
  showMap: false,
  isFiltersTabOpen: false,
  aggregation: {
    minSalary: null,
    maxSalary: null,
  },
};
const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_JOBS_REQUEST:
      return { ...state, loading: true };

    case FETCH_JOBS_SUCCESS: {
      const newJobsState = { ...state, loading: false, jobs: action.payload.response, error: "" };
      const req = action.payload.request;
      if (!req.tags || req.tags.length === 0) {
        newJobsState.totalJobs = action.payload.response.page.totalElements;
      }
      return newJobsState;
    }
    case FETCH_JOBS_FAILURE:
      return { ...state, loading: false, error: action.payload, totalJobs: null, jobs: [] };

    case FETCH_JOB_SEARCH_REQ_OBJECT:
      return { ...state, jobSearchReqObject: action.payload };

    case FETCH_SELECTED_TAG:
      return { ...state, selectedTags: action.payload };

    case UPDATE_JOB_OBJECT: {
      const jobResults = { ...action.payload.jobs };
      return { ...state, jobs: jobResults };
    }
    case SHOW_MAP: {
      const mapController = { ...action.mapController };
      return { ...state, mapController };
    }
    case UPDATE_RECOMMENDED_JOB_OBJECT: {
      const recommended = { ...action.payload.recommendedJobs };
      return { ...state, recommendedJobs: recommended };
    }
    case IS_FILTERS_TAB_OPEN_ON_MOB:
      return { ...state, isFiltersTabOpen: action.payload };

    case UPDATE_AGGREGATIONS:
      return { ...state, aggregation: action.payload };
    default:
      return state;
  }
};

export default rootReducer;
