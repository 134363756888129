/* eslint-disable import/no-cycle */
import API from "../../../services/Api";
import { postJobApplicationFavoriteEmail } from "../applyFavEmail/action";

export const POST_EMAIL_REQUEST = "POST_EMAIL_REQUEST";
export const postEmailRequest = (payload) => ({ type: POST_EMAIL_REQUEST, payload });

export const POST_EMAIL_SUCCESS = "POST_EMAIL_SUCCESS";
export const postEmailSuccess = (payload) => ({ type: POST_EMAIL_SUCCESS, payload });

export const POST_EMAIL_FAILURE = "POST_EMAIL_FAILURE";
export const postEmailFailure = (payload) => ({ type: POST_EMAIL_FAILURE, payload });

export const postEmail = (reqObj) => (dispatch) => {
  dispatch(postEmailRequest(reqObj));
  API.post(`jobs/${reqObj.jobId}/email`, JSON.stringify(reqObj.emailReqObj), {
    headers: {
      "content-type": "application/json",
    },
  })
    .then((response) => {
      if (response.status === 200) {
        dispatch(postEmailSuccess(response));
      } else {
        dispatch(postEmailFailure(response));
      }
      dispatch(postJobApplicationFavoriteEmail(reqObj));
    })
    .catch((error) => {
      dispatch(postEmailFailure(error));
    });
};

export const SET_EMAIL_TO_DEFAULT = "SET_EMAIL_TO_DEFAULT";
export const setEmailToDefault = (payload) => ({ type: SET_EMAIL_TO_DEFAULT, payload });
