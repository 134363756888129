import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import CancelPresentationOutlinedIcon from "@mui/icons-material/CancelPresentationOutlined";
import { makeStyles } from "@material-ui/core/styles";
import Image from "react-bootstrap/Image";
import CloseActiveIcon from "../../../../imagesV2/close-active.svg";
import saveDisabled from "../../../../imagesV2/save-disabled.svg";
import saveActive from "../../../../imagesV2/save-active.svg";

const useStyles = makeStyles((theme) => ({
  closeIcon: {
    width: "20px",
    height: "20px",
    marginTop: "15px",
    cursor: "pointer",
  },
  checkIconDisabled: {
    width: "20px",
    height: "20px",
    marginTop: "15px",
    marginRight: "10px",
    cursor: "default",
  },
  checkIconActive: {
    width: "20px",
    height: "20px",
    marginTop: "15px",
    marginRight: "10px",
    cursor: "pointer",
  },
}));

function SavedSearchesIcon(props) {
  const classes = useStyles();
  const updatedVal = props.updatedValue ? props.updatedValue.trim() : "";

  return (
    <div>
      <span>
        <Image
          src={
            updatedVal !== "" && props.updatedValue !== props.originalValue
              ? saveActive
              : saveDisabled
          }
          className={
            updatedVal !== "" && props.updatedValue !== props.originalValue
              ? classes.checkIconActive
              : classes.checkIconDisabled
          }
          onClick={
            updatedVal !== "" && props.updatedValue !== props.originalValue
              ? () => props.onClick("save")
              : null
          }
        />
      </span>
      <span>
        <Image
          src={CloseActiveIcon}
          className={classes.closeIcon}
          onClick={() => props.onClick("close")}
        />
      </span>
    </div>
  );
}
export default SavedSearchesIcon;
