import { Component } from "react";
import AliceCarousel from 'react-alice-carousel';
import { isMobile } from "react-device-detect";
import RightArrow from "src/imagesV2/rightArrow.svg";
import LeftArrow from "src/imagesV2/leftArrow.svg";

interface JobsCarouselProps {
  title?: any;
  card: string[];
  hotJobs?: boolean;
  isQuickApplyAdvCard?:boolean
  jobViews?: boolean;
}
interface JobsCarouselState {
  galleryItems: JSX.Element[];
  items: {
    length: number;
  };
  currentIndex: number;
  itemsPerSlide: number;
  responsive: {
    1024: {
        items: number;
    };
}
}

class JobsCarousel extends Component<JobsCarouselProps, JobsCarouselState> {
  componentWillReceiveProps() {
    this.setState({
      items: this.props.card,
      galleryItems: this.galleryItems(),
    });
  }

  constructor(props) {
    super(props);
    this.state = {
      items: [],
      currentIndex: 0,
      responsive: { 1024: { items: 4 } },
      galleryItems: this.galleryItems(),
      itemsPerSlide: 4
    };
  }

  onSlideChanged = (event) => this.setState({ currentIndex: event.item });

  galleryItems() {
    return this.props?.card?.map((reactElement) => <div key={reactElement}> {reactElement}</div>);
  }

  render() {
    const { galleryItems, responsive, currentIndex, itemsPerSlide } = this.state;
    const isLastSet = currentIndex >= galleryItems.length - itemsPerSlide;
    const totalSets = Math.ceil(this.props.card.length / itemsPerSlide);
    const { jobViews,isQuickApplyAdvCard } = this.props;
    const hotJobsResponsiveness =  {
      0: { items: 2 }, // From 0px up to 767px, display 2 card.
      768: { items: 2 }, // From 768px up to 1023px, display 2 cards.
      1024: { items: itemsPerSlide }, // From 1024px and above, display 4 cards.
    };
    const jobViewsResponsiveness = {
      0: { items: 1 },
      768: { items: 4 },
      1024: { items: itemsPerSlide },
    };
    return (
      <div className={this.props.hotJobs && isMobile && this.props.card.length === 1 ? "hotJobSingleCarousel" : this.props.hotJobs ? "hotJobsCarousel" : jobViews || isQuickApplyAdvCard ? "" : "mainCarouselsection"}>
        <div className={(jobViews || isQuickApplyAdvCard) && isMobile? "": (!isMobile || !this.props.hotJobs) ? "container" : this.props.card.length === 1 ? "container": ""}>
          {this.props.hotJobs ? this.props.title : <h2 className="h2Carousel">{this.props.title}</h2>}
          {this.props.hotJobs ?
          <AliceCarousel
            disableDotsControls={(!isMobile && this.props.card.length < 5) || isMobile}
            renderDotsItem={({ activeIndex }) => {
              const dotIndex = Math.floor(currentIndex / itemsPerSlide);
              const isActiveDot = isLastSet ? activeIndex === totalSets - 1 : activeIndex === dotIndex;
              let dotClasses = `alice-carousel__dots-item mb-1 ${isActiveDot ? '__active' : 'mb-1'}`;
              return ( <div className={dotClasses} key={activeIndex} /> );
            }}
            items={galleryItems}
            onSlideChanged={this.onSlideChanged}
            responsive={hotJobsResponsiveness}
            animationDuration={300}
            controlsStrategy="responsive"
            infinite={true}
            keyboardNavigation={true}
            renderPrevButton={()=>{
              if(!isMobile && this.props.card && this.props.card.length > itemsPerSlide && currentIndex > 0)
                {
                  return <img src={LeftArrow} style={{ position: "absolute",
                  left: "-4%",
                  bottom: "58%",
                  backgroundColor: "#fff",
                  borderRadius: "100px",
                  cursor: 'pointer' }} className="hotJobsRightArrowContainer" />
                }
            }}
            renderNextButton={()=>{
              if(!isMobile && (this.props.card && this.props.card.length > itemsPerSlide ) && currentIndex < galleryItems.length - itemsPerSlide)
                {
                  return <img src={RightArrow} style={{ position: "absolute",
                  right: "-5%",
                  top: "30%",
                  backgroundColor: "#fff",
                  borderRadius: "100px",
                  cursor: 'pointer' }} className="hotJobsRightArrowContainer" />
                }
            }}
          />
          : this.props.jobViews ? (
            <AliceCarousel
              disableDotsControls={!!(!isMobile && this.props.card.length < 5)}
              disableButtonsControls
              items={galleryItems}
              autoPlayInterval={4000}
              autoPlayDirection="ltr"
              autoPlay={false}
              responsive={jobViewsResponsiveness}
              activeIndex={currentIndex}
              onSlideChanged={this.onSlideChanged}
            />
          ) : (
            <AliceCarousel

              disableDotsControls={!!(!isMobile && this.props.card.length < 5 ||  isQuickApplyAdvCard)}
              disableButtonsControls
              items={galleryItems}
              autoPlayInterval={4000}
              autoPlayDirection="ltr"
              autoPlay={!(!isMobile && this.props.card.length < 1 || isQuickApplyAdvCard)}
              responsive={responsive}
              activeIndex={currentIndex}
              onSlideChanged={this.onSlideChanged}
            />
          )}
        </div>
      </div>
    );
  }
}

export default JobsCarousel;
