import { Link, useLocation } from "react-router-dom";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Image from "react-bootstrap/Image";
import Button from "@material-ui/core/Button";
import { isLoggedIn } from "axios-jwt";
import { useTranslation } from "react-i18next";
import FuzzySet from "fuzzyset";
import queryString from "query-string";
import { useEffect } from "react";
import { JOBS_RESULTS, HOME, LOGIN } from "src/router/routes";
import Analytics from "src/services/Analytics";
import { CUSTOMER_SERVICE_EMAIL, CUSTOMER_SERVICE_PHONE } from "src/constants/contact_info";
import errorImg from "src/images/404-Graphic.svg";
import jobSearchQueryBuilder from "src/services/QueryBuilder/JobSearchQueryBuilder";

function PageNotFound() {
  const { t } = useTranslation();
  const { search } = useLocation();

  const getLocation = (city: string, state: string): string | null => {
    if (city && state) {
      return `${city}, ${state}`;
    }
    if (city) {
      return city;
    }
    return state ?? null;
  };

  const getSpecialty = (specialtyitem: string | (string | null)[] | null): string[] | null => {
    if (specialtyitem && typeof specialtyitem === "string") {
      return JSON.parse(specialtyitem)[0];
    }
    return null;
  };

  useEffect(() => {
    const queryStringValues = search ? queryString.parse(search) : {};
    const { specialty } = queryStringValues;
    const currentLocation = jobSearchQueryBuilder.getDefaultLocation();
    const where = getLocation(currentLocation.city, currentLocation.state);
    const specialtyList = getSpecialty(specialty);

    const fuzzySet = FuzzySet();
    fuzzySet.add(JOBS_RESULTS);
    const result = fuzzySet.get(window.location.pathname);
    if (result && result[0][0] > 0.5) {
      const dimensions = {
        dimension9:
          specialtyList && specialtyList.length > 0 ? [...new Set(specialtyList)].join() : null,
        dimension6: where,
        dimension5: t("googleAnalytics.jobNotFound"),
      };
      const googleAnalyticsEvent = {
        category: t("googleAnalytics.category.jobs"),
        action: t("googleAnalytics.action.executeJobSearch"),
        label: t("googleAnalytics.label.jobSearch"),
      };
      Analytics.sendPageview(window.location.pathname, dimensions);
      Analytics.sendEvent(googleAnalyticsEvent);
    }
  }, [search]);

  return (
    <div
      className={
        isLoggedIn() ? "container-fluid errorPageBanner" : "container-fluid notLogPageBanner"
      }
    >
      {isLoggedIn() ? null : <div className="errPageLogo" />}
      <div className={isLoggedIn() ? "errorImgDiv" : "notLogErrImg"}>
        <Image src={errorImg} />
      </div>
      <div className="erroPageMainTxt">
        {t("pageNotFound.errorHeading1")}{" "}
        <span className="errPagetxtmob">{t("pageNotFound.errorHeading2")}</span>
      </div>
      <div className="errorPageBackbtn">
        <Button className="errPageBtn">
          <Link to={isLoggedIn() ? HOME : LOGIN}>
            <ArrowBackIcon fontSize="small" />{" "}
            {isLoggedIn() ? t("pageNotFound.dashboardLabel") : t("pageNotFound.loginLabel")}
          </Link>
        </Button>
      </div>
      <div className="errorPageSubTxt">
        {t("pageNotFound.errHelpMsg")}
        <span className="mt-3 errPhnPadding">
          &nbsp;
          <a className="custServiceNo" href={`mailto:${CUSTOMER_SERVICE_EMAIL}`}>
            {CUSTOMER_SERVICE_EMAIL}{" "}
          </a>
        </span>
        <br />
        {t("pageNotFound.errHelpPhone")}
        <span className="mt-3 errPhnPadding">
          &nbsp;
          <a href={`tel:${CUSTOMER_SERVICE_PHONE}`} className="custServiceNo">
            {CUSTOMER_SERVICE_PHONE}
          </a>
        </span>
      </div>
    </div>
  );
}
export default PageNotFound;
