import React from "react";
import Button from "@material-ui/core/Button";
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import MailIcon from "@material-ui/icons/Mail";
import Image from "react-bootstrap/Image";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Slide from "@material-ui/core/Slide";
import FavoriteBorderOutlinedIcon from "@material-ui/icons/FavoriteBorderOutlined";
import FavoriteIcon from "@material-ui/icons/Favorite";
import { connect } from "react-redux";
import { withTranslation, TFunction } from "react-i18next";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { postJobApplicationFavoriteEmail } from "src/redux/userAction/applyFavEmail/action";
import Analytics from "src/services/Analytics";
import EmailRecruiterV2 from "src/components/common/MainContent/JobResults/common/EmailRecruiterV2";
import InterestedIcon from "src/imagesV2/InterestedIcon.svg";
import CheckIcon from "src/imagesV2/checkIcon.svg";
import { JOBS_DETAILS } from "src/router/routes";
import ImInterestedModal from "src/components/common/MainContent/JobResults/ImInterestedModal";
import { JOBS_DETAILS as JOB_DETAILS_SOURCE } from "src/constants/campaigns_constants";
import QuickApplyCard from "src/components/common/QuickApply/QuickApplyCard";
import config from "src/config";
import {
  jobDetailType,
  reqObjType,
  MyJobsFiltered,
} from "src/components/common/MainContent/JobResults/common/index";
import { graphqlRequest as request } from "src/services/GraphQL/GraphQL";
import { getJobQuickApply, getJobQuickApplyEligibilityGauge  } from "src/services/GraphQL/graph.query";
import { STORAGE_KEY } from "axios-jwt";
import QuickApplyCardSkeleton from "../../QuickApply/QuickApplyCardSkeleton";
import AcknowledgementOverlay from "src/components/common/QuickApply/Acknowledgement/AcknowledgementOverlay";
import SnackbarSuccess from "src/components/common/SnackbarSuccess/SnackbarSuccess";
import { isMobile } from "react-device-detect";
import { setJobQuickApplyRequirements } from "src/redux/jobDetails/action";
import { fetchMyJobs } from "src/redux/jobs/MyJob/action";
import { RecommendedJobsSourceTypes } from "src/types/receommendedJobsSource";
import getCandidateInformation from "../../CandidateInformation/CandidateInformation";

const enableQuickApply = config.ENABLE_QUICK_APPLY === "true";
const enableQuickApplyV2 = config.ENABLE_QUICK_APPLY_V2 === "true";
const enableAddLeadSource = config.ENABLE_ADD_LEAD_SOURCE === "true";
const enableSsnAndDobFields = config.ENABLE_SSN_AND_DOB_FIELDS === "true";
const enableEligibilityMeter = config.ENABLE_ELIGIBILITY_METER === "true";
const userId = 2;

interface JobDescriptionEmailProps {
  t: TFunction<"translation", undefined>;
  companyCode: {
    code: string;
    id: string;
  };
  email: string;
  atsBullhornCandidateId: string;
  bullhornIdFromBullhorn: string;
  addToFavOrApplyCall: (data: reqObjType) => void;
  addQuickApplyReqToStore: (data) => void;
  history: RouteComponentProps["history"];
  campaignCode: string;
  recommendedJobs: string;
  isRecommendedJobsOnResult: boolean;
  isRecommended: boolean;
  emailSuccessResponse: { appliedJobId: string };
  className: string;
  applyLoading: boolean;
  applyError: string;
  isFavorite: boolean;
  jobId: string;
  allJobs: jobDetailType[];
  companyId: string;
  job: jobDetailType;
  shouldOpen: boolean;
  quickApplyError: string;
  jobQuickApplySuccess: string;
  myJobs: MyJobsFiltered | null;
  eligibilityPercentage: number | null;
  onBoardingObject: any;
  fetchMyJobs: (bullhornId: string, companyId: string, authToken: string, candidateInformation?: any, includeQuickApplyEligibility?: boolean) => void;
  jobsSource: RecommendedJobsSourceTypes;
}

interface Query {
  jobId: number;
  companyId: number;
  email: string;
  bullhornId: string;
  profession: string;
  specialty: string;
  includeSsnAndDob: boolean;
  candidateInformation?: any
}

interface StateProps {
  open: boolean;
  opentoast: boolean;
  fillIcon: boolean;
  isFavorite: boolean;
  reqObject: reqObjType | null;
  isInterestedClicked: boolean;
  isQuickApplied: boolean;
  jobRequirements: string | null;
  quickApplyCalled: boolean;
  isQuickApplyLoading: boolean;
  openAcknowledgementOverlay: boolean;
  quickApplyDetails: object;
  agreeAndAccept: boolean;
  showUpdatedToast: boolean;
  showQuickApplyLoader: boolean;
  includeQuickApplyEligibility: boolean;
}

class JobDescriptionEmail extends React.Component<
  JobDescriptionEmailProps & RouteComponentProps,
  StateProps
> {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      opentoast: false,
      fillIcon: false,
      isFavorite: false,
      reqObject: null,
      isInterestedClicked: false,
      isQuickApplied: false,
      jobRequirements: null,
      quickApplyCalled: false,
      isQuickApplyLoading: false,
      openAcknowledgementOverlay: false,
      quickApplyDetails: {},
      agreeAndAccept: false,
      showUpdatedToast: false,
      showQuickApplyLoader: false,
      includeQuickApplyEligibility: true
    };
  }

  componentDidMount() {
    if (
      this.props.email &&
      this.props.job &&
      this.props.companyCode?.id &&
      (this.props.atsBullhornCandidateId || this.props.bullhornIdFromBullhorn)
    ) {
      this.getQuickApplyResponse(this.props.job);
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.email !== this.props.email ||
      prevProps.job !== this.props.job ||
      prevProps.atsBullhornCandidateId !== this.props.atsBullhornCandidateId ||
      prevProps.bullhornIdFromBullhorn !== this.props.bullhornIdFromBullhorn
    ) {
      if (
        this.props.email &&
        this.props.job?.atsJobId &&
        this.props.companyCode?.id &&
        (this.props.atsBullhornCandidateId || this.props.bullhornIdFromBullhorn)
      ) {
        this.getQuickApplyResponse(this.props.job);
      }
      this.setState({
        isQuickApplied: this.props?.job?.isQuickApplied ? this.props.job.isQuickApplied : false,
      });
    }
  }

  handleClick = () => {
    this.setState({ open: !this.state.open });
  };

  handleClose(_, param) {
    this.setState({ open: false });
    if (param) {
      this.setState({ opentoast: true });
      setTimeout(() => {
        this.setState({ opentoast: false });
      }, 3000);
    }
  }

  closeMethod() {
    this.setState({ open: false });
  }

  handleAgreeAndAccept = (value) => {
    this.setState({
      agreeAndAccept: value,
    });
  };

  submitAcknowledgement = (quickApplyDetails, preferredStartDate: string, tentativeEndDate: string, requestedTimeOffDates: Date[]) => {
    let job = quickApplyDetails.job;
    let userId = quickApplyDetails.userId;
    let action = quickApplyDetails.action;
    const { companyId, campaignCode, isRecommended } = this.props;
    let flag = false;
    let gaEvent = {};
    let segmentEventName = null;
    if (action === "quickApply" && !job.isQuickApplied) {
      flag = job.isQuickApplied;
      segmentEventName = this.props.t("segment.jobQuickApplied");
    }

    const reqObj = {
      jobId: job.id,
      userId,
      action,
      jobs: this.props.allJobs,
      request: {
        isActive: !flag,
        campaignCode: this.props.campaignCode,
        companyId: "",
        leadSource: enableAddLeadSource ? this.props.t("jobCardV2.leadSource") : null,
        preferredStartDate,
        tentativeEndDate,
        requestedTimeOffDates
      },
      jobDetails: job,
      recommendedJobs: this.props.recommendedJobs,
      isRecommendedJobsOnResult: this.props.isRecommendedJobsOnResult,
    };
    if (action === "apply" || action === "quickApply") {
      reqObj.request.companyId = this.props.companyCode.code;
    }

    if (action === "quickApply") {
      this.setState({
        reqObject: reqObj,
        isQuickApplied: true,
        showQuickApplyLoader: true
      });
    }
    this.props.addToFavOrApplyCall(reqObj);
    if (!flag) {
      this.trackEvent(gaEvent, this.props);
      this.trackSegmentEvent(segmentEventName, {
        ...this.props,
        preferredStartDate,
        tentativeEndDate,
        requestedTimeOffDates: requestedTimeOffDates?.length > 0
      });
    }
    const queryString = enableQuickApply
      ? `jobId=${job.id
      }&companyId=${companyId}&campaignCode=${campaignCode}&isRecommended=${isRecommended}&isApplied=${action === "apply" ? !flag : job.isApplied
      }&isQuickApplied=${action === "quickApply" ? !flag : job.isQuickApplied}`
      : `jobId=${job.id
      }&companyId=${companyId}&campaignCode=${campaignCode}&isRecommended=${isRecommended}&isApplied=${action === "apply" ? !flag : job.isApplied
      }`;
    this.props.history.push({
      pathname: JOBS_DETAILS,
      search: queryString,
    });
    localStorage.setItem(
      "historyCounter",
      localStorage.getItem("historyCounter")
        ? parseInt(localStorage.getItem("historyCounter") || "") + "1"
        : "1"
    );
  };

  handleQuickApplyClick(job, userId, action) {
    if (!this.state.showQuickApplyLoader) {
      // Modal Viewed Event For RTO 
      const segmentEventName = this.props.t("segment.modalViewedEvent");
      const segmentEventProps = {
        modal_type: this.props.t("acknowledgements.modalName"),
        segment_source_name: `${process.env.NODE_ENV}-web-gateway`,
      };
      Analytics.segmentTrackEvent(segmentEventName, segmentEventProps);
      this.setState({ openAcknowledgementOverlay: true });
      this.setState({
        quickApplyDetails: {
          job,
          userId,
          action,
        },
      });
    }
  }
  addFavOrApplyJob(job, userId, action) {
    const { companyId, campaignCode, isRecommended } = this.props;
    let flag = false;
    let gaEvent = {};
    let segmentEventName = null;
    if (action === "favorite") {
      flag = job.isFavorite;
      gaEvent = {
        category: this.props.t("googleAnalytics.category.jobs"),
        action: this.props.t("googleAnalytics.action.favoriteJob"),
        label: this.props.t("googleAnalytics.label.favoriteJob"),
      };
      segmentEventName = this.props.t("segment.favoriteJob");
    } else if (action === "apply" && !job.isApplied) {
      flag = job.isApplied;
      gaEvent = {
        category: this.props.t("googleAnalytics.category.apply"),
        action: this.props.t("googleAnalytics.action.applyForJob"),
        label: this.props.t("googleAnalytics.label.applyForJob"),
      };
      segmentEventName = this.props.t("segment.jobInterestShowed");
    } else if (action === "quickApply" && !job.isQuickApplied) {
      // loader enable state for quick apply v1 without RTO
      this.setState({
        showQuickApplyLoader: true
      });
      flag = job.isQuickApplied;
      segmentEventName = this.props.t("segment.jobQuickApplied");
    }

    const reqObj = {
      jobId: job.id,
      userId,
      action,
      jobs: this.props.allJobs,
      request: {
        isActive: !flag,
        campaignCode: this.props.campaignCode,
        companyId: "",
        leadSource: enableAddLeadSource ? this.props.t("jobCardV2.leadSource") : null,
      },
      jobDetails: job,
      recommendedJobs: this.props.recommendedJobs,
      isRecommendedJobsOnResult: this.props.isRecommendedJobsOnResult,
    };
    if (action === "apply" || action === "quickApply") {
      reqObj.request.companyId = this.props.companyCode.code;
    }
    if (action === "apply") {
      this.setState({
        reqObject: reqObj,
        isInterestedClicked: true,
      });
    }
    if (action === "quickApply") {
      this.setState({
        reqObject: reqObj,
        isQuickApplied: true,
      });
    }
    this.props.addToFavOrApplyCall(reqObj);
    if (!flag) {
      this.trackEvent(gaEvent, this.props);
      this.trackSegmentEvent(segmentEventName, this.props);
    }
    const queryString = enableQuickApply
      ? `jobId=${job.id
      }&companyId=${companyId}&campaignCode=${campaignCode}&isRecommended=${isRecommended}&isApplied=${action === "apply" ? !flag : job.isApplied
      }&isQuickApplied=${action === "quickApply" ? !flag : job.isQuickApplied}`
      : `jobId=${job.id
      }&companyId=${companyId}&campaignCode=${campaignCode}&isRecommended=${isRecommended}&isApplied=${action === "apply" ? !flag : job.isApplied
      }`;
    this.props.history.push({
      pathname: JOBS_DETAILS,
      search: queryString,
    });
    localStorage.setItem(
      "historyCounter",
      localStorage.getItem("historyCounter")
        ? parseInt(localStorage.getItem("historyCounter") || "") + "1"
        : "1"
    );
  }

  trackEvent(event, props) {
    const dimensions = {
      dimension7: props.job.atsJobId,
      dimension9: props.job.specialtyCode,
      dimension10: props.campaignCode,
    };
    Analytics.sendPageview(this.props.history.location, dimensions);
    Analytics.sendEvent(event);
  }

  trackSegmentEvent(eventName, props) {
    const segmentEventName = eventName;
    const segmentEventProps = {
      jobId: props.job.atsJobId,
      origin: localStorage.getItem("previousPageUrl") || "",
      profession: props.job.professionCode,
      specialty: props.job.specialtyCode,
      state: props.job.state,
      city: props.job.city,
      zip: props.job.zip,
      shift: props.job.shift,
      type: props.job.type,
      weeklyPay: props.job.salaryMax,
      facilityName: props.job.facilityName,
      sourceType: JOB_DETAILS_SOURCE,
      sourceSubArea: JOB_DETAILS_SOURCE,
      preferredStartDate: props.preferredStartDate,
      tentativeEndDate: props.tentativeEndDate,
      requestedTimeOffDates: props.requestedTimeOffDates,
      eligibilityPercent : this.props.eligibilityPercentage
    };
    Analytics.segmentTrackEvent(segmentEventName, segmentEventProps);
  }

  renderApplyButton(job, userId) {
    let isDisabled = false;
    // check for quick apply or normal apply
    if (job.isApplied || job.isQuickApplied) {
      isDisabled = true;
    } else {
      isDisabled = false;
    }
    if (this.props.jobId === this.props.job.id && this.props.applyError) {
      isDisabled = false;
    }

    return (
      <>
        <Button
          variant="contained"
          className="btnApplyforJob"
          disabled={isDisabled}
          onClick={this.addFavOrApplyJob.bind(this, job, userId, "apply")}
          startIcon={
            isDisabled ? (
              <Image src={CheckIcon} />
            ) : (
              <Image src={InterestedIcon} className="interestdBtnIcon" />
            )
          }
        >
          <span className="d-flex flex-column">
            <span>{this.props.t("jobs.jobDescription.applyButton")}</span>
            {!isDisabled && (
              <span className="notifyRecruiter">
                {this.props.t("jobs.jobDescription.notifyRecruiter")}
              </span>
            )}
          </span>
        </Button>
        <ImInterestedModal
          shouldOpen={
            this.props.jobId === this.props.job.id &&
            !this.props.applyLoading &&
            this.props.applyError &&
            this.state.isInterestedClicked
          }
          reqObject={this.state.reqObject!}
        />
      </>
    );
  }

  checkQuickApplyBtnStatus(job, jobId, quickApplyError) {
    let isDisabled = false;
    if (job.isQuickApplied) {
      isDisabled = true;
    } else {
      isDisabled = false;
    }
    if (jobId === job.id && quickApplyError) {
      isDisabled = false;
    }
    return isDisabled;
  }

  async getQuickApplyResponse(job, updatesApplied = false) {
    this.setState({ isQuickApplyLoading: true });
    const query: Query = {
      jobId: 0,
      companyId: 0,
      email: "",
      bullhornId: "",
      profession: "",
      specialty: "",
      includeSsnAndDob: false
    };
    const authToken = JSON.parse(
      (localStorage.getItem(STORAGE_KEY) || "")?.toString()
    )?.accessToken;
    query.jobId = Number(job.atsJobId);
    query.email = this.props.email;
    query.companyId = Number(this.props.companyCode.id);
    query.bullhornId =
      this.props.atsBullhornCandidateId ||
      (this.props.bullhornIdFromBullhorn ? `${this.props.bullhornIdFromBullhorn}` : "");
    query.specialty = job.specialtyDescription;
    query.profession = job.professionDescription;
    query.includeSsnAndDob = enableSsnAndDobFields;
    const candidateInformation = getCandidateInformation(this.props.onBoardingObject);
    
    if(candidateInformation 
      && 'workExperience' in candidateInformation 
      && 'credentials' in candidateInformation 
      && 'references' in candidateInformation 
      && 'onboarding' in candidateInformation
      && enableEligibilityMeter){
      query.candidateInformation = candidateInformation
    }
   
    this.setState({ quickApplyCalled: true });
    const  getJobQuickApplyRequirements = enableEligibilityMeter ? getJobQuickApplyEligibilityGauge : getJobQuickApply;
    return await request(getJobQuickApplyRequirements, query, authToken).then(
      async (res) => {
        if (enableEligibilityMeter) {
          await this.props.fetchMyJobs(
            query.bullhornId.toString(),
            query.companyId.toString(),
            authToken,
            candidateInformation,
            this.state.includeQuickApplyEligibility
          );
        } else {
          await this.props.fetchMyJobs(
            query.bullhornId.toString(),
            query.companyId.toString(),
            authToken,
          );
        }
        if (res?.getJobQuickApply && res?.getJobQuickApply?.content) {
          const requirements = res?.getJobQuickApply?.content;
          this.setState({ isQuickApplyLoading: false });
          this.setState({ jobRequirements: requirements });
          this.setState({ showUpdatedToast: updatesApplied });
          this.props.addQuickApplyReqToStore(requirements);
        } else {
          this.setState({ isQuickApplyLoading: false });
          this.setState({ jobRequirements: null });
          this.setState({ showUpdatedToast: false });
          this.props.addQuickApplyReqToStore(null);
        }
      },
      (error) => {
        console.error("Error while fetching requirements:", error);
        this.setState({ isQuickApplyLoading: false });
        this.setState({ jobRequirements: null });
        this.props.addQuickApplyReqToStore(null);
      }
    );
  }

  handleCloseOverlay = (value) => {
    this.setState({ openAcknowledgementOverlay: value });
  };

  renderQuickApplyCard(job, jobId, quickApplyError, jobQuickApplySuccess) {
    const { isQuickApplied, jobRequirements, isQuickApplyLoading } = this.state;
    if (isQuickApplyLoading) return <QuickApplyCardSkeleton />;
    if (enableQuickApply && jobRequirements)
      return (
        <QuickApplyCard
          handleOnClick={
            enableQuickApplyV2
              ? () => this.handleQuickApplyClick(job, userId, "quickApply")
              : () => this.addFavOrApplyJob(job, userId, "quickApply")
          }
          isDisabled={this.checkQuickApplyBtnStatus(job, jobId, quickApplyError)}
          isQuickAppliedSuccess={isQuickApplied && jobQuickApplySuccess}
          jobRequirements={jobRequirements}
          job={job}
          sourceUrl={this.props.history?.location?.pathname + this.props.history?.location?.search}
          refreshQuickApplyCard={this.getQuickApplyResponse.bind(this, job, true)}
          isApplying={this.state.showQuickApplyLoader}
          setIsApplying={(value) => this.setState({ showQuickApplyLoader: value })}
        />
      );
    return null;
  }

  render() {
    const {
      job,
      companyId,
      isRecommended,
      t,
      emailSuccessResponse,
      jobId,
      quickApplyError,
      jobQuickApplySuccess,
      myJobs,
    } = this.props;
    const { open, opentoast, openAcknowledgementOverlay, quickApplyDetails, agreeAndAccept, showUpdatedToast } =
      this.state;

    const applyBtn = this.renderApplyButton(job, userId);

    let canFavoriteAndMessage = true;

    if (
      myJobs &&
      (myJobs.quickApply._embedded.results.find((el) => job.id === el.id) ||
        myJobs.assignments._embedded.results.find((el) => job.id === el.id))
    ) {
      canFavoriteAndMessage = false;
    }

    // if job is quick applied on the job detail page,
    // I'm Interested, Favorite and Mail Icon will be hidden
    if(job.isQuickApplied){
      canFavoriteAndMessage = false
    }

    return (
      <div className="jobDescriptionEmailbtnposition">
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Slide direction="up" in={open}>
            <div className="tabemailCard">
              <IconButton
                aria-label="Close"
                className="jobDesEmailCloseBtn"
                onClick={() => this.setState({ open: false })}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
              <EmailRecruiterV2
                handleClose={this.handleClose.bind(this, true)}
                job={job}
                hideClose
                sourceType={JOB_DETAILS_SOURCE}
                campaignCode={JOB_DETAILS_SOURCE}
                companyId={companyId}
                isRecommended={isRecommended}
                similarOrViewed={""}
                eligibilityPercent={this.props.eligibilityPercentage}
                jobsSource={this.props.jobsSource}
              />
            </div>
          </Slide>
        </Modal>
        {openAcknowledgementOverlay && enableQuickApplyV2 && (
          <AcknowledgementOverlay
            handleClose={this.handleCloseOverlay}
            openModal={openAcknowledgementOverlay}
            quickApplyDetails={quickApplyDetails}
            handleSubmit={this.submitAcknowledgement}
            handleAgreeAndAccept={this.handleAgreeAndAccept}
            agreeAndAccept={agreeAndAccept}
          />
        )}

        {showUpdatedToast && (
          <SnackbarSuccess topRight={!isMobile} message={t("QuickApplyCard.QuickApplyUpdated")} />
        )}
        <div>{this.renderQuickApplyCard(job, jobId, quickApplyError, jobQuickApplySuccess)}</div>

        {canFavoriteAndMessage && (
          <div className="actions-button">
            <div className="actionsButtonInnterWrapper">
              <div className="fixedAjobDescApplyBtnFixed">{applyBtn}</div>
              {opentoast && (
                <SnackbarSuccess message={t("jobs.jobDescription.messageSent")} topRight />
              )}
              <div className="actionButtonWrapper">
                <div className="jobDescriptionFavBtn">
                  {job.isFavorite ? (
                    <Button
                      className=" JobDescrButton JobDescrFavButton"
                      onClick={() => this.addFavOrApplyJob(job, userId, "favorite")}
                    >
                      <FavoriteIcon fontSize="small" className="FavoriteIconclick" />
                      &nbsp;&nbsp;
                      <span className="MuiButton-iconSizeMedium">
                        {t("jobs.jobDescription.favButton")}
                      </span>
                    </Button>
                  ) : (
                    <Button
                      className=" JobDescrButton JobDescrFavButton"
                      onClick={() => this.addFavOrApplyJob(job, userId, "favorite")}
                    >
                      <FavoriteBorderOutlinedIcon fontSize="small" className="jDFavoriteIcon" />
                      <span className="MuiButton-iconSizeMedium">
                        {t("jobs.jobDescription.addToFavButton")}
                      </span>
                    </Button>
                  )}
                </div>
                <div className="jobDescriptionEmailBtn">
                  <Button
                    variant="outlined"
                    className="JobDescrButton JobDescrEmailButton"
                    startIcon={
                      job.isEmailed || emailSuccessResponse.appliedJobId === job.id ? (
                        <MailIcon className="recruiterIconFilled" />
                      ) : (
                        <MailOutlineIcon className="jDrecruiterIcon" fontSize="small" />
                      )
                    }
                    onClick={this.handleClick}
                  >
                    {t("jobs.jobDescription.emailButton")}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  allJobs: state.jobResults.jobs,
  emailSuccessResponse: state.emailRecruiter.emailSuccessResponse,
  isRecommendedJobsOnResult: state.jobDetails.isRecommendedJobsOnResult,
  recommendedJobs: state.jobs.recommendedJobs,
  companyCode: state.company.company,
  applyLoading: state.applyFavEmail.applyLoading,
  applyError: state.applyFavEmail.applyError,
  jobId: state.applyFavEmail.jobId,
  quickApplyLoading: state.applyFavEmail.quickApplyLoading,
  quickApplyError: state.applyFavEmail.quickApplyError,
  jobQuickApplySuccess: state.applyFavEmail.jobQuickApplySuccess,
  atsBullhornCandidateId: state.candidate.atsBullhornCandidateId,
  bullhornIdFromBullhorn: state.candidate.bullhornIdFromBullhorn,
  email: state.auth.email,
  myJobs: state.myJobs.filteredJobs,
  eligibilityPercentage: state.jobDetails.eligibilityPercentage,
  onBoardingObject: state.onBoarding
});

const mapDispatchToProps = (dispatch) => ({
  addToFavOrApplyCall: (reqObj) => dispatch(postJobApplicationFavoriteEmail(reqObj)),
  addQuickApplyReqToStore: (reqObj) => dispatch(setJobQuickApplyRequirements(reqObj)),
  fetchMyJobs: (bullhornId, companyId, authToken, candidateInformation, includeQuickApplyEligibility) => dispatch(fetchMyJobs(bullhornId, companyId, authToken, candidateInformation, includeQuickApplyEligibility)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(withRouter(JobDescriptionEmail)));