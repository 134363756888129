import fetch from "cross-fetch";
import { ApolloClient, ApolloLink, gql, HttpLink, InMemoryCache } from "@apollo/client";
import request, { RequestDocument } from "graphql-request";
import { getAccessToken, getRefreshToken } from "axios-jwt";
import jwt_decode from "jwt-decode";
import { refreshSession } from "../Auth";

const authLink = new ApolloLink((operation, forward) => {
  const accessToken = getAccessToken();

  if (accessToken != null) {
    operation.setContext(({ headers }) => ({ headers: {
      authorization: `Bearer ${accessToken}`,
      ...headers
    }}));
  }

  return forward(operation);
});

const graphqlServer = `${process.env.REACT_APP_PUBLIC_API_BASE}/graphql`;
const apolloClient = new ApolloClient({
  link: ApolloLink.from([
    authLink,
    new HttpLink({ uri: graphqlServer, fetch }),
  ]),
  cache: new InMemoryCache(),
});

const graphqlRequest = async <T = any>(document: RequestDocument, variables: any = {}, token?: string | null) =>
{
  if(token){
    const decodedJwt = jwt_decode(token) as {exp:number}
    const currentTime = Math.floor(Date.now() / 1000);
    if(decodedJwt.exp && decodedJwt.exp < currentTime){
       const {idToken} = await refreshSession(getRefreshToken(),localStorage.getItem("username"))
       token = idToken?.jwtToken
    }
    return request<T>(graphqlServer, document, variables, { authorization: `Bearer ${token}` }) 
  }
  else{
    return request<T>(graphqlServer, document, variables)
  }
}
   
    

export { gql, graphqlServer, graphqlRequest, apolloClient };
