import { ChangeEvent, memo } from "react";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import { useTranslation } from "react-i18next";
import jobSearchQueryBuilder from "src/services/QueryBuilder/JobSearchQueryBuilder";

interface PaginationLimitSelectProps {
  pageSize: number;
  equalizePageLimit: (value: number) => void;
  detectSecondLimitChange: (value: boolean) => void;
  detectComponentSequence: number;
  limit: number[];
  disabled: boolean;
}

const PaginationLimitSelect = memo(
  ({
    pageSize,
    equalizePageLimit,
    detectSecondLimitChange,
    detectComponentSequence,
    limit,
    disabled,
  }: PaginationLimitSelectProps) => {
    const { t } = useTranslation();
    const setSizeCriteria = (
      event: ChangeEvent<{
        name?: string;
        value: unknown;
      }>
    ): void => {
      if (typeof event.target.value !== "number") return;
      if (window.location.pathname.includes("result")) {
        jobSearchQueryBuilder.handleParamsChange({
          size: event.target.value,
          offset: 1,
        });
      }
      equalizePageLimit(event.target.value);
      detectSecondLimitChange(detectComponentSequence !== 1);
    };

    return (
      <div className="myJobsPagination">
        <FormControl variant="outlined">
          <InputLabel className="mjchkboxAlign">{t("jobResults.searchResult.showing")}</InputLabel>
          <Select
            labelId="showing-select-label"
            id="showing-select"
            label="Showing"
            value={pageSize || null}
            onChange={(event) => setSizeCriteria(event)}
            disabled={disabled}
          >
            {limit?.map((option) => (
              <MenuItem key={option} value={option} className="selectedPgValue">
                {option}
              </MenuItem>
            )) ?? <></>}
          </Select>
        </FormControl>
      </div>
    );
  }
);
export default PaginationLimitSelect;
