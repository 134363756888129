import { withTranslation, TFunction } from "react-i18next";

interface IjobDescription {
  t: TFunction<"translation", undefined>;
  jobDescription?: {
    description?: string;
    atsJobId: string;
  };
}

const JobDescription = ({ jobDescription, t }: IjobDescription) => {
  const dynamicHTML = (description) => {
    /* eslint-disable */
    return <div dangerouslySetInnerHTML={{ __html: description }} />;
  };

  const job = jobDescription;
  let description;
  if (job?.description) {
    description = dynamicHTML(job?.description.split("Posted Date")[0]);
  } else {
    description = t("jobs.jobDescription.noJobsDescription");
  }

  return (
    <div>
      <div className="jobCardMain">
        <div className="JobDescriptionRow">
          <div className="JobDescriptionTab">{description}</div>
        </div>
        <span className="JobDescriptionJobID">
          {job?.atsJobId ? `${t("jobDetails.jobId")} ${job?.atsJobId}` : ""}
        </span>
      </div>
    </div>
  );
};

JobDescription.defaultProps = {
  jobDescription: {
    description: "",
    atsJobId: "",
  },
};

export default withTranslation()(JobDescription);
