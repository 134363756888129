import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import InputAdornment from "@mui/material/InputAdornment";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import InputBase from "@mui/material/InputBase";
import SavedSearchesIcon from "./SavedSearchesIcon";

const useStyles = makeStyles((theme) => ({
  saveTextFld: {
    display: "flex",
    fontSize: "0.875rem !important",
    "@media(min-width: 0px) and (max-width: 768px)": {
      borderRight: "none",
      marginTop: "5px",
    },
    "& input": {
      width: "95%",
      textOverflow: "ellipsis",
      fontWeight: "600",
    },
    "& label": {
      color: "#3A3B3F",
      width: "75%",
      marginTop: "12px",
      marginLeft: "20px",
      fontSize: "1rem",
      fontWeight: "500",
      "@media(min-width: 0px) and (max-width: 768px)": {
        fontSize: "1rem",
      },
      "@media(min-width: 1024px) and (max-width: 1280px)": {
        fontSize: "0.9rem",
      },
    },
  },
  editTextInputBase: {
    width: "80%",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    background: "none",
    fontWeight: "500!important",
    fontSize: "1rem!important",
    padding: "10px 0px 10px 20px",
    "@media(min-width: 0px) and (max-width: 768px)": {
      width: "75%",
      padding: "10px 0px 10px 10px",
    },
  },
  editIcon: {
    color: "#373C73 !important",
    marginTop: "25px",
    cursor: "pointer",
  },
  "& .MuiSvgIcon-root": {
    marginTop: "15px",
  },
}));

function SavedSearchesTitle(props) {
  const classes = useStyles();

  return (
    <div className={classes.saveTextFld}>
      <InputBase
        className={classes.editTextInputBase}
        id="input-base"
        value={props.title}
        onChange={props.onChange}
        disabled={!(props.disabled === props.index)}
      />
      <InputAdornment position="end" className={classes.editIcon}>
        {!(props.disabled === props.index) && (
          <CreateOutlinedIcon onClick={props.handleInputDisableFlag} />
        )}
      </InputAdornment>

      {props.disabled === props.index && (
        <SavedSearchesIcon
          onClick={(type) => props.handleIconClick(type)}
          updatedValue={props.title}
          originalValue={props.originalName}
        />
      )}
    </div>
  );
}
export default SavedSearchesTitle;
