import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Link from "@material-ui/core/Link";
import { CUSTOMER_SERVICE_EMAIL, CUSTOMER_SERVICE_PHONE } from "src/constants/contact_info";
import { withTranslation } from "react-i18next";
import RecruiterInformation from "src/components/common/MainContent/Home/RecruiterInformation";

export interface MyRecruiterProps {
  t: (value: string) => string;
  recruiterDetails: {
    id: number;
    isActive: boolean;
    isDeleted: boolean;
    candidateId: number;
    companyId: number;
    revenueCenter: string;
    consultantId: string;
    firstName: string;
    lastName: string;
    emailId: string;
    phone: string;
    dateCreated: string;
    dateLastUpdated: string;
  };
}

function MyRecruiter(props: MyRecruiterProps) {
  const { t, recruiterDetails } = props;

  return (
    <Row>
      <Col sm={12} md={6} lg={6} xs={12}>
        <div className="customerService">{t("dashboard.recruiterDetails.customerService")}</div>
        <Card className="myRecruiterDetails">
          <div className="custServiceImg" />
          <div className="details">
            <CardContent>
              <Typography className="myRecruiterLabel">
                {t("dashboard.recruiterDetails.call")}
              </Typography>
              <Typography className="myRectContactDetail">
                <Link href={`tel:${CUSTOMER_SERVICE_PHONE}`} className="labelDetailsLink">
                  {CUSTOMER_SERVICE_PHONE}
                </Link>
              </Typography>
            </CardContent>
          </div>
          <div className="details contactDetailsMob">
            <CardContent>
              <Typography className="myRecruiterLabel">
                {t("dashboard.recruiterDetails.email")}
              </Typography>
              <Typography className="myRectContactDetail">
                <Link className="labelDetailsLink" href={`mailto:${CUSTOMER_SERVICE_EMAIL}`}>
                  {CUSTOMER_SERVICE_EMAIL}
                </Link>
              </Typography>
            </CardContent>
          </div>
        </Card>
      </Col>
      <Col sm={12} md={6} lg={6} xs={12}>
        <div className="customerService recruiterLblMob">
          {t("dashboard.recruiterDetails.recruiter")}
        </div>
        <Card className="myRecruiterDetails">
          <div className="recruiterImg" />
          <div className="details">
            <CardContent>
              <Typography className="myRecruiterLabel">
                {t("dashboard.recruiterDetails.name")}
              </Typography>
              <Typography className="myRecruiterNameLabel">
                <RecruiterInformation recruiterDetails={recruiterDetails} displayRecruiterName />
              </Typography>
            </CardContent>
          </div>
          <div className="details contactDetailsMob">
            <CardContent className="content">
              <Typography className="myRecruiterLabel">
                {t("dashboard.recruiterDetails.contact")}
              </Typography>
              <Typography className="myRectContactDetail">
                <RecruiterInformation recruiterDetails={recruiterDetails} displayRecruiterPhone />
              </Typography>
            </CardContent>
          </div>
        </Card>
      </Col>
    </Row>
  );
}

export default withTranslation()(MyRecruiter);
