export const HR_BENEFITS_EMAIL = "HRBenefits@crosscountry.com";
export const FIDELITY_BENEFITS_CONTACT = "(800) 835-5095";
export const HR_BENEFITS_PHONE = "1-855-245-8987";
export const AFTER_HOURS_HELPLINE = "(800) 347-2264";
export const CUSTOMER_SERVICE_EMAIL = "loginhelp@crosscountry.com";
export const CUSTOMER_SERVICE_PHONE = "800-530-6125";
export const RECRUITER_DEFAULT_CARE_TEAM_PHONE = "800-530-6125";
export const RECRUITER_DEFAULT_CARE_TEAM_NAME = "Talent Pool";
export const RECRUITER_DEFAULT_CARE_TEAM_EMAIL = "TMTNotifications@crosscountry.com";
export const MAIN_FAX = "(561) 998-8533";
export const NEW_APPLICATION_FAX = "(888) 298-3146";
export const MAIN_OFFICE_ADDRESS = "6551 Park of Commerce Blvd. Boca Raton, FL 33487-8247";
export const MAIN_OFFICE_ADDRESS_PART1 = "6551 Park Of Commerce";
export const MAIN_OFFICE_ADDRESS_PART2 = "Blvd Boca Raton,";
export const MAIN_OFFICE_ADDRESS_PART3 = "FL 33487-8247 USA";
export const MAIN_OFFICE_LAT_LNG = "26.406692,-80.1160862";
export const PAYROLL_SUPPORT_EMAIL = "askpayroll@crosscountry.com";
export const PAYROLL_SUPPORT_PHONE = "800-388-2016";
// Login Help Contact Cross Country
export const LOGIN_HELP_EMAIL = "loginhelp@crosscountry.com";
export const DEFAULT_IFRAME_EMAIL = "loginhelp@crosscountry.com";
export const CCRN_RELIANCEMATRIX_EMAIL= "rsli@acieap.com";