import Container from "react-bootstrap/Container";
import { connect } from "react-redux";
import { TFunction, withTranslation } from "react-i18next";

interface TaxSavingPlusProps {
  t: TFunction<"translation", undefined>;
  companyCode: {
    code: string;
  };
}

const TaxSavingPlus = ({ t, companyCode }: TaxSavingPlusProps) => {
  return (
    <div>
      <Container fluid className="taxSavingBanner">
        <Container>
          <p className="taxBannerText taxMarginTop">{t("taxSavingPlus.taxSavingTitle")}</p>
          <div className="BannerSubTxt">
            {" "}
            {t(`taxSavingPlus.taxSavingBannerTxt-${companyCode.code}`)}
          </div>
        </Container>
      </Container>
      <Container className="taxPagePadding">
        <div className="taxsavingComingSoonHead">{t("taxSavingPlus.taxSavingBannerTxt1")}</div>
      </Container>
    </div>
  );
};

const mapStateToProps = (state) => ({
  companyCode: state.company.company,
});

export default connect(mapStateToProps)(withTranslation()(TaxSavingPlus));
