import { push } from "connected-react-router";
import { ACCOUNT_SETTINGS } from "../../router/routes";
import API from "../../services/Api";

export const redirectToAccountSettings = (payload) => (dispatch) => {
  if (payload === ACCOUNT_SETTINGS) {
    dispatch(push(ACCOUNT_SETTINGS));
  }
};

export const PASSWORD_UPDATE_REQ_OBJECT = "PASSWORD_UPDATE_REQ_OBJECT";
export const passwordUpdateReq = (payload) => ({ type: PASSWORD_UPDATE_REQ_OBJECT, payload });

export const PASSWORD_UPDATE_SUCCESS = "PASSWORD_UPDATE_SUCCESS";
export const passwordUpdateSuccess = (payload) => ({ type: PASSWORD_UPDATE_SUCCESS, payload });

export const PASSWORD_UPDATE_FAILURE = "PASSWORD_UPDATE_FAILURE";
export const passwordUpdateFailure = (payload) => ({ type: PASSWORD_UPDATE_FAILURE, payload });

export const passwordUpdate = (reqObj) => (dispatch) => {
  dispatch(passwordUpdateReq());
  API.post("/users/updatePassword", JSON.stringify(reqObj), {
    headers: {
      "content-type": "application/json",
    },
  })
    .then((response) => {
      if (response.data.code === "200" && response.data.isPasswordUpdateSuccessful) {
        dispatch(passwordUpdateSuccess(response));
      } else {
        dispatch(passwordUpdateFailure(response));
      }
    })
    .catch((error) => {
      dispatch(passwordUpdateFailure(error));
    });
};
