import { TFunction, withTranslation } from "react-i18next";
import { triggerAnalytics } from "src/components/common/Footer/utils/TriggerAnalytics";
import styles from "./DoormatLinks.module.scss";

type footerLinks = {
  className: string;
  translatedString: string;
  route: string;
}[];

interface DoormatLinksProps {
  t: TFunction<"translation", undefined>;
  mainFooterLinks: footerLinks;
  myProfileSubNav: footerLinks;
  BuildFooterItems: (
    { route, translatedString, className }: footerLinks[0],
    index: number
  ) => JSX.Element;
  payrollSubNav: footerLinks;
  enableMyTeam: boolean;
  enablePaystubs: boolean;
  payrollSubNavV2: footerLinks;
}

const DoormatLinks = ({
  t,
  mainFooterLinks,
  myProfileSubNav,
  BuildFooterItems,
  payrollSubNav,
  enableMyTeam,
  enablePaystubs,
  payrollSubNavV2,
}: DoormatLinksProps) => {
  return (
    <>
      <div className={styles.DoormatLinks}>
        {mainFooterLinks.map((item, index) => {
          if (index === 4 && !enableMyTeam) return null;
          return BuildFooterItems(item, index);
        })}
      </div>

      <div className={styles.DoormatLinks}>
        {myProfileSubNav.map((item, index) => {
          return BuildFooterItems(item, index);
        })}
      </div>

      <div className={styles.DoormatLinks}>
        {enablePaystubs
          ? payrollSubNavV2.map((item, index) => BuildFooterItems(item, index))
          : payrollSubNav.map((item, index) => BuildFooterItems(item, index))}
        <div className={styles.DoormatTerms}>{t("footer.termsAndConditions")}</div>
        <div
          className={styles.DoormatSubLink}
          role="button"
          tabIndex={0}
          onKeyPress={(event) => {
            triggerAnalytics(t("footer.termsAndConditionsLink"), event);
            window.open(
              "https://content.ccrncloud.com/content/docs/terms-and-conditions/active-assignment/terms-and-conditions.pdf",
              "_blank"
            );
          }}
          onClick={(event) => {
            triggerAnalytics(t("footer.termsAndConditionsLink"), event);
            window.open(
              "https://content.ccrncloud.com/content/docs/terms-and-conditions/active-assignment/terms-and-conditions.pdf",
              "_blank"
            );
          }}
        >
          {t("footer.termsAndConditionsLink")}
        </div>
      </div>
    </>
  );
};

export default withTranslation()(DoormatLinks);
