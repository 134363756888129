import i18n from "../translations/i18n";
import config from "../config";
import gender from "../constants/onboardingGender.json";
import ethnicity from "../constants/onBoardingEthnicity.json";
import statesLicenses from "../constants/stateLicenses.json";
import relationship from "../constants/EmergencyContactRelationship.json";
import basicInfoLists from "../constants/basicInfoLists.json";

const ethnicityAndGender = config.ETHNICITY_AND_GENDER === "true";

const basicInfoConstraints = {
  firstname: {
    presence: { allowEmpty: false },
    format: {
      pattern: config.NAME_REGEX,
      message: i18n.t("onBoarding.errorMessage.firstname"),
    },
    length: {
      maximum: 50,
      message: i18n.t("onBoarding.errorMessage.firstnameLength"),
    },
  },
  lastname: {
    presence: { allowEmpty: false },
    format: {
      pattern: config.NAME_REGEX,
      message: i18n.t("onBoarding.errorMessage.lastname"),
    },
    length: {
      maximum: 50,
      message: i18n.t("onBoarding.errorMessage.lastnameLength"),
    },
  },
  email: {
    presence: { allowEmpty: false },
    format: {
      pattern: config.EMAIL_REGEX,
      message: i18n.t("onBoarding.errorMessage.email"),
    },
  },
  phone: {
    presence: { allowEmpty: false },
    format: {
      pattern: config.PHONE_REGEX_BASIC_INFO,
      message: i18n.t("onBoarding.errorMessage.phone"),
    },
  },
  stateLicensesHeld: {
    multipleSelectCustom: statesLicenses.states,
  },
  gender: {
    singleSelectCustom: gender.gender,
  },
  ...(ethnicityAndGender && {
    ethnicity: {
      singleSelectCustom: ethnicity.ethnicity,
    },
  }),
  dateOfBirth: {
    dateOfBirthCustom: true,
  },
  socialSecurityNumber:
    process.env.REACT_APP_ENABLE_SSN_VALIDATION_V2 === "true"
      ? {
          length: (value) => {
            if (value?.length === 0) return { is: 0 };
            return { is: 11, message: i18n.t("onBoarding.errorMessage.SSNDigit") };
          },
          format: {
            pattern: config.SSN_REGEX,
            flags: "i",
            message: i18n.t("onBoarding.errorMessage.SSNValid"),
          },
        }
      : {
          format: {
            pattern: config.SSN_REGEX,
            message: i18n.t("onBoarding.errorMessage.SSNDigit"),
          },
        },
  isCurrAddressDiff: { presence: { allowEmpty: false } },
  "currentAddress.streetAddress": {
    currentAddressCustom: {
      name: "streetAddress",
      required: true,
      format: {
        pattern: config.STREET_REGEX,
        message: i18n.t("onBoarding.errorMessage.street"),
      },
      length: {
        max: 200,
        message: i18n.t("onBoarding.errorMessage.streetLength"),
      },
    },
  },
  "currentAddress.city": {
    currentAddressCustom: {
      name: "city",
      required: true,
      format: {
        pattern: config.CITY_REGEX,
        message: i18n.t("onBoarding.errorMessage.city"),
      },
      length: {
        max: 30,
        message: i18n.t("onBoarding.errorMessage.cityLength"),
      },
    },
  },
  "currentAddress.state": {
    currentAddressCustom: {
      name: "state",
      required: true,
      format: {
        pattern: config.CITY_REGEX,
        message: i18n.t("onBoarding.errorMessage.state"),
      },
      length: {
        max: 30,
        message: i18n.t("onBoarding.errorMessage.stateLength"),
      },
    },
  },
  "currentAddress.zip": {
    currentAddressCustom: {
      name: "zip",
      required: true,
      format: {
        pattern: config.ZIP_REGEX,
        message: i18n.t("onBoarding.errorMessage.zip"),
      },
    },
  },
  "currentAddress.zipCanada": {
    currentAddressCustom: {
      name: "zip",
      required: true,
      format: {
        pattern: config.CANADA_ZIP_REGEX,
        message: i18n.t("onBoarding.errorMessage.zip"),
      },
    },
  },
  "currentAddress.apt": {
    currentAddressCustom: {
      name: "apt",
      required: false,
      format: {
        pattern: config.APT_REGEX,
        message: i18n.t("onBoarding.errorMessage.apt"),
      },
      length: {
        max: 30,
        message: i18n.t("onBoarding.errorMessage.aptLength"),
      },
    },
  },
  "currentAddress.country": {
    currentAddressCustom: {
      name: "country",
      required: true,
      format: {
        pattern: config.COUNTRY_REGEX,
        message: i18n.t("onBoarding.errorMessage.country"),
      },
    },
  },
  "permanentAddress.streetAddress": {
    presence: { allowEmpty: false },
    format: {
      pattern: config.STREET_REGEX,
      message: i18n.t("onBoarding.errorMessage.street"),
    },
    length: {
      maximum: 200,
      message: i18n.t("onBoarding.errorMessage.streetLength"),
    },
  },
  "permanentAddress.city": {
    presence: { allowEmpty: false },
    format: {
      pattern: config.CITY_REGEX,
      message: i18n.t("onBoarding.errorMessage.city"),
    },
    length: {
      maximum: 30,
      message: i18n.t("onBoarding.errorMessage.cityLength"),
    },
  },
  "permanentAddress.state": {
    presence: { allowEmpty: false },
    format: {
      pattern: config.CITY_REGEX,
      message: i18n.t("onBoarding.errorMessage.state"),
    },
    length: {
      maximum: 30,
      message: i18n.t("onBoarding.errorMessage.stateLength"),
    },
  },
  "permanentAddress.zip": {
    presence: { allowEmpty: false },
    format: {
      pattern: config.ZIP_REGEX,
      message: i18n.t("onBoarding.errorMessage.zip"),
    },
  },
  "permanentAddress.zipCanada": {
    presence: { allowEmpty: false },
    format: {
      pattern: config.CANADA_ZIP_REGEX,
      message: i18n.t("onBoarding.errorMessage.zip"),
    },
  },
  "permanentAddress.apt": {
    format: {
      pattern: config.APT_REGEX,
      message: i18n.t("onBoarding.errorMessage.apt"),
    },
    length: {
      maximum: 30,
      message: i18n.t("onBoarding.errorMessage.aptLength"),
    },
  },
  "permanentAddress.country": {
    presence: { allowEmpty: false },
    format: {
      pattern: config.COUNTRY_REGEX,
      message: i18n.t("onBoarding.errorMessage.country"),
    },
  },
  emergencyContact: {
    presence: { allowEmpty: false },
    format: {
      pattern: config.NAME_REGEX,
      message: i18n.t("onBoarding.errorMessage.emergencyContact"),
    },
    length: {
      maximum: 200,
      message: i18n.t("onBoarding.errorMessage.emergencyContactLength"),
    },
  },
  emergencyPhone: {
    presence: { allowEmpty: false },
    format: {
      pattern: config.PHONE_REGEX_BASIC_INFO,
      message: i18n.t("onBoarding.errorMessage.phone"),
    },
  },
  relation: {
    presence: { allowEmpty: false },
    singleSelectCustom: relationship.relationship,
  },
  dateAvailable: {
    dateAvailableCustom: true,
  },
  shifts: {
    multipleSelectCustom: basicInfoLists.shifts,
  },
};
export default basicInfoConstraints;
