import { useState } from "react";
import PropTypes from "prop-types";
import { Theme, makeStyles } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import jobSearchQueryBuilder from "src/services/QueryBuilder/JobSearchQueryBuilder";
import config from "src/config";

const useStyles = makeStyles((theme) => {
  const themeWithProps = theme as Theme & { palette: { colorPrimary: string; mainBorder: string } };
  return {
    filterDistance: {
      "& .MuiSelect-select, & .MuiSelect-select:focus": {
        border: `${themeWithProps.palette.mainBorder}!important`,
        padding: "4px 0px 6px 8px!important",
        borderRadius: "4px",
        color: `${themeWithProps.palette.colorPrimary}!important`,
        width: "50px",
        "@media(min-width: 0px) and (max-width: 768px)": {
          width: "13vw",
        },
      },
      "& .MuiInputBase-root.Mui-disabled .MuiSelect-select": {
        color: "#c4c4c4 !important",
      },
    },
  };
});

const FilterDistance = ({ disable }) => {
  const classes = useStyles();
  const { search } = useLocation();
  const queryStringValues = search ? queryString.parse(search) : {};
  const concatValues = (geoLocationRadius: string | (string | null)[]): string => {
    return geoLocationRadius instanceof Array ? geoLocationRadius.join(" ") : geoLocationRadius;
  };
  const radius =
    queryStringValues && queryStringValues.geoLocationRadius
      ? concatValues(queryStringValues.geoLocationRadius).replace("/mi/g", "")
      : config.GEO_LOCATION_RADIUS;
  const [geoLocationRadius] = useState(radius);

  const handleChange = (event) => {
    jobSearchQueryBuilder.setIsFilteredJobs(true);
    jobSearchQueryBuilder.handleParamsChange({
      geoLocationRadius: `${event.target.value}mi`,
      offset: 1,
      refreshPayFilter: "true",
    });
  };
  const limit = [30, 50, 100, 250];
  return (
    <span className={classes.filterDistance}>
      <FormControl>
        <Select
          labelId="filter-distance-label"
          id="filter-distance"
          value={geoLocationRadius ? parseInt(geoLocationRadius, 10) : config.GEO_LOCATION_RADIUS}
          onChange={handleChange}
          disableUnderline
          disabled={!disable}
        >
          {limit.map((option) => (
            <MenuItem key={option} value={option} className="selectedPgValue">
              {option}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </span>
  );
};

FilterDistance.propTypes = {
  disable: PropTypes.bool.isRequired,
};

export default FilterDistance;
