import React from "react";
import { connect } from "react-redux";
import BasicInfoPage from "./BasicInfoPage";
import WorkExpPage from "./WorkExpPage";
import ReferencesPage from "./ReferencesPage";
import SpecialtiesEduPage from "./Specialties&EduPage";
import SkillChecklistOverview from "./SkillChecklistOverview";
import {
  BASIC_INFO_PAGE,
  EDU_SPEC_PAGE,
  WORK_EXP_PAGE,
  SKILL_CHECKLIST_OVERVIEW_PAGE,
  REFERENCES_PAGE,
} from "../../router/routes";
import config from "../../config";

class Profiledocs extends React.PureComponent {
  constructor(props) {
    super(props);
    let pageToRedirect = BASIC_INFO_PAGE;
    if (!this.props.onBoardingCompletedSteps[0]) {
      pageToRedirect = BASIC_INFO_PAGE;
    } else if (!this.props.onBoardingCompletedSteps[1]) {
      pageToRedirect = EDU_SPEC_PAGE;
    } else if (!this.props.onBoardingCompletedSteps[2]) {
      pageToRedirect = WORK_EXP_PAGE;
    } else if (!this.props.onBoardingCompletedSteps[3]) {
      pageToRedirect = SKILL_CHECKLIST_OVERVIEW_PAGE;
    } else if (!this.props.onBoardingCompletedSteps[4]) {
      pageToRedirect = REFERENCES_PAGE;
    } else {
      pageToRedirect = BASIC_INFO_PAGE;
    }

    this.state = {
      pageToRedirect,
    };
  }

  render() {
    switch (this.state.pageToRedirect) {
      case BASIC_INFO_PAGE:
        return <BasicInfoPage />;
      case EDU_SPEC_PAGE:
        return <SpecialtiesEduPage />;
      case WORK_EXP_PAGE:
        return <WorkExpPage />;
      case REFERENCES_PAGE:
        return <ReferencesPage />;
      case SKILL_CHECKLIST_OVERVIEW_PAGE:
        return <SkillChecklistOverview />;
      default:
        return <BasicInfoPage />;
    }
  }
}
const mapStateToProps = (state) => ({
  onBoardingCompletedSteps: state.onBoarding.completedSteps,
});

export default connect(mapStateToProps, null)(Profiledocs);
