import { useEffect, useState } from "react";
import { Button } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Chip from "@material-ui/core/Chip";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import moment from "moment";
import { APIMethods, ReferencesStates } from "./constants/constants";

const ReferencesTable = ({
  references,
  mode,
  handelEdit,
  handelReadOnly,
  handelDelete,
  isEdit,
  editIndex,
}) => {
  const [isDisabled, setIsDisabled] = useState(false);
  useEffect(() => {
    mode !== APIMethods.ADD ? setIsDisabled(false) : setIsDisabled(true);
  }, [mode]);
  return (
    <div className="mb-5">
      <TableContainer component={Paper} className="referTableWrap mt-4">
        <Table>
          <TableHead>
            <TableRow className="tableHead">
              <TableCell>Status</TableCell>
              <TableCell>Facility</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Phone</TableCell>
              <TableCell>Email Address</TableCell>
              <TableCell>Completion Date</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody className="tableBody">
            {references.map((reference, index) => (
              <TableRow key={index}>
                <TableCell>
                  <Chip
                    className={
                      reference?.status.toLowerCase() === ReferencesStates.PENDING.toLowerCase()
                        ? "pendingStatus"
                        : reference?.status?.toLowerCase() ===
                          ReferencesStates.COMPLETE.toLowerCase()
                        ? "completeStatus"
                        : reference?.status?.toLowerCase() ===
                          ReferencesStates.INPROGRESS.toLowerCase()
                        ? "progressStatus"
                        : reference?.status?.toLowerCase() ===
                          ReferencesStates.DECLINED.toLowerCase()
                        ? "declineStatus"
                        : reference?.status?.toLowerCase() ===
                          ReferencesStates.EXPIRED.toLowerCase()
                        ? "expiredStatus"
                        : ""
                    }
                    label={reference?.status}
                  />
                </TableCell>
                <TableCell>{reference?.facilityName ? reference.facilityName : "-"}</TableCell>
                <TableCell>
                  {reference?.supervisorFirstName && reference?.supervisorLastName
                    ? `${reference.supervisorFirstName} ${reference.supervisorLastName}`
                    : reference?.supervisorFirstName
                    ? reference.supervisorFirstName
                    : reference?.supervisorLastName
                    ? reference.supervisorLastName
                    : "-"}
                </TableCell>
                <TableCell>
                  {reference?.supervisorPhone ? reference.supervisorPhone : "-"}
                </TableCell>
                <TableCell>
                  <span className="normalText">
                    {reference?.supervisorEmail ? reference.supervisorEmail : "-"}
                  </span>
                </TableCell>
                <TableCell>
                  {reference?.dateCompleted
                    ? moment.utc(reference.dateCompleted).format("MM/DD/yyyy")
                    : "-"}
                </TableCell>
                <TableCell className="tableIcons">
                  <>
                    <Button
                      onClick={() => handelEdit(reference, index)}
                      disabled={isEdit}
                      className="editIcon"
                    >
                      <div>
                        <EditOutlinedIcon />
                      </div>
                    </Button>
                    {reference?.status?.toLowerCase() !==
                      ReferencesStates.DECLINED.toLowerCase() && (
                      <Button
                        onClick={() => handelDelete(index, reference)}
                        disabled={isEdit}
                        className="deleteIcon"
                      >
                        <div>
                          <DeleteOutlineOutlinedIcon />
                        </div>
                      </Button>
                    )}
                  </>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
export default ReferencesTable;
